import { Row, Col, Table } from 'react-bootstrap';
import { FieldArray } from 'redux-form';

import pick from 'lodash.pick';

import WeightBalanceExternalLoadAssignmentFieldArray from './weight_balance_external_load_assignment_field_array';

const WeightBalanceExternalLoadAssignments = (props) => {
  const { field, formValues, isDeveloper } = props;

  const renderExternalLoadAssignmentsFieldArray = (externalLoadAssignments) => {
    const formKeys = ['external_load_assignments_attributes'];
    return (
      <WeightBalanceExternalLoadAssignmentFieldArray
        externalLoadAssignments={externalLoadAssignments}
        formValues={pick(formValues, formKeys)}
        isDeveloper={isDeveloper}
      />
    );
  };

  return (
    <Row className="mt-3">
      <Col xs={10}>
        <Table striped size="sm">
          <thead>
            <tr>
              <th style={{ width: '200px' }}>Name</th>
              {isDeveloper && <th>Weight Unit</th>}
              <th>External Load Weight</th>
            </tr>
          </thead>
          <FieldArray
            name={`${field}.external_load_assignments_attributes`}
            component={renderExternalLoadAssignmentsFieldArray}
          />
        </Table>
      </Col>
    </Row>
  );
};

export default WeightBalanceExternalLoadAssignments;
