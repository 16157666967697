import { useState, useEffect } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import compact from 'lodash.compact';
import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';

import Glyphicon from '../glyphicon';
import { round0, round2 } from '../../lib/convert_units';

const FlightPlan = (props) => {
  const {
    index,
    change,
    field,
    formValues,
    flightSegmentCalculatedData,
    locationsDataSelector,
    isDeveloper,
    anyPilotTakeOffFuelAdjusted,
  } = props;

  const round = isDeveloper ? round2 : round0;

  const {
    flightSegmentAirspeedAdj: originalFlightSegmentAirspeedAdj,
    flightSegmentDistanceAdj: originalFlightSegmentDistanceAdj,
    //
    flightSegmentTakeOffFuel: originalFlightSegmentTakeOffFuel,
    flightSegmentLandingFuel = 0,
    prevFlightSegmentLandingFuel,
    flightSegmentPilotTakeOffFuel: originalFlightSegmentPilotTakeOffFuel,
    flightSegmentPilotLandingFuel = 0,
    prevFlightSegmentPilotLandingFuel,
    //
    flightSegmentTakeOffFuelWeightUnderMinDispatchFuelWeight,
    flightSegmentPilotTakeOffFuelWeightUnderMinDispatchFuelWeight,
    flightSegmentTakeOffFuelOverMaximumTankCapacity,
    flightSegmentPilotTakeOffFuelOverMaximumTankCapacity,
    flightSegmentFuelBurn = 0,
    flightSegmentPilotTakeOffFuelAdjusted,
    bookingRemainingRequiredFuel = 0,
    //
    convertedLocationDistance,
    //
    flightSegmentLoadedFlighttime = 0,
    flightSegmentRemainingWeight = 0,
    //
    flightSegmentAllUpWeightOverMaximumWeight,
    flightSegmentOutOfBalance,
    //
  } = flightSegmentCalculatedData;

  const [routeShorthandName, setRouteShorthandName] = useState('');
  const [fuelNames, setFuelNames] = useState([]);
  const [flightSegmentDistance, setFlightSegmentDistance] = useState('-');
  const [flightSegmentAirspeedAdj, setFlightSegmentAirspeedAdj] = useState('-');
  const [flightSegmentTakeOffFuel, setFlightSegmentTakeOffFuel] = useState(0);
  const [flightSegmentPilotTakeOffFuel, setFlightSegmentPilotTakeOffFuel] = useState(0);

  useEffect(() => {
    let newRouteShorthandName = '';
    if (formValues) {
      const startLocationShorthandName = get(locationsDataSelector, [
        get(formValues, 'start_location_id'),
        'shorthandName',
      ]);
      const endLocationShorthandName = get(locationsDataSelector, [
        get(formValues, 'end_location_id'),
        'shorthandName',
      ]);
      newRouteShorthandName = compact([
        String(index),
        startLocationShorthandName,
        endLocationShorthandName,
      ]).join('-');
    }
    setRouteShorthandName(newRouteShorthandName);
  }, [index, formValues, locationsDataSelector]);

  useEffect(() => {
    let newFuelNames = [];
    if (flightSegmentCalculatedData) {
      const { flightSegmentFuelBowsers = [] } = flightSegmentCalculatedData;
      newFuelNames = [...flightSegmentFuelBowsers].map((f) => f.name);
    }
    setFuelNames(newFuelNames);
  }, [flightSegmentCalculatedData]);

  useEffect(() => {
    let newFlightSegmentDistance = '-';
    let newFlightSegmentAirspeedAdj = '-';
    let newTakeOffFuel = 0;
    let newPilotTakeOffFuel = 0;
    if (flightSegmentCalculatedData) {
      newFlightSegmentDistance = get(
        flightSegmentCalculatedData,
        'flightSegmentDistance',
        0
      );
      newFlightSegmentAirspeedAdj = get(
        flightSegmentCalculatedData,
        'flightSegmentAirspeedAdj',
        0
      );
      newTakeOffFuel = get(flightSegmentCalculatedData, 'flightSegmentTakeOffFuel', 0);
      newPilotTakeOffFuel = get(
        flightSegmentCalculatedData,
        'flightSegmentPilotTakeOffFuel',
        0
      );
    }
    setFlightSegmentDistance(round2(newFlightSegmentDistance));
    setFlightSegmentAirspeedAdj(round2(newFlightSegmentAirspeedAdj));
    setFlightSegmentTakeOffFuel(round2(newTakeOffFuel));
    setFlightSegmentPilotTakeOffFuel(round2(newPilotTakeOffFuel));
  }, [flightSegmentCalculatedData]);

  const handleDistanceChange = (e) => {
    const newDistance = e.target.value;
    setFlightSegmentDistance(newDistance);
  };

  const handleDistanceBlur = (e) => {
    const roundedConvertedLocationDistance = round2(convertedLocationDistance);
    let newDistance = round2(defaultTo(parseFloat(e.target.value), 0));
    if (newDistance === 0) {
      newDistance = roundedConvertedLocationDistance;
    }
    setFlightSegmentDistance(newDistance);
    let newDistanceAdj = 0;
    if (newDistance !== roundedConvertedLocationDistance) {
      newDistanceAdj = round2(newDistance - roundedConvertedLocationDistance);
    }
    if (newDistanceAdj !== originalFlightSegmentDistanceAdj) {
      change([field, 'distance_adj'].join('.'), newDistanceAdj);
    }
  };

  const handleAirspeedAdjChange = (e) => {
    const newAirspeedAdj = e.target.value;
    setFlightSegmentAirspeedAdj(newAirspeedAdj);
  };

  const handleAirspeedAdjBlur = (e) => {
    const newAirspeedAdj = round2(defaultTo(parseFloat(e.target.value), 0));
    setFlightSegmentAirspeedAdj(newAirspeedAdj);
    if (newAirspeedAdj !== originalFlightSegmentAirspeedAdj) {
      change([field, 'airspeed_adj'].join('.'), newAirspeedAdj);
    }
  };

  const handlePilotTakeOffFuelChange = (e) => {
    const newPilotTakeOffFuel = e.target.value;
    setFlightSegmentPilotTakeOffFuel(newPilotTakeOffFuel);
  };

  const handlePilotTakeOffFuelBlur = (e) => {
    const newPilotTakeOffFuel = round2(defaultTo(parseFloat(e.target.value), 0));
    setFlightSegmentPilotTakeOffFuel(newPilotTakeOffFuel);
    if (newPilotTakeOffFuel !== round2(originalFlightSegmentPilotTakeOffFuel)) {
      if (newPilotTakeOffFuel === 0) {
        change([field, 'pilot_take_off_fuel_adjusted'].join('.'), false);
        change([field, 'take_off_fuel'].join('.'), 0);
      } else {
        change([field, 'pilot_take_off_fuel_adjusted'].join('.'), true);
      }
      change([field, 'pilot_take_off_fuel'].join('.'), newPilotTakeOffFuel);
    }
  };

  const renderTitle = () => {
    if (fuelNames.length > 0) {
      const tooltip = (
        <Tooltip id={`tooltip-flight-plan-${index}`}>
          {fuelNames.map((fuelName) => (
            <p key={fuelName} className="mb-0">
              {fuelName}
            </p>
          ))}
        </Tooltip>
      );
      return (
        <OverlayTrigger key={index} placement="right" overlay={tooltip}>
          <span>{routeShorthandName}</span>
        </OverlayTrigger>
      );
    }
    return routeShorthandName;
  };

  const hasRefuelled = () =>
    originalFlightSegmentPilotTakeOffFuel > prevFlightSegmentPilotLandingFuel;

  const hasDefuelled = () =>
    originalFlightSegmentPilotTakeOffFuel < prevFlightSegmentPilotLandingFuel;

  const takeOffFuelRefill = () => {
    if (prevFlightSegmentLandingFuel) {
      return round2(originalFlightSegmentTakeOffFuel - prevFlightSegmentLandingFuel);
    }
    return '-';
  };

  const pilotTakeOffFuelRefill = () => {
    if (prevFlightSegmentPilotLandingFuel) {
      return round2(
        originalFlightSegmentPilotTakeOffFuel - prevFlightSegmentPilotLandingFuel
      );
    }
    return '-';
  };

  if (isEmpty(flightSegmentCalculatedData)) {
    return null;
  }

  return (
    <tr>
      <td className="text-start">{renderTitle()}</td>
      <td>{round(convertedLocationDistance)}</td>
      <td>
        <input
          type="text"
          style={{ width: '88px' }}
          value={flightSegmentDistance}
          onChange={handleDistanceChange}
          onBlur={handleDistanceBlur}
        />
      </td>
      <td>
        <input
          type="text"
          style={{ width: '88px' }}
          value={flightSegmentAirspeedAdj}
          onChange={handleAirspeedAdjChange}
          onBlur={handleAirspeedAdjBlur}
        />
      </td>
      <td>
        {round(flightSegmentLoadedFlighttime)}
        &nbsp;/&nbsp;{round(flightSegmentFuelBurn)}
      </td>
      {isDeveloper && <td>{round(bookingRemainingRequiredFuel)}</td>}
      <td
        style={{
          ...(!isDeveloper &&
            anyPilotTakeOffFuelAdjusted && {
              color: '#d3d3d3',
            }),
          ...(flightSegmentTakeOffFuelOverMaximumTankCapacity && {
            backgroundColor: 'red',
            color: 'black',
          }),
        }}
      >
        {round(flightSegmentTakeOffFuel)}
      </td>
      <td
        style={{
          ...(!isDeveloper &&
            anyPilotTakeOffFuelAdjusted && {
              color: '#d3d3d3',
            }),
          ...(flightSegmentTakeOffFuelWeightUnderMinDispatchFuelWeight && {
            backgroundColor: 'red',
            color: 'black',
          }),
        }}
      >
        {round(flightSegmentLandingFuel)}
      </td>
      {isDeveloper ? (
        <td>{`${takeOffFuelRefill()}/${pilotTakeOffFuelRefill()}`}</td>
      ) : (
        <td>
          <Glyphicon
            style={{
              /* eslint-disable-next-line */
              color: hasRefuelled() ? 'green' : hasDefuelled() ? 'red' : '#dddddd',
            }}
            /* eslint-disable-next-line */
            glyph={hasRefuelled() ? 'ok' : hasDefuelled() ? 'exclamation-sign' : 'minus'}
          />
        </td>
      )}
      <td>
        <input
          type="text"
          style={{
            width: '88px',
            ...(flightSegmentPilotTakeOffFuelOverMaximumTankCapacity && {
              backgroundColor: 'red',
              color: 'black',
            }),
          }}
          value={flightSegmentPilotTakeOffFuel}
          onChange={handlePilotTakeOffFuelChange}
          onBlur={handlePilotTakeOffFuelBlur}
        />
      </td>
      <td
        {...(flightSegmentPilotTakeOffFuelWeightUnderMinDispatchFuelWeight && {
          style: {
            backgroundColor: 'red',
            color: 'black',
          },
        })}
      >
        {round(flightSegmentPilotLandingFuel)}
      </td>
      <td
        {...(flightSegmentAllUpWeightOverMaximumWeight && {
          style: {
            backgroundColor: 'red',
            color: 'black',
          },
        })}
      >
        {round(flightSegmentRemainingWeight)}
      </td>
      {isDeveloper && (
        <td>
          <Glyphicon
            style={{
              color: flightSegmentPilotTakeOffFuelAdjusted ? 'green' : '#dddddd',
            }}
            glyph={flightSegmentPilotTakeOffFuelAdjusted ? 'ok' : 'minus'}
          />
        </td>
      )}
      <td>
        <Glyphicon
          style={{
            color: flightSegmentOutOfBalance ? 'red' : 'green',
          }}
          glyph={flightSegmentOutOfBalance ? 'exclamation-sign' : 'ok'}
        />
      </td>
    </tr>
  );
};

export default FlightPlan;
