import { Row, Col, Card, Table } from 'react-bootstrap';

import DlHorizontal from '../dl_horizontal';

const BookingAuditSnapshot = (props) => {
  const {
    id,
    chargeable_full_names: chargeableFullNames,
    provider_full_name: providerFullName,
    aircraft_display_name_with_type: aircraftDisplayNameWithType,
    aircraft_configuration_name: aircraftConfigurationName,
    pilot_display_name: pilotDisplayName,
    copilot_display_name: copilotDisplayName,
    requested_by: requestedBy,
    customer_reference: customerReference,
    pilot_notes: pilotNotes,
    job_notes: jobNotes,
    public_notes: publicNotes,
    flight_segment_summaries: flightSegmentSummaries,
  } = props;

  const renderFlightSegmentSummary = (flightSegmentSummary) => {
    const {
      id: fsId,
      start_at: startAt,
      start_location: startLocation,
      end_location: endLocation,
      pax,
    } = flightSegmentSummary;
    return (
      <tr key={fsId}>
        <td>{startAt}</td>
        <td>{`${startLocation} - ${endLocation}`}</td>
        <td>{pax}</td>
      </tr>
    );
  };

  const renderFlightSegmentSummaries = () => (
    <Table size="sm">
      <tbody>
        {flightSegmentSummaries.map((flightSegmentSummary) =>
          renderFlightSegmentSummary(flightSegmentSummary)
        )}
      </tbody>
    </Table>
  );

  return (
    <Row className="mt-3">
      <Col xs={6}>
        <Card>
          <Card.Header>Details</Card.Header>
          <Card.Body>
            <DlHorizontal dt="ID" dd={id} />
            <DlHorizontal dt="Chargeable" dd={chargeableFullNames} />
            <DlHorizontal dt="Provider" dd={providerFullName} />
            <DlHorizontal dt="Aircraft" dd={aircraftDisplayNameWithType} />
            <DlHorizontal dt="Configuration" dd={aircraftConfigurationName} />
            <DlHorizontal dt="Pilot" dd={pilotDisplayName} />
            <DlHorizontal dt="Copilot" dd={copilotDisplayName} />
            <DlHorizontal dt="Requested By" dd={requestedBy} />
            <DlHorizontal dt="Customer Reference" dd={customerReference} />
            <DlHorizontal dt="Pilot Notes" dd={pilotNotes} />
            <DlHorizontal dt="Job Notes" dd={jobNotes} />
            <DlHorizontal dt="Public Notes" dd={publicNotes} />
          </Card.Body>
        </Card>
      </Col>
      <Col xs={6}>
        <Card>
          <Card.Header>Flight Segments</Card.Header>
          <Card.Body>{renderFlightSegmentSummaries()}</Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default BookingAuditSnapshot;
