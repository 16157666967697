import { Col } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash.get';
import debounce from 'lodash.debounce';

import InputField from '../form/input_field';

class LocationFormGeometryFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitudeEntered: '',
      longitudeEntered: '',
      latitudeEnteredValid: true,
      longitudeEnteredValid: true,
    };
    this._handleLatitudeEntered = this._handleLatitudeEntered.bind(this);
    this._handleLongitudeEntered = this._handleLongitudeEntered.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.delayedUpdateLatitude = debounce((latitudeEntered, displayFormat) => {
      this.props.latitude.input.onChange(
        this.convertToDd(latitudeEntered, displayFormat)
      );
    }, 500);
    this.delayedUpdateLongitude = debounce((longitudeEntered, displayFormat) => {
      this.props.longitude.input.onChange(
        this.convertToDd(longitudeEntered, displayFormat)
      );
    }, 500);
    this._setGeometry(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.display_format') !==
        get(nextProps, 'formValues.display_format') ||
      get(this.props, 'formValues.latitude') !== get(nextProps, 'formValues.latitude') ||
      get(this.props, 'formValues.longitude') !== get(nextProps, 'formValues.longitude')
    ) {
      this._setGeometry(nextProps);
    }
  }

  componentWillUnmount() {
    this.delayedUpdateLatitude.cancel();
    this.delayedUpdateLongitude.cancel();
  }

  _setGeometry(props) {
    const {
      latitude,
      longitude,
      display_format: displayFormat,
    } = get(props, 'formValues');
    if (latitude && longitude) {
      this.setState({
        latitudeEntered: this.convertFromDd(latitude, parseInt(displayFormat, 10)),
        longitudeEntered: this.convertFromDd(longitude, parseInt(displayFormat, 10)),
      });
    }
  }

  convertFromDd(coordinate, displayFormat) {
    switch (parseInt(displayFormat, 10)) {
      case 2: // decimal minutes -> dd
        return this.convertDdDmmCoordinate(coordinate);
      case 1: // degree minute seconds -> dd
        return this.convertDdDmsCoordinate(coordinate);
      default:
        return Math.round(coordinate * 10000000000) / 10000000000;
    }
  }

  convertDdDmmCoordinate(coordinate) {
    let multiplier = 1.0;
    if (coordinate < 0.0) {
      coordinate *= -1.0;
      multiplier = -1.0;
    }
    const degrees = multiplier * Math.floor(coordinate);
    let decimalMinutes = coordinate % 1;
    decimalMinutes *= 60;
    decimalMinutes = Math.round(decimalMinutes * 10000000000) / 10000000000;
    return [degrees, decimalMinutes].join(' ');
  }

  convertDdDmsCoordinate(coordinate) {
    let multiplier = 1.0;
    if (coordinate < 0.0) {
      coordinate *= -1.0;
      multiplier = -1.0;
    }
    const degrees = multiplier * Math.floor(coordinate);
    let decimalMinutes = coordinate % 1;
    decimalMinutes *= 60;

    const minutes = Math.floor(decimalMinutes);
    let decimalSeconds = decimalMinutes % 1;
    decimalSeconds *= 60;
    decimalSeconds = Math.round(decimalSeconds * 10000000000) / 10000000000;
    return [degrees, minutes, decimalSeconds].join(' ');
  }

  convertToDd(coordinate, displayFormat) {
    switch (parseInt(displayFormat, 10)) {
      case 2: // decimal minutes -> dd
        return this.convertDmmDdCoordinate(coordinate);
      case 1: // degree minute seconds -> dd
        return this.convertDmsDdCoordinate(coordinate);
      default:
        return coordinate;
    }
  }

  convertDmmDdCoordinate(coordinate) {
    const parts = coordinate.split(/\s/);
    let degrees = Number(parts[0]);
    let multipler = 1.0;
    if (degrees < 0.0) {
      degrees *= -1.0;
      multipler = -1.0;
    }
    const decimalMinutes = parts[1] ? Number(parts[1]) : 0;
    const newCoordinate = multipler * (degrees + decimalMinutes / 60);
    return newCoordinate;
  }

  convertDmsDdCoordinate(coordinate) {
    const parts = coordinate.split(/\s/);
    let degrees = Number(parts[0]);
    let multipler = 1.0;
    if (degrees < 0.0) {
      degrees *= -1.0;
      multipler = -1.0;
    }
    const minutes = parts[1] ? Number(parts[1]) : 0;
    const seconds = parts[2] ? Number(parts[2]) : 0;
    const newCoordinate = multipler * (degrees + minutes / 60 + seconds / 60 / 60);
    return newCoordinate;
  }

  _handleLatitudeEntered(e) {
    const latitudeEntered = e.target.value;
    const latitudeEnteredValid = this.validateLatitudeEntered(latitudeEntered);
    this.setState({
      latitudeEntered,
      latitudeEnteredValid,
    });
    if (latitudeEnteredValid) {
      const displayFormat = parseInt(get(this.props, 'formValues.display_format'), 10);
      this.updateLatitude(latitudeEntered, displayFormat);
    }
  }

  updateLatitude(latitudeEntered, displayFormat) {
    this.delayedUpdateLatitude(latitudeEntered, displayFormat);
  }

  _handleLongitudeEntered(e) {
    const longitudeEntered = e.target.value;
    const longitudeEnteredValid = this.validateLongitudeEntered(longitudeEntered);
    this.setState({
      longitudeEntered,
      longitudeEnteredValid,
    });
    if (longitudeEnteredValid) {
      const displayFormat = parseInt(get(this.props, 'formValues.display_format'), 10);
      this.updateLongitude(longitudeEntered, displayFormat);
    }
  }

  updateLongitude(longitudeEntered, displayFormat) {
    this.delayedUpdateLongitude(longitudeEntered, displayFormat);
  }

  validateLatitudeEntered(latitudeEntered) {
    if (latitudeEntered === '') {
      return true;
    }
    let valid = true;
    const displayFormat = parseInt(get(this.props, 'formValues.display_format'), 10);
    if (!Number.isNaN(displayFormat) && latitudeEntered) {
      switch (parseInt(displayFormat, 10)) {
        case 3: // decimal degree
          valid =
            /^(\+|-)?(?:90(?:(?:\.0{1,10})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,10})?))$/.test(
              latitudeEntered
            );
          break;
        case 2: // decimal minutes
          valid =
            /^(\+|-)?([0-8]?[0-9]((\s[0-5]?[0-9])|(\s[0-5]?[0-9]\.[0-9]{1,10}))?|90((\s[0]?[0])|(\s[0]?[0]\.0{1,10}))?)$/.test(
              latitudeEntered
            );
          break;
        case 1: // degree minute seconds
          valid =
            /^(\+|-)?([0-8]?[0-9](\s[0-5]?[0-9])?((\s[0-5]?[0-9])|(\s[0-5]?[0-9]\.[0-9]{1,10}))?|90(\s[0]?[0])?((\s[0]?[0])|(\s[0]?[0]\.0{1,10}))?)$/.test(
              latitudeEntered
            );
          break;
        default:
          valid = true;
      }
    }
    return valid;
  }

  validateLongitudeEntered(longitudeEntered) {
    if (longitudeEntered === '') {
      return true;
    }
    let valid = true;
    const displayFormat = parseInt(get(this.props, 'formValues.display_format'), 10);
    if (!Number.isNaN(displayFormat) && longitudeEntered) {
      switch (parseInt(displayFormat, 10)) {
        case 3: // decimal degree
          valid =
            /^(\+|-)?(?:180(?:(?:\.0{1,10})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,10})?))$/.test(
              longitudeEntered
            );
          break;
        case 2: // decimal minutes
          valid =
            /^(\+|-)?(([0-9]|[1-9][0-9]|1[0-7][0-9])((\s[0-5]?[0-9])|(\s[0-5]?[0-9]\.[0-9]{1,10}))?|180((\s[0]?[0])|(\s[0]?[0]\.0{1,10}))?)$/.test(
              longitudeEntered
            );
          break;
        case 1: // degree minute seconds
          valid =
            /^(\+|-)?(([0-9]|[1-9][0-9]|1[0-7][0-9])(\s[0-5]?[0-9])?((\s[0-5]?[0-9])|(\s[0-5]?[0-9]\.[0-9]{1,10}))?|180(\s[0]?[0])?((\s[0]?[0])|(\s[0]?[0]\.0{1,10}))?)$/.test(
              longitudeEntered
            );
          break;
        default:
          valid = true;
      }
    }
    return valid;
  }

  _renderDisplayFormatHint() {
    const displayFormat = this.props.locationDisplayFormats.find(
      (df) => df.id === parseInt(get(this.props, 'formValues.display_format'), 10)
    );
    return get(displayFormat, 'format');
  }

  render() {
    const {
      display_format: { input: displayFormatInput, meta: displayFormatMeta },
      latitude: { input: latitudeInput, meta: latitudeMeta },
      longitude: { input: longitudeInput, meta: longitudeMeta },
    } = this.props;

    const { locationDisplayFormats } = this.props;
    return (
      <Col xs={12}>
        <InputField
          type="text"
          plainText
          labelWidth={3}
          inputWidth={3}
          input={latitudeInput}
          meta={latitudeMeta}
        >
          Current DD Latitude
        </InputField>
        <InputField
          type="text"
          plainText
          labelWidth={3}
          inputWidth={3}
          input={longitudeInput}
          meta={longitudeMeta}
        >
          Current DD Longitude
        </InputField>
        <InputField
          labelWidth={3}
          inputWidth={3}
          asElement="select"
          selectOptions={locationDisplayFormats}
          defaultSelectOption={false}
          input={displayFormatInput}
          meta={displayFormatMeta}
        >
          Display Format
        </InputField>
        <InputField
          type="text"
          labelWidth={3}
          inputWidth={3}
          helpText={this._renderDisplayFormatHint()}
          showValid
          input={{
            name: 'latitudeEntered',
            value: this.state.latitudeEntered,
            onChange: this._handleLatitudeEntered,
          }}
          meta={{ touched: true, invalid: !this.state.latitudeEnteredValid, error: '' }}
        >
          Change Latitude
        </InputField>
        <InputField
          type="text"
          labelWidth={3}
          inputWidth={3}
          helpText={this._renderDisplayFormatHint()}
          showValid
          input={{
            name: 'longitudeEntered',
            value: this.state.longitudeEntered,
            onChange: this._handleLongitudeEntered,
          }}
          meta={{ touched: true, invalid: !this.state.longitudeEnteredValid, error: '' }}
        >
          Change Longitude
        </InputField>
      </Col>
    );
  }
}

export default LocationFormGeometryFields;
