import 'whatwg-fetch';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Rollbar from 'rollbar/dist/rollbar.umd';

import get from 'lodash.get';
import pick from 'lodash.pick';
import isEqual from 'lodash.isequal';

import { store, reduxPersistor } from './store';
import { getIdToken, removeIdToken, clearLocalStorage } from './lib/local_storage';
import getTenantConfig from './lib/tenant_config';
import { processMutationSubscription } from './lib/process_mutation_subscription';

import mutationSubscription from './subscriptions/mutation_subscription';

import {
  restoreApolloCache,
  hydrateCache,
  apolloClient,
  queueLink,
} from './lib/apollo_client';
import Api from './lib/api';

import Loader from './components/loader';
import { authSet, authReset } from './actions/auth_actions';
import { dataSet, dataReset } from './actions/data_actions';
import { currentSettingsSet } from './actions/current_setting_actions';

import App from './app';

let pubsubSubscription = null;

const subscribeMutation = () => {
  if (pubsubSubscription) {
    pubsubSubscription.unsubscribe();
    pubsubSubscription = null;
  }
  pubsubSubscription = apolloClient
    .subscribe({
      query: mutationSubscription,
      variables: {},
      fetchPolicy: 'no-cache',
    })
    .subscribe({
      next(mutation) {
        processMutationSubscription(mutation);
      },
      error(error) {
        console.log('mutationSubscriptionError: ', error);
      },
    });
};

const setOnline = async () => {
  const deviceOnline = window.navigator.onLine;
  let serverOnline;
  if (deviceOnline) {
    try {
      const pingResp = await fetch(`${process.env.EXPRESS_API_URL}/ping`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      const ping = await pingResp.json();
      if (ping.pong) {
        serverOnline = true;
      } else {
        serverOnline = false;
      }
    } catch (err) {
      console.log(err);
      serverOnline = false;
    }
  } else {
    serverOnline = false;
  }
  const online = deviceOnline && serverOnline;
  const newState = {
    online,
    deviceOnline,
    serverOnline,
  };
  const currentState = pick(get(store.getState(), ['currentSettings']), [
    'online',
    'deviceOnline',
    'serverOnline',
  ]);
  if (!isEqual(newState, currentState)) {
    store.dispatch(currentSettingsSet(newState));
  }
  if (!currentState.online && newState.online) {
    queueLink.open();
    subscribeMutation();
  }
  if (currentState.online && !newState.online) {
    queueLink.close();
  }
  // console.log({ online, deviceOnline, serverOnline });
  setTimeout(setOnline, 15000);
};
await setOnline();

const setTenant = () => {
  const tenantConfig = getTenantConfig(window.$CLIENT_TENANT);
  store.dispatch(currentSettingsSet(tenantConfig));
};
setTenant();

if (process.env.NODE_ENV === 'production') {
  const rollbarConfig = {
    accessToken: process.env.ROLLBAR_CLIENT_ACCESS_TOKEN, // eslint-disable-line no-undef
    captureUncaught: true,
    payload: {
      environment: process.env.NODE_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.GIT_REVISION, // eslint-disable-line no-undef
          guess_uncaught_frames: true,
        },
      },
    },
  };
  window.Rollbar = new Rollbar(rollbarConfig);
}

const restoreStore = async () => {
  const { cacheUpdatedAt } = store.getState().currentSettings;
  if (!cacheUpdatedAt) {
    const dataResp = await Api.get('/api/data');
    store.dispatch(dataSet(dataResp.data));
  } else {
    try {
      const cacheResp = await Api.get('/api/cache', { cache_updated_at: cacheUpdatedAt });
      if (cacheResp.data.stale) {
        const dataResp = await Api.get('/api/data');
        store.dispatch(dataSet(dataResp.data));
      }
    } catch (err) {
      // something dodgy going on with token, decodeable but backend says not signed
      removeIdToken();
      window.location.reload();
    }
  }
};

const restoreAuth = async () => {
  const token = getIdToken();
  try {
    const decoded = jwtDecode(token);
    store.dispatch(
      authSet({
        isAuthenticating: false,
        isAuthenticated: true,
        isEmployee: decoded.isEmployee,
        token,
        contact: decoded.contact,
        contactId: decoded.contact_id,
        tenant: decoded.tenant,
        connectionKey: decoded.connectionKey,
      })
    );
    return true;
  } catch (err) {
    // console.log(err);
    removeIdToken();
    await clearLocalStorage();
    store.dispatch(authReset());
    store.dispatch(dataReset());
    // if (!history.location.pathname.includes('login')) {
    //   history.push('/logout');
    // }
    return false;
  }
};

const authed = await restoreAuth();

if (authed) {
  await restoreApolloCache();
  await restoreStore();
  await hydrateCache();
}

const rootEl = document.getElementById('root');
const root = createRoot(rootEl);

const initApp = async (InitApp) => (
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={reduxPersistor}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <InitApp />
        </BrowserRouter>
      </ApolloProvider>
    </PersistGate>
  </Provider>
);

initApp(App).then((app) => root.render(app));
