import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import { Row, Col, Card, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

import Confirm from '../components/confirm';
import Loader from '../components/loader';
import DlHorizontal from '../components/dl_horizontal';
import Title from '../components/title';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import seatConfigurationQuery from '../queries/seat_configuration_query';
import seatConfigurationDeleteMutation from '../mutations/seat_configuration_delete_mutation';

import { queriesReady } from '../lib/utils';

class SeatConfigurationShow extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const seatConfigurationId = e.currentTarget.getAttribute('data-id');
    this.props
      .seatConfigurationDeleteMutation({
        variables: {
          id: seatConfigurationId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Seat Configuration delete');
        this.props.navigate('/seat_configurations');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.aircraftTypeListQuery,
      testProps.seatConfigurationQuery
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoaded()) {
      const {
        id,
        name,
        aircraftType: { name: aircraftTypeName },
        aircraftSeatSeatConfigurations,
      } = this.props.seatConfigurationQuery.data;
      return (
        <>
          <Row className="mt-4 mb-3">
            <Col sm="auto">
              <Title show id={id}>
                Seat Configuration
              </Title>
            </Col>
            <Col>
              <Row className="justify-content-end g-0">
                <Col sm="auto">
                  <ButtonToolbar>
                    <ButtonGroup size="sm" className="mx-1">
                      <Confirm
                        dataId={id}
                        onConfirm={this.handleDeleteClick}
                        title="Delete Seat Configuration"
                        body="Are you sure you want to delete this Seat Configuration?"
                        confirmText="Confirm"
                      >
                        <Button variant="danger">Delete</Button>
                      </Confirm>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="mx-1">
                      <LinkContainer to={`/seat_configurations/${id}/edit`}>
                        <Button variant="primary">Edit</Button>
                      </LinkContainer>
                      <LinkContainer to="/seat_configurations">
                        <Button variant="primary">All Seat Configurations</Button>
                      </LinkContainer>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row className="mb-4">
            <Col sm={6}>
              <Card>
                <Card.Header>Seat Configuration Details</Card.Header>
                <Card.Body>
                  <DlHorizontal dt="Name" dd={name} />
                  <DlHorizontal dt="Aircraft Type" dd={aircraftTypeName} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Card>
                <Card.Header>Seat Details</Card.Header>
                <Card.Body>
                  {aircraftSeatSeatConfigurations.map((seat) => {
                    const {
                      seat_installed: seatInstalled,
                      aircraftSeat: { name: seatName, seat_type: seatType },
                    } = seat;
                    return (
                      <DlHorizontal
                        dt={seatName}
                        dd={`SeatType: ${seatType} - Installed: ${seatInstalled}`}
                        key={seatName}
                      />
                    );
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(seatConfigurationDeleteMutation, {
    name: 'seatConfigurationDeleteMutation',
  }),
  graphql(aircraftTypeListQuery, {
    name: 'aircraftTypeListQuery',
  }),
  graphql(seatConfigurationQuery, {
    name: 'seatConfigurationQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  })
)(SeatConfigurationShow);

// export default connect(mapStateToProps, {
//   mutationSuccess,
//   mutationFailure,
//   mutationSet,
// })(SeatConfigurationShow);
