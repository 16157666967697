import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { connect } from 'react-redux';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady } from '../lib/utils';

import bookingDetailForMonthByProviderQuery from '../queries/booking_detail_for_month_by_provider_query';
import contactListQuery from '../queries/contact_list_query';

moment.updateLocale('en-nz');

class ReportProviderFlightRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterProviderId: this.props.currentSettingsReportProviderId,
      filterProviderIds: [],
    };
    this._handleDisplayProviderIdChange = this._handleDisplayProviderIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('month').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.provider_id) {
      this._handleDisplayProviderIdChange({
        target: { value: this.props.params.provider_id },
      });
    }
    if (
      !moment(this.props.currentSettingsReportStart).isSame(
        moment(this.props.currentSettingsReportStart).startOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportStart: moment(this.props.currentSettingsReportStart)
          .startOf('month')
          .toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportEnd).isSame(
        moment(this.props.currentSettingsReportStart).endOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportEnd: moment(this.props.currentSettingsReportStart)
          .endOf('month')
          .toISOString(),
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.bookingDetailForMonthByProviderQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterProviderIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterProviderIds = nextProps.bookingDetailForMonthByProviderQuery.data.map(
        (data) => data.provider_id
      );
    }
    this.setState({
      filterProviderIds,
    });
    let filterProviderId = nextProps.currentSettingsReportProviderId;
    if (filterProviderId && this.isLoaded(nextProps)) {
      if (
        filterProviderIds.length > 0 &&
        filterProviderIds.indexOf(filterProviderId) === -1
      ) {
        filterProviderId = '';
      }
    }
    if (filterProviderId !== this.props.currentSettingsReportProviderId) {
      this._handleDisplayProviderIdChange({ target: { value: filterProviderId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.bookingDetailForMonthByProviderQuery,
      props.providerListQuery
    );
  }

  _handleDisplayProviderIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterProviderId: value,
    });
    this.props.currentSettingsSet({
      reportProviderId: value,
    });
  }

  _renderFixed(value, symbol = '') {
    return value ? symbol + value.toFixed(2) : '-';
  }

  _renderLandingFees(landing_fees) {
    if (landing_fees) {
      return (
        <span>
          {landing_fees
            .filter((lf) => lf.oncharge)
            .map((lf) => {
              const oncharge = lf.oncharge ? 'Charge Client: Yes' : 'Charge Client: No';
              const fee = `$${lf.fee}`;
              return (
                <span
                  key={lf.id}
                  style={{ display: 'block' }}
                >{`${lf.name} Landing Fee - ${fee} - ${oncharge}`}</span>
              );
            })}
        </span>
      );
    }
  }

  _renderPilotFlightExpenses(pilot_expenses) {
    if (pilot_expenses) {
      return (
        <span>
          {pilot_expenses
            .filter((pe) => pe.oncharge)
            .map((pe) => {
              const oncharge = pe.oncharge ? 'Charge Client: Yes' : 'Charge Client: No';
              const fee = `$${pe.fee}`;
              return (
                <span
                  key={pe.id}
                  style={{ display: 'block' }}
                >{`Pilot Expense - ${pe.note} - ${fee} - ${oncharge}`}</span>
              );
            })}
        </span>
      );
    }
  }

  _renderChargeables(chargeables) {
    if (chargeables) {
      return (
        <span>
          {chargeables.map((bc) => {
            const { chargeable_full_name } = bc;
            const invoice_reference = bc.invoice_reference
              ? `#${bc.invoice_reference}`
              : '-';
            return (
              <span
                key={bc.id}
                style={{ display: 'block' }}
              >{`${chargeable_full_name}: ${invoice_reference}`}</span>
            );
          })}
        </span>
      );
    }
  }

  _renderFlightSegmentSummary(flight_segment_summary) {
    const { start_at, start_location, end_location, pax } = flight_segment_summary;
    const summary = `${start_at} ${start_location} - ${end_location}`;
    return (
      <span>
        {summary} {pax ? ' | ' : ''} {pax}
      </span>
    );
  }

  _renderRow(booking) {
    const {
      id,
      booking_id,
      reference,
      start_at_s,
      pilot_display_name,
      copilot_display_name,
      aircraft_registration_abbreviated,
      flight_time,
      pilot_expenses,
      landing_fees,
      chargeables,
      flight_segment_summaries,
    } = booking;

    return (
      <tr key={id}>
        <td>
          <LinkContainer to={`/flights/${booking_id}/edit`} className="ps-0">
            <Button variant="link">{`#${reference}`}</Button>
          </LinkContainer>
        </td>
        <td>{start_at_s}</td>
        <td>
          {[pilot_display_name, copilot_display_name].filter((name) => name).join(', ')}
        </td>
        <td>{aircraft_registration_abbreviated}</td>
        <td className="text-end">{this._renderFixed(flight_time)}</td>
        <td>
          {flight_segment_summaries.map((flight_segment_summary, index) => (
            <span key={index} style={{ display: 'block' }}>
              {this._renderFlightSegmentSummary(flight_segment_summary)}
            </span>
          ))}
        </td>
        <td>
          <span>
            {this._renderLandingFees(landing_fees)}
            {this._renderPilotFlightExpenses(pilot_expenses)}
          </span>
        </td>
        <td>{this._renderChargeables(chargeables)}</td>
      </tr>
    );
  }

  _renderRows(bookings) {
    return <tbody>{bookings.map((booking) => this._renderRow(booking))}</tbody>;
  }

  _renderHeaderRow() {
    return (
      <thead>
        <tr>
          <th className="border-top-0">Ref#</th>
          <th className="border-top-0">Date</th>
          <th className="border-top-0">Pilot</th>
          <th className="border-top-0">Registration</th>
          <th className="border-top-0">Time</th>
          <th width="30%" className="border-top-0">
            Route Details
          </th>
          <th width="15%" className="border-top-0">
            Chargeable Expenses
          </th>
          <th width="15%" className="border-top-0">
            Invoice#
          </th>
        </tr>
      </thead>
    );
  }

  _renderFooterRow(bookings, provider_full_name) {
    const result_hash = {
      flight_time: 0,
      expenses: 0,
      landing_fees: 0,
      pilot_expenses: 0,
    };
    const results = bookings.reduce((results, booking) => {
      results.flight_time += booking.flight_time || 0;
      if (booking.landing_fees) {
        results.expenses += booking.landing_fees
          .filter((lf) => lf.oncharge)
          .reduce((sum, lf) => sum + (lf.fee || 0), 0);
      }
      if (booking.pilot_expenses) {
        results.expenses += booking.pilot_expenses
          .filter((pe) => pe.oncharge)
          .reduce((sum, pe) => (sum = pe.fee || 0), 0);
      }
      return results;
    }, result_hash);
    return (
      <tr>
        <th
          colSpan={4}
          className="text-end"
        >{`Total Flight Time for ${provider_full_name}:`}</th>
        <th colSpan={1} className="text-end">
          {this._renderFixed(results.flight_time)}
        </th>
        <th
          style={{ textAlign: 'right' }}
        >{`Total Expenses for ${provider_full_name}:`}</th>
        <th style={{ textAlign: 'left' }}>{this._renderFixed(results.expenses, '$')}</th>
        <th />
      </tr>
    );
  }

  _renderProviders() {
    return this.props.bookingDetailForMonthByProviderQuery.data
      .filter((data) => {
        if (
          this.state.filterProviderId &&
          data.provider_id !== this.state.filterProviderId
        ) {
          return false;
        }
        return true;
      })
      .map((data, index) => {
        const { id, provider_full_name } = data;
        return (
          <Row key={id} className="mb-4" xs={1}>
            <Col>
              <h4>{provider_full_name}</h4>
            </Col>
            <Col>
              <Table striped size="sm">
                {this._renderHeaderRow(id)}
                {this._renderRows(data.bookings)}
                <tfoot>{this._renderFooterRow(data.bookings, provider_full_name)}</tfoot>
              </Table>
            </Col>
          </Row>
        );
      });
  }

  render() {
    if (this.isLoading()) {
      return <Loader />;
    }
    return (
      <>
        <Row className="my-3">
          <Col>
            <ReportHeader
              title="Provider Flight Record Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
            locked
          />
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'filterProviderId',
              value: this.state.filterProviderId,
              onChange: this._handleDisplayProviderIdChange,
            }}
            asElement="select"
            selectOptions={this.props.providerListQuery.data.filter(
              (model) => this.state.filterProviderIds.indexOf(model.id) > -1
            )}
            optionKey="fullName"
            defaultSelectOptionName="All"
          />
        </Row>
        {this._renderProviders()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportProviderId: state.currentSettings.reportProviderId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
  };
}

export default compose(
  connect(mapStateToProps, { currentSettingsSet }),
  graphql(contactListQuery, {
    name: 'providerListQuery',
    options: { variables: { role: 'provider' } },
  }),
  graphql(bookingDetailForMonthByProviderQuery, {
    name: 'bookingDetailForMonthByProviderQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportProviderFlightRecord);
