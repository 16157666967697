import actionTypes from '../constants';

export function dataReset() {
  return {
    type: actionTypes.DATA_RESET,
  };
}

export function dataSet(payload) {
  return {
    type: actionTypes.DATA_SET,
    payload,
  };
}
