import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import makeClassNames from 'classnames';

function CardHeaderToggle({ children, eventKey, onClick, className }) {
  const newClassNames = makeClassNames('mb-2', 'p-2', className);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    if (onClick) {
      onClick(eventKey);
    }
  });

  return (
    <Card.Header className={newClassNames} onClick={decoratedOnClick}>
      {children}
    </Card.Header>
  );
}

export default CardHeaderToggle;
