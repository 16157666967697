import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';

import compact from 'lodash.compact';
import debounce from 'lodash.debounce';
import defaultTo from 'lodash.defaultto';
import first from 'lodash.first';
import get from 'lodash.get';
import has from 'lodash.has';

import InvalidBlock from '../form/invalid_block';
import ReactDateTimeField from '../form/react_date_time_field';
import InputField from '../form/input_field';

moment.updateLocale('en-nz');

class FlightSegmentEndDateLocationAndTimeInputFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      time: '',
      dateError: {},
      timeError: {},
      locationLongName: '',
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);

    this.handleLocationIdChange = this.handleLocationIdChange.bind(this);
    this.handleLocationIdBlur = this.handleLocationIdBlur.bind(this);
    this.handleLocationIdInputChange = this.handleLocationIdInputChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.end_at) {
      this.receiveEndAt();
      if (this.props.start_at) {
        this.normalizeEndAt();
      }
    }
    if (this.props.end_location_id) {
      this.receiveEndLocationId();
    }
    this.delayedHandleLocationIdChange = debounce(
      (locations) => {
        const locationId = get(first(locations), 'id', '');
        const currentLocationId = defaultTo(parseInt(this.props.end_location_id, 10), '');
        const newLocationId = defaultTo(parseInt(locationId, 10), '');
        if (currentLocationId !== newLocationId) {
          if (this.props.handleLocationChanged) {
            this.props.handleLocationChanged(this.props.index, locationId);
          }
        }
      },
      200,
      { leading: true, trailing: false }
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.end_at !== nextProps.end_at) {
      this.receiveEndAt(nextProps);
    }
    if (this.props.end_location_id !== nextProps.end_location_id) {
      this.receiveEndLocationId(nextProps);
    }
    if (nextProps.end_at && nextProps.start_at) {
      this.normalizeEndAt(nextProps, this.props);
    }
  }

  componentWillUnmount() {
    this.delayedHandleLocationIdChange.cancel();
  }

  handleLocationIdInputChange(locationLongName) {
    this.setState({
      locationLongName,
    });
  }

  handleLocationIdChange(locations) {
    if (locations.length === 0 || has(first(locations), 'id')) {
      this.delayedHandleLocationIdChange(locations);
    }
  }

  handleLocationIdBlur(e) {
    if (!this.props.end_location_id && e.target.value) {
      this.setState({
        locationLongName: '',
      });
    }
  }

  handleDateChange(newDate) {
    const date = moment(newDate);
    if (date.isValid()) {
      const formattedDate = date.format('DD/MM/YYYY');
      this.setState({
        date: formattedDate,
        dateError: {},
      });
      const { time, timeError } = this.state;
      if (!timeError.invalid) {
        const input = get(this.props, `${this.props.field}.end_at.input`);
        input.onChange(moment(`${formattedDate} ${time}`, 'DD/MM/YYYY HHmm').format());
      }
    } else {
      this.setState({
        date,
        dateError: { touched: true, invalid: true, error: 'DD/MM/YYYY' },
      });
    }
  }

  handleTimeChange(e) {
    let time = e.target.value;
    time = time === '2400' ? '2359' : time;
    if (time.match(/^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/)) {
      this.setState({
        time,
        timeError: {},
      });
      const { date, dateError } = this.state;
      if (!dateError.invalid) {
        const input = get(this.props, `${this.props.field}.end_at.input`);
        input.onChange(moment(`${date} ${time}`, 'DD/MM/YYYY HHmm').format());
      }
    } else if (
      time === '' &&
      this.props.loadedFlighttime &&
      this.props.loadedFlighttime > 0
    ) {
      const loadedFlighttimeDuration = parseInt(
        moment.duration(this.props.loadedFlighttime, 'minutes').asSeconds(),
        10
      );
      const endAt = moment(this.props.start_at).add(loadedFlighttimeDuration, 'seconds');
      this.setState({
        time: endAt.format('HHmm'),
        timeError: {},
      });
      const input = get(this.props, `${this.props.field}.end_at.input`);
      input.onChange(endAt.format());
    } else {
      this.setState({
        time,
        timeError: { touched: true, invalid: true, error: 'HHMM' },
      });
    }
  }

  getSelectableLocations() {
    return this.props.selectableLocations;
  }

  receiveEndLocationId(props = this.props) {
    let locationLongName = '';
    const locationId = defaultTo(parseInt(props.end_location_id, 10), '');
    if (locationId) {
      // we use long name as that matches the typeahead label return from server.
      locationLongName = get(this.props.locationsDataSelector, [locationId, 'longName']);
    }
    this.setState({
      locationLongName,
    });
  }

  receiveEndAt(props = this.props) {
    this.setState({
      date: moment(props.end_at).format('DD/MM/YYYY'),
      time: moment(props.end_at).format('HHmm'),
    });
  }

  normalizeFlightTime(mmStartAt, mmEndAt, loadedFlighttime) {
    if (loadedFlighttime && loadedFlighttime > 0) {
      const loadedFlighttimeDuration = parseInt(
        moment.duration(loadedFlighttime, 'minutes').asSeconds(),
        10
      );
      const currentFlighttimeDuration = parseInt(
        moment.duration(mmEndAt.diff(mmStartAt, 'minutes', true), 'minutes').asSeconds(),
        10
      );
      if (loadedFlighttimeDuration > currentFlighttimeDuration) {
        mmEndAt.add(loadedFlighttimeDuration - currentFlighttimeDuration, 'seconds');
        const input = get(this.props, `${this.props.field}.end_at.input`);
        input.onChange(mmEndAt.format());
      }
    }
  }

  normalizeEndAt(props = this.props, prevProps = {}) {
    const mmStartAt = moment(props.start_at);
    const mmEndAt = moment(props.end_at);
    const { loadedFlighttime } = props;
    if (mmStartAt.isBefore(mmEndAt)) {
      if (prevProps.start_at && prevProps.end_at) {
        if (prevProps.end_at === props.end_at) {
          const mmPrevStartAt = moment(prevProps.start_at);
          const mmPrevEndAt = moment(prevProps.end_at);
          const prevDayDiff = mmPrevEndAt.diff(mmPrevStartAt, 'days');
          const currentDayDiff = mmEndAt.diff(mmStartAt, 'days');
          if (currentDayDiff > prevDayDiff) {
            mmEndAt.subtract(currentDayDiff - prevDayDiff, 'days');
            const input = get(props, `${props.field}.end_at.input`);
            input.onChange(mmEndAt.format());
          } else {
            this.normalizeFlightTime(mmStartAt, mmEndAt, loadedFlighttime);
          }
        } else {
          this.normalizeFlightTime(mmStartAt, mmEndAt, loadedFlighttime);
        }
      } else {
        this.normalizeFlightTime(mmStartAt, mmEndAt, loadedFlighttime);
      }
    } else if (mmStartAt.isAfter(mmEndAt)) {
      if (mmStartAt.isAfter(mmEndAt, 'day')) {
        mmEndAt.set({
          year: mmStartAt.year(),
          month: mmStartAt.month(),
          date: mmStartAt.date(),
        });
      }
      if (mmStartAt.isAfter(mmEndAt, 'second')) {
        mmEndAt.set({
          hour: mmStartAt.hour(),
          minute: mmStartAt.minute(),
          second: mmStartAt.second(),
        });
      }
      if (loadedFlighttime && loadedFlighttime > 0) {
        const loadedFlighttimeDuration = parseInt(
          moment.duration(loadedFlighttime, 'minutes').asSeconds(),
          10
        );
        const currentFlighttimeDuration = parseInt(
          moment
            .duration(mmEndAt.diff(mmStartAt, 'minutes', true), 'minutes')
            .asSeconds(),
          10
        );
        if (loadedFlighttimeDuration > currentFlighttimeDuration) {
          mmEndAt.add(loadedFlighttimeDuration - currentFlighttimeDuration, 'seconds');
        }
      }
      const input = get(this.props, `${this.props.field}.end_at.input`);
      input.onChange(mmEndAt.format());
    } else {
      this.normalizeFlightTime(mmStartAt, mmEndAt, loadedFlighttime);
    }
  }

  render() {
    const {
      end_at: { input: endAtInput },
      end_location_id: { input: endLocationIdInput, meta: endLocationIdMeta },
    } = get(this.props, this.props.field);

    return (
      <Row>
        <Col xs={4} className="pe-1">
          <ReactDateTimeField
            size="sm"
            dateFormat="DD/MM/YYYY"
            closeOnSelect
            input={{
              ...endAtInput,
              name: `${endAtInput.name}-date`,
              onChange: this.handleDateChange,
            }}
            labelWidth={0}
            inputWidth={12}
            inputProps={{ tabIndex: '-1' }}
            groupClassName="p-0"
          />
        </Col>
        <Col xs={5} className="px-1">
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={12}
            input={{
              name: endLocationIdInput.name,
            }}
            groupClassName="p-0"
            innerContent={
              <>
                <Typeahead
                  id={`${endLocationIdInput.name}-id`}
                  onInputChange={this.handleLocationIdInputChange}
                  onChange={this.handleLocationIdChange}
                  onBlur={this.handleLocationIdBlur}
                  options={this.getSelectableLocations()}
                  selected={compact([this.state.locationLongName])}
                  filterBy={['searchable_name']}
                  labelKey="name"
                  minLength={2}
                  size="sm"
                  placeholder="End Location..."
                />
                <InvalidBlock meta={endLocationIdMeta} force />
              </>
            }
          />
        </Col>
        <Col xs={3} className="px-1">
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={12}
            groupClassName="p-0"
            input={{
              name: `${endAtInput.name}-time`,
              value: this.state.time,
              onChange: this.handleTimeChange,
            }}
            meta={{ ...this.state.timeError }}
            {...(this.props.end_critical && {
              inputProps: { style: { backgroundColor: 'yellow' } },
            })}
          />
        </Col>
      </Row>
    );
  }
}

export default FlightSegmentEndDateLocationAndTimeInputFields;
