import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import accounting from 'accounting';
import { connect } from 'react-redux';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import {
  mutationSet,
  // mutationFailure,
} from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady, getExport } from '../lib/utils';

import pilotDutyRecordAccumulationListQuery from '../queries/pilot_duty_record_accumulation_list_query';
import pilotFlightLogAccumulationListQuery from '../queries/pilot_flight_log_accumulation_list_query';
import contactListQuery from '../queries/contact_list_query';

const moment = extendMoment(Moment);
moment.updateLocale('en-nz');

class ReportPilotCumulativeHour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPilotId: this.props.currentSettingsReportPilotId,
      filterPilotIds: [],
    };
    this.handleDisplayPilotIdChange = this.handleDisplayPilotIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.pilotId) {
      this.handleDisplayPilotIdChange({
        target: { value: parseInt(this.props.params.pilotId, 10) },
      });
    }
    if (
      !moment(this.props.currentSettingsReportStart).isSame(
        moment(this.props.currentSettingsReportStart).startOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportStart: moment(this.props.currentSettingsReportStart)
          .startOf('month')
          .toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportEnd).isSame(
        moment(this.props.currentSettingsReportStart).endOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportEnd: moment(this.props.currentSettingsReportStart)
          .endOf('month')
          .toISOString(),
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce(() => {
      this.props.pilotDutyRecordAccumulationListQuery.refetch();
      this.props.pilotFlightLogAccumulationListQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterPilotIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterPilotIds = nextProps.pilotDutyRecordAccumulationListQuery.data.map(
        (data) => data.pilot_id
      );
    }
    this.setState({
      filterPilotIds,
    });
    let filterPilotId = nextProps.currentSettingsReportPilotId;
    if (filterPilotId && this.isLoaded()) {
      if (filterPilotIds.length > 0 && filterPilotIds.indexOf(filterPilotId) === -1) {
        filterPilotId = '';
      }
    }
    if (filterPilotId !== this.props.currentSettingsReportPilotId) {
      this.handleDisplayPilotIdChange({ target: { value: filterPilotId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      pilotId: this.props.currentSettingsReportPilotId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  sumSlice = (slice) =>
    accounting.toFixed(
      slice.reduce((sum, value) => sum + value, 0),
      2
    );

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.pilotDutyRecordAccumulationListQuery,
      testProps.pilotFlightLogAccumulationListQuery,
      testProps.pilotListQuery
    );
  }

  handleDisplayPilotIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterPilotId: value,
    });
    this.props.currentSettingsSet({
      reportPilotId: value,
    });
  }

  renderRow(day, index, dutyTotals, logTotals) {
    return (
      <tr key={index}>
        <td>{day.format('DD/MM/YYYY')}</td>
        <td className="text-center">{logTotals[index]}</td>
        <td className="text-center">
          {this.sumSlice(logTotals.slice(index, index + 7))}
        </td>
        <td className="text-center">
          {this.sumSlice(logTotals.slice(index, index + 28))}
        </td>
        <td className="text-center">
          {this.sumSlice(logTotals.slice(index, index + 90))}
        </td>
        <td className="text-center">
          {this.sumSlice(logTotals.slice(index, index + 365))}
        </td>
        <td
          style={{ borderTop: 'none', borderBottom: 'none', backgroundColor: '#fcfcfc' }}
        >
          &nbsp;
        </td>
        <td>{day.format('DD/MM/YYYY')}</td>
        <td className="text-center">{dutyTotals[index]}</td>
        <td className="text-center">
          {this.sumSlice(dutyTotals.slice(index, index + 7))}
        </td>
        <td className="text-center">
          {this.sumSlice(dutyTotals.slice(index, index + 30))}
        </td>
        <td className="text-center">
          {this.sumSlice(dutyTotals.slice(index, index + 90))}
        </td>
        <td className="text-center">
          {this.sumSlice(dutyTotals.slice(index, index + 365))}
        </td>
      </tr>
    );
  }

  renderPilotCumulativeHours(range, dutyTotals, logTotals) {
    return (
      <tbody>
        {range.map((day, index) => this.renderRow(day, index, dutyTotals, logTotals))}
      </tbody>
    );
  }

  renderHeaderRowA = () => (
    <thead>
      <tr>
        <th colSpan={6} className="text-center border-top-0">
          Flying Hours
        </th>
        <th style={{ borderBottom: 'none' }}>&nbsp;</th>
        <th colSpan={6} className="text-center border-top-0">
          Duty Hours
        </th>
      </tr>
    </thead>
  );

  renderHeaderRowB = () => (
    <thead>
      <tr>
        <th className="text-start" style={{ borderTop: 'none' }}>
          Date
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          1
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          7
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          28
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          90
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          365
        </th>
        <th className="text-center" style={{ borderTop: 'none', borderBottom: 'none' }}>
          &nbsp;
        </th>
        <th className="text-start" style={{ borderTop: 'none' }}>
          Date
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          1
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          7
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          30
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          90
        </th>
        <th className="text-center" style={{ borderTop: 'none' }}>
          365
        </th>
      </tr>
    </thead>
  );

  renderPilots() {
    const MMRange = moment(this.props.currentSettingsReportStart).range('month');
    const range = Array.from(MMRange.reverseBy('day'));
    return this.props.pilotDutyRecordAccumulationListQuery.data
      .filter((data) => {
        if (this.state.filterPilotId && data.pilot_id !== this.state.filterPilotId) {
          return false;
        }
        return true;
      })
      .map((data) => {
        const pilot = this.props.pilotListQuery.data.find(
          (model) => model.id === data.pilot_id
        );
        if (pilot) {
          const pilotFullName = pilot.fullName;
          const dutyTotals = data ? data.duty_totals : [];
          const log = this.props.pilotFlightLogAccumulationListQuery.data.find(
            (logs) => logs.pilot_id === data.pilot_id
          );
          const logTotals = log ? log.log_totals : [];
          return (
            <Row key={data.pilot_id} className="mb-4" sm={1}>
              <Col>
                <h4>{pilotFullName}</h4>
              </Col>
              <Col>
                <Table striped size="sm">
                  {this.renderHeaderRowA()}
                  {this.renderHeaderRowB()}
                  {dutyTotals.length > 0 &&
                    logTotals.length > 0 &&
                    this.renderPilotCumulativeHours(range, dutyTotals, logTotals)}
                </Table>
              </Col>
            </Row>
          );
        }
        return undefined;
      });
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <>
        <Row className="my-3">
          <Col className="d-flex justify-content-between align-items-start">
            <ReportHeader
              title="Cumulative Hours Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
            <Button
              variant="primary"
              data-report-name="pilot_cumulative_hours"
              onClick={this.getExport}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
            locked
          />
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'filterPilotId',
              value: this.state.filterPilotId,
              onChange: this.handleDisplayPilotIdChange,
            }}
            asElement="select"
            selectOptions={this.props.pilotListQuery.data.filter(
              (model) => this.state.filterPilotIds.indexOf(model.id) > -1
            )}
            optionKey="fullName"
            defaultSelectOptionName="All"
          />
        </Row>
        {this.renderPilots()}
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportPilotId: state.currentSettings.reportPilotId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, { currentSettingsSet, mutationSet }),
  graphql(contactListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(pilotDutyRecordAccumulationListQuery, {
    name: 'pilotDutyRecordAccumulationListQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(pilotFlightLogAccumulationListQuery, {
    name: 'pilotFlightLogAccumulationListQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportPilotCumulativeHour);
