import { Row, Col } from 'react-bootstrap';
import get from 'lodash.get';

import CheckboxInputField from '../form/checkbox_input_field';
import InputField from '../form/input_field';
import InvalidBlock from '../form/invalid_block';

const FlightSignoffFormPilotFlightExpenseFields = (props) => {
  const { contactsDataSelector, formValues, parentField, overNight } = props;
  const { pilot_id: pilotId } = formValues;
  const {
    notes: { input: notesInput, meta: notesMeta },
    total: { input: totalInput, meta: totalMeta },
    includes_gst: { input: includesGstInput, meta: includesGstMeta },
    onchargeable: { input: onchargeableInput, meta: onchargeableMeta },
    reimbursable: { input: reimbursableInput, meta: reimbursableMeta },
  } = get(props, parentField);

  const renderControlLabel = () => get(contactsDataSelector, [pilotId, 'fullName']);

  return (
    <InputField
      size="sm"
      labelWidth={3}
      inputWidth={9}
      input={{}}
      innerContent={
        <>
          <Row>
            <Col sm={3}>
              <InputField
                controlOnly
                blurOnly
                type="text"
                input={totalInput}
                meta={totalMeta}
                size="sm"
              />
            </Col>
            <Col sm={9}>
              <InputField
                controlOnly
                blurOnly
                type="text"
                input={notesInput}
                meta={notesMeta}
                size="sm"
                readOnly={overNight}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <CheckboxInputField
                innerContentOnly
                size="sm"
                input={onchargeableInput}
                meta={onchargeableMeta}
              >
                Oncharge
              </CheckboxInputField>
            </Col>
            <Col sm={3}>
              <CheckboxInputField
                innerContentOnly
                size="sm"
                input={reimbursableInput}
                meta={reimbursableMeta}
              >
                Reimburse
              </CheckboxInputField>
            </Col>
            <Col sm={3}>
              <CheckboxInputField
                innerContentOnly
                size="sm"
                input={includesGstInput}
                meta={includesGstMeta}
              >
                GST
              </CheckboxInputField>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <InvalidBlock meta={notesMeta} force />
              <InvalidBlock meta={totalMeta} force />
            </Col>
          </Row>
        </>
      }
    >
      {renderControlLabel()}
    </InputField>
  );
};

export default FlightSignoffFormPilotFlightExpenseFields;
