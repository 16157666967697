import get from 'lodash.get';
import memoize from 'lodash.memoize';

export const AircraftTypeWeightDataMemo = memoize(
  ({ aircraftTypeId, aircraftTypes }) => {
    if (aircraftTypeId) {
      const aircraftType = aircraftTypes.find(
        (at) => at.id === parseInt(aircraftTypeId, 10)
      );
      return {
        aircraftTypeWeightUnit: get(aircraftType, 'weight_unit', 0),
        aircraftTypeArmUnit: get(aircraftType, 'arm_unit', 0),
        aircraftTypeMaximumAllUpWeight: get(aircraftType, 'maximum_all_up_weight', 0),
        aircraftTypeMaximumAllUpWeightWithExternalLoad: get(
          aircraftType,
          'maximum_all_up_weight_with_external_load',
          0
        ),
      };
    }
    return {};
  },
  ({ aircraftTypeId }) => {
    if (aircraftTypeId) {
      return [aircraftTypeId].map((i) => String(i)).join('.');
    }
    return 'unknown';
  }
);
