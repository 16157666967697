import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import accounting from 'accounting';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';
import CheckboxInputField from '../components/form/checkbox_input_field';

import { queriesReady, getExport } from '../lib/utils';

import pilotFlightExpenseDetailForMonthByPilotQuery from '../queries/pilot_flight_expense_detail_for_month_by_pilot_query';
import contactListQuery from '../queries/contact_list_query';

class ReportPilotFlightExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPilotId: this.props.currentSettingsReportPilotId,
      filterPilotIds: [],
      overnight: false,
      onchargeable: false,
      reimbursable: false,
    };
    this.handleDisplayPilotIdChange = this.handleDisplayPilotIdChange.bind(this);
    this.handleOvernightFilterChecked = this.handleOvernightFilterChecked.bind(this);
    this.handleOnchargeableFilterChecked =
      this.handleOnchargeableFilterChecked.bind(this);
    this.handleReimbursableFilterChecked =
      this.handleReimbursableFilterChecked.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.pilotId) {
      this.handleDisplayPilotIdChange({
        target: {
          value: parseInt(this.props.params.pilotId, 10),
        },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce(() => {
      this.props.pilotFlightExpenseDetailForMonthByPilotQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterPilotIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterPilotIds = nextProps.pilotFlightExpenseDetailForMonthByPilotQuery.data.map(
        (data) => data.pilot_id
      );
    }
    this.setState({
      filterPilotIds,
    });
    let filterPilotId = nextProps.currentSettingsReportPilotId;
    if (filterPilotId && this.isLoaded(nextProps)) {
      if (filterPilotIds.length > 0 && filterPilotIds.indexOf(filterPilotId) === -1) {
        filterPilotId = '';
      }
    }
    if (filterPilotId !== this.props.currentSettingsReportPilotId) {
      this.handleDisplayPilotIdChange({ target: { value: filterPilotId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      pilotId: this.props.currentSettingsReportPilotId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.pilotFlightExpenseDetailForMonthByPilotQuery,
      testProps.pilotListQuery
    );
  }

  handleDisplayPilotIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterPilotId: value,
    });
    this.props.currentSettingsSet({
      reportPilotId: value,
    });
  }

  handleOvernightFilterChecked() {
    this.setState((prevState) => ({ overnight: !prevState.overnight }));
  }

  handleOnchargeableFilterChecked() {
    this.setState((prevState) => ({ onchargeable: !prevState.onchargeable }));
  }

  handleReimbursableFilterChecked() {
    this.setState((prevState) => ({ reimbursable: !prevState.reimbursable }));
  }

  renderRow = (pilotFlightExpense) => {
    const {
      id,
      booking_id: bookingId,
      booking_reference: bookingReference,
      booking_start_at_s: bookingStartAtS,
      notes,
      reimbursable,
      onchargeable,
      chargeable_full_names: chargeableFullNames,
      total_excl_gst: totalExclGst,
    } = pilotFlightExpense;

    return (
      <tr key={id}>
        <td>
          <LinkContainer to={`/flights/${bookingId}/edit`} className="ps-0">
            <Button variant="link" size="sm" className="text-start p-0 m-0">
              {`#${bookingReference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{bookingStartAtS}</td>
        <td>{notes}</td>
        <td>{reimbursable ? 'yes' : 'no'}</td>
        <td>{onchargeable ? 'yes' : 'no'}</td>
        <td>{chargeableFullNames}</td>
        <td className="text-end">{accounting.formatMoney(totalExclGst)}</td>
      </tr>
    );
  };

  renderRows(pilotFlightExpenses) {
    return (
      <tbody>
        {pilotFlightExpenses.map((pilotFlightExpense) =>
          this.renderRow(pilotFlightExpense)
        )}
      </tbody>
    );
  }

  renderHeaderRow = () => (
    <thead>
      <tr>
        <th className="border-top-0">Reference</th>
        <th className="border-top-0">Date</th>
        <th className="border-top-0">Expense</th>
        <th className="border-top-0">Reimburse?</th>
        <th className="border-top-0">Oncharge?</th>
        <th className="border-top-0">Customer</th>
        <th className="text-end border-top-0">Excl. Gst</th>
      </tr>
    </thead>
  );

  renderFooterRow = (pilotFlightExpenses, pilotFullName) => {
    const total = pilotFlightExpenses.reduce(
      (sum, expense) => sum + expense.total_excl_gst,
      0
    );
    return (
      <tfoot>
        <tr>
          <th colSpan={5} />
          <th className="text-end">{`Total expenses for ${pilotFullName}:`}</th>
          <th className="text-end">{accounting.formatMoney(total)}</th>
        </tr>
      </tfoot>
    );
  };

  renderPilots() {
    return this.props.pilotFlightExpenseDetailForMonthByPilotQuery.data
      .filter((data) => {
        if (this.state.filterPilotId && data.pilot_id !== this.state.filterPilotId) {
          return false;
        }
        return true;
      })
      .map((data) => {
        const pilot = this.props.pilotListQuery.data.find(
          (model) => model.id === data.pilot_id
        );
        if (pilot) {
          const pilotFullName = pilot.fullName;
          const filteredExpenses = data.pilot_flight_expenses.filter((pfe) => {
            if (this.state.overnight && !pfe.overnight) {
              return false;
            }
            if (this.state.onchargeable && !pfe.onchargeable) {
              return false;
            }
            if (this.state.reimbursable && !pfe.reimbursable) {
              return false;
            }
            return true;
          });
          return (
            <Row key={data.pilot_id} xs={1} className="mb-4">
              <Col>
                <h4>{pilotFullName}</h4>
              </Col>
              <Col>
                <Table striped size="sm">
                  {this.renderHeaderRow()}
                  {this.renderRows(filteredExpenses)}
                  {this.renderFooterRow(filteredExpenses, pilotFullName)}
                </Table>
              </Col>
            </Row>
          );
        } else {
          return '';
        }
      });
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <>
        <Row className="my-3">
          <Col className="d-flex justify-content-between align-items-start">
            <ReportHeader
              title="Flight Expense Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
            <Button
              variant="primary"
              data-report-name="pilot_flight_expense_detail_for_month_by_pilot"
              onClick={this.getExport}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
          />
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'filterPilotId',
              value: this.state.filterPilotId,
              onChange: this.handleDisplayPilotIdChange,
            }}
            asElement="select"
            selectOptions={this.props.pilotListQuery.data.filter(
              (model) => this.state.filterPilotIds.indexOf(model.id) > -1
            )}
            optionKey="fullName"
            defaultSelectOptionName="All"
          />
          <CheckboxInputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'only_overnight',
              value: this.state.overnight,
              onChange: this.handleOvernightFilterChecked,
            }}
          >
            Only Overnight
          </CheckboxInputField>
          <CheckboxInputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'only_onchargeable',
              value: this.state.onchargeable,
              onChange: this.handleOnchargeableFilterChecked,
            }}
          >
            Only Onchargeable
          </CheckboxInputField>
          <CheckboxInputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'only_reimbursable',
              value: this.state.reimbursable,
              onChange: this.handleReimbursableFilterChecked,
            }}
          >
            Only Reimbursable
          </CheckboxInputField>
        </Row>
        {this.renderPilots()}
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportPilotId: state.currentSettings.reportPilotId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationFailure,
    mutationSet,
  }),
  graphql(contactListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(pilotFlightExpenseDetailForMonthByPilotQuery, {
    name: 'pilotFlightExpenseDetailForMonthByPilotQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportPilotFlightExpense);
