import accounting from 'accounting';

import get from 'lodash.get';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const plotName = get(payload, '0.payload.name');
    return (
      <div
        style={{
          padding: '1rem',
          border: '1px solid black',
          backgroundColor: 'white',
          opacity: 1,
        }}
      >
        {plotName && (
          <p>
            <strong>{plotName}</strong>
          </p>
        )}
        {payload.map((p) => (
          <p key={p.name}>{`${p.name}: ${accounting.toFixed(p.value, 4)}`}</p>
        ))}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
