import { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Draggable } from '@fullcalendar/interaction';
import DateTime from 'react-datetime';
import objectHash from 'object-hash';
import moment from 'moment';
import { kebabCase } from 'change-case';

import first from 'lodash.first';
import flatten from 'lodash.flatten';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import last from 'lodash.last';
import omit from 'lodash.omit';
import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';

import DutyEventModal from './duty_event_modal';
import CopyDutyEventsModal from './copy_duty_events_modal';
import DeleteDutyEventsModal from './delete_duty_events_modal';
import TemplateDutyEventsModal from './template_duty_events_modal';
import ScrollableFullCalendar from './scrollable_full_calendar';

import { getContrastYIQ } from '../../lib/utils';

moment.updateLocale('en-nz');

function Roster(props) {
  const {
    isEditor,
    powerEdit,
    blockedOutEvents = [],
    publicHolidayEvents = [],
    weekendEvents = [],
    rosteredEvents = [],
    publishedThruEvents = [],
    roster: {
      id: rosterId,
      name: rosterName,
      description,
      rosterTemplates,
      contactRosters,
      dutyTemplates,
      lastPublisher,
      // published_at: publishedAt,
      published_thru: originalPublishedThru,
    },
    dutyEvents,
    currentContact,
    currentSettingsDutyEventCollectionStartDate,
    currentSettingsDutyEventCollectionEndDate,
    dispatchDutyEventCollectionVars,
    handleRosterPublish: parentHandleRosterPublish,
    handleDutyEventCreate,
    handleDutyEventUpdate,
    handleDutyEventDelete,
  } = props;

  const ROSTER_HTML_ID = `roster-${rosterId}`;
  const ROSTER_CALENDAR_HTML_ID = `${ROSTER_HTML_ID}-calendar`;
  const ROSTER_POPOVERS_HTML_ID = `${ROSTER_HTML_ID}-popovers`;
  const ROSTER_DRAGGABLES_HTML_ID = `${ROSTER_HTML_ID}-duty-templates`;

  const [publishedThru, setPublishedThru] = useState('');
  const [dutyEventModalState, setDutyEventModalState] = useState({
    show: false,
    rosterId,
  });
  const [showCopyDutyEventsModal, setShowCopyDutyEventsModal] = useState(false);
  const [showDeleteDutyEventsModal, setShowDeleteDutyEventsModal] = useState(false);
  const [showTemplateDutyEventsModal, setShowTemplateDutyEventsModal] = useState(false);

  const activeSortedRosterTemplates = useMemo(() => {
    if (rosterTemplates) {
      return rosterTemplates
        .filter((rt) => rt.active)
        .sort((a, b) => a.position - b.position);
    }
    return [];
  }, [rosterTemplates]);

  const groupedDutyTemplates = useMemo(() => {
    if (dutyTemplates) {
      return groupBy(
        dutyTemplates.map((dutyTemplate) => {
          const {
            id,
            position,
            name,
            short_name: shortName,
            duty_color: dutyColor,
          } = dutyTemplate;
          return {
            id,
            name,
            shortName,
            dutyColor,
            position,
          };
        }),
        'position'
      );
    }
    return {};
  }, [dutyTemplates]);

  const calendarResources = useMemo(() => {
    if (contactRosters) {
      const resources = contactRosters.map((contact) => {
        const {
          id: contactRosterId,
          position,
          contact: { id: contactId, fullName },
        } = contact;
        const paddedPosition = `${position}`.padStart(2, '0');
        return {
          id: kebabCase(fullName),
          title: fullName,
          extendedProps: {
            contactId,
            contactRosterId,
            position: paddedPosition,
          },
        };
      });
      return sortBy(resources, ['extendedProps.position', 'title']);
    }
    return [];
  }, [contactRosters]);

  const hashedCalendarEvents = useMemo(() => {
    const events = [
      ...rosteredEvents,
      ...blockedOutEvents,
      ...weekendEvents,
      ...publicHolidayEvents,
      ...publishedThruEvents,
    ].map((event) => {
      // force the calendar to mount/dismount mutated events by changing id
      const hash = objectHash(event);
      return {
        ...event,
        id: `${event.id}-${hash}`,
      };
    });
    return events;
  }, [
    rosteredEvents,
    blockedOutEvents,
    weekendEvents,
    publicHolidayEvents,
    publishedThruEvents,
  ]);

  useEffect(() => {
    let newPublishedThru = '';
    if (originalPublishedThru) {
      newPublishedThru = moment(originalPublishedThru).format('DD/MM/YYYY');
    }
    setPublishedThru(newPublishedThru);
  }, [originalPublishedThru]);

  // make dutyTemplates draggable
  useEffect(() => {
    let draggable;
    const draggableEl = document.getElementById(ROSTER_DRAGGABLES_HTML_ID);
    if (draggableEl && dutyTemplates) {
      /* eslint-disable no-new */
      draggable = new Draggable(draggableEl, {
        itemSelector: '.fc-event',
        eventData: (event) => {
          const id = parseInt(event.getAttribute('data-id'), 10);
          const title = event.getAttribute('title');
          const color = event.getAttribute('data-color');
          return {
            id,
            title,
            color,
            create: true, // this is the default, add to a dragged external event will fire the relevent callbacks
          };
        },
      });
    }
    return () => {
      if (draggable) {
        draggable.destroy();
      }
    };
  }, [ROSTER_DRAGGABLES_HTML_ID, dutyTemplates]);

  const hidePopovers = useCallback(() => {
    // during drag and drops tooltips can get stuck
    const calendarEl = document.getElementById(ROSTER_POPOVERS_HTML_ID);
    const tooltips = calendarEl.querySelectorAll('[role="tooltip"]');
    tooltips.forEach((tooltip) => {
      tooltip.remove();
    });
  }, [ROSTER_POPOVERS_HTML_ID]);

  const handleRosterPublish = useCallback(() => {
    const newPublishedThru = moment(publishedThru, 'DD/MM/YYYY')
      .endOf('day')
      .utc()
      .format();
    const newPublishedAt = moment.utc().format();
    const newLastPublisherId = currentContact.id;
    const publishedRoster = {
      id: rosterId,
      published_at: newPublishedAt,
      published_thru: newPublishedThru,
      last_publisher_id: newLastPublisherId,
    };
    parentHandleRosterPublish(publishedRoster);
  }, [rosterId, currentContact, publishedThru, parentHandleRosterPublish]);

  const handleCopyDutyEventsModalClick = useCallback(() => {
    setShowCopyDutyEventsModal(true);
  }, []);

  const handleCopyDutyEventsModalCancel = useCallback(() => {
    setShowCopyDutyEventsModal(false);
  }, []);

  const handleCopyDutyEventsModalSubmit = useCallback(
    async (data) => {
      setShowCopyDutyEventsModal(false);
      const { copyFrom, copyThru, pasteFrom, contacts: copyableContacts } = data;
      const copyableContactIds = copyableContacts
        .filter((contact) => contact.copy)
        .map(({ id }) => id);
      if (copyableContactIds.length > 0) {
        const start = moment(copyFrom).startOf('day').utc().format();
        const end = moment(copyThru).add(1, 'day').startOf('day').utc().format();
        const copyableDutyEvents = dutyEvents.filter(
          (de) =>
            copyableContactIds.includes(de.contact_id) &&
            moment(de.start_at).isBefore(end) &&
            moment(de.end_at).isAfter(start)
        );
        if (copyableDutyEvents.length > 0) {
          const diffInDays = moment(pasteFrom).diff(moment(copyFrom), 'days');
          const copiedDutyEventsPromises = copyableDutyEvents.map(
            async (copyableDutyEvent) => {
              const { start_at: copyableStartAt, end_at: copyableEndAt } =
                copyableDutyEvent;
              const copiedDutyEvent = {
                ...omit(copyableDutyEvent, ['__typename', 'id', 'contact']),
                start_at: moment(copyableStartAt).add(diffInDays, 'days').utc().format(),
                end_at: moment(copyableEndAt).add(diffInDays, 'days').utc().format(),
                published_snapshot: omit(copyableDutyEvent.published_snapshot, [
                  '__typename',
                ]),
              };
              handleDutyEventCreate(copiedDutyEvent);
            }
          );
          await Promise.all(copiedDutyEventsPromises);
        }
      }
    },
    [dutyEvents, handleDutyEventCreate]
  );

  const handleDeleteDutyEventsModalClick = useCallback(() => {
    setShowDeleteDutyEventsModal(true);
  }, []);

  const handleDeleteDutyEventsModalCancel = useCallback(() => {
    setShowDeleteDutyEventsModal(false);
  }, []);

  const handleDeleteDutyEventsModalSubmit = useCallback(
    async (data) => {
      setShowDeleteDutyEventsModal(false);
      const { deleteFrom, deleteThru, contacts: deletableContacts } = data;
      const deletableContactIds = deletableContacts
        .filter((contact) => contact.delete)
        .map(({ id }) => id);
      if (deletableContactIds.length > 0) {
        const start = moment(deleteFrom).startOf('day').utc().format();
        const end = moment(deleteThru).add(1, 'day').startOf('day').utc().format();
        const deletableDutyEvents = dutyEvents.filter(
          (de) =>
            deletableContactIds.includes(de.contact_id) &&
            moment(de.start_at).isBefore(end) &&
            moment(de.end_at).isAfter(start)
        );
        if (deletableDutyEvents.length > 0) {
          const deletableDutyEventsPromises = deletableDutyEvents.map(
            async (deletableDutyEvent) => {
              handleDutyEventDelete(deletableDutyEvent.id);
            }
          );
          await Promise.all(deletableDutyEventsPromises);
        }
      }
    },
    [dutyEvents, handleDutyEventDelete]
  );

  const handleTemplateDutyEventsModalClick = useCallback(() => {
    setShowTemplateDutyEventsModal(true);
  }, []);

  const handleTemplateDutyEventsModalCancel = useCallback(() => {
    setShowTemplateDutyEventsModal(false);
  }, []);

  const handleTemplateDutyEventsModalSubmit = useCallback(
    async (data) => {
      setShowTemplateDutyEventsModal(false);
      const {
        rosterTemplateId,
        templateFrom,
        templateThru,
        contacts: templatableContacts,
      } = data;
      const templatableContactIds = templatableContacts
        .filter((contact) => contact.template)
        .map(({ id }) => id);
      if (templatableContactIds.length > 0) {
        let start = moment(templateFrom).startOf('day').utc();
        const end = moment(templateThru).add(1, 'day').startOf('day').utc();
        const rosterTemplate = rosterTemplates.find(
          (rt) => rt.id === parseInt(rosterTemplateId, 10)
        );
        if (rosterTemplate) {
          const { rosterTemplateDutyTemplates } = rosterTemplate;
          const templatableDutyTemplates = rosterTemplateDutyTemplates
            .filter((rtdt) => rtdt.active)
            .sort((a, b) => a.position - b.position)
            .map((rtdt) => {
              const { duty_template_id: dutyTemplateId, length: rosterTemplateLength } =
                rtdt;
              const dutyTemplate = dutyTemplates.find((dt) => dt.id === dutyTemplateId);
              return {
                ...dutyTemplate,
                rosterTemplateLength,
              };
            })
            .filter((dt) => dt);
          let templatableDutyEvents = [];
          while (start.isBefore(end)) {
            let mapStart = start.clone();
            const chunkTemplatableDutyEvents = templatableDutyTemplates.map(
              (dutyTemplate) => {
                const {
                  id: dutyTemplateId,
                  name: dutyTemplateName,
                  short_name: shortName,
                  description: dutyTemplateDescription,
                  duty_color: dutyColor,
                  fixed_time: fixedTime,
                  contact_available: contactAvailable,
                  start_time: startTime,
                  end_time: endTime,
                  rosterTemplateLength,
                } = dutyTemplate;
                const startDate = mapStart.format();
                const endDate = mapStart
                  .clone()
                  .add(rosterTemplateLength, 'days')
                  .format();
                mapStart = moment(endDate).utc();
                const newDutyEvents = templatableContactIds.map((contactId) => ({
                  duty_template_id: dutyTemplateId,
                  roster_id: rosterId,
                  contact_id: contactId,
                  name: dutyTemplateName,
                  short_name: shortName,
                  description: dutyTemplateDescription,
                  duty_color: dutyColor,
                  fixed_time: fixedTime,
                  contact_available: contactAvailable,
                  start_time: startTime,
                  end_time: endTime,
                  start_at: startDate,
                  end_at: endDate,
                  // are not setting publish
                }));
                return newDutyEvents;
              }
            );
            start = mapStart.clone();
            templatableDutyEvents = [
              ...templatableDutyEvents,
              ...flatten(chunkTemplatableDutyEvents),
            ];
          }
          templatableDutyEvents = templatableDutyEvents.filter((de) =>
            moment(de.start_at).isBefore(end)
          );
          if (templatableDutyEvents.length > 0) {
            const templatableDutyEventsPromises = templatableDutyEvents.map(
              async (templatableDutyEvent) => {
                handleDutyEventCreate(templatableDutyEvent);
              }
            );
            await Promise.all(templatableDutyEventsPromises);
          }
        }
      }
    },
    [rosterId, rosterTemplates, dutyTemplates, handleDutyEventCreate]
  );

  const handleDutyEventModalCancel = useCallback(() => {
    setDutyEventModalState({
      show: false,
      rosterId,
    });
  }, [rosterId]);

  const handleDutyEventModalDelete = useCallback(() => {
    const dutyEventId = get(dutyEventModalState, 'dutyEvent.id');
    setDutyEventModalState({
      show: false,
      rosterId,
    });
    handleDutyEventDelete(dutyEventId);
  }, [rosterId, dutyEventModalState, handleDutyEventDelete]);

  const handleDutyEventModalUpdate = useCallback(
    ({ startTime, endTime, dutyNotes, published, managerNotes }) => {
      const dutyEventId = get(dutyEventModalState, 'dutyEvent.id');
      setDutyEventModalState({
        show: false,
        rosterId,
      });
      const updatedDutyEvent = {
        id: dutyEventId,
        start_time: startTime,
        end_time: endTime,
        duty_notes: dutyNotes,
        manager_notes: managerNotes,
        published,
      };
      handleDutyEventUpdate(updatedDutyEvent);
    },
    [rosterId, dutyEventModalState, handleDutyEventUpdate]
  );

  const handleDutyEventAdjustments = useCallback(
    (existingDutyEvents, start, end, deleteableDutyEventIds = []) => {
      existingDutyEvents.forEach((existingDutyEvent) => {
        // console.log({ index });
        const {
          id: existingDutyEventId,
          start_at: existingStartAt,
          end_at: existingEndAt,
        } = existingDutyEvent;
        // console.log({
        //   existingDutyEventId,
        //   existingStartAt,
        //   start,
        //   existingEndAt,
        //   end,
        // });
        if (moment(existingStartAt).isSame(start)) {
          // console.log('same start');
          if (moment(existingEndAt).isSame(end)) {
            // console.log('same start and end');
            handleDutyEventDelete(existingDutyEventId);
          } else if (moment(existingEndAt).isBefore(end)) {
            // console.log('same start and end is before new end');
            handleDutyEventDelete(existingDutyEventId);
          } else {
            // console.log('same start different end');
            const updatedDutyEvent = {
              id: existingDutyEventId,
              start_at: end,
            };
            handleDutyEventUpdate(updatedDutyEvent);
          }
        } else if (moment(existingEndAt).isSame(end)) {
          // console.log('same end');
          if (moment(existingStartAt).isAfter(start)) {
            // console.log('start after new start and same end');
            handleDutyEventDelete(existingDutyEventId);
          } else {
            // console.log('existing start before new start and same end');
            const updatedDutyEvent = {
              id: existingDutyEventId,
              end_at: start,
            };
            // console.log({ updatedDutyEvent });
            handleDutyEventUpdate(updatedDutyEvent);
          }
        } else if (moment(existingStartAt).isBefore(start)) {
          // console.log('existing start before new start,  truncate');
          const updatedDutyEvent = {
            id: existingDutyEventId,
            end_at: start,
          };
          handleDutyEventUpdate(updatedDutyEvent);
          if (moment(existingEndAt).isAfter(end)) {
            // console.log('existing end after new end. Duplicate the event');
            const truncatedDutyEvent = {
              ...omit(existingDutyEvent, ['__typename', 'id', 'contact']),
              start_at: end,
              published_snapshot: omit(existingDutyEvent.published_snapshot, [
                '__typename',
              ]),
            };
            // console.log({ truncatedDutyEvent });
            handleDutyEventCreate(truncatedDutyEvent);
          }
        } else if (moment(existingEndAt).isAfter(end)) {
          // console.log('existing end after new end, truncate');
          const updatedDutyEvent = {
            id: existingDutyEventId,
            start_at: end,
          };
          handleDutyEventUpdate(updatedDutyEvent);
        } else {
          // console.log('start and end are inside the new event');
          handleDutyEventDelete(existingDutyEventId);
        }
      });
      deleteableDutyEventIds.forEach((deleteableDutyEventId) =>
        handleDutyEventDelete(deleteableDutyEventId)
      );
    },
    [handleDutyEventCreate, handleDutyEventUpdate, handleDutyEventDelete]
  );

  const handleEventReceive = useCallback(
    (eventInfo) => {
      hidePopovers();
      if (!isEditor) {
        return;
      }
      const { draggedEl, event } = eventInfo;
      let { start, end } = event;
      start = start
        ? moment(start).startOf('day').utc().format()
        : moment().startOf('day').utc().format();
      // fullcalendar end datetimes are exclusive
      end = end
        ? moment(end).startOf('day').utc().format()
        : moment(start).add(1, 'day').startOf('day').utc().format();

      const dutyTemplateId = parseInt(draggedEl.getAttribute('data-id'), 10);
      const dutyTemplate = dutyTemplates.find((dt) => dt.id === dutyTemplateId);
      const {
        name: dutyTemplateName,
        short_name: shortName,
        description: dutyTemplateDescription,
        duty_color: dutyColor,
        fixed_time: fixedTime,
        contact_available: contactAvailable,
        start_time: startTime,
        end_time: endTime,
      } = dutyTemplate;
      const resourceId = get(event.getResources(), '0.id');
      const newContactId = get(
        calendarResources.find((r) => r.id === resourceId),
        'extendedProps.contactId'
      );
      let createPublished = false;
      if (!powerEdit) {
        const existingDutyEvent = dutyEvents.find(
          (de) =>
            de.contact_id === newContactId &&
            moment(de.start_at).isBefore(end) &&
            moment(de.end_at).isAfter(start)
        );
        if (existingDutyEvent) {
          if (
            originalPublishedThru &&
            existingDutyEvent.published &&
            moment(start).isBefore(originalPublishedThru)
          ) {
            createPublished = true;
          }
          handleDutyEventAdjustments([existingDutyEvent], start, end);
        }
      }
      const newDutyEvent = {
        duty_template_id: dutyTemplateId,
        roster_id: rosterId,
        contact_id: newContactId,
        name: dutyTemplateName,
        short_name: shortName,
        description: dutyTemplateDescription,
        duty_color: dutyColor,
        fixed_time: fixedTime,
        contact_available: contactAvailable,
        start_time: startTime,
        end_time: endTime,
        start_at: start,
        end_at: end,
      };
      if (createPublished) {
        const newPublishedAt = moment.utc().format();
        const newLastPublisherId = currentContact.id;
        newDutyEvent.published_snapshot = {
          ...newDutyEvent,
          published_at: newPublishedAt,
          published_thru: originalPublishedThru,
          last_publisher_id: newLastPublisherId,
        };
        newDutyEvent.published = true;
      }
      handleDutyEventCreate(newDutyEvent);
    },
    [
      rosterId,
      calendarResources,
      dutyTemplates,
      handleDutyEventAdjustments,
      handleDutyEventCreate,
      hidePopovers,
      isEditor,
      powerEdit,
      dutyEvents,
      originalPublishedThru,
      currentContact,
    ]
  );

  const handleEventChange = useCallback(
    (eventInfo) => {
      hidePopovers();
      if (!isEditor) {
        return;
      }
      const { event } = eventInfo;
      const { extendedProps } = event;
      const { dutyEventId } = extendedProps;
      let { start, end } = event;
      start = moment(start).startOf('day').utc().format();
      // fullcalendar end datetimes are exclusive
      end = moment(end).startOf('day').utc().format();
      const resourceId = get(event.getResources(), '0.id');
      const newContactId = get(
        calendarResources.find((r) => r.id === resourceId),
        'extendedProps.contactId'
      );
      if (!powerEdit) {
        const existingDutyEvents = dutyEvents.filter(
          (de) =>
            de.id !== dutyEventId &&
            de.contact_id === newContactId &&
            moment(de.start_at).isBefore(end) &&
            moment(de.end_at).isAfter(start)
        );
        if (existingDutyEvents.length > 0) {
          const firstDutyEvent = first(sortBy(existingDutyEvents, ['start_at']));
          const lastDutyEvent = last(sortBy(existingDutyEvents, ['end_at']));
          const deleteableDutyEventIds = existingDutyEvents
            .map((de) => de.id)
            .filter((deId) => ![firstDutyEvent.id, lastDutyEvent.id].includes(deId));
          handleDutyEventAdjustments(
            uniqBy([firstDutyEvent, lastDutyEvent], 'id'),
            start,
            end,
            deleteableDutyEventIds
          );
        }
      }
      const updatedDutyEvent = {
        id: dutyEventId,
        contact_id: newContactId,
        start_at: start,
        end_at: end,
      };
      handleDutyEventUpdate(updatedDutyEvent);
    },
    [
      calendarResources,
      handleDutyEventAdjustments,
      handleDutyEventUpdate,
      hidePopovers,
      isEditor,
      powerEdit,
      dutyEvents,
    ]
  );

  const handleEventClick = useCallback(
    (eventInfo) => {
      hidePopovers();
      if (!isEditor) {
        return;
      }
      const { event } = eventInfo;
      const { extendedProps = {} } = event;
      const { dutyEventId, skipEventClick } = extendedProps;
      if (skipEventClick) {
        return;
      }
      if (!dutyEventId) {
        return;
      }
      const dutyEvent = dutyEvents.find((de) => de.id === dutyEventId);
      setDutyEventModalState({
        ...dutyEventModalState,
        show: true,
        dutyEvent,
      });
    },
    [dutyEvents, dutyEventModalState, hidePopovers, isEditor]
  );

  const onDayPickerChanged = (date) => {
    if (moment.isMoment(date)) {
      setPublishedThru(date.format('DD/MM/YYYY'));
    }
  };

  const renderTools = () => (
    <Row>
      <Col>
        <Row className="justify-content-end flex-nowrap">
          <Col sm="auto">
            <ButtonToolbar>
              <ButtonGroup>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={handleCopyDutyEventsModalClick}
                >
                  Copy Events..
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={handleDeleteDutyEventsModalClick}
                >
                  Delete Events..
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={handleTemplateDutyEventsModalClick}
                >
                  Template Events..
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const renderPublishing = () => {
    let statusString = 'Not visible';
    if (lastPublisher) {
      statusString = `Visible thru ${moment(originalPublishedThru).format('LL')} by ${lastPublisher.fullName}`;
    }
    return (
      <Row>
        <Col>
          <Row className="justify-content-end flex-nowrap">
            <Col sm="auto" className="me-2 align-self-center">
              <DateTime
                value={publishedThru}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect
                initialViewMode="days"
                onChange={onDayPickerChanged}
                inputProps={{ style: { width: '110px', height: '27px' } }}
              />
            </Col>
            <Col sm="auto">
              <Button
                className="me-2"
                size="sm"
                variant="primary"
                onClick={handleRosterPublish}
                // disabled={
                //   !publishedThru ||
                //   moment(originalPublishedThru).format('DD/MM/YYYY') === publishedThru
                // }
              >
                Set Visible
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="me-2 pt-2">{statusString}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Row id={ROSTER_HTML_ID}>
      <div id={ROSTER_POPOVERS_HTML_ID} />
      <TemplateDutyEventsModal
        show={showTemplateDutyEventsModal}
        rosterId={rosterId}
        rosterName={rosterName}
        calendarResources={calendarResources}
        rosterTemplates={activeSortedRosterTemplates}
        handleTemplateDutyEventsModalCancel={handleTemplateDutyEventsModalCancel}
        handleTemplateDutyEventsModalSubmit={handleTemplateDutyEventsModalSubmit}
      />
      <DeleteDutyEventsModal
        show={showDeleteDutyEventsModal}
        rosterId={rosterId}
        rosterName={rosterName}
        calendarResources={calendarResources}
        handleDeleteDutyEventsModalCancel={handleDeleteDutyEventsModalCancel}
        handleDeleteDutyEventsModalSubmit={handleDeleteDutyEventsModalSubmit}
      />
      <CopyDutyEventsModal
        show={showCopyDutyEventsModal}
        rosterId={rosterId}
        rosterName={rosterName}
        calendarResources={calendarResources}
        handleCopyDutyEventsModalCancel={handleCopyDutyEventsModalCancel}
        handleCopyDutyEventsModalSubmit={handleCopyDutyEventsModalSubmit}
      />
      <DutyEventModal
        {...dutyEventModalState}
        handleDutyEventModalCancel={handleDutyEventModalCancel}
        handleDutyEventModalUpdate={handleDutyEventModalUpdate}
        handleDutyEventModalDelete={handleDutyEventModalDelete}
      />
      <Col>
        <div className="sticky sticky-top-0">
          <p>{description}</p>
          {isEditor && (
            <Row className="justify-content-start g-0">
              <Col className="flex-grow-1">
                <Row id={ROSTER_DRAGGABLES_HTML_ID}>
                  <Col>
                    {Object.keys(groupedDutyTemplates).map((position) => (
                      <Row
                        key={`${rosterId}-${position}`}
                        className="justify-content-start"
                      >
                        {groupedDutyTemplates[position].map((dutyTemplate) => {
                          const {
                            id: dutyTemplateId,
                            shortName,
                            dutyColor,
                            name,
                          } = dutyTemplate;
                          return (
                            <Col
                              key={`${rosterId}-${position}-${dutyTemplateId}`}
                              sm="auto"
                              style={{ width: '10%' }}
                            >
                              <div
                                className="p-2 mb-2 fc-event fc-h-event fc-daygrid-event"
                                title={name}
                                data-id={dutyTemplateId}
                                data-color={dutyColor}
                                style={{
                                  backgroundColor: dutyColor,
                                  borderColor: dutyColor,
                                  cursor: 'pointer',
                                }}
                              >
                                <div className="fc-event-main">
                                  <div>
                                    <strong style={{ color: getContrastYIQ(dutyColor) }}>
                                      {shortName}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ))}
                  </Col>
                </Row>
              </Col>
              <Col className="flex-grow-0 text-nowrap">{renderPublishing()}</Col>
              <Col className="flex-grow-0 text-nowrap">{renderTools()}</Col>
            </Row>
          )}
          <hr />
        </div>
        <ScrollableFullCalendar
          ROSTER_CALENDAR_HTML_ID={ROSTER_CALENDAR_HTML_ID}
          ROSTER_POPOVERS_HTML_ID={ROSTER_POPOVERS_HTML_ID}
          isEditor={isEditor}
          calendarEvents={hashedCalendarEvents}
          calendarResources={calendarResources}
          handleEventReceive={handleEventReceive}
          handleEventChange={handleEventChange}
          handleEventClick={handleEventClick}
          currentSettingsDutyEventCollectionStartDate={
            currentSettingsDutyEventCollectionStartDate
          }
          currentSettingsDutyEventCollectionEndDate={
            currentSettingsDutyEventCollectionEndDate
          }
          dispatchDutyEventCollectionVars={dispatchDutyEventCollectionVars}
        />
      </Col>
    </Row>
  );
}

export default Roster;
