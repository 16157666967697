import { Fields } from 'redux-form';

import get from 'lodash.get';
import last from 'lodash.last';

import WeightBalanceAssetAssignmentFields from './weight_balance_asset_assignment_fields';

const WeightBalanceAssetAssignmentFieldArray = (props) => {
  const {
    formValues,
    assetAssignments,
    change,
    flightSegmentIndex,
    flightSegmentsLength,
    optional,
    isDeveloper,
  } = props;

  return (
    <tbody>
      {assetAssignments.fields.map((field, index) => {
        const assetAssignmentField = last(field.split('.'));
        const values = get(formValues, assetAssignmentField, {});
        const selected = optional
          ? values.asset_assignment_installed_weight_increase > 0
          : values.asset_assignment_installed_weight_increase <= 0;
        if (!values._destroy && selected) {
          const fields = [`${field}.asset_assignment_installed`];
          return (
            <Fields
              key={field}
              change={change}
              flightSegmentIndex={flightSegmentIndex}
              flightSegmentsLength={flightSegmentsLength}
              index={index}
              parentField={field}
              names={fields}
              component={WeightBalanceAssetAssignmentFields}
              formValues={values}
              isDeveloper={isDeveloper}
            />
          );
        }
        return undefined;
      })}
    </tbody>
  );
};

export default WeightBalanceAssetAssignmentFieldArray;
