import { Row, Col, Form, Card, Table, Button } from 'react-bootstrap';
import { useEffect, useCallback } from 'react';
import { Field } from 'redux-form';
import objectHash from 'object-hash';

import get from 'lodash.get';
import last from 'lodash.last';

import usePreviousState from '../../hooks/use_previous_state';
import InputField from '../form/input_field';
import DlHorizontal from '../dl_horizontal';

const BookingAdminFlightRecord = (props) => {
  const {
    isOfficeAdmin,
    change,
    formValues,
    locationsDataSelector,
    fuelBowsersDataSelector,
    fuelTankersDataSelector,
    contactsDataSelector,
    currentSettingsFuelBowserFillDefaultQuantityUnit,
    flightSegmentsCalculatedData = [],
  } = props;

  const {
    admin_flight_record_attributes: adminFlightRecordAttributes,
    flight_segments_attributes: flightSegmentsAttributes = [],
    hobb_record_attributes: hobbRecordAttributes = {},
    fuel_bowser_fills_attributes: fuelBowserFillsAttributes = [],
    fuel_tanker_fills_attributes: fuelTankerFillsAttributes = [],
    pilot_flight_expenses_attributes: pilotFlightExpensesAttributes = [],
    copilot_id: copilotId,
  } = formValues;

  const { flight_time: flightTime } = hobbRecordAttributes;

  const prevFlightTime = usePreviousState(flightTime);

  const getFlightSummary = useCallback(() => {
    const fss = flightSegmentsAttributes.filter((fs) => !fs._destroy);
    if (fss.length > 0) {
      const newSummaries = fss.map((fs) => {
        const {
          start_location_id: startLocationId,
          seat_assignments_attributes: seatAssignmentsAttributes = [],
        } = fs;

        const startLocationName = get(
          locationsDataSelector,
          [startLocationId, 'shorthandName'],
          'Unknown'
        );
        const pax = seatAssignmentsAttributes
          .filter((sa) => !sa._destroy)
          .filter(
            (sa) =>
              sa.seat_assignment_seat_type === 'pax_only' ||
              (sa.seat_assignment_seat_type === 'copilot_or_pax' && !copilotId)
          )
          .map((sa) => sa.pax_name)
          .filter((name) => name)
          .join(', ');
        return pax ? `${startLocationName} (${pax})` : startLocationName;
      });
      const endLocationName = get(
        locationsDataSelector,
        [get(last(fss), 'end_location_id'), 'shorthandName'],
        'Unknown'
      );
      newSummaries.push(endLocationName);
      return newSummaries.join(' - ');
    }
    return ' No flight summary';
  }, [copilotId, flightSegmentsAttributes, locationsDataSelector]);

  const handleResetSummaryButtonClick = useCallback(() => {
    change('admin_flight_record_attributes.flight_summary', getFlightSummary());
  }, [change, getFlightSummary]);

  useEffect(() => {
    const {
      flight_time: adminFlightTime,
      flight_summary: flightSummary,
      flight_out_of_balance: flightOutOfBalance,
      flight_overweight: flightOverweight,
    } = adminFlightRecordAttributes;

    if (prevFlightTime && flightTime && prevFlightTime !== flightTime) {
      change('admin_flight_record_attributes.flight_time', flightTime);
    }
    if (!adminFlightTime) {
      change('admin_flight_record_attributes.flight_time', flightTime);
    }
    if (!flightSummary) {
      change('admin_flight_record_attributes.flight_summary', getFlightSummary());
    }
    const outOfBalance = flightSegmentsCalculatedData.some(
      (fs) => fs.flightSegmentOutOfBalance
    );
    const overWeight = flightSegmentsCalculatedData.some(
      (fs) => fs.flightSegmentAllUpWeightOverMaximumWeight
    );
    if (outOfBalance !== flightOutOfBalance) {
      change('admin_flight_record_attributes.flight_out_of_balance', outOfBalance);
    }
    if (overWeight !== flightOverweight) {
      change('admin_flight_record_attributes.flight_overweight', overWeight);
    }
  }, [
    prevFlightTime,
    flightTime,
    adminFlightRecordAttributes,
    flightSegmentsCalculatedData,
    change,
    getFlightSummary,
  ]);

  const renderAdminFlightRecordFields = () => (
    <>
      <Field
        blurOnly
        type="text"
        size="md"
        name="admin_flight_record_attributes.flight_time"
        labelWidth={3}
        inputWidth={3}
        component={InputField}
      >
        Flight Time
      </Field>
      <Field
        blurOnly
        type="text"
        size="md"
        name="admin_flight_record_attributes.flight_summary"
        labelWidth={3}
        inputWidth={9}
        component={InputField}
        asElement="textarea"
        rows={3}
      >
        Flight Summary
      </Field>
      <Row>
        <Col sm={3} className="text-end">
          <Button
            type="button"
            variant="link"
            size="sm"
            onClick={handleResetSummaryButtonClick}
            tabIndex={-1}
          >
            reset summary...
          </Button>
        </Col>
      </Row>
    </>
  );

  const renderPilotFlightExpense = (pilotFlightExpense, idx) => {
    const {
      id,
      pilot_id: pilotId,
      notes,
      total,
      includes_gst: includesGst,
      onchargeable,
      reimbursable,
    } = pilotFlightExpense;
    const key = objectHash({ id, idx });
    const pilotName = get(contactsDataSelector, [pilotId, 'fullName'], 'Select Pilot');
    return (
      <tr key={key}>
        <td>{pilotName}</td>
        <td>{notes}</td>
        <td className="text-end">{total ? `$${parseFloat(total).toFixed(2)}` : '-'}</td>
        <td className="text-center">{includesGst ? 'yes' : 'no'}</td>
        <td className="text-center">{onchargeable ? 'yes' : 'no'}</td>
        <td className="text-center">{reimbursable ? 'yes' : 'no'}</td>
      </tr>
    );
  };

  const renderPilotFlightExpenses = () => {
    if (pilotFlightExpensesAttributes.length > 0) {
      return (
        <Table size="sm">
          <thead>
            <tr>
              <th>Pilot</th>
              <th>Notes</th>
              <th className="text-end">Total</th>
              <th className="text-center">GST</th>
              <th className="text-center">Oncharge</th>
              <th className="text-center">Reimburse</th>
            </tr>
          </thead>
          <tbody>
            {pilotFlightExpensesAttributes
              .filter((fs) => !fs._destroy)
              .map(renderPilotFlightExpense)}
          </tbody>
        </Table>
      );
    }
    return <p>No pilot flight expenses</p>;
  };

  const renderFuelTankerFill = (fuelTankerFill, idx) => {
    const {
      id,
      fuel_tanker_id: fuelTankerId,
      quantity_value: quantityValue,
    } = fuelTankerFill;
    const key = objectHash({ id, idx, type: 'ftf' });
    const fuelTankerName = get(
      fuelTankersDataSelector,
      [fuelTankerId, 'name'],
      'Select Tanker'
    );
    return (
      <tr key={key}>
        <td>{fuelTankerName}</td>
        <td>{`${parseFloat(quantityValue).toFixed(
          2
        )} ${currentSettingsFuelBowserFillDefaultQuantityUnit}`}</td>
        <td className="text-muted small">tanker</td>
      </tr>
    );
  };

  const renderFuelBowserFill = (fuelBowserFill, idx) => {
    const {
      id,
      fuel_bowser_id: fuelBowserId,
      quantity_value: quantityValue,
    } = fuelBowserFill;
    const key = objectHash({ id, idx, type: 'fbf' });
    const fuelBowserName = get(
      fuelBowsersDataSelector,
      [fuelBowserId, 'name'],
      'Select Bowser'
    );
    return (
      <tr key={key}>
        <td>{fuelBowserName}</td>
        <td>{`${parseFloat(quantityValue).toFixed(
          2
        )} ${currentSettingsFuelBowserFillDefaultQuantityUnit}`}</td>
        <td className="text-muted small">bowser</td>
      </tr>
    );
  };

  const renderFuelFills = () => (
    <Table size="sm">
      <tbody>
        {fuelBowserFillsAttributes.filter((fs) => !fs._destroy).map(renderFuelBowserFill)}
        {fuelTankerFillsAttributes.filter((ft) => !ft._destroy).map(renderFuelTankerFill)}
      </tbody>
    </Table>
  );

  const renderLanding = (flightSegment, idx) => {
    const {
      id,
      end_location_id: endLocationId,
      end_location_landing_fee: endLocationLandingFee,
      oncharge_end_location_landing_fee: onchargeEndLocationLandingFee,
    } = flightSegment;
    const key = objectHash({ id, idx });
    const endLocationName = get(
      locationsDataSelector,
      [endLocationId, 'longName'],
      'Unknown'
    );
    return (
      <tr key={key}>
        <td>{endLocationName}</td>
        <td>{endLocationLandingFee ? `$${endLocationLandingFee}` : '-'}</td>
        <td>{onchargeEndLocationLandingFee ? 'charged' : 'not charged'}</td>
      </tr>
    );
  };

  const renderLandings = () => (
    <Table size="sm">
      <tbody>
        {flightSegmentsAttributes.filter((fs) => !fs._destroy).map(renderLanding)}
      </tbody>
    </Table>
  );

  const renderHobbs = () => {
    const { start_hobb: startHobb, end_hobb: endHobb } = hobbRecordAttributes;
    return (
      <Row>
        <Col xs={4}>
          <DlHorizontal dt="Start Hobb" dd={startHobb || '-'} />
        </Col>
        <Col xs={4}>
          <DlHorizontal dt="End Hobb" dd={endHobb || '-'} />
        </Col>
        <Col xs={4}>
          <DlHorizontal dt="Flight Time" dd={flightTime || '-'} />
        </Col>
      </Row>
    );
  };

  return (
    <Row className="mt-3">
      <Col xs={6}>
        <Card>
          <Card.Body>{renderHobbs()}</Card.Body>
        </Card>
        <Card>
          <Card.Header>Landings</Card.Header>
          <Card.Body>{renderLandings()}</Card.Body>
        </Card>
        <Card>
          <Card.Header>Refuellings</Card.Header>
          <Card.Body>{renderFuelFills()}</Card.Body>
        </Card>
        <Card>
          <Card.Header>Expenses</Card.Header>
          <Card.Body>{renderPilotFlightExpenses()}</Card.Body>
        </Card>
      </Col>
      {isOfficeAdmin && (
        <Col xs={6}>
          <Card>
            <Card.Body>{renderAdminFlightRecordFields()}</Card.Body>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default BookingAdminFlightRecord;
