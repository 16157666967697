import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FieldArray } from 'redux-form';
import { useSelector } from 'react-redux';

import WeightBalances from './weight_balances';

const BookingWeightAndBalance = (props) => {
  const { formValues, locationsDataSelector, change, flightSegmentsCalculatedData } =
    props;

  const { aircraft_id: aircraftId, aircraft_configuration_id: aircraftConfigurationId } =
    formValues;

  const [isDeveloper, setIsDeveloper] = useState(false);
  const currentContactEmail = useSelector((state) => state.currentContact.email);

  useEffect(() => {
    setIsDeveloper(currentContactEmail === 'gordon.king02@gmail.com');
  }, [currentContactEmail]);

  const renderWeightBalancesFieldArray = (flightSegments) => (
    <WeightBalances
      change={change}
      flightSegments={flightSegments}
      formValues={formValues}
      locationsDataSelector={locationsDataSelector}
      flightSegmentsCalculatedData={flightSegmentsCalculatedData}
      isDeveloper={isDeveloper}
    />
  );

  if (!aircraftConfigurationId) {
    return (
      <Row className="mt-3">
        <Col sm={12}>
          <p>Select aircraft and aircraft configuration</p>
        </Col>
      </Row>
    );
  }

  if (aircraftId && aircraftConfigurationId) {
    return (
      <Row className="mt-3">
        <Col xs={12}>
          <FieldArray
            name="flight_segments_attributes"
            component={renderWeightBalancesFieldArray}
          />
        </Col>
      </Row>
    );
  }
  return null;
};

export default BookingWeightAndBalance;
