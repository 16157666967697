import { Fields } from 'redux-form';

import get from 'lodash.get';
import last from 'lodash.last';

import WeightBalanceHoldAssignmentFields from './weight_balance_hold_assignment_fields';

const WeightBalanceHoldAssignmentFieldArray = (props) => {
  const { formValues, holdAssignments, isDeveloper, optional } = props;
  return (
    <tbody>
      {holdAssignments.fields.map((field, index) => {
        const holdAssignmentField = last(field.split('.'));
        const values = get(formValues, holdAssignmentField, {});
        const selected = optional
          ? values.hold_assignment_installed_weight_increase > 0
          : values.hold_assignment_installed_weight_increase <= 0;
        if (!values._destroy && selected) {
          const fields = [
            `${field}.hold_assignment_installed`,
            `${field}.hold_assignment_weight`,
          ];
          return (
            <Fields
              key={field}
              index={index}
              parentField={field}
              names={fields}
              component={WeightBalanceHoldAssignmentFields}
              formValues={values}
              isDeveloper={isDeveloper}
            />
          );
        }
        return undefined;
      })}
    </tbody>
  );
};

export default WeightBalanceHoldAssignmentFieldArray;
