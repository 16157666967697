/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import InputField from '../form/input_field';
import Glyphicon from '../glyphicon';

import {
  // round0,
  round2,
  convertFromTo,
  convertFuelToFuel,
} from '../../lib/convert_units';

const BookingFlightPlanFields = (props) => {
  const {
    bookingCalculatedData,
    firstFlightSegmentsCalculatedData,
    handleResetCalculation,
    convertFlightSegmentWeight,
    convertFlightSegmentFuel,
    convertFlightSegmentAirspeed,
    booking_cruise_airspeed_sl: {
      input: bookingCruiseAirspeedSlInput,
      meta: bookingCruiseAirspeedSlMeta,
    },
    booking_cruise_fuel_consumption_sl: {
      input: bookingCruiseFuelConsumptionSlInput,
      meta: bookingCruiseFuelConsumptionSlMeta,
    },
    booking_planned_reserve: {
      input: bookingPlannedReserveInput,
      meta: bookingPlannedReserveMeta,
    },
    booking_weight_unit: { input: bookingWeightUnitInput, meta: bookingWeightUnitMeta },
    booking_arm_unit: { input: bookingArmUnitInput, meta: bookingArmUnitMeta },
    booking_fuel_unit: { input: bookingFuelUnitInput, meta: bookingFuelUnitMeta },
    booking_airspeed_unit: {
      input: bookingAirspeedUnitInput,
      meta: bookingAirspeedUnitMeta,
    },
    isDeveloper,
  } = props;

  const {
    bookingWeightUnit,
    convertedAircraftTypeMaximumAllUpWeight,
    convertedAircraftWeight,
    aircraftTypeDepartureAndArrivalLoading,
    convertedAircraftTypeCruiseAirspeedSl,
    bookingAirspeedUnit,
    bookingCruiseAirspeedSl,
    convertedAircraftTypeCruiseFuelConsumptionSl,
    bookingFuelUnit,
    aircraftTypeCompanyPlannedReserve,
    bookingReserveConsumptionSl,
    bookingCruiseFuelConsumptionSl,
    aircraftTypeFuelTypeSpecificGravity,
    aircraftTypeWeightUnit,
    aircraftTypeArmUnit,
    aircraftTypeFuelUnit,
    aircraftTypeAirspeedUnit,
    bookingAircraftWeightDefined,
  } = bookingCalculatedData;

  const { tankAssignmentsMaximumFuel } = firstFlightSegmentsCalculatedData;

  const currentSettingsAircraftTypeWeightUnits = useSelector(
    (state) => state.currentSettings.aircraft_type_weight_units
  );
  const currentSettingsAircraftTypeArmUnits = useSelector(
    (state) => state.currentSettings.aircraft_type_arm_units
  );
  const currentSettingsAircraftTypeFuelUnits = useSelector(
    (state) => state.currentSettings.aircraft_type_fuel_units
  );
  const currentSettingsAircraftTypeAirspeedUnits = useSelector(
    (state) => state.currentSettings.aircraft_type_airspeed_units
  );

  // const handleBookingCruiseAirspeedSlBlur = (e) => {
  //   const newBookingCruiseAirspeedSl = round2(defaultTo(parseFloat(e.target.value), 0));
  //   bookingCruiseAirspeedSlInput.onChange(newBookingCruiseAirspeedSl);
  //   handleResetCalculation();
  // };

  // const handleBookingCruiseFuelConsumptionSlBlur = (e) => {
  //   let newBookingCruiseFuelConsumptionSl = round2(
  //     defaultTo(parseFloat(e.target.value), 0)
  //   );
  //   if (newBookingCruiseFuelConsumptionSl === 0) {
  //     newBookingCruiseFuelConsumptionSl = convertedAircraftTypeCruiseFuelConsumptionSl;
  //   }
  //   if (newBookingCruiseFuelConsumptionSl !== bookingCruiseFuelConsumptionSl) {
  //     console.log('updating');
  //     bookingCruiseFuelConsumptionSlInput.onChange(newBookingCruiseFuelConsumptionSl);
  //     handleResetCalculation();
  //   }
  // };

  // const handleBookingPlannedReserveBlur = (e) => {
  //   const newBookingPlannedReserve = round0(defaultTo(parseInt(e.target.value, 10), 0));
  //   bookingPlannedReserveInput.onChange(newBookingPlannedReserve);
  //   handleResetCalculation();
  // };

  const handleBookingWeightUnitChange = (e) => {
    const newBookingWeightUnit = e.target.value;
    convertFlightSegmentWeight(bookingWeightUnit, newBookingWeightUnit);
    bookingWeightUnitInput.onChange(newBookingWeightUnit);
    handleResetCalculation();
  };

  const handleBookingArmUnitChange = (e) => {
    const newBookingArmUnit = e.target.value;
    bookingArmUnitInput.onChange(newBookingArmUnit);
    handleResetCalculation();
  };

  const handleBookingFuelUnitChange = (e) => {
    const newBookingFuelUnit = e.target.value;
    const newBookingCruiseFuelConsumptionSl = round2(
      convertFuelToFuel({
        fuelQuantity: bookingCruiseFuelConsumptionSl,
        fromFuelUnit: bookingFuelUnit,
        toFuelUnit: newBookingFuelUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      })
    );
    convertFlightSegmentFuel(bookingFuelUnit, newBookingFuelUnit);
    bookingCruiseFuelConsumptionSlInput.onChange(newBookingCruiseFuelConsumptionSl);
    bookingFuelUnitInput.onChange(newBookingFuelUnit);
    handleResetCalculation();
  };

  const handleBookingAirspeedUnitChange = (e) => {
    const newBookingAirspeedUnit = e.target.value;
    const newBookingCruiseAirspeedSl = round2(
      convertFromTo(bookingCruiseAirspeedSl, bookingAirspeedUnit, newBookingAirspeedUnit)
    );
    convertFlightSegmentAirspeed(bookingAirspeedUnit, newBookingAirspeedUnit);
    bookingCruiseAirspeedSlInput.onChange(newBookingCruiseAirspeedSl);
    bookingAirspeedUnitInput.onChange(newBookingAirspeedUnit);
    handleResetCalculation();
  };

  const renderAirspeedLabel = () => (
    <span>
      {`Cruise Airspeed ${bookingAirspeedUnit}`}
      <br />
      <small>{`(default ${parseInt(convertedAircraftTypeCruiseAirspeedSl, 10)})`}</small>
    </span>
  );

  const renderConsumptionLabel = () => (
    <span>
      {`Fuel Consumption ${bookingFuelUnit}/h`}
      <br />
      <small>{`(default ${parseInt(
        convertedAircraftTypeCruiseFuelConsumptionSl,
        10
      )})`}</small>
    </span>
  );

  const renderReserveLabel = () => (
    <span>
      Company Reserve mins
      <br />
      <small>{`
        fuel consumption
        ${parseInt(bookingReserveConsumptionSl, 10)}${bookingFuelUnit},
        (default ${parseInt(aircraftTypeCompanyPlannedReserve, 10)})`}</small>
    </span>
  );

  const renderAircraftEmptyWeightLabel = () => {
    const definedTip = (
      <Tooltip
        id="aircraft-weight-defined"
        style={{ width: 'auto', whiteSpace: 'pre-line' }}
      >
        {bookingAircraftWeightDefined || 'No weight definition'}
      </Tooltip>
    );
    return (
      <span>
        <OverlayTrigger key="1" placement="bottom" overlay={definedTip}>
          <span>
            Aircraft Empty Weight &nbsp;
            <Glyphicon glyph="info-sign" />
          </span>
        </OverlayTrigger>
      </span>
    );
  };

  return (
    <Row className="mt-3">
      <Col sm={4}>
        <InputField
          input={bookingCruiseAirspeedSlInput}
          // input={{
          //   ...bookingCruiseAirspeedSlInput,
          //   onBlur: handleBookingCruiseAirspeedSlBlur,
          // }}
          meta={bookingCruiseAirspeedSlMeta}
          helpBlock={false}
          type="text"
          size="sm"
          blurOnly
          labelWidth={8}
          inputWidth={4}
          noTab
        >
          {renderAirspeedLabel()}
        </InputField>
        <InputField
          input={bookingCruiseFuelConsumptionSlInput}
          // input={{
          //   ...bookingCruiseFuelConsumptionSlInput,
          //   onBlur: handleBookingCruiseFuelConsumptionSlBlur,
          // }}
          meta={bookingCruiseFuelConsumptionSlMeta}
          helpBlock={false}
          type="text"
          size="sm"
          blurOnly
          labelWidth={8}
          inputWidth={4}
          noTab
        >
          {renderConsumptionLabel()}
        </InputField>
        <InputField
          input={bookingPlannedReserveInput}
          // input={{
          //   ...bookingPlannedReserveInput,
          //   onBlur: handleBookingPlannedReserveBlur,
          // }}
          meta={bookingPlannedReserveMeta}
          helpBlock={false}
          type="text"
          size="sm"
          blurOnly
          labelWidth={8}
          inputWidth={4}
          noTab
        >
          {renderReserveLabel()}
        </InputField>
        <InputField
          input={{
            name: 'aircraftTypeDepartureAndArrivalLoading',
            value: `${aircraftTypeDepartureAndArrivalLoading}mins`,
          }}
          meta={{}}
          plainText
          helpBlock={false}
          type="text"
          size="sm"
          labelWidth={8}
          inputWidth={4}
          noTab
        >
          Dep/Arr Loading
        </InputField>
      </Col>
      {isDeveloper && (
        <Col sm={4}>
          <InputField
            input={{ ...bookingWeightUnitInput, onChange: handleBookingWeightUnitChange }}
            meta={bookingWeightUnitMeta}
            helpBlock={false}
            type="text"
            size="sm"
            asElement="select"
            labelWidth={6}
            inputWidth={6}
            noTab
            defaultSelectOption={false}
            selectOptions={currentSettingsAircraftTypeWeightUnits.map((unit) => ({
              id: unit,
              name: unit,
            }))}
          >
            <span>
              Booking Weight Unit
              <br />
              <small>{`(default ${aircraftTypeWeightUnit})`}</small>
            </span>
          </InputField>
          <InputField
            input={{ ...bookingArmUnitInput, onChange: handleBookingArmUnitChange }}
            meta={bookingArmUnitMeta}
            helpBlock={false}
            type="text"
            size="sm"
            asElement="select"
            labelWidth={6}
            inputWidth={6}
            noTab
            defaultSelectOption={false}
            selectOptions={currentSettingsAircraftTypeArmUnits.map((unit) => ({
              id: unit,
              name: unit,
            }))}
          >
            <span>
              Booking Arm Unit
              <br />
              <small>{`(default ${aircraftTypeArmUnit})`}</small>
            </span>
          </InputField>
          <InputField
            input={{ ...bookingFuelUnitInput, onChange: handleBookingFuelUnitChange }}
            meta={bookingFuelUnitMeta}
            helpBlock={false}
            type="text"
            size="sm"
            asElement="select"
            labelWidth={6}
            inputWidth={6}
            noTab
            defaultSelectOption={false}
            selectOptions={currentSettingsAircraftTypeFuelUnits.map((unit) => ({
              id: unit,
              name: unit,
            }))}
          >
            <span>
              Booking Fuel Unit
              <br />
              <small>{`(default ${aircraftTypeFuelUnit})`}</small>
            </span>
          </InputField>
          <InputField
            input={{
              ...bookingAirspeedUnitInput,
              onChange: handleBookingAirspeedUnitChange,
            }}
            meta={bookingAirspeedUnitMeta}
            helpBlock={false}
            type="text"
            size="sm"
            asElement="select"
            labelWidth={6}
            inputWidth={6}
            noTab
            defaultSelectOption={false}
            selectOptions={currentSettingsAircraftTypeAirspeedUnits.map((unit) => ({
              id: unit,
              name: unit,
            }))}
          >
            <span>
              Booking Airspeed Unit
              <br />
              <small>{`(default ${aircraftTypeAirspeedUnit})`}</small>
            </span>
          </InputField>
        </Col>
      )}
      <Col sm={4}>
        <InputField
          input={{
            name: 'convertedAircraftTypeMaximumAllUpWeight',
            value: `${parseInt(
              convertedAircraftTypeMaximumAllUpWeight,
              10
            )}${bookingWeightUnit}`,
          }}
          meta={{}}
          plainText
          helpBlock={false}
          type="text"
          size="sm"
          labelWidth={8}
          inputWidth={4}
          noTab
        >
          Aircraft Type Maximum Weight
        </InputField>
        <InputField
          input={{
            name: 'aircraftWeight',
            value: `${parseInt(convertedAircraftWeight, 10)}${bookingWeightUnit}`,
          }}
          meta={{}}
          plainText
          helpBlock={false}
          type="text"
          size="sm"
          labelWidth={8}
          inputWidth={4}
          noTab
        >
          {renderAircraftEmptyWeightLabel()}
        </InputField>
        <InputField
          input={{
            name: 'tankAssignmentsMaximumFuel',
            value: `${parseInt(tankAssignmentsMaximumFuel, 10)}${bookingFuelUnit}`,
          }}
          meta={{}}
          plainText
          helpBlock={false}
          type="text"
          size="sm"
          labelWidth={8}
          inputWidth={4}
          noTab
        >
          Maximum Fuel Capacity
        </InputField>
        <Row className="justify-content-end">
          <Col sm={4} className="text-start">
            <Button
              type="button"
              variant="primary"
              size="sm"
              onClick={handleResetCalculation}
            >
              Revert
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BookingFlightPlanFields;
