import memoize from 'lodash.memoize';

import { AircraftWeightDataMemo } from './aircraft_weight_data_memo';
import { AircraftFuelDataMemo } from './aircraft_fuel_data_memo';
import { AircraftAirspeedDataMemo } from './aircraft_airspeed_data_memo';

import {
  convertFromTo,
  convertFuelToMass,
  convertFuelToFuel,
} from '../lib/convert_units';

export const BookingCalculatedDataMemo = memoize(
  ({ aircraftId, bookingModifiers, aircrafts, aircraftTypes, fuelTypes }) => {
    if (aircraftId) {
      const bookingDistanceUnit = 'nMi';
      const bookingHeightUnit = 'feet';
      const kgUnit = 'kg';
      const kmphUnit = 'km/h';
      const knotUnit = 'knot';
      const meterUnit = 'm';
      const nmiUnit = 'nMi';

      let {
        bookingCruiseAirspeedSl,
        bookingCruiseFuelConsumptionSl,
        bookingPlannedReserve,
        bookingArmUnit,
        bookingWeightUnit,
        bookingFuelUnit,
        bookingAirspeedUnit,
      } = bookingModifiers;

      const {
        bookingAircraftWeight,
        bookingAircraftWeightDefined,
        bookingAircraftLateralArm,
        bookingAircraftLongitudinalArm,
        bookingCaaWeightLoading,
        bookingCaaWeightLoadingIncluded,
        copilotId,
      } = bookingModifiers;

      const aircraftWeightData = AircraftWeightDataMemo({
        aircraftId,
        aircrafts,
        aircraftTypes,
      });
      const aircraftAirspeedData = AircraftAirspeedDataMemo({
        aircraftId,
        aircrafts,
        aircraftTypes,
      });
      const aircraftFuelData = AircraftFuelDataMemo({
        aircraftId,
        aircrafts,
        aircraftTypes,
        fuelTypes,
      });
      const aircraftData = {
        ...aircraftWeightData,
        ...aircraftFuelData,
        ...aircraftAirspeedData,
      };

      const {
        aircraftTypeWeightUnit,
        aircraftTypeFuelUnit,
        aircraftTypeArmUnit,
        aircraftTypeAirspeedUnit,
        aircraftTypeCruiseAirspeedSl,
        aircraftTypeCruiseFuelConsumptionSl,
        aircraftTypeFuelTypeSpecificGravity,
        aircraftTypeMaximumAllUpWeight,
        aircraftTypeCompanyPlannedReserve,
      } = aircraftData;

      bookingCruiseAirspeedSl = bookingCruiseAirspeedSl || aircraftTypeCruiseAirspeedSl;
      bookingCruiseFuelConsumptionSl =
        bookingCruiseFuelConsumptionSl || aircraftTypeCruiseFuelConsumptionSl;
      bookingPlannedReserve = bookingPlannedReserve || aircraftTypeCompanyPlannedReserve;
      bookingArmUnit = bookingArmUnit || aircraftTypeArmUnit;
      bookingWeightUnit = bookingWeightUnit || aircraftTypeWeightUnit;
      bookingFuelUnit = bookingFuelUnit || aircraftTypeFuelUnit;
      bookingAirspeedUnit = bookingAirspeedUnit || aircraftTypeAirspeedUnit;

      const bookingCruiseAirspeedSlKnots = convertFromTo(
        bookingCruiseAirspeedSl,
        bookingAirspeedUnit,
        knotUnit
      );

      const bookingCruiseAirspeedSlKmph = convertFromTo(
        bookingCruiseAirspeedSl,
        bookingAirspeedUnit,
        kmphUnit
      );

      const bookingCruiseFuelConsumptionSlKg = convertFuelToMass({
        fuelQuantity: bookingCruiseFuelConsumptionSl,
        fuelUnit: bookingFuelUnit,
        massUnit: kgUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      const convertedAircraftWeight = convertFromTo(
        bookingAircraftWeight,
        aircraftTypeWeightUnit,
        bookingWeightUnit
      );

      const convertedAircraftTypeMaximumAllUpWeight = convertFromTo(
        aircraftTypeMaximumAllUpWeight,
        aircraftTypeWeightUnit,
        bookingWeightUnit
      );

      const convertedAircraftTypeCruiseFuelConsumptionSl = convertFuelToFuel({
        fuelQuantity: aircraftTypeCruiseFuelConsumptionSl,
        fromFuelUnit: aircraftTypeFuelUnit,
        toFuelUnit: bookingFuelUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      const convertedAircraftTypeCruiseAirspeedSl = convertFromTo(
        aircraftTypeCruiseAirspeedSl,
        aircraftTypeAirspeedUnit,
        bookingAirspeedUnit
      );

      const aircraftTypeReserveConsumptionSl =
        (aircraftTypeCruiseFuelConsumptionSl / 60) * aircraftTypeCompanyPlannedReserve;

      const convertedAircraftTypeReserveConsumptionSl = convertFuelToFuel({
        fuelQuantity: aircraftTypeReserveConsumptionSl,
        fromFuelUnit: aircraftTypeFuelUnit,
        toFuelUnit: bookingFuelUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      const bookingReserveConsumptionSl =
        (bookingCruiseFuelConsumptionSl / 60) * bookingPlannedReserve;

      const bookingReserveConsumptionSlKg = convertFuelToFuel({
        fuelQuantity: bookingReserveConsumptionSl,
        fromFuelUnit: bookingFuelUnit,
        toFuelUnit: kgUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      return {
        ...aircraftData,
        bookingCruiseAirspeedSl,
        bookingCruiseFuelConsumptionSl,
        bookingPlannedReserve,
        bookingArmUnit,
        bookingWeightUnit,
        bookingFuelUnit,
        bookingAirspeedUnit,
        bookingAircraftWeight,
        bookingAircraftWeightDefined,
        bookingAircraftLateralArm,
        bookingAircraftLongitudinalArm,
        bookingCaaWeightLoading,
        bookingCaaWeightLoadingIncluded,
        copilotId,
        // todo, probably distance will need making changeable
        kgUnit,
        kmphUnit,
        knotUnit,
        meterUnit,
        nmiUnit,
        bookingDistanceUnit,
        bookingHeightUnit,
        //
        bookingCruiseAirspeedSlKnots,
        bookingCruiseAirspeedSlKmph,
        //
        bookingCruiseFuelConsumptionSlKg,
        bookingReserveConsumptionSl,
        bookingReserveConsumptionSlKg,
        //
        convertedAircraftWeight,
        convertedAircraftTypeMaximumAllUpWeight,
        convertedAircraftTypeCruiseFuelConsumptionSl,
        convertedAircraftTypeCruiseAirspeedSl,
        convertedAircraftTypeReserveConsumptionSl,
      };
    }
    return {};
  },
  ({ aircraftId, bookingModifiersObjectHash }) => {
    if (aircraftId) {
      return [aircraftId, bookingModifiersObjectHash].map((i) => String(i)).join('.');
    }
    return 'unknown';
  }
);
