import { Col } from 'react-bootstrap';
import { Component } from 'react';

import compact from 'lodash.compact';
import get from 'lodash.get';

import FlightSegment from './flight_segment';
import { getSelectable } from '../../lib/utils';

class FlightSegmentFieldArray extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
  }

  handleDeleteClicked(index) {
    const newFlightSegments = get(this.props, 'flight_segments_attributes', []).map(
      (fs, mapIndex) => {
        if (index === mapIndex && !fs.id) {
          return null;
        }
        return {
          ...fs,
          take_off_fuel: 0,
          pilot_take_off_fuel: 0,
          pilot_take_off_fuel_adjusted: false,
          ...(index === mapIndex && { _destroy: true }),
        };
      }
    );
    this.props.change('flight_segments_attributes', compact(newFlightSegments));
  }

  getPrevEndAts(flightSegments) {
    return [this.props.start_at, ...flightSegments.map((fs) => fs.end_at)];
  }

  getSelectablePassengers() {
    return getSelectable(
      get(this.props, 'passengers', []),
      [],
      'fullName',
      'rolePassenger'
    );
  }

  getSelectableLocations() {
    // // when returning to this, need a mechanism to add a searchable field in getSelectable
    // return getSelectable(
    //   get(this.props, 'locations', []),
    //   [],
    //   'fullName',
    //   'rolePassenger'
    // );
    return get(this.props, 'locations', []).map((l) => ({
      id: l.id,
      name: l.long_name,
      searchable_name: compact([
        l.short_name,
        l.shorthand_name,
        l.long_name,
        l.gps_waypoint_name,
      ]).join(''),
    }));
  }

  render() {
    let activeIndex = -1;
    const activeFlightSegments = get(this.props, 'flight_segments_attributes', []).filter(
      (fs) => !fs._destroy
    );
    const activePrevEndAts = this.getPrevEndAts(activeFlightSegments);
    const selectablePassengers = this.getSelectablePassengers();
    const selectableLocations = this.getSelectableLocations();
    return (
      <Col sm={12}>
        {this.props.flightSegments.fields.map((field, index) => {
          const values = get(this.props, field, {});
          if (!values._destroy) {
            activeIndex += 1;
            return (
              <FlightSegment
                key={`fs-${field}`}
                index={index}
                activeIndex={activeIndex}
                change={this.props.change}
                field={field}
                isMd={this.props.containerWidth < 1200}
                isSm={this.props.containerWidth < 992}
                isXs={this.props.containerWidth < 768}
                formValues={values}
                handleDeleteClicked={this.handleDeleteClicked}
                handleAddFlightSegmentModalClicked={
                  this.props.handleAddFlightSegmentModalClicked
                }
                selectablePassengers={selectablePassengers}
                addingPassenger={this.props.addingPassenger}
                handleAddPassengerModalClicked={this.props.handleAddPassengerModalClicked}
                handlePassengerChanged={this.props.handlePassengerChanged}
                selectableLocations={selectableLocations}
                handleStartLocationChanged={this.props.handleStartLocationChanged}
                handleEndLocationChanged={this.props.handleEndLocationChanged}
                handleCriticalLocationModalClicked={
                  this.props.handleCriticalLocationModalClicked
                }
                prevEndAt={activePrevEndAts[activeIndex]}
                bookingPilotId={this.props.pilot_id}
                bookingCopilotId={this.props.copilot_id}
                bookingAircraftId={this.props.aircraft_id}
                bookingAircraftConfigurationId={this.props.aircraft_configuration_id}
                bookingAircraftSeatConfigurations={
                  this.props.bookingAircraftSeatConfigurations
                }
                bookingAircraftHoldConfigurations={
                  this.props.bookingAircraftHoldConfigurations
                }
                bookingAircraftTankConfigurations={
                  this.props.bookingAircraftTankConfigurations
                }
                bookingAircraftAssets={this.props.bookingAircraftAssets}
                bookingExternalLoadPoints={this.props.bookingExternalLoadPoints}
                aircraftsDataSelector={this.props.aircraftsDataSelector}
                contactsDataSelector={this.props.contactsDataSelector}
                locationsDataSelector={this.props.locationsDataSelector}
                flightSegmentCalculatedData={get(
                  this.props.flightSegmentsCalculatedData,
                  [activeIndex],
                  {}
                )}
              />
            );
          }
          return undefined;
        })}
      </Col>
    );
  }
}

export default FlightSegmentFieldArray;
