/* eslint-disable react/jsx-props-no-spreading */
import InputField from './input_field';
import HelpBlock from './help_block';
import InvalidBlock from './invalid_block';
import TagList from '../tag_list';

const TagField = (props) => {
  const {
    input,
    meta,
    labelWidth,
    inputWidth,
    size,
    helpText,
    placeholderText,
    children,
    suggestions,
    handleTagCreate,
  } = props;

  return (
    <InputField
      size={size}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      input={{
        name: input.name,
      }}
      innerContent={
        <>
          <TagList
            suggestions={suggestions}
            tagCollectionInput={input}
            onTagCreate={handleTagCreate}
            placeholderText={placeholderText}
          />
          <InvalidBlock meta={meta} helpText={helpText} force />
          <HelpBlock meta={meta} helpText={helpText} />
        </>
      }
    >
      {children}
    </InputField>
  );
};

TagField.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 9,
  helpText: '',
  placeholderText: '',
};

export default TagField;
