const PilotFlightLogTableHeader = () => (
  <thead>
    <tr>
      <th colSpan={2} className="border-0">
        &nbsp;
      </th>
      <th colSpan={4} className="border-top-0 text-center">
        Day
      </th>
      <th className="border-0">&nbsp;</th>
      <th colSpan={4} className="border-top-0 text-center">
        Night
      </th>
      <th colSpan={2} className="border-0">
        &nbsp;
      </th>
    </tr>
    <tr>
      <th className="border-top-0">Pilot</th>
      <th style={{ width: '7%' }} className="border-top-0 text-start">
        Multi Engine
      </th>
      <th style={{ width: '7%' }} className="border-top-0 text-center">
        PIC
      </th>
      <th style={{ width: '7%' }} className="border-top-0 text-center">
        Copilot
      </th>
      <th style={{ width: '7%' }} className="border-top-0 text-center">
        Dual
      </th>
      <th style={{ width: '7%' }} className="border-top-0 text-center">
        Command Practice
      </th>
      <th style={{ width: '1%' }} className="border-top-0">
        &nbsp;
      </th>
      <th style={{ width: '7%' }} className="border-top-0 text-center">
        PIC
      </th>
      <th style={{ width: '7%' }} className="border-top-0 text-center">
        Copilot
      </th>
      <th style={{ width: '7%' }} className="border-top-0 text-center">
        Dual
      </th>
      <th style={{ width: '7%' }} className="border-top-0 text-center">
        Command Practice
      </th>
      <th style={{ width: '1%' }} className="border-top-0">
        &nbsp;
      </th>
      <th style={{ width: '200px' }} className="border-top-0 text-start">
        Other Flight Types
      </th>
      <th style={{ width: '40px' }} className="border-top-0 text-end">
        &nbsp;
      </th>
    </tr>
  </thead>
);
export default PilotFlightLogTableHeader;
