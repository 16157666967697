import configureMeasurements, { mass, length, speed, volume } from 'convert-units';

mass.systems.imperial.lbs = mass.systems.imperial.lb;

volume.systems.metric.litres = volume.systems.metric.l;
volume.systems.imperial.imperial_gal = {
  name: { singular: 'Imperial Gallon', plural: 'Imperial Gallons' },
  to_anchor: 153.722,
};

length.systems.imperial.inches = length.systems.imperial.in;
length.systems.imperial.feet = length.systems.imperial.ft;

length.systems.metric.meters = length.systems.metric.m;
length.systems.metric.millimeters = length.systems.metric.mm;

speed.systems.imperial.knots = speed.systems.imperial.knot;

const roundUp = (num) => Math.ceil(num);
const roundDown = (num) => Math.floor(num);
const round0 = (num) => Math.round(num);
const round2 = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

// round10 matches the precision in the database and reduces floating point calculation errors after loading from db
const round10 = (num) => Math.round((num + Number.EPSILON) * 10000000000) / 10000000000;

const convert = configureMeasurements({ mass, length, speed, volume });

const convertFromTo = (quantity, fromUnit, toUnit) =>
  round10(convert(quantity).from(fromUnit).to(toUnit));

const convertFuelToMass = ({ fuelQuantity, fuelUnit, massUnit, specificGravity }) => {
  let result = 0;
  if (fuelQuantity && fuelUnit && massUnit && specificGravity) {
    switch (fuelUnit) {
      case 'kg':
      case 'lbs':
        result = convert(fuelQuantity).from(fuelUnit).to(massUnit);
        break;
      case 'litres':
      case 'gal':
      case 'imperial_gal':
        result = convert(convert(fuelQuantity).from(fuelUnit).to('l') * specificGravity)
          .from('kg')
          .to(massUnit);
        break;
      default:
        return 0;
    }
  }
  return round10(result);
};

const convertMassToFuel = ({ massQuantity, massUnit, fuelUnit, specificGravity }) => {
  let result = 0;
  if (massQuantity && fuelUnit && massUnit && specificGravity) {
    switch (fuelUnit) {
      case 'kg':
      case 'lbs':
        result = convert(massQuantity).from(massUnit).to(fuelUnit);
        break;
      case 'litres':
      case 'gal':
      case 'imperial_gal':
        result = convert(convert(massQuantity).from(massUnit).to('kg') / specificGravity)
          .from('l')
          .to(fuelUnit);
        break;
      default:
        return 0;
    }
  }
  return round10(result);
};

const convertFuelToFuel = ({
  fuelQuantity,
  fromFuelUnit,
  toFuelUnit,
  specificGravity,
}) => {
  let result = 0;
  if (fuelQuantity && fromFuelUnit && toFuelUnit && specificGravity) {
    switch (fromFuelUnit) {
      case 'kg':
      case 'lbs':
        result = convertMassToFuel({
          massQuantity: fuelQuantity,
          massUnit: fromFuelUnit,
          fuelUnit: toFuelUnit,
          specificGravity,
        });
        break;
      case 'litres':
      case 'gal':
      case 'imperial_gal':
        switch (toFuelUnit) {
          case 'kg':
          case 'lbs':
            result = convertFuelToMass({
              fuelQuantity,
              fuelUnit: fromFuelUnit,
              massUnit: toFuelUnit,
              specificGravity,
            });
            break;
          case 'litres':
          case 'gal':
          case 'imperial_gal':
            result = convert(fuelQuantity).from(fromFuelUnit).to(toFuelUnit);
            break;
          default:
            return 0;
        }
        break;
      default:
        return 0;
    }
  }
  return round10(result);
};

export {
  convert,
  convertFromTo,
  convertFuelToMass,
  convertMassToFuel,
  convertFuelToFuel,
  round0,
  round2,
  round10,
  roundUp,
  roundDown,
};
