import compact from 'lodash.compact';
import difference from 'lodash.difference';
import flatten from 'lodash.flatten';
import intersection from 'lodash.intersection';
import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';
import uniq from 'lodash.uniq';

import { kebabCase } from 'change-case';

// const getAvailableDutyEventContacts = (dutyEvents, bookings, bookingView) => {
const getGroupedContacts = ({ bookings, bookingView, dutyEvents, allViewPilotIds }) => {
  const { default_booking_view: defaultBookingView, viewPilots } = bookingView;
  const viewPilotIds = viewPilots.map((p) => p.id);
  const bookingPilotIds = uniq(
    flatten(bookings.map((b) => compact([b.pilot_id, b.copilot_id])))
  );
  const pilotIds = defaultBookingView
    ? difference(bookingPilotIds, allViewPilotIds)
    : intersection(viewPilotIds, bookingPilotIds);

  const bookingEmployeesIds = uniqBy(
    flatten(bookings.map((b) => compact(b.employees))),
    'id'
  ).map((c) => c.id);

  const employeeIds = defaultBookingView
    ? difference(bookingEmployeesIds, allViewPilotIds)
    : intersection(viewPilotIds, bookingEmployeesIds);

  const availableDutyEvents = dutyEvents.filter((de) => de.contact_available);
  const availableDutyEventContacts = sortBy(
    uniqBy(
      availableDutyEvents.map(({ contact }) => contact),
      'id'
    ),
    ({ fullName, roles = [] }) => {
      const hasPilotRole = roles.some((role) => role.pilot);
      return [hasPilotRole ? 0 : 1, fullName];
    }
  );
  const availableContactIds = availableDutyEventContacts.map((c) => c.id);

  const unavailableDutyEvents = dutyEvents.filter((de) => !de.contact_available);
  const unavailableDutyEventContacts = sortBy(
    uniqBy(
      unavailableDutyEvents.map(({ short_name: dutyEventShortName, contact }) => ({
        ...contact,
        dutyEventShortName,
      })),
      'id'
    ),
    ({ dutyEventShortName, fullName, roles = [] }) => {
      const hasPilotRole = roles.some((role) => role.pilot);
      return [hasPilotRole ? 0 : 1, dutyEventShortName, fullName];
    }
  );
  const unavailableContactIds = unavailableDutyEventContacts.map((c) => c.id);
  const unrosteredPilotIds = difference(pilotIds, availableContactIds);
  const unavailableEmployeeIds = difference(employeeIds, availableContactIds);
  const unrosteredEmployeeIds = difference(unavailableEmployeeIds, unavailableContactIds);
  const unrosteredPilots = bookings
    .filter(
      (b) =>
        unrosteredPilotIds.includes(b.pilot_id) ||
        unrosteredPilotIds.includes(b.copilot_id)
    )
    .map((booking) => {
      const { pilot, copilot } = booking;
      let result = [];
      if (pilot && unrosteredPilotIds.includes(pilot.id)) {
        result = [...result, pilot];
      }
      if (copilot && unrosteredPilotIds.includes(copilot.id)) {
        result = [...result, copilot];
      }
      return result;
    });

  const unrosteredEmployees = bookings
    .filter(
      (b) =>
        intersection(
          unrosteredEmployeeIds,
          b.employees.map(({ id }) => id)
        ).length > 0
    )
    .map((booking) => {
      const { employees } = booking;
      return employees.filter(({ id }) => unrosteredEmployeeIds.includes(id));
    });

  const uniqUnrosteredPilots = sortBy(uniqBy(flatten(unrosteredPilots), 'id'), [
    'fullName',
  ]);

  const uniqUnrosteredEmployees = sortBy(uniqBy(flatten(unrosteredEmployees), 'id'), [
    'fullName',
  ]);

  const groupedContacts = {
    availableWithFlightContacts: availableDutyEventContacts.filter((c) =>
      pilotIds.includes(c.id)
    ),
    availableWithoutFlightContacts: availableDutyEventContacts.filter(
      (c) => !pilotIds.includes(c.id)
    ),
    unrosteredWithFlightContacts: uniqUnrosteredPilots,
    unrosteredWithoutFlightContacts: uniqUnrosteredEmployees,
    unavailableDutyEventContacts,
  };
  return groupedContacts;
};

// const getUnavailableDutyEventContacts = (dutyEvents, bookingView) => {
//   const { default_booking_view: defaultBookingView } = bookingView;
//   // if (defaultBookingView) {
//   //   return [];
//   // }
//   const unavailableDutyEvents = dutyEvents.filter((de) => !de.contact_available);
//   return sortBy(
//     uniqBy(
//       unavailableDutyEvents.map(({ contact }) => contact),
//       'id'
//     ),
//     ({ fullName, roles = [] }) => {
//       const hasPilotRole = roles.some((role) => role.pilot);
//       return [hasPilotRole ? 0 : 1, fullName];
//     }
//   );
// };

// const getBookingContacts = (bookings, bookingView) => {
//   const { default_booking_view: defaultBookingView, viewPilots } = bookingView;
//   const groupedBookings = bookings.reduce(
//     (result, booking) => {
//       const { pilot, copilot, employees, calendar_type: calendarType } = booking;
//       const contacts = [...employees, pilot, copilot].filter((c) => c);
//       // console.log(contacts);
//       contacts.forEach((contact) => {
//         if (
//           (calendarType !== 'flight' && defaultBookingView) ||
//           // some bookings come through with copilots etc, or shared meetings
//           viewPilots.find((vp) => vp.id === contact.id)
//         ) {
//           if (contact.roles.some((r) => r.employee)) {
//             result.employees.push(contact);
//           } else {
//             // eslint-disable-next-line no-lonely-if
//             if (contact.roles.some((r) => r.pilot)) {
//               result.otherPilots.push(contact);
//             } else {
//               result.nonEmployees.push(contact);
//             }
//           }
//         } else {
//           result.nonEmployees.push(contact);
//         }
//       });
//       return result;
//     },
//     { employees: [], otherPilots: [], nonEmployees: [] }
//   );
//   const resources = sortBy(
//     [
//       ...uniqBy(groupedBookings.employees, 'id'),
//       ...uniqBy(groupedBookings.otherPilots, 'id'),
//     ],
//     ['position', 'fullName']
//   );
//   // console.log(resources);
//   return resources;
// };

// const getContactResource = ({ fullName }, groupId, index) => ({
//   // id: kebabCase(fullName),
//   id: `${kebabCase(fullName)}-orig`,
//   title: fullName,
//   groupId,
//   extendedProps: {
//     position: index,
//   },
// });

const getNewContactResource = ({ fullName }, groupId, index, indexAdd = 0) => ({
  id: `${kebabCase(fullName)}-test`,
  title: fullName,
  groupId,
  extendedProps: {
    position: (index + indexAdd).toString(), // position is used by calendar to order resources.  This keeps things in 'groups' without using groups
  },
});

const getContactResources = ({
  bookings,
  dutyEvents,
  bookingView,
  allViewPilotIds,
  resourceGroupContact,
}) => {
  // console.log(bookingView.name);
  // const bookingContacts = getBookingContacts(bookings, bookingView);
  // const bookingContactResources = bookingContacts.map((contact, index) =>
  //   getContactResource(contact, '', index)
  // );
  // const flightAndMeetingBookings = bookings.filter(
  //   ({ calendar_type: calendarType }) =>
  //     calendarType === 'flight' || calendarType === 'meeting'
  // );
  const {
    availableWithFlightContacts,
    availableWithoutFlightContacts,
    unrosteredWithFlightContacts,
    unrosteredWithoutFlightContacts,
    unavailableDutyEventContacts,
  } = getGroupedContacts({ bookings, bookingView, dutyEvents, allViewPilotIds });

  const availableWithFlightResources = availableWithFlightContacts.map((contact, index) =>
    getNewContactResource(
      contact,
      resourceGroupContact ? 'Contact' : 'Available Flying',
      index,
      100
    )
  );
  const unrosteredWithFlightResources = unrosteredWithFlightContacts.map(
    (contact, index) =>
      getNewContactResource(
        contact,
        resourceGroupContact ? 'Contact' : 'Unrostered Flying',
        index,
        200
      )
  );
  const availableWithoutFlightResources = availableWithoutFlightContacts.map(
    (contact, index) =>
      getNewContactResource(
        contact,
        resourceGroupContact ? 'Contact' : 'Available Free',
        index,
        300
      )
  );
  const unrosteredWithoutFlightResources = unrosteredWithoutFlightContacts.map(
    (contact, index) =>
      getNewContactResource(
        contact,
        resourceGroupContact ? 'Contact' : 'Meeting',
        index,
        400
      )
  );
  const unavailableDutyEventResources = unavailableDutyEventContacts.map(
    (contact, index) =>
      getNewContactResource(
        contact,
        resourceGroupContact ? 'Contact' : 'Unavailable',
        index,
        500
      )
  );

  return [
    // ...(resourceGroupContact ? [] : bookingContactResources),
    ...availableWithFlightResources,
    ...availableWithoutFlightResources,
    ...unrosteredWithFlightResources,
    ...unrosteredWithoutFlightResources,
    ...unavailableDutyEventResources,
  ];
};

export default getContactResources;
