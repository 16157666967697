import { Fields } from 'redux-form';

import get from 'lodash.get';
import last from 'lodash.last';

import WeightBalanceExternalLoadAssignmentFields from './weight_balance_external_load_assignment_fields';

const WeightBalanceExternalLoadAssignmentFieldArray = (props) => {
  const { formValues, externalLoadAssignments, isDeveloper } = props;
  return (
    <tbody>
      {externalLoadAssignments.fields.map((field, index) => {
        const externalLoadAssignmentField = last(field.split('.'));
        const values = get(formValues, externalLoadAssignmentField, {});
        if (!values._destroy) {
          const fields = [`${field}.external_load_assignment_weight`];
          return (
            <Fields
              key={field}
              index={index}
              parentField={field}
              names={fields}
              component={WeightBalanceExternalLoadAssignmentFields}
              formValues={values}
              isDeveloper={isDeveloper}
            />
          );
        }
        return undefined;
      })}
    </tbody>
  );
};

export default WeightBalanceExternalLoadAssignmentFieldArray;
