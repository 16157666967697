import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';

import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';
import last from 'lodash.last';

import InputField from '../form/input_field';

moment.updateLocale('en-nz');

class BookingHobbRecordFields extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.formValues.aircraft_id) {
      this.receiveAircraftId();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let flightTime = '';
    if (this.props.formValues.aircraft_id !== nextProps.formValues.aircraft_id) {
      this.receiveAircraftId(nextProps, true);
    }
    if (
      get(nextProps, 'formValues.hobb_record_attributes.start_hobb', '') &&
      get(nextProps, 'formValues.hobb_record_attributes.end_hobb', '')
    ) {
      flightTime =
        Math.round(
          (get(nextProps, 'formValues.hobb_record_attributes.end_hobb') -
            get(nextProps, 'formValues.hobb_record_attributes.start_hobb')) *
            100
        ) / 100;
    }
    if (flightTime !== get(nextProps, 'formValues.hobb_record_attributes.flight_time')) {
      const input = get(nextProps, 'hobb_record_attributes.flight_time.input');
      input.onChange(flightTime);
    }
  }

  receiveAircraftId(props = this.props) {
    const aircraftId = defaultTo(
      parseInt(get(props, 'formValues.aircraft_id', ''), 10),
      ''
    );
    const hobbAircraftId = defaultTo(
      parseInt(get(props, 'formValues.hobb_record_attributes.aircraft_id', ''), 10),
      ''
    );
    if (aircraftId !== hobbAircraftId) {
      const aircraftIdInput = get(props, 'hobb_record_attributes.aircraft_id.input');
      const endInput = get(props, 'hobb_record_attributes.end_hobb.input');
      const startInput = get(props, 'hobb_record_attributes.start_hobb.input');
      const candidates = get(this.props.aircraftCandidateHobbsDataSelector, aircraftId, {
        starts: [],
        ends: [],
      });
      aircraftIdInput.onChange(aircraftId);
      startInput.onChange(defaultTo(last(candidates.starts), ''));
      endInput.onChange('');
    }
  }

  renderFlightTime() {
    const value = get(this.props, 'formValues.hobb_record_attributes.flight_time', '');
    return value !== '' ? parseFloat(value).toFixed(1) : '';
  }

  render() {
    const {
      start_hobb: { input: startHobbInput, meta: startHobbMeta },
      end_hobb: { input: endHobbInput, meta: endHobbMeta },
    } = get(this.props, 'hobb_record_attributes');

    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Hobb Record</strong>
          </p>
        </Col>
        <Col sm={10}>
          <InputField
            labelWidth={2}
            inputWidth={2}
            type="text"
            input={startHobbInput}
            meta={startHobbMeta}
            size="sm"
            blurOnly
          >
            Start Hobb
          </InputField>
          <InputField
            labelWidth={2}
            inputWidth={2}
            type="text"
            input={endHobbInput}
            meta={endHobbMeta}
            size="sm"
            blurOnly
          >
            End Hobb
          </InputField>
          <InputField
            labelWidth={2}
            inputWidth={2}
            type="text"
            input={{
              value: this.renderFlightTime(),
              name: 'booking_hobb_record_end_hobb',
            }}
            size="sm"
            readOnly
            plaintext
            meta={endHobbMeta}
          >
            Flight Time
          </InputField>
        </Col>
      </Row>
    );
  }
}

export default BookingHobbRecordFields;
