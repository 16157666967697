import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import QueueLink from 'apollo-link-queue';

import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
// import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
// import localForage from 'localforage';

import get from 'lodash.get';
import pick from 'lodash.pick';

import subscriptionClient from './subscription_client';
import {
  getIdToken,
  apolloSchemaVersionKey,
  // apolloCachePersistKey,
} from './local_storage';
import hydrateQuery from '../queries/hydrate_query';

const expectedSchemaVersion = '1';
const currentSchemaVersion = localStorage.getItem(apolloSchemaVersionKey);

const httpLinkOptions = {
  uri: `${process.env.EXPRESS_API_URL}/graphql`,
  headers: { 'Apollo-Require-Preflight': 'true' },
};

const noBatchLinkSplit = ApolloLink.split(
  (operation) => operation.getContext().noBatch,
  new HttpLink(httpLinkOptions),
  new BatchHttpLink(httpLinkOptions)
);

const hasUploadLinkSplit = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink(httpLinkOptions),
  noBatchLinkSplit
);

const operationDefinitionLinkSplit = ApolloLink.split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  new GraphQLWsLink(subscriptionClient),
  hasUploadLinkSplit
);

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${getIdToken()}`,
    },
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const reportError = window.$NODE_ENV !== 'development';
  let isUnauthorized = false;
  let isForbidden = false;

  if (networkError) {
    isUnauthorized = networkError.statusCode === 401;
    if (isUnauthorized && reportError) {
      window.Rollbar.info(networkError, networkError.result);
    }
  }
  if (graphQLErrors && Array.isArray(graphQLErrors)) {
    isUnauthorized = graphQLErrors.some(
      (graphQLError) => get(graphQLError, 'extensions.code') === 'UNAUTHENTICATED'
    );
    if (isUnauthorized && reportError) {
      const graphQLError = graphQLErrors.find(
        (err) => get(err, 'extensions.code') === 'UNAUTHENTICATED'
      );
      window.Rollbar.info('UNAUTHENTICATED', graphQLError);
    }

    isForbidden = graphQLErrors.some(
      (graphQLError) => get(graphQLError, 'extensions.code') === 'FORBIDDEN'
    );
    if (isForbidden && reportError) {
      const graphQLError = graphQLErrors.find(
        (err) => get(err, 'extensions.code') === 'FORBIDDEN'
      );
      window.Rollbar.info('FORBIDDEN', graphQLError);
    }
  }

  if (window.$NODE_ENV === 'development') {
    console.log('NETWORK: ', JSON.stringify(networkError, undefined, 2));
    console.log('GRAPHQL: ', JSON.stringify(graphQLErrors, undefined, 2));
    console.log({ reportError, isUnauthorized, isForbidden });
  }

  if (isUnauthorized || isForbidden) {
    // TODO brittle
    // console.log(window.location.pathname);
    if (!window.location.pathname.includes('login')) {
      window.location = '/auth/logout';
    }
  } else if (networkError && reportError) {
    window.Rollbar.error(networkError, networkError.result);
  } else if (graphQLErrors && Array.isArray(graphQLErrors) && reportError) {
    graphQLErrors.forEach((graphQLError) => {
      const code = get(graphQLError, 'extensions.code', '[GraphQL error]');
      if (code === 'BAD_USER_INPUT') {
        window.Rollbar.info(
          code,
          pick(get(graphQLError, ['extensions']), ['code', 'data', 'config'])
        );
      } else {
        window.Rollbar.error(code, graphQLError);
      }
    });
  }
});

const queueLink = new QueueLink();
queueLink.close();

const link = ApolloLink.from([
  queueLink,
  errorLink,
  authLink,
  operationDefinitionLinkSplit,
]);

const cache = new InMemoryCache({
  addTypename: true,
  possibleTypes: {
    CheckableType: ['ContactType', 'OtherAssetType'],
  },
  typePolicies: {
    BookingType: {
      fields: {
        bookingEmployees: {
          merge: false,
        },
        employees: {
          merge: false,
        },
        bookingChargeables: {
          merge: false,
        },
        chargeables: {
          merge: false,
        },
        flightSegments: {
          merge: false,
        },
        flightSegmentSumaries: {
          merge: false,
        },
        engineEvents: {
          merge: false,
        },
        fuelTankerFills: {
          merge: false,
        },
        fuelBowserFills: {
          merge: false,
        },
        oilFills: {
          merge: false,
        },
        pilotFlightExpenses: {
          merge: false,
        },
        pilotFlightLogs: {
          merge: false,
        },
      },
    },
    ContactType: {
      fields: {
        activations: {
          merge: false,
        },
        addresses: {
          merge: false,
        },
        documents: {
          merge: false,
        },
        phoneNumbers: {
          merge: false,
        },
        providerAircrafts: {
          merge: false,
        },
        relatedAircrafts: {
          merge: false,
        },
        relatedChargeables: {
          merge: false,
        },
        relatedPilots: {
          merge: false,
        },
        roles: {
          merge: false,
        },
        statutoryRoles: {
          merge: false,
        },
        taggings: {
          merge: false,
        },
      },
    },
    FlightSegmentType: {
      fields: {
        seatAssignments: {
          merge: false,
        },
        holdAssignments: {
          merge: false,
        },
        tankAssignments: {
          merge: false,
        },
        assetAssignments: {
          merge: false,
        },
        externalLoadAssignments: {
          merge: false,
        },
      },
    },
    WbLimitType: {
      fields: {
        wbLimitPoints: {
          merge: false,
        },
      },
    },
    LocationType: {
      fields: {
        locationImages: {
          merge: false,
        },
        fuelBowsers: {
          merge: false,
        },
        namedPilots: {
          merge: false,
        },
        locationServices: {
          merge: false,
        },
        locationImageLargeUrls: {
          merge: false,
        },
      },
    },
    Query: {
      fields: {
        bookingList: {
          merge: false,
        },
        contactList: {
          merge: false,
        },
        dutyEventList: {
          merge: false,
        },
        locationList: {
          merge: false,
        },
        documentList: {
          merge: false,
        },
        checkList: {
          merge: false,
        },
      },
    },
  },
});

// const apolloPersistor = new CachePersistor({
//   cache,
//   // storage: localStorage,
//   // 5 MB
//   // maxSize: 5242880,
//   storage: new LocalForageWrapper(localForage),
//   key: apolloCachePersistKey,
//   // 10 MB
//   maxSize: 10485760,
//   // debug: true,
// });

const apolloPersistor = {
  restore: () => console.log('restore apollo cache'),
  purge: () => console.log('purge apollo cache'),
};

const apolloClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: process.env.NODE_ENV !== 'production',
  // // eslint-disable-next-line no-underscore-dangle
  // cache: cache.restore(window.__APOLLO_STATE__ || {}),
  // shouldBatch: true,
  // local state
  // https://blog.apollographql.com/announcing-apollo-client-2-5-c12230cabbb7
  // resolvers: { ... },
  // typeDefs: { ... },
});

const restoreApolloCache = async () => {
  if (currentSchemaVersion === expectedSchemaVersion) {
    await apolloPersistor.restore();
  } else {
    await apolloPersistor.purge();
    localStorage.setItem(apolloSchemaVersionKey, expectedSchemaVersion);
  }
};

const hydrateCache = async () => {
  apolloClient.query({
    query: hydrateQuery,
    fetchPolicy: 'network-only',
    context: { noBatch: true },
  });
};

export { queueLink, restoreApolloCache, apolloClient, subscriptionClient, hydrateCache };
