import { useCallback, useMemo, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';

import Field from '../components/form/react_final_form_field';
import OnChangeField from '../components/form/rff_on_change_field';

import { currentSettingsSet } from '../actions/current_setting_actions';
import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import FormButtons from '../components/form/form_buttons';
import InputField from '../components/form/input_field';

import { toastSuccess, toastError } from '../lib/action_helpers';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import aircraftConfigurationCreateMutation from '../mutations/aircraft_configuration_create_mutation';
import aircraftConfigurationUpdateMutation from '../mutations/aircraft_configuration_update_mutation';
import pageAircraftConfigurationFormQuery from '../queries/page_aircraft_configuration_form_query';
import { aircraftConfigurationFormValidator } from '../validators';
import { aircraftConfigurationDefaultValues } from '../defaults';
import { aircraftConfigurationWhiteList } from '../white_lists';

const AircraftConfigurationForm = () => {
  const dispatch = useDispatch();
  const [aircraftTypeId, setAircraftTypeId] = useState('');
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const currentSettingsWbLimitLimitTypeLateral = useSelector(
    (state) => state.currentSettings.wb_limit_limit_type_lateral
  );
  const currentSettingsWbLimitLimitTypeLongitudinal = useSelector(
    (state) => state.currentSettings.wb_limit_limit_type_longitudinal
  );
  const [aircraftConfigurationUpdate] = useMutation(aircraftConfigurationUpdateMutation);
  const [aircraftConfigurationCreate] = useMutation(aircraftConfigurationCreateMutation);
  const navigate = useNavigate();
  const params = useParams();

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageAircraftConfigurationFormQuery, {
    variables: {
      hasAircraftConfigurationId: !!params.id,
      aircraftConfigurationId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setAircraftTypeId(get(data, 'aircraftConfiguration.aircraft_type_id', ''));
    },
  });

  const pageLoadedOrRefetching = useMemo(
    () =>
      !pageLoading ||
      (pageLoading &&
        [NetworkStatus.refetch, NetworkStatus.setVariables].includes(pageNetworkStatus)),
    [pageLoading, pageNetworkStatus]
  );

  const onAircraftTypeChange = useCallback(
    (form, value, previous) => {
      if (value !== previous) {
        setAircraftTypeId(parseInt(value, 10) || '');
        form.batch(() => {
          form.change('external_load_point_id', '');
          form.change('hold_configuration_id', '');
          form.change('tank_configuration_id', '');
          form.change('seat_configuration_id', '');
          form.change('wb_lateral_limit_id', '');
          form.change('wb_longitudinal_limit_id', '');
          form.change('wb_external_lateral_limit_id', '');
          form.change('wb_external_longitudinal_limit_id', '');
        });
      }
    },
    [setAircraftTypeId]
  );

  const getSelectable = useCallback(
    (list, limitType) => {
      if (aircraftTypeId) {
        return list
          .filter(
            (m) =>
              m.aircraft_type_id === aircraftTypeId &&
              (!limitType || m.limit_type === limitType)
          )
          .map((m) => ({
            id: m.id,
            name: m.name,
          }));
      }
      return [];
    },
    [aircraftTypeId]
  );

  const onFormSubmit = useCallback(
    async (data) => {
      let mutation;
      let mutationMessageAction;
      const submitData = cloneDeep(data);
      const mutationData = {
        variables: { input: coerceInput(submitData) },
      };
      if (params.id) {
        mutationData.variables.id = params.id;
        mutation = aircraftConfigurationUpdate;
        mutationMessageAction = 'update';
      } else {
        mutation = aircraftConfigurationCreate;
        mutationMessageAction = 'create';
      }
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await mutation(mutationData);
        toastSuccess(`Aircraft Configuration ${mutationMessageAction} succeeded`);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        navigate('/aircraft_configurations');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
      return undefined;
    },
    [
      params.id,
      aircraftConfigurationCreate,
      aircraftConfigurationUpdate,
      dispatch,
      navigate,
    ]
  );

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title form updating={!!params.id}>
            Aircraft Configuration
          </Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <LinkContainer to="/aircraft_configurations">
                <Button size="sm" variant="primary">
                  All Aircraft Configurations
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={pickValues(
              pageData.aircraftConfiguration || aircraftConfigurationDefaultValues,
              aircraftConfigurationWhiteList
            )}
            onSubmit={onFormSubmit}
            validate={aircraftConfigurationFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting, form }) => (
              <form noValidate onSubmit={handleSubmit}>
                <OnChangeField name="aircraft_type_id">
                  {(value, previous) => onAircraftTypeChange(form, value, previous)}
                </OnChangeField>
                <fieldset className="border rounded-3 p-3">
                  <legend className="float-none w-auto px-3 fs-6">
                    Aircraft Configuration Details
                  </legend>
                  <Field
                    type="text"
                    name="name"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                  >
                    Name
                  </Field>
                  <Field
                    type="text"
                    name="description"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="textarea"
                    rows={10}
                  >
                    Description
                  </Field>
                  <Field
                    type="text"
                    name="aircraft_type_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="select"
                    selectOptions={pageData.aircraftTypeList.map(({ id, name }) => ({
                      id,
                      name,
                    }))}
                  >
                    Aircraft Type
                  </Field>
                  <Field
                    type="text"
                    name="wb_longitudinal_limit_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="select"
                    selectOptions={getSelectable(
                      pageData.wbLimitList,
                      currentSettingsWbLimitLimitTypeLongitudinal
                    )}
                  >
                    WB Longitudinal Limit
                  </Field>
                  <Field
                    type="text"
                    name="wb_lateral_limit_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="select"
                    selectOptions={getSelectable(
                      pageData.wbLimitList,
                      currentSettingsWbLimitLimitTypeLateral
                    )}
                  >
                    WB Lateral Limit
                  </Field>
                  <Field
                    type="text"
                    name="wb_external_longitudinal_limit_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="select"
                    selectOptions={getSelectable(
                      pageData.wbLimitList,
                      currentSettingsWbLimitLimitTypeLongitudinal
                    )}
                  >
                    WB Longitudinal Limit with External Load
                  </Field>
                  <Field
                    type="text"
                    name="wb_external_lateral_limit_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="select"
                    selectOptions={getSelectable(
                      pageData.wbLimitList,
                      currentSettingsWbLimitLimitTypeLateral
                    )}
                  >
                    WB Lateral Limit with External Load
                  </Field>
                  <Field
                    type="text"
                    name="seat_configuration_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="select"
                    selectOptions={getSelectable(pageData.seatConfigurationList)}
                  >
                    Seat Configurations
                  </Field>

                  <Field
                    type="text"
                    name="hold_configuration_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="select"
                    selectOptions={getSelectable(pageData.holdConfigurationList)}
                  >
                    Hold Configurations
                  </Field>
                  <Field
                    type="text"
                    name="tank_configuration_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="select"
                    selectOptions={getSelectable(pageData.tankConfigurationList)}
                  >
                    Tank Configurations
                  </Field>
                  <Field
                    type="text"
                    name="external_load_point_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="select"
                    selectOptions={getSelectable(pageData.externalLoadPointList)}
                  >
                    External Load Point
                  </Field>
                </fieldset>
                <Row>
                  <FormButtons
                    updating={!!params.id}
                    pristine={pristine}
                    submitting={submitting}
                    cancelLink="/aircraft_configurations"
                  />
                </Row>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default AircraftConfigurationForm;
