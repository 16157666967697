import { Component } from 'react';

import get from 'lodash.get';

import InputField from '../form/input_field';
import { getSelectable } from '../../lib/utils';

class AircraftInputField extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.provider_id', '') !==
      get(nextProps, 'formValues.provider_id', '')
    ) {
      if (get(nextProps, 'formValues.provider_id')) {
        const provider = this.props.providers.find(
          (c) => c.id === parseInt(get(nextProps, 'formValues.provider_id'), 10)
        );
        let defaultAircraftId = '';
        if (get(provider, 'default_aircraft_id')) {
          defaultAircraftId = get(provider, 'default_aircraft_id');
        } else if (get(provider, 'use_first_if_no_default_aircraft')) {
          defaultAircraftId = get(provider, ['providerAircrafts', 0, 'id']);
        }
        this.props.input.onChange(defaultAircraftId);
      } else {
        this.props.input.onChange('');
      }
    }
  }

  getSelectableAircrafts() {
    return getSelectable(
      this.props.aircrafts,
      get(this.props, 'formValues.aircraft_id'),
      'registration_abbreviated'
    );
  }

  render() {
    const { input, meta } = this.props;

    return (
      <InputField
        type="text"
        size="sm"
        labelWidth={4}
        inputWidth={8}
        asElement="select"
        selectOptions={this.getSelectableAircrafts()}
        input={input}
        meta={meta}
      >
        {this.props.children}
      </InputField>
    );
  }
}

export default AircraftInputField;
