import { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import EnvelopeChart from '../wb_limit_form/envelope_chart';

const WeightBalanceLongCg = (props) => {
  const { flightSegmentCalculatedData } = props;

  const {
    bookingArmUnit,
    bookingWeightUnit,
    convertedZeroFuelAircraft,
    convertedZeroFuelAircraftWithExternalLoad,
    convertedAllUpAircraft,
    convertedAllUpAircraftWithExternalLoad,
    convertedWbLongitudinalLimitPoints,
    convertedWbExternalLongitudinalLimitPoints,
  } = flightSegmentCalculatedData;

  const [limitsCollection, setLimitsCollection] = useState([]);
  const [externalLimitsCollection, setExternalLimitsCollection] = useState([]);
  const [fuelBurnCollection, setFuelBurnCollection] = useState([]);
  const [fuelBurnWithExternalLoadCollection, setFuelBurnWithExternalLoadCollection] =
    useState([]);

  useEffect(() => {
    let newLimitsCollection = [];
    if (convertedWbLongitudinalLimitPoints) {
      newLimitsCollection = convertedWbLongitudinalLimitPoints.map((point) => {
        const {
          converted_wb_limit_arm: x,
          converted_wb_limit_weight: y,
          position,
        } = point;
        return { name: 'Limit', x, y, position };
      });
    }
    setLimitsCollection(newLimitsCollection);
  }, [convertedWbLongitudinalLimitPoints]);

  useEffect(() => {
    let newExternalLimitsCollection = [];
    if (convertedWbExternalLongitudinalLimitPoints) {
      newExternalLimitsCollection = convertedWbExternalLongitudinalLimitPoints.map(
        (point) => {
          const {
            converted_wb_limit_arm: x,
            converted_wb_limit_weight: y,
            position,
          } = point;
          return { name: 'Limit', x, y, position };
        }
      );
    }
    setExternalLimitsCollection(newExternalLimitsCollection);
  }, [convertedWbExternalLongitudinalLimitPoints]);

  useEffect(() => {
    let newFuelBurnCollection = [];
    if (convertedZeroFuelAircraft.id && convertedAllUpAircraft.id) {
      newFuelBurnCollection = [
        {
          name: 'All Up Weight',
          x: convertedAllUpAircraft.longitudinalArm,
          y: convertedAllUpAircraft.weight,
        },
        {
          name: 'Zero Fuel Weight',
          x: convertedZeroFuelAircraft.longitudinalArm,
          y: convertedZeroFuelAircraft.weight,
        },
      ];
    }
    setFuelBurnCollection(newFuelBurnCollection);
  }, [convertedZeroFuelAircraft, convertedAllUpAircraft]);

  useEffect(() => {
    let newFuelBurnWithExternalLoadCollection = [];
    if (
      convertedZeroFuelAircraftWithExternalLoad.id &&
      convertedAllUpAircraftWithExternalLoad.id &&
      convertedAllUpAircraft.weight !== convertedAllUpAircraftWithExternalLoad.weight
    ) {
      newFuelBurnWithExternalLoadCollection = [
        {
          name: 'All Up Weight With External Load',
          x: convertedAllUpAircraftWithExternalLoad.longitudinalArm,
          y: convertedAllUpAircraftWithExternalLoad.weight,
        },
        {
          name: 'Zero Fuel Weight With External Load',
          x: convertedZeroFuelAircraftWithExternalLoad.longitudinalArm,
          y: convertedZeroFuelAircraftWithExternalLoad.weight,
        },
      ];
    }
    setFuelBurnWithExternalLoadCollection(newFuelBurnWithExternalLoadCollection);
  }, [
    convertedAllUpAircraft,
    convertedZeroFuelAircraftWithExternalLoad,
    convertedAllUpAircraftWithExternalLoad,
  ]);

  if (!convertedWbLongitudinalLimitPoints) {
    return (
      <Row className="mt-3">
        <Col sm={12}>
          <p>No longitudinal limits</p>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mt-3">
      <Col xs={12}>
        <Card>
          <Card.Body>
            <EnvelopeChart
              armUnit={bookingArmUnit}
              weightUnit={bookingWeightUnit}
              limitsCollection={limitsCollection}
              externalLimitsCollection={externalLimitsCollection}
              fuelBurnCollection={fuelBurnCollection}
              fuelBurnWithExternalLoadCollection={fuelBurnWithExternalLoadCollection}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default WeightBalanceLongCg;
