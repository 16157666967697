import { Row, Col, Form, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';

import { Field, FieldArray, reduxForm, getFormValues } from 'redux-form';

import pick from 'lodash.pick';

import FlightSignoffFormLandingCharges from './flight_signoff_form_landing_charges';
import FlightSignoffFormPilotFlightExpenses from './flight_signoff_form_pilot_flight_expenses';

import InputField from '../form/input_field';
import CheckboxInputField from '../form/checkbox_input_field';
import Glyphicon from '../glyphicon';

moment.updateLocale('en-nz');

class FlightSignoffForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completeAdmin: !!this.props.booking.admin_completed_at,
    };
    this.handleCompleteAdminChecked = this.handleCompleteAdminChecked.bind(this);
    this.renderLandingChargesFieldArray = this.renderLandingChargesFieldArray.bind(this);
    this.renderPilotFlightExpensesFieldArray =
      this.renderPilotFlightExpensesFieldArray.bind(this);
  }

  handleCompleteAdminChecked(e) {
    const { checked } = e.target;
    this.setState({ completeAdmin: checked });
    if (checked) {
      this.props.change('admin_completed_at', moment().format());
      this.props.change('admin_completed_by_admin_id', this.props.currentContactId);
    } else {
      this.props.change('admin_completed_at', '');
      this.props.change('admin_completed_by_admin_id', '');
    }
  }

  renderLandingChargesFieldArray(flightSegments) {
    const formKeys = ['flight_segments_attributes'];
    return (
      <FlightSignoffFormLandingCharges
        flightSegments={flightSegments}
        locationsDataSelector={this.props.locationsDataSelector}
        formValues={pick(this.props.formValues, formKeys)}
      />
    );
  }

  renderPilotFlightExpensesFieldArray(pilotFlightExpenses) {
    const formKeys = ['pilot_flight_expenses_attributes'];
    return (
      <FlightSignoffFormPilotFlightExpenses
        pilotFlightExpenses={pilotFlightExpenses}
        formValues={pick(this.props.formValues, formKeys)}
        contactsDataSelector={this.props.contactsDataSelector}
        currentSettingsPilotFlightExpenseOvernightText={
          this.props.currentSettingsPilotFlightExpenseOvernightText
        }
      />
    );
  }

  render() {
    const {
      booking: { id },
      handleSubmit,
      pristine,
      reset,
      submitting,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit} key={id}>
        <Field
          blurOnly
          type="text"
          name="admin_flight_record_attributes.flight_time"
          component={InputField}
        >
          Flight Time
        </Field>
        <Field
          blurOnly
          type="text"
          name="admin_flight_record_attributes.flight_summary"
          component={InputField}
          asElement="textarea"
          rows={4}
        >
          Flight Summary
        </Field>
        <FieldArray
          name="flight_segments_attributes"
          component={this.renderLandingChargesFieldArray}
        />
        <FieldArray
          name="pilot_flight_expenses_attributes"
          component={this.renderPilotFlightExpensesFieldArray}
        />
        <CheckboxInputField
          size="sm"
          input={{
            name: `mark_admin_complete_${id}`,
            value: this.state.completeAdmin,
            onChange: this.handleCompleteAdminChecked,
          }}
        >
          Mark Admin Complete
        </CheckboxInputField>
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            <ButtonToolbar>
              <ButtonGroup className="me-2">
                <Button
                  type="button"
                  variant="danger"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Reset
                </Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button type="submit" variant="primary" disabled={pristine || submitting}>
                  {submitting && <Glyphicon glyph="refresh" spin />}
                  Update
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    formValues: getFormValues(props.form)(state),
  };
}

export default compose(connect(mapStateToProps, {}), reduxForm({}))(FlightSignoffForm);
