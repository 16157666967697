import { Row, Col, Card, Accordion } from 'react-bootstrap';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import moment from 'moment';
import SunCalc from 'suncalc';

import compact from 'lodash.compact';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import includes from 'lodash.includes';
import intersection from 'lodash.intersection';
import sortBy from 'lodash.sortby';
import trim from 'lodash.trim';
import uniq from 'lodash.uniq';

import { currentSettingsSet } from '../actions/current_setting_actions';

import Loader from '../components/loader';
import CardHeaderToggle from '../components/card_header_toggle';
import { renderOverlay, renderError } from '../components/render_helpers';
import BookingManagerFilters from '../components/booking_manager/booking_manager_filters';
import BookingManagerTools from '../components/booking_manager/booking_manager_tools';
import BookingManagerListItems from '../components/booking_manager/booking_manager_list_items';
import BookingManagerCalendar from '../components/booking_manager/booking_manager_calendar';

import Api from '../lib/api';
import { coerceInput } from '../lib/utils';
import { toastSuccess, toastError } from '../lib/action_helpers';

import bookingUpdateMutation from '../mutations/booking_update_mutation';
import bookingDeleteMutation from '../mutations/booking_delete_mutation';
import bookingCloneMutation from '../mutations/booking_clone_mutation';

import pageBookingManagerQuery from '../queries/page_booking_manager_query';

const BookingManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchStr, setSearchStr] = useState('');
  const [searchBookingIds, setSearchBookingIds] = useState([]);
  const [contactOnly, setContactOnly] = useState(false);
  const [resourceTypeContact, setResourceTypeContact] = useState(true);
  const [resourceGroupContact, setResourceGroupContact] = useState(true);

  const currentContact = useSelector((state) => state.currentContact);
  const employee = useSelector((state) => state.currentContact['employee?']);
  const currentSettingsMutating = useSelector(
    ({ currentSettings }) => currentSettings.mutating
  );
  const currentSettingsBookingCollectionStartDate = useSelector(
    ({ currentSettings }) => currentSettings.bookingCollectionStartDate
  );
  const currentSettingsBookingCollectionEndDate = useSelector(
    ({ currentSettings }) => currentSettings.bookingCollectionEndDate
  );
  const currentSettingsBookingCollectionView = useSelector(
    ({ currentSettings }) => currentSettings.bookingCollectionView
  );
  const currentSettingsBookingStatuses = useSelector(
    ({ currentSettings }) => currentSettings.bookingStatuses
  );
  const currentSettingsBookingCalendarTypes = useSelector(
    ({ currentSettings }) => currentSettings.bookingCalendarTypes
  );
  const currentSettingsEnteredBookingViewIndexes = useSelector(
    ({ currentSettings }) => currentSettings.enteredBookingViewIndexes
  );
  const currentSettingsContainerHeight = useSelector(
    ({ currentSettings }) => currentSettings.containerHeight
  );

  const [bookingUpdate] = useMutation(bookingUpdateMutation);
  const [bookingDelete] = useMutation(bookingDeleteMutation);
  const [bookingClone] = useMutation(bookingCloneMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageBookingManagerQuery, {
    variables: {
      startAt: currentSettingsBookingCollectionStartDate,
      endAt: currentSettingsBookingCollectionEndDate,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () =>
      !pageLoading ||
      (pageLoading &&
        [NetworkStatus.refetch, NetworkStatus.setVariables].includes(pageNetworkStatus)),
    [pageLoading, pageNetworkStatus]
  );

  const isDeveloper = useMemo(
    () => currentContact?.email === 'gordon.king02@gmail.com',
    [currentContact]
  );

  const bookingViews = useMemo(() => {
    if (pageData?.bookingViewList?.length > 0) {
      return sortBy(pageData.bookingViewList, ['position']);
    }
    return [];
  }, [pageData]);

  const defaultBookingViewBookingView = useMemo(
    () => bookingViews.find((bv) => bv.default_booking_view),
    [bookingViews]
  );

  const dutyEvents = useMemo(() => {
    if (pageData?.dutyEventList?.length > 0) {
      const newDutyEvents = compact(
        pageData.dutyEventList.map((de) => {
          if (de.published) {
            const roster = pageData.rosterList.find((r) => r.id === de.roster_id);
            const publishedThru = get(roster, 'published_thru');

            if (publishedThru) {
              const { start_at: startAt, end_at: endAt } = de;
              const newEndAt = moment.min(moment(endAt), moment(publishedThru));
              if (
                moment(startAt).isBefore(newEndAt) &&
                moment(newEndAt).isSameOrAfter(currentSettingsBookingCollectionEndDate)
              ) {
                return {
                  ...de,
                  end_at: newEndAt.format(),
                };
              }
            }
          }
          return undefined;
        })
      );
      return newDutyEvents;
    }
    return [];
  }, [pageData, currentSettingsBookingCollectionEndDate]);

  const processedBookings = useMemo(() => {
    let newProcessedBookings = {};
    let originalListLength = 0;
    let filteredListLength = 0;
    if (pageData?.bookingList?.length > 0) {
      originalListLength = pageData.bookingList.length;
      const {
        id: currentContactId,
        related_aircraft_ids: relatedAircraftIds,
        related_chargeable_ids: relatedChargeableIds,
      } = currentContact;

      // shared bookings are bookings scoped to the logged in user
      // and their roles (employee, provider, chargeable etc)
      // and further filtered based on searchstr
      const sharedBookings = pageData.bookingList.filter((booking) => {
        const {
          id: bookingId,
          aircraft_id: aircraftId,
          pilot_id: pilotId,
          copilot_id: copilotId,
          employees,
          chargeables,
        } = booking;
        const pilotIds = compact([pilotId, copilotId]);
        if (employee) {
          if (contactOnly) {
            // if not pilot, copilot or employee, remove
            const employeeIds = employees.map((e) => e.id);
            if (
              !(
                includes(pilotIds, currentContactId) ||
                employeeIds.includes(currentContactId)
              )
            ) {
              return false;
            }
          }
          if (searchStr) {
            // if not in the search list of ids, remove
            if (!searchBookingIds.includes(bookingId)) {
              return false;
            }
          }
        } else {
          // if not an employee, and not the pilot, owner, or chargeable, remove
          const chargeableIds = chargeables.map((c) => c.id);
          if (
            !(
              includes(pilotIds, currentContactId) ||
              (relatedAircraftIds && includes(relatedAircraftIds, aircraftId)) ||
              (relatedChargeableIds &&
                intersection(relatedChargeableIds, chargeableIds).length > 0)
            )
          ) {
            return false;
          }
        }
        return true;
      });

      // shared duty events are scoped to the filters or logged in user
      const sharedDutyEvents = dutyEvents.filter((dutyEvent) => {
        const { contact_id: contactId } = dutyEvent;
        if (employee) {
          if (contactOnly) {
            // if not self, remove
            if (contactId !== currentContactId) {
              return false;
            }
          }
        } else if (contactId !== currentContactId) {
          // if not an employee, and not self remove
          return false;
        }
        return true;
      });

      const allViewAircraftIds = uniq(
        pageData.bookingViewList.reduce(
          (accum, bookingView) => [...accum, ...bookingView.aircrafts.map((a) => a.id)],
          []
        )
      );
      const allViewPilotIds = uniq(
        pageData.bookingViewList.reduce(
          (accum, bookingView) => [...accum, ...bookingView.viewPilots.map((p) => p.id)],
          []
        )
      );
      const bookingViewPositions = pageData.bookingViewList.map((bv) => bv.position);
      const firstBookingView = pageData.bookingViewList.find(
        ({ position }) => position === Math.min(...bookingViewPositions)
      );

      newProcessedBookings = pageData.bookingViewList.reduce((accum, bookingView) => {
        const {
          id: bookingViewId,
          default_booking_view: defaultBookingView,
          aircrafts,
          viewPilots,
        } = bookingView;
        const viewPilotIds = viewPilots.map((vp) => vp.id);
        const viewAircraftIds = aircrafts.map((a) => a.id);
        const isFirstBookingView = firstBookingView.id === bookingViewId;

        // list bookings include bookings for the aircraft and pilots in the booking view
        // plus orphans (other employees etc) in the default calendar view
        const listBookings = sharedBookings.filter((booking) => {
          const {
            aircraft_id: aircraftId,
            calendar_type: calendarType,
            employees,
          } = booking;
          const bookingEmployeeIds = employees.map((e) => e.id);
          if (
            isFirstBookingView &&
            calendarType === currentSettingsBookingCalendarTypes.banner
          ) {
            // banners go into the top booking view
            return true;
          }
          if (
            calendarType === currentSettingsBookingCalendarTypes.flight ||
            calendarType === currentSettingsBookingCalendarTypes.maintenance
          ) {
            // if booking aircraft belongs to the booking view
            if (includes(viewAircraftIds, aircraftId)) {
              return true;
            }
            // or if its the default booking view and the aircraft is not in another view
            if (defaultBookingView && !includes(allViewAircraftIds, aircraftId)) {
              return true;
            }
          } else if (
            calendarType === currentSettingsBookingCalendarTypes.meeting ||
            calendarType === currentSettingsBookingCalendarTypes.leave ||
            calendarType === currentSettingsBookingCalendarTypes.rostered
          ) {
            // if employee(s) belongs to the booking view pilots
            if (intersection(viewPilotIds, bookingEmployeeIds).length > 0) {
              return true;
            }
            // or if its the default booking view and some of the employees are orphans
            if (
              defaultBookingView &&
              intersection(allViewPilotIds, bookingEmployeeIds).length !==
                bookingEmployeeIds.length
            ) {
              return true;
            }
          }
          return false;
        });
        filteredListLength += listBookings.length;

        // calendar aircraft bookings don't include cancelled flight bookings
        // or non aircraft non flight bookings (so maintenance only, not any meetings in the default view)
        const calendarAircraftBookings = listBookings.filter((booking) => {
          const { aircraft_id: aircraftId, status } = booking;
          // no cancelled bookings
          if (status === currentSettingsBookingStatuses.cancelled) {
            return false;
          }
          // no meetings in the default booking view when in aircraft calendar mode
          if (!aircraftId) {
            return false;
          }
          return true;
        });

        // calendar contact bookings include bookings for any pilot in the booking view, but nothing cancelled
        const calendarContactBookings = sharedBookings.filter((booking) => {
          const {
            status,
            calendar_type: calendarType,
            pilot_id: pilotId,
            copilot_id: copilotId,
            aircraft_id: aircraftId,
            employees,
          } = booking;
          if (status === currentSettingsBookingStatuses.cancelled) {
            return false;
          }
          const pilotIds = compact([pilotId, copilotId]);
          const bookingEmployeeIds = employees.map((e) => e.id);
          if (calendarType === currentSettingsBookingCalendarTypes.flight) {
            // if pilots it belongs to the booking view
            if (intersection(viewPilotIds, pilotIds).length > 0) {
              return true;
            }
            // if pilot doesn't belong to ANY booking view put in default
            if (
              defaultBookingView &&
              ((pilotId && !includes(allViewPilotIds, pilotId)) ||
                (copilotId && !includes(allViewPilotIds, copilotId)) ||
                !includes(allViewAircraftIds, aircraftId))
            ) {
              return true;
            }
          } else if (
            calendarType === currentSettingsBookingCalendarTypes.meeting ||
            calendarType === currentSettingsBookingCalendarTypes.leave ||
            calendarType === currentSettingsBookingCalendarTypes.rostered
          ) {
            // if employee(s) belongs to the booking view pilots
            if (intersection(viewPilotIds, bookingEmployeeIds).length > 0) {
              return true;
            }
            // or if its the default booking view and some of the employees are orphans
            if (
              defaultBookingView &&
              intersection(allViewPilotIds, bookingEmployeeIds).length !==
                bookingEmployeeIds.length
            ) {
              return true;
            }
          }
          return false;
        });

        const calendarDutyEvents = sharedDutyEvents.filter((dutyEvent) => {
          const { contact_id: contactId } = dutyEvent;
          if (
            includes(viewPilotIds, contactId) ||
            (defaultBookingView && !includes(allViewPilotIds, contactId))
          ) {
            return true;
          }
          return false;
        });

        return {
          ...accum,
          [bookingView.id]: {
            // list:listBookings,
            // calendarContact: calendarContactBookings,
            // calendarAircraft: calendarAircraftBookings,
            listBookings,
            calendarContactBookings,
            calendarAircraftBookings,
            calendarDutyEvents,
            allViewAircraftIds,
            allViewPilotIds,
          },
        };
      }, {});
    }
    return {
      ...newProcessedBookings,
      filteredListLength,
      originalListLength,
    };
  }, [
    pageData,
    dutyEvents,
    currentContact,
    searchStr,
    searchBookingIds,
    contactOnly,
    employee,
    currentSettingsBookingCalendarTypes,
    currentSettingsBookingStatuses,
  ]);

  const listHeight = useMemo(() => {
    let newListHeight = currentSettingsContainerHeight - 105 - 15 - 88;
    if (currentSettingsContainerHeight < 480) {
      newListHeight = currentSettingsContainerHeight - 165 - 15 - 88;
    }
    return newListHeight;
  }, [currentSettingsContainerHeight]);

  const calendarHeight = useMemo(() => {
    let newCalendarHeight = currentSettingsContainerHeight - 15 - 124;
    if (currentSettingsContainerHeight < 480) {
      newCalendarHeight = currentSettingsContainerHeight - 175 - 15 - 124;
    } else if (currentSettingsContainerHeight < 992) {
      newCalendarHeight = currentSettingsContainerHeight - 115 - 15 - 124;
    }
    return newCalendarHeight;
  }, [currentSettingsContainerHeight]);

  const titleStr = useMemo(() => {
    const getTitleString = (view, start, end) => {
      switch (view) {
        case 'resourceTimelineDay':
          return moment(start).format('ddd, D MMM YYYY');
        case 'timeGridWeek':
          switch (moment(start).format('MMM')) {
            case moment(end).format('MMM'):
              return `${moment(start).format('D')} \u2013 ${moment(end).format(
                'D MMM YYYY'
              )}`;
            default:
              return `${moment(start).format('D MMM')} \u2013 ${moment(end).format(
                'D MMM YYYY'
              )}`;
          }
        case 'dayGridMonth':
          return moment(start).format('MMM YYYY');
        default:
          return 'Unknown';
      }
    };
    const newTitleStr = getTitleString(
      currentSettingsBookingCollectionView,
      currentSettingsBookingCollectionStartDate,
      currentSettingsBookingCollectionEndDate
    );
    return newTitleStr;
  }, [
    currentSettingsBookingCollectionView,
    currentSettingsBookingCollectionStartDate,
    currentSettingsBookingCollectionEndDate,
  ]);

  const daylightStr = useMemo(() => {
    let newDaylightStr = '';
    if (
      currentSettingsBookingCollectionView === 'resourceTimelineDay' &&
      currentSettingsBookingCollectionStartDate
    ) {
      const times = SunCalc.getTimes(
        moment(currentSettingsBookingCollectionStartDate).add(12, 'hours').toDate(),
        -36.848461,
        174.763336
      );
      newDaylightStr = `${moment(times.dawn).format('HH:mm')} / ${moment(
        times.dusk
      ).format('HH:mm')}`;
    }
    return newDaylightStr;
  }, [currentSettingsBookingCollectionView, currentSettingsBookingCollectionStartDate]);

  const handleBookingViewEntered = useCallback(
    (e) => {
      const bookingViewIndex = parseInt(e.getAttribute('data-index'), 10);
      dispatch(
        currentSettingsSet({
          enteredBookingViewIndexes: uniq([
            ...currentSettingsEnteredBookingViewIndexes,
            bookingViewIndex,
          ]),
        })
      );
    },
    [currentSettingsEnteredBookingViewIndexes, dispatch]
  );

  const handleBookingViewExited = useCallback(
    (e) => {
      const bookingViewIndex = parseInt(e.getAttribute('data-index'), 10);
      dispatch(
        currentSettingsSet({
          enteredBookingViewIndexes: currentSettingsEnteredBookingViewIndexes.filter(
            (idx) => idx !== bookingViewIndex
          ),
        })
      );
    },
    [currentSettingsEnteredBookingViewIndexes, dispatch]
  );

  // onCalendarDisplayDateChanged
  const dispatchBookingCollectionVars = useCallback(
    (calendarStart, calendarEnd, viewName) => {
      dispatch(
        currentSettingsSet({
          bookingCollectionStartDate: calendarStart,
          bookingCollectionEndDate: calendarEnd,
          bookingCollectionView: viewName,
        })
      );
    },
    [dispatch]
  );

  const dispatchBookingRequestedCalendarType = useCallback(
    (bookingRequestedCalendarType) => {
      dispatch(
        currentSettingsSet({
          bookingRequestedCalendarType,
        })
      );
    },
    [dispatch]
  );

  const onNewClicked = useCallback(
    (isFlight) => {
      if (isFlight) {
        navigate('/flights/new');
      } else {
        navigate('/nonflights/new');
      }
    },
    [navigate]
  );

  const onEditClicked = useCallback(
    (id, isFlight) => {
      if (isFlight) {
        navigate(`/flights/${id}/edit`);
      } else {
        navigate(`/nonflights/${id}/edit`);
      }
    },
    [navigate]
  );

  const onDeleteClicked = useCallback(
    async (id) => {
      const mutationData = {
        variables: { id },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await bookingDelete(mutationData);
        toastSuccess('Booking delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Booking delete failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [bookingDelete, dispatch]
  );

  const onCloneClicked = useCallback(
    async (id, cloneType) => {
      const mutationData = {
        variables: {
          input: {
            id,
            cloneType,
          },
        },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await bookingClone(mutationData);
        toastSuccess('Booking cloned ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Booking clone failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [bookingClone, dispatch]
  );

  const onFlightCompleteClicked = useCallback(
    async (id) => {
      const mutationData = {
        variables: {
          id,
          input: coerceInput({
            id,
            audit_created: true,
            audit_created_at: moment().format(),
            audit_created_by_admin_id: currentContact.id,
            updated_by_admin_id: currentContact.id,
          }),
        },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await bookingUpdate(mutationData);
        toastSuccess('Booking update ok');
        navigate(`/flights/${id}/edit`);
      } catch (err) {
        console.log(err.toString());
        toastError('Booking update failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [bookingUpdate, currentContact, dispatch, navigate]
  );

  const onListItemCalendarClicked = (id) => {
    const startAt = pageData.bookingList.find((model) => model.id === id).start_at;
    dispatchBookingCollectionVars(
      moment(startAt).startOf('day').format(),
      moment(startAt).endOf('day').format(),
      'resourceTimelineDay'
    );
  };

  const setDebouncedSearchBookingIds = useMemo(
    () =>
      debounce(async (str) => {
        try {
          const resp = await Api.get(`/api/search/bookings/${str}`, {
            start_at: currentSettingsBookingCollectionStartDate,
            end_at: currentSettingsBookingCollectionEndDate,
          });
          setSearchBookingIds(get(resp, 'data', []));
        } catch (err) {
          console.log(err.toString());
        }
      }, 500),
    [currentSettingsBookingCollectionStartDate, currentSettingsBookingCollectionEndDate]
  );

  useEffect(
    /* eslint-disable arrow-body-style, react-hooks/exhaustive-deps */
    () => {
      return () => {
        setDebouncedSearchBookingIds.cancel();
      };
    },
    []
  );

  useEffect(() => {
    dispatch(currentSettingsSet({ returnRoute: location.pathname }));
  }, [dispatch, location]);

  useEffect(() => {
    if (searchStr) {
      setDebouncedSearchBookingIds(trim(searchStr).replace(/\.*$/, ''));
    } else {
      setSearchBookingIds([]);
    }
  }, [searchStr, setDebouncedSearchBookingIds]);

  const renderContent = () => (
    <Accordion
      alwaysOpen
      defaultActiveKey={currentSettingsEnteredBookingViewIndexes}
      as={Row}
      className="mt-3"
    >
      <Col sm={5}>
        <Card>
          <Card.Body>
            <Row className="g-0">
              <Col className="flex-grow-0 text-nowrap">
                <h3>{titleStr}</h3>
              </Col>
              <Col>
                <Row className="justify-content-end g-0">
                  <BookingManagerFilters
                    isDeveloper={isDeveloper}
                    pageRefetch={pageRefetch}
                    originalListLength={get(processedBookings, 'originalListLength')}
                    filteredListLength={get(processedBookings, 'filteredListLength')}
                    employee={employee}
                    contactOnly={contactOnly}
                    setContactOnly={setContactOnly}
                    resourceTypeContact={resourceTypeContact}
                    setResourceTypeContact={setResourceTypeContact}
                    resourceGroupContact={resourceGroupContact}
                    setResourceGroupContact={setResourceGroupContact}
                    searchStr={searchStr}
                    setSearchStr={setSearchStr}
                  />
                </Row>
              </Col>
            </Row>
            <Row className="mb-2 g-0">
              <BookingManagerTools
                pageRefetch={pageRefetch}
                dispatchBookingCollectionVars={dispatchBookingCollectionVars}
                dispatchBookingRequestedCalendarType={
                  dispatchBookingRequestedCalendarType
                }
                onNewClicked={onNewClicked}
                currentSettingsBookingCollectionStartDate={
                  currentSettingsBookingCollectionStartDate
                }
                currentSettingsBookingCollectionEndDate={
                  currentSettingsBookingCollectionEndDate
                }
                currentSettingsBookingCollectionView={
                  currentSettingsBookingCollectionView
                }
                currentSettingsBookingCalendarTypes={currentSettingsBookingCalendarTypes}
              />
            </Row>
            <Row className="g-0">
              <Col
                className="overflow-auto"
                style={{
                  height: listHeight,
                }}
              >
                {bookingViews.map((bookingView, index) => {
                  const { id: bookingViewId, name: bookingViewName } = bookingView;
                  return (
                    <Card
                      key={bookingViewId}
                      className="mb-2 border-start-0 border-end-0 rounded-0"
                    >
                      <CardHeaderToggle className="px-4" eventKey={index}>
                        <Row className="justify-content-between">
                          <Col className="align-self-center font">
                            <strong>{bookingViewName}</strong>
                          </Col>
                        </Row>
                      </CardHeaderToggle>
                      <Accordion.Collapse
                        data-index={index}
                        eventKey={index}
                        onEntered={handleBookingViewEntered}
                        onExited={handleBookingViewExited}
                      >
                        <Card.Body className="p-0">
                          {includes(currentSettingsEnteredBookingViewIndexes, index) ? (
                            <BookingManagerListItems
                              listBookings={get(
                                processedBookings,
                                [bookingViewId, 'listBookings'],
                                []
                              )}
                              currentContact={currentContact}
                              onListItemCalendarClicked={onListItemCalendarClicked}
                              onFlightCompleteClicked={onFlightCompleteClicked}
                              onDeleteClicked={onDeleteClicked}
                              onCloneClicked={onCloneClicked}
                              currentSettingsBookingCalendarTypes={
                                currentSettingsBookingCalendarTypes
                              }
                              currentSettingsBookingStatuses={
                                currentSettingsBookingStatuses
                              }
                            />
                          ) : (
                            <Loader
                              fadeIn="none"
                              wrapperStyle={{
                                zIndex: 1000,
                                height: '100%',
                                marginTop: '20px',
                              }}
                            />
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={7}>
        <Card>
          <Card.Body>
            <Row className="g-0">
              <Col
                id="booking-views"
                className="overflow-auto"
                style={{
                  height: calendarHeight,
                }}
              >
                {bookingViews.map((bookingView, index) => {
                  const { id: bookingViewId, name: bookingViewName } = bookingView;
                  const BOOKING_VIEW_HTML_ID = `booking-view-${bookingViewId}`;
                  const BOOKING_VIEW_CALENDAR_HTML_ID = `${BOOKING_VIEW_HTML_ID}-calendar`;
                  const BOOKING_VIEW_POPOVERS_HTML_ID = `${BOOKING_VIEW_HTML_ID}-popovers`;
                  return (
                    <div key={bookingViewId} id={BOOKING_VIEW_HTML_ID}>
                      <div id={BOOKING_VIEW_POPOVERS_HTML_ID} />
                      <Card className="mb-2 border-start-0 border-end-0 rounded-0">
                        <CardHeaderToggle className="px-4" eventKey={index}>
                          <Row className="justify-content-between">
                            <Col className="align-self-center">
                              <strong>{bookingViewName}</strong>
                            </Col>
                            <Col className="text-center">{titleStr}</Col>
                            <Col className="text-end">{index === 0 && daylightStr}</Col>
                          </Row>
                        </CardHeaderToggle>
                        <Accordion.Collapse
                          data-index={index}
                          eventKey={index}
                          onEntered={handleBookingViewEntered}
                          onExited={handleBookingViewExited}
                        >
                          <Card.Body className="p-0 m-0">
                            <Card className="mb-2">
                              <Card.Body className="p-0">
                                {includes(
                                  currentSettingsEnteredBookingViewIndexes,
                                  index
                                ) ? (
                                  <BookingManagerCalendar
                                    isDeveloper={isDeveloper}
                                    BOOKING_VIEW_CALENDAR_HTML_ID={
                                      BOOKING_VIEW_CALENDAR_HTML_ID
                                    }
                                    BOOKING_VIEW_POPOVERS_HTML_ID={
                                      BOOKING_VIEW_POPOVERS_HTML_ID
                                    }
                                    bookingView={bookingView}
                                    defaultBookingViewBookingView={
                                      defaultBookingViewBookingView
                                    }
                                    contactBookings={get(
                                      processedBookings,
                                      [bookingViewId, 'calendarContactBookings'],
                                      []
                                    )}
                                    aircraftBookings={get(
                                      processedBookings,
                                      [bookingViewId, 'calendarAircraftBookings'],
                                      []
                                    )}
                                    dutyEvents={get(
                                      processedBookings,
                                      [bookingViewId, 'calendarDutyEvents'],
                                      []
                                    )}
                                    allViewPilotIds={get(
                                      processedBookings,
                                      [bookingViewId, 'allViewPilotIds'],
                                      []
                                    )}
                                    resourceTypeContact={resourceTypeContact}
                                    resourceGroupContact={resourceGroupContact}
                                    currentSettingsBookingCollectionStartDate={
                                      currentSettingsBookingCollectionStartDate
                                    }
                                    currentSettingsBookingCollectionEndDate={
                                      currentSettingsBookingCollectionEndDate
                                    }
                                    currentSettingsBookingCollectionView={
                                      currentSettingsBookingCollectionView
                                    }
                                    currentSettingsBookingCalendarTypes={
                                      currentSettingsBookingCalendarTypes
                                    }
                                    onEditClicked={onEditClicked}
                                    onDisplayDateChanged={dispatchBookingCollectionVars}
                                  />
                                ) : (
                                  <Loader
                                    fadeIn="none"
                                    wrapperStyle={{
                                      zIndex: 1000,
                                      height: '100%',
                                      marginTop: '20px',
                                    }}
                                  />
                                )}
                              </Card.Body>
                            </Card>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Accordion>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default BookingManager;
