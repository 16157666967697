import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash.get';
import has from 'lodash.has';
import pick from 'lodash.pick';

import BookingOilFillFields from './booking_oil_fill_fields';

class OilFills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getSelectOptions();
    if (
      has(this.props, 'formValues.oil_fills_attributes') &&
      get(this.props, 'formValues.oil_fills_attributes', []).length === 0
    ) {
      const oilFills = [];
      this.props.aircraftEngines.forEach((engine) => {
        oilFills.push({
          aircraft_engine_id: engine.id,
          quantity_value: 0,
        });
      });
      this.props.change('oil_fills_attributes', oilFills);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.formValues.aircraft_id !== nextProps.formValues.aircraft_id) {
      this.getSelectOptions(nextProps);
    }
  }

  getSelectOptions(props = this.props) {
    this.setState({
      selectOptions: props.aircraftEngines,
    });
  }

  handleAddButtonClick() {
    this.props.oilFills.fields.push({
      quantity_value: 0,
    });
  }

  handleDeleteButtonClick(index) {
    this.props.oilFills.fields.remove(index);
  }

  renderOilFillsList() {
    return (
      <>
        {this.props.oilFills.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}._destroy`,
              `${field}.aircraft_engine_id`,
              `${field}.quantity_value`,
            ];
            return (
              <Fields
                key={`of-${field}`}
                index={index}
                parentField={field}
                names={fields}
                component={BookingOilFillFields}
                formValues={pick(values, ['id', 'quantity_value', 'aircraft_engine_id'])}
                handleDeleteButtonClick={this.handleDeleteButtonClick}
                selectOptions={this.state.selectOptions}
                bookingAircraftId={get(this.props, 'formValues.aircraft_id', '')}
                currentSettingsFuelBowserFillDefaultQuantityUnit={
                  this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
                }
              />
            );
          }
          return undefined;
        })}
      </>
    );
  }

  render() {
    const fillsLength = get(this.props, 'formValues.oil_fills_attributes', []).filter(
      (of) => !of._destroy
    ).length;
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Oil Fills</strong>
          </p>
        </Col>
        <Col sm={10}>
          {fillsLength > 0 && this.renderOilFillsList()}
          <Row>
            <Col sm={2} className="text-end">
              <Button
                type="button"
                variant="link"
                size="sm"
                onClick={this.handleAddButtonClick}
                tabIndex={-1}
                disabled={!this.props.formValues.aircraft_id}
              >
                add...
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default OilFills;
