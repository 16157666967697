import { useMemo, useCallback } from 'react';
import { Row, Col, Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import moment from 'moment';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import get from 'lodash.get';
import sortBy from 'lodash.sortby';

import ReactDateTimeField from '../form/react_date_time_field';
import Field from '../form/react_final_form_field';
import OnChangeField from '../form/rff_on_change_field';
import CheckboxInputField from '../form/checkbox_input_field';

function DeleteDutyEventsModal(props) {
  const {
    show,
    rosterId,
    rosterName,
    calendarResources,
    handleDeleteDutyEventsModalCancel,
    handleDeleteDutyEventsModalSubmit,
  } = props;

  const initialValues = useMemo(() => {
    let deleteDefaultValues = { contactIds: [] };
    if (rosterId && calendarResources?.length > 0) {
      deleteDefaultValues = {
        rosterId,
        deleteFrom: moment().startOf('month').format(),
        deleteThru: moment().endOf('month').format(),
        contacts: sortBy(calendarResources, ['id']).map(({ extendedProps, title }) => ({
          id: get(extendedProps, 'contactId'),
          title,
          delete: false,
        })),
      };
    }
    return deleteDefaultValues;
  }, [rosterId, calendarResources]);

  const onDeleteFromChange = useCallback(
    (form, values) => {
      if (rosterId) {
        const { deleteFrom, deleteThru } = values;
        if (moment(deleteFrom).isAfter(deleteThru)) {
          form.batch(() => {
            form.change('deleteThru', deleteFrom);
          });
        }
      }
    },
    [rosterId]
  );

  const onDeleteThruChange = useCallback(
    (form, values) => {
      if (rosterId) {
        const { deleteFrom, deleteThru } = values;
        if (moment(deleteThru).isBefore(deleteFrom)) {
          form.batch(() => {
            form.change('deleteFrom', deleteThru);
          });
        }
      }
    },
    [rosterId]
  );

  return (
    <Modal
      id={`roster-${rosterId}-duty-event-modal`}
      show={show}
      onHide={handleDeleteDutyEventsModalCancel}
      centered
      size="lg"
      animation={false}
    >
      <FinalForm
        initialValues={initialValues}
        onSubmit={handleDeleteDutyEventsModalSubmit}
        mutators={{ setFieldTouched, ...arrayMutators }}
      >
        {({ handleSubmit, submitting, form, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{`Delete Duty Events for ${rosterName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                This will delete all events for the selected contact(s) where the event
                start is before the selected thru date and the event end is after the
                selected start date
              </p>
              <OnChangeField name="deleteFrom">
                {() => onDeleteFromChange(form, values)}
              </OnChangeField>
              <OnChangeField name="deleteThru">
                {() => onDeleteThruChange(form, values)}
              </OnChangeField>
              <fieldset className="border rounded-3 p-3">
                <Row>
                  <Col>
                    <Field
                      name="deleteFrom"
                      labelWidth={4}
                      inputWidth={8}
                      component={ReactDateTimeField}
                      helpText="DD/MM/YYYY"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      closeOnSelect
                    >
                      Delete From
                    </Field>
                  </Col>
                  <Col>
                    <Field
                      name="deleteThru"
                      labelWidth={4}
                      inputWidth={8}
                      component={ReactDateTimeField}
                      helpText="DD/MM/YYYY"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      closeOnSelect
                    >
                      Delete Thru
                    </Field>
                  </Col>
                </Row>
                <fieldset className="border rounded-3 p-3">
                  <legend className="float-none w-auto px-3 fs-6">
                    Resources to Delete
                  </legend>
                  <FieldArray name="contacts">
                    {({ fields: arrayFields }) => (
                      <Row>
                        {arrayFields.map((arrayName) => (
                          <Col sm={4} key={arrayName}>
                            <Field
                              type="checkbox"
                              name={`${arrayName}.delete`}
                              labelWidth={1}
                              inputWidth={11}
                              component={CheckboxInputField}
                            >
                              {get(values, `${arrayName}.title`)}
                            </Field>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </FieldArray>
                </fieldset>
              </fieldset>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-end w-100">
                <ButtonGroup>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={handleDeleteDutyEventsModalCancel}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary" disabled={submitting}>
                    Delete
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
}

export default DeleteDutyEventsModal;
