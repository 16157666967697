import { Form } from 'react-bootstrap';
import { isInvalid, validationText } from './helpers';

const InvalidBlock = ({ meta, helpText, force = false }) => {
  // Feedback css displays only if sibling to .is-invalid class
  // For custom inputs without a normal Form.Control, force display if in error
  if (isInvalid(meta)) {
    return (
      <Form.Control.Feedback
        type="invalid"
        {...(force && { style: { display: 'block' } })}
      >
        {validationText(meta, helpText)}
      </Form.Control.Feedback>
    );
  }
  return null;
};

export default InvalidBlock;
