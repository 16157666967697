import { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import difference from 'lodash.difference';
import get from 'lodash.get';
import includes from 'lodash.includes';
import isEqual from 'lodash.isequal';
import without from 'lodash.without';

import InvalidBlock from '../form/invalid_block';
import InputField from '../form/input_field';
import { getSelectable } from '../../lib/utils';

class ChargeableInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChargeables: [],
    };
    this.handleSelectedChargeableChange = this.handleSelectedChargeableChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.booking_chargeables_attributes) {
      this.setState({
        selectedChargeables: this.getSelectedChargeables(),
      });
    }
  }

  componentDidMount() {
    this.inputRef.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { selectedChargeables } = this.state;
    let contactFightNotes;
    if (
      !isEqual(
        this.props.booking_chargeables_attributes,
        nextProps.booking_chargeables_attributes
      )
    ) {
      selectedChargeables = this.getSelectedChargeables(nextProps);
      if (selectedChargeables.length > 0) {
        contactFightNotes = selectedChargeables
          .filter((chargeable) => chargeable.flightNotes)
          .map((chargeable) => `${chargeable.name}: ${chargeable.flightNotes}`)
          .join('\n');
      } else {
        contactFightNotes = '';
      }
    }
    this.setState({
      selectedChargeables,
    });
    if (contactFightNotes || contactFightNotes === '') {
      this.props.change('contact_flight_notes', contactFightNotes);
    }
  }

  handleSelectedChargeableChange(selectedChargeables) {
    const currentIds = this.props.booking_chargeables_attributes
      // eslint-disable-next-line no-underscore-dangle
      .filter((bc) => !bc._destroy)
      .map((bc) => bc.chargeable_id);
    const newIds = selectedChargeables.map((sc) => sc.id);
    const deleteIds = difference(currentIds, newIds);
    let addIds = difference(newIds, currentIds);

    this.props.bookingChargeables.fields.forEach((name, index, fields) => {
      const { id, chargeable_id: chargeableId, _destroy } = get(this.props, name);
      if (includes(deleteIds, chargeableId)) {
        if (id) {
          this.props.change(`${name}._destroy`, true);
        } else {
          fields.remove(index);
        }
      } else if (includes(addIds, chargeableId) && _destroy) {
        addIds = without(addIds, chargeableId);
        this.props.change(`${name}._destroy`, false);
      }
    });
    if (addIds.length > 0) {
      addIds.forEach((addId) => {
        this.props.bookingChargeables.fields.push({ chargeable_id: addId });
      });
    }
  }

  getSelectedChargeables = (props = this.props) => {
    if (props.booking_chargeables_attributes) {
      return (
        props.booking_chargeables_attributes
          // eslint-disable-next-line no-underscore-dangle
          .filter((bookingChargeable) => !bookingChargeable._destroy)
          .map((bookingChargeable) => {
            const chargeable = props.chargeables.find(
              (c) => c.id === bookingChargeable.chargeable_id
            );
            return {
              id: bookingChargeable.chargeable_id,
              name: get(chargeable, 'fullName', '404'),
              flightNotes: get(chargeable, 'flight_notes'),
            };
          })
      );
    }
    return [];
  };

  getSelectableChargeables() {
    const selectedChargeableIds = get(this.state, 'selectedChargeables', []).map(
      (c) => c.id
    );
    return getSelectable(
      get(this.props, 'chargeables', []),
      [],
      'fullName',
      'roleChargeable'
    ).filter((c) => !includes(selectedChargeableIds, c.id));
  }

  render() {
    const {
      bookingChargeables: { meta: bookingChargeablesMeta },
    } = this.props;

    return (
      <InputField
        size="sm"
        labelWidth={4}
        inputWidth={8}
        input={{
          name: 'chargeable_ids',
        }}
        innerContent={
          <>
            <Typeahead
              id="typeahead-chargeableids"
              ref={(el) => {
                this.inputRef = el;
              }}
              multiple
              onChange={this.handleSelectedChargeableChange}
              options={this.getSelectableChargeables()}
              selected={this.state.selectedChargeables}
              filterBy={['name']}
              labelKey="name"
              minLength={1}
              size="sm"
            />
            <InvalidBlock meta={bookingChargeablesMeta} force />
          </>
        }
      >
        Charge
      </InputField>
    );
  }
}

export default ChargeableInputField;
