import { Row, Col, Card } from 'react-bootstrap';
import { FieldArray } from 'redux-form';
import moment from 'moment';

import pick from 'lodash.pick';

import DlHorizontal from '../dl_horizontal';
import PilotFlightLogs from './pilot_flight_logs';

import usePreviousState from '../../hooks/use_previous_state';

const BookingPilotFlightLog = (props) => {
  const {
    change,
    formValues = {},
    currentSettingsPilotFlightLogFlightTypes,
    aircraftsDataSelector,
    contactsDataSelector,
  } = props;
  const {
    start_at: startAt,
    end_at: endAt,
    aircraft_id: aircraftId,
    pilot_id: flightPilotId,
    copilot_id: flightCopilotId,
    hobb_record_attributes: hobbRecordAttributes = {},
  } = formValues;

  const { flight_time: flightTime } = hobbRecordAttributes;

  const prevFlightTime = usePreviousState(flightTime);
  const prevFlightPilotId = usePreviousState(flightPilotId);
  const prevFlightCopilotId = usePreviousState(flightCopilotId);

  const renderPilotFlightLogsFieldArray = (pilotFlightLogs) => {
    const formKeys = [
      'aircraft_id',
      'pilot_id',
      'copilot_id',
      'pilot_flight_logs_attributes',
      'hobb_record_attributes',
    ];
    return (
      <PilotFlightLogs
        change={change}
        pilotFlightLogs={pilotFlightLogs}
        formValues={pick(formValues, formKeys)}
        currentSettingsPilotFlightLogFlightTypes={
          currentSettingsPilotFlightLogFlightTypes
        }
        aircraftsDataSelector={aircraftsDataSelector}
        contactsDataSelector={contactsDataSelector}
        flightTime={flightTime}
        prevFlightTime={prevFlightTime}
        aircraftId={aircraftId}
        flightPilotId={flightPilotId}
        flightCopilotId={flightCopilotId}
        prevFlightPilotId={prevFlightPilotId}
        prevFlightCopilotId={prevFlightCopilotId}
      />
    );
  };

  if (!flightTime) {
    return (
      <Row className="mt-4">
        <Col sm={12}>
          <p>Update hobb record</p>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mt-3">
      <Col sm={12}>
        <Row>
          <Col sm={4}>
            <Card>
              <Card.Body>
                <DlHorizontal dt="Start At" dd={moment(startAt).format('HH:mm')} />
                <DlHorizontal dt="End At" dd={moment(endAt).format('HH:mm')} />
                <DlHorizontal dt="Flight Time" dd={flightTime} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <FieldArray
            name="pilot_flight_logs_attributes"
            component={renderPilotFlightLogsFieldArray}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default BookingPilotFlightLog;
