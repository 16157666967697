import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunkMiddleware from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';

import { localStorageReduxPersistKey } from '../lib/local_storage';
import { reducers, initialState } from '../reducers';

const rootReducer = combineReducers({
  ...reducers,
  form: formReducer,
});

const persistConfig = {
  key: localStorageReduxPersistKey,
  keyPrefix: '',
  storage,
  whitelist: ['currentSettings', 'currentContact', 'currentTenant', 'tags'],
};

const reducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  reducer,
  initialState,
  composeWithDevToolsDevelopmentOnly(applyMiddleware(thunkMiddleware))
);

export const reduxPersistor = persistStore(store);
