import { useCallback } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { currentSettingsSet } from '../../actions/current_setting_actions';

import WeightBalanceData from './weight_balance_data';
// import WeightBalanceLoad from './weight_balance_load';
import WeightBalanceLatCg from './weight_balance_lat_cg';
import WeightBalanceLongCg from './weight_balance_long_cg';
import WeightBalanceSeatAssignments from './weight_balance_seat_assignments';
import WeightBalanceHoldAssignments from './weight_balance_hold_assignments';
import WeightBalanceTankAssignments from './weight_balance_tank_assignments';
import WeightBalanceAssetAssignments from './weight_balance_asset_assignments';
import WeightBalanceExternalLoadAssignments from './weight_balance_external_load_assignments';

const WeightBalance = (props) => {
  const {
    field,
    formValues,
    change,
    flightSegmentIndex,
    flightSegmentsLength,
    flightSegmentCalculatedData,
    isDeveloper,
  } = props;

  const dispatch = useDispatch();
  const tabKey = useSelector((state) => state.currentSettings.weightBalanceTabKey);
  const setTabKey = useCallback(
    (weightBalanceTabKey) => {
      dispatch(
        currentSettingsSet({
          weightBalanceTabKey,
        })
      );
    },
    [dispatch]
  );

  return (
    <Tabs id="weight-balance-fs-id" activeKey={tabKey} onSelect={setTabKey}>
      <Tab eventKey={0} title="Data">
        <WeightBalanceData flightSegmentCalculatedData={flightSegmentCalculatedData} />
      </Tab>
      {/* <Tab eventKey={1} title="Load">
        <WeightBalanceLoad />
      </Tab> */}
      <Tab eventKey={2} title="Long CG">
        <WeightBalanceLongCg flightSegmentCalculatedData={flightSegmentCalculatedData} />
      </Tab>
      <Tab eventKey={3} title="Lat CG">
        <WeightBalanceLatCg flightSegmentCalculatedData={flightSegmentCalculatedData} />
      </Tab>
      <Tab eventKey={4} title="Holds">
        <WeightBalanceHoldAssignments
          isDeveloper={isDeveloper}
          field={field}
          formValues={formValues}
          flightSegmentCalculatedData={flightSegmentCalculatedData}
        />
      </Tab>
      <Tab eventKey={5} title="Equipment">
        <WeightBalanceAssetAssignments
          isDeveloper={isDeveloper}
          field={field}
          formValues={formValues}
          change={change}
          flightSegmentIndex={flightSegmentIndex}
          flightSegmentsLength={flightSegmentsLength}
        />
      </Tab>
      <Tab eventKey={6} title="Seats">
        <WeightBalanceSeatAssignments
          isDeveloper={isDeveloper}
          field={field}
          formValues={formValues}
        />
      </Tab>
      {isDeveloper && (
        <Tab eventKey={7} title="Fuel">
          <WeightBalanceTankAssignments field={field} formValues={formValues} />
        </Tab>
      )}
      <Tab eventKey={8} title="External Load">
        <WeightBalanceExternalLoadAssignments
          isDeveloper={isDeveloper}
          field={field}
          formValues={formValues}
          flightSegmentCalculatedData={flightSegmentCalculatedData}
        />
      </Tab>
    </Tabs>
  );
};

export default WeightBalance;
