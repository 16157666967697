const WeightBalanceDataTableHeader = (props) => {
  const { bookingWeightUnit, bookingArmUnit } = props;
  return (
    <thead>
      <tr>
        <th className="text-start">Item</th>
        <th>
          Weight
          <br />({bookingWeightUnit})
        </th>
        <th>
          Long Arm
          <br />({bookingArmUnit})
        </th>
        <th>
          Long Moment
          <br />({bookingWeightUnit}-{bookingArmUnit})
        </th>
        <th>
          Lat Arm
          <br />({bookingArmUnit})
        </th>
        <th>
          Lat Moment
          <br />({bookingWeightUnit}-{bookingArmUnit})
        </th>
      </tr>
    </thead>
  );
};

export default WeightBalanceDataTableHeader;
