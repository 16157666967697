import { Row, Col, Table } from 'react-bootstrap';
import accounting from 'accounting';

import WeightBalanceDataTableHeader from './weight_balance_data_table_header';

const WeightBalanceData = (props) => {
  const { flightSegmentCalculatedData } = props;
  const {
    bookingArmUnit,
    bookingWeightUnit,
    convertedEmptyAircraft,
    convertedSeatAssignments,
    convertedHoldAssignments,
    convertedAssetAssignments,
    convertedExternalLoadAssignments,
    convertedZeroFuelAircraft,
    convertedZeroFuelAircraftWithExternalLoad,
    convertedTankAssignments,
    convertedAllUpAircraft,
    convertedAllUpAircraftWithExternalLoad,
  } = flightSegmentCalculatedData;

  const toFixed = (num, scale = 4) => accounting.toFixed(num, scale);

  const renderResultRow = (result, strong = false) => {
    const {
      id,
      name,
      weight,
      longitudinalArm,
      longitudinalMoment,
      lateralArm,
      lateralMoment,
    } = result;
    return (
      <tr
        key={`wb-fs-a-${id}`}
        style={{
          ...(strong && {
            borderBottom: '2px solid #dddddd',
            borderTop: '2px solid #dddddd',
          }),
        }}
      >
        <td className="text-start">{strong ? <strong>{name}</strong> : name}</td>
        <td>{toFixed(weight, 0)}</td>
        <td>{toFixed(longitudinalArm)}</td>
        <td>{toFixed(longitudinalMoment)}</td>
        <td>{toFixed(lateralArm)}</td>
        <td>{toFixed(lateralMoment)}</td>
      </tr>
    );
  };

  return (
    <Row className="mt-3">
      <Col xs={12}>
        <Table size="sm" striped className="text-end">
          <WeightBalanceDataTableHeader
            bookingWeightUnit={bookingWeightUnit}
            bookingArmUnit={bookingArmUnit}
          />
          <tbody>
            {renderResultRow(convertedEmptyAircraft, true)}
            {convertedSeatAssignments.map((seatAssignment) =>
              renderResultRow(seatAssignment)
            )}
            {convertedHoldAssignments.map((holdAssignment) =>
              renderResultRow(holdAssignment)
            )}
            {convertedAssetAssignments.map((assetAssignment) =>
              renderResultRow(assetAssignment)
            )}
            {renderResultRow(convertedZeroFuelAircraft, true)}

            {convertedTankAssignments.map((fuelTankAssignment) =>
              renderResultRow(fuelTankAssignment)
            )}
            {renderResultRow(convertedAllUpAircraft, true)}
          </tbody>
          {convertedAllUpAircraft.weight !==
            convertedAllUpAircraftWithExternalLoad.weight && (
            <tbody>
              <tr />
              <tr>
                <td colSpan={6}>
                  <hr />
                </td>
              </tr>
              {renderResultRow(convertedZeroFuelAircraft, true)}
              {convertedExternalLoadAssignments.map((externalLoadAssignment) =>
                renderResultRow(externalLoadAssignment)
              )}
              {renderResultRow(convertedZeroFuelAircraftWithExternalLoad, true)}
              {convertedTankAssignments.map((fuelTankAssignment) =>
                renderResultRow(fuelTankAssignment)
              )}
              {renderResultRow(convertedAllUpAircraftWithExternalLoad, true)}
            </tbody>
          )}
        </Table>
      </Col>
    </Row>
  );
};

export default WeightBalanceData;
