import actionTypes from '../constants';

export const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isEmployee: false,
  token: '',
  contactId: '',
  tenant: '',
  connectionKey: '',
  contact: {},
};

export default function authReducer(state = initialState, action) {
  const { type, payload, error } = action;
  if (error) {
    return state;
  }
  switch (type) {
    case actionTypes.AUTH_SET:
      return {
        ...initialState,
        ...payload,
      };
    case actionTypes.AUTH_RESET:
      return initialState;
    default:
      return state;
  }
}
