import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Check as CheckIcon, X as XIcon } from 'react-feather';
import qs from 'qs';
import get from 'lodash.get';

import Api from '../lib/api';
import { subscriptionClient, hydrateCache } from '../lib/apollo_client';
// import { authSet, authReset } from '../store/auth_slice';
import { authSet, authReset } from '../actions/auth_actions';
import { dataSet } from '../actions/data_actions';
import SubmitButtonSpinner from '../components/submit_button_spinner';
// import Glyphicon from '../components/glyphicon';

import Field from '../components/form/react_final_form_field';
import InputField from '../components/form/input_field';
import { loginFormValidator } from '../validators';
import { setIdToken, removeIdToken } from '../lib/local_storage';
// import { toastSuccess } from '../lib/toast_helpers';
import { toastSuccess } from '../lib/action_helpers';

import LoginLogo from '../images/login_logo_255X67.jpg';

export default function Login() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAuthenticating = useSelector((state) => state.auth.isAuthenticating);
  const settingsOnline = useSelector((state) => state.currentSettings.online);
  const settingsDeviceOnline = useSelector((state) => state.currentSettings.deviceOnline);
  const settingsServerOnline = useSelector((state) => state.currentSettings.serverOnline);
  const tenantLongName = useSelector((state) => state.currentSettings.tenantLongName);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const defaultPath = '/checks';
  let redirectPath = null;
  if (location.search) {
    redirectPath = get(
      qs.parse(location.search, { ignoreQueryPrefix: true }),
      'redirect',
      null
    );
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectPath || defaultPath);
    }
  }, [isAuthenticated, redirectPath, navigate]);

  const handleFormSubmit = async (data) => {
    try {
      if (settingsOnline) {
        dispatch(authSet({ isAuthenticating: true }));
        const { email, password } = data;
        const loginResp = await Api.post('/contacts/sign_in', {
          contact: { email, password },
        });
        const token = get(loginResp, 'data.token');
        const decoded = jwtDecode(token);
        setIdToken(token);
        dispatch(
          authSet({
            isAuthenticating: false,
            isAuthenticated: true,
            isEmployee: decoded.isEmployee,
            token,
            contact: decoded.contact,
            contactId: decoded.contact_id,
            tenant: decoded.tenant,
            connectionKey: decoded.connectionKey,
          })
        );
        toastSuccess('Login successful');
        // this will actually reboot the WS connection
        subscriptionClient.terminate();
        // load settings
        const dataResp = await Api.get('/api/data');
        dispatch(dataSet(dataResp.data));
        toastSuccess('Settings loaded');
        // load apollo cache
        await hydrateCache();
        navigate(redirectPath || defaultPath);
      } else {
        toastSuccess('Service offine');
      }
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed login', {
          err,
        });
      }
      removeIdToken();
      dispatch(authReset());
      navigate('/auth/logout');
      return err;
    }
    return undefined;
  };
  return (
    <Card>
      <Card.Body>
        <div className="text-center mt-3 mb-3">
          <img
            src={LoginLogo}
            alt={tenantLongName}
            // className="img-fluid rounded-circle me-2"
            className="img-fluid mb-2"
            style={{ height: '100px' }}
          />
        </div>
        {/* <Card.Body style={{ height: '400px' }}> */}
        <div className="m-sm-4">
          <input style={{ display: 'none' }} type="text" name="fakeemailremembered" />
          <input
            style={{ display: 'none' }}
            type="password"
            name="fakepasswordremembered"
          />
          {settingsOnline && (
            <FinalForm onSubmit={handleFormSubmit} validate={loginFormValidator}>
              {({ handleSubmit, submitting, pristine, invalid }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Field
                    type="text"
                    name="email"
                    autoCapitalize="none"
                    component={InputField}
                    helpText="&nbsp;"
                  >
                    Email
                  </Field>
                  <Field
                    type="password"
                    name="password"
                    autoCapitalize="none"
                    component={InputField}
                    helpText="&nbsp;"
                  >
                    Password
                  </Field>
                  <Form.Group as={Row}>
                    <Col sm={12}>
                      <div className="float-end">
                        <Button
                          className="float-end"
                          type="submit"
                          variant="primary"
                          disabled={pristine || invalid || submitting}
                        >
                          {(isAuthenticating || submitting) && <SubmitButtonSpinner />}
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Form.Group>
                </Form>
              )}
            </FinalForm>
          )}
          {!settingsOnline && (
            <div className="text-center mt-4">
              <p className="lead">You are either offline or SAAM is unavailable</p>
              <p>
                Device Online:
                <span className="ml-4">
                  {settingsDeviceOnline ? (
                    <CheckIcon color="green" />
                  ) : (
                    <XIcon color="red" />
                  )}
                </span>
              </p>
              <p>
                Service Online:
                <span className="ml-4">
                  {settingsServerOnline ? (
                    <CheckIcon color="green" />
                  ) : (
                    <XIcon color="red" />
                  )}
                </span>
              </p>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
