import { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import difference from 'lodash.difference';
import get from 'lodash.get';
import includes from 'lodash.includes';
import isEqual from 'lodash.isequal';
import without from 'lodash.without';

import InputField from '../form/input_field';
import InvalidBlock from '../form/invalid_block';
import LabelBlock from '../form/label_block';
import { getSelectable } from '../../lib/utils';

class EmployeeInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmployees: [],
    };
    this.handleSelectedEmployeeChange = this.handleSelectedEmployeeChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (get(this.props, 'formValues.booking_employees_attributes')) {
      this.setState({
        selectedEmployees: this.getSelectedEmployees(),
      });
    }
  }

  componentDidMount() {
    this.inputRef.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { selectedEmployees } = this.state;
    if (
      !isEqual(
        get(this.props, 'formValues.booking_employees_attributes'),
        get(nextProps, 'formValues.booking_employees_attributes')
      )
    ) {
      selectedEmployees = this.getSelectedEmployees(nextProps);
    }
    this.setState({
      selectedEmployees,
    });
  }

  handleSelectedEmployeeChange(selectedEmployees) {
    const currentIds = get(this.props, 'formValues.booking_employees_attributes')
      .filter((bookingEmployee) => !bookingEmployee._destroy)
      .map((bc) => bc.employee_id);
    const newIds = selectedEmployees.map((c) => c.id);
    const deleteIds = difference(currentIds, newIds);
    let addIds = difference(newIds, currentIds);

    this.props.bookingEmployees.fields.forEach((name, index, fields) => {
      const {
        id,
        employee_id: employeeId,
        _destroy,
      } = get(this.props, `formValues.${name}`);
      if (includes(deleteIds, employeeId)) {
        if (id) {
          this.props.change(`${name}._destroy`, true);
        } else {
          fields.remove(index);
        }
      } else if (includes(addIds, employeeId) && _destroy) {
        addIds = without(addIds, employeeId);
        this.props.change(`${name}._destroy`, false);
      }
    });
    if (addIds.length > 0) {
      addIds.forEach((addId) => {
        this.props.bookingEmployees.fields.push({ employee_id: addId });
      });
    }
  }

  getSelectedEmployees(props = this.props) {
    if (get(props, 'formValues.booking_employees_attributes')) {
      return get(props, 'formValues.booking_employees_attributes')
        .filter((bookingEmployee) => !bookingEmployee._destroy)
        .map((bookingEmployee) => {
          const employee = props.employees.find(
            (e) => e.id === bookingEmployee.employee_id
          );
          return {
            id: bookingEmployee.employee_id,
            name: get(employee, 'fullName', '404'),
          };
        });
    }
    return [];
  }

  getSelectableEmployees() {
    const selectedEmployeeIds = get(this.state, 'selectedEmployee', []).map((c) => c.id);
    return getSelectable(
      get(this.props, 'employees', []),
      [],
      'fullName',
      'roleEmployee'
    ).filter((c) => !includes(selectedEmployeeIds, c.id));
  }

  render() {
    const {
      bookingEmployees: { meta: bookingEmployeesMeta },
    } = this.props;
    return (
      <Row>
        <InputField
          size="sm"
          labelWidth={0}
          inputWidth={12}
          input={{
            name: 'employee-ids',
          }}
          innerContent={
            <>
              <LabelBlock size="sm">Scheduled for Employees</LabelBlock>
              <Typeahead
                id="typeahead-employeeids"
                ref={(el) => {
                  this.inputRef = el;
                }}
                multiple
                onChange={this.handleSelectedEmployeeChange}
                options={this.getSelectableEmployees()}
                selected={this.state.selectedEmployees}
                filterBy={['name']}
                labelKey="name"
                minLength={1}
                size="sm"
              />
              <InvalidBlock meta={bookingEmployeesMeta} force />
            </>
          }
        />
      </Row>
    );
  }
}

export default EmployeeInputField;
