import { Row, Col, Button } from 'react-bootstrap';
import get from 'lodash.get';

import Confirm from '../confirm';
import InputField from '../form/input_field';
import CheckboxInputField from '../form/checkbox_input_field';
import Glyphicon from '../glyphicon';

const LocationFormFuelBowserFields = (props) => {
  const {
    size,
    labelWidth,
    inputWidth,
    fuelAvailabilities,
    fuelCompanies,
    fuelPumpAccesses,
    fuelTypes,
    handleDeleteClick: parentHandleDeleteClick,
    index,
    parentField,
  } = props;

  const {
    fuel_availability_id: {
      input: fuelAvailabilityIdInput,
      meta: fuelAvailabilityIdMeta,
    },
    fuel_company_id: { input: fuelCompanyIdInput, meta: fuelCompanyIdMeta },
    fuel_pump_access_id: { input: fuelPumpAccessIdInput, meta: fuelPumpAccessIdMeta },
    fuel_type_id: { input: fuelTypeIdInput, meta: fuelTypeIdMeta },
    price: { input: priceInput, meta: priceMeta },
    active: { input: activeInput, meta: activeMeta },
  } = get(props, parentField);

  const handleDeleteClick = () => {
    if (parentHandleDeleteClick) {
      parentHandleDeleteClick(index);
    }
  };

  const renderDeleteMessage = () => {
    let msg = 'Are you sure you want to delete this fuel bowser information?';
    if (get(props, 'formValues.id')) {
      msg +=
        ' Note that while the information will be removed from the list, it will not be permanently deleted until you save this Location.';
    }
    return msg;
  };

  return (
    <fieldset className="border rounded-3 p-3">
      <Row>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          asElement="select"
          selectOptions={fuelTypes}
          input={fuelTypeIdInput}
          meta={fuelTypeIdMeta}
        >
          Fuel Type
        </InputField>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          asElement="select"
          selectOptions={fuelCompanies}
          input={fuelCompanyIdInput}
          meta={fuelCompanyIdMeta}
        >
          Fuel Company
        </InputField>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          asElement="select"
          selectOptions={fuelAvailabilities}
          input={fuelAvailabilityIdInput}
          meta={fuelAvailabilityIdMeta}
        >
          Fuel Availability
        </InputField>
      </Row>
      <Row>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          asElement="select"
          selectOptions={fuelPumpAccesses}
          input={fuelPumpAccessIdInput}
          meta={fuelPumpAccessIdMeta}
        >
          Fuel Pump Access
        </InputField>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={priceInput}
          meta={priceMeta}
          helpText="required"
        >
          Price
        </InputField>
        <CheckboxInputField
          size={size}
          labelWidth={0}
          inputWidth={1}
          meta={activeMeta}
          input={activeInput}
          onChange={(e) => activeInput.onChange(e.target.checked)}
          className="mt-4"
        >
          Active
        </CheckboxInputField>
        <Col sm={1}>
          <Confirm
            onConfirm={handleDeleteClick}
            title="Delete Fuel Bowser"
            body={renderDeleteMessage()}
            confirmText="Confirm"
          >
            <Button type="button" variant="danger" size={size} className="mt-4">
              <Glyphicon glyph="bin" />
            </Button>
          </Confirm>
        </Col>
      </Row>
    </fieldset>
  );
};

LocationFormFuelBowserFields.defaultProps = {
  size: 'sm',
  labelWidth: 0,
  inputWidth: 4,
};

export default LocationFormFuelBowserFields;
