import { useCallback, useMemo, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';

import Field from '../components/form/react_final_form_field';

import { currentSettingsSet } from '../actions/current_setting_actions';
import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import FormButtons from '../components/form/form_buttons';
import InputField from '../components/form/input_field';
import FileTypeIcon from '../components/file_type_icon';
import DocumentField from '../components/form/document_form/document_field';

import { toastSuccess, toastError } from '../lib/action_helpers';
import {
  coerceInput,
  pickValues,
  handleSubmitError,
  getFileExtension,
} from '../lib/utils';
import documentCreateMutation from '../mutations/document_create_mutation';
import documentUpdateMutation from '../mutations/document_update_mutation';
import pageDocumentFormQuery from '../queries/page_document_form_query';
import { documentFormValidator } from '../validators';
import { documentDefaultValues } from '../defaults';
import { documentWhiteList } from '../white_lists';

const DocumentForm = () => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const currentContact = useSelector((state) => state.currentContact);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const [documentUpdate] = useMutation(documentUpdateMutation);
  const [documentCreate] = useMutation(documentCreateMutation);
  const navigate = useNavigate();
  const params = useParams();

  // todo tag collection.  look in document_form_old for code

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageDocumentFormQuery, {
    variables: {
      hasDocumentId: !!params.id,
      documentId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const document = get(data, 'document', {});
      if (document.id) {
        const documentFileName = document.document_file_name;
        setFileName(documentFileName);
        const newFileExtension = getFileExtension(documentFileName);
        setFileExtension(newFileExtension);
      }
    },
  });

  const pageLoadedOrRefetching = useMemo(
    () =>
      !pageLoading ||
      (pageLoading &&
        [NetworkStatus.refetch, NetworkStatus.setVariables].includes(pageNetworkStatus)),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.document) {
      const values = pickValues(pageData.document, documentWhiteList);
      return values;
    }
    return documentDefaultValues;
  }, [pageData]);

  const documentCategoryOptions = useMemo(() => {
    if (pageData?.documentCategoryList) {
      const getDocumentCategoryAncestryName = (documentCategory) => {
        const { rgt, lft } = documentCategory;
        return pageData.documentCategoryList
          .filter((dc) => dc.lft <= lft && dc.rgt >= rgt)
          .sort((a, b) => a.lft - b.lft)
          .map((dc) => dc.name)
          .join(' | ');
      };
      return pageData.documentCategoryList
        .map((dc) => ({
          id: dc.id,
          name: getDocumentCategoryAncestryName(dc),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    return [];
  }, [pageData]);

  const onFormSubmit = useCallback(
    async (data) => {
      let mutation;
      let mutationMessageAction;
      const submitData = cloneDeep(data);
      if (submitData.document) {
        submitData.uploader_id = currentContact.id;
        submitData.uploaded_at = moment().format();
      }
      const mutationData = {
        context: { hasUpload: true },
        variables: { input: coerceInput(submitData) },
      };
      if (params.id) {
        mutationData.variables.id = params.id;
        mutation = documentUpdate;
        mutationMessageAction = 'update';
      } else {
        mutation = documentCreate;
        mutationMessageAction = 'create';
      }
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await mutation(mutationData);
        toastSuccess(`Document ${mutationMessageAction} succeeded`);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        navigate('/documents');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
      return undefined;
    },
    [params.id, currentContact.id, documentCreate, documentUpdate, dispatch, navigate]
  );

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title form updating={!!params.id}>
            Document
          </Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <LinkContainer to="/documents">
                <Button size="sm" variant="primary">
                  All Documents
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validate={documentFormValidator}
            mutators={{ setFieldTouched, ...arrayMutators }}
          >
            {({ handleSubmit, pristine, submitting, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <fieldset className="border rounded-3 p-3">
                  <legend className="float-none w-auto px-3 fs-6">
                    Document Details
                  </legend>
                  {!!params.id && (
                    <>
                      <InputField
                        type="text"
                        plainText
                        labelWidth={3}
                        inputWidth={6}
                        input={{
                          name: 'current_file_name',
                          value: fileName,
                        }}
                      >
                        Current File Name
                      </InputField>
                      <InputField
                        type="text"
                        plainText
                        labelWidth={3}
                        inputWidth={6}
                        input={{
                          name: 'current_file_name',
                        }}
                        innerContent={
                          <FileTypeIcon extension={fileExtension} size="x2" />
                        }
                      >
                        Current File Type
                      </InputField>
                    </>
                  )}
                  <Field
                    type="text"
                    name="description"
                    labelWidth={3}
                    inputWidth={6}
                    component={InputField}
                    asElement="textarea"
                    rows={3}
                  >
                    Description
                  </Field>
                  <Field
                    asElement="select"
                    name="document_category_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    selectOptions={documentCategoryOptions}
                  >
                    Category
                  </Field>
                  <DocumentField values={values} inputWidth={6} />
                </fieldset>
                <Row>
                  <FormButtons
                    updating={!!params.id}
                    pristine={pristine}
                    submitting={submitting}
                    cancelLink="/documents"
                  />
                </Row>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default DocumentForm;
