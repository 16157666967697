import { Col, Button, ButtonGroup, Card } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash.get';
import has from 'lodash.has';

import Confirm from '../confirm';
import Glyphicon from '../glyphicon';

const { FileReader } = window;

class LocationFormLocationImageImageField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inlineImage: null,
    };
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this._handlePromoteClick = this._handlePromoteClick.bind(this);
    this._handleDemoteClick = this._handleDemoteClick.bind(this);
    this.defaultImage =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.image.lastModified') !==
      get(nextProps, 'formValues.image.lastModified')
    ) {
      if (get(nextProps, 'formValues.image.lastModified')) {
        const reader = new FileReader();
        reader.onload = (upload) => {
          this.setState({
            inlineImage: upload.target.result,
          });
        };
        reader.readAsDataURL(get(nextProps, 'formValues.image'));
      } else {
        this.setState({
          inlineImage: null,
        });
      }
    }
  }

  _handlePromoteClick() {
    if (this.props.handlePromoteClick) {
      this.props.handlePromoteClick(this.props.index);
    }
  }

  _handleDemoteClick() {
    if (this.props.handleDemoteClick) {
      this.props.handleDemoteClick(this.props.index);
    }
  }

  _handleDeleteClick() {
    if (this.props.handleDeleteClick) {
      this.props.handleDeleteClick(this.props.index);
    }
  }

  _renderDeleteMessage() {
    let msg = 'Are you sure you want to delete this image?';
    if (get(this.props, 'formValues.id')) {
      msg +=
        ' Note that while the image will be removed from the list, it will not be permanently deleted until you save this Location.';
    }
    return msg;
  }

  _handleImageChange(e) {
    const file = e.target.files[0];
    if (['image/jpg', 'image/jpeg', 'image/png'].indexOf(file.type) > -1) {
      this.props.input.onChange(file);
    }
  }

  render() {
    const { promoteDisabled, demoteDisabled, locationImageUrl } = this.props;

    return (
      <Col xs={3}>
        <Card>
          <Card.Img
            variant="top"
            src={this.state.inlineImage || locationImageUrl || this.defaultImage}
            style={{
              height: this.state.inlineImage || locationImageUrl ? '200px' : '50px',
            }}
          />
          <Card.Footer>
            <ButtonGroup className="me-4 mb-1" size="sm">
              <Button
                type="button"
                variant="dark"
                onClick={this._handlePromoteClick}
                disabled={promoteDisabled}
              >
                <Glyphicon glyph="arrow-left" />
              </Button>
              <Button
                type="button"
                variant="dark"
                onClick={this._handleDemoteClick}
                disabled={demoteDisabled}
              >
                <Glyphicon glyph="arrow-right" />
              </Button>
            </ButtonGroup>
            <ButtonGroup className="mb-1" size="sm">
              <Confirm
                onConfirm={this._handleDeleteClick}
                title="Delete Location Image"
                body={this._renderDeleteMessage()}
                confirmText="Confirm"
              >
                <Button type="button" variant="dark">
                  <Glyphicon glyph="bin" />
                </Button>
              </Confirm>
              {has(this.props, 'formValues.id') || (
                <label className="btn btn-dark mb-0">
                  <Glyphicon glyph="upload" />
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this._handleImageChange}
                  />
                </label>
              )}
            </ButtonGroup>
          </Card.Footer>
        </Card>
      </Col>
    );
  }
}

export default LocationFormLocationImageImageField;
