import { useCallback, useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Card, Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { currentSettingsSet } from '../actions/current_setting_actions';
import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import ReactTable from '../components/react_table';

import { toastSuccess, toastError } from '../lib/action_helpers';
import fuelTankerDeleteMutation from '../mutations/fuel_tanker_delete_mutation';
import pageFuelTankerListQuery from '../queries/page_fuel_tanker_list_query';

const FuelTankerList = () => {
  const dispatch = useDispatch();
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const currentContact = useSelector((state) => state.currentContact);
  const [fuelTankerDelete] = useMutation(fuelTankerDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageFuelTankerListQuery, { notifyOnNetworkStatusChange: true });

  const renderLinkCell = useCallback((row) => {
    const id = row.getValue('id');
    return (
      <ButtonGroup key={id}>
        <LinkContainer
          to={`/reports/fuel_bowser_fill_for_fuel_tanker/${moment().format(
            'MM-YYYY'
          )}/${id}`}
        >
          <Button size="sm" variant="link">
            tanker fill report
          </Button>
        </LinkContainer>
        <LinkContainer
          to={`/reports/fuel_tanker_fill_for_aircraft/${moment().format(
            'MM-YYYY'
          )}/${id}`}
        >
          <Button size="sm" variant="link">
            aircraft fill report
          </Button>
        </LinkContainer>
        <LinkContainer to={`/fuel_tankers/${id}/fuel_bowser_fill/new`}>
          <Button size="sm" variant="link">
            add tanker fill
          </Button>
        </LinkContainer>
      </ButtonGroup>
    );
  }, []);

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
      enableColumnFilter: false,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      filterFn: 'includesString',
    },
    {
      header: 'Current location',
      accessorKey: 'current_location',
      enableColumnFilter: false,
    },
    {
      header: 'Links',
      id: 'links',
      cell: ({ row }) => renderLinkCell(row),
    },
  ];

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleRefetch = () => pageRefetch();

  const handleDelete = useCallback(
    async (e) => {
      const fuelTankerId = e.currentTarget.getAttribute('data-id');
      const mutationData = {
        variables: { id: fuelTankerId },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await fuelTankerDelete(mutationData);
        toastSuccess('FuelTanker delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('FuelTanker delete failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [fuelTankerDelete, dispatch]
  );

  const getTableTools = () => {
    if (currentContact['manager?'] || currentContact['office_admin?']) {
      return {
        handleDelete,
      };
    }
    return {};
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title list>Fuel Tankers</Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                size="sm"
                variant="primary"
                onClick={handleRefetch}
                disabled={pageLoading}
              >
                Refresh
              </Button>
            </Col>
            <Col sm="auto">
              <LinkContainer to="/fuel_tankers/new">
                <Button size="sm" variant="primary">
                  Add a Fuel Tanker
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      {pageData?.fuelTankerList.length ? (
        <ReactTable
          rootName="fuelTanker"
          parentColumns={parentColumns}
          data={pageData.fuelTankerList}
          initialStateSorting={[{ id: 'name', desc: false }]}
          {...getTableTools()}
        />
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>No fuel tankers</Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default FuelTankerList;
