/* eslint-disable no-bitwise  */
import {
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Carousel,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';
import L from 'leaflet';

import get from 'lodash.get';
import merge from 'lodash.merge';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import Confirm from '../components/confirm';
import Loader from '../components/loader';
import DlHorizontal from '../components/dl_horizontal';
import Title from '../components/title';

import locationDeleteMutation from '../mutations/location_delete_mutation';

import { queriesReady, queryJustReady, getExport } from '../lib/utils';

import locationShowQuery from '../queries/location_show_query';

const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoiZ29yZG9ua2luZzAyIiwiYSI6ImNpbnUxbWJ3NjExZ2x1aWtqaThraGM3dmsifQ.rKb2iQMCm5ZPhJGfsSg_mg';

const baseLayerTemplates = [
  {
    name: 'Mapbox - Satellite Streets',
    maptype: 'mapbox/satellite-streets-v11',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    name: 'Mapbox - Street',
    maptype: 'mapbox/streets-v11',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    name: 'Mapbox - Satellite',
    maptype: 'mapbox/satellite-v9',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
];

moment.updateLocale('en-nz');

class LocationShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: [0, 0],
      zoom: 15,
      label: 'loading',
    };
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.mountMap = this.mountMap.bind(this);

    this.map = null;
    this.marker = null;
    this.baseLayers = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (queryJustReady(this.props.locationQuery, nextProps.locationQuery)) {
      if (
        get(nextProps, 'locationQuery.data.latitude') &&
        get(nextProps, 'locationQuery.data.longitude')
      ) {
        this.setState({
          position: [
            get(nextProps, 'locationQuery.data.latitude'),
            get(nextProps, 'locationQuery.data.longitude'),
          ],
          label: get(nextProps, 'locationQuery.data.long_name'),
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.map &&
      get(prevProps, 'locationQuery.data.latitude') !==
        get(this.props, 'locationQuery.data.latitude')
    ) {
      this.map.setView(this.state.position, this.state.zoom);
      this.marker.setLatLng(this.state.position).setPopupContent(get(this.state.label));
    }
  }

  createBaseLayer = (baseLayer) => {
    const { maptype, url, attribution } = baseLayer;
    const element = L.tileLayer(url, {
      attribution,
      id: maptype,
      accessToken: MAPBOX_API_TOKEN,
      tileSize: 512,
      zoomOffset: -1,
    });
    return Object.assign({}, baseLayer, { element });
  };

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const exportType = e.target.getAttribute('data-export-type');
    getExport(reportName, {}, null, exportType)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  convertDdDmsCoordinate = (D) => {
    const degrees = 0 | D;
    // eslint-disable-next-line no-param-reassign
    const minutes = 0 | (((D < 0 ? (D = -D) : D) % 1) * 60);
    const seconds = 0 | (((D * 60) % 1) * 60);
    return [degrees, minutes, seconds].join(' ');
  };

  convertDdDmmCoordinate = (D) => {
    const degrees = 0 | D;
    // eslint-disable-next-line no-param-reassign
    const minutes = 0 | (((D < 0 ? (D = -D) : D) % 1) * 60);
    const seconds = 0 | (((D * 60) % 1) * 60);
    let decimalMinutes = minutes + seconds / 60;
    decimalMinutes = Math.round(decimalMinutes * 100000000000000) / 100000000000000;
    return [degrees, decimalMinutes].join(' ');
  };

  convertFromDd(coordinate, displayFormat) {
    switch (parseInt(displayFormat, 10)) {
      case 2: // decimal minutes -> dd
        return this.convertDdDmmCoordinate(coordinate);
      case 1: // degree minute seconds -> dd
        return this.convertDdDmsCoordinate(coordinate);
      default:
        return coordinate;
    }
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.locationQuery);
  }

  mountMap(ref) {
    if (ref) {
      this.map = L.map('location-show-map-new', {
        zoom: this.state.zoom,
        center: this.state.position,
      });
      this.baseLayers = baseLayerTemplates.map((baseLayer) =>
        this.createBaseLayer(baseLayer)
      );
      const baseLayersMenu = this.baseLayers.reduce(
        (result, layer) => merge({}, result, { [layer.name]: layer.element }),
        {}
      );
      L.control.layers(baseLayersMenu, {}, { position: 'topright' }).addTo(this.map);
      baseLayersMenu[this.baseLayers[0].name].addTo(this.map);
      this.marker = L.marker(this.state.position)
        .addTo(this.map)
        .bindPopup(this.state.label)
        .openPopup();
    } else {
      this.map.remove();
      this.map = null;
      this.marker = null;
      this.baseLayers = null;
    }
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const locationId = e.currentTarget.getAttribute('data-id');
    this.props
      .locationDeleteMutation({
        variables: {
          id: locationId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Location delete');
        this.props.navigate('/locations');
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  renderBoolean = (attribute) => {
    if (attribute) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  renderDisplayFormat() {
    const displayFormatId = get(
      this.props,
      'locationQuery.display_format',
      this.props.currentSettingsLocationDefaultDisplayFormatId
    );
    return get(
      this.props.currentSettingsLocationDisplayFormats.find(
        (df) => df.id === displayFormatId
      ),
      'name'
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoaded()) {
      const location = this.props.locationQuery.data;
      const {
        approach_depart_procedures: approachDepartProcedures,
        airways_fee: airwaysFee,
        display_format: displayFormat,
        fuel_notes: fuelNotes,
        gps_waypoint_name: gpsWaypointName,
        hazards,
        landing_fee: landingFee,
        long_name: longName,
        id,
        latitude,
        longitude,
        pal_frequency: palFrequency,
        named_pilots_only: namedPilotsOnly,
        notes,
        sensitive_location: sensitiveLocation,
        sensitive_nature: sensitiveNature,
        short_name: shortName,
        shorthand_name: shorthandName,
        fuelBowsers,
        fullName,
        locationAvailability,
        locationImageLargeUrls,
        locationLighting,
        locationServices,
        locationStatus,
        locationSurface,
        locationType,
        manager,
        namedPilots,
      } = location;

      return (
        <>
          <Row className="mt-4 mb-3">
            <Col sm="auto">
              <Title show fullName={fullName}>
                Location
              </Title>
            </Col>
            <Col>
              <Row className="justify-content-end g-0">
                <Col sm="auto">
                  <ButtonToolbar>
                    <ButtonGroup size="sm" className="mx-1">
                      <Confirm
                        dataId={id}
                        onConfirm={this.handleDeleteClick}
                        title="Delete Location"
                        body="Are you sure you want to delete this Location?"
                        confirmText="Confirm"
                      >
                        <Button variant="danger">Delete</Button>
                      </Confirm>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="mx-1">
                      <LinkContainer to={`/locations/${id}/edit`}>
                        <Button variant="primary">Edit</Button>
                      </LinkContainer>
                      <Button
                        variant="primary"
                        data-report-name={`locations/${id}`}
                        data-export-type="html"
                        onClick={this.getExport}
                      >
                        Print
                      </Button>
                      <Button
                        variant="primary"
                        data-report-name={`locations/${id}`}
                        data-export-type="pdf"
                        onClick={this.getExport}
                      >
                        PDF
                      </Button>
                      <Button
                        variant="primary"
                        data-report-name={`locations/${id}`}
                        data-export-type="kml"
                        onClick={this.getExport}
                      >
                        KML
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="mx-1">
                      <LinkContainer to="/locations">
                        <Button variant="primary">All Locations</Button>
                      </LinkContainer>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row className="mb-4">
            <Col sm={6}>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>General Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal dt="Long Name" dd={longName} />
                      <DlHorizontal dt="Short Name" dd={shortName} />
                      <DlHorizontal dt="Shorthand Name" dd={shorthandName} />
                      <DlHorizontal dt="GPS Waypoint Name" dd={gpsWaypointName} />
                      <DlHorizontal
                        dt="Responsible Person Name"
                        dd={manager && manager.fullName}
                      />
                      <DlHorizontal
                        dt="Location Status"
                        dd={locationStatus && locationStatus.name}
                      />
                      <DlHorizontal
                        dt="Location Type"
                        dd={locationType && locationType.name}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Mapping Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal dt="Decimal Latitude" dd={latitude} />
                      <DlHorizontal dt="Decimal Longitude" dd={longitude} />
                      <DlHorizontal
                        dt="Entered Display Format"
                        dd={this.renderDisplayFormat()}
                      />
                      <DlHorizontal
                        dt="Entered Latitude"
                        dd={this.convertFromDd(latitude, displayFormat)}
                      />
                      <DlHorizontal
                        dt="Entered Longitude"
                        dd={this.convertFromDd(longitude, displayFormat)}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Landing Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal
                        dt="Location Availability"
                        dd={locationAvailability && locationAvailability.name}
                      />
                      <DlHorizontal
                        dt="Location Surface"
                        dd={locationSurface && locationSurface.name}
                      />
                      <DlHorizontal
                        dt="Location Lighting"
                        dd={locationLighting && locationLighting.name}
                      />
                      <DlHorizontal dt="PAL Frequency" dd={palFrequency} />
                      <DlHorizontal
                        dt="Location Services"
                        dd={locationServices.map((ls) => ls.name).join(', ')}
                      />
                      <DlHorizontal dt="Landing Fee" dd={landingFee} />
                      <DlHorizontal dt="Airways Fee" dd={airwaysFee} />
                      <DlHorizontal
                        dt="Sensitive Location"
                        dd={this.renderBoolean(sensitiveLocation)}
                      />
                      <DlHorizontal dt="Sensitive Nature" dd={sensitiveNature} />
                      <DlHorizontal
                        dt="Approach & Departure"
                        dd={approachDepartProcedures}
                      />
                      <DlHorizontal dt="Hazards" dd={hazards} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Map</Card.Header>
                    <Card.Body>
                      <div
                        id="location-show-map-new"
                        style={{ height: '400px' }}
                        ref={(input) => {
                          this.mountMap(input);
                        }}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Other Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal
                        dt="Named Pilots Only"
                        dd={this.renderBoolean(namedPilotsOnly)}
                      />
                      <DlHorizontal
                        dt="Named Pilot Names"
                        dd={namedPilots.map((p) => p.fullName).join(', ')}
                      />
                      <DlHorizontal dt="Notes" dd={notes} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Card>
                    <Card.Header>Fuel Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal dt="Fuel Notes" dd={fuelNotes} />
                      <DlHorizontal
                        dt="Fuel Bowsers"
                        dd={fuelBowsers.map((fb) => fb.name).join(', ')}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <Card.Header>Images</Card.Header>
                <Card.Body>
                  {locationImageLargeUrls && locationImageLargeUrls.length > 0 ? (
                    <Carousel interval={8000}>
                      {locationImageLargeUrls.map((image) => (
                        <Carousel.Item key={image.id}>
                          <img alt="" height={768} width={1024} src={image.url} />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <p>No images</p>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsLocationDisplayFormats: state.currentSettings.location_display_formats,
    currentSettingsLocationDefaultDisplayFormatId:
      state.currentSettings.location_default_display_format_id,
  };
}

export default compose(
  graphql(locationDeleteMutation, {
    name: 'locationDeleteMutation',
  }),
  graphql(locationShowQuery, {
    name: 'locationQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, { mutationSuccess, mutationFailure, mutationSet })
)(LocationShow);
