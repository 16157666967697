import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { reduxPersistor } from '../store';
import { apolloClient, subscriptionClient } from '../lib/apollo_client';
import Api from '../lib/api';
import { removeIdToken, clearLocalStorage } from '../lib/local_storage';
// import { authReset } from '../store/auth_slice';
// import { toastSuccess } from '../lib/toast_helpers';
import { toastSuccess, toastError } from '../lib/action_helpers';

import { authReset } from '../actions/auth_actions';
import { dataReset } from '../actions/data_actions';

export default function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const settingsOnline = useSelector((state) => state.currentSettings.online);

  useEffect(() => {
    const logout = async () => {
      try {
        removeIdToken();
        subscriptionClient.terminate();
        apolloClient.stop();
        await apolloClient.clearStore();
        if (location.search.includes('?reset')) {
          await reduxPersistor.purge();
          await clearLocalStorage();
        }
        if (settingsOnline) {
          await Api.get('/contacts/sign_out');
        }
        dispatch(authReset());
        dispatch(dataReset());
        toastSuccess('Logout successful');
        navigate('/auth/login');
      } catch (err) {
        console.log(err);
        removeIdToken();
        dispatch(authReset());
        dispatch(dataReset());
        toastError('Logout unsuccessful');
      }
    };
    logout();
  }, [dispatch, navigate, settingsOnline, location]);

  return <p style={{ color: 'red' }}>Logging out...</p>;
}
