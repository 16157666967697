import { Component } from 'react';

import trim from 'lodash.trim';

class ErrorBoundaryApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    if (window.$NODE_ENV !== 'development') {
      try {
        const exception = {
          path: window.location.href,
          error: error ? error.toString() : 'error',
          errorInfo: errorInfo.componentStack
            .split('\n')
            .map((line) => trim(line))
            .filter((line) => line),
        };
        window.Rollbar.error('[Boundary error]', exception);
      } catch (err) {
        console.log('rollbar fail');
        console.log(err);
        console.log(error);
        console.log(errorInfo);
      }
    }
  }

  render() {
    if (this.state.error) {
      if (window.$NODE_ENV === 'development') {
        return (
          <div>
            <h1>Error Boundary</h1>
            <h3>{this.state.error && this.state.error.toString()}</h3>
            <pre>{this.state.errorInfo.componentStack}</pre>
          </div>
        );
      } else {
        return (
          <div style={{ height: `${this.props.containerHeight}px`, marginTop: 0 }}>
            <div style={{ height: '100%' }}>
              <div className="error-container">
                <div
                  style={{
                    display: 'table',
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                    <div style={{ margin: '0 auto', width: '300px' }}>
                      <h2>Something went wrong</h2>
                      <br />
                      <p>This error has been logged and we will get to it shortly.</p>
                      <br />
                      <a
                        className="btn btn-primary btn-block"
                        href={window.location.href}
                      >
                        Try Again
                      </a>
                      <br />
                      <a className="btn btn-white btn-block" href="/bookings">
                        Home
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundaryApp;
