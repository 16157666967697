import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash.get';
import pick from 'lodash.pick';

import HoldConfigurationFormAircraftHoldHoldConfigurationFields from './aircraft_hold_hold_configuration_fields';

class HoldConfigurationFormAircraftHoldHoldConfigurationFieldArray extends Component {
  renderList() {
    const formKeys = ['id'];
    return (
      <Col sm={{ span: 9, offset: 3 }}>
        {this.props.aircraftHoldHoldConfigurations.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}.hold_installed`,
              `${field}.hold_configuration_id`,
              `${field}.aircraft_hold_id`,
              `${field}.position`,
            ];
            return (
              <Fields
                key={`fb-${field}`}
                index={index}
                parentField={field}
                names={fields}
                component={HoldConfigurationFormAircraftHoldHoldConfigurationFields}
                formValues={pick(values, formKeys)}
                aircraftTypes={this.props.aircraftTypes}
                aircraftTypeId={get(this.props, 'formValues.aircraft_type_id')}
              />
            );
          }
          return undefined;
        })}
      </Col>
    );
  }

  render() {
    return <Row>{this.renderList()}</Row>;
  }
}

export default HoldConfigurationFormAircraftHoldHoldConfigurationFieldArray;
