import { useCallback } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import compact from 'lodash.compact';
import get from 'lodash.get';

import WeightBalance from './weight_balance';
import { currentSettingsSet } from '../../actions/current_setting_actions';

const WeightBalances = (props) => {
  const {
    flightSegments,
    formValues,
    locationsDataSelector,
    flightSegmentsCalculatedData,
    change,
    isDeveloper,
  } = props;

  const dispatch = useDispatch();
  const tabKey = useSelector((state) => state.currentSettings.weightBalancesTabKey);
  const setTabKey = useCallback(
    (weightBalancesTabKey) => {
      dispatch(
        currentSettingsSet({
          weightBalancesTabKey,
        })
      );
    },
    [dispatch]
  );

  const tabTitle = (flightSegmentValues, activeIndex) => {
    const { start_location_id: startLocationId, end_location_id: endLocationId } =
      flightSegmentValues;
    const startLocationShorthandName = get(locationsDataSelector, [
      startLocationId,
      'shorthandName',
    ]);
    const endLocationShorthandName = get(locationsDataSelector, [
      endLocationId,
      'shorthandName',
    ]);
    return compact([
      String(activeIndex),
      startLocationShorthandName,
      endLocationShorthandName,
    ]).join('-');
  };

  let activeIndex = -1;
  const flightSegmentsLength = flightSegments.fields.length;

  return (
    <Tabs id="weight-balances-fs-id" activeKey={tabKey} onSelect={setTabKey}>
      {flightSegments.fields.map((field, index) => {
        const flightSegmentValues = get(formValues, field, {});
        if (!flightSegmentValues._destroy) {
          activeIndex += 1;
          return (
            <Tab
              key={field}
              eventKey={activeIndex}
              title={tabTitle(flightSegmentValues, activeIndex)}
            >
              <Row style={{ marginTop: '10px' }}>
                <Col sm={12}>
                  <WeightBalance
                    field={field}
                    formValues={flightSegmentValues}
                    change={change}
                    flightSegmentIndex={index}
                    flightSegmentsLength={flightSegmentsLength}
                    flightSegmentCalculatedData={get(
                      flightSegmentsCalculatedData,
                      [activeIndex],
                      {}
                    )}
                    isDeveloper={isDeveloper}
                  />
                </Col>
              </Row>
            </Tab>
          );
        }
        return undefined;
      })}
    </Tabs>
  );
};

export default WeightBalances;
