import { Row, Col, Button, InputGroup } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash.get';

import InputField from '../form/input_field';
import InvalidBlock from '../form/invalid_block';

class FuelBowserFillFields extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.bookingAircraftId !== nextProps.bookingAircraftId) {
      const input = get(nextProps, `${nextProps.parentField}bowser_fillable_id.input`);
      if (nextProps.bookingAircraftId) {
        input.onChange(nextProps.bookingAircraftId);
      } else {
        input.onChange('');
      }
    }
  }

  handleDeleteButtonClick() {
    const id = get(this.props, 'formValues.id', '');
    if (id) {
      const input = get(this.props, `${this.props.parentField}._destroy.input`);
      input.onChange(true);
    } else if (this.props.handleDeleteButtonClick) {
      this.props.handleDeleteButtonClick(this.props.index);
    }
  }

  render() {
    const {
      quantity_value: { input: quantityValueInput, meta: quantityValueMeta },
      fuel_bowser_id: { input: fuelBowserIdInput, meta: fuelBowserIdMeta },
    } = get(this.props, this.props.parentField);

    return (
      <InputField
        size="sm"
        labelWidth={2}
        inputWidth={10}
        input={{}}
        innerContent={
          <Row>
            <Col sm={2}>
              <InputGroup size="sm">
                <InputField
                  controlOnly
                  blurOnly
                  type="text"
                  input={quantityValueInput}
                  meta={quantityValueMeta}
                  size="sm"
                />
                <InputGroup.Text>
                  {this.props.currentSettingsFuelBowserFillDefaultQuantityUnit}
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col sm={3}>
              <InputField
                controlOnly
                type="text"
                size="sm"
                asElement="select"
                selectOptions={this.props.selectOptions}
                input={fuelBowserIdInput}
                meta={fuelBowserIdMeta}
              />
            </Col>
            <Col sm={7}>
              <Button
                type="button"
                variant="link"
                size="sm"
                onClick={this.handleDeleteButtonClick}
                tabIndex={-1}
              >
                del...
              </Button>
            </Col>
            <Col sm={12}>
              <InvalidBlock meta={quantityValueMeta} force />
              <InvalidBlock meta={fuelBowserIdMeta} force />
            </Col>
          </Row>
        }
      >
        {`Bowser Refuel ${this.props.index + 1}`}
      </InputField>
    );
  }
}

export default FuelBowserFillFields;
