import { useMemo, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import get from 'lodash.get';

import InputField from '../form/input_field';
import Confirm from '../confirm';
import Glyphicon from '../glyphicon';

const WbLimitFormWbLimitPointFields = (props) => {
  const {
    index,
    formValues,
    parentField,
    size,
    labelWidth,
    inputWidth,
    handleDeleteClick: parentHandleDeleteClick,
  } = props;
  const {
    position: { input: positionInput, meta: positionMeta },
    wb_limit_arm: { input: wbLimitArmInput, meta: wbLimitArmMeta },
    wb_limit_weight: { input: wbLimitWeightInput, meta: wbLimitWeightMeta },
  } = get(props, parentField);

  const fieldsetLegend = useMemo(
    () => (formValues.position ? `Point #${formValues.position}` : 'New point'),
    [formValues.position]
  );

  const deleteMessage = useMemo(() => {
    let msg = 'Are you sure you want to delete this limit point?';
    if (formValues.id) {
      msg +=
        ' Note that while the information will be removed from the list, it will not be permanently deleted until you save this WB Limit.';
    }
    return msg;
  }, [formValues.id]);

  const handleDeleteClick = useCallback(() => {
    if (parentHandleDeleteClick) {
      parentHandleDeleteClick(index);
    }
  }, [index, parentHandleDeleteClick]);

  return (
    <fieldset className="border rounded-3 p-3">
      <legend className="float-none w-auto px-3 fs-6">{fieldsetLegend}</legend>
      <InputField
        input={positionInput}
        meta={positionMeta}
        type="text"
        size={size}
        labelWidth={labelWidth}
        inputWidth={inputWidth}
      >
        Position
      </InputField>
      <InputField
        input={wbLimitArmInput}
        meta={wbLimitArmMeta}
        type="text"
        size={size}
        labelWidth={labelWidth}
        inputWidth={inputWidth}
      >
        Arm
      </InputField>
      <InputField
        input={wbLimitWeightInput}
        meta={wbLimitWeightMeta}
        type="text"
        size={size}
        labelWidth={labelWidth}
        inputWidth={inputWidth}
      >
        Weight
      </InputField>
      <Row>
        <Col sm={{ span: inputWidth, offset: labelWidth }}>
          <Confirm
            onConfirm={handleDeleteClick}
            title="Delete Point"
            body={deleteMessage}
            confirmText="Confirm"
          >
            <Button type="button" variant="default" size={size}>
              <Glyphicon glyph="bin" />
            </Button>
          </Confirm>
        </Col>
      </Row>
    </fieldset>
  );
};

WbLimitFormWbLimitPointFields.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 3,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  noTab: true,
};

export default WbLimitFormWbLimitPointFields;
