import { Row, Col, Table } from 'react-bootstrap';
import { FieldArray } from 'redux-form';

import pick from 'lodash.pick';

import WeightBalanceSeatAssignmentFieldArray from './weight_balance_seat_assignment_field_array';

const WeightBalanceSeatAssignments = (props) => {
  const { field, formValues, isDeveloper } = props;

  const { seat_assignments_attributes: seatAssignmentsAttributes } = formValues;

  const renderSeatAssignmentsFieldArray = (seatAssignments, optional) => {
    const formKeys = ['seat_assignments_attributes'];
    return (
      <WeightBalanceSeatAssignmentFieldArray
        seatAssignments={seatAssignments}
        formValues={pick(formValues, formKeys)}
        optional={optional}
        isDeveloper={isDeveloper}
      />
    );
  };

  const renderOptionalSeatAssignmentsFieldArray = (seatAssignments) =>
    renderSeatAssignmentsFieldArray(seatAssignments, true);

  const renderIncludedSeatAssignmentsFieldArray = (seatAssignments) =>
    renderSeatAssignmentsFieldArray(seatAssignments, false);

  const hasOptional = () =>
    seatAssignmentsAttributes
      .filter((sa) => !sa._destroy)
      .some((sa) => sa.seat_assignment_installed_weight_increase > 0);

  const hasIncluded = () =>
    seatAssignmentsAttributes
      .filter((sa) => !sa._destroy)
      .some((sa) => sa.seat_assignment_installed_weight_increase === 0);

  return (
    <Row className="mt-3">
      <Col xs={10}>
        {hasIncluded() && (
          <>
            <h4>Included</h4>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th style={{ width: '200px' }}>Name</th>
                  {isDeveloper && <th>Weight Unit</th>}
                  <th>Weight</th>
                  <th>Seat Type</th>
                  <th>Pax Name</th>
                  <th>Pax Weight</th>
                  <th>Seat Installed</th>
                </tr>
              </thead>
              <FieldArray
                name={`${field}.seat_assignments_attributes`}
                component={renderIncludedSeatAssignmentsFieldArray}
              />
            </Table>
          </>
        )}
        {hasOptional() && (
          <>
            <h4>Optional</h4>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th style={{ width: '200px' }}>Name</th>
                  {isDeveloper && <th>Weight Unit</th>}
                  <th>Weight</th>
                  <th>Seat Type</th>
                  <th>Pax Name</th>
                  <th>Pax Weight</th>
                  <th>Seat Installed</th>
                </tr>
              </thead>
              <FieldArray
                name={`${field}.seat_assignments_attributes`}
                component={renderOptionalSeatAssignmentsFieldArray}
              />
            </Table>
          </>
        )}
      </Col>
    </Row>
  );
};

export default WeightBalanceSeatAssignments;
