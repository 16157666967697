import { Fields } from 'redux-form';

import get from 'lodash.get';
import last from 'lodash.last';

import WeightBalanceSeatAssignmentFields from './weight_balance_seat_assignment_fields';

const WeightBalanceSeatAssignmentFieldArray = (props) => {
  const { formValues, seatAssignments, optional, isDeveloper } = props;

  return (
    <tbody>
      {seatAssignments.fields.map((field) => {
        const seatAssignmentField = last(field.split('.'));
        const values = get(formValues, seatAssignmentField, {});
        const selected = optional
          ? values.seat_assignment_installed_weight_increase > 0
          : values.seat_assignment_installed_weight_increase <= 0;
        if (!values._destroy && selected) {
          const fields = [
            `${field}.seat_assignment_installed`,
            `${field}.seat_assignment_weight`,
            `${field}.passenger_id`,
            `${field}.pax_name`,
          ];
          return (
            <Fields
              key={field}
              parentField={field}
              names={fields}
              component={WeightBalanceSeatAssignmentFields}
              formValues={values}
              isDeveloper={isDeveloper}
            />
          );
        }
        return undefined;
      })}
    </tbody>
  );
};

export default WeightBalanceSeatAssignmentFieldArray;
