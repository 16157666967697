/* eslint-disable react/prefer-stateless-function, react/jsx-props-no-spreading */
import { Component } from 'react';

import get from 'lodash.get';

import InputField from '../form/input_field';
import CheckboxInputField from '../form/checkbox_input_field';

class HoldConfigurationFormAircraftHoldHoldConfigurationFields extends Component {
  render() {
    const { aircraftTypeId, aircraftTypes, parentField } = this.props;

    const {
      aircraft_hold_id: { input: aircraftHoldIdInput },
      hold_installed: { input: holdInstalledInput, meta: holdInstalledMeta },
      position: { input: positionInput, meta: positionMeta },
    } = get(this.props, parentField);

    const aircraftType = aircraftTypes.find(
      (at) => at.id === parseInt(aircraftTypeId, 10)
    );

    const { aircraftHolds } = aircraftType;
    const { name } = aircraftHolds.find(
      (as) => as.id === parseInt(aircraftHoldIdInput.value, 10)
    );
    const fieldsetLegend = name || 'New aircraft hold configuration';
    return (
      <fieldset className="border rounded-3 p-3">
        <legend className="float-none w-auto px-3 fs-6">{fieldsetLegend}</legend>
        <CheckboxInputField
          size={this.props.size}
          labelWidth={this.props.labelWidth}
          inputWidth={this.props.inputWidth}
          input={holdInstalledInput}
          meta={holdInstalledMeta}
        >
          Hold Installed
        </CheckboxInputField>
        <InputField
          input={positionInput}
          meta={positionMeta}
          type="text"
          size={this.props.size}
          labelWidth={this.props.labelWidth}
          inputWidth={this.props.inputWidth}
        >
          Position
        </InputField>
      </fieldset>
    );
  }
}

HoldConfigurationFormAircraftHoldHoldConfigurationFields.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 3,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  noTab: true,
};

export default HoldConfigurationFormAircraftHoldHoldConfigurationFields;
