/* eslint-disable react/jsx-props-no-spreading */
import { Row, Col, Button } from 'react-bootstrap';

import get from 'lodash.get';

import Confirm from '../confirm';
import InputField from '../form/input_field';

import Glyphicon from '../glyphicon';
import CheckboxInputField from '../form/checkbox_input_field';

const ContactFormPhoneNumberFields = (props) => {
  const {
    size,
    labelWidth,
    inputWidth,
    handleDeleteClick: parentHandleDeleteClick,
    handleDefaultClick: parentHandleDefaultClick,
    index,
    parentField,
    phoneNumberTypes,
  } = props;

  const {
    phone_number_type: { input: phoneNumberTypeInput, meta: phoneNumberTypeMeta },
    description: { input: descriptionInput, meta: descriptionMeta },
    country_code: { input: countryCodeInput, meta: countryCodeMeta },
    area_code: { input: areaCodeInput, meta: areaCodeMeta },
    phone_number: { input: phoneNumberInput, meta: phoneNumberMeta },
    default_phone_number: { input: defaultPhoneNumberInput },
    extension: { input: extensionInput, meta: extensionMeta },
  } = get(props, parentField);

  const handleDefaultClick = (e) => {
    if (parentHandleDefaultClick) {
      if (e.target.checked) {
        parentHandleDefaultClick(index);
      } else {
        parentHandleDefaultClick();
      }
    }
  };

  const handleDeleteClick = () => {
    if (parentHandleDeleteClick) {
      parentHandleDeleteClick(index);
    }
  };

  const renderDeleteMessage = () => {
    let msg = 'Are you sure you want to delete this phone number information?';
    if (get(props, 'formValues.id')) {
      msg +=
        ' Note that while the information will be removed from the list, it will not be permanently deleted until you save this Contact.';
    }
    return msg;
  };

  return (
    <fieldset className="border rounded-3 p-3">
      <Row>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          asElement="select"
          selectOptions={phoneNumberTypes}
          input={phoneNumberTypeInput}
          meta={phoneNumberTypeMeta}
        >
          Type
        </InputField>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={descriptionInput}
          meta={descriptionMeta}
        >
          Description
        </InputField>
        <CheckboxInputField
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          meta={phoneNumberMeta}
          input={defaultPhoneNumberInput}
          onChange={handleDefaultClick}
          className="mt-4"
        >
          Default
        </CheckboxInputField>
      </Row>
      <Row>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={countryCodeInput}
          meta={countryCodeMeta}
        >
          Ctry Code
        </InputField>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={areaCodeInput}
          meta={areaCodeMeta}
        >
          Area Code
        </InputField>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={phoneNumberInput}
          meta={phoneNumberMeta}
        >
          Phone Number
        </InputField>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={extensionInput}
          meta={extensionMeta}
        >
          Extension
        </InputField>
        <Col sm={{ span: 1, offset: 4 }}>
          <Confirm
            onConfirm={handleDeleteClick}
            title="Delete Phone Number"
            body={renderDeleteMessage()}
            confirmText="Confirm"
          >
            <Button type="button" variant="danger" size={size} className="mt-4">
              <Glyphicon glyph="bin" />
            </Button>
          </Confirm>
        </Col>
      </Row>
    </fieldset>
  );
};

ContactFormPhoneNumberFields.defaultProps = {
  size: 'sm',
  labelWidth: 0,
  inputWidth: 4,
};

export default ContactFormPhoneNumberFields;
