import { useCallback, useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Card, Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';

import { currentSettingsSet } from '../actions/current_setting_actions';
import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import ReactTable from '../components/react_table';

import { getExport } from '../lib/utils';
import { toastSuccess, toastError } from '../lib/action_helpers';
import locationDeleteMutation from '../mutations/location_delete_mutation';
import pageLocationListQuery from '../queries/page_location_list_query';

const LocationList = () => {
  const dispatch = useDispatch();
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const currentContact = useSelector((state) => state.currentContact);
  const [locationDelete] = useMutation(locationDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageLocationListQuery, { notifyOnNetworkStatusChange: true });

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
      enableColumnFilter: false,
    },
    {
      header: 'Short Name',
      accessorKey: 'short_name',
      filterFn: 'includesString',
      headerClassName: 'w-50',
    },
    {
      header: 'Long Name',
      accessorKey: 'long_name',
      filterFn: 'includesString',
      headerClassName: 'w-50',
    },
  ];

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleRefetch = () => pageRefetch();

  const handleDelete = useCallback(
    async (e) => {
      const locationId = e.currentTarget.getAttribute('data-id');
      const mutationData = {
        variables: { id: locationId },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await locationDelete(mutationData);
        toastSuccess('Location delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Location delete failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [locationDelete, dispatch]
  );

  const handleExport = useCallback(
    async (e) => {
      const reportName = e.target.getAttribute('data-report-name');
      const exportType = e.target.getAttribute('data-export-type');
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        // const args = {
        //   filters: this.props.filterIds, // Can i get this from the selected rows?
        // };
        // await getExport(reportName, pick(args, identity), null, exportType)
        await getExport(reportName, {}, null, exportType);
        toastSuccess('Location export ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Location export failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [dispatch]
  );

  const getTableTools = () => {
    if (currentContact['manager?'] || currentContact['office_admin?']) {
      return {
        handleDelete,
      };
    }
    return {};
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title list>Locations</Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                size="sm"
                variant="primary"
                onClick={handleRefetch}
                disabled={pageLoading}
              >
                Refresh
              </Button>
            </Col>
            <Col sm="auto">
              <ButtonGroup size="sm" className="me-2">
                <Button
                  variant="primary"
                  size="sm"
                  data-report-name="locations"
                  data-export-type="html"
                  onClick={handleExport}
                >
                  Print
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  data-report-name="locations"
                  data-export-type="pdf"
                  onClick={handleExport}
                >
                  PDF
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  data-report-name="locations"
                  data-export-type="csv"
                  onClick={handleExport}
                >
                  CSV
                </Button>
              </ButtonGroup>
            </Col>
            <Col sm="auto">
              <LinkContainer to="/locations/new">
                <Button size="sm" variant="primary">
                  Add a Location
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      {pageData?.locationList.length ? (
        <ReactTable
          rootName="location"
          parentColumns={parentColumns}
          data={pageData.locationList}
          initialStateSorting={[{ id: 'fullName', desc: false }]}
          {...getTableTools()}
        />
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>No locations</Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default LocationList;
