import makeValidator from './make_validator';

const aircraftConfigurationFormSchema = {
  type: 'object',
  required: [
    'name',
    'aircraft_type_id',
    'wb_longitudinal_limit_id',
    'wb_lateral_limit_id',
    'seat_configuration_id',
    'hold_configuration_id',
    'tank_configuration_id',
  ],
  properties: {},
};

export const aircraftConfigurationFormValidator = makeValidator(
  aircraftConfigurationFormSchema
);
