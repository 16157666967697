import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash.get';

import InputField from '../form/input_field';

class BookingPilotFlightNoteFields extends Component {
  UNSAFE_componentWillMount() {
    if (!get(this.props, 'formValues.pilot_flight_note_attributes.pilot_id')) {
      const input = get(this.props, 'pilot_flight_note_attributes.pilot_id.input');
      input.onChange(get(this.props, 'formValues.pilot_id', ''));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.pilot_id') !== get(nextProps, 'formValues.pilot_id')
    ) {
      const input = get(nextProps, 'pilot_flight_note_attributes.pilot_id.input');
      input.onChange(get(nextProps, 'formValues.pilot_id', ''));
    }
  }

  render() {
    const {
      formValues: { pilot_id: pilotId },
      pilot_flight_note_attributes: {
        notes: { input: notesInput, meta: notesMeta },
      },
    } = this.props;

    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Aircraft Notes</strong>
          </p>
        </Col>
        <Col sm={10}>
          {pilotId ? (
            <InputField
              blurOnly
              type="text"
              size="sm"
              labelWidth={2}
              inputWidth={10}
              input={notesInput}
              meta={notesMeta}
              asElement="textarea"
              rows={4}
            >
              Note
            </InputField>
          ) : (
            <p className="small">Select a pilot</p>
          )}
        </Col>
      </Row>
    );
  }
}

export default BookingPilotFlightNoteFields;
