import { useState, useEffect, useMemo, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import get from 'lodash.get';

import Field from '../../react_final_form_field';
import InputField from '../../input_field';

import Confirm from '../../../confirm';
import Glyphicon from '../../../glyphicon';
import FileTypeIcon from '../../../file_type_icon';

import { getFileExtension } from '../../../../lib/utils';

const DocumentFields = (props) => {
  const { document, arrayName, arrayIndex, remove, labelWidth, inputWidth, size } = props;
  const { id: documentId, document_file_name: documentFileName } = document;

  const [fileName, setFileName] = useState('');
  const [fileExtension, setFileExtension] = useState('');

  useEffect(() => {
    const newFileExtension = getFileExtension(documentFileName);
    setFileName(documentFileName || 'New document');
    setFileExtension(newFileExtension);
  }, [documentFileName]);

  const confirmBodyText = useMemo(
    () => (
      <>
        <p>Are you sure you want to delete this document?</p>
        {documentId && (
          <p>
            Note that while the document will be removed from the list, it will not be
            permanently deleted until you save this record.
          </p>
        )}
      </>
    ),
    [documentId]
  );

  const handleFileChange = useCallback((name, onChange, e) => {
    const file = get(e, 'target.files.0');
    if (file) {
      const newFileName = get(file, 'name');
      const newFileExtension = getFileExtension(newFileName);
      setFileName(newFileName || 'New document');
      setFileExtension(newFileExtension);
      onChange(file);
    }
  }, []);

  return (
    <Col className="mb-2" sm={{ span: inputWidth, offset: labelWidth }}>
      <fieldset className="border rounded-3 p-3">
        <legend className="float-none w-auto px-3 fs-6">{fileName}</legend>
        <Row>
          <Col sm={1}>
            <FileTypeIcon extension={fileExtension} size="x2" spacings="ms-4" />
          </Col>
          <Col sm={10}>
            <Field
              type="text"
              name={`${arrayName}.description`}
              labelWidth={3}
              inputWidth={9}
              size={size}
              component={InputField}
            >
              Description
            </Field>
          </Col>
          <Col sm={1}>
            <div>
              <Confirm
                onConfirm={() => remove(arrayIndex)}
                title="Delete Document"
                body={confirmBodyText}
                confirmText="Confirm"
              >
                <Button size={size} type="button" variant="dark" className="p-0">
                  <Glyphicon glyph="bin" />
                </Button>
              </Confirm>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 10, offset: 1 }}>
            <Field
              type="file"
              name={`${arrayName}.document`}
              labelWidth={3}
              inputWidth={9}
              size={size}
              component={InputField}
              customOnChange={handleFileChange}
            >
              File
            </Field>
          </Col>
        </Row>
      </fieldset>
    </Col>
  );
};

DocumentFields.defaultProps = {
  labelWidth: 3,
  inputWidth: 9,
  helpText: '',
  size: 'sm',
};

export default DocumentFields;
