import { useCallback, useMemo } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';

import cloneDeep from 'lodash.clonedeep';

import Field from '../components/form/react_final_form_field';
import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import FormButtons from '../components/form/form_buttons';
import InputField from '../components/form/input_field';
import ReactDateTimeField from '../components/form/react_date_time_field';

import { currentSettingsSet } from '../actions/current_setting_actions';
import { toastSuccess, toastError } from '../lib/action_helpers';
import { coerceInput, handleSubmitError } from '../lib/utils';

import pilotDutyRecordCreateMutation from '../mutations/pilot_duty_record_create_mutation';
import contactListQuery from '../queries/contact_list_query';

import { pilotDutyRecordFormValidator } from '../validators';
import { getPilotDutyRecordDefaultValues } from '../defaults';

const PilotDutyRecordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const currentContact = useSelector((state) => state.currentContact);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);

  const [pilotDutyRecordCreate] = useMutation(pilotDutyRecordCreateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(contactListQuery, {
    variables: {
      role: 'pilot',
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () =>
      !pageLoading ||
      (pageLoading &&
        [NetworkStatus.refetch, NetworkStatus.setVariables].includes(pageNetworkStatus)),
    [pageLoading, pageNetworkStatus]
  );

  const pilotSelectList = useMemo(() => {
    if (pageData?.data.length) {
      const pilots = pageData.data.map((p) => ({ id: p.id, name: p.fullName }));
      return pilots;
    }
    return [];
  }, [pageData]);

  const initialValues = useMemo(
    () => ({
      ...getPilotDutyRecordDefaultValues(), // dates
      ...(currentContact['pilot?'] && { pilot_id: currentContact.id }),
    }),
    [currentContact]
  );

  const isAdminOrOfficeAdmin = useMemo(() => {
    if (currentContact) {
      const { 'manager?': manager, 'office_admin?': officeAdmin } = currentContact;
      return manager || officeAdmin;
    }
    return false;
  }, [currentContact]);

  const onFormSubmit = useCallback(
    async (data) => {
      const mutation = pilotDutyRecordCreate;
      const mutationMessageAction = 'create';
      const submitData = cloneDeep(data);
      const mutationData = {
        variables: { input: coerceInput(submitData) },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await mutation(mutationData);
        toastSuccess(`Pilot duty record ${mutationMessageAction} succeeded`);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        navigate('/pilot_duty_records');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
      return undefined;
    },
    [pilotDutyRecordCreate, dispatch, navigate]
  );

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title form updating={!!params.id}>
            Pilot Duty Record
          </Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <LinkContainer to="/pilot_duty_records">
                <Button size="sm" variant="primary">
                  All Pilot Duty Records
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validate={pilotDutyRecordFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate onSubmit={handleSubmit}>
                <fieldset className="border rounded-3 p-3">
                  <legend className="float-none w-auto px-3 fs-6">
                    Pilot Duty Record Details
                  </legend>
                  <Row>
                    <Col>
                      <Field
                        asElement="select"
                        name="pilot_id"
                        labelWidth={3}
                        inputWidth={6}
                        component={InputField}
                        selectOptions={pilotSelectList}
                        disabled={!isAdminOrOfficeAdmin}
                      >
                        Pilot
                      </Field>
                    </Col>
                    <Col>
                      <Field
                        name="duty_start_at"
                        inputWidth={6}
                        component={ReactDateTimeField}
                        helpText="ddd Do MMMM YYYY HH:mm"
                        dateFormat="ddd Do MMMM YYYY"
                        timeFormat="HH:mm"
                        closeOnSelect
                        initialViewMode="time"
                      >
                        Duty Start At
                      </Field>
                      <Field
                        name="duty_end_at"
                        inputWidth={6}
                        component={ReactDateTimeField}
                        helpText="ddd Do MMMM YYYY HH:mm"
                        dateFormat="ddd Do MMMM YYYY"
                        timeFormat="HH:mm"
                        closeOnSelect
                        initialViewMode="time"
                      >
                        Duty End At
                      </Field>
                    </Col>
                  </Row>
                </fieldset>
                <Row>
                  <FormButtons
                    updating={!!params.id}
                    pristine={pristine}
                    submitting={submitting}
                    cancelLink="/pilot_duty_records"
                  />
                </Row>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PilotDutyRecordForm;
