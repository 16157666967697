import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import { Field } from 'redux-form';

import get from 'lodash.get';

import InputField from '../form/input_field';

class ChargeablePaxes extends Component {
  getChargeableLabel(index) {
    const chargeableId = get(
      this.props,
      ['formValues', 'booking_chargeables_attributes', index, 'chargeable_id'],
      ''
    );
    return get(
      this.props.contactsDataSelector,
      [chargeableId, 'fullName'],
      'Select a chargeable'
    );
  }

  renderBookingChargeablesList() {
    return (
      <>
        {this.props.bookingChargeables.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            return (
              <Field
                type="text"
                key={`booking-bc-pax-${field}`}
                parentField={field}
                name={`${field}.pax`}
                component={InputField}
                size="sm"
                labelWidth={2}
                inputWidth={2}
              >
                {this.getChargeableLabel(index)}
              </Field>
            );
          }
          return undefined;
        })}
      </>
    );
  }

  render() {
    const chargesLength = get(
      this.props,
      'formValues.booking_chargeables_attributes',
      []
    ).filter((bc) => !bc._destroy).length;
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Seat Allocation</strong>
          </p>
        </Col>
        {chargesLength === 0 && (
          <Col sm={10}>
            <p className="small">No chargeable selected</p>
          </Col>
        )}
        {chargesLength === 1 && (
          <Col sm={10}>
            <p className="small">Single chargeable - no seat allocation</p>
          </Col>
        )}
        {chargesLength > 1 && <Col sm={10}>{this.renderBookingChargeablesList()}</Col>}
      </Row>
    );
  }
}

export default ChargeablePaxes;
