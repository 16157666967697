import Spinner from 'react-spinkit';

function LoaderComponent({ wrapperStyle, noSpinner, fadeIn, name, color }) {
  const defaultWrapperStyle = {
    zIndex: 1000,
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(252,252,252,0.1)',
    width: '100%',
    height: '100%',
  };

  return (
    <div style={wrapperStyle || defaultWrapperStyle}>
      <div
        style={{
          position: 'relative',
          top: 'calc(50% - 16px)',
          left: 'calc(50% - 16px)',
          width: '32px',
          height: '32px',
        }}
      >
        {noSpinner || (
          <Spinner
            // fadeIn={fadeIn || 'half'}
            // fadeIn={fadeIn || 'none'}
            fadeIn={fadeIn || 'quarter'}
            name={name || 'circle'}
            color={color || '#bcbcbc'}
            style={{
              width: '32px',
              height: '32px',
            }}
          />
        )}
      </div>
    </div>
  );
}

export default LoaderComponent;
