import { Row, Form } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import {
  reduxForm,
  Field,
  FieldArray,
  change as changeFieldValue,
  getFormValues,
} from 'redux-form';

import clone from 'lodash.clone';
import cloneDeep from 'lodash.clonedeep';
import isNil from 'lodash.isnil';
import get from 'lodash.get';
import has from 'lodash.has';
import omit from 'lodash.omit';
import omitBy from 'lodash.omitby';
import pick from 'lodash.pick';

import TankConfigurationFormAircraftTankTankConfigurationFieldArray from '../components/tank_configuration_form/aircraft_tank_tank_configuration_field_array';
import InputField from '../components/form/input_field';
import Loader from '../components/loader';
import Title from '../components/title';
import FormButtons from '../components/form/form_buttons';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import tankConfigurationQuery from '../queries/tank_configuration_query';
import tankConfigurationUpdateMutation from '../mutations/tank_configuration_update_mutation';
import tankConfigurationCreateMutation from '../mutations/tank_configuration_create_mutation';

import { queriesReady, queryReady, coerceInput } from '../lib/utils';

let isInitialisedTankConfigurationForm = false;

class TankConfigurationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.renderAircraftTankTankConfigurationFieldArray =
      this.renderAircraftTankTankConfigurationFieldArray.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      has(this.props, 'formValues.aircraft_type_id') &&
      get(nextProps, 'formValues.aircraft_type_id') !==
        get(this.props, 'formValues.aircraft_type_id')
    ) {
      if (get(nextProps, 'formValues.aircraft_type_id')) {
        const aircraftType = this.props.aircraftTypeListQuery.data.find(
          (at) => at.id === parseInt(get(nextProps, 'formValues.aircraft_type_id'), 10)
        );
        const aircraftTankTankConfigurations = aircraftType.aircraftTanks.map(
          ({ id }) => ({
            aircraft_tank_id: id,
          })
        );
        this.props.changeFieldValue(
          this.props.form,
          'aircraftTankTankConfigurations',
          aircraftTankTankConfigurations
        );
      } else {
        this.props.changeFieldValue(
          this.props.form,
          'aircraftTankTankConfigurations',
          []
        );
      }
    }
  }

  componentWillUnmount() {
    isInitialisedTankConfigurationForm = false;
  }

  onSubmit(data) {
    this.props.mutationSet(true);
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: coerceInput(submitData) },
    };
    if (this.state.updating) {
      mutation = this.props.tankConfigurationUpdateMutation;
      mutationMessage = 'Tank Configuration update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.tankConfigurationCreateMutation;
      mutationMessage = 'Tank Configuration create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/tank_configurations');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.aircraftTypeListQuery, [
      testProps.tankConfigurationQuery,
      true,
    ]);
  }

  renderAircraftTankTankConfigurationFieldArray(aircraftTankTankConfigurations) {
    const formKeys = ['id', 'aircraft_type_id', 'aircraftTankTankConfigurations'];
    return (
      <TankConfigurationFormAircraftTankTankConfigurationFieldArray
        aircraftTankTankConfigurations={aircraftTankTankConfigurations}
        formValues={pick(this.props.formValues, formKeys)}
        aircraftTypes={this.props.aircraftTypeListQuery.data}
        change={this.props.change}
      />
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedTankConfigurationForm &&
      this.isLoaded() &&
      get(this.props, 'formValues') &&
      (!this.state.updating || get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting } = this.props;
      return (
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Title form updating={this.state.updating}>
            Tank Configuration
          </Title>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">
              Tank Configuration Details
            </legend>
            <Field
              type="text"
              name="name"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
            >
              Name
            </Field>
            <Field
              type="text"
              name="aircraft_type_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.props.aircraftTypeListQuery.data.map(
                ({ id, name }) => ({
                  id,
                  name,
                })
              )}
            >
              Aircraft Type
            </Field>
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Aircraft Tanks</legend>
            <FieldArray
              name="aircraftTankTankConfigurations"
              component={this.renderAircraftTankTankConfigurationFieldArray}
            />
          </fieldset>
          <Row>
            <FormButtons
              submitting={submitting}
              pristine={pristine}
              updating={this.state.updating}
              cancelLink="/tank_configurations"
            />
          </Row>
        </Form>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function validate() {
  return {};
}

const attributeBlackList = [
  '__typename',
  'aircraftType',
  'aircraftTankTankConfigurations',
];

function pickInitialValues(tankConfigurationQueryInitial, updating) {
  if (!isInitialisedTankConfigurationForm) {
    if (!updating) {
      isInitialisedTankConfigurationForm = true;
      return {
        aircraft_type_id: '',
        aircraftTankTankConfigurations: [],
      };
    }
    if (queryReady(tankConfigurationQueryInitial)) {
      isInitialisedTankConfigurationForm = true;
      const editData = omit(
        omitBy(cloneDeep(tankConfigurationQueryInitial.data), isNil),
        attributeBlackList
      );
      editData.aircraftTankTankConfigurations = clone(
        /* eslint-disable camelcase */
        get(tankConfigurationQueryInitial, 'data.aircraftTankTankConfigurations', [])
      )
        .sort((a, b) => a.position - b.position)
        .map(({ id, aircraft_tank_id, position }) => ({
          id,
          aircraft_tank_id,
          position,
        }));
      return editData;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(
    props.tankConfigurationQuery,
    !!props.params.id
  );
  return {
    initialValues,
    tagsCollection: state.tags.collection,
    currentSettingsMutating: state.currentSettings.mutating,
    formValues: getFormValues('TankConfigurationForm')(state),
  };
}

export default compose(
  graphql(tankConfigurationCreateMutation, {
    name: 'tankConfigurationCreateMutation',
  }),
  graphql(tankConfigurationUpdateMutation, {
    name: 'tankConfigurationUpdateMutation',
  }),
  graphql(aircraftTypeListQuery, {
    name: 'aircraftTypeListQuery',
  }),
  graphql(tankConfigurationQuery, {
    name: 'tankConfigurationQuery',
    skip: (props) => !props.params.id,
    // todo convert this bank once newstyle form initialization id done.
    // options: (props) => { return { variables: { id: props.params.id }, fetchPolicy: 'cache-and-network' } }
    options: (props) => ({ variables: { id: props.params.id } }),
  }),
  connect(mapStateToProps, {
    changeFieldValue,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'TankConfigurationForm',
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    validate,
  })
)(TankConfigurationForm);
