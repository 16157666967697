/* eslint-disable import/no-cycle  */
import { Navigate, useRoutes } from 'react-router-dom';
import compact from 'lodash.compact';
import first from 'lodash.first';

import Page404 from './components/page_404';

import Logout from './containers/logout';
import Login from './containers/login';
import AuthorizationList from './containers/authorization_list';
import BookingManager from './containers/booking_manager';
import FlightForm from './containers/flight_form';
import FlightSignoff from './containers/flight_signoff';
import CheckList from './containers/check_list';
import CheckForm from './containers/check_form';
import CheckShow from './containers/check_show';
import ContactList from './containers/contact_list';
import ContactForm from './containers/contact_form';
import ContactShow from './containers/contact_show';
import DocumentList from './containers/document_list';
import DocumentFormNew from './containers/document_form_new';
import FuelTankerList from './containers/fuel_tanker_list';
import FuelTankerShow from './containers/fuel_tanker_show';
import FuelTankerForm from './containers/fuel_tanker_form';
import FuelBowserFillForm from './containers/fuel_bowser_fill_form';
import LocationList from './containers/location_list';
import LocationForm from './containers/location_form';
import LocationShow from './containers/location_show';
import LocationMerge from './containers/location_merge';
import NonFlightForm from './containers/non_flight_form';
import ReportDataIntegrity from './containers/report_data_integrity';
import ReportAllocation from './containers/report_allocation';
import ReportAircraftFlightRecord from './containers/report_aircraft_flight_record';
import ReportAircraftFlightTimeEvent from './containers/report_aircraft_flight_time_event';
import ReportAircraftFlightTypeCategory from './containers/report_aircraft_flight_type_category';
import ReportChargeableFlightRecord from './containers/report_chargeable_flight_record';
import ReportProviderFlightRecord from './containers/report_provider_flight_record';
import ReportProviderFlightHour from './containers/report_provider_flight_hour';
import ReportChargeableInvoice from './containers/report_chargeable_invoice';
import ReportDutyEventSummary from './containers/report_duty_event_summary';
import ReportPilotDutyRecord from './containers/report_pilot_duty_record';
import ReportPilotFlightExpense from './containers/report_pilot_flight_expense';
import ReportPilotFlightLog from './containers/report_pilot_flight_log';
import ReportPilotCumulativeHour from './containers/report_pilot_cumulative_hour';
import ReportProviderSwapDetail from './containers/report_provider_swap_detail';
import ReportProviderSwapSummary from './containers/report_provider_swap_summary';
import ReportLocationLandingFee from './containers/report_location_landing_fee';
import ReportFuelTankerFillForAircraft from './containers/report_fuel_tanker_fill_for_aircraft';
import ReportFuelBowserFillForAircraft from './containers/report_fuel_bowser_fill_for_aircraft';
import ReportFuelBowserFillForFuelTanker from './containers/report_fuel_bowser_fill_for_fuel_tanker';
import RosterList from './containers/roster_list';
import WbLimitList from './containers/wb_limit_list';
import WbLimitForm from './containers/wb_limit_form';
import WbLimitShow from './containers/wb_limit_show';
import HoldConfigurationList from './containers/hold_configuration_list';
import HoldConfigurationForm from './containers/hold_configuration_form';
import HoldConfigurationShow from './containers/hold_configuration_show';
import SeatConfigurationList from './containers/seat_configuration_list';
import SeatConfigurationForm from './containers/seat_configuration_form';
import SeatConfigurationShow from './containers/seat_configuration_show';
import TankConfigurationList from './containers/tank_configuration_list';
import TankConfigurationForm from './containers/tank_configuration_form';
import TankConfigurationShow from './containers/tank_configuration_show';
import AircraftList from './containers/aircraft_list';
import AircraftForm from './containers/aircraft_form';
import AircraftConfigurationList from './containers/aircraft_configuration_list';
import AircraftConfigurationForm from './containers/aircraft_configuration_form';
import AircraftConfigurationShow from './containers/aircraft_configuration_show';
import PilotDutyRecordListList from './containers/pilot_duty_record_list';
import PilotDutyRecordForm from './containers/pilot_duty_record_form';

import AuthLayout from './layouts/auth_layout';
import AuthWrapper from './layouts/auth_wrapper';
import FixedAndScrollLayout from './layouts/fixed_and_scroll_layout';
import FluidAndScrollLayout from './layouts/fluid_and_scroll_layout';
import FluidAndNoScrollLayout from './layouts/fluid_and_no_scroll_layout';

const auth = (Component, skipAuthIsEmployee = false) => (
  <AuthWrapper skipAuthIsEmployee={skipAuthIsEmployee}>
    <Component />
  </AuthWrapper>
);

const restfulAuthRoutes = ({
  path,
  listElement,
  showElement,
  newElement,
  editElement,
  singleton = false,
  skipAuthIsEmployee = false,
}) => ({
  path,
  children: compact([
    newElement && {
      path: 'new',
      element: auth(newElement, skipAuthIsEmployee),
    },
    showElement && {
      path: singleton ? '' : ':id',
      element: auth(showElement, skipAuthIsEmployee),
    },
    editElement && {
      path: singleton ? 'edit' : ':id/edit',
      element: auth(editElement, skipAuthIsEmployee),
    },
    !singleton &&
      listElement && {
        path: '',
        element: auth(listElement, skipAuthIsEmployee),
      },
  ]),
});

const reportAuthRoutes = (path, element) => {
  const segments = path.split('/');
  const firstSegment = first(segments);
  return {
    path: firstSegment,
    children: [
      {
        path: '',
        element: auth(element),
      },
      ...(segments.length > 1
        ? segments.slice(1).map((segment, index) => ({
            path: segments.slice(1, index + 2).join('/'),
            element: auth(element),
          }))
        : []),
    ],
  };
};

const AppRoutes = () => {
  const routes = [
    {
      path: '/',
      element: <Navigate to="/checks" />,
    },
    {
      path: '/login',
      element: <Navigate to="/auth/login" />,
    },
    {
      path: '/logout',
      element: <Navigate to="/auth/logout" />,
    },
    {
      path: '/404',
      element: <Page404 />,
    },
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'logout',
          element: <Logout />,
        },
      ],
    },
    {
      element: <FluidAndNoScrollLayout />,
      children: [
        {
          path: '/bookings',
          element: auth(BookingManager, true),
        },
      ],
    },
    {
      element: <FluidAndScrollLayout />,
      children: [
        {
          path: '/rosters',
          element: auth(RosterList),
        },
        {
          path: '/authorizations',
          element: auth(AuthorizationList),
        },
        {
          path: '/reports',
          children: [
            reportAuthRoutes(
              'pilot_flight_log/:startAtDate/:pilotId',
              ReportPilotFlightLog
            ),
          ],
        },
      ],
    },
    {
      element: <FixedAndScrollLayout />,
      children: [
        restfulAuthRoutes({
          path: '/checks',
          listElement: CheckList,
          showElement: CheckShow,
          newElement: CheckForm,
          editElement: CheckForm,
        }),
        restfulAuthRoutes({
          path: '/contacts',
          listElement: ContactList,
          showElement: ContactShow,
          newElement: ContactForm,
          editElement: ContactForm,
        }),
        restfulAuthRoutes({
          path: '/documents',
          listElement: DocumentList,
          newElement: DocumentFormNew,
          editElement: DocumentFormNew,
        }),
        // restfulAuthRoutes({
        //   path: '/documents_new',
        //   newElement: DocumentFormNew,
        //   editElement: DocumentFormNew,
        // }),
        restfulAuthRoutes({
          path: '/flights',
          newElement: FlightForm,
          editElement: FlightForm,
          skipAuthIsEmployee: true,
        }),
        {
          path: '/flights/signoff',
          element: auth(FlightSignoff),
          children: [
            {
              path: ':startAtDate',
              element: auth(FlightSignoff),
              children: [
                {
                  path: ':aircraftId',
                  element: auth(FlightSignoff),
                },
              ],
            },
          ],
        },
        restfulAuthRoutes({
          path: '/nonflights',
          newElement: NonFlightForm,
          editElement: NonFlightForm,
          skipAuthIsEmployee: true,
        }),
        restfulAuthRoutes({
          path: '/fuel_tankers/:fuelTankerId/fuel_bowser_fill',
          newElement: FuelBowserFillForm,
          editElement: FuelBowserFillForm,
          skipAuthIsEmployee: true,
        }),
        restfulAuthRoutes({
          path: '/fuel_tankers',
          listElement: FuelTankerList,
          showElement: FuelTankerShow,
          newElement: FuelTankerForm,
          editElement: FuelTankerForm,
        }),
        restfulAuthRoutes({
          path: '/locations',
          listElement: LocationList,
          showElement: LocationShow,
          newElement: LocationForm,
          editElement: LocationForm,
        }),
        {
          path: '/locations/merge',
          element: auth(LocationMerge),
        },
        restfulAuthRoutes({
          path: '/wb_limits',
          listElement: WbLimitList,
          showElement: WbLimitShow,
          newElement: WbLimitForm,
          editElement: WbLimitForm,
        }),
        restfulAuthRoutes({
          path: '/hold_configurations',
          listElement: HoldConfigurationList,
          showElement: HoldConfigurationShow,
          newElement: HoldConfigurationForm,
          editElement: HoldConfigurationForm,
        }),
        restfulAuthRoutes({
          path: '/seat_configurations',
          listElement: SeatConfigurationList,
          showElement: SeatConfigurationShow,
          newElement: SeatConfigurationForm,
          editElement: SeatConfigurationForm,
        }),
        restfulAuthRoutes({
          path: '/tank_configurations',
          listElement: TankConfigurationList,
          showElement: TankConfigurationShow,
          newElement: TankConfigurationForm,
          editElement: TankConfigurationForm,
        }),
        restfulAuthRoutes({
          path: '/aircraft_configurations',
          listElement: AircraftConfigurationList,
          showElement: AircraftConfigurationShow,
          newElement: AircraftConfigurationForm,
          editElement: AircraftConfigurationForm,
        }),
        restfulAuthRoutes({
          path: '/pilot_duty_records',
          listElement: PilotDutyRecordListList,
          newElement: PilotDutyRecordForm,
        }),
        restfulAuthRoutes({
          path: '/aircrafts',
          listElement: AircraftList,
          // newElement: AircraftForm,
          editElement: AircraftForm,
        }),
        {
          path: '/reports',
          children: [
            {
              path: 'integrity',
              element: auth(ReportDataIntegrity),
            },
            reportAuthRoutes(
              'aircraft_flight_record/:startAtDate/:aircraftId',
              ReportAircraftFlightRecord
            ),
            reportAuthRoutes('allocation/:startAtDate/:aircraftId', ReportAllocation),
            reportAuthRoutes(
              'aircraft_flight_time_event/:startAtDate/:aircraftId',
              ReportAircraftFlightTimeEvent
            ),
            reportAuthRoutes(
              'aircraft_flight_type_category/:startAtDate/:aircraftId',
              ReportAircraftFlightTypeCategory
            ),
            reportAuthRoutes(
              'fuel_bowser_fill_for_aircraft/:startAtDate/:aircraftId',
              ReportFuelBowserFillForAircraft
            ),
            reportAuthRoutes(
              'chargeable_flight_record/:startAtDate/:chargeableId',
              ReportChargeableFlightRecord
            ),
            reportAuthRoutes(
              'chargeable_invoice/:startAtDate/:chargeableId',
              ReportChargeableInvoice
            ),
            reportAuthRoutes(
              'provider_flight_record/:startAtDate/:providerId',
              ReportProviderFlightRecord
            ),
            reportAuthRoutes(
              'provider_flight_hour/:startAtDate/:providerId',
              ReportProviderFlightHour
            ),
            reportAuthRoutes(
              'provider_swap_detail/:startAtDate/:providerId',
              ReportProviderSwapDetail
            ),
            reportAuthRoutes(
              'provider_swap_summary/:startAtDate',
              ReportProviderSwapSummary
            ),
            reportAuthRoutes(
              'pilot_duty_record/:startAtDate/:pilotId',
              ReportPilotDutyRecord
            ),
            reportAuthRoutes(
              'duty_event_summary/:startAtDate/:pilotId',
              ReportDutyEventSummary
            ),
            reportAuthRoutes(
              'pilot_flight_expense/:startAtDate/:pilotId',
              ReportPilotFlightExpense
            ),
            reportAuthRoutes(
              'pilot_cumulative_hour/:startAtDate/:pilotId',
              ReportPilotCumulativeHour
            ),
            reportAuthRoutes(
              'location_landing_fee/:startAtDate/:locationId',
              ReportLocationLandingFee
            ),
            reportAuthRoutes(
              'fuel_tanker_fill_for_aircraft/:startAtDate/:fuelTankerId',
              ReportFuelTankerFillForAircraft
            ),
            reportAuthRoutes(
              'fuel_bowser_fill_for_fuel_tanker/:startAtDate/:fuelTankerId',
              ReportFuelBowserFillForFuelTanker
            ),
          ],
        },
      ],
    },
    {
      path: '/*',
      element: <Navigate to="/404" />,
    },
  ];
  return useRoutes(routes);
};

export default AppRoutes;
