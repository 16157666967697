import { useCallback, useMemo, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';

import cloneDeep from 'lodash.clonedeep';
import forEach from 'lodash.foreach';
import get from 'lodash.get';

import Field from '../components/form/react_final_form_field';
import OnChangeField from '../components/form/rff_on_change_field';

import { currentSettingsSet } from '../actions/current_setting_actions';
import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import FormButtons from '../components/form/form_buttons';
import InputField from '../components/form/input_field';
import ReactDateTimeField from '../components/form/react_date_time_field';
import DocumentFieldArray from '../components/form/check_form/documents/document_field_array';

import { toastSuccess, toastError } from '../lib/action_helpers';
import {
  coerceInput,
  pickValues,
  mapPickValues,
  handleSubmitError,
  getSelectable,
} from '../lib/utils';
import checkCreateMutation from '../mutations/check_create_mutation';
import checkUpdateMutation from '../mutations/check_update_mutation';
import pageCheckFormQuery from '../queries/page_check_form_query';
import { checkFormValidator } from '../validators';
import { getCheckDefaultValues } from '../defaults';
import { checkWhiteList, documentWhiteList } from '../white_lists';

const CheckForm = () => {
  const dispatch = useDispatch();
  const [checkCategory, setCheckCategory] = useState({});
  const [checkType, setCheckType] = useState({});
  const [checkableId, setCheckableId] = useState('');
  const currentContact = useSelector((state) => state.currentContact);
  const currentTenant = useSelector((state) => state.currentTenant);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const currentSettingsCheckCategoryCheckableGroups = useSelector(
    (state) => state.currentSettings.check_category_checkable_groups
  );
  const [checkUpdate] = useMutation(checkUpdateMutation);
  const [checkCreate] = useMutation(checkCreateMutation);
  const navigate = useNavigate();
  const params = useParams();

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageCheckFormQuery, {
    variables: {
      hasCheckId: !!params.id,
      checkId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const check = get(data, 'check', {});
      if (check.id) {
        setCheckCategory(
          data.checkCategoryList.find((cc) => cc.id === check.check_category_id)
        );
        setCheckType(data.checkTypeList.find((cc) => cc.id === check.check_type_id));
        setCheckableId(check.checkable_id);
      }
    },
  });

  const pageLoadedOrRefetching = useMemo(
    () =>
      !pageLoading ||
      (pageLoading &&
        [NetworkStatus.refetch, NetworkStatus.setVariables].includes(pageNetworkStatus)),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.check) {
      const values = pickValues(pageData.check, checkWhiteList);
      values.documents = mapPickValues(
        get(pageData, 'check.documents', []),
        documentWhiteList
      );
      return values;
    }
    return getCheckDefaultValues();
  }, [pageData]);

  const checkableOptions = useMemo(() => {
    if (checkCategory.id) {
      const { checkable_group: checkableGroup } = checkCategory;
      if (checkableGroup === 'pilots') {
        return getSelectable(get(pageData, 'pilotList', []), checkableId, 'fullName');
      }
      if (checkableGroup === 'employees') {
        return getSelectable(get(pageData, 'employeeList', []), checkableId, 'fullName');
      }
      if (checkableGroup === 'passengers') {
        return getSelectable(get(pageData, 'passengerList', []), checkableId, 'fullName');
      }
      if (checkableGroup === 'equipment') {
        return pageData.otherAssetList.map(({ id, name }) => ({
          id,
          name,
        }));
      }
      if (checkableGroup === 'tenant') {
        const { id, fullName } = currentTenant;
        return [{ id, name: fullName }];
      }
      return [];
    }
    return [];
  }, [checkCategory, checkableId, pageData, currentTenant]);

  const onCheckCategoryChange = useCallback(
    (form, value, previous) => {
      if (pageData.checkCategoryList && value !== previous) {
        const newCheckCategoryId = parseInt(value, 10) || '';
        let newCheckCategory = {};
        let newCheckableType = '';
        if (newCheckCategoryId) {
          newCheckCategory = pageData.checkCategoryList.find(
            (cc) => cc.id === newCheckCategoryId
          );
          newCheckableType =
            currentSettingsCheckCategoryCheckableGroups[
              get(newCheckCategory, 'checkable_group')
            ];
        }
        setCheckCategory(newCheckCategory);
        form.batch(() => {
          form.change('check_type_id', '');
          form.mutators.setFieldTouched('check_type_id', true);
          form.change('checkable_id', '');
          form.mutators.setFieldTouched('checkable_id', true);
          form.change('checkable_type', newCheckableType);
        });
      }
    },
    [pageData, setCheckCategory, currentSettingsCheckCategoryCheckableGroups]
  );

  const onCheckTypeChange = useCallback(
    (form, value, previous) => {
      if (pageData.checkTypeList && value !== previous) {
        const newCheckTypeId = parseInt(value, 10) || '';
        let newCheckType = {};
        if (newCheckTypeId) {
          newCheckType = pageData.checkTypeList.find((ct) => ct.id === newCheckTypeId);
        }
        setCheckType(newCheckType);
      }
    },
    [pageData, setCheckType]
  );

  const onCheckableChange = useCallback(
    (form, value, previous) => {
      if (value !== previous) {
        setCheckableId(parseInt(value, 10) || '');
      }
    },
    [setCheckableId]
  );

  const onFormSubmit = useCallback(
    async (data) => {
      let mutation;
      let mutationMessageAction;
      const submitData = cloneDeep(data);
      forEach(submitData.documents, (d) => {
        if (d.document) {
          // eslint-disable-next-line no-param-reassign
          d.uploader_id = currentContact.id;
          // eslint-disable-next-line no-param-reassign
          d.uploaded_at = moment().format();
        }
      });
      submitData.check_start_on = submitData.check_end_on;
      if (checkType.lead_period_days) {
        submitData.check_start_on = moment(submitData.check_end_on, 'YYYY-MM-DD')
          .subtract(checkType.lead_period_days, 'days')
          .format('YYYY-MM-DD');
      }
      const mutationData = {
        context: { hasUpload: true },
        variables: { input: coerceInput(submitData) },
      };
      if (params.id) {
        mutationData.variables.id = params.id;
        mutation = checkUpdate;
        mutationMessageAction = 'update';
      } else {
        mutation = checkCreate;
        mutationMessageAction = 'create';
      }
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await mutation(mutationData);
        toastSuccess(`Check ${mutationMessageAction} succeeded`);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        navigate('/checks');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
      return undefined;
    },
    [params.id, currentContact, checkType, checkCreate, checkUpdate, dispatch, navigate]
  );

  // handleCompleteSubmit(data) {
  //   const submitData = cloneDeep(data);
  //   const today = new Date();
  //   submitData.check_complete_on = today.toISOString().substring(0, 10);
  //   this.handleSubmit(submitData);
  // }

  // handleRolloverSubmit(data) {
  //   const submitData = cloneDeep(data);
  //   submitData.rollover = true;
  //   this.handleCompleteSubmit(submitData);
  // }

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title form updating={!!params.id}>
            Check
          </Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <LinkContainer to="/checks">
                <Button size="sm" variant="primary">
                  All Checks
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validate={checkFormValidator}
            mutators={{ setFieldTouched, ...arrayMutators }}
          >
            {({ handleSubmit, pristine, submitting, form, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <OnChangeField name="check_category_id">
                  {(value, previous) => onCheckCategoryChange(form, value, previous)}
                </OnChangeField>
                <OnChangeField name="check_type_id">
                  {(value, previous) => onCheckTypeChange(form, value, previous)}
                </OnChangeField>
                <OnChangeField name="checkable_id">
                  {(value, previous) => onCheckableChange(form, value, previous)}
                </OnChangeField>
                <fieldset className="border rounded-3 p-3">
                  <legend className="float-none w-auto px-3 fs-6">Check Details</legend>
                  <Field
                    asElement="select"
                    name="check_category_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    selectOptions={pageData.checkCategoryList.map(({ id, name }) => ({
                      id,
                      name,
                    }))}
                  >
                    Check Category
                  </Field>
                  <Field
                    asElement="select"
                    name="check_type_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    selectOptions={pageData.checkTypeList
                      .filter((ct) => ct.check_category_id === checkCategory.id)
                      .map(({ id, name }) => ({
                        id,
                        name,
                      }))}
                  >
                    Check Type
                  </Field>
                  <Field
                    asElement="select"
                    name="checkable_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    selectOptions={checkableOptions}
                  >
                    Checkable
                  </Field>
                  <Field
                    asElement="select"
                    name="manager_id"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    selectOptions={getSelectable(
                      get(pageData, 'managerList', []),
                      values.manager_id,
                      'fullName'
                    )}
                  >
                    Responsible Manager
                  </Field>
                  <Field
                    name="check_end_on"
                    inputWidth={3}
                    component={ReactDateTimeField}
                    helpText="DD/MM/YYYY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    closeOnSelect
                  >
                    Check Due On
                  </Field>
                  {!!params.id && (
                    <>
                      <InputField
                        type="text"
                        plainText
                        labelWidth={3}
                        inputWidth={3}
                        input={{
                          name: 'critical',
                          // eslint-disable-next-line
                          value: checkType
                            ? get(checkType, 'critical')
                              ? 'Yes'
                              : 'No'
                            : '-',
                        }}
                      >
                        Critical
                      </InputField>
                      <InputField
                        type="text"
                        plainText
                        labelWidth={3}
                        inputWidth={3}
                        input={{
                          name: 'critical',
                          value: get(checkType, 'lead_period_days', '-'),
                        }}
                      >
                        Lead Period Days
                      </InputField>
                    </>
                  )}
                </fieldset>
                <fieldset className="border rounded-3 p-3">
                  <legend className="float-none w-auto px-3 fs-6">Documents</legend>
                  <DocumentFieldArray check={pageData.check} />
                </fieldset>
                <Row>
                  <FormButtons
                    updating={!!params.id}
                    pristine={pristine}
                    submitting={submitting}
                    cancelLink="/checks"
                  />
                </Row>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default CheckForm;
