import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { Row, Col, Table, Form, Button } from 'react-bootstrap';

import get from 'lodash.get';
import debounce from 'lodash.debounce';
import flattenDeep from 'lodash.flattendeep';
import omit from 'lodash.omit';
import uniq from 'lodash.uniq';

import aircraftListQuery from '../queries/aircraft_list_query';
import flightTypeCategoryListQuery from '../queries/flight_type_category_list_query';
import hobbRecordQuarterlyByFlightTypeCategoryQuery from '../queries/hobb_record_quarterly_by_flight_type_category_query';

import { currentSettingsSet } from '../actions/current_setting_actions';
import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import { flightTypeCategoriesData } from '../selectors';
import { queriesReady, getExport } from '../lib/utils';

import Loader from '../components/loader';
import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

const endOfQuarter = moment().endOf('quarter');

class ReportAircraftFlightTypeCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startAtDate: moment(this.props.currentSettingsReportStart).format('MM/YYYY'),
      startAtDateError: {},
      filterAircraftId: this.props.currentSettingsReportAircraftId,
      filterAircraftIds: [],
    };
    this._handleStartAtDateChange = this._handleStartAtDateChange.bind(this);
    this._handleDisplayAircraftIdChange = this._handleDisplayAircraftIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this._handleStartAtDateChange(date);
    }
    if (this.props.params.aircraftId) {
      this._handleDisplayAircraftIdChange({
        target: { value: parseInt(this.props.params.aircraftId) },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.hobbRecordQuarterlyByFlightTypeCategoryQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterAircraftIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterAircraftIds = nextProps.hobbRecordQuarterlyByFlightTypeCategoryQuery.data.map(
        (data) => data.aircraft_id
      );
    }
    this.setState({
      startAtDate: moment(nextProps.currentSettingsReportStart).format('MM/YYYY'),
      startAtDateError: {},
      filterAircraftIds,
    });
    let filterAircraftId = nextProps.currentSettingsReportAircraftId;
    if (filterAircraftId && this.isLoaded(nextProps)) {
      if (
        filterAircraftIds.length > 0 &&
        filterAircraftIds.indexOf(filterAircraftId) === -1
      ) {
        filterAircraftId = '';
      }
    }
    if (filterAircraftId !== this.props.currentSettingsReportAircraftId) {
      this._handleDisplayAircraftIdChange({ target: { value: filterAircraftId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.hobbRecordQuarterlyByFlightTypeCategoryQuery,
      props.flightTypeCategoryListQuery,
      props.aircraftListQuery
    );
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      aircraftId: this.props.currentSettingsReportAircraftId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  _handleDisplayAircraftIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterAircraftId: value,
    });
    this.props.currentSettingsSet({
      reportAircraftId: value,
    });
  }

  _handleStartAtDateChange(date) {
    if (this.isLoaded()) {
      if (moment.isMoment(date)) {
        this.setState({
          startAtDate: date.format('MM/YYYY'),
          startAtDateError: {},
        });
        this.props.currentSettingsSet({
          reportStart: date.clone().startOf('month').toISOString(),
        });
      } else {
        this.setState({
          startAtDateError: { touched: true, invalid: true, error: 'MM/YYYY' },
        });
      }
    }
  }

  _isValidDate(current) {
    return (
      current.isBefore(endOfQuarter) &&
      ['Mar', 'Jun', 'Sep', 'Dec'].indexOf(current.format('MMM')) > -1
    );
  }

  _getFlightTypeCategoryName(id) {
    return get(this.props.flightTypeCategoriesDataSelector, [id, 'name']);
  }

  _renderChildRow(
    grandparent_category,
    grandparent_index,
    parent_category,
    parent_index,
    child_category,
    child_index,
    grandchild_category,
    grandchild_index
  ) {
    const categories = this.props.flightTypeCategoryListQuery.data;
    const grandparentName = this._getFlightTypeCategoryName(
      grandparent_category.flight_type_category_id
    );
    const parentName = this._getFlightTypeCategoryName(
      parent_category.flight_type_category_id
    );
    const childName = this._getFlightTypeCategoryName(
      child_category.flight_type_category_id
    );
    const grandchildName = this._getFlightTypeCategoryName(
      grandchild_category.flight_type_category_id
    );

    return (
      <tr
        key={[grandparent_index, parent_index, child_index, grandchild_index].join('-')}
      >
        <td
          className="text-start"
          style={{
            width: '18%',
            borderTopColor:
              grandchild_index === 0 && child_index === 0 && parent_index === 0
                ? 'rgb(221, 221, 221'
                : 'rgb(252,252,252)',
          }}
        >
          {parent_index === 0 && child_index === 0 && grandchild_index === 0
            ? grandparentName || '-'
            : ''}
        </td>
        <td
          className="text-start"
          style={{
            width: '18%',
            borderTopColor:
              child_index === 0 && grandchild_index === 0
                ? 'rgb(221, 221, 221'
                : 'rgb(252,252,252)',
          }}
        >
          {child_index === 0 && grandchild_index === 0 ? parentName || '-' : ''}
        </td>
        <td
          className="text-start"
          style={{
            width: '18%',
            borderTopColor:
              grandchild_index === 0 ? 'rgb(221, 221, 221' : 'rgb(252,252,252',
          }}
        >
          {grandchild_index === 0 ? childName || '-' : ''}
        </td>
        <td className="text-start" style={{ width: '18%' }}>
          {grandchildName || '-'}
        </td>
        <td className="text-end" style={{ width: '14%%' }}>
          {grandchild_category.total}
        </td>
        <td className="text-end" style={{ width: '14%%' }}>
          {grandchild_category.count}
        </td>
      </tr>
    );
  }

  _renderAircraftFlightTypeCategories(grandparent_categories) {
    const rows = [...grandparent_categories]
      .sort((a, b) => {
        const a_position = this.props.flightTypeCategoryListQuery.data.find(
          (model) => model.id === a.flight_type_category_id
        ).position;
        const b_position = this.props.flightTypeCategoryListQuery.data.find(
          (model) => model.id === b.flight_type_category_id
        ).position;
        return a_position - b_position;
      })
      .map((grandparent_category, grandparent_index) => {
        if (
          grandparent_category.flight_type_categories &&
          grandparent_category.flight_type_categories.length > 0
        ) {
          return [...grandparent_category.flight_type_categories]
            .sort((a, b) => {
              const a_position = this.props.flightTypeCategoryListQuery.data.find(
                (model) => model.id === a.flight_type_category_id
              ).position;
              const b_position = this.props.flightTypeCategoryListQuery.data.find(
                (model) => model.id === b.flight_type_category_id
              ).position;
              return a_position - b_position;
            })
            .map((parent_category, parent_index) => {
              if (
                parent_category.flight_type_categories &&
                parent_category.flight_type_categories.length > 0
              ) {
                return [...parent_category.flight_type_categories]
                  .sort((a, b) => {
                    const a_position = this.props.flightTypeCategoryListQuery.data.find(
                      (model) => model.id === a.flight_type_category_id
                    ).position;
                    const b_position = this.props.flightTypeCategoryListQuery.data.find(
                      (model) => model.id === b.flight_type_category_id
                    ).position;
                    return a_position - b_position;
                  })
                  .map((child_category, child_index) => {
                    if (
                      child_category.flight_type_categories &&
                      child_category.flight_type_categories.length > 0
                    ) {
                      return [...child_category.flight_type_categories]
                        .sort((a, b) => {
                          const a_position =
                            this.props.flightTypeCategoryListQuery.data.find(
                              (model) => model.id === a.flight_type_category_id
                            ).position;
                          const b_position =
                            this.props.flightTypeCategoryListQuery.data.find(
                              (model) => model.id === b.flight_type_category_id
                            ).position;
                          return a_position - b_position;
                        })
                        .map((grandchild_category, grandchild_index) =>
                          this._renderChildRow(
                            grandparent_category,
                            grandparent_index,
                            parent_category,
                            parent_index,
                            child_category,
                            child_index,
                            grandchild_category,
                            grandchild_index
                          )
                        );
                    } else {
                      return this._renderChildRow(
                        grandparent_category,
                        grandparent_index,
                        parent_category,
                        parent_index,
                        child_category,
                        child_index,
                        omit(child_category, 'flight_type_category_id'),
                        0
                      );
                    }
                  });
              } else {
                return this._renderChildRow(
                  grandparent_category,
                  grandparent_index,
                  parent_category,
                  parent_index,
                  omit(parent_category, 'flight_type_category_id'),
                  0,
                  omit(parent_category, 'flight_type_category_id'),
                  0
                );
              }
            });
        } else {
          return this._renderChildRow(
            grandparent_category,
            grandparent_index,
            omit(grandparent_category, 'flight_type_category_id'),
            0,
            omit(grandparent_category, 'flight_type_category_id'),
            0,
            omit(grandparent_category, 'flight_type_category_id'),
            0
          );
        }
      });
    return <tbody>{flattenDeep(rows)}</tbody>;
  }

  _renderHeaderRow(index) {
    return (
      <thead>
        <tr>
          <th colSpan={4} className="text-end border-0">
            &nbsp;
          </th>
          <th className="text-end border-0">Hours Flown</th>
          <th className="text-end border-0">No. of Flights</th>
        </tr>
      </thead>
    );
  }

  _renderFooterRow(aircraft_registration_full, total, count) {
    return (
      <tfoot>
        <tr>
          <th
            colSpan={4}
            className="text-end"
          >{`Totals for ${aircraft_registration_full}`}</th>
          <th className="text-end">{total}</th>
          <th className="text-end">{count}</th>
        </tr>
      </tfoot>
    );
  }

  _renderAircrafts(bookings) {
    return bookings
      .filter((data) => {
        if (
          this.state.filterAircraftId &&
          data.aircraft_id !== this.state.filterAircraftId
        ) {
          return false;
        }
        return true;
      })
      .map((data, index) => {
        const aircraft = this.props.aircraftListQuery.data.find(
          (model) => model.id === data.aircraft_id
        );
        const aircraft_registration_full = aircraft.registration_full;
        return (
          <Row key={data.aircraft_id} className="mb-4" xs={1}>
            <Col>
              <h4>{aircraft_registration_full}</h4>
            </Col>
            <Col>
              <Table size="sm">
                {this._renderHeaderRow()}
                {this._renderAircraftFlightTypeCategories(data.flight_type_categories)}
                {this._renderFooterRow(
                  aircraft_registration_full,
                  data.total,
                  data.count
                )}
              </Table>
            </Col>
          </Row>
        );
      });
  }

  _renderTotalRow(bookings, aoc_number) {
    const totals = bookings
      .filter((data) => {
        if (
          this.state.filterAircraftId &&
          data.aircraft_id !== this.state.filterAircraftId
        ) {
          return false;
        }
        return true;
      })
      .map((data) => ({ count: data.count, total: data.total }));
    return (
      <Row className="mb-4">
        <Col>
          <Table size="sm">
            <tfoot>
              <tr>
                <th
                  colSpan={4}
                  className="text-end"
                >{`Totals for AOC Number ${aoc_number}`}</th>
                <th className="text-end" style={{ width: '15%' }}>
                  {totals.reduce((sum, value) => sum + value.total, 0).toFixed(2)}
                </th>
                <th className="text-end" style={{ width: '15%' }}>
                  {totals.reduce((sum, value) => sum + value.count, 0)}
                </th>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    );
  }

  _renderAocNumbers() {
    const aoc_numbers = uniq(
      this.props.hobbRecordQuarterlyByFlightTypeCategoryQuery.data.map(
        (b) => b.aoc_number
      )
    );
    return aoc_numbers.map((aoc_number) => {
      const bookings =
        this.props.hobbRecordQuarterlyByFlightTypeCategoryQuery.data.filter(
          (b) => b.aoc_number === aoc_number
        );
      // const {swap_group_name} = first(bookings)
      return (
        <>
          <Row key={aoc_number}>
            <Col>
              <h4>{`AOC Number - ${aoc_number}`}</h4>
              <hr className="mt-0" />
            </Col>
          </Row>
          {this._renderAircrafts(bookings)}
          {this._renderTotalRow(bookings, aoc_number)}
        </>
      );
    });
  }

  _renderHeader() {
    return `Aircraft Operating Statistics ${moment(
      this.props.currentSettingsReportStart
    ).format('YYYY')} Q${moment(this.props.currentSettingsReportStart).format(
      'Q'
    )} ${moment(this.props.currentSettingsReportStart)
      .startOf('quarter')
      .format('MMM')} - ${moment(this.props.currentSettingsReportStart)
      .endOf('quarter')
      .format('MMM')}`;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <>
        <Row className="my-3">
          <Col className="d-flex justify-content-between align-items-start">
            <h3>{this._renderHeader()}</h3>
            <Button
              variant="primary"
              data-report-name="hobb_record_quarterly_by_flight_type_category"
              onClick={this.getExport}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
            locked
          />
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'filterAircraftId',
              value: this.state.filterAircraftId,
              onChange: this._handleDisplayAircraftIdChange,
            }}
            asElement="select"
            selectOptions={this.props.aircraftListQuery.data.filter(
              (model) => this.state.filterAircraftIds.indexOf(model.id) > -1
            )}
            optionKey="registration_abbreviated"
            defaultSelectOptionName="All"
          />
        </Row>
        {this._renderAocNumbers()}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentSettingsReportAircraftId: state.currentSettings.reportAircraftId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    flightTypeCategoriesDataSelector: flightTypeCategoriesData(props),
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationFailure,
    mutationSet,
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(flightTypeCategoryListQuery, {
    name: 'flightTypeCategoryListQuery',
  }),
  graphql(hobbRecordQuarterlyByFlightTypeCategoryQuery, {
    name: 'hobbRecordQuarterlyByFlightTypeCategoryQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportAircraftFlightTypeCategory);
