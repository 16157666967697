import { Row, Col } from 'react-bootstrap';
import { renderToStaticMarkup } from 'react-dom/server';
import { Popover } from 'bootstrap';

import { renderMultlilineText } from '../../lib/utils';

const renderFlight = (flightSegmentSummaries) => (
  <Row>
    <Col sm={12} className="small">
      {flightSegmentSummaries.map((flightSegmentSummary) => {
        const {
          id,
          start_at: startAt,
          start_location: startLocation,
          end_location: endLocation,
          pax,
        } = flightSegmentSummary;
        const startCritical = startAt.includes('*');
        return (
          <Row key={id}>
            <Col sm="auto" className="m-0 px-1 mb-1">
              <span {...(startCritical && { className: 'bg-yellow-important' })}>
                {startAt.replace('*', '')}
              </span>
            </Col>
            <Col className="m-0 px-1">{`${startLocation} - ${endLocation} ${
              pax ? ` |  ${pax}` : ''
            }`}</Col>
          </Row>
        );
      })}
    </Col>
  </Row>
);

const renderNonFlight = (title, jobNotes) => (
  <>
    <p>{title}</p>
    {jobNotes && <p>{renderMultlilineText(jobNotes)}</p>}
  </>
);

const renderEventPopover = (info, BOOKING_VIEW_POPOVERS_HTML_ID) => {
  const {
    event: {
      title,
      extendedProps: {
        skipPopover,
        eventType,
        isFlight,
        chargeableFullNames,
        employeeFullNames,
        contactFullName,
        jobNotes,
        flightSegmentSummaries,
      },
    },
    el,
  } = info;

  if (eventType === 'Solar') {
    return false;
  }

  if (skipPopover) {
    return false;
  }

  const content = isFlight
    ? renderFlight(flightSegmentSummaries)
    : renderNonFlight(title, jobNotes);

  const popOver = new Popover(el, {
    html: true,
    title: isFlight ? chargeableFullNames : employeeFullNames || contactFullName || title,
    placement: 'bottom',
    content: renderToStaticMarkup(content),
    trigger: 'hover',
    container: `#${BOOKING_VIEW_POPOVERS_HTML_ID}`,
  });

  return popOver;
};

export default renderEventPopover;
