import { Button } from 'react-bootstrap';
import { useEffect } from 'react';

import get from 'lodash.get';
import clone from 'lodash.clone';

import InputField from '../form/input_field';
import CheckboxInputField from '../form/checkbox_input_field';

const PilotFlightLog = (props) => {
  const {
    formValues,
    index,
    parentField,
    contactsDataSelector,
    handleDelete,
    currentSettingsPilotFlightLogFlightTypes,
    flightTime,
    prevFlightTime,
    flightPilotId,
    flightCopilotId,
    prevFlightPilotId,
    prevFlightCopilotId,
  } = props;

  const {
    id,
    pilot_id: logPilotId,
    flight_types: flightTypes = [],
    pilot_in_charge_day: pilotInChargeDay,
    pilot_in_charge_night: pilotInChargeNight,
    copilot_day: copilotDay,
    copilot_night: copilotNight,
  } = formValues;

  const {
    _destroy: { input: destroyInput },
    pilot_id: { input: pilotIdInput },
    flight_types: { input: flightTypesInput },
    multiengine: { input: multiengineInput, meta: multiengineMeta },
    command_practice_day: {
      input: commandPracticeDayInput,
      meta: commandPracticeDayMeta,
    },
    command_practice_night: {
      input: commandPracticeNightInput,
      meta: commandPracticeNightMeta,
    },
    dual_day: { input: dualDayInput, meta: dualDayMeta },
    dual_night: { input: dualNightInput, meta: dualNightMeta },
    copilot_day: { input: copilotDayInput, meta: copilotDayMeta },
    copilot_night: { input: copilotNightInput, meta: copilotNightMeta },
    pilot_in_charge_day: { input: pilotInChargeDayInput, meta: pilotInChargeDayMeta },
    pilot_in_charge_night: {
      input: pilotInChargeNightInput,
      meta: pilotInChargeNightMeta,
    },
  } = get(props, parentField);

  useEffect(() => {
    if (prevFlightTime && flightTime && prevFlightTime !== flightTime && flightTime > 0) {
      if (pilotInChargeDay) {
        pilotInChargeDayInput.onChange(flightTime);
      }
      if (copilotDay) {
        copilotDayInput.onChange(flightTime);
      }
    }
  }, [
    prevFlightTime,
    flightTime,
    pilotInChargeDay,
    copilotDay,
    pilotInChargeDayInput,
    copilotDayInput,
  ]);

  useEffect(() => {
    if (prevFlightPilotId !== flightPilotId || prevFlightCopilotId !== flightCopilotId) {
      if (logPilotId) {
        if (prevFlightPilotId !== flightPilotId && prevFlightPilotId === logPilotId) {
          pilotIdInput.onChange(flightPilotId);
        } else if (
          prevFlightCopilotId !== flightCopilotId &&
          prevFlightCopilotId === logPilotId
        ) {
          pilotIdInput.onChange(flightCopilotId);
        }
      } else if (flightPilotId && (pilotInChargeDay || pilotInChargeNight)) {
        pilotIdInput.onChange(flightPilotId);
      } else if (flightCopilotId && (copilotDay || copilotNight)) {
        pilotIdInput.onChange(flightCopilotId);
      }
    }
  }, [
    flightPilotId,
    flightCopilotId,
    prevFlightPilotId,
    prevFlightCopilotId,
    logPilotId,
    pilotInChargeDay,
    pilotInChargeNight,
    copilotDay,
    copilotNight,
    pilotIdInput,
  ]);

  const handleDeleteButtonClick = () => {
    if (id) {
      destroyInput.onChange(true);
    } else if (handleDelete) {
      handleDelete(index);
    }
  };

  const handleFlightTypeBlur = (e) => {
    const flightTypeName = e.target.getAttribute('data-type');
    const { value } = e.target;
    const newFlightTypes = clone(flightTypes);
    newFlightTypes[flightTypeName] = value;
    flightTypesInput.onChange(newFlightTypes);
  };

  const renderFlightTypes = () => (
    <p>
      {currentSettingsPilotFlightLogFlightTypes.map((flightType) => {
        const value = get(flightTypes, flightType, '');
        return (
          <span key={`pflft-${index}-${flightType}`} style={{ display: 'block' }}>
            <span style={{ display: 'inline-block', width: '70%' }}>{flightType}</span>
            <span>
              <InputField
                inputProps={{ 'data-type': flightType }}
                inputStyle={{ display: 'inline', width: '30%' }}
                controlOnly
                blurOnly
                type="text"
                input={{ value, onBlur: handleFlightTypeBlur }}
                size="sm"
              />
            </span>
          </span>
        );
      })}
    </p>
  );

  return (
    <tr>
      <td>{get(contactsDataSelector, [logPilotId, 'fullName'], 'Unknown')}</td>
      <td>
        <CheckboxInputField
          innerContentOnly
          size="sm"
          input={multiengineInput}
          meta={multiengineMeta}
        />
      </td>
      <td>
        <InputField
          controlOnly
          blurOnly
          type="text"
          input={pilotInChargeDayInput}
          meta={pilotInChargeDayMeta}
          size="sm"
        />
      </td>
      <td>
        <InputField
          controlOnly
          blurOnly
          type="text"
          input={copilotDayInput}
          meta={copilotDayMeta}
          size="sm"
        />
      </td>
      <td>
        <InputField
          controlOnly
          blurOnly
          type="text"
          input={dualDayInput}
          meta={dualDayMeta}
          size="sm"
        />
      </td>
      <td>
        <InputField
          controlOnly
          blurOnly
          type="text"
          input={commandPracticeDayInput}
          meta={commandPracticeDayMeta}
          size="sm"
        />
      </td>
      <td />
      <td>
        <InputField
          controlOnly
          blurOnly
          type="text"
          input={pilotInChargeNightInput}
          meta={pilotInChargeNightMeta}
          size="sm"
        />
      </td>
      <td>
        <InputField
          controlOnly
          blurOnly
          type="text"
          input={copilotNightInput}
          meta={copilotNightMeta}
          size="sm"
        />
      </td>
      <td>
        <InputField
          controlOnly
          blurOnly
          type="text"
          input={dualNightInput}
          meta={dualNightMeta}
          size="sm"
        />
      </td>
      <td>
        <InputField
          controlOnly
          blurOnly
          type="text"
          input={commandPracticeNightInput}
          meta={commandPracticeNightMeta}
          size="sm"
        />
      </td>
      <td />
      <td>{renderFlightTypes()}</td>
      <td>
        <Button
          type="button"
          variant="link"
          size="sm"
          onClick={handleDeleteButtonClick}
          tabIndex={-1}
        >
          del...
        </Button>
      </td>
    </tr>
  );
};
export default PilotFlightLog;
