/* eslint-disable react/jsx-props-no-spreading, react/sort-comp */
import { Row, Col, Tabs, Tab, Form, Button, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';

import { reduxForm, Field, Fields, FieldArray, getFormValues } from 'redux-form';

import objectHash from 'object-hash';

import compact from 'lodash.compact';
import clone from 'lodash.clone';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import defaultTo from 'lodash.defaultto';
import findIndex from 'lodash.findindex';
import findLastIndex from 'lodash.findlastindex';
import first from 'lodash.first';
import get from 'lodash.get';
import has from 'lodash.has';
import isEmpty from 'lodash.isempty';
import isNumber from 'lodash.isnumber';
import last from 'lodash.last';
import mergeWith from 'lodash.mergewith';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import range from 'lodash.range';
import reduce from 'lodash.reduce';
import set from 'lodash.set';
import slice from 'lodash.slice';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import {
  aircraftsData,
  aircraftCandidateHobbsData,
  contactsData,
  fuelBowsersData,
  fuelTankersData,
  locationsData,
} from '../selectors';

import { BookingCalculatedDataMemo, FlightSegmentCalculatedDataMemo } from '../memos';

import Loader from '../components/loader';
import InputField from '../components/form/input_field';
import LabelBlock from '../components/form/label_block';
import Glyphicon from '../components/glyphicon';

import BookingTitle from '../components/flight_form/booking_title';
import BookingAuditSnapshot from '../components/flight_form/booking_audit_snapshot';
import BookingWeightAndBalance from '../components/flight_form/booking_weight_and_balance';
import BookingFlightPlan from '../components/flight_form/booking_flight_plan';
import BookingPilotFlightLog from '../components/flight_form/booking_pilot_flight_log';
import BookingAdminFlightRecord from '../components/flight_form/booking_admin_flight_record';
import BookingCreators from '../components/flight_form/booking_creators';

import ChargeablePaxes from '../components/flight_form/chargeable_paxes';
import EngineEvents from '../components/flight_form/engine_events';
import FlightSegmentFieldArray from '../components/flight_form/flight_segment_field_array';
import FuelBowserFillFieldArray from '../components/flight_form/fuel_bowser_fill_field_array';
import FuelTankerFillFieldArray from '../components/flight_form/fuel_tanker_fill_field_array';
import LandingCharges from '../components/flight_form/landing_charges';
import OilFills from '../components/flight_form/oil_fills';
import PilotFlightExpenses from '../components/flight_form/pilot_flight_expenses';
import PilotFlightLogSummary from '../components/flight_form/pilot_flight_log_summary';

import AircraftInputField from '../components/flight_form/aircraft_input_field';
import AircraftConfigurationInputField from '../components/flight_form/aircraft_configuration_input_field';
import ChargeableInputField from '../components/flight_form/chargeable_input_field';
import StartDateAndTimeInputFields from '../components/flight_form/start_date_and_time_input_fields';
import EndDateAndTimeInputFields from '../components/flight_form/end_date_and_time_input_fields';
import ProviderInputField from '../components/flight_form/provider_input_field';
import FlightTypeInputField from '../components/flight_form/flight_type_input_field';
import PilotInputField from '../components/flight_form/pilot_input_field';
import CopilotInputField from '../components/flight_form/copilot_input_field';
import BookingCaaWeightLoadingIncludedInputField from '../components/flight_form/booking_caa_weight_loading_included_input_field';

import BookingHobbRecordFields from '../components/flight_form/booking_hobb_record_fields';
import BookingPilotDutyRecordFields from '../components/flight_form/booking_pilot_duty_record_fields';
import BookingPilotFlightNoteFields from '../components/flight_form/booking_pilot_flight_note_fields';

import FlightSegmentAddModal from '../components/flight_form/flight_segment_add_modal';
import FlightSegmentLocationCriticalModal from '../components/flight_form/flight_segment_location_critical_modal';
import PassengerAddModal from '../components/flight_form/passenger_add_modal';
import Confirm from '../components/confirm';

import {
  // defaultBookingDev,
  defaultBooking,
  defaultFlightSegment,
} from '../defaults';

import bookingUpdateMutation from '../mutations/booking_update_mutation';
import bookingCreateMutation from '../mutations/booking_create_mutation';
import contactCreateMutation from '../mutations/contact_create_mutation';

import {
  queriesReady,
  queryReady,
  queryJustReady,
  coerceInput,
  mapPickValues,
  pickValues,
  mapOmitValues,
  omitValues,
} from '../lib/utils';

import { roundUp, round2, round10, convertMassToFuel } from '../lib/convert_units';

import aircraftListQuery from '../queries/aircraft_list_query';
import aircraftCandidateHobbListQuery from '../queries/aircraft_candidate_hobb_list_query';
import aircraftConfigurationListQuery from '../queries/aircraft_configuration_list_query';
import aircraftEngineListQuery from '../queries/aircraft_engine_list_query';
import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import aircraftFlightTypePilotMapQuery from '../queries/aircraft_flight_type_pilot_map_query';
import bookingQuery from '../queries/booking_query';
import contactListQuery from '../queries/contact_list_query';
import flightTypeListQuery from '../queries/flight_type_list_query';
import fuelTypeListQuery from '../queries/fuel_type_list_query';
import fuelBowserListQuery from '../queries/fuel_bowser_list_query';
import fuelTankerListQuery from '../queries/fuel_tanker_list_query';
import locationListQuery from '../queries/location_min_list_query';
import roleListQuery from '../queries/role_list_query';

moment.updateLocale('en-nz');

let isInitialisedBookingForm = false;

class FlightForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
      tabKey: 2,
      addPassenger: {
        show: false,
        index: '',
        flightSegmentIndex: '',
        label: '',
        weight: 0,
      },
      addFlightSegment: { show: false, index: '' },
      addCritical: { show: false, index: '' },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleAddFlightSegmentClicked = this.handleAddFlightSegmentClicked.bind(this);
    this.handleAddFlightSegmentModalClicked =
      this.handleAddFlightSegmentModalClicked.bind(this);
    this.handleAddFlightSegmentModalCancel =
      this.handleAddFlightSegmentModalCancel.bind(this);
    this.handleAddFlightSegmentModalSubmit =
      this.handleAddFlightSegmentModalSubmit.bind(this);

    this.handleCriticalLocationModalClicked =
      this.handleCriticalLocationModalClicked.bind(this);
    this.handleCriticalLocationModalCancel =
      this.handleCriticalLocationModalCancel.bind(this);
    this.handleCriticalLocationModalSubmit =
      this.handleCriticalLocationModalSubmit.bind(this);

    this.handleStartLocationChanged = this.handleStartLocationChanged.bind(this);
    this.handleEndLocationChanged = this.handleEndLocationChanged.bind(this);

    this.handleAddPassengerModalClicked = this.handleAddPassengerModalClicked.bind(this);
    this.handleAddPassengerModalCancel = this.handleAddPassengerModalCancel.bind(this);
    this.handleAddPassengerModalSubmit = this.handleAddPassengerModalSubmit.bind(this);

    this.handleAddPassengerClicked = this.handleAddPassengerClicked.bind(this);
    this.handlePassengerChanged = this.handlePassengerChanged.bind(this);

    this.renderBookingTab = this.renderBookingTab.bind(this);
    this.renderFlightPlanTab = this.renderFlightPlanTab.bind(this);

    this.renderFlightSegmentFieldArray = this.renderFlightSegmentFieldArray.bind(this);
    this.renderFuelBowserFillFieldArray = this.renderFuelBowserFillFieldArray.bind(this);

    this.renderChargeablesFieldArray = this.renderChargeablesFieldArray.bind(this);
    this.renderChargeablesPaxFieldArray = this.renderChargeablesPaxFieldArray.bind(this);
    this.renderEngineEventsFieldArray = this.renderEngineEventsFieldArray.bind(this);

    this.renderFuelTankerFillFieldArray = this.renderFuelTankerFillFieldArray.bind(this);
    this.renderLandingChargesFieldArray = this.renderLandingChargesFieldArray.bind(this);
    this.renderOilFillsFieldArray = this.renderOilFillsFieldArray.bind(this);
    this.renderPilotFlightExpensesFieldArray =
      this.renderPilotFlightExpensesFieldArray.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.delayedUpdateFlightSegments = debounce((flightSegmentsCalculatedData) => {
      const flightSegments = get(this.props, 'formValues.flight_segments_attributes', []);
      const newFlightSegments = flightSegments.map((fs, index) => {
        const flightSegmentCalculatedData = flightSegmentsCalculatedData.find(
          (fsd) => fsd.flightSegmentIndex === index
        );
        if (flightSegmentCalculatedData) {
          const newFlightSegment = {
            ...fs,
            take_off_fuel: flightSegmentCalculatedData.newFlightSegmentTakeOffFuel,
          };
          if (!fs.pilot_take_off_fuel_adjusted) {
            newFlightSegment.pilot_take_off_fuel =
              flightSegmentCalculatedData.newFlightSegmentPilotTakeOffFuel;
          }
          return newFlightSegment;
        }
        return fs;
      });
      this.props.change('flight_segments_attributes', newFlightSegments);
    }, 100);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.bookingQuery &&
      queryJustReady(this.props.bookingQuery, nextProps.bookingQuery)
    ) {
      if (
        get(nextProps.bookingQuery, 'data.audit_created_at') &&
        this.state.tabKey === 2
      ) {
        this.setState({
          tabKey: 3,
        });
      }
    }
  }

  componentWillUnmount() {
    isInitialisedBookingForm = false;
    this.delayedUpdateFlightSegments.cancel();
  }

  getBookingAircraftSeatConfigurations() {
    const aircraftConfigurationId = get(
      this.props,
      'formValues.aircraft_configuration_id'
    );
    if (aircraftConfigurationId) {
      const aircraftConfiguration = this.props.aircraftConfigurationListQuery.data.find(
        (ac) => ac.id === parseInt(aircraftConfigurationId, 10)
      );
      return clone(
        get(aircraftConfiguration, 'seatConfiguration.aircraftSeatSeatConfigurations')
      ).sort((a, b) => a.position - b.position);
    }
    return [];
  }

  getBookingAircraftHoldConfigurations() {
    const aircraftConfigurationId = get(
      this.props,
      'formValues.aircraft_configuration_id'
    );
    if (aircraftConfigurationId) {
      const aircraftConfiguration = this.props.aircraftConfigurationListQuery.data.find(
        (ac) => ac.id === parseInt(aircraftConfigurationId, 10)
      );
      return clone(
        get(aircraftConfiguration, 'holdConfiguration.aircraftHoldHoldConfigurations', [])
      ).sort((a, b) => a.position - b.position);
    }
    return [];
  }

  getBookingAircraftTankConfigurations() {
    const aircraftConfigurationId = get(
      this.props,
      'formValues.aircraft_configuration_id'
    );
    if (aircraftConfigurationId) {
      const aircraftConfiguration = this.props.aircraftConfigurationListQuery.data.find(
        (ac) => ac.id === parseInt(aircraftConfigurationId, 10)
      );
      return clone(
        get(aircraftConfiguration, 'tankConfiguration.aircraftTankTankConfigurations', [])
      ).sort((a, b) => a.position - b.position);
    }
    return [];
  }

  getBookingAircraftAssets() {
    const aircraftConfigurationId = get(
      this.props,
      'formValues.aircraft_configuration_id'
    );
    if (aircraftConfigurationId) {
      const aircraftConfiguration = this.props.aircraftConfigurationListQuery.data.find(
        (ac) => ac.id === parseInt(aircraftConfigurationId, 10)
      );
      return clone(get(aircraftConfiguration, 'aircraftAssetAircraftConfigurations'));
    }
    return [];
  }

  getBookingExternalLoadPoints() {
    const aircraftConfigurationId = get(
      this.props,
      'formValues.aircraft_configuration_id'
    );
    if (aircraftConfigurationId) {
      const aircraftConfiguration = this.props.aircraftConfigurationListQuery.data.find(
        (ac) => ac.id === parseInt(aircraftConfigurationId, 10)
      );
      const externalLoadPoint = get(aircraftConfiguration, 'externalLoadPoint');
      if (externalLoadPoint) {
        // In future I predict externalLoadPoints will be has many for aircraft configurations
        return [externalLoadPoint];
      }
    }
    return [];
  }

  getBookingAircraftEngines() {
    return this.props.aircraftEngineListQuery.data.filter(
      (ae) =>
        ae.aircraft_id === parseInt(get(this.props, 'formValues.aircraft_id', 0), 10)
    );
  }

  getActiveFlightSegments() {
    return get(this.props, 'formValues.flight_segments_attributes', [])
      .map((fs, idx) => ({
        ...fs,
        index: idx,
      }))
      .filter((fs) => !fs._destroy);
  }

  getBookingModifiers() {
    const formValues = get(this.props, 'formValues', {});
    const bookingModifiers = {
      bookingPlannedReserve: defaultTo(
        parseInt(get(formValues, 'booking_planned_reserve'), 10),
        0
      ),
      bookingCruiseAirspeedSl: round2(
        defaultTo(parseFloat(get(formValues, 'booking_cruise_airspeed_sl')), 0)
      ),
      bookingCruiseFuelConsumptionSl: round2(
        defaultTo(parseFloat(get(formValues, 'booking_cruise_fuel_consumption_sl')), 0)
      ),
      bookingArmUnit: get(formValues, 'booking_arm_unit'),
      bookingWeightUnit: get(formValues, 'booking_weight_unit'),
      bookingFuelUnit: get(formValues, 'booking_fuel_unit'),
      bookingAirspeedUnit: get(formValues, 'booking_airspeed_unit'),
      bookingAircraftWeight: round10(
        defaultTo(parseFloat(get(formValues, 'booking_aircraft_weight')), 0)
      ),
      bookingAircraftWeightDefined: get(formValues, 'booking_aircraft_weight_defined'),
      bookingAircraftLateralArm: round10(
        defaultTo(parseFloat(get(formValues, 'booking_aircraft_lateral_arm')), 0)
      ),
      bookingAircraftLongitudinalArm: round10(
        defaultTo(parseFloat(get(formValues, 'booking_aircraft_longitudinal_arm')), 0)
      ),
      bookingCaaWeightLoading: roundUp(
        defaultTo(parseInt(get(formValues, 'booking_caa_weight_loading'), 10), 0)
      ),
      bookingCaaWeightLoadingIncluded: get(
        formValues,
        'booking_caa_weight_loading_included',
        false
      ),
      copilotId: defaultTo(parseInt(get(formValues, 'copilot_id'), 10), ''),
    };
    return bookingModifiers;
  }

  getBookingCalculatedData() {
    const formValues = get(this.props, 'formValues', {});
    const aircraftId = defaultTo(parseInt(get(formValues, 'aircraft_id'), 10), '');
    const bookingModifiers = this.getBookingModifiers();
    const bookingCalculatedData = BookingCalculatedDataMemo({
      aircraftId,
      bookingModifiersObjectHash: objectHash(bookingModifiers),
      bookingModifiers,
      aircrafts: this.props.aircraftListQuery.data,
      aircraftTypes: this.props.aircraftTypeListQuery.data,
      fuelTypes: this.props.fuelTypeListQuery.data,
    });
    return bookingCalculatedData;
  }

  getFlightSegmentsCalculatedData() {
    const formValues = get(this.props, 'formValues', {});
    const aircraftId = defaultTo(parseInt(get(formValues, 'aircraft_id'), 10), '');
    const aircraftConfigurationId = defaultTo(
      parseInt(get(formValues, 'aircraft_configuration_id'), 10),
      ''
    );
    const bookingModifiers = this.getBookingModifiers();
    const activeFlightSegments = this.getActiveFlightSegments();
    // console.log(activeFlightSegments);
    // console.log('-------------');
    // console.log('iteration');
    const activeFlightSegmentsCalculatedData = activeFlightSegments.map(
      (activeFlightSegment, activeFlightSegmentIndex) =>
        FlightSegmentCalculatedDataMemo({
          aircraftId,
          aircraftConfigurationId,
          bookingModifiersObjectHash: objectHash(bookingModifiers),
          bookingModifiers,
          flightSegmentObjectHash: objectHash(activeFlightSegment),
          activeFlightSegmentIndex,
          flightSegment: activeFlightSegment,
          flightSegments: activeFlightSegments,
          aircrafts: this.props.aircraftListQuery.data,
          aircraftTypes: this.props.aircraftTypeListQuery.data,
          aircraftConfigurations: this.props.aircraftConfigurationListQuery.data,
          fuelTypes: this.props.fuelTypeListQuery.data,
          locations: this.props.locationListQuery.data,
          fuelBowsers: this.props.fuelBowserListQuery.data,
          fuelTankers: this.props.fuelTankerListQuery.data,
        })
    );

    const calculatedDataLength = activeFlightSegmentsCalculatedData.length;
    const calculatedDataRange = range(calculatedDataLength).reverse();
    // console.log({ calculatedDataLength });
    // console.log({ calculatedDataRange });
    // console.log({ activeFlightSegmentsCalculatedData });

    // Calculate default take off and landing fuel
    calculatedDataRange.map((calculatedDataRangeIndex) => {
      // console.log('-----');
      // console.log({ calculatedDataRangeIndex });
      const calculatedDataSlices = activeFlightSegmentsCalculatedData.slice(
        calculatedDataRangeIndex
      );
      const nextCalculatedDataSlices = activeFlightSegmentsCalculatedData.slice(
        calculatedDataRangeIndex + 1
      );
      const calculatedDataSlicesLength = calculatedDataSlices.length;
      const firstCalculatedDataSlice = calculatedDataSlices[0];
      if (isEmpty(firstCalculatedDataSlice)) {
        return 0;
      }
      const {
        aircraftTypeMaximumAllUpWeight,
        flightSegmentPilotTakeOffFuelAdjusted,
        convertedBookingReserveConsumptionSl,
        convertedFlightSegmentFuelBurn,
        convertedZeroFuelAircraft: { weight: convertedZeroFuelAircraftWeight },
        convertedTankAssignmentsMaximumWeight,
        // flightSegmentIndex,
        activeFlightSegmentIndex,
        bookingCruiseFuelConsumptionSl,
        bookingReserveConsumptionSl,
      } = firstCalculatedDataSlice;
      // console.log({ flightSegmentIndex });
      // console.log({ activeFlightSegmentIndex });

      const remainingFlightTime = calculatedDataSlices
        .filter((fs) => fs.flightSegmentLoadedFlighttime)
        .reduce((total, time) => total + time.flightSegmentLoadedFlighttime, 0);

      let remainingFlightFuel = 0;
      if (remainingFlightTime > 0) {
        remainingFlightFuel = round10(
          (remainingFlightTime / 60) * bookingCruiseFuelConsumptionSl
        );
      }
      const bookingRemainingRequiredFuel =
        remainingFlightFuel + bookingReserveConsumptionSl;

      let newConvertedFlightSegmentTakeOffFuel = 0;
      let newConvertedFlightSegmentLandingFuel = 0;

      if (calculatedDataSlicesLength === 1) {
        newConvertedFlightSegmentLandingFuel = convertedBookingReserveConsumptionSl;
        newConvertedFlightSegmentTakeOffFuel = round10(
          newConvertedFlightSegmentLandingFuel + convertedFlightSegmentFuelBurn
        );
      } else {
        const nextCalculatedDataSlice = calculatedDataSlices.at(1);
        newConvertedFlightSegmentLandingFuel =
          nextCalculatedDataSlice.newConvertedFlightSegmentTakeOffFuel;
        newConvertedFlightSegmentTakeOffFuel = round10(
          newConvertedFlightSegmentLandingFuel + convertedFlightSegmentFuelBurn
        );
      }

      const newConvertedFlightSegmentRemainingZeroFuelWeight = round10(
        aircraftTypeMaximumAllUpWeight - convertedZeroFuelAircraftWeight
      );

      const payloadRestricted =
        newConvertedFlightSegmentTakeOffFuel >
        newConvertedFlightSegmentRemainingZeroFuelWeight;
      const capacityRestricted =
        newConvertedFlightSegmentTakeOffFuel > convertedTankAssignmentsMaximumWeight;

      if (payloadRestricted || capacityRestricted) {
        const nextRefuellingCalculatedDataSlice = nextCalculatedDataSlices.find(
          (s) => s.flightSegmentFuelBowsers.length > 0
        );
        if (nextRefuellingCalculatedDataSlice) {
          // const { flightSegmentIndex: refuellingFlightSegmentIndex } =
          //   nextRefuellingCalculatedDataSlice;
          const { activeFlightSegmentIndex: refuellingFlightSegmentIndex } =
            nextRefuellingCalculatedDataSlice;
          const refuellingRange = range(
            refuellingFlightSegmentIndex - 1,
            // flightSegmentIndex - 1
            activeFlightSegmentIndex - 1
          );
          let newFuel = convertedBookingReserveConsumptionSl;
          refuellingRange.map((refuellingIndex) => {
            if (refuellingIndex === activeFlightSegmentIndex) {
              newConvertedFlightSegmentLandingFuel = newFuel;
              newConvertedFlightSegmentTakeOffFuel = round10(
                newConvertedFlightSegmentLandingFuel + convertedFlightSegmentFuelBurn
              );
              newFuel = newConvertedFlightSegmentTakeOffFuel;
            } else {
              // console.log({ refuellingRange });
              // console.log({ refuellingIndex });
              // console.log({ calculatedDataSlices });
              const refuelCalculatedDataSlice = calculatedDataSlices.find(
                (s) => s.activeFlightSegmentIndex === refuellingIndex
              );
              refuelCalculatedDataSlice.newConvertedFlightSegmentLandingFuel = newFuel;
              refuelCalculatedDataSlice.newConvertedFlightSegmentTakeOffFuel = round10(
                refuelCalculatedDataSlice.newConvertedFlightSegmentLandingFuel +
                  refuelCalculatedDataSlice.convertedFlightSegmentFuelBurn
              );
              newFuel = refuelCalculatedDataSlice.newConvertedFlightSegmentTakeOffFuel;
            }
            return true;
          });
        } else if (capacityRestricted) {
          const overCapacity = round10(
            newConvertedFlightSegmentTakeOffFuel - convertedTankAssignmentsMaximumWeight
          );
          newConvertedFlightSegmentTakeOffFuel = round10(
            newConvertedFlightSegmentTakeOffFuel - overCapacity
          );
          newConvertedFlightSegmentLandingFuel = round10(
            newConvertedFlightSegmentLandingFuel - overCapacity
          );
          nextCalculatedDataSlices.map((nextCalculatedDataSlice) => {
            /* eslint-disable no-param-reassign */
            nextCalculatedDataSlice.newConvertedFlightSegmentTakeOffFuel = round10(
              nextCalculatedDataSlice.newConvertedFlightSegmentTakeOffFuel - overCapacity
            );
            nextCalculatedDataSlice.newConvertedFlightSegmentLandingFuel = round10(
              nextCalculatedDataSlice.newConvertedFlightSegmentLandingFuel - overCapacity
            );
            return true;
          });
        }
      }
      firstCalculatedDataSlice.newConvertedFlightSegmentTakeOffFuel =
        newConvertedFlightSegmentTakeOffFuel;
      firstCalculatedDataSlice.newConvertedFlightSegmentLandingFuel =
        newConvertedFlightSegmentLandingFuel;
      firstCalculatedDataSlice.bookingRemainingRequiredFuel =
        bookingRemainingRequiredFuel;

      return flightSegmentPilotTakeOffFuelAdjusted;
    });

    // Calculate pilot take off and landing fuel
    calculatedDataRange.map((calculatedDataRangeIndex) => {
      const calculatedDataSlices = activeFlightSegmentsCalculatedData.slice(
        calculatedDataRangeIndex
      );
      const nextCalculatedDataSlices = activeFlightSegmentsCalculatedData.slice(
        calculatedDataRangeIndex + 1
      );
      const firstCalculatedDataSlice = first(calculatedDataSlices);
      if (isEmpty(firstCalculatedDataSlice)) {
        return 0;
      }
      const {
        flightSegmentPilotTakeOffFuelAdjusted,
        newConvertedFlightSegmentTakeOffFuel,
        newConvertedFlightSegmentLandingFuel,
        convertedFlightSegmentFuelBurn,
        activeFlightSegmentIndex,
        convertedFlightSegmentPilotTakeOffFuel,
      } = firstCalculatedDataSlice;

      let newConvertedFlightSegmentPilotTakeOffFuel = 0;
      let newConvertedFlightSegmentPilotLandingFuel = 0;

      if (!flightSegmentPilotTakeOffFuelAdjusted) {
        newConvertedFlightSegmentPilotTakeOffFuel = newConvertedFlightSegmentTakeOffFuel;
        newConvertedFlightSegmentPilotLandingFuel = newConvertedFlightSegmentLandingFuel;
        firstCalculatedDataSlice.newConvertedFlightSegmentPilotTakeOffFuel =
          newConvertedFlightSegmentPilotTakeOffFuel;
        firstCalculatedDataSlice.newConvertedFlightSegmentPilotLandingFuel =
          newConvertedFlightSegmentPilotLandingFuel;
      } else {
        newConvertedFlightSegmentPilotTakeOffFuel =
          convertedFlightSegmentPilotTakeOffFuel;
        newConvertedFlightSegmentPilotLandingFuel = round10(
          convertedFlightSegmentPilotTakeOffFuel - convertedFlightSegmentFuelBurn
        );
        firstCalculatedDataSlice.newConvertedFlightSegmentPilotTakeOffFuel =
          newConvertedFlightSegmentPilotTakeOffFuel;
        firstCalculatedDataSlice.newConvertedFlightSegmentPilotLandingFuel =
          newConvertedFlightSegmentPilotLandingFuel;
        const fuelAdjustedCalculatedDataSlice = nextCalculatedDataSlices.find(
          (currentSlice) => currentSlice.flightSegmentPilotTakeOffFuelAdjusted
        );
        const refuelledCalculatedDataSlice = nextCalculatedDataSlices.find(
          (currentSlice) => {
            const {
              activeFlightSegmentIndex: currentSliceActiveFlightSegmentIndex,
              newConvertedFlightSegmentPilotTakeOffFuel: currentSlicePilotTakeOffFuel,
            } = currentSlice;
            const prevSlice = calculatedDataSlices.find(
              (s) =>
                s.activeFlightSegmentIndex === currentSliceActiveFlightSegmentIndex - 1
            );
            const prevSlicePilotLandingFuel = get(
              prevSlice,
              'newConvertedFlightSegmentPilotLandingFuel'
            );
            const prevSliceLandingFuel = get(
              prevSlice,
              'newConvertedFlightSegmentLandingFuel'
            );
            return (
              prevSlicePilotLandingFuel &&
              prevSliceLandingFuel &&
              currentSlicePilotTakeOffFuel >
                round10(
                  prevSlicePilotLandingFuel +
                    prevSliceLandingFuel -
                    prevSlicePilotLandingFuel
                )
            );
          }
        );
        const lastCalculatedDataSlice = last(calculatedDataSlices);
        let fuelAdjustedActiveIndex = get(
          fuelAdjustedCalculatedDataSlice,
          'activeFlightSegmentIndex'
        );
        if (fuelAdjustedActiveIndex !== undefined) {
          // we want to adjust upto but not including the next pilot adjusted segment
          fuelAdjustedActiveIndex -= 1;
        }
        let refuelledActiveIndex = get(
          refuelledCalculatedDataSlice,
          'activeFlightSegmentIndex'
        );
        if (refuelledActiveIndex !== undefined) {
          // we want to adjust upto but not including the next refuelled segment
          refuelledActiveIndex -= 1;
        }
        const lastActiveIndex = get(lastCalculatedDataSlice, 'activeFlightSegmentIndex');
        // potentially zeros in our array indexes
        const candidateIndexes = [fuelAdjustedActiveIndex, refuelledActiveIndex].filter(
          (item) => item !== undefined && item !== null
        );
        const endAdjustableIndex =
          candidateIndexes.length > 0 ? Math.min(...candidateIndexes) : lastActiveIndex;
        const adjustableRange = range(activeFlightSegmentIndex, endAdjustableIndex + 1);
        let newFuel = newConvertedFlightSegmentPilotLandingFuel;
        adjustableRange.map((adjustableIndex) => {
          if (adjustableIndex !== activeFlightSegmentIndex) {
            const adjustCalculatedDataSlice = calculatedDataSlices.find(
              (s) => s.activeFlightSegmentIndex === adjustableIndex
            );
            adjustCalculatedDataSlice.newConvertedFlightSegmentPilotTakeOffFuel = newFuel;
            adjustCalculatedDataSlice.newConvertedFlightSegmentPilotLandingFuel = round10(
              adjustCalculatedDataSlice.newConvertedFlightSegmentPilotTakeOffFuel -
                adjustCalculatedDataSlice.convertedFlightSegmentFuelBurn
            );
            newFuel = adjustCalculatedDataSlice.newConvertedFlightSegmentPilotLandingFuel;
          }
          return true;
        });
      }
      return flightSegmentPilotTakeOffFuelAdjusted;
    });

    activeFlightSegmentsCalculatedData.map((flightSegmentCalculatedData) => {
      if (isEmpty(flightSegmentCalculatedData)) {
        return flightSegmentCalculatedData;
      }
      const {
        newConvertedFlightSegmentLandingFuel,
        newConvertedFlightSegmentPilotLandingFuel,
        bookingWeightUnit,
        bookingFuelUnit,
        aircraftTypeFuelTypeSpecificGravity,
      } = flightSegmentCalculatedData;

      const flightSegmentLandingFuel = convertMassToFuel({
        massQuantity: newConvertedFlightSegmentLandingFuel,
        massUnit: bookingWeightUnit,
        fuelUnit: bookingFuelUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      const flightSegmentPilotLandingFuel = convertMassToFuel({
        massQuantity: newConvertedFlightSegmentPilotLandingFuel,
        massUnit: bookingWeightUnit,
        fuelUnit: bookingFuelUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      flightSegmentCalculatedData.flightSegmentLandingFuel = flightSegmentLandingFuel;
      flightSegmentCalculatedData.flightSegmentPilotLandingFuel =
        flightSegmentPilotLandingFuel;
      return true;
    });

    const newFlightSegmentsCalculatedData = activeFlightSegmentsCalculatedData.map(
      (flightSegmentCalculatedData, flightSegmentCalculatedDataIndex) => {
        if (isEmpty(flightSegmentCalculatedData)) {
          return flightSegmentCalculatedData;
        }
        const {
          newConvertedFlightSegmentTakeOffFuel,
          newConvertedFlightSegmentPilotTakeOffFuel,
          bookingWeightUnit,
          bookingFuelUnit,
          aircraftTypeFuelTypeSpecificGravity,
          convertedFlightSegmentMinDispatchFuel,
          convertedTankAssignmentsMaximumWeight,
        } = flightSegmentCalculatedData;

        let prevFlightSegmentLandingFuel;
        if (flightSegmentCalculatedDataIndex > 0) {
          const prevFlightSegmentCalculatedData = activeFlightSegmentsCalculatedData.at(
            flightSegmentCalculatedDataIndex - 1
          );
          prevFlightSegmentLandingFuel =
            prevFlightSegmentCalculatedData.flightSegmentLandingFuel;
        }
        let prevFlightSegmentPilotLandingFuel;
        if (flightSegmentCalculatedDataIndex > 0) {
          const prevFlightSegmentCalculatedData = activeFlightSegmentsCalculatedData.at(
            flightSegmentCalculatedDataIndex - 1
          );
          prevFlightSegmentPilotLandingFuel =
            prevFlightSegmentCalculatedData.flightSegmentPilotLandingFuel;
        }

        const newFlightSegmentTakeOffFuel = convertMassToFuel({
          massQuantity: newConvertedFlightSegmentTakeOffFuel,
          massUnit: bookingWeightUnit,
          fuelUnit: bookingFuelUnit,
          specificGravity: aircraftTypeFuelTypeSpecificGravity,
        });
        const newFlightSegmentPilotTakeOffFuel = convertMassToFuel({
          massQuantity: newConvertedFlightSegmentPilotTakeOffFuel,
          massUnit: bookingWeightUnit,
          fuelUnit: bookingFuelUnit,
          specificGravity: aircraftTypeFuelTypeSpecificGravity,
        });

        const flightSegmentTakeOffFuelWeightUnderMinDispatchFuelWeight =
          round2(newConvertedFlightSegmentTakeOffFuel) <
          round2(convertedFlightSegmentMinDispatchFuel);
        const flightSegmentPilotTakeOffFuelWeightUnderMinDispatchFuelWeight =
          round2(newConvertedFlightSegmentPilotTakeOffFuel) <
          round2(convertedFlightSegmentMinDispatchFuel);

        const flightSegmentTakeOffFuelOverMaximumTankCapacity =
          round2(newConvertedFlightSegmentTakeOffFuel) >
          round2(convertedTankAssignmentsMaximumWeight);
        const flightSegmentPilotTakeOffFuelOverMaximumTankCapacity =
          round2(newConvertedFlightSegmentPilotTakeOffFuel) >
          round2(convertedTankAssignmentsMaximumWeight);

        return {
          ...flightSegmentCalculatedData,
          newFlightSegmentTakeOffFuel, // USED to update form value
          newFlightSegmentPilotTakeOffFuel, // USED to update form value
          prevFlightSegmentLandingFuel, // USED in flight plan to see if there was a refuel
          prevFlightSegmentPilotLandingFuel, // USED in flight plan to see if there was a refuel
          flightSegmentTakeOffFuelWeightUnderMinDispatchFuelWeight,
          flightSegmentPilotTakeOffFuelWeightUnderMinDispatchFuelWeight,
          flightSegmentTakeOffFuelOverMaximumTankCapacity,
          flightSegmentPilotTakeOffFuelOverMaximumTankCapacity,
        };
      }
    );
    const currentTakeOffFuelObjectHash = objectHash(
      activeFlightSegments.map((fs) => fs.take_off_fuel)
    );
    const nextTakeOffFuelObjectHash = objectHash(
      newFlightSegmentsCalculatedData.map((fs) => fs.newFlightSegmentTakeOffFuel)
    );
    const currentPilotTakeOffFuelObjectHash = objectHash(
      activeFlightSegments.map((fs) => fs.pilot_take_off_fuel)
    );
    const nextPilotTakeOffFuelObjectHash = objectHash(
      newFlightSegmentsCalculatedData.map((fs) => fs.newFlightSegmentPilotTakeOffFuel)
    );
    if (
      currentTakeOffFuelObjectHash !== nextTakeOffFuelObjectHash ||
      currentPilotTakeOffFuelObjectHash !== nextPilotTakeOffFuelObjectHash
    ) {
      this.delayedUpdateFlightSegments(newFlightSegmentsCalculatedData);
    }
    return newFlightSegmentsCalculatedData;
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      [testProps.bookingQuery, true], // ignore if undefined
      testProps.aircraftListQuery,
      testProps.aircraftConfigurationListQuery,
      testProps.aircraftEngineListQuery,
      testProps.aircraftTypeListQuery,
      testProps.aircraftCandidateHobbListQuery,
      testProps.aircraftFlightTypePilotMapQuery,
      testProps.chargeableListQuery,
      testProps.flightTypeListQuery,
      testProps.fuelBowserListQuery,
      testProps.fuelTankerListQuery,
      testProps.fuelTypeListQuery,
      testProps.locationListQuery,
      testProps.passengerListQuery,
      testProps.pilotListQuery,
      testProps.providerListQuery,
      testProps.roleListQuery
    );
  }

  handleSubmit(data) {
    const submitData = cloneDeep(data);
    const adminId = this.props.currentContact.id;
    if (this.state.updating) {
      submitData.updated_by_admin_id = adminId;
      if (this.isAudited()) {
        const undeletedChargeables = submitData.booking_chargeables_attributes.filter(
          (bca) => !bca._destroy
        );
        if (undeletedChargeables.length === 1) {
          const newBookingChargeables = submitData.booking_chargeables_attributes.map(
            (bookingChargeable) => ({ ...bookingChargeable, pax: '' })
          );
          submitData.booking_chargeables_attributes = newBookingChargeables;
        }
      }
    } else {
      submitData.chargeable_ids = submitData.booking_chargeables_attributes.map(
        (bca) => bca.chargeable_id
      );
      delete submitData.booking_chargeables_attributes;
      submitData.updated_by_admin_id = adminId;
      submitData.created_by_admin_id = adminId;
    }
    const activeFlightSegments = this.getActiveFlightSegments();
    if (activeFlightSegments.length > 0) {
      const firstFlightSegment = first(activeFlightSegments);
      const lastFlightSegment = last(activeFlightSegments);
      submitData.start_at = submitData.start_at || firstFlightSegment.start_at;
      submitData.end_at = submitData.end_at || lastFlightSegment.end_at;
      submitData.start_location_id = firstFlightSegment.start_location_id;
      submitData.end_location_id = lastFlightSegment.end_location_id;
    } else {
      submitData.start_location_id = '';
      submitData.end_location_id = '';
    }
    let activeIndex = -1;
    let newFlightSegments = cloneDeep(submitData.flight_segments_attributes);
    newFlightSegments = newFlightSegments.map((flightSegment) => {
      if (!flightSegment._destroy) {
        activeIndex += 1;
      }
      const newSeatAssigments = cloneDeep(flightSegment.seat_assignments_attributes).map(
        (sa) => ({
          ...sa,
          seat_assignment_weight: sa.seat_assignment_weight || 0,
        })
      );
      return {
        ...flightSegment,
        position: activeIndex,
        seat_assignments_attributes: newSeatAssigments,
      };
    });

    submitData.flight_segments_attributes = newFlightSegments;

    if (get(submitData, 'pilot_flight_logs_attributes')) {
      submitData.pilot_flight_logs_attributes =
        submitData.pilot_flight_logs_attributes.map((pfl) => {
          const newFlightTypes = reduce(
            pfl.flight_types,
            (result, value, key) => {
              const newResult = { ...result };
              if (value && isNumber(parseFloat(value))) {
                newResult[key] = parseFloat(value);
              }
              return newResult;
            },
            {}
          );
          return mergeWith(
            pfl,
            { flight_types: newFlightTypes },
            (objValue, srcValue, key) => {
              if (key === 'flight_types') {
                return srcValue;
              }
              return undefined;
            }
          );
        });
    }

    if (!this.state.updating || (this.state.updating && !this.isAudited())) {
      [
        'oil_fills_attributes',
        'engine_events_attributes',
        'fuel_bowser_fills_attributes',
        'fuel_tanker_fills_attributes',
        'pilot_flight_expenses_attributes',
        'pilot_flight_logs_attributes',
        'pilot_duty_record_attributes',
        'copilot_duty_record_attributes',
        'hobb_record_attributes',
        'admin_flight_record_attributes',
        'pilot_flight_note_attributes',
      ].forEach((attributes) => {
        delete submitData[attributes];
      });
    }

    if (this.isAudited()) {
      set(submitData, 'admin_flight_record_attributes.admin_id', adminId);
    }
    // console.log('submit')
    // console.log(submitData)
    // return

    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = { variables: { input: coerceInput(submitData) } };
    if (this.state.updating) {
      mutation = this.props.bookingUpdateMutation;
      mutationMessage = 'Booking update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.bookingCreateMutation;
      mutationMessage = 'Booking create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate(this.props.currentSettingsReturnRoute);
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleTabClick(tabKey) {
    if (Number.isNaN(parseInt(tabKey, 10))) {
      tabKey.preventDefault();
    } else {
      this.setState({ tabKey });
    }
    this.setState({ tabKey });
  }

  handleAddPassengerModalClicked(index, flightSegmentIndex, label, weight) {
    this.setState({
      addPassenger: {
        show: true,
        index,
        flightSegmentIndex,
        label,
        weight,
      },
    });
  }

  handleAddPassengerModalCancel() {
    this.setState({
      addPassenger: {
        show: false,
        index: '',
        flightSegmentIndex: '',
        label: '',
        weight: 0,
      },
    });
  }

  handleAddPassengerModalSubmit({ firstName, lastName, weight }) {
    const { index, flightSegmentIndex } = this.state.addPassenger;
    const passengerRoleId = get(
      this.props.roleListQuery.data.find((r) => r.passenger && !r.chargeable),
      'id'
    );
    const args = {
      display_name: compact([firstName, lastName]).join(' '),
      first_name: firstName,
      last_name: lastName || 'Unknown',
      weight: weight || 0,
      role_ids: [passengerRoleId],
    };
    this.handleAddPassengerClicked(args, index, flightSegmentIndex);
    this.handleAddPassengerModalCancel();
  }

  handleAddPassengerClicked(args, index, flightSegmentIndex) {
    this.props.mutationSet(true);
    const mutationData = { variables: { input: coerceInput(args) } };
    return this.props
      .contactCreateMutation(mutationData)
      .then((res) => {
        this.props.mutationSuccess('Passenger create');
        this.handlePassengerChanged(
          index,
          flightSegmentIndex,
          get(res, 'data.contactCreate.id'),
          args.display_name,
          args.weight
        );
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handlePassengerChanged(index, flightSegmentIndex, passengerId, paxName, weight) {
    const flightSegment = get(this.props.formValues, [
      'flight_segments_attributes',
      flightSegmentIndex,
    ]);
    const seatAssignment = get(flightSegment, ['seat_assignments_attributes', index]);
    const currentPassengerId = defaultTo(parseInt(seatAssignment.passenger_id, 10), '');
    const newPassengerId = defaultTo(parseInt(passengerId, 10), '');
    const currentPaxName = defaultTo(seatAssignment.pax_name, '');
    const newPaxName = defaultTo(paxName, '');
    const currentWeight = defaultTo(
      parseInt(seatAssignment.seat_assignment_weight, 10),
      0
    );
    let newWeight = defaultTo(parseInt(weight, 10), 0);
    const field = [
      'flight_segments_attributes',
      flightSegmentIndex,
      'seat_assignments_attributes',
      index,
    ].join('.');
    if (currentPassengerId !== newPassengerId || currentPaxName !== newPaxName) {
      if (currentPassengerId !== newPassengerId) {
        this.props.change(`${field}.passenger_id`, newPassengerId);
      }
      if (currentPaxName !== newPaxName) {
        this.props.change(`${field}.pax_name`, newPaxName);
      }
      if (newPassengerId) {
        const passenger = this.props.passengerListQuery.data.find(
          (p) => p.id === newPassengerId
        );
        newWeight = get(passenger, 'weight', newWeight);
      }
      if (currentWeight !== newWeight) {
        this.props.change(`${field}.seat_assignment_weight`, newWeight);
      }
    }
  }

  handleAddFlightSegmentClicked() {
    this.handleAddFlightSegmentModalClicked('last');
  }

  handleAddFlightSegmentModalClicked(index) {
    this.setState({
      addFlightSegment: {
        show: true,
        index,
      },
    });
  }

  handleAddFlightSegmentModalCancel() {
    this.setState({
      addFlightSegment: {
        show: false,
        index: '',
      },
    });
  }

  handleAddFlightSegmentModalSubmit(addType, includePax) {
    const { index } = this.state.addFlightSegment;
    let collectionIndex = index;
    if (collectionIndex === 'last') {
      const values = this.props.formValues.flight_segments_attributes;
      collectionIndex = findLastIndex(values, (fs) => !fs._destroy);
    }
    this.handleAddFlightSegmentModalCancel();
    this.handleAddFlightSegment(collectionIndex, addType, includePax);
  }

  cloneSeatAssignments(flightSegment, includePax) {
    const copilotId = get(this.props, 'formValues.copilot_id');
    return cloneDeep(flightSegment.seat_assignments_attributes)
      .filter((sa) => !sa._destroy)
      .map((sa) => ({
        ...omit(sa, ['id']),
        ...(!includePax &&
          (sa.seat_assignment_seat_type === 'pax_only' ||
            (sa.seat_assignment_seat_type === 'copilot_or_pax' && !copilotId)) && {
            pax_name: '',
            seat_assignment_weight: '',
            passenger_id: '',
          }),
      }));
  }

  cloneHoldAssignments(flightSegment) {
    return cloneDeep(flightSegment.hold_assignments_attributes)
      .filter((ha) => !ha._destroy)
      .map((ha) => ({
        ...omit(ha, ['id']),
        hold_assignment_weight: 0,
      }));
  }

  cloneTankAssignments(flightSegment) {
    return cloneDeep(flightSegment.tank_assignments_attributes)
      .filter((ha) => !ha._destroy)
      .map((ta) => ({
        ...omit(ta, ['id']),
        tank_assignment_fuel: 0,
      }));
  }

  cloneAssetAssignments(flightSegment) {
    return cloneDeep(flightSegment.asset_assignments_attributes)
      .filter((aa) => !aa._destroy)
      .map((aa) => ({
        ...omit(aa, ['id']),
      }));
  }

  cloneExternalLoadAssignments(flightSegment) {
    return cloneDeep(flightSegment.external_load_assignments_attributes)
      .filter((ha) => !ha._destroy)
      .map((ta) => ({
        ...omit(ta, ['id']),
        external_load_assignment_weight: 0,
      }));
  }

  handleAddFlightSegment(index, addType, includePax) {
    const newFlightSegments = get(
      this.props,
      'formValues.flight_segments_attributes',
      []
    ).map((fs) => ({
      ...fs,
      take_off_fuel: 0,
      pilot_take_off_fuel: 0,
      pilot_take_off_fuel_adjusted: false,
    }));
    const baseFlightSegment = defaultFlightSegment(
      this.props.currentSettingsBookingCollectionStartDate
    );
    let newIndex = index;
    let newFlightSegment = {};
    if (index > -1) {
      let afterFlightSegment = {};
      let beforeFlightSegment = {};
      let endLocation;
      switch (addType) {
        case 'new':
        // fall through to new below no break
        case 'newBelow':
          newIndex += 1;
          afterFlightSegment = newFlightSegments[index];
          newFlightSegment = {
            ...baseFlightSegment,
            start_at: afterFlightSegment.end_at,
            end_at: afterFlightSegment.end_at,
            start_location_id: afterFlightSegment.end_location_id,
            end_location_id: '',
            seat_assignments_attributes: this.cloneSeatAssignments(
              afterFlightSegment,
              includePax
            ),
            hold_assignments_attributes: this.cloneHoldAssignments(afterFlightSegment),
            tank_assignments_attributes: this.cloneTankAssignments(afterFlightSegment),
            asset_assignments_attributes: this.cloneAssetAssignments(afterFlightSegment),
            external_load_assignments_attributes:
              this.cloneExternalLoadAssignments(afterFlightSegment),
          };
          break;
        case 'return':
          newIndex += 1;
          afterFlightSegment = newFlightSegments[index];
          beforeFlightSegment = first(newFlightSegments.filter((fs) => !fs._destroy));
          newFlightSegment = {
            ...baseFlightSegment,
            start_at: afterFlightSegment.end_at,
            end_at: afterFlightSegment.end_at,
            start_location_id: afterFlightSegment.end_location_id,
            end_location_id: beforeFlightSegment.start_location_id,
            seat_assignments_attributes: this.cloneSeatAssignments(
              afterFlightSegment,
              includePax
            ),
            hold_assignments_attributes: this.cloneHoldAssignments(afterFlightSegment),
            tank_assignments_attributes: this.cloneTankAssignments(afterFlightSegment),
            asset_assignments_attributes: this.cloneAssetAssignments(afterFlightSegment),
            external_load_assignments_attributes:
              this.cloneExternalLoadAssignments(afterFlightSegment),
          };
          endLocation = this.props.locationListQuery.data.find(
            (l) => l.id === beforeFlightSegment.start_location_id
          );
          break;
        case 'newAbove':
          afterFlightSegment = newFlightSegments[index];
          newFlightSegment = {
            ...baseFlightSegment,
            start_at: afterFlightSegment.start_at,
            end_at: afterFlightSegment.start_at,
            start_location_id: '',
            end_location_id: afterFlightSegment.start_location_id,
            seat_assignments_attributes: this.cloneSeatAssignments(
              afterFlightSegment,
              includePax
            ),
            hold_assignments_attributes: this.cloneHoldAssignments(afterFlightSegment),
            tank_assignments_attributes: this.cloneTankAssignments(afterFlightSegment),
            asset_assignments_attributes: this.cloneAssetAssignments(afterFlightSegment),
            external_load_assignments_attributes:
              this.cloneExternalLoadAssignments(afterFlightSegment),
          };
          endLocation = this.props.locationListQuery.data.find(
            (l) => l.id === afterFlightSegment.start_location_id
          );
          break;
        default:
          break;
      }
      if (endLocation) {
        const locationLandingFee = get(endLocation, 'landing_fee');
        newFlightSegment.end_location_landing_fee = locationLandingFee;
        newFlightSegment.end_location_landing_fee_original = locationLandingFee;
        newFlightSegment.oncharge_end_location_landing_fee = !!(
          locationLandingFee && locationLandingFee > 0
        );
      }
    } else {
      newIndex = 0;
      newFlightSegment = baseFlightSegment;
    }
    newFlightSegments.splice(newIndex, 0, newFlightSegment);
    this.props.change('flight_segments_attributes', newFlightSegments);
  }

  handleCriticalLocationModalClicked(index) {
    this.setState({
      addCritical: {
        show: true,
        index,
      },
    });
  }

  handleCriticalLocationModalCancel() {
    this.setState({
      addCritical: {
        show: false,
        index: '',
      },
    });
  }

  handleCriticalLocationModalSubmit(start) {
    const { index } = this.state.addCritical;
    if (start) {
      const currentValue = get(
        this.props.formValues,
        ['flight_segments_attributes', index, 'start_critical'],
        false
      );
      this.props.change(
        ['flight_segments_attributes', index, 'start_critical'].join('.'),
        !currentValue
      );
    } else {
      const currentValue = get(
        this.props.formValues,
        ['flight_segments_attributes', index, 'end_critical'],
        false
      );
      this.props.change(
        ['flight_segments_attributes', index, 'end_critical'].join('.'),
        !currentValue
      );
    }
    this.handleCriticalLocationModalCancel();
  }

  handleStartLocationChanged(index, startLocationId) {
    const newFlightSegments = get(
      this.props,
      'formValues.flight_segments_attributes',
      []
    ).map((fs) => ({
      ...fs,
      take_off_fuel: 0,
      pilot_take_off_fuel: 0,
      pilot_take_off_fuel_adjusted: false,
    }));
    const flightSegment = newFlightSegments[index];
    const currentStartLocationId = defaultTo(
      parseInt(flightSegment.start_location_id, 10),
      ''
    );
    const newStartLocationId = defaultTo(parseInt(startLocationId, 10), '');
    if (currentStartLocationId !== newStartLocationId) {
      flightSegment.start_location_id = newStartLocationId;
      if (newStartLocationId) {
        const prevFlightSegmentIndex = findLastIndex(
          slice(newFlightSegments, 0, index),
          (fs) => !fs._destroy
        );
        if (prevFlightSegmentIndex > -1) {
          const prevFlightSegment = newFlightSegments[prevFlightSegmentIndex];
          prevFlightSegment.end_location_id = newStartLocationId;
          const location = this.props.locationListQuery.data.find(
            (l) => l.id === newStartLocationId
          );
          const locationLandingFee = get(location, 'landing_fee', '');
          prevFlightSegment.end_location_landing_fee = locationLandingFee;
          prevFlightSegment.end_location_landing_fee_original = locationLandingFee;
          prevFlightSegment.oncharge_end_location_landing_fee = !!(
            locationLandingFee && locationLandingFee > 0
          );
        }
      }
    }
    this.props.change('flight_segments_attributes', newFlightSegments);
  }

  handleEndLocationChanged(index, endLocationId) {
    const newFlightSegments = get(
      this.props,
      'formValues.flight_segments_attributes',
      []
    ).map((fs) => ({
      ...fs,
      take_off_fuel: 0,
      pilot_take_off_fuel: 0,
      pilot_take_off_fuel_adjusted: false,
    }));
    const flightSegment = newFlightSegments[index];
    const currentEndLocationId = defaultTo(
      parseInt(flightSegment.end_location_id, 10),
      ''
    );
    const newEndLocationId = defaultTo(parseInt(endLocationId, 10), '');
    if (currentEndLocationId !== newEndLocationId) {
      flightSegment.end_location_id = newEndLocationId;
      if (newEndLocationId) {
        const location = this.props.locationListQuery.data.find(
          (l) => l.id === newEndLocationId
        );
        const locationLandingFee = get(location, 'landing_fee', '');
        flightSegment.end_location_landing_fee = locationLandingFee;
        flightSegment.end_location_landing_fee_original = locationLandingFee;
        flightSegment.oncharge_end_location_landing_fee = !!(
          locationLandingFee && locationLandingFee > 0
        );
        const nextFlightSegmentIndex = findIndex(
          slice(newFlightSegments, index + 1),
          (fs) => !fs._destroy
        );
        if (nextFlightSegmentIndex > -1) {
          const nextFlightSegment = newFlightSegments[nextFlightSegmentIndex + index + 1];
          nextFlightSegment.start_location_id = newEndLocationId;
        }
      }
    }
    this.props.change('flight_segments_attributes', newFlightSegments);
  }

  isOfficeAdmin() {
    return get(this.props, 'currentContact.office_admin?', false);
  }

  isHobbed() {
    return get(this.props.bookingQuery, 'data.hobbRecord.id');
  }

  isAudited() {
    return get(this.props.bookingQuery, 'data.audit_created_at');
  }

  renderFlightSegmentFieldArray(flightSegments) {
    const formKeys = [
      'aircraft_id',
      'aircraft_configuration_id',
      'booking_cruise_airspeed_sl',
      'flight_segments_attributes',
      'pilot_id',
      'copilot_id',
      'start_at',
    ];
    return (
      <FlightSegmentFieldArray
        containerWidth={this.props.containerWidth}
        updating={this.state.updating}
        change={this.props.change}
        flightSegments={flightSegments}
        passengers={this.props.passengerListQuery.data}
        addingPassenger={this.state.addPassenger.show}
        handleAddPassengerModalClicked={this.handleAddPassengerModalClicked}
        handlePassengerChanged={this.handlePassengerChanged}
        locations={this.props.locationListQuery.data}
        handleStartLocationChanged={this.handleStartLocationChanged}
        handleEndLocationChanged={this.handleEndLocationChanged}
        handleCriticalLocationModalClicked={this.handleCriticalLocationModalClicked}
        handleAddFlightSegmentModalClicked={this.handleAddFlightSegmentModalClicked}
        bookingAircraftSeatConfigurations={this.getBookingAircraftSeatConfigurations()}
        bookingAircraftHoldConfigurations={this.getBookingAircraftHoldConfigurations()}
        bookingAircraftTankConfigurations={this.getBookingAircraftTankConfigurations()}
        bookingAircraftAssets={this.getBookingAircraftAssets()}
        bookingExternalLoadPoints={this.getBookingExternalLoadPoints()}
        flightSegmentsCalculatedData={this.getFlightSegmentsCalculatedData()}
        aircraftsDataSelector={this.props.aircraftsDataSelector}
        contactsDataSelector={this.props.contactsDataSelector}
        locationsDataSelector={this.props.locationsDataSelector}
        {...pick(this.props.formValues, formKeys)}
      />
    );
  }

  renderChargeablesFieldArray(bookingChargeables) {
    return (
      <ChargeableInputField
        change={this.props.change}
        chargeables={this.props.chargeableListQuery.data}
        bookingChargeables={bookingChargeables}
        {...pick(this.props.formValues, ['booking_chargeables_attributes'])}
      />
    );
  }

  renderChargeablesPaxFieldArray(bookingChargeables) {
    const formKeys = ['booking_chargeables_attributes'];
    return (
      <ChargeablePaxes
        bookingChargeables={bookingChargeables}
        formValues={pick(this.props.formValues, formKeys)}
        contactsDataSelector={this.props.contactsDataSelector}
      />
    );
  }

  renderFuelBowserFillFieldArray(fuelBowserFills) {
    const formKeys = ['start_at', 'aircraft_id', 'fuel_bowser_fills_attributes'];
    return (
      <FuelBowserFillFieldArray
        change={this.props.change}
        fuelBowserFills={fuelBowserFills}
        flightSegmentsCalculatedData={this.getFlightSegmentsCalculatedData()}
        formValues={pick(this.props.formValues, formKeys)}
        currentSettingsFuelBowserFillDefaultQuantityUnit={
          this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
        }
      />
    );
  }

  renderFuelTankerFillFieldArray(fuelTankerFills) {
    const formKeys = ['start_at', 'aircraft_id', 'fuel_tanker_fills_attributes'];
    return (
      <FuelTankerFillFieldArray
        change={this.props.change}
        fuelTankerFills={fuelTankerFills}
        flightSegmentsCalculatedData={this.getFlightSegmentsCalculatedData()}
        formValues={pick(this.props.formValues, formKeys)}
        currentSettingsFuelBowserFillDefaultQuantityUnit={
          this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
        }
      />
    );
  }

  renderLandingChargesFieldArray(flightSegments) {
    const formKeys = ['flight_segments_attributes'];
    return (
      <LandingCharges
        flightSegments={flightSegments}
        locationsDataSelector={this.props.locationsDataSelector}
        formValues={pick(this.props.formValues, formKeys)}
      />
    );
  }

  renderOilFillsFieldArray(oilFills) {
    const formKeys = ['aircraft_id', 'oil_fills_attributes'];
    return (
      <OilFills
        change={this.props.change}
        oilFills={oilFills}
        aircraftEngines={this.getBookingAircraftEngines()}
        formValues={pick(this.props.formValues, formKeys)}
        currentSettingsFuelBowserFillDefaultQuantityUnit={
          this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
        }
      />
    );
  }

  renderEngineEventsFieldArray(engineEvents) {
    const formKeys = ['aircraft_id', 'engine_events_attributes'];
    return (
      <EngineEvents
        change={this.props.change}
        engineEvents={engineEvents}
        formValues={pick(this.props.formValues, formKeys)}
        currentSettingsEngineEventTypes={this.props.currentSettingsEngineEventTypes}
      />
    );
  }

  renderPilotFlightExpensesFieldArray(pilotFlightExpenses) {
    const formKeys = ['pilot_id', 'copilot_id', 'pilot_flight_expenses_attributes'];
    return (
      <PilotFlightExpenses
        change={this.props.change}
        pilotFlightExpenses={pilotFlightExpenses}
        formValues={pick(this.props.formValues, formKeys)}
        contactsDataSelector={this.props.contactsDataSelector}
        currentSettingsPilotFlightExpenseOvernightText={
          this.props.currentSettingsPilotFlightExpenseOvernightText
        }
        currentSettingsPilotFlightExpenseOvernightRate={
          this.props.currentSettingsPilotFlightExpenseOvernightRate
        }
      />
    );
  }

  renderAuditSnapshotTab() {
    return <BookingAuditSnapshot {...this.props.bookingQuery.data.audit_snapshot} />;
  }

  renderWeightAndBalanceTab() {
    const formValues = get(this.props, 'formValues', {});
    return (
      <BookingWeightAndBalance
        change={this.props.change}
        formValues={formValues}
        locationsDataSelector={this.props.locationsDataSelector}
        flightSegmentsCalculatedData={this.getFlightSegmentsCalculatedData()}
      />
    );
  }

  renderPilotFlightLogTab() {
    const formValues = get(this.props, 'formValues', {});
    return (
      <BookingPilotFlightLog
        change={this.props.change}
        formValues={formValues}
        currentSettingsPilotFlightLogFlightTypes={
          this.props.currentSettingsPilotFlightLogFlightTypes
        }
        aircraftsDataSelector={this.props.aircraftsDataSelector}
        contactsDataSelector={this.props.contactsDataSelector}
      />
    );
  }

  renderAdminFlightRecordTab() {
    const formValues = get(this.props, 'formValues', {});
    return (
      <BookingAdminFlightRecord
        change={this.props.change}
        formValues={formValues}
        isOfficeAdmin={this.isOfficeAdmin()}
        locationsDataSelector={this.props.locationsDataSelector}
        fuelBowsersDataSelector={this.props.fuelBowsersDataSelector}
        fuelTankersDataSelector={this.props.fuelTankersDataSelector}
        contactsDataSelector={this.props.contactsDataSelector}
        currentSettingsFuelBowserFillDefaultQuantityUnit={
          this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
        }
        flightSegmentsCalculatedData={this.getFlightSegmentsCalculatedData()}
      />
    );
  }

  renderFlightPlanTab() {
    const formValues = get(this.props, 'formValues', {});
    return (
      <BookingFlightPlan
        change={this.props.change}
        formValues={formValues}
        aircrafts={this.props.aircraftListQuery.data}
        aircraftTypes={this.props.aircraftTypeListQuery.data}
        aircraftConfigurations={this.props.aircraftConfigurationListQuery.data}
        bookingCalculatedData={this.getBookingCalculatedData()}
        flightSegmentsCalculatedData={this.getFlightSegmentsCalculatedData()}
        locationsDataSelector={this.props.locationsDataSelector}
      />
    );
  }

  renderBookingTab() {
    return (
      <Col sm={12}>
        <FlightSegmentAddModal
          {...this.state.addFlightSegment}
          handleAddFlightSegmentSubmit={this.handleAddFlightSegmentModalSubmit}
          handleAddFlightSegmentCancel={this.handleAddFlightSegmentModalCancel}
        />
        <FlightSegmentLocationCriticalModal
          {...this.state.addCritical}
          handleCriticalLocationSubmit={this.handleCriticalLocationModalSubmit}
          handleCriticalLocationCancel={this.handleCriticalLocationModalCancel}
        />

        <PassengerAddModal
          {...this.state.addPassenger}
          handleAddPassengerSubmit={this.handleAddPassengerModalSubmit}
          handleAddPassengerCancel={this.handleAddPassengerModalCancel}
        />
        <Row className="mt-4">
          <Col md={4}>
            <FieldArray
              name="booking_chargeables_attributes"
              component={this.renderChargeablesFieldArray}
            />
            <Field
              type="text"
              name="provider_id"
              component={ProviderInputField}
              providers={this.props.providerListQuery.data}
              chargeables={this.props.chargeableListQuery.data}
              formValues={pick(this.props.formValues, [
                'booking_chargeables_attributes',
                'provider_id',
              ])}
            >
              Allocate
            </Field>
            <Field
              type="text"
              asElement="select"
              name="status"
              size="sm"
              labelWidth={4}
              inputWidth={8}
              noTab
              component={InputField}
              selectOptions={Object.keys(this.props.currentSettingsBookingStatuses).map(
                (status) => ({
                  id: status,
                  name: status,
                })
              )}
            >
              Status
            </Field>
            <Field
              type="text"
              name="aircraft_id"
              component={AircraftInputField}
              aircrafts={this.props.aircraftListQuery.data}
              providers={this.props.providerListQuery.data}
              formValues={pick(this.props.formValues, ['provider_id', 'aircraft_id'])}
            >
              Aircraft
            </Field>
            <Field
              updating={this.state.updating}
              type="text"
              name="flight_type_id"
              component={FlightTypeInputField}
              asElement="select"
              chargeables={this.props.chargeableListQuery.data}
              aircrafts={this.props.aircraftListQuery.data}
              flightTypes={this.props.flightTypeListQuery.data}
              {...pick(this.props.formValues, [
                'booking_chargeables_attributes',
                'aircraft_id',
              ])}
            >
              Flight Type
            </Field>
            <Field
              type="text"
              name="pilot_id"
              component={PilotInputField}
              pilots={this.props.pilotListQuery.data}
              aircrafts={this.props.aircraftListQuery.data}
              aircraftFlightTypePilotMap={this.props.aircraftFlightTypePilotMapQuery.data}
              {...pick(this.props.formValues, [
                'aircraft_id',
                'flight_type_id',
                'pilot_id',
                'copilot_id',
              ])}
            >
              Pilot
            </Field>
            <Field
              type="text"
              name="copilot_id"
              component={CopilotInputField}
              pilots={this.props.pilotListQuery.data}
              aircraftFlightTypePilotMap={this.props.aircraftFlightTypePilotMapQuery.data}
              {...pick(this.props.formValues, [
                'aircraft_id',
                'flight_type_id',
                'pilot_id',
                'copilot_id',
              ])}
            >
              Copilot/Student
            </Field>
            <Field
              name="aircraft_configuration_id"
              component={AircraftConfigurationInputField}
              change={this.props.change}
              aircrafts={this.props.aircraftListQuery.data}
              aircraftTypes={this.props.aircraftTypeListQuery.data}
              aircraftConfigurations={this.props.aircraftConfigurationListQuery.data}
              formValues={pick(this.props.formValues, [
                'aircraft_id',
                'aircraft_configuration_id',
              ])}
              handleAircraftTypeChange={this.handleAircraftTypeChange}
            >
              Configuration
            </Field>
            <Fields
              names={[
                'booking_caa_weight_loading',
                'booking_caa_weight_loading_included',
                'booking_caa_weight_loading_included_adjusted',
              ]}
              component={BookingCaaWeightLoadingIncludedInputField}
              updating={this.state.updating}
              flightTypes={this.props.flightTypeListQuery.data}
              formValues={pick(this.props.formValues, [
                'flight_type_id',
                'booking_weight_unit',
              ])}
            />
          </Col>
          <Col md={8}>
            <Row>
              <Col sm={6}>
                <Field
                  type="text"
                  name="requested_by"
                  component={InputField}
                  size="sm"
                  labelWidth={4}
                  inputWidth={8}
                  noTab
                  blurOnly
                >
                  Requested By
                </Field>
              </Col>
              <Col sm={6}>
                <Field
                  type="text"
                  name="customer_reference"
                  component={InputField}
                  size="sm"
                  labelWidth={4}
                  inputWidth={8}
                  noTab
                  blurOnly
                >
                  Cust Reference
                </Field>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Field
                  type="text"
                  asElement="textarea"
                  name="job_notes"
                  component={InputField}
                  size="sm"
                  labelWidth={2}
                  inputWidth={10}
                  rows={3}
                  noTab
                  blurOnly
                >
                  Job Notes
                </Field>
              </Col>
              <Col sm={12}>
                <Field
                  type="text"
                  asElement="textarea"
                  name="contact_flight_notes"
                  component={InputField}
                  size="sm"
                  labelWidth={2}
                  inputWidth={10}
                  rows={1}
                  noTab
                  blurOnly
                >
                  Contact Notes
                </Field>
              </Col>
              <Col sm={12}>
                <Field
                  type="text"
                  asElement="textarea"
                  name="pilot_notes"
                  component={InputField}
                  size="sm"
                  labelWidth={2}
                  inputWidth={10}
                  rows={6}
                  noTab
                  blurOnly
                >
                  Pilot Notes
                </Field>
              </Col>
              <Col sm={12}>
                <Field
                  type="text"
                  asElement="textarea"
                  name="public_notes"
                  component={InputField}
                  size="sm"
                  labelWidth={2}
                  inputWidth={10}
                  rows={2}
                  noTab
                  blurOnly
                >
                  Public Notes
                </Field>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <hr style={{ marginTop: 0 }} />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <Field
              type="text"
              name="start_at"
              component={StartDateAndTimeInputFields}
              {...pick(this.props.formValues, ['start_at'])}
            >
              Start Date
            </Field>
          </Col>
        </Row>
        <Row>
          <FieldArray
            name="flight_segments_attributes"
            component={this.renderFlightSegmentFieldArray}
          />
        </Row>
        <Row>
          <Col xs={3}>
            <Field
              type="text"
              name="end_at"
              inputWidth={12}
              component={EndDateAndTimeInputFields}
              {...pick(this.props.formValues, ['end_at', 'start_at'])}
              lastFlightSegmentEndAt={get(last(this.getActiveFlightSegments()), 'end_at')}
            >
              End Date
            </Field>
          </Col>
          <Col xs={2}>
            <InputField
              size="sm"
              labelWidth={0}
              inputWidth={12}
              input={{
                name: 'booking-add-leg',
              }}
              groupClassName="p-0"
              innerContent={
                <>
                  <LabelBlock size="sm">Add Leg</LabelBlock>
                  <div>
                    <Button
                      variant="secondary"
                      onClick={this.handleAddFlightSegmentClicked}
                      tabIndex={-1}
                      style={{ padding: '0' }}
                    >
                      <Glyphicon glyph="plus" />
                    </Button>
                  </div>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <hr />
          </Col>
        </Row>
        <Row>{this.renderFlightData()}</Row>
      </Col>
    );
  }

  renderFlightData() {
    if (this.isAudited()) {
      return (
        <Col sm={12}>
          {this.renderHobbsReport()}
          {this.renderEngineEvents()}
          {this.renderLandingCharges()}
          {this.renderChargeablePax()}
          {this.renderFuelBowserFills()}
          {this.renderFuelTankerFills()}
          {this.renderOilFills()}
          {this.renderPilotDutyRecords()}
          {this.renderCopilotDutyRecord()}
          {this.renderPilotFlightLogSummary()}
          {this.renderPilotFlightExpenses()}
          {this.renderPilotFlightNote()}
        </Col>
      );
    }
    return undefined;
  }

  renderHobbsReport() {
    if (has(this.props, 'formValues.aircraft_id')) {
      const fields = [
        'hobb_record_attributes.start_hobb',
        'hobb_record_attributes.end_hobb',
        'hobb_record_attributes.flight_time',
        'hobb_record_attributes.aircraft_id',
      ];
      return (
        <>
          <Fields
            names={fields}
            component={BookingHobbRecordFields}
            formValues={pick(this.props.formValues, [
              'aircraft_id',
              'hobb_record_attributes',
            ])}
            aircraftCandidateHobbsDataSelector={
              this.props.aircraftCandidateHobbsDataSelector
            }
          />
          <hr />
        </>
      );
    }
    return undefined;
  }

  renderLandingCharges() {
    return (
      <>
        <FieldArray
          name="flight_segments_attributes"
          component={this.renderLandingChargesFieldArray}
        />
        <hr />
      </>
    );
  }

  renderChargeablePax() {
    return (
      <>
        <FieldArray
          name="booking_chargeables_attributes"
          component={this.renderChargeablesPaxFieldArray}
        />
        <hr />
      </>
    );
  }

  renderFuelBowserFills() {
    return (
      <>
        <FieldArray
          name="fuel_bowser_fills_attributes"
          component={this.renderFuelBowserFillFieldArray}
        />
        <hr />
      </>
    );
  }

  renderFuelTankerFills() {
    return (
      <>
        <FieldArray
          name="fuel_tanker_fills_attributes"
          component={this.renderFuelTankerFillFieldArray}
        />
        <hr />
      </>
    );
  }

  renderOilFills() {
    if (this.getBookingAircraftEngines().length > 0) {
      return (
        <>
          <FieldArray
            name="oil_fills_attributes"
            component={this.renderOilFillsFieldArray}
          />
          <hr />
        </>
      );
    }
    return undefined;
  }

  renderEngineEvents() {
    return (
      <>
        <FieldArray
          name="engine_events_attributes"
          component={this.renderEngineEventsFieldArray}
        />
        <hr />
      </>
    );
  }

  renderPilotDutyRecords() {
    if (get(this.props, 'formValues.pilot_duty_record_attributes.id')) {
      const fields = [
        'pilot_duty_record_attributes.duty_start_at',
        'pilot_duty_record_attributes.duty_end_at',
        'pilot_duty_record_attributes.rest_start_at',
        'pilot_duty_record_attributes.rest_end_at',
      ];
      return (
        <div>
          <Fields
            names={fields}
            parentField="pilot_duty_record_attributes"
            component={BookingPilotDutyRecordFields}
            formValues={pick(this.props.formValues, [
              'pilot_id',
              'pilot_duty_record_attributes',
            ])}
            contactsDataSelector={this.props.contactsDataSelector}
            title="Pilot Duty Record"
          />
          <hr />
        </div>
      );
    }
    return undefined;
  }

  renderCopilotDutyRecord() {
    if (get(this.props, 'formValues.copilot_duty_record_attributes.id')) {
      const fields = [
        'copilot_duty_record_attributes.duty_start_at',
        'copilot_duty_record_attributes.duty_end_at',
        'copilot_duty_record_attributes.rest_start_at',
        'copilot_duty_record_attributes.rest_end_at',
      ];
      return (
        <div>
          <Fields
            names={fields}
            parentField="copilot_duty_record_attributes"
            component={BookingPilotDutyRecordFields}
            formValues={pick(this.props.formValues, [
              'copilot_id',
              'copilot_duty_record_attributes',
            ])}
            contactsDataSelector={this.props.contactsDataSelector}
            title="Copilot Duty Record"
          />
          <hr />
        </div>
      );
    }
    return undefined;
  }

  renderPilotFlightLogSummary() {
    const formKeys = [
      'start_at',
      'end_at',
      'end_location_id',
      'pilot_flight_logs_attributes',
      'hobb_record_attributes',
    ];
    return (
      <div>
        <PilotFlightLogSummary
          handleTabClick={this.handleTabClick}
          formValues={pick(this.props.formValues, formKeys)}
          contactsDataSelector={this.props.contactsDataSelector}
          locationsDataSelector={this.props.locationsDataSelector}
        />
        <hr />
      </div>
    );
  }

  renderPilotFlightExpenses() {
    return (
      <>
        <FieldArray
          name="pilot_flight_expenses_attributes"
          component={this.renderPilotFlightExpensesFieldArray}
        />
        <hr />
      </>
    );
  }

  renderPilotFlightNote() {
    const fields = [
      'pilot_flight_note_attributes.pilot_id',
      'pilot_flight_note_attributes.notes',
    ];
    return (
      <Fields
        names={fields}
        component={BookingPilotFlightNoteFields}
        formValues={pick(this.props.formValues, [
          'pilot_id',
          'pilot_flight_note_attributes',
        ])}
      />
    );
  }

  renderTab1() {
    if (this.isAudited()) {
      return (
        <Tab eventKey={1} title="Original Booking">
          {this.renderAuditSnapshotTab()}
        </Tab>
      );
    }
    return undefined;
  }

  renderTab2() {
    let tab;
    let title;
    if (this.isHobbed()) {
      if (this.isOfficeAdmin()) {
        title = 'Flight Plan';
        tab = this.renderFlightPlanTab;
      } else {
        title = 'Flight Plan';
        tab = () => (
          <Row className="mt-3">
            <Col sm={12}>
              <p>Booking hobb report recorded</p>
            </Col>
          </Row>
        );
      }
    } else if (this.isAudited()) {
      title = 'Flight Plan';
      tab = this.renderFlightPlanTab;
    } else {
      title = 'Booking';
      tab = this.renderBookingTab;
    }
    return (
      <Tab eventKey={2} title={title}>
        {tab()}
      </Tab>
    );
  }

  renderTab3() {
    let tab;
    let title;
    if (this.isHobbed()) {
      if (this.isOfficeAdmin()) {
        title = 'Flight Log';
        tab = this.renderBookingTab;
      } else {
        title = 'Flight Log';
        tab = () => (
          <Row className="mt-3">
            <Col sm={12}>
              <p>Booking hobb report recorded</p>
            </Col>
          </Row>
        );
      }
    } else if (this.isAudited()) {
      title = 'Flight Log';
      tab = this.renderBookingTab;
    } else {
      title = 'Flight Plan';
      tab = this.renderFlightPlanTab;
    }

    return (
      <Tab eventKey={3} title={title}>
        {tab()}
      </Tab>
    );
  }

  renderTab4() {
    return (
      <Tab eventKey={4} title="Weight and Balance">
        {this.renderWeightAndBalanceTab()}
      </Tab>
    );
  }

  renderTab5() {
    if (this.isAudited()) {
      return (
        <Tab eventKey={5} title="Pilot Logs">
          {this.renderPilotFlightLogTab()}
        </Tab>
      );
    }
    return undefined;
  }

  renderTab6() {
    if (this.isAudited()) {
      return (
        <Tab
          eventKey={6}
          title="Invoice"
          {...(!this.isOfficeAdmin() && { tabClassName: 'd-none' })}
        >
          {this.renderAdminFlightRecordTab()}
        </Tab>
      );
    }
    return undefined;
  }

  renderSubmitButton() {
    const { handleSubmit, submitting, formValues } = this.props;
    const adminFlightRecordOverweight = get(
      formValues,
      'admin_flight_record_attributes.flight_overweight'
    );
    const adminFlightRecordOutOfBalance = get(
      formValues,
      'admin_flight_record_attributes.flight_out_of_balance'
    );
    if (
      this.isAudited() &&
      (adminFlightRecordOverweight || adminFlightRecordOutOfBalance)
    ) {
      return (
        <Confirm
          disabled={submitting}
          confirmBSStyle="primary"
          cancelBSStyle="danger"
          onConfirm={handleSubmit(this.handleSubmit)}
          title="Submit Out of Balance/Overweight Booking?"
          body={
            <div>
              <p>Are you sure you want to save this booking?</p>
              {adminFlightRecordOverweight && <p>One of more legs is overweight</p>}
              {adminFlightRecordOutOfBalance && <p>One of more legs is out of balance</p>}
            </div>
          }
          confirmText={this.state.updating ? 'Update' : 'Create'}
        >
          <Button type="button" variant="primary" disabled={submitting}>
            {submitting && <Glyphicon glyph="refresh" spin />}{' '}
            {this.state.updating ? 'Update' : 'Create'}
          </Button>
        </Confirm>
      );
    }
    return (
      <Button
        type="button"
        variant="primary"
        disabled={submitting}
        onClick={handleSubmit(this.handleSubmit)}
      >
        {submitting && <Glyphicon glyph="refresh" spin />}{' '}
        {this.state.updating ? 'Update' : 'Create'}
      </Button>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedBookingForm &&
      this.isLoaded() &&
      (!this.state.updating || get(this.props, 'formValues.id'))
    ) {
      const { submitting, error } = this.props;

      return (
        <Form>
          <BookingTitle
            updating={this.state.updating}
            reference={get(this.props, 'bookingQuery.data.reference')}
            startAt={get(this.props, 'bookingQuery.data.start_at')}
            calendarType={get(this.props, 'formValues.calendar_type')}
          />
          {error && <strong>{error}</strong>}
          <Tabs
            id="new-booking-booking"
            activeKey={this.state.tabKey}
            onSelect={this.handleTabClick}
          >
            {this.renderTab1()}
            {this.renderTab2()}
            {this.renderTab3()}
            {this.renderTab4()}
            {this.renderTab5()}
            {this.renderTab6()}
          </Tabs>
          <Row>
            <Col sm={12}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.updating && (
                <BookingCreators
                  contactsDataSelector={this.props.contactsDataSelector}
                  {...pick(get(this.props.bookingQuery, 'data', {}), [
                    'created_by_admin_id',
                    'created_at',
                    'updated_by_admin_id',
                    'updated_at',
                  ])}
                />
              )}
            </Col>
            <Col sm="auto">
              <ButtonGroup>
                <LinkContainer to={this.props.currentSettingsReturnRoute}>
                  <Button type="reset" variant="danger" disabled={submitting}>
                    Cancel
                  </Button>
                </LinkContainer>
                {this.renderSubmitButton()}
              </ButtonGroup>
            </Col>
          </Row>
        </Form>
      );
    }
    return undefined;
  }

  render() {
    return (
      <Row className="mt-3">
        <Col sm={12} className="m-0 p-0">
          {this.renderOverlay()}
          {this.renderData()}
        </Col>
      </Row>
    );
  }
}

const bookingWhiteList = [
  'admin_completed_at',
  'admin_completed_by_admin_id',
  'aircraft_configuration_id',
  'aircraft_id',
  // 'aircraft_provider_id',
  // 'audit_booking_id',
  'audit_created_by_admin_id',
  'calendar_type',
  'booking_planned_reserve',
  'contact_flight_notes',
  // 'copilot_duty_record_id',
  'copilot_id',
  'booking_caa_weight_loading',
  'booking_caa_weight_loading_included',
  'booking_caa_weight_loading_included_adjusted',
  'booking_cruise_airspeed_sl',
  'booking_cruise_fuel_consumption_sl',
  'customer_reference',
  'end_at',
  'end_location_id',
  'flight_type_id',
  'id',
  'job_notes',
  // 'pilot_duty_record_id',
  'pilot_id',
  'pilot_notes',
  'provider_id',
  'public_notes',
  'requested_by',
  'start_at',
  'start_location_id',
  'status',
  'booking_arm_unit',
  'booking_weight_unit',
  'booking_fuel_unit',
  'booking_airspeed_unit',
  'booking_aircraft_weight',
  'booking_aircraft_weight_defined',
  'booking_aircraft_lateral_arm',
  'booking_aircraft_longitudinal_arm',
];

const flightSegmentWhiteList = [
  'airspeed_adj',
  'booking_id',
  'distance_adj',
  'take_off_fuel',
  'pilot_take_off_fuel',
  'pilot_take_off_fuel_adjusted',
  'end_at',
  'end_critical',
  'end_location_id',
  'end_location_landing_fee',
  'end_location_landing_fee_original',
  'id',
  'luggage_weight',
  'oncharge_end_location_landing_fee',
  'start_at',
  'start_critical',
  'start_location_id',
];

const seatAssignmentWhiteList = [
  'id',
  'name',
  'position',
  'flight_segment_id',
  'aircraft_seat_seat_configuration_id',
  'passenger_id',
  'pax_name',
  'seat_assignment_weight',
  'seat_assignment_installed',
  'seat_assignment_installed_weight_increase',
  'seat_assignment_uninstalled_weight_reduction',
  'seat_assignment_longitudinal_arm',
  'seat_assignment_lateral_arm',
  'seat_assignment_weight_unit',
  'seat_assignment_arm_unit',
  'seat_assignment_seat_type',
];

const holdAssignmentWhiteList = [
  'id',
  'name',
  'position',
  'flight_segment_id',
  'aircraft_hold_hold_configuration_id',
  'hold_assignment_installed',
  'hold_assignment_weight_unit',
  'hold_assignment_weight',
  'hold_assignment_maximum_weight',
  'hold_assignment_installed_weight_increase',
  'hold_assignment_uninstalled_weight_reduction',
  'hold_assignment_arm_unit',
  'hold_assignment_longitudinal_arm',
  'hold_assignment_lateral_arm',
];

const tankAssignmentWhiteList = [
  'id',
  'name',
  'position',
  'flight_segment_id',
  'aircraft_tank_tank_configuration_id',
  'tank_assignment_fuel_unit',
  'tank_assignment_fuel',
  'tank_assignment_maximum_fuel',
  'tank_assignment_arm_unit',
  'tank_assignment_longitudinal_arm',
  'tank_assignment_lateral_arm',
];

const assetAssignmentWhiteList = [
  'id',
  'name',
  'flight_segment_id',
  'aircraft_asset_aircraft_configuration_id',
  'asset_assignment_installed',
  'asset_assignment_installed_weight_increase',
  'asset_assignment_uninstalled_weight_reduction',
  'asset_assignment_longitudinal_arm',
  'asset_assignment_lateral_arm',
  'asset_assignment_weight_unit',
  'asset_assignment_arm_unit',
];

const externalLoadAssignmentWhiteList = [
  'id',
  'name',
  'flight_segment_id',
  'external_load_point_id',
  'external_load_assignment_installed',
  'external_load_assignment_weight',
  'external_load_assignment_longitudinal_arm',
  'external_load_assignment_lateral_arm',
  'external_load_assignment_weight_unit',
  'external_load_assignment_arm_unit',
];

function validate() {
  return {};
}

function pickInitialValues(
  bookingQueryInitial,
  currentSettingsBookingCollectionStartDate,
  updating
) {
  if (!isInitialisedBookingForm) {
    if (!updating) {
      isInitialisedBookingForm = true;
      return defaultBooking(currentSettingsBookingCollectionStartDate);
    }
    if (queryReady(bookingQueryInitial)) {
      isInitialisedBookingForm = true;

      const bookingQueryInitialData = cloneDeep(bookingQueryInitial.data);
      const booking = pickValues(bookingQueryInitialData, bookingWhiteList);

      booking.flight_segments_attributes = get(
        bookingQueryInitialData,
        'flightSegments'
      ).map((flightSegment) => {
        const newFlightSegment = pickValues(flightSegment, flightSegmentWhiteList);
        const newSeatAssignments = mapPickValues(
          cloneDeep(flightSegment.seatAssignments),
          seatAssignmentWhiteList
        );
        const newHoldAssignments = mapPickValues(
          cloneDeep(flightSegment.holdAssignments),
          holdAssignmentWhiteList
        );
        const newTankAssignments = mapPickValues(
          cloneDeep(flightSegment.tankAssignments),
          tankAssignmentWhiteList
        );
        const newAssetAssignments = mapPickValues(
          cloneDeep(flightSegment.assetAssignments),
          assetAssignmentWhiteList
        );
        const newExternalLoadAssignments = mapPickValues(
          cloneDeep(flightSegment.externalLoadAssignments),
          externalLoadAssignmentWhiteList
        );
        return {
          ...newFlightSegment,
          seat_assignments_attributes: newSeatAssignments,
          hold_assignments_attributes: newHoldAssignments,
          tank_assignments_attributes: newTankAssignments,
          asset_assignments_attributes: newAssetAssignments,
          external_load_assignments_attributes: newExternalLoadAssignments,
        };
      });

      booking.pilot_flight_logs_attributes = get(
        bookingQueryInitialData,
        'pilotFlightLogs'
      ).map((pilotFlightLog) =>
        mergeWith(
          omitValues(pilotFlightLog),
          { flight_types: omitValues(pilotFlightLog.flight_types, ['id', '__typename']) },
          (objValue, srcValue, key) => {
            if (key === 'flight_types') {
              return srcValue;
            }
            return undefined;
          }
        )
      );
      booking.admin_flight_record_attributes = omitValues(
        get(bookingQueryInitialData, 'adminFlightRecord')
      );
      booking.booking_chargeables_attributes = mapOmitValues(
        get(bookingQueryInitialData, 'bookingChargeables')
      );
      booking.copilot_duty_record_attributes = omitValues(
        get(bookingQueryInitialData, 'copilotDutyRecord'),
        ['__typename', 'pilot', 'pilotBookings', 'copilotBookings']
      );
      booking.engine_events_attributes = mapOmitValues(
        get(bookingQueryInitialData, 'engineEvents')
      );
      booking.fuel_bowser_fills_attributes = mapOmitValues(
        get(bookingQueryInitialData, 'fuelBowserFills')
      );
      booking.fuel_tanker_fills_attributes = mapOmitValues(
        get(bookingQueryInitialData, 'fuelTankerFills')
      );
      booking.hobb_record_attributes = omitValues(
        get(bookingQueryInitialData, 'hobbRecord')
      );
      booking.oil_fills_attributes = mapOmitValues(
        get(bookingQueryInitialData, 'oilFills')
      );
      booking.pilot_duty_record_attributes = omitValues(
        get(bookingQueryInitialData, 'pilotDutyRecord'),
        ['__typename', 'pilot', 'pilotBookings', 'copilotBookings']
      );
      booking.pilot_flight_expenses_attributes = mapOmitValues(
        get(bookingQueryInitialData, 'pilotFlightExpenses')
      );
      booking.pilot_flight_note_attributes = omitValues(
        get(bookingQueryInitialData, 'pilotFlightNote')
      );

      return booking;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(
    props.bookingQuery,
    state.currentSettings.bookingCollectionStartDate,
    !!props.params.id
  );
  return {
    initialValues,
    currentSettingsBookingCollectionStartDate:
      state.currentSettings.bookingCollectionStartDate,
    currentContact: state.currentContact,
    currentSettingsBookingStatuses: state.currentSettings.bookingStatuses,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsReturnRoute: state.currentSettings.returnRoute,
    currentSettingsFuelBowserFillDefaultQuantityUnit:
      state.currentSettings.fuel_bowser_fill_default_quantity_unit,
    currentSettingsPilotFlightExpenseOvernightText:
      state.currentSettings.pilot_flight_expense_overnight_text,
    currentSettingsPilotFlightExpenseOvernightRate:
      state.currentSettings.pilot_flight_expense_overnight_rate,
    currentSettingsEngineEventTypes: state.currentSettings.engine_event_types,
    currentSettingsPilotFlightLogFlightTypes:
      state.currentSettings.pilot_flight_log_flight_types,
    aircraftsDataSelector: aircraftsData(props),
    aircraftCandidateHobbsDataSelector: aircraftCandidateHobbsData(props),
    fuelBowsersDataSelector: fuelBowsersData(props),
    fuelTankersDataSelector: fuelTankersData(props),
    locationsDataSelector: locationsData(props),
    contactsDataSelector: contactsData(props),
    formValues: getFormValues('FlightForm')(state),
    containerWidth: state.currentSettings.containerWidth,
  };
}

export default compose(
  graphql(bookingCreateMutation, {
    name: 'bookingCreateMutation',
  }),
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(contactCreateMutation, {
    name: 'contactCreateMutation',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(aircraftConfigurationListQuery, {
    name: 'aircraftConfigurationListQuery',
  }),
  graphql(aircraftEngineListQuery, {
    name: 'aircraftEngineListQuery',
  }),
  graphql(aircraftTypeListQuery, {
    name: 'aircraftTypeListQuery',
  }),
  graphql(aircraftCandidateHobbListQuery, {
    name: 'aircraftCandidateHobbListQuery',
  }),
  graphql(aircraftFlightTypePilotMapQuery, {
    name: 'aircraftFlightTypePilotMapQuery',
  }),
  graphql(contactListQuery, {
    name: 'chargeableListQuery',
    options: { variables: { role: 'chargeable' } },
  }),
  graphql(contactListQuery, {
    name: 'providerListQuery',
    options: { variables: { role: 'provider' } },
  }),
  graphql(contactListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(contactListQuery, {
    name: 'passengerListQuery',
    options: { variables: { role: 'passenger' } },
  }),
  graphql(flightTypeListQuery, {
    name: 'flightTypeListQuery',
  }),
  graphql(fuelTankerListQuery, {
    name: 'fuelTankerListQuery',
  }),
  graphql(fuelBowserListQuery, {
    name: 'fuelBowserListQuery',
  }),
  graphql(fuelTypeListQuery, {
    name: 'fuelTypeListQuery',
  }),
  graphql(locationListQuery, {
    name: 'locationListQuery',
  }),
  graphql(roleListQuery, {
    name: 'roleListQuery',
  }),
  graphql(bookingQuery, {
    name: 'bookingQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'FlightForm',
    validate,
  })
)(FlightForm);
