import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash.get';
import last from 'lodash.last';

import SeatAssignment from './seat_assignment';

class SeatAssignments extends Component {
  render() {
    const seatLength = get(
      this.props,
      ['formValues', 'seat_assignments_attributes'],
      []
    ).filter((sa) => !sa._destroy && sa.seat_assignment_installed).length;
    if (seatLength > 0) {
      let width;
      if (!this.props.isXs) {
        width = `${Math.round((99.9 / seatLength) * 1000) / 1000}`;
        width = `${Math.min(width, 15)}%`;
      }
      return (
        <Row style={{ flexWrap: 'nowrap' }}>
          {this.props.seatAssignments.fields.map((field, index) => {
            const seatAssignmentField = last(field.split('.'));
            const values = get(this.props, `formValues.${seatAssignmentField}`, {});
            if (!values._destroy && values.seat_assignment_installed) {
              return (
                <SeatAssignment
                  key={`sa-${seatAssignmentField}`}
                  bookingPilotId={this.props.bookingPilotId}
                  bookingCopilotId={this.props.bookingCopilotId}
                  formValues={values}
                  seatAssignmentIndex={index}
                  flightSegmentIndex={this.props.flightSegmentIndex}
                  field={field}
                  width={width}
                  selectablePassengers={this.props.selectablePassengers}
                  addingPassenger={this.props.addingPassenger}
                  handleAddPassengerModalClicked={
                    this.props.handleAddPassengerModalClicked
                  }
                  handlePassengerChanged={this.props.handlePassengerChanged}
                />
              );
            }
            return undefined;
          })}
        </Row>
      );
    }
    return (
      <Row>
        <Col sm={12} className="px-1">
          <div className="center-block" style={{ padding: '20px 0 20px 0' }}>
            <p className="text-center">Select aircraft and configuration</p>
          </div>
        </Col>
      </Row>
    );
  }
}

export default SeatAssignments;
