import makeValidator from './make_validator';

const aircraftFormSchema = {
  type: 'object',
  required: ['aircraft_weight', 'aircraft_lateral_arm', 'aircraft_longitudinal_arm'],
  properties: {
    aircraft_weight: { type: 'number', exclusiveMinimum: 0 },
    aircraft_longitudinal_arm: { type: 'number' },
    aircraft_lateral_arm: { type: 'number' },
  },
};

export const aircraftFormValidator = makeValidator(aircraftFormSchema);
