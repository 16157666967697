import memoize from 'lodash.memoize';

import { LocationCoordinatesMemo } from './location_coordinates_memo';

export const LocationsDistanceMemo = memoize(
  ({ locationIdA, locationIdB, locations }) => {
    if (locationIdA && locationIdB) {
      const locationACoordinates = LocationCoordinatesMemo(locationIdA, locations);
      const locationBCoordinates = LocationCoordinatesMemo(locationIdB, locations);
      let distance = 0;
      // todo check this out.  Why is leaflet on the window
      const startLatlng = window.L.latLng(
        locationACoordinates.locationLatitude,
        locationACoordinates.locationLongitude
      );
      const endLatlng = window.L.latLng(
        locationBCoordinates.locationLatitude,
        locationBCoordinates.locationLongitude
      );
      if (startLatlng && endLatlng) {
        distance = startLatlng.distanceTo(endLatlng);
      }
      return distance;
    }
    return 0;
  },
  ({ locationIdA, locationIdB }) => {
    if (locationIdA && locationIdB) {
      return [locationIdA, locationIdB].map((i) => String(i)).join('.');
    }
    return 'unknown';
  }
);
