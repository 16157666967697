import { Row, Col, Card, Accordion } from 'react-bootstrap';
import { Component } from 'react';

import { Field, Fields, FieldArray } from 'redux-form';

import clone from 'lodash.clone';
import defaultTo from 'lodash.defaultto';
import first from 'lodash.first';
import get from 'lodash.get';
import last from 'lodash.last';
import pick from 'lodash.pick';
import uniqBy from 'lodash.uniqby';

import { convertFromTo, convertFuelToFuel } from '../../lib/convert_units';

import SeatAssignments from './seat_assignments';
import FlightSegmentCardHeader from './flight_segment_panel_header';
import FlightSegmentLuggageWeightField from './flight_segment_luggage_weight_field';
import FlightSegmentStartDateLocationAndTimeInputFields from './flight_segment_start_date_location_and_time_input_fields';
import FlightSegmentEndDateLocationAndTimeInputFields from './flight_segment_end_date_location_and_time_input_fields';
import InputField from '../form/input_field';
import CardHeaderToggle from '../card_header_toggle';

class FlightSegment extends Component {
  constructor(props) {
    super(props);
    this.renderCardHeader = this.renderCardHeader.bind(this);
    this.renderSeatAssignmentsFieldArray =
      this.renderSeatAssignmentsFieldArray.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO what happens if change to a configuration with no copilot seat and
    // a copilot_id?
    /* eslint-disable camelcase */
    const currentAircraftConfigurationId = defaultTo(
      parseInt(get(this.props, 'bookingAircraftConfigurationId'), 10),
      ''
    );
    const nextAircraftConfigurationId = defaultTo(
      parseInt(get(nextProps, 'bookingAircraftConfigurationId'), 10),
      ''
    );
    const nextPilotId = defaultTo(parseInt(get(nextProps, 'bookingPilotId'), 10), '');
    const nextCopilotId = defaultTo(parseInt(get(nextProps, 'bookingCopilotId'), 10), '');
    const nextSeatAssignmentsAttributes =
      nextProps.formValues.seat_assignments_attributes;
    const nextHoldAssignmentsAttributes =
      nextProps.formValues.hold_assignments_attributes;
    const nextTankAssignmentsAttributes =
      nextProps.formValues.tank_assignments_attributes;
    const nextAssetAssignmentsAttributes =
      nextProps.formValues.asset_assignments_attributes;
    const nextExternalLoadAssignmentsAttributes =
      nextProps.formValues.external_load_assignments_attributes;
    const currentLuggageWeight = defaultTo(
      parseInt(get(this.props, 'formValues.luggage_weight'), 10),
      0
    );
    const nextLuggageWeight = defaultTo(
      parseInt(get(nextProps, 'formValues.luggage_weight'), 10),
      0
    );
    const currentPilotTakeOffFuel = defaultTo(
      parseFloat(get(this.props, 'formValues.pilot_take_off_fuel')),
      0
    );
    const nextPilotTakeOffFuel = defaultTo(
      parseFloat(get(nextProps, 'formValues.pilot_take_off_fuel')),
      0
    );
    const { aircraftTypeFuelTypeSpecificGravity, bookingWeightUnit, bookingFuelUnit } =
      nextProps.flightSegmentCalculatedData;

    if (currentAircraftConfigurationId !== nextAircraftConfigurationId) {
      if (!nextAircraftConfigurationId) {
        const newAssetAssignments = nextAssetAssignmentsAttributes
          .filter((aa) => !!aa.id)
          .map((aa) => ({ ...aa, _destroy: true }));
        const newSeatAssignments = nextSeatAssignmentsAttributes
          .filter((sa) => !!sa.id)
          .map((sa) => ({ ...sa, _destroy: true }));
        const newHoldAssignments = nextHoldAssignmentsAttributes
          .filter((ha) => !!ha.id)
          .map((ha) => ({ ...ha, _destroy: true }));
        const newTankAssignments = nextTankAssignmentsAttributes
          .filter((ta) => !!ta.id)
          .map((ta) => ({ ...ta, _destroy: true }));
        const newExternalLoadAssignments = nextExternalLoadAssignmentsAttributes
          .filter((ha) => !!ha.id)
          .map((ha) => ({ ...ha, _destroy: true }));
        const newFlightSegment = {
          ...nextProps.formValues,
          asset_assignments_attributes: newAssetAssignments,
          seat_assignments_attributes: newSeatAssignments,
          hold_assignments_attributes: newHoldAssignments,
          tank_assignments_attributes: newTankAssignments,
          external_load_assignments_attributes: newExternalLoadAssignments,
        };
        this.props.change(this.props.field, newFlightSegment);
      } else {
        const deleteableAssetAssignments = nextAssetAssignmentsAttributes
          .filter((aa) => !!aa.id)
          .map((aa) => ({ ...aa, _destroy: true }));
        const newAssetAssignments = nextProps.bookingAircraftAssets.map(
          (bookingAircraftAsset) => {
            const {
              id: aircraftAssetAircraftConfigurationId,
              aircraftAsset: {
                name: aircraftAssetName,
                default_asset_installed: aircraftAssetDefaultAssetInstalled,
                weight_unit: aircraftAssetWeightUnit,
                asset_installed_weight_increase: aircraftAssetInstalledWeightIncrease,
                asset_uninstalled_weight_reduction:
                  aircraftAssetUninstalledWeightReduction,
                arm_unit: aircraftAssetArmUnit,
                asset_longitudinal_arm: aircraftAssetLongitudinalArm,
                asset_lateral_arm: aircraftAssetLateralArm,
              },
            } = bookingAircraftAsset;

            const newAssetAssignment = {
              aircraft_asset_aircraft_configuration_id:
                aircraftAssetAircraftConfigurationId,
              name: aircraftAssetName,
              asset_assignment_installed: aircraftAssetDefaultAssetInstalled,
              asset_assignment_weight_unit: aircraftAssetWeightUnit,
              asset_assignment_installed_weight_increase:
                aircraftAssetInstalledWeightIncrease,
              asset_assignment_uninstalled_weight_reduction:
                aircraftAssetUninstalledWeightReduction,
              asset_assignment_arm_unit: aircraftAssetArmUnit,
              asset_assignment_longitudinal_arm: aircraftAssetLongitudinalArm,
              asset_assignment_lateral_arm: aircraftAssetLateralArm,
            };
            return newAssetAssignment;
          }
        );

        // TODO seat assignments convert from contactWeightUnit to seatWeightUnit
        // when setting the passenger weight
        // const contactWeightUnit = 'kg';
        const paxOnlySeatAssignments = nextSeatAssignmentsAttributes.filter(
          (sa) =>
            (!('seat_assignment_seat_type' in sa) ||
              sa.seat_assignment_seat_type === 'pax_only') &&
            !!sa.pax_name
        );
        const uniqPaxOnlySeatAssignments = uniqBy(
          paxOnlySeatAssignments,
          (sa) => sa.pax_name
        );
        const deleteableSeatAssignments = nextSeatAssignmentsAttributes
          .filter((sa) => !!sa.id)
          .map((sa) => ({ ...sa, _destroy: true }));
        const newSeatAssignments = nextProps.bookingAircraftSeatConfigurations.map(
          (bookingAircraftSeatConfiguration) => {
            const {
              id: aircraftSeatSeatConfigurationId,
              position: aircraftSeatPosition,
              seat_installed: aircraftSeatInstalled,
              aircraftSeat: {
                name: aircraftSeatName,
                seat_type: aircraftSeatSeatType,
                weight_unit: aircraftSeatWeightUnit,
                seat_installed_weight_increase: aircraftSeatInstalledWeightIncrease,
                seat_uninstalled_weight_reduction: aircraftSeatUninstalledWeightReduction,
                arm_unit: aircraftSeatArmUnit,
                seat_longitudinal_arm: aircraftSeatLongitudinalArm,
                seat_lateral_arm: aircraftSeatLateralArm,
              },
            } = bookingAircraftSeatConfiguration;

            const newSeatAssignment = {
              name: aircraftSeatName,
              position: aircraftSeatPosition,
              aircraft_seat_seat_configuration_id: aircraftSeatSeatConfigurationId,
              seat_assignment_seat_type: aircraftSeatSeatType,
              seat_assignment_installed: aircraftSeatInstalled,
              seat_assignment_arm_unit: aircraftSeatArmUnit,
              seat_assignment_longitudinal_arm: aircraftSeatLongitudinalArm,
              seat_assignment_lateral_arm: aircraftSeatLateralArm,
              seat_assignment_weight_unit: aircraftSeatWeightUnit,
              seat_assignment_installed_weight_increase:
                aircraftSeatInstalledWeightIncrease,
              seat_assignment_uninstalled_weight_reduction:
                aircraftSeatUninstalledWeightReduction,
              seat_assignment_weight: 0,
              pax_name: '',
              passenger_id: '',
            };
            // TODO when assigning passengers and pilots to seats,
            // need to convert from Contact (assumed) weight unit, to seat assignment weight unit
            if (aircraftSeatInstalled) {
              if (aircraftSeatSeatType === 'pilot_only' && nextPilotId) {
                const bookingPilot = get(nextProps.contactsDataSelector, [nextPilotId]);
                if (bookingPilot) {
                  newSeatAssignment.seat_assignment_weight = bookingPilot.weight;
                  newSeatAssignment.pax_name = bookingPilot.fullName;
                  newSeatAssignment.passenger_id = nextPilotId;
                }
              }
              if (aircraftSeatSeatType === 'copilot_only' && nextCopilotId) {
                const bookingCopilot = get(nextProps.contactsDataSelector, [
                  nextCopilotId,
                ]);
                if (bookingCopilot) {
                  newSeatAssignment.seat_assignment_weight = bookingCopilot.weight;
                  newSeatAssignment.pax_name = bookingCopilot.fullName;
                  newSeatAssignment.passenger_id = nextCopilotId;
                }
              }
              if (aircraftSeatSeatType === 'copilot_or_pax') {
                if (nextCopilotId) {
                  const bookingCopilot = get(nextProps.contactsDataSelector, [
                    nextCopilotId,
                  ]);
                  if (bookingCopilot) {
                    newSeatAssignment.seat_assignment_weight = bookingCopilot.weight;
                    newSeatAssignment.pax_name = bookingCopilot.fullName;
                    newSeatAssignment.passenger_id = nextCopilotId;
                  }
                } else {
                  const currentCopilotOrPaxSeat = nextSeatAssignmentsAttributes.find(
                    (sa) => sa.seat_assignment_seat_type === 'copilot_or_pax'
                  );
                  if (currentCopilotOrPaxSeat && currentCopilotOrPaxSeat.pax_name) {
                    newSeatAssignment.seat_assignment_weight =
                      currentCopilotOrPaxSeat.seat_assignment_weight;
                    newSeatAssignment.pax_name = currentCopilotOrPaxSeat.pax_name;
                    newSeatAssignment.passenger_id = currentCopilotOrPaxSeat.passenger_id;
                  }
                }
              }
              if (aircraftSeatSeatType === 'pax_only') {
                const currentPaxOnlySeat = uniqPaxOnlySeatAssignments.shift();
                if (currentPaxOnlySeat) {
                  newSeatAssignment.seat_assignment_weight =
                    currentPaxOnlySeat.seat_assignment_weight;
                  newSeatAssignment.pax_name = currentPaxOnlySeat.pax_name;
                  newSeatAssignment.passenger_id = currentPaxOnlySeat.passenger_id;
                }
              }
            }
            return newSeatAssignment;
          }
        );
        const deleteableHoldAssignments = nextHoldAssignmentsAttributes
          .filter((ha) => !!ha.id)
          .map((ha) => ({ ...ha, _destroy: true }));
        let nextLuggageWeightRemainder = nextLuggageWeight;
        const newHoldAssignments = nextProps.bookingAircraftHoldConfigurations.map(
          (bookingAircraftHoldConfiguration) => {
            const {
              id: aircraftHoldHoldConfigurationId,
              position: aircraftHoldPosition,
              hold_installed: aircraftHoldInstalled,
              aircraftHold: {
                name: aircraftHoldName,
                weight_unit: aircraftHoldWeightUnit,
                hold_maximum_weight: aircraftHoldMaximumWeight,
                hold_installed_weight_increase: aircraftHoldInstalledWeightIncrease,
                hold_uninstalled_weight_reduction: aircraftHoldUninstalledWeightReduction,
                arm_unit: aircraftHoldArmUnit,
                hold_longitudinal_arm: aircraftHoldLongitudinalArm,
                hold_lateral_arm: aircraftHoldLateralArm,
              },
            } = bookingAircraftHoldConfiguration;

            const newHoldAssignment = {
              name: aircraftHoldName,
              position: aircraftHoldPosition,
              aircraft_hold_hold_configuration_id: aircraftHoldHoldConfigurationId,
              hold_assignment_installed: aircraftHoldInstalled,
              hold_assignment_arm_unit: aircraftHoldArmUnit,
              hold_assignment_longitudinal_arm: aircraftHoldLongitudinalArm,
              hold_assignment_lateral_arm: aircraftHoldLateralArm,
              hold_assignment_weight_unit: aircraftHoldWeightUnit,
              hold_assignment_maximum_weight: aircraftHoldMaximumWeight,
              hold_assignment_installed_weight_increase:
                aircraftHoldInstalledWeightIncrease,
              hold_assignment_uninstalled_weight_reduction:
                aircraftHoldUninstalledWeightReduction,
              hold_assignment_weight: 0,
            };
            if (aircraftHoldInstalled) {
              if (nextLuggageWeightRemainder > 0) {
                const convertedNextLuggageWeightRemainder = parseInt(
                  convertFromTo(
                    nextLuggageWeightRemainder,
                    bookingWeightUnit,
                    aircraftHoldWeightUnit
                  ),
                  10
                );
                if (aircraftHoldMaximumWeight > 0) {
                  newHoldAssignment.hold_assignment_weight = Math.min(
                    convertedNextLuggageWeightRemainder,
                    aircraftHoldMaximumWeight
                  );
                } else {
                  newHoldAssignment.hold_assignment_weight =
                    convertedNextLuggageWeightRemainder;
                }
                nextLuggageWeightRemainder -= convertFromTo(
                  newHoldAssignment.hold_assignment_weight,
                  aircraftHoldWeightUnit,
                  bookingWeightUnit
                );
              }
            }
            return newHoldAssignment;
          }
        );
        const deleteableTankAssignments = nextTankAssignmentsAttributes
          .filter((ta) => !!ta.id)
          .map((ta) => ({ ...ta, _destroy: true }));

        let nextTakeOffFuelRemainder = nextPilotTakeOffFuel;
        const newTankAssignments = nextProps.bookingAircraftTankConfigurations.map(
          (bookingAircraftTankConfiguration) => {
            let tankAssignmentFuel = 0;
            let tankAssignmentLongitudinalArm = 0;
            let tankAssignmentLateralArm = 0;
            const {
              id: aircraft_tank_tank_configuration_id,
              position,
              aircraftTank: {
                name: aircraftTankName,
                fuel_unit: aircraftTankFuelUnit,
                tank_maximum_fuel: aircraftTankMaximumFuel,
                arm_unit: aircraftTankArmUnit,
                aircraftTankPoints,
              },
            } = bookingAircraftTankConfiguration;
            if (nextTakeOffFuelRemainder > 0) {
              const convertedNextTakeOffFuelRemainder = convertFuelToFuel({
                fuelQuantity: nextTakeOffFuelRemainder,
                fromFuelUnit: bookingFuelUnit,
                toFuelUnit: aircraftTankFuelUnit,
                specificGravity: aircraftTypeFuelTypeSpecificGravity,
              });

              if (aircraftTankMaximumFuel > 0) {
                tankAssignmentFuel = Math.min(
                  convertedNextTakeOffFuelRemainder,
                  aircraftTankMaximumFuel
                );
              } else {
                tankAssignmentFuel = convertedNextTakeOffFuelRemainder;
              }
              let tankPoint = first(
                clone(aircraftTankPoints)
                  .sort((a, b) => a.tank_point_fuel - b.tank_point_fuel)
                  .filter((tp) => tp.tank_point_fuel >= tankAssignmentFuel)
              );
              if (!tankPoint) {
                tankPoint = last(
                  clone(aircraftTankPoints).sort(
                    (a, b) => a.tank_point_fuel - b.tank_point_fuel
                  )
                );
              }
              tankAssignmentLateralArm = tankPoint.tank_point_lateral_arm;
              tankAssignmentLongitudinalArm = tankPoint.tank_point_longitudinal_arm;

              nextTakeOffFuelRemainder -= convertFuelToFuel({
                fuelQuantity: tankAssignmentFuel,
                fromFuelUnit: aircraftTankFuelUnit,
                toFuelUnit: bookingFuelUnit,
                specificGravity: aircraftTypeFuelTypeSpecificGravity,
              });
            }
            const newTankAssignment = {
              name: aircraftTankName,
              position,
              aircraft_tank_tank_configuration_id,
              tank_assignment_fuel_unit: aircraftTankFuelUnit,
              tank_assignment_fuel: tankAssignmentFuel,
              tank_assignment_maximum_fuel: aircraftTankMaximumFuel,
              tank_assignment_arm_unit: aircraftTankArmUnit,
              tank_assignment_longitudinal_arm: tankAssignmentLongitudinalArm,
              tank_assignment_lateral_arm: tankAssignmentLateralArm,
            };
            return newTankAssignment;
          }
        );

        const deleteableExternalLoadAssignments = nextExternalLoadAssignmentsAttributes
          .filter((el) => !!el.id)
          .map((el) => ({ ...el, _destroy: true }));
        const newExternalLoadAssignments = nextProps.bookingExternalLoadPoints.map(
          (bookingExternalLoadPoint) => {
            const {
              id: externalLoadPointId,
              name: externalLoadPointName,
              weight_unit: externalLoadPointWeightUnit,
              arm_unit: externalLoadPointArmUnit,
              external_load_point_longitudinal_arm: externalLoadPointLongitudinalArm,
              external_load_point_lateral_arm: externalLoadPointLateralArm,
            } = bookingExternalLoadPoint;

            const newExternalLoadAssignment = {
              external_load_point_id: externalLoadPointId,
              name: externalLoadPointName,
              external_load_assignment_weight_unit: externalLoadPointWeightUnit,
              external_load_assignment_arm_unit: externalLoadPointArmUnit,
              external_load_assignment_longitudinal_arm: externalLoadPointLongitudinalArm,
              external_load_assignment_lateral_arm: externalLoadPointLateralArm,
              external_load_assignment_weight: 0,
            };
            return newExternalLoadAssignment;
          }
        );
        const newFlightSegment = {
          ...nextProps.formValues,
          asset_assignments_attributes: [
            ...deleteableAssetAssignments,
            ...newAssetAssignments,
          ],
          seat_assignments_attributes: [
            ...deleteableSeatAssignments,
            ...newSeatAssignments,
          ],
          hold_assignments_attributes: [
            ...deleteableHoldAssignments,
            ...newHoldAssignments,
          ],
          tank_assignments_attributes: [
            ...deleteableTankAssignments,
            ...newTankAssignments,
          ],
          external_load_assignments_attributes: [
            ...deleteableExternalLoadAssignments,
            ...newExternalLoadAssignments,
          ],
        };
        this.props.change(this.props.field, newFlightSegment);
      }
    }
    if (nextAircraftConfigurationId) {
      const pilotOnlyIndex = nextSeatAssignmentsAttributes.findIndex(
        (sa) => !sa._destroy && sa.seat_assignment_seat_type === 'pilot_only'
      );
      if (pilotOnlyIndex > -1) {
        const pilotOnlySeatAssignment = nextSeatAssignmentsAttributes.at(pilotOnlyIndex);
        const { passenger_id } = pilotOnlySeatAssignment;
        const passengerId = defaultTo(parseInt(passenger_id, 10), '');
        if (nextPilotId !== passengerId) {
          const newPilotSeatAssignment = {
            ...pilotOnlySeatAssignment,
            seat_assignment_weight: 0,
            pax_name: '',
            passenger_id: '',
          };
          if (nextPilotId) {
            const bookingPilot = get(nextProps.contactsDataSelector, [nextPilotId]);
            if (bookingPilot) {
              newPilotSeatAssignment.seat_assignment_weight = bookingPilot.weight;
              newPilotSeatAssignment.pax_name = bookingPilot.fullName;
              newPilotSeatAssignment.passenger_id = nextPilotId;
            }
          }
          this.props.change(
            `${this.props.field}.seat_assignments_attributes[${pilotOnlyIndex}]`,
            newPilotSeatAssignment
          );
        }
      }

      const copilotOnlyIndex = nextSeatAssignmentsAttributes.findIndex(
        (sa) => !sa._destroy && sa.seat_assignment_seat_type === 'copilot_only'
      );
      if (copilotOnlyIndex > -1) {
        const copilotOnlySeatAssignment =
          nextSeatAssignmentsAttributes.at(copilotOnlyIndex);
        const { passenger_id } = copilotOnlySeatAssignment;
        const passengerId = defaultTo(parseInt(passenger_id, 10), '');
        if (nextCopilotId !== passengerId) {
          const newCopilotSeatAssignment = {
            ...copilotOnlySeatAssignment,
            seat_assignment_weight: 0,
            pax_name: '',
            passenger_id: '',
          };
          if (nextCopilotId) {
            const bookingCopilot = get(nextProps.contactsDataSelector, [nextCopilotId]);
            if (bookingCopilot) {
              newCopilotSeatAssignment.seat_assignment_weight = bookingCopilot.weight;
              newCopilotSeatAssignment.pax_name = bookingCopilot.fullName;
              newCopilotSeatAssignment.passenger_id = nextCopilotId;
            }
          }
          this.props.change(
            `${this.props.field}.seat_assignments_attributes[${copilotOnlyIndex}]`,
            newCopilotSeatAssignment
          );
        }
      }

      const copilotOrPaxIndex = nextSeatAssignmentsAttributes.findIndex(
        (sa) => !sa._destroy && sa.seat_assignment_seat_type === 'copilot_or_pax'
      );
      if (copilotOrPaxIndex > -1) {
        const copilotOrPaxSeatAssignment =
          nextSeatAssignmentsAttributes.at(copilotOrPaxIndex);
        const { passenger_id, pax_name: paxName } = copilotOrPaxSeatAssignment;
        const passengerId = defaultTo(parseInt(passenger_id, 10), '');
        if (
          (nextCopilotId && nextCopilotId !== passengerId) ||
          (this.props.bookingCopilotId && !nextCopilotId)
        ) {
          const newCopilotSeatAssignment = {
            ...copilotOrPaxSeatAssignment,
            seat_assignment_weight: 0,
            pax_name: '',
            passenger_id: '',
          };
          if (nextCopilotId) {
            const bookingCopilot = get(nextProps.contactsDataSelector, [nextCopilotId]);
            if (bookingCopilot) {
              newCopilotSeatAssignment.seat_assignment_weight = bookingCopilot.weight;
              newCopilotSeatAssignment.pax_name = bookingCopilot.fullName;
              newCopilotSeatAssignment.passenger_id = nextCopilotId;
            }
            if (paxName && !this.props.bookingCopilotId) {
              const emptyPaxOnlyIndex = nextSeatAssignmentsAttributes.findIndex(
                (sa) =>
                  !sa._destroy &&
                  sa.seat_assignment_seat_type === 'pax_only' &&
                  !sa.pax_name
              );
              if (emptyPaxOnlyIndex > -1) {
                const emptyPaxOnlySeatAssignment =
                  nextSeatAssignmentsAttributes.at(emptyPaxOnlyIndex);
                const newEmptyPaxOnlySeatAssignment = {
                  ...emptyPaxOnlySeatAssignment,
                  seat_assignment_weight:
                    copilotOrPaxSeatAssignment.seat_assignment_weight,
                  pax_name: copilotOrPaxSeatAssignment.pax_name,
                  passenger_id: copilotOrPaxSeatAssignment.passenger_id,
                };
                this.props.change(
                  `${this.props.field}.seat_assignments_attributes[${emptyPaxOnlyIndex}]`,
                  newEmptyPaxOnlySeatAssignment
                );
              }
            }
          }
          this.props.change(
            `${this.props.field}.seat_assignments_attributes[${copilotOrPaxIndex}]`,
            newCopilotSeatAssignment
          );
        }
      }
    }
    if (currentLuggageWeight !== nextLuggageWeight) {
      let nextLuggageWeightRemainder = nextLuggageWeight;
      const newHoldAssignments = nextHoldAssignmentsAttributes.map((holdAssignment) => {
        const {
          hold_assignment_installed: holdAssignmentInstalled,
          hold_assignment_weight_unit: holdAssignmentWeightUnit,
          hold_assignment_maximum_weight: holdAssignmentMaximumWeight,
        } = holdAssignment;
        let holdAssignmentWeight = 0;
        if (!holdAssignment._destroy && holdAssignmentInstalled) {
          if (nextLuggageWeightRemainder > 0) {
            const convertedNextLuggageWeightRemainder = parseInt(
              convertFromTo(
                nextLuggageWeightRemainder,
                bookingWeightUnit,
                holdAssignmentWeightUnit
              ),
              10
            );
            if (holdAssignmentMaximumWeight > 0) {
              holdAssignmentWeight = Math.min(
                convertedNextLuggageWeightRemainder,
                holdAssignmentMaximumWeight
              );
            } else {
              holdAssignmentWeight = convertedNextLuggageWeightRemainder;
            }
            nextLuggageWeightRemainder -= convertFromTo(
              holdAssignmentWeight,
              holdAssignmentWeightUnit,
              bookingWeightUnit
            );
          }
        }
        return {
          ...holdAssignment,
          hold_assignment_weight: holdAssignmentWeight,
        };
      });
      this.props.change(
        `${this.props.field}.hold_assignments_attributes`,
        newHoldAssignments
      );
    }

    if (currentPilotTakeOffFuel !== nextPilotTakeOffFuel) {
      let nextTakeOffFuelRemainder = nextPilotTakeOffFuel;
      const newTankAssignments = nextTankAssignmentsAttributes.map((tankAssignment) => {
        const {
          tank_assignment_fuel_unit: tankAssignmentFuelUnit,
          tank_assignment_maximum_fuel: tankAssignmentMaximumFuel,
        } = tankAssignment;

        let tankAssignmentFuel = 0;
        let tankAssignmentLongitudinalArm = 0;
        let tankAssignmentLateralArm = 0;

        if (!tankAssignment._destroy) {
          const bookingAircraftTankConfiguration =
            nextProps.bookingAircraftTankConfigurations.find(
              (batc) => batc.id === tankAssignment.aircraft_tank_tank_configuration_id
            );
          if (bookingAircraftTankConfiguration) {
            const {
              aircraftTank: { aircraftTankPoints },
            } = bookingAircraftTankConfiguration;
            if (nextTakeOffFuelRemainder > 0) {
              const convertedNextTakeOffFuelRemainder = convertFuelToFuel({
                fuelQuantity: nextTakeOffFuelRemainder,
                fromFuelUnit: bookingFuelUnit,
                toFuelUnit: tankAssignmentFuelUnit,
                specificGravity: aircraftTypeFuelTypeSpecificGravity,
              });
              if (tankAssignmentMaximumFuel > 0) {
                tankAssignmentFuel = Math.min(
                  convertedNextTakeOffFuelRemainder,
                  tankAssignmentMaximumFuel
                );
              } else {
                tankAssignmentFuel = convertedNextTakeOffFuelRemainder;
              }
              let tankPoint = first(
                clone(aircraftTankPoints)
                  .sort((a, b) => a.tank_point_fuel - b.tank_point_fuel)
                  .filter((tp) => tp.tank_point_fuel >= tankAssignmentFuel)
              );
              if (!tankPoint) {
                tankPoint = last(
                  clone(aircraftTankPoints).sort(
                    (a, b) => a.tank_point_fuel - b.tank_point_fuel
                  )
                );
              }
              tankAssignmentLateralArm = tankPoint.tank_point_lateral_arm;
              tankAssignmentLongitudinalArm = tankPoint.tank_point_longitudinal_arm;
              nextTakeOffFuelRemainder -= convertFuelToFuel({
                fuelQuantity: tankAssignmentFuel,
                fromFuelUnit: tankAssignmentFuelUnit,
                toFuelUnit: bookingFuelUnit,
                specificGravity: aircraftTypeFuelTypeSpecificGravity,
              });
            }
          }
        }
        return {
          ...tankAssignment,
          tank_assignment_fuel: tankAssignmentFuel,
          tank_assignment_longitudinal_arm: tankAssignmentLongitudinalArm,
          tank_assignment_lateral_arm: tankAssignmentLateralArm,
        };
      });
      this.props.change(
        `${this.props.field}.tank_assignments_attributes`,
        newTankAssignments
      );
    }
  }

  styleRemainingWeight() {
    const style = {};
    const { flightSegmentAllUpWeightOverMaximumWeight } =
      this.props.flightSegmentCalculatedData;
    if (flightSegmentAllUpWeightOverMaximumWeight) {
      style.color = 'red';
      style.fontWeight = 'bold';
    }
    return style;
  }

  renderRemainingWeight() {
    const { flightSegmentRemainingWeight, bookingWeightUnit } =
      this.props.flightSegmentCalculatedData;
    if (bookingWeightUnit) {
      if (flightSegmentRemainingWeight === 0) {
        return `${flightSegmentRemainingWeight}${bookingWeightUnit}`;
      }
      if (flightSegmentRemainingWeight) {
        return `${defaultTo(
          Math.round(flightSegmentRemainingWeight),
          '?'
        )}${bookingWeightUnit}`;
      }
    }
    return '-';
  }

  renderDistance() {
    const flightSegmentDistance = get(
      this.props.flightSegmentCalculatedData,
      'flightSegmentDistance'
    );
    let bookingDistanceUnit = get(
      this.props.flightSegmentCalculatedData,
      'bookingDistanceUnit'
    );
    if (bookingDistanceUnit === 'nMi') {
      bookingDistanceUnit = 'nm';
    }
    if (flightSegmentDistance && flightSegmentDistance > 0) {
      return `${Math.round(flightSegmentDistance)}${bookingDistanceUnit}`;
    }
    return '-';
  }

  renderFlighttime() {
    return get(
      this.props.flightSegmentCalculatedData,
      'flightSegmentLoadedFlighttimeFormatted',
      '-'
    );
  }

  renderCardHeader() {
    const {
      index,
      activeIndex,
      isXs,
      isMd,
      bookingPilotId,
      bookingCopilotId,
      bookingAircraftId,
    } = this.props;
    const formKeys = [
      'id',
      'start_at',
      'start_critical',
      'start_location_id',
      'end_location_id',
      'seat_assignments_attributes',
    ];
    return (
      <FlightSegmentCardHeader
        index={index}
        activeIndex={activeIndex}
        isXs={isXs}
        isMd={isMd}
        bookingPilotId={bookingPilotId}
        bookingCopilotId={bookingCopilotId}
        bookingAircraftId={bookingAircraftId}
        handleDeleteClicked={this.props.handleDeleteClicked}
        handleCriticalLocationModalClicked={this.props.handleCriticalLocationModalClicked}
        handleAddFlightSegmentModalClicked={this.props.handleAddFlightSegmentModalClicked}
        aircraftsDataSelector={this.props.aircraftsDataSelector}
        contactsDataSelector={this.props.contactsDataSelector}
        locationsDataSelector={this.props.locationsDataSelector}
        flightSegmentCalculatedData={this.props.flightSegmentCalculatedData}
        {...pick(this.props.formValues, formKeys)}
      />
    );
  }

  renderSeatAssignmentsFieldArray(seatAssignments) {
    const formKeys = ['seat_assignments_attributes'];
    return (
      <SeatAssignments
        flightSegmentIndex={this.props.index}
        isXs={this.props.isXs}
        seatAssignments={seatAssignments}
        formValues={pick(this.props.formValues, formKeys)}
        bookingPilotId={this.props.bookingPilotId}
        bookingCopilotId={this.props.bookingCopilotId}
        selectablePassengers={this.props.selectablePassengers}
        addingPassenger={this.props.addingPassenger}
        handleAddPassengerModalClicked={this.props.handleAddPassengerModalClicked}
        handlePassengerChanged={this.props.handlePassengerChanged}
      />
    );
  }

  renderCardBody() {
    const { field, index } = this.props;

    return (
      <Row>
        <Col md={3} className="border-end">
          <Fields
            field={field}
            index={index}
            names={[`${field}.start_at`, `${field}.start_location_id`]}
            component={FlightSegmentStartDateLocationAndTimeInputFields}
            selectableLocations={this.props.selectableLocations}
            handleLocationChanged={this.props.handleStartLocationChanged}
            flightSegmentCalculatedData={this.props.flightSegmentCalculatedData}
            prevEndAt={this.props.prevEndAt}
            locationsDataSelector={this.props.locationsDataSelector}
            {...pick(this.props.formValues, [
              'start_at',
              'start_location_id',
              'start_critical',
            ])}
          />
          <Fields
            field={field}
            index={index}
            names={[`${field}.end_at`, `${field}.end_location_id`]}
            component={FlightSegmentEndDateLocationAndTimeInputFields}
            selectableLocations={this.props.selectableLocations}
            handleLocationChanged={this.props.handleEndLocationChanged}
            loadedFlighttime={get(
              this.props.flightSegmentCalculatedData,
              'flightSegmentLoadedFlighttime'
            )}
            locationsDataSelector={this.props.locationsDataSelector}
            {...pick(this.props.formValues, [
              'end_at',
              'end_location_id',
              'start_at',
              'end_critical',
            ])}
          />
        </Col>
        <Col md={7} className="border-end">
          <FieldArray
            name={`${field}.seat_assignments_attributes`}
            component={this.renderSeatAssignmentsFieldArray}
          />
        </Col>
        <Col md={2}>
          <Row>
            <Col xs={4} className="px-1">
              <InputField
                labelWidth={0}
                inputWidth={12}
                input={{
                  name: 'r-payld_input_name',
                  value: this.renderRemainingWeight(),
                }}
                size="sm"
                plainText
                inputStyle={this.styleRemainingWeight()}
                groupClassName="p-0"
              >
                R-Payld
              </InputField>
            </Col>
            <Col xs={4} className="px-1">
              <InputField
                labelWidth={0}
                inputWidth={12}
                input={{
                  name: 'dist_input_name',
                  value: this.renderDistance(),
                }}
                size="sm"
                plainText
                groupClassName="p-0"
              >
                Dist
              </InputField>
            </Col>
            <Col xs={4} className="px-1">
              <InputField
                labelWidth={0}
                inputWidth={12}
                input={{
                  name: 'time_input_name',
                  value: this.renderFlighttime(),
                }}
                size="sm"
                plainText
                groupClassName="p-0"
              >
                Time
              </InputField>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="px-1">
              <Field
                type="text"
                name={`${field}.luggage_weight`}
                component={FlightSegmentLuggageWeightField}
                formValues={pick(this.props.formValues, ['luggage_weight'])}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  render() {
    const { index, isMd } = this.props;

    if (!isMd) {
      return (
        <Accordion>
          <Card className="mb-2 overflow-visible">
            <CardHeaderToggle eventKey={index} className="px-4">
              {this.renderCardHeader()}
            </CardHeaderToggle>
            <Accordion.Collapse eventKey={index}>
              <Card.Body className="p-2">{this.renderCardBody()}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    }
    return (
      <Card className="mb-2">
        <Card.Header>{this.renderCardHeader()}</Card.Header>
        <Card.Body>{this.renderCardBody()}</Card.Body>
      </Card>
    );
  }
}

export default FlightSegment;
