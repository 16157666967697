import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { connect } from 'react-redux';

import debounce from 'lodash.debounce';
import uniq from 'lodash.uniq';
import first from 'lodash.first';

import { currentSettingsSet } from '../actions/current_setting_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';

import { queriesReady } from '../lib/utils';

import bookingSwapsForMonthByProviderQuery from '../queries/booking_swaps_for_month_by_provider_query';

moment.updateLocale('en-nz');

class ReportProviderSwapSummary extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('month').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportStart).isSame(
        moment(this.props.currentSettingsReportStart).startOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportStart: moment(this.props.currentSettingsReportStart)
          .startOf('month')
          .toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportEnd).isSame(
        moment(this.props.currentSettingsReportStart).endOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportEnd: moment(this.props.currentSettingsReportStart)
          .endOf('month')
          .toISOString(),
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.bookingSwapsForMonthByProviderQuery.refetch();
    }, 250);
  }

  // componentWillReceiveProps (nextProps) {
  // }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.bookingSwapsForMonthByProviderQuery);
  }

  _renderFixed(value, symbol = '') {
    return value ? symbol + value.toFixed(2) : '-';
  }

  _renderHeaderRow() {
    return (
      <tr>
        <th className="border-top-0">Company</th>
        <th style={{ width: '50px' }} className="text-end border-top-0">
          DR/CR
        </th>
      </tr>
    );
  }

  _renderFooterRowSubtotal(sum) {
    return (
      <tr>
        <th className="text-end">Provider Subtotal</th>
        <th className="text-end">{sum.toFixed(2)}</th>
      </tr>
    );
  }

  _renderFooterRowAdjustments(adjustment) {
    return (
      <tr>
        <th className="text-end">Adjustments</th>
        <th className="text-end">{adjustment.toFixed(2)}</th>
      </tr>
    );
  }

  _renderFooterRowTotal(total) {
    return (
      <tr>
        <th className="text-end">Total</th>
        <th className="text-end">{total.toFixed(2)}</th>
      </tr>
    );
  }

  _renderProviders(bookings) {
    const sum =
      Math.round(
        bookings.reduce((sum, provider) => sum + provider.end_balance, 0) * 100
      ) / 100;
    const { swap_group_adjustment_value } = first(bookings);
    return (
      <Table striped>
        <thead>{this._renderHeaderRow()}</thead>
        <tbody>
          {bookings.map((data, index) => (
            <tr key={data.id}>
              <td>{data.provider_full_name}</td>
              <td className="text-end">{data.end_balance.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          {this._renderFooterRowSubtotal(sum)}
          {this._renderFooterRowAdjustments(swap_group_adjustment_value)}
          {this._renderFooterRowTotal(sum + swap_group_adjustment_value)}
        </tfoot>
      </Table>
    );
  }

  _renderSwapGroups() {
    const swap_group_ids = uniq(
      this.props.bookingSwapsForMonthByProviderQuery.data.map((b) => b.swap_group_id)
    );
    return swap_group_ids.map((swap_group_id) => {
      const bookings = this.props.bookingSwapsForMonthByProviderQuery.data.filter(
        (b) => b.swap_group_id === swap_group_id
      );
      const { swap_group_name } = first(bookings);
      return (
        <Row key={swap_group_id} xs={1}>
          <Col>
            <h4>{swap_group_name}</h4>
          </Col>
          <Col md={6}>{this._renderProviders(bookings)}</Col>
        </Row>
      );
    });
  }

  render() {
    if (this.isLoading()) {
      return <Loader />;
    }
    return (
      <>
        <Row className="my-3">
          <Col>
            <ReportHeader
              title="Provider Swap Summary"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
            locked
          />
        </Row>
        {this._renderSwapGroups()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
  };
}

export default compose(
  connect(mapStateToProps, { currentSettingsSet }),
  graphql(bookingSwapsForMonthByProviderQuery, {
    name: 'bookingSwapsForMonthByProviderQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportProviderSwapSummary);
