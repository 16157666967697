import { useState, useEffect } from 'react';

import defaultTo from 'lodash.defaultto';

import InputField from '../form/input_field';

const FlightSegmentLuggageWeightField = (props) => {
  const { formValues, input: luggageWeightInput, meta: luggageWeightMeta } = props;
  const { luggage_weight: luggageWeight } = formValues;
  const [weight, setWeight] = useState('');

  useEffect(() => {
    setWeight(luggageWeight || 0);
  }, [luggageWeight]);

  const handleWeightChange = (e) => {
    const newWeight = e.target.value;
    setWeight(newWeight);
  };

  const handleWeightBlur = (e) => {
    const newWeight = defaultTo(parseInt(e.target.value, 10), 0);
    setWeight(newWeight);
    // TODO look up Arm? not sure even needed now, use booking weight type..
    luggageWeightInput.onChange(newWeight);
  };

  return (
    <InputField
      input={{
        ...luggageWeightInput,
        onChange: handleWeightChange,
        onBlur: handleWeightBlur,
        value: weight,
      }}
      meta={luggageWeightMeta}
      type="text"
      size="sm"
      labelWidth={4}
      inputWidth={8}
      noTab
      groupClassName="mx-0 p-0"
      labelAlign="left"
      labelColClassName="ps-0 pe-1"
      inputColClassName="ps-1 pe-2"
    >
      Luggage
    </InputField>
  );
};

export default FlightSegmentLuggageWeightField;
