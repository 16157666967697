import { useState, useEffect } from 'react';

import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';

import { round2 } from '../../lib/convert_units';

const WeightBalanceTankAssignmentFields = (props) => {
  const { formValues, parentField } = props;

  const {
    tank_assignment_fuel: { input: tankAssignmentFuelInput },
  } = get(props, parentField);

  const {
    name,
    tank_assignment_maximum_fuel: tankAssigmentMaximumFuel,
    tank_assignment_fuel: tankAssignmentFuel,
    tank_assignment_fuel_unit: tankAssignmentFuelUnit,
  } = formValues;

  const [fuel, setFuel] = useState('');

  useEffect(() => {
    setFuel(tankAssignmentFuel || 0);
  }, [tankAssignmentFuel]);

  const handleFuelChange = (e) => {
    const newFuel = e.target.value;
    setFuel(newFuel);
  };

  const handleFuelBlur = (e) => {
    const newFuel = round2(defaultTo(parseFloat(e.target.value), 0));
    // TODO look up new Arms based on new weight and submit
    // still not sure if allowing this to be changed is a good idea
    // maybe only should be changed by take_off_weight
    // have disabled this input for now
    tankAssignmentFuelInput.onChange(newFuel);
  };

  return (
    <tr>
      <td>{name}</td>
      <td>{tankAssignmentFuelUnit}</td>
      <td>{tankAssigmentMaximumFuel}</td>
      <td>
        <input
          type="text"
          style={{ width: '88px' }}
          value={fuel}
          disabled
          onChange={handleFuelChange}
          onBlur={handleFuelBlur}
        />
      </td>
    </tr>
  );
};

export default WeightBalanceTankAssignmentFields;
