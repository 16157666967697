import actionTypes from '../constants';

export function authReset() {
  return {
    type: actionTypes.AUTH_RESET,
  };
}

export function authSet(payload) {
  return {
    type: actionTypes.AUTH_SET,
    payload,
  };
}
