import makeValidator from './make_validator';

export const documentFormSchema = {
  type: 'object',
  required: ['document_category_id', 'description'],
  // properties: {
  //   description: {
  //     type: 'string',
  //     minLength: 5,
  //     // allOf: [
  //     //   { type: 'string', minLength: 3, maxLength: 3 },
  //     //   {
  //     //     pattern: '^(?!^0+$).*',
  //     //     errorMessage: {
  //     //       pattern: 'should match pattern "not all zeros"',
  //     //     },
  //     //   },
  //     // ],
  //   },
  // },
  allOf: [
    {
      if: {
        not: {
          required: ['id'],
        },
      },
      then: {
        required: ['document'],
      },
    },
  ],
};
export const documentNestedFormSchema = {
  type: 'object',
  required: ['description'],
  allOf: [
    {
      if: {
        not: {
          required: ['id'],
        },
      },
      then: {
        required: ['document'],
      },
    },
  ],
};

export const documentFormValidator = makeValidator(documentFormSchema);

export const documentNestedFormValidator = makeValidator(documentNestedFormSchema);
