import { Form } from 'react-bootstrap';
import { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import compact from 'lodash.compact';
import debounce from 'lodash.debounce';
import defaultTo from 'lodash.defaultto';
import first from 'lodash.first';
import get from 'lodash.get';

import LabelBlock from '../form/label_block';
import InvalidBlock from '../form/invalid_block';
import InputField from '../form/input_field';

class SeatAssignmentPaxFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paxName: '',
      seatAssignmentWeight: '',
    };
    this.handlePaxNameChange = this.handlePaxNameChange.bind(this);
    this.handlePaxNameInputChange = this.handlePaxNameInputChange.bind(this);
    this.handlePaxNameBlur = this.handlePaxNameBlur.bind(this);
    this.handleWeightChange = this.handleWeightChange.bind(this);
    this.handleWeightBlur = this.handleWeightBlur.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.delayedHandlePaxNameChange = debounce((passengers) => {
      const customOption = get(first(passengers), 'customOption', false);
      const blurOption = get(first(passengers), 'blurOption', false);
      const paxName = get(first(passengers), 'name', '');
      let passengerId = get(first(passengers), 'id', '');
      if (
        !this.props.addingPassenger &&
        !(blurOption && paxName === this.props.paxName)
      ) {
        if (customOption) {
          passengerId = '';
          if (this.props.handleAddPassengerModalClicked) {
            this.props.handleAddPassengerModalClicked(
              this.props.seatAssignmentIndex,
              this.props.flightSegmentIndex,
              paxName,
              this.props.seatAssignmentWeight
            );
          }
        } else if (paxName || blurOption) {
          const currentPassengerId = defaultTo(parseInt(this.props.passengerId, 10), '');
          const newPassengerId = defaultTo(parseInt(passengerId, 10), '');
          const currentPaxName = defaultTo(this.props.paxName, '');
          const newPaxName = defaultTo(paxName, '');
          if (currentPassengerId !== newPassengerId || currentPaxName !== newPaxName) {
            if (this.props.handlePassengerChanged) {
              this.props.handlePassengerChanged(
                this.props.seatAssignmentIndex,
                this.props.flightSegmentIndex,
                newPassengerId,
                newPaxName
              );
            }
          }
        }
      }
    }, 200);
  }

  componentDidMount() {
    if (this.props.paxName) {
      this.receivePaxName();
    }
    if (this.props.seatAssignmentWeight) {
      this.receiveSeatAssignmentWeight();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.paxName !== nextProps.paxName) {
      this.receivePaxName(nextProps);
    }
    if (this.props.seatAssignmentWeight !== nextProps.seatAssignmentWeight) {
      this.receiveSeatAssignmentWeight(nextProps);
    }
  }

  componentWillUnmount() {
    this.delayedHandlePaxNameChange.cancel();
  }

  handleWeightChange(e) {
    const newSeatAssignmentWeight = e.target.value || '';
    this.setState({
      seatAssignmentWeight: newSeatAssignmentWeight,
    });
  }

  handleWeightBlur(e) {
    const newSeatAssignmentWeight = defaultTo(parseInt(e.target.value, 10), 0);
    this.setState({
      seatAssignmentWeight: newSeatAssignmentWeight || '',
    });
    const seatAssignmentWeightInput = get(
      this.props,
      [this.props.field, 'seat_assignment_weight', 'input'].join('.')
    );
    seatAssignmentWeightInput.onChange(newSeatAssignmentWeight);
  }

  handlePaxNameInputChange(paxName) {
    this.setState({
      paxName,
    });
  }

  handlePaxNameChange(passengers) {
    this.delayedHandlePaxNameChange(passengers);
  }

  handlePaxNameBlur(e) {
    const passengers = [
      {
        name: e.target.value,
        blurOption: true,
        id: '',
      },
    ];
    this.delayedHandlePaxNameChange(passengers);
  }

  getSelectablePassengers() {
    return this.props.selectablePassengers;
  }

  receivePaxName(props = this.props) {
    this.setState({
      paxName: props.paxName,
    });
  }

  receiveSeatAssignmentWeight(props = this.props) {
    this.setState({
      seatAssignmentWeight: props.seatAssignmentWeight,
    });
  }

  render() {
    const {
      seat_assignment_weight: {
        input: seatAssignmentWeightInput,
        meta: seatAssignmentWeightMeta,
      },
      pax_name: { input: paxNameInput, meta: paxNameMeta },
    } = get(this.props, this.props.field);
    return (
      <>
        <InputField
          size="sm"
          labelWidth={0}
          inputWidth={12}
          input={{
            name: paxNameInput.name,
          }}
          groupClassName="p-0"
          innerContent={
            <>
              <LabelBlock size="sm">{this.props.seatName}</LabelBlock>
              <Typeahead
                id={`${paxNameInput.name}-id`}
                ref={`${paxNameInput.name}-ref`}
                inputProps={{ name: paxNameInput.name }}
                onInputChange={this.handlePaxNameInputChange}
                onChange={this.handlePaxNameChange}
                onBlur={this.handlePaxNameBlur}
                options={this.getSelectablePassengers()}
                selected={compact([this.state.paxName])}
                allowNew
                newSelectionPrefix="Add with details: "
                filterBy={['name']}
                labelKey="name"
                minLength={1}
                size="sm"
              />
              <InvalidBlock meta={paxNameMeta} force />
            </>
          }
        />
        <InputField
          labelWidth={0}
          inputWidth={12}
          type="text"
          input={seatAssignmentWeightInput}
          meta={seatAssignmentWeightMeta}
          groupClassName="p-0"
          innerContent={
            <>
              <Form.Control
                size="sm"
                type="text"
                {...seatAssignmentWeightInput}
                value={this.state.seatAssignmentWeight}
                onChange={this.handleWeightChange}
                onBlur={this.handleWeightBlur}
              />
              <InvalidBlock meta={seatAssignmentWeightMeta} force />
            </>
          }
        />
      </>
    );
  }
}

export default SeatAssignmentPaxFields;
