import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';

import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';
import includes from 'lodash.includes';
import isEqual from 'lodash.isequal';

import CheckboxInputField from '../form/checkbox_input_field';
import InputField from '../form/input_field';
import InvalidBlock from '../form/invalid_block';

class BookingPilotFlightExpenseFields extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.pilotIds, nextProps.pilotIds)) {
      const pilotId = defaultTo(parseInt(get(nextProps, 'formValues.pilot_id'), 10), '');
      if (pilotId && !includes(nextProps.pilotIds, pilotId)) {
        const input = get(nextProps, `${nextProps.parentField}pilot_id.input`);
        input.onChange('');
      }
    }
  }

  handleDeleteButtonClick() {
    const id = get(this.props, 'formValues.id', '');
    if (id) {
      const input = get(this.props, `${this.props.parentField}._destroy.input`);
      input.onChange(true);
    } else if (this.props.handleDeleteButtonClick) {
      this.props.handleDeleteButtonClick(this.props.index);
    }
  }

  render() {
    const {
      pilot_id: { input: pilotIdInput, meta: pilotIdMeta },
      notes: { input: notesInput, meta: notesMeta },
      total: { input: totalInput, meta: totalMeta },
      includes_gst: { input: includesGstInput, meta: includesGstMeta },
      onchargeable: { input: onchargeableInput, meta: onchargeableMeta },
      reimbursable: { input: reimbursableInput, meta: reimbursableMeta },
    } = get(this.props, this.props.parentField);

    return (
      <InputField
        size="sm"
        labelWidth={2}
        inputWidth={10}
        input={{}}
        innerContent={
          <Row>
            <Col sm={2}>
              <InputField
                controlOnly
                type="text"
                size="sm"
                asElement="select"
                selectOptions={this.props.selectOptions}
                input={pilotIdInput}
                meta={pilotIdMeta}
              />
            </Col>
            <Col sm={3}>
              <InputField
                controlOnly
                blurOnly
                type="text"
                input={notesInput}
                meta={notesMeta}
                size="sm"
                readOnly={this.props.overNight}
              />
            </Col>
            <Col sm={1}>
              <InputField
                controlOnly
                blurOnly
                type="text"
                input={totalInput}
                meta={totalMeta}
                size="sm"
              />
            </Col>
            <Col sm={1}>
              <CheckboxInputField
                innerContentOnly
                size="sm"
                input={includesGstInput}
                meta={includesGstMeta}
              />
            </Col>
            <Col sm={1}>
              <CheckboxInputField
                innerContentOnly
                size="sm"
                input={onchargeableInput}
                meta={onchargeableMeta}
              />
            </Col>
            <Col sm={1}>
              <CheckboxInputField
                innerContentOnly
                size="sm"
                input={reimbursableInput}
                meta={reimbursableMeta}
              />
            </Col>
            <Col sm={1}>
              <Button
                type="button"
                variant="link"
                size="sm"
                onClick={this.handleDeleteButtonClick}
                tabIndex={-1}
              >
                del...
              </Button>
            </Col>
            <Col sm={12}>
              <InvalidBlock meta={pilotIdMeta} force />
              <InvalidBlock meta={notesMeta} force />
              <InvalidBlock meta={totalMeta} force />
            </Col>
          </Row>
        }
      >
        {`Expense ${this.props.index + 1}`}
      </InputField>
    );
  }
}

export default BookingPilotFlightExpenseFields;
