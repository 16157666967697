export const checkWhiteList = [
  'id',
  'check_type_id',
  'check_category_id',
  'checkable_type',
  'checkable_id',
  'manager_id',
  'check_start_on',
  'check_end_on',
  'check_complete_on',
];
