import { forwardRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';

const OverlayableLinkContainer = forwardRef((props, ref) => {
  const { children, to } = props;
  return (
    <div ref={ref}>
      <LinkContainer to={to}>{children}</LinkContainer>
    </div>
  );
});

export default OverlayableLinkContainer;
