import get from 'lodash.get';

import FlightPlan from './flight_plan';

const FlightPlans = (props) => {
  const {
    flightSegments,
    change,
    formValues,
    flightSegmentsCalculatedData,
    locationsDataSelector,
    isDeveloper,
    anyPilotTakeOffFuelAdjusted,
  } = props;

  let activeIndex = -1;

  return (
    <tbody>
      {flightSegments.fields.map((field, index) => {
        const flightSegmentValues = get(formValues, field, {});
        if (!flightSegmentValues._destroy) {
          activeIndex += 1;
          return (
            <FlightPlan
              key={field}
              index={index}
              change={change}
              field={field}
              formValues={flightSegmentValues}
              flightSegmentCalculatedData={flightSegmentsCalculatedData[activeIndex]}
              locationsDataSelector={locationsDataSelector}
              isDeveloper={isDeveloper}
              anyPilotTakeOffFuelAdjusted={anyPilotTakeOffFuelAdjusted}
            />
          );
        }
        return undefined;
      })}
    </tbody>
  );
};

export default FlightPlans;
