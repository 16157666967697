import { useCallback, useMemo } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';

import cloneDeep from 'lodash.clonedeep';

import Field from '../components/form/react_final_form_field';
import { currentSettingsSet } from '../actions/current_setting_actions';
import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import FormButtons from '../components/form/form_buttons';
import InputField from '../components/form/input_field';

import { toastSuccess, toastError } from '../lib/action_helpers';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import aircraftUpdateMutation from '../mutations/aircraft_update_mutation';
import pageAircraftFormQuery from '../queries/page_aircraft_form_query';
import { aircraftFormValidator } from '../validators';
import { aircraftDefaultValues } from '../defaults';
import { aircraftWhiteList } from '../white_lists';

const AircraftForm = () => {
  const dispatch = useDispatch();
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const [aircraftUpdate] = useMutation(aircraftUpdateMutation);
  const navigate = useNavigate();
  const params = useParams();

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageAircraftFormQuery, {
    variables: { hasAircraftId: !!params.id, aircraftId: params.id || 0 },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () =>
      !pageLoading ||
      (pageLoading &&
        [NetworkStatus.refetch, NetworkStatus.setVariables].includes(pageNetworkStatus)),
    [pageLoading, pageNetworkStatus]
  );

  const onFormSubmit = useCallback(
    async (data) => {
      let mutation;
      let mutationMessageAction;
      const submitData = cloneDeep(data);
      const mutationData = {
        variables: { input: coerceInput(submitData) },
      };
      if (params.id) {
        mutationData.variables.id = params.id;
        mutation = aircraftUpdate;
        mutationMessageAction = 'update';
      } else {
        // no route for this for aircraft.
        // mutation = aircraftCreate
        // mutationMessageAction = 'create'
        console.log('No client create for aircraft');
      }
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await mutation(mutationData);
        toastSuccess(`Aircraft ${mutationMessageAction} suceeded`);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        navigate('/aircrafts');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
      return undefined;
    },
    [params.id, aircraftUpdate, dispatch, navigate]
  );

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title form updating={!!params.id}>
            Aircraft
          </Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <LinkContainer to="/aircrafts">
                <Button size="sm" variant="primary">
                  All Aircraft
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={pickValues(
              pageData.aircraft || aircraftDefaultValues,
              aircraftWhiteList
            )}
            onSubmit={onFormSubmit}
            validate={aircraftFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate onSubmit={handleSubmit}>
                <fieldset className="border rounded-3 p-3">
                  <legend className="float-none w-auto px-3 fs-6">
                    Aircraft Weight Details
                  </legend>
                  {pageData.aircraft && (
                    <InputField
                      type="text"
                      input={{
                        name: 'registrationAbbreviated',
                        value: pageData.aircraft.registration_abbreviated,
                      }}
                      meta={{}}
                      plainText
                      helpBlock={false}
                      labelWidth={3}
                      inputWidth={3}
                      noTab
                    >
                      Aircraft
                    </InputField>
                  )}
                  <Field
                    type="text"
                    name="aircraft_weight"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                  >
                    Aircraft Weight
                  </Field>
                  <Field
                    type="text"
                    name="aircraft_weight_defined"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                    asElement="textarea"
                    rows={10}
                  >
                    Aircraft Weight Defined
                  </Field>
                  <Field
                    type="text"
                    name="aircraft_longitudinal_arm"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                  >
                    Aircraft Longitudinal Arm
                  </Field>
                  <Field
                    type="text"
                    name="aircraft_lateral_arm"
                    labelWidth={3}
                    inputWidth={3}
                    component={InputField}
                  >
                    Aircraft Lateral Arm
                  </Field>
                </fieldset>
                <Row>
                  <FormButtons
                    updating={!!params.id}
                    pristine={pristine}
                    submitting={submitting}
                    cancelLink="/aircrafts"
                  />
                </Row>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default AircraftForm;
