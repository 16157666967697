/* eslint-disable no-use-before-define */
// https://gist.github.com/simonrenoult/2fa79fec8f035b2d35cef0767363b227

import isEmpty from 'lodash.isempty';
import filter from 'lodash.filter';
import map from 'lodash.map';
import pickBy from 'lodash.pickby';
import mapValues from 'lodash.mapvalues';
import isNull from 'lodash.isnull';
import isPlainObject from 'lodash.isplainobject';
import isString from 'lodash.isstring';

function sanitizeString(string) {
  return isEmpty(string) ? null : string;
}

function sanitizeArray(array) {
  return filter(map(array, sanitize), isPickable);
}

function sanitizeObject(object) {
  return pickBy(mapValues(object, sanitize), isPickable);
}

function isPickable(value) {
  const typeSupported =
    isString(value) || isNull(value) || Array.isArray(value) || isPlainObject(value);
  return (
    !typeSupported || // integers and floats are kept
    (isPlainObject(value) && isEmpty(value)) || // empty objects kept
    (Array.isArray(value) && isEmpty(value)) || // empty arrays are kept
    !isEmpty(value) // null values will be removed here
  );
}

export function sanitize(object) {
  // this function recursively cleans an object for validation tests.
  // the main purpose is to remove empty keys so ajv [required] validations will work
  if (isString(object)) return sanitizeString(object);
  if (Array.isArray(object)) return sanitizeArray(object);
  if (isPlainObject(object)) return sanitizeObject(object);
  return object;
}
