import { useState, useEffect } from 'react';

import get from 'lodash.get';
import last from 'lodash.last';

import { round0, round2 } from '../../lib/convert_units';

const FlightPlanTableHeader = (props) => {
  const { flightSegmentsCalculatedData, isDeveloper } = props;

  const [sumConvertedLocationDistance, setSumConvertedLocationDistance] = useState('-');
  const [sumFlightSegmentDistance, setSumFlightSegmentDistance] = useState('-');
  const [sumFlightTime, setSumFlightTime] = useState('-');
  const [sumFuelBurn, setSumFuelBurn] = useState('-');
  const [lastBookingRemainingRequiredFuel, setLastBookingRemainingRequiredFuel] =
    useState('-');

  useEffect(() => {
    const round = isDeveloper ? round2 : round0;
    let newSumConvertedLocationDistance = '-';
    let newSumFlightSegmentDistance = '-';
    let newSumFlightTime = '-';
    let newSumFuelBurn = '-';
    let newLastBookingRemainingRequiredFuel = '-';
    if (flightSegmentsCalculatedData) {
      newSumConvertedLocationDistance = flightSegmentsCalculatedData.reduce(
        (total, data) => total + get(data, 'convertedLocationDistance', 0),
        0
      );
      newSumFlightSegmentDistance = flightSegmentsCalculatedData.reduce(
        (total, data) => total + get(data, 'flightSegmentDistance', 0),
        0
      );
      newSumFlightTime = flightSegmentsCalculatedData.reduce(
        (total, data) => total + get(data, 'flightSegmentLoadedFlighttime', 0),
        0
      );
      newSumFuelBurn = flightSegmentsCalculatedData.reduce(
        (total, data) => total + get(data, 'flightSegmentFuelBurn', 0),
        0
      );
      newLastBookingRemainingRequiredFuel =
        get(last(flightSegmentsCalculatedData), 'bookingRemainingRequiredFuel', 0) -
        get(last(flightSegmentsCalculatedData), 'flightSegmentFuelBurn', 0);
    }
    setSumConvertedLocationDistance(round(newSumConvertedLocationDistance));
    setSumFlightSegmentDistance(round(newSumFlightSegmentDistance));
    setSumFlightTime(round(newSumFlightTime));
    setSumFuelBurn(round(newSumFuelBurn));
    setLastBookingRemainingRequiredFuel(round(newLastBookingRemainingRequiredFuel));
  }, [flightSegmentsCalculatedData, isDeveloper]);

  return (
    <tfoot>
      <tr>
        <td />
        <td>{sumConvertedLocationDistance}</td>
        <td>{sumFlightSegmentDistance}</td>
        <td />
        <td>
          {sumFlightTime}
          &nbsp;/&nbsp;{sumFuelBurn}
        </td>
        {/* {isDeveloper && <td />} */}
        {isDeveloper && <td />}
        <td />
        <td />
        {/* {isDeveloper ? (
          <td>{lastBookingRemainingRequiredFuel}</td>
        ) : (
          <td />
        )} */}
        <td colSpan={isDeveloper ? 6 : 4} />
      </tr>
    </tfoot>
  );
};

export default FlightPlanTableHeader;
