import { Row, Col, Table, Form, Button, Card } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';
import DlHorizontal from '../components/dl_horizontal';

import { queriesReady } from '../lib/utils';

import bookingDetailForMonthByAircraftQuery from '../queries/booking_detail_for_month_by_aircraft_query';
import aircraftListQuery from '../queries/aircraft_list_query';

moment.updateLocale('en-nz');

class ReportAircraftFlightRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterAircraftId: this.props.currentSettingsReportAircraftId,
      filterAircraftIds: [],
    };
    this._handleDisplayAircraftIdChange = this._handleDisplayAircraftIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('month').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.aircraftId) {
      this._handleDisplayAircraftIdChange({
        target: { value: this.props.params.aircraftId },
      });
    }
    if (
      !moment(this.props.currentSettingsReportStart).isSame(
        moment(this.props.currentSettingsReportStart).startOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportStart: moment(this.props.currentSettingsReportStart)
          .startOf('month')
          .toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportEnd).isSame(
        moment(this.props.currentSettingsReportStart).endOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportEnd: moment(this.props.currentSettingsReportStart)
          .endOf('month')
          .toISOString(),
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.bookingDetailForMonthByAircraftQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterAircraftIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterAircraftIds = nextProps.bookingDetailForMonthByAircraftQuery.data.map(
        (data) => data.aircraft_id
      );
    }
    this.setState({
      filterAircraftIds,
    });
    let filterAircraftId = nextProps.currentSettingsReportAircraftId;
    if (filterAircraftId && this.isLoaded(nextProps)) {
      if (
        filterAircraftIds.length > 0 &&
        filterAircraftIds.indexOf(filterAircraftId) === -1
      ) {
        filterAircraftId = '';
      }
    }
    if (filterAircraftId !== this.props.currentSettingsReportAircraftId) {
      this._handleDisplayAircraftIdChange({ target: { value: filterAircraftId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.bookingDetailForMonthByAircraftQuery,
      props.aircraftListQuery
    );
  }

  _handleDisplayAircraftIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterAircraftId: value,
    });
    this.props.currentSettingsSet({
      reportAircraftId: value,
    });
  }

  _renderFixed(value) {
    return value ? value.toFixed(1) : '-';
  }

  _renderPilotFlightExpenses(pilot_expenses) {
    if (pilot_expenses) {
      return (
        <span>
          {pilot_expenses.map((pfe) => {
            const oncharge = pfe.oncharge ? 'Charge Client: Yes' : 'Charge Client: No';
            const fee = `$${pfe.fee}`;
            return (
              <span
                key={pfe.id}
                style={{ display: 'block' }}
              >{`Pilot Expense - ${pfe.note} - ${fee} - ${oncharge}`}</span>
            );
          })}
        </span>
      );
    }
  }

  _renderLandingFees(landing_fees) {
    if (landing_fees) {
      return (
        <span>
          {landing_fees.map((lf) => {
            const oncharge = lf.oncharge ? 'Charge Client: Yes' : 'Charge Client: No';
            const fee = `$${lf.fee}`;
            return (
              <span
                key={lf.id}
                style={{ display: 'block' }}
              >{`${lf.name} Landing Fee - ${fee} - ${oncharge}`}</span>
            );
          })}
        </span>
      );
    }
  }

  _styleHobb(serial_hobb) {
    if (!serial_hobb) {
      return { borderTop: '3px solid red' };
    }
  }

  _renderRowA(booking) {
    const {
      id,
      booking_id,
      reference,
      start_at_s,
      pilot_display_name,
      copilot_display_name,
      start_hobb,
      end_hobb,
      serial_hobb,
      landing_fees,
      pilot_expenses,
    } = booking;
    return (
      <tr>
        <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
          <LinkContainer to={`/flights/${booking_id}/edit`} className="ps-0">
            <Button variant="link">{`#${reference}`}</Button>
          </LinkContainer>
        </td>
        <td>{start_at_s}</td>
        <td>
          {[pilot_display_name, copilot_display_name].filter((name) => name).join(',')}
        </td>
        <td style={this._styleHobb(serial_hobb)}>{`${this._renderFixed(
          start_hobb
        )} / ${this._renderFixed(end_hobb)}`}</td>
        <td rowSpan={2}>
          <span>
            {this._renderLandingFees(landing_fees)}
            {this._renderPilotFlightExpenses(pilot_expenses)}
          </span>
        </td>
      </tr>
    );
  }

  _renderRowB(booking) {
    const { flight_time, booking_summary, chargeable_full_names } = booking;
    return (
      <tr>
        <td>{chargeable_full_names}</td>
        <td>{booking_summary}</td>
        <td>{this._renderFixed(flight_time)}</td>
      </tr>
    );
  }

  _renderHeaderRow() {
    return (
      <thead>
        <tr>
          <th className="border-top-0">Ref#</th>
          <th className="border-top-0">
            Date /
            <br />
            Charge to
          </th>
          <th className="border-top-0">
            Pilot /
            <br />
            Flight Summary
          </th>
          <th width="15%" className="border-top-0">
            Start/End /
            <br />
            Flight Time
          </th>
          <th width="40%" className="border-top-0">
            Expenses
          </th>
        </tr>
      </thead>
    );
  }

  _renderSummary(data, name) {
    const { start_hobb, end_hobb, next_start_hobb, prev_end_hobb, calc_hobb, sum_hobb } =
      data;

    const MM_currentSettingsReportStart = moment(this.props.currentSettingsReportStart);

    return (
      <Card>
        <Card.Header>{`Summary for ${name}`}</Card.Header>
        <Card.Body>
          <DlHorizontal
            dt={`${MM_currentSettingsReportStart.clone()
              .subtract(1, 'month')
              .format('MMMM')} End Hobb`}
            dd={this._renderFixed(prev_end_hobb)}
          />
          <DlHorizontal
            dt={`${MM_currentSettingsReportStart.format('MMMM')} Start Hobb`}
            dd={this._renderFixed(start_hobb)}
          />
          <DlHorizontal
            dt={`${MM_currentSettingsReportStart.format('MMMM')} End Hobb`}
            dd={this._renderFixed(end_hobb)}
          />
          <DlHorizontal
            dt={`${MM_currentSettingsReportStart.clone()
              .add(1, 'month')
              .format('MMMM')} Start Hobb`}
            dd={this._renderFixed(next_start_hobb)}
          />
          <DlHorizontal dt="End - Prev End" dd={this._renderFixed(calc_hobb)} />
          <DlHorizontal dt="Sum of Flight Times" dd={this._renderFixed(sum_hobb)} />
        </Card.Body>
      </Card>
    );
  }

  _renderAircrafts() {
    return this.props.bookingDetailForMonthByAircraftQuery.data
      .filter((data) => {
        if (
          this.state.filterAircraftId &&
          data.aircraft_id !== this.state.filterAircraftId
        ) {
          return false;
        }
        return true;
      })
      .map((data) => (
        <Row key={data.aircraft_id} className="mb-4" xs={1}>
          <Col>
            <h4>{data.aircraft_registration_abbreviated}</h4>
          </Col>
          <Col>
            <Table key={data.id} size="sm">
              {this._renderHeaderRow()}
              {data.bookings.map((booking, index) => (
                <tbody
                  key={booking.id}
                  style={{
                    backgroundColor: index % 2 === 1 ? 'inherit' : '#f9f9f9',
                  }}
                >
                  {this._renderRowA(booking)}
                  {this._renderRowB(booking)}
                </tbody>
              ))}
            </Table>
          </Col>
          <Col md={5}>
            {this._renderSummary(data, data.aircraft_registration_abbreviated)}
          </Col>
        </Row>
      ));
  }

  render() {
    if (this.isLoading()) {
      return <Loader />;
    }
    return (
      <>
        <Row className="my-3">
          <Col>
            <ReportHeader
              title="Aircraft Flight Record Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
            locked
          />
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'filterAircraftId',
              value: this.state.filterAircraftId,
              onChange: this._handleDisplayAircraftIdChange,
            }}
            asElement="select"
            selectOptions={this.props.aircraftListQuery.data.filter(
              (model) => this.state.filterAircraftIds.indexOf(model.id) > -1
            )}
            optionKey="registration_abbreviated"
            defaultSelectOptionName="All"
          />
        </Row>
        {this._renderAircrafts()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsReportAircraftId: state.currentSettings.reportAircraftId,
  };
}

export default compose(
  connect(mapStateToProps, { currentSettingsSet }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(bookingDetailForMonthByAircraftQuery, {
    name: 'bookingDetailForMonthByAircraftQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportAircraftFlightRecord);
