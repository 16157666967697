import { useEffect, useState, useCallback, useMemo } from 'react';

import { Card, Col, Row, Button, Form } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { kebabCase } from 'change-case';
import moment from 'moment';

import compact from 'lodash.compact';
import get from 'lodash.get';

import { currentSettingsSet } from '../actions/current_setting_actions';
import CheckboxInputField from '../components/form/checkbox_input_field';

import { renderOverlay, renderError } from '../components/render_helpers';
import Roster from '../components/roster_list/roster';
import { toastSuccess, toastError } from '../lib/action_helpers';
import { getContrastYIQ } from '../lib/utils';

import pageRosterListQuery from '../queries/page_roster_list_query';
import rosterPublishMutation from '../mutations/roster_publish_mutation';
import dutyEventCreateMutation from '../mutations/duty_event_create_mutation';
import dutyEventUpdateMutation from '../mutations/duty_event_update_mutation';
import dutyEventDeleteMutation from '../mutations/duty_event_delete_mutation';

const RosterList = () => {
  const dispatch = useDispatch();
  const [rosterPublish] = useMutation(rosterPublishMutation);
  const [dutyEventCreate] = useMutation(dutyEventCreateMutation);
  const [dutyEventUpdate] = useMutation(dutyEventUpdateMutation);
  const [dutyEventDelete] = useMutation(dutyEventDeleteMutation);
  const currentContact = useSelector((state) => state.currentContact);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const currentSettingsDutyEventCollectionStartDate = useSelector(
    ({ currentSettings }) => currentSettings.dutyEventCollectionStartDate
  );
  const currentSettingsDutyEventCollectionEndDate = useSelector(
    ({ currentSettings }) => currentSettings.dutyEventCollectionEndDate
  );

  const [powerEdit, setPowerEdit] = useState(false);
  const [activeRosterId, setActiveRosterId] = useState('');

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageRosterListQuery, {
    variables: {
      startAt: currentSettingsDutyEventCollectionStartDate,
      endAt: currentSettingsDutyEventCollectionEndDate,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () =>
      !pageLoading ||
      (pageLoading &&
        [NetworkStatus.refetch, NetworkStatus.setVariables].includes(pageNetworkStatus)),
    [pageLoading, pageNetworkStatus]
  );

  const isEditor = useMemo(() => {
    if (currentContact?.id) {
      // const { 'manager?': manager, 'office_admin?': officeAdmin } = currentContact;
      // return manager || officeAdmin;
      const { 'rosterer?': rosterer } = currentContact;
      return rosterer;
    }
    return false;
  }, [currentContact]);

  const rosters = useMemo(() => {
    if (pageData?.rosterList) {
      if (isEditor) {
        return pageData.rosterList;
      }
      return pageData.rosterList.filter((r) =>
        r.contactRosters.find((cr) => cr.contact_id === currentContact.id)
      );
    }
    return [];
  }, [pageData, currentContact, isEditor]);

  useEffect(() => {
    if (rosters.length && !activeRosterId) {
      setActiveRosterId(get(rosters, [0, 'id'], ''));
    }
  }, [rosters, activeRosterId]);

  const activeRoster = useMemo(() => {
    if (rosters.length > 0 && activeRosterId) {
      return rosters.find((r) => r.id === activeRosterId) || {};
    }
    return {};
  }, [rosters, activeRosterId]);

  const dutyEvents = useMemo(() => {
    if (pageData?.dutyEventList) {
      if (isEditor) {
        return pageData.dutyEventList;
      }
      const rosterIds = rosters.map((r) => r.id);
      const twoMonthsAgo = moment().subtract(2, 'months');
      return pageData.dutyEventList.filter(
        (de) =>
          de.published &&
          rosterIds.includes(de.roster_id) &&
          moment(de.start_at).isAfter(twoMonthsAgo)
      );
    }
    return [];
  }, [pageData, rosters, isEditor]);

  const activeDutyEvents = useMemo(() => {
    if (dutyEvents && activeRosterId) {
      return dutyEvents.filter((de) => de.roster_id === activeRosterId);
    }
    return [];
  }, [dutyEvents, activeRosterId]);

  const weekendEvents = useMemo(() => {
    const newWeekendEvents = [];
    const start = moment(currentSettingsDutyEventCollectionStartDate);
    const end = moment(currentSettingsDutyEventCollectionEndDate);
    const startOfDay = start.clone();
    let idx = 0;
    while (startOfDay.isSameOrBefore(end)) {
      if (startOfDay.isoWeekday() === 6 || startOfDay.isoWeekday() === 7) {
        newWeekendEvents.push({
          id: `weekend-${idx}`,
          backgroundColor: '#DCDCDC',
          textColor: '#000000',
          allDay: true,
          start: startOfDay.toISOString(),
          display: 'background',
          extendedProps: {
            eventType: 'RosterWeekend',
            skipEventClick: true,
            skipEventPopover: true,
          },
        });
        idx += 1;
      }
      startOfDay.add(1, 'day');
    }
    return newWeekendEvents;
  }, [
    currentSettingsDutyEventCollectionStartDate,
    currentSettingsDutyEventCollectionEndDate,
  ]);

  const publicHolidayEvents = useMemo(() => {
    if (pageData?.publicHolidayList) {
      return pageData.publicHolidayList.map((ph, idx) => {
        const { id: publicHolidayId, name, date, observed } = ph;
        return {
          id: `public-holiday-${idx}`,
          title: date === observed ? name : `${name} (observed)`,
          start: moment(observed).startOf('day').format(),
          end: moment(observed).endOf('day').format(),
          allDay: true,
          display: 'background',
          backgroundColor: '#A9A9A9',
          textColor: '#000000',
          extendedProps: {
            eventType: 'RosterPublicHoliday',
            skipEventClick: true,
            skipEventPopover: true,
            publicHolidayId,
          },
        };
      });
    }
    return [];
  }, [pageData]);

  const blockedOutEvents = useMemo(() => {
    if (rosters.length && dutyEvents && activeRoster?.id) {
      // const rosterNames = pageData.rosterList.reduce(
      //   (accum, r) => ({ ...accum, [r.id]: r.name }),
      //   {}
      // );
      const { id: rosterId, contactRosters } = activeRoster;
      const contactIds = contactRosters.map((c) => c.contact_id);
      const filteredDutyEvents = dutyEvents.filter(
        (de) => de.roster_id !== rosterId && contactIds.includes(de.contact_id)
      );
      return filteredDutyEvents.map((de) => {
        const {
          id: dutyEventId,
          start_at: start,
          end_at: end,
          short_name: shortName,
          // roster_id: dutyEventRosterId,
          contact: { id: contactId, fullName },
        } = de;
        const resourceId = kebabCase(fullName);
        return {
          id: `blocked-out-${dutyEventId}`,
          // title: rosterNames[dutyEventRosterId],
          title: shortName,
          start,
          end,
          allDay: true,
          resourceId,
          display: 'background',
          backgroundColor: '#A9A9A9',
          textColor: '#000000',
          extendedProps: {
            eventType: 'RosterBlockedOut',
            skipEventClick: true,
            skipEventPopover: true,
            contactId,
          },
        };
      });
    }
    return [];
  }, [rosters, dutyEvents, activeRoster]);

  const rosteredEvents = useMemo(() => {
    if (activeRoster.id && activeDutyEvents) {
      const { contactRosters } = activeRoster;
      return compact(
        activeDutyEvents.map((dutyEvent) => {
          const {
            id: dutyEventId,
            short_name: shortName,
            description,
            published,
            duty_notes: dutyNotes,
            manager_notes: managerNotes,
            duty_color: dutyColor,
            duty_template_id: dutyTemplateId,
            start_at: startAt,
            end_at: endAt,
            fixed_time: fixedTime,
            contact_available: contactAvailable,
            contact: { id: contactId, fullName },
          } = dutyEvent;
          const contactRoster = contactRosters.find((c) => c.contact_id === contactId);
          if (contactRoster) {
            // only include duty events from the roster if the contact is still attached
            const resourceId = kebabCase(fullName);
            return {
              id: `rostered-${dutyEventId}`,
              allDay: true,
              resourceId,
              title: shortName,
              backgroundColor: dutyColor,
              textColor: getContrastYIQ(dutyColor),
              start: startAt,
              end: endAt,
              extendedProps: {
                eventType: 'Rostered',
                contactId,
                dutyEventId,
                dutyTemplateId,
                fixedTime,
                contactAvailable,
                description,
                dutyNotes,
                managerNotes,
                published,
              },
            };
          }
          return undefined;
        })
      );
    }
    return [];
  }, [activeRoster, activeDutyEvents]);

  const publishedThruEvents = useMemo(() => {
    if (activeRoster.id) {
      const { id: rosterId, published_thru: publishedThru } = activeRoster;
      if (publishedThru) {
        return [
          {
            id: `published-thu-${rosterId}`,
            start: moment(publishedThru).endOf('day').subtract(2, 'hours').format(),
            end: moment(publishedThru).endOf('day').subtract(1, 'hours').format(),
            display: 'background',
            color: 'black',
            extendedProps: {
              eventType: 'RosterPublicHoliday',
              skipEventClick: true,
              skipEventPopover: true,
              publishedThru,
            },
          },
        ];
      }
    }
    return [];
  }, [activeRoster]);

  const handleActiveRosterChange = useCallback((e) => {
    const newActiveRosterId = parseInt(e.target.value, 10);
    setActiveRosterId(newActiveRosterId);
  }, []);

  const handleRosterPublish = useCallback(
    async (input) => {
      const { id } = input;
      const mutationData = {
        variables: { id, input },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await rosterPublish(mutationData);
        toastSuccess('Roster publish ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Roster publish failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        pageRefetch();
      }
    },
    [rosterPublish, dispatch, pageRefetch]
  );

  const dispatchDutyEventCollectionVars = useCallback(
    (calendarStart, calendarEnd) => {
      dispatch(
        currentSettingsSet({
          dutyEventCollectionStartDate: calendarStart,
          dutyEventCollectionEndDate: calendarEnd,
        })
      );
    },
    [dispatch]
  );

  const handleDutyEventCreate = useCallback(
    async (input) => {
      const mutationData = {
        variables: { input },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await dutyEventCreate(mutationData);
        toastSuccess('Duty event create ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Duty event create failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        pageRefetch();
      }
    },
    [dutyEventCreate, dispatch, pageRefetch]
  );

  const handleDutyEventUpdate = useCallback(
    async (input) => {
      const { id } = input;
      const mutationData = {
        variables: { id, input },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await dutyEventUpdate(mutationData);
        toastSuccess('Duty event update ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Duty event update failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        pageRefetch();
      }
    },
    [dutyEventUpdate, dispatch, pageRefetch]
  );

  const handleDutyEventDelete = useCallback(
    async (id) => {
      const mutationData = {
        variables: { id },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await dutyEventDelete(mutationData);
        toastSuccess('Duty Event delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Duty Event delete failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        pageRefetch();
      }
    },
    [dutyEventDelete, dispatch, pageRefetch]
  );

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <Row className="justify-content-between g-0">
            <Col className="flex-grow-0 me-4 align-self-center">
              <h3>Rosters</h3>
            </Col>
            <Col>
              {rosters.length > 0 && (
                <Form.Select
                  size="lg"
                  className="d-inline-block w-25"
                  value={activeRosterId}
                  onChange={handleActiveRosterChange}
                >
                  {rosters.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            {isEditor && (
              <Col sm="auto" className="me-4">
                <CheckboxInputField
                  size="sm"
                  innerContentOnly
                  input={{
                    name: 'roster-power-edit',
                    value: powerEdit,
                    onChange: (e) => {
                      setPowerEdit(e.target.checked);
                    },
                  }}
                >
                  Admin Edit
                </CheckboxInputField>
              </Col>
            )}
            <Col sm="auto">
              <Button
                className="me-2"
                size="sm"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={pageLoading}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      {activeRosterId ? (
        <Roster
          isEditor={isEditor}
          powerEdit={powerEdit}
          roster={activeRoster}
          dutyEvents={activeDutyEvents}
          blockedOutEvents={blockedOutEvents}
          weekendEvents={weekendEvents}
          publicHolidayEvents={publicHolidayEvents}
          publishedThruEvents={publishedThruEvents}
          rosteredEvents={rosteredEvents}
          currentContact={currentContact}
          currentSettingsDutyEventCollectionStartDate={
            currentSettingsDutyEventCollectionStartDate
          }
          currentSettingsDutyEventCollectionEndDate={
            currentSettingsDutyEventCollectionEndDate
          }
          dispatchDutyEventCollectionVars={dispatchDutyEventCollectionVars}
          handleRosterPublish={handleRosterPublish}
          handleDutyEventCreate={handleDutyEventCreate}
          handleDutyEventUpdate={handleDutyEventUpdate}
          handleDutyEventDelete={handleDutyEventDelete}
        />
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>Select a Roster</Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default RosterList;
