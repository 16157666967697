import { Row, Col, Table } from 'react-bootstrap';
import { FieldArray } from 'redux-form';

import pick from 'lodash.pick';

import WeightBalanceAssetAssignmentFieldArray from './weight_balance_asset_assignment_field_array';

const WeightBalanceAssetAssignments = (props) => {
  const {
    field,
    formValues,
    change,
    flightSegmentIndex,
    flightSegmentsLength,
    isDeveloper,
  } = props;

  const { asset_assignments_attributes: assetAssignmentsAttributes } = formValues;

  const renderAssetAssignmentsFieldArray = (assetAssignments, optional) => {
    const formKeys = ['asset_assignments_attributes'];
    return (
      <WeightBalanceAssetAssignmentFieldArray
        assetAssignments={assetAssignments}
        formValues={pick(formValues, formKeys)}
        change={change}
        flightSegmentIndex={flightSegmentIndex}
        flightSegmentsLength={flightSegmentsLength}
        optional={optional}
        isDeveloper={isDeveloper}
      />
    );
  };

  const renderOptionalAssetAssignmentsFieldArray = (assetAssignments) =>
    renderAssetAssignmentsFieldArray(assetAssignments, true);

  const renderIncludedAssetAssignmentsFieldArray = (assetAssignments) =>
    renderAssetAssignmentsFieldArray(assetAssignments, false);

  const hasOptional = () =>
    assetAssignmentsAttributes
      .filter((aa) => !aa._destroy)
      .some((aa) => aa.asset_assignment_installed_weight_increase > 0);

  const hasIncluded = () =>
    assetAssignmentsAttributes
      .filter((aa) => !aa._destroy)
      .some((aa) => aa.asset_assignment_installed_weight_increase === 0);

  return (
    <Row className="mt-3">
      <Col xs={10}>
        {hasIncluded() && (
          <>
            <h4>Included</h4>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th style={{ width: '400px' }}>Name</th>
                  {isDeveloper && <th>Weight Unit</th>}
                  <th>Weight</th>
                  <th>Equipment Installed</th>
                </tr>
              </thead>
              <FieldArray
                name={`${field}.asset_assignments_attributes`}
                component={renderIncludedAssetAssignmentsFieldArray}
              />
            </Table>
          </>
        )}
        {hasOptional() && (
          <>
            <h4>Optional</h4>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th style={{ width: '400px' }}>Name</th>
                  {isDeveloper && <th>Weight Unit</th>}
                  <th>Weight</th>
                  <th>Equipment Installed</th>
                </tr>
              </thead>
              <FieldArray
                name={`${field}.asset_assignments_attributes`}
                component={renderOptionalAssetAssignmentsFieldArray}
              />
            </Table>
          </>
        )}
      </Col>
    </Row>
  );
};

export default WeightBalanceAssetAssignments;
