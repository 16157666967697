import { Component } from 'react';

import first from 'lodash.first';
import get from 'lodash.get';
import includes from 'lodash.includes';
import isEqual from 'lodash.isequal';

import InputField from '../form/input_field';

class FlightTypeInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.aircraft_id) {
      this.setState({
        selectOptions: this._getSelectOptions(),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { selectOptions } = this.state;
    if (this.props.aircraft_id !== nextProps.aircraft_id) {
      if (nextProps.aircraft_id) {
        selectOptions = this._getSelectOptions(nextProps);
      } else {
        selectOptions = [];
      }
      const firstNextChargeableId = get(
        first(nextProps.booking_chargeables_attributes.filter((bca) => !bca._destroy)),
        'chargeable_id',
        ''
      );
      this._setDefaultFlightTypeId(firstNextChargeableId, selectOptions);
    }
    if (
      !isEqual(
        this.props.booking_chargeables_attributes,
        nextProps.booking_chargeables_attributes
      )
    ) {
      let firstPrevChargeableId = '';
      if (
        this.props.booking_chargeables_attributes &&
        this.props.booking_chargeables_attributes.length > 0
      ) {
        firstPrevChargeableId = get(
          first(this.props.booking_chargeables_attributes.filter((bca) => !bca._destroy)),
          'chargeable_id',
          ''
        );
      }
      const firstNextChargeableId = get(
        first(nextProps.booking_chargeables_attributes.filter((bca) => !bca._destroy)),
        'chargeable_id',
        ''
      );
      if (firstPrevChargeableId !== firstNextChargeableId) {
        this._setDefaultFlightTypeId(firstNextChargeableId, selectOptions);
      }
    }
    this.setState({
      selectOptions,
    });
  }

  _getSelectOptions(props = this.props) {
    const aircraft = props.aircrafts.find(
      (a) => a.id === parseInt(props.aircraft_id, 10)
    );
    const flightTypeIds = aircraft.flightTypes.map((ft) => ft.id);
    return props.flightTypes
      .filter((ft) => includes(flightTypeIds, ft.id))
      .map((ft) => ({ id: ft.id, name: ft.name }));
  }

  _setDefaultFlightTypeId(firstNextChargeableId, selectOptions) {
    if (firstNextChargeableId) {
      const chargeable = this.props.chargeables.find(
        (c) => c.id === firstNextChargeableId
      );
      const defaultFlightTypeId = get(chargeable, 'default_flight_type_id', '');
      if (
        includes(
          selectOptions.map((ft) => ft.id),
          defaultFlightTypeId
        )
      ) {
        this.props.input.onChange(defaultFlightTypeId);
      } else {
        this.props.input.onChange('');
      }
    } else {
      this.props.input.onChange('');
    }
  }

  render() {
    const { input, meta } = this.props;
    return (
      <InputField
        type="text"
        size="sm"
        labelWidth={4}
        inputWidth={8}
        asElement="select"
        selectOptions={this.state.selectOptions}
        input={input}
        meta={meta}
      >
        {this.props.children}
      </InputField>
    );
  }
}

export default FlightTypeInputField;
