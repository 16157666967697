import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';

import compact from 'lodash.compact';
import debounce from 'lodash.debounce';
import defaultTo from 'lodash.defaultto';
import first from 'lodash.first';
import get from 'lodash.get';
import has from 'lodash.has';

import InvalidBlock from '../form/invalid_block';
import LabelBlock from '../form/label_block';
import ReactDateTimeField from '../form/react_date_time_field';
import InputField from '../form/input_field';

moment.updateLocale('en-nz');

class FlightSegmentStartDateLocationAndTimeInputFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      time: '',
      dateError: {},
      timeError: {},
      locationLongName: '',
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);

    this.handleLocationIdChange = this.handleLocationIdChange.bind(this);
    this.handleLocationIdInputChange = this.handleLocationIdInputChange.bind(this);
    this.handleLocationIdBlur = this.handleLocationIdBlur.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.start_at) {
      this.receiveStartAt();
      if (this.props.prevEndAt) {
        this.normalizeStartAt();
      }
    }
    if (this.props.start_location_id) {
      this.receiveStartLocationId();
    }
    this.delayedHandleLocationIdChange = debounce(
      (locations) => {
        const locationId = get(first(locations), 'id', '');
        const currentLocationId = defaultTo(
          parseInt(this.props.start_location_id, 10),
          ''
        );
        const newLocationId = defaultTo(parseInt(locationId, 10), '');
        if (currentLocationId !== newLocationId) {
          if (this.props.handleLocationChanged) {
            this.props.handleLocationChanged(this.props.index, locationId);
          }
        }
      },
      200,
      { leading: true, trailing: false }
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.start_at !== nextProps.start_at) {
      this.receiveStartAt(nextProps);
    }
    if (this.props.start_location_id !== nextProps.start_location_id) {
      this.receiveStartLocationId(nextProps);
    }
    if (nextProps.start_at && nextProps.prevEndAt) {
      this.normalizeStartAt(nextProps, this.props);
    }
  }

  componentWillUnmount() {
    this.delayedHandleLocationIdChange.cancel();
  }

  handleLocationIdInputChange(locationLongName) {
    this.setState({
      locationLongName,
    });
  }

  handleLocationIdChange(locations) {
    if (locations.length === 0 || has(first(locations), 'id')) {
      this.delayedHandleLocationIdChange(locations);
    }
  }

  handleLocationIdBlur(e) {
    if (!this.props.start_location_id && e.target.value) {
      this.setState({
        locationLongName: '',
      });
    }
  }

  handleDateChange(newDate) {
    const date = moment(newDate);
    if (date.isValid()) {
      const formattedDate = date.format('DD/MM/YYYY');
      this.setState({
        date: formattedDate,
        dateError: {},
      });
      const { time, timeError } = this.state;
      if (!timeError.invalid) {
        const input = get(this.props, `${this.props.field}.start_at.input`);
        input.onChange(moment(`${formattedDate} ${time}`, 'DD/MM/YYYY HHmm').format());
      }
    } else {
      this.setState({
        date,
        dateError: { touched: true, invalid: true, error: 'DD/MM/YYYY' },
      });
    }
  }

  handleTimeChange(e) {
    let time = e.target.value;
    time = time === '2400' ? '2359' : time;
    if (time.match(/^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/)) {
      this.setState({
        time,
        timeError: {},
      });
      const { date, dateError } = this.state;
      if (!dateError.invalid) {
        const input = get(this.props, `${this.props.field}.start_at.input`);
        input.onChange(moment(`${date} ${time}`, 'DD/MM/YYYY HHmm').format());
      }
    } else {
      this.setState({
        time,
        timeError: { touched: true, invalid: true, error: 'HHMM' },
      });
    }
  }

  getSelectableLocations() {
    return this.props.selectableLocations;
  }

  receiveStartLocationId(props = this.props) {
    let locationLongName = '';
    const locationId = defaultTo(parseInt(props.start_location_id, 10), '');
    if (locationId) {
      // we use long name as that matches the typeahead label return from server.
      locationLongName = get(this.props.locationsDataSelector, [locationId, 'longName']);
    }
    this.setState({
      locationLongName,
    });
  }

  receiveStartAt(props = this.props) {
    this.setState({
      date: moment(props.start_at).format('DD/MM/YYYY'),
      time: moment(props.start_at).format('HHmm'),
    });
  }

  normalizeStartAt(props = this.props, prevProps = {}) {
    const mmStartAt = moment(props.start_at);
    const mmPrevEndAt = moment(props.prevEndAt);
    if (mmPrevEndAt.isBefore(mmStartAt)) {
      if (prevProps.prevEndAt && prevProps.start_at) {
        if (prevProps.start_at === props.start_at) {
          const mmPrevStartAt = moment(prevProps.start_at);
          const mmPrevPrevEndAt = moment(prevProps.prevEndAt);
          const prevDayDiff = mmPrevStartAt.diff(mmPrevPrevEndAt, 'days');
          const currentDayDiff = mmStartAt.diff(mmPrevEndAt, 'days');
          if (currentDayDiff > prevDayDiff) {
            mmStartAt.subtract(currentDayDiff - prevDayDiff, 'days');
            const input = get(props, `${props.field}.start_at.input`);
            input.onChange(mmStartAt.format());
          }
        }
      }
    } else if (mmPrevEndAt.isAfter(mmStartAt)) {
      if (mmPrevEndAt.isAfter(mmStartAt, 'day')) {
        mmStartAt.set({
          year: mmPrevEndAt.year(),
          month: mmPrevEndAt.month(),
          date: mmPrevEndAt.date(),
        });
      }
      if (mmPrevEndAt.isAfter(mmStartAt, 'second')) {
        mmStartAt.set({
          hour: mmPrevEndAt.hour(),
          minute: mmPrevEndAt.minute(),
          second: mmPrevEndAt.second(),
        });
      }
      const input = get(props, `${props.field}.start_at.input`);
      input.onChange(mmStartAt.format());
    }
  }

  renderTimeOnGround() {
    if (get(this.props.flightSegmentCalculatedData, 'timeOnGroundFormatted')) {
      return `ToG ${get(
        this.props.flightSegmentCalculatedData,
        'timeOnGroundFormatted'
      )}`;
    }
    return <span>&nbsp;</span>;
  }

  render() {
    const {
      start_at: { input: startAtInput },
      start_location_id: { input: startLocationIdInput, meta: startLocationIdMeta },
    } = get(this.props, this.props.field);
    return (
      <Row>
        <Col xs={4} className="pe-1">
          <ReactDateTimeField
            size="sm"
            dateFormat="DD/MM/YYYY"
            closeOnSelect
            input={{
              ...startAtInput,
              name: `${startAtInput.name}-date`,
              onChange: this.handleDateChange,
            }}
            labelWidth={0}
            inputWidth={12}
            inputProps={{ tabIndex: '-1' }}
            groupClassName="p-0"
          >
            {this.renderTimeOnGround()}
          </ReactDateTimeField>
        </Col>
        <Col xs={5} className="px-1">
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={12}
            input={{
              name: startLocationIdInput.name,
            }}
            groupClassName="p-0"
            innerContent={
              <>
                <LabelBlock size="sm">&nbsp;</LabelBlock>
                <Typeahead
                  id={`${startLocationIdInput.name}-id`}
                  onInputChange={this.handleLocationIdInputChange}
                  onChange={this.handleLocationIdChange}
                  onBlur={this.handleLocationIdBlur}
                  options={this.getSelectableLocations()}
                  selected={compact([this.state.locationLongName])}
                  filterBy={['searchable_name']}
                  labelKey="name"
                  minLength={2}
                  size="sm"
                  placeholder="Start Location..."
                />
                <InvalidBlock meta={startLocationIdMeta} force />
              </>
            }
          />
        </Col>
        <Col xs={3} className="px-1">
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={12}
            groupClassName="p-0"
            input={{
              name: `${startAtInput.name}-time`,
              value: this.state.time,
              onChange: this.handleTimeChange,
            }}
            meta={{ ...this.state.timeError }}
            {...(this.props.start_critical && {
              inputProps: { style: { backgroundColor: 'yellow' } },
            })}
          >
            &nbsp;
          </InputField>
        </Col>
      </Row>
    );
  }
}

export default FlightSegmentStartDateLocationAndTimeInputFields;
