import get from 'lodash.get';
import memoize from 'lodash.memoize';

export const AircraftTypeAirspeedDataMemo = memoize(
  ({ aircraftTypeId, aircraftTypes }) => {
    if (aircraftTypeId) {
      const aircraftType = aircraftTypes.find(
        (at) => at.id === parseInt(aircraftTypeId, 10)
      );
      return {
        aircraftTypeAirspeedUnit: get(aircraftType, 'airspeed_unit'),
        aircraftTypeCruiseAirspeedSl: get(aircraftType, 'cruise_airspeed_sl'),
        aircraftTypeDepartureAndArrivalLoading: get(
          aircraftType,
          'departure_and_arrival_loading'
        ),
      };
    }
    return {};
  },
  ({ aircraftTypeId }) => {
    if (aircraftTypeId) {
      return [aircraftTypeId].map((i) => String(i)).join('.');
    }
    return 'unknown';
  }
);
