import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady, coerceInput } from '../lib/utils';

import fuelTankerUpdateMutation from '../mutations/fuel_tanker_update_mutation';

import fuelBowserFillDetailForMonthForFuelTankerQuery from '../queries/fuel_bowser_fill_detail_for_month_for_fuel_tanker_query';
import locationListQuery from '../queries/location_min_list_query';
import fuelTankerListQuery from '../queries/fuel_tanker_list_query';

moment.updateLocale('en-nz');

class ReportFuelBowserFill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterLocationId: this.props.currentSettingsReportLocationId,
      filterLocationIds: [],
      filterFuelTankerId: this.props.currentSettingsReportFuelTankerId,
      filterFuelTankerIds: [],
      fuel_bowser_fill_ids: {},
    };
    this._handleDisplayLocationIdChange = this._handleDisplayLocationIdChange.bind(this);
    this._handleDisplayFuelTankerIdChange =
      this._handleDisplayFuelTankerIdChange.bind(this);
    this._handleFuelBowserFillInvoicedChange =
      this._handleFuelBowserFillInvoicedChange.bind(this);
    this._handleSaveFuelBowserFillInvoicedClick =
      this._handleSaveFuelBowserFillInvoicedClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.fuelTankerId) {
      this._handleDisplayFuelTankerIdChange({
        target: { value: this.props.params.fuelTankerId },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.fuelBowserFillDetailForMonthForFuelTankerQuery.refetch();
      this.setState({
        fuel_bowser_fill_ids: {},
      });
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterLocationIds } = this.state;
    let { filterFuelTankerIds } = this.state;
    if (!this.isLoaded(this.props) && this.isLoaded(nextProps)) {
      filterLocationIds =
        nextProps.fuelBowserFillDetailForMonthForFuelTankerQuery.data.map(
          (data) => data.location_id
        );
      filterFuelTankerIds =
        nextProps.fuelBowserFillDetailForMonthForFuelTankerQuery.data.map(
          (data) => data.fuel_tanker_id
        );
    }
    filterLocationIds = [...new Set(filterLocationIds)];
    filterFuelTankerIds = [...new Set(filterFuelTankerIds)];
    this.setState({
      filterLocationIds,
      filterFuelTankerIds,
    });
    let filterLocationId = nextProps.currentSettingsReportLocationId;
    if (filterLocationId && this.isLoaded(nextProps)) {
      if (
        filterLocationIds.length > 0 &&
        filterLocationIds.indexOf(filterLocationId) === -1
      ) {
        filterLocationId = '';
      }
    }
    if (filterLocationId !== this.props.currentSettingsReportLocationId) {
      this._handleDisplayLocationIdChange({ target: { value: filterLocationId } });
    }

    let filterFuelTankerId = nextProps.currentSettingsReportFuelTankerId;
    if (filterFuelTankerId && this.isLoaded(nextProps)) {
      if (
        filterFuelTankerIds.length > 0 &&
        filterFuelTankerIds.indexOf(filterFuelTankerId) === -1
      ) {
        filterFuelTankerId = '';
      }
    }
    if (filterFuelTankerId !== this.props.currentSettingsReportFuelTankerId) {
      this._handleDisplayFuelTankerIdChange({ target: { value: filterFuelTankerId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.fuelBowserFillDetailForMonthForFuelTankerQuery,
      props.locationListQuery,
      props.fuelTankerListQuery
    );
  }

  _handleDisplayLocationIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterLocationId: value,
    });
    this.props.currentSettingsSet({
      reportLocationId: value,
    });
  }

  _handleDisplayFuelTankerIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterFuelTankerId: value,
    });
    this.props.currentSettingsSet({
      reportFuelTankerId: value,
    });
  }

  _handleSaveFuelBowserFillInvoicedClick() {
    let mutated = false;
    const { fuel_bowser_fill_ids } = this.state;
    Object.keys(fuel_bowser_fill_ids).forEach((fuel_bowser_fill_id) => {
      mutated = true;
      this.props.mutationSet(true);
      const { fuel_tanker_id, quantity_value, invoiced } =
        fuel_bowser_fill_ids[fuel_bowser_fill_id];
      this.props
        .fuelTankerUpdateMutation({
          variables: {
            id: fuel_tanker_id,
            input: coerceInput({
              id: fuel_tanker_id,
              fuel_bowser_fills_attributes: {
                id: fuel_bowser_fill_id,
                quantity_value,
                invoiced,
              },
            }),
          },
        })
        .then((res) => {
          this.props.mutationSuccess('Fuel Tanker fuel invoice update');
        })
        .catch((err) => {
          this.props.mutationFailure(err);
        });
    });
    if (mutated) {
      this.delayedHandleRefetch();
    }
  }

  _handleFuelBowserFillInvoicedChange(e) {
    const { fuel_bowser_fill_ids } = this.state;
    const [, fuel_tanker_id, fuel_bowser_fill_id, quantity_value] =
      e.target.id.split('-');
    fuel_bowser_fill_ids[fuel_bowser_fill_id] = {
      fuel_tanker_id,
      quantity_value: parseFloat(quantity_value),
      invoiced: e.target.checked,
    };
    this.setState({
      fuel_bowser_fill_ids,
    });
  }

  _renderFixed(value) {
    return value ? value.toFixed(2) : '-';
  }

  _renderRow(fuel_bowser_fill) {
    const {
      id,
      fuel_tanker_id,
      fuel_bowser_fill_id,
      filled_on_s,
      fuel_tanker_name,
      fuel_bowser_name,
      location_short_name,
      price,
      quantity_value,
      quantity_s,
      invoiced,
    } = fuel_bowser_fill;

    return (
      <tr key={id}>
        <td>{filled_on_s}</td>
        <td>{location_short_name}</td>
        <td>{fuel_tanker_name}</td>
        <td>{fuel_bowser_name}</td>
        <td className="text-end">{quantity_s}</td>
        <td className="text-end">
          <LinkContainer
            to={`/fuel_tankers/${fuel_tanker_id}/fuel_bowser_fill/${fuel_bowser_fill_id}/edit`}
            className="ps-0"
          >
            <Button variant="link" size="sm" className="text-start p-0 m-0">
              edit
            </Button>
          </LinkContainer>
        </td>
        <td className="text-end">
          <input
            id={`toggleinvoiced-${fuel_tanker_id}-${fuel_bowser_fill_id}-${quantity_value}`}
            type="checkbox"
            checked={
              this.state.fuel_bowser_fill_ids[fuel_bowser_fill_id] !== undefined
                ? this.state.fuel_bowser_fill_ids[fuel_bowser_fill_id].invoiced
                : invoiced
            }
            onChange={this._handleFuelBowserFillInvoicedChange}
          />
        </td>
      </tr>
    );
  }

  _renderHeaderRow() {
    return (
      <thead>
        <tr>
          <th className="border-top-0">Date</th>
          <th className="border-top-0">Location</th>
          <th className="border-top-0">Fuel Tanker</th>
          <th className="border-top-0">Bowser</th>
          <th className="text-end border-top-0">Qty</th>
          <th className="text-end border-top-0">Edit</th>
          <th className="text-end border-top-0">Received</th>
        </tr>
      </thead>
    );
  }

  _renderFuelBowserFills() {
    const fuel_bowser_fills =
      this.props.fuelBowserFillDetailForMonthForFuelTankerQuery.data.filter(
        (fuel_bowser_fill) => {
          if (
            this.state.filterFuelTankerId &&
            fuel_bowser_fill.fuel_tanker_id !== this.state.filterFuelTankerId
          ) {
            return false;
          }
          if (
            this.state.filterLocationId &&
            fuel_bowser_fill.location_id !== this.state.filterLocationId
          ) {
            return false;
          }
          return true;
        }
      );
    return (
      <Row xs={1}>
        <Col className="d-flex justify-content-end align-items-start">
          <Button
            variant="primary"
            onClick={this._handleSaveFuelBowserFillInvoicedClick}
            className="py-0"
          >
            Save Received
          </Button>
        </Col>
        <Col>
          <Table striped size="sm">
            {this._renderHeaderRow()}
            <tbody>
              {fuel_bowser_fills.map((fuel_bowser_fill) =>
                this._renderRow(fuel_bowser_fill)
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }

  _renderLocationFilter() {
    const dataLocations = this.props.locationListQuery.data.filter(
      (model) => this.state.filterLocationIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterLocationId',
          value: this.state.filterLocationId,
          onChange: this._handleDisplayLocationIdChange,
        }}
        asElement="select"
        selectOptions={dataLocations}
        optionKey="fullName"
        defaultSelectOptionName="All"
      />
    );
  }

  _renderFuelTankerFilter() {
    const dataFuelTankers = this.props.fuelTankerListQuery.data.filter(
      (model) => this.state.filterFuelTankerIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterFuelTankerId',
          value: this.state.filterFuelTankerId,
          onChange: this._handleDisplayFuelTankerIdChange,
        }}
        asElement="select"
        selectOptions={dataFuelTankers}
        optionKey="name"
        defaultSelectOptionName="All"
      />
    );
  }

  _renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      return (
        <>
          <Row className="my-3">
            <Col>
              <ReportHeader
                title="Tanker Refuelling by Bowser Report"
                start={this.props.currentSettingsReportStart}
                end={this.props.currentSettingsReportEnd}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="auto" className="px-0">
              <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
                <Glyphicon glyph="repeat" />
              </Button>
            </Col>
            <ReactDateTimeFilter
              size="sm"
              labelWidth={0}
              inputWidth={0}
              currentSettingsReportStart={this.props.currentSettingsReportStart}
              currentSettingsReportEnd={this.props.currentSettingsReportEnd}
              onChange={this.props.currentSettingsSet}
              closeOnSelect
            />
            {this._renderLocationFilter()}
            {this._renderFuelTankerFilter()}
          </Row>
          {this._renderFuelBowserFills()}
        </>
      );
    }
  }

  render() {
    return (
      <>
        {this._renderOverlay()}
        {this._renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportLocationId: state.currentSettings.reportLocationId,
    currentSettingsReportFuelTankerId: state.currentSettings.reportFuelTankerId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  graphql(fuelTankerUpdateMutation, {
    name: 'fuelTankerUpdateMutation',
  }),
  graphql(locationListQuery, {
    name: 'locationListQuery',
  }),
  graphql(fuelTankerListQuery, {
    name: 'fuelTankerListQuery',
  }),
  graphql(fuelBowserFillDetailForMonthForFuelTankerQuery, {
    name: 'fuelBowserFillDetailForMonthForFuelTankerQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportFuelBowserFill);
