import Ajv from 'ajv';
import extendAjvErrors from 'ajv-errors';
import addFormats from 'ajv-formats';

import cloneDeep from 'lodash.clonedeep';
import compact from 'lodash.compact';
import get from 'lodash.get';
import set from 'lodash.set';

import { sanitize } from '../lib/sanitizer';

const makeValidator = (schema) => {
  const ajv = new Ajv({
    allErrors: true,
    coerceTypes: true,
    $data: true,
  });
  addFormats(ajv);
  extendAjvErrors(ajv);
  const validator = ajv.compile(schema);
  return (values) => {
    const errors = {};
    const testValues = sanitize(cloneDeep(values));

    validator(testValues);
    if (validator.errors) {
      validator.errors.forEach((error) => {
        const { message, instancePath, keyword, params = {} } = error;
        const { missingProperty } = params;

        // /flightSegments/1/seatAssignments/4
        let errorPath = instancePath.replace(/\//g, '.');
        // .flightSegments.1.seatAssignments.4
        errorPath = errorPath.replace(/^\./, '');
        // flightSegments.1.seatAssignments.4

        if (keyword === 'required') {
          errorPath = compact([errorPath, missingProperty]).join('.');
          // flightSegments.1.seatAssignments.4.passenger_id
        }
        if (errorPath) {
          if (
            message !== 'must match "then" schema' &&
            message !== 'must match "else" schema'
          ) {
            set(errors, errorPath, [...get(errors, errorPath, []), message]);
          }
        }
      });
    }
    return errors;
  };
};

export default makeValidator;
