import { useState, useEffect } from 'react';
import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

import InputField from '../form/input_field';
import CheckboxInputField from '../form/checkbox_input_field';

function DutyEventModal(props) {
  const {
    show,
    rosterId,
    dutyEvent = {},
    handleDutyEventModalCancel,
    handleDutyEventModalUpdate,
    handleDutyEventModalDelete,
  } = props;
  const {
    id: dutyEventId,
    name,
    published: origPublished,
    start_time: origStartTime,
    end_time: origEndTime,
    duty_notes: origDutyNotes,
    manager_notes: origManagerNotes,
  } = dutyEvent;

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [dutyNotes, setDutyNotes] = useState('');
  const [managerNotes, setManagerNotes] = useState('');
  const [published, setPublished] = useState(false);

  useEffect(() => {
    let newStartTime = '';
    if (origStartTime) {
      newStartTime = origStartTime;
    }
    setStartTime(newStartTime);
  }, [origStartTime]);

  useEffect(() => {
    let newEndTime = '';
    if (origEndTime) {
      newEndTime = origEndTime;
    }
    setEndTime(newEndTime);
  }, [origEndTime]);

  useEffect(() => {
    let newDutyNotes = '';
    if (origDutyNotes) {
      newDutyNotes = origDutyNotes;
    }
    setDutyNotes(newDutyNotes);
  }, [origDutyNotes]);

  useEffect(() => {
    let newManagerNotes = '';
    if (origManagerNotes) {
      newManagerNotes = origManagerNotes;
    }
    setManagerNotes(newManagerNotes);
  }, [origManagerNotes]);

  useEffect(() => {
    let newPublished = false;
    if (origPublished) {
      newPublished = origPublished;
    }
    setPublished(newPublished);
  }, [origPublished]);

  // TODO this is not how this is meant to work but
  // for some reason when this modal dismounts via show={show}
  // the modal and backdrop and not cleared away and the user
  // cannot click anything on the screen.  This seems related
  // https://github.com/react-bootstrap/react-bootstrap/issues/6153
  // set animations to false works

  return (
    <Modal
      id={`roster-${rosterId}-duty-event-modal-${dutyEventId}`}
      show={show}
      onHide={handleDutyEventModalCancel}
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${name}-${dutyEventId}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputField
          type="text"
          size="sm"
          labelWidth={4}
          inputWidth={4}
          input={{
            name: `roster-${rosterId}-duty-event-modal-${dutyEventId}-start-time`,
            value: startTime,
            onChange: (e) => {
              setStartTime(e.target.value);
            },
          }}
        >
          Duty Start Time
        </InputField>
        <InputField
          type="text"
          size="sm"
          labelWidth={4}
          inputWidth={4}
          input={{
            name: `roster-${rosterId}-duty-event-modal-${dutyEventId}-end-time`,
            value: endTime,
            onChange: (e) => {
              setEndTime(e.target.value);
            },
          }}
        >
          Duty End Time
        </InputField>
        <InputField
          type="text"
          size="sm"
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          rows={3}
          input={{
            name: `roster-${rosterId}-duty-event-modal-${dutyEventId}-duty-notes`,
            value: dutyNotes,
            onChange: (e) => {
              setDutyNotes(e.target.value);
            },
          }}
        >
          Duty Notes
        </InputField>
        <InputField
          type="text"
          size="sm"
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          rows={3}
          input={{
            name: `roster-${rosterId}-duty-event-modal-${dutyEventId}-manager-notes`,
            value: managerNotes,
            onChange: (e) => {
              setManagerNotes(e.target.value);
            },
          }}
        >
          Manager Notes
        </InputField>
        <CheckboxInputField
          input={{
            name: `roster-${rosterId}-duty-event-modal-${dutyEventId}-published`,
            value: published,
            onChange: (e) => {
              setPublished(e.target.checked);
            },
          }}
          labelWidth={4}
          inputWidth={8}
          inlineLabel
        >
          Published
        </CheckboxInputField>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar className="d-flex justify-content-between w-100">
          <ButtonGroup>
            <Button type="button" variant="danger" onClick={handleDutyEventModalDelete}>
              Delete Duty Event
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              type="button"
              variant="secondary"
              onClick={handleDutyEventModalCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="primary"
              // disabled={!this.state.firstName}
              onClick={() =>
                handleDutyEventModalUpdate({
                  startTime,
                  endTime,
                  dutyNotes,
                  managerNotes,
                  published,
                })
              }
            >
              Save
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
}

export default DutyEventModal;
