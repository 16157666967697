import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import classifyPoint from 'robust-point-in-polygon';

import get from 'lodash.get';
import clone from 'lodash.clone';
import first from 'lodash.first';
import flatten from 'lodash.flatten';
import last from 'lodash.last';
import memoize from 'lodash.memoize';

import { BookingCalculatedDataMemo } from './booking_calculated_data_memo';
import { LocationsDistanceMemo } from './locations_distance_memo';
import { LocationFuelDataMemo } from './location_fuel_data_memo';
import {
  convertFromTo,
  convertFuelToMass,
  convertMassToFuel,
  round10,
} from '../lib/convert_units';
import { defaultConvertedWbResult } from '../defaults';

moment.updateLocale('en-nz');

export const FlightSegmentCalculatedDataMemo = memoize(
  ({
    aircraftId,
    aircraftConfigurationId,
    bookingModifiers,
    bookingModifiersObjectHash,
    activeFlightSegmentIndex,
    flightSegment,
    flightSegments,
    aircrafts,
    aircraftTypes,
    aircraftConfigurations,
    fuelTypes,
    locations,
    fuelBowsers,
    fuelTankers,
  }) => {
    if (aircraftId && aircraftConfigurationId) {
      const bookingCalculatedData = BookingCalculatedDataMemo({
        aircraftId,
        bookingModifiersObjectHash,
        bookingModifiers,
        aircrafts,
        aircraftTypes,
        fuelTypes,
      });

      const {
        aircraftTypeWeightUnit,
        aircraftTypeArmUnit,
        aircraftTypeMaximumAllUpWeight,
        aircraftTypeMaximumAllUpWeightWithExternalLoad,
        aircraftTypeFuelTypeSpecificGravity,
        aircraftTypeFuelTypeId,
        aircraftTypeDepartureAndArrivalLoading,
        //
        bookingAircraftLongitudinalArm,
        bookingAircraftLateralArm,
        bookingAircraftWeight,
        //
        bookingCaaWeightLoading,
        bookingCaaWeightLoadingIncluded,
        copilotId,
        //
        bookingCruiseAirspeedSl,
        bookingCruiseAirspeedSlKmph,
        //
        bookingCruiseFuelConsumptionSl,
        //
        bookingReserveConsumptionSl,
        //
        bookingArmUnit,
        bookingWeightUnit,
        bookingAirspeedUnit,
        bookingDistanceUnit,
        bookingFuelUnit,
        //
        kmphUnit,
        meterUnit,
      } = bookingCalculatedData;

      const aircraftConfiguration = aircraftConfigurations.find(
        (ac) => ac.id === parseInt(aircraftConfigurationId, 10)
      );

      const { wbLongitudinalLimit, wbLateralLimit } = aircraftConfiguration;

      const {
        wbLimitPoints: wbLongitudinalLimitPoints = [],
        arm_unit: wbLongitudinalLimitArmUnit,
        weight_unit: wbLongitudinalLimitWeightUnit,
      } = wbLongitudinalLimit;

      const convertedWbLongitudinalLimitPoints = clone(wbLongitudinalLimitPoints)
        .sort((a, b) => a.position - b.position)
        .map((point) => {
          const {
            wb_limit_arm: wbLimitArm,
            wb_limit_weight: wbLimitWeight,
            position,
          } = point;
          return {
            converted_wb_limit_arm: convertFromTo(
              parseFloat(wbLimitArm),
              wbLongitudinalLimitArmUnit,
              bookingArmUnit
            ),
            converted_wb_limit_weight: convertFromTo(
              parseFloat(wbLimitWeight),
              wbLongitudinalLimitWeightUnit,
              bookingWeightUnit
            ),
            position,
          };
        });

      const longitudinalPolygon = convertedWbLongitudinalLimitPoints.map((p) => [
        p.converted_wb_limit_arm,
        p.converted_wb_limit_weight,
      ]);

      const {
        wbLimitPoints: wbLateralLimitPoints = [],
        arm_unit: wbLateralLimitArmUnit,
        weight_unit: wbLateralLimitWeightUnit,
      } = wbLateralLimit;

      const convertedWbLateralLimitPoints = clone(wbLateralLimitPoints)
        .sort((a, b) => a.position - b.position)
        .map((point) => {
          const {
            wb_limit_arm: wbLimitArm,
            wb_limit_weight: wbLimitWeight,
            position,
          } = point;
          return {
            converted_wb_limit_arm: convertFromTo(
              parseFloat(wbLimitArm),
              wbLateralLimitArmUnit,
              bookingArmUnit
            ),
            converted_wb_limit_weight: convertFromTo(
              parseFloat(wbLimitWeight),
              wbLateralLimitWeightUnit,
              bookingWeightUnit
            ),
            position,
          };
        });

      const lateralPolygon = convertedWbLateralLimitPoints.map((p) => [
        p.converted_wb_limit_arm,
        p.converted_wb_limit_weight,
      ]);

      const { wbExternalLongitudinalLimit, wbExternalLateralLimit } =
        aircraftConfiguration;

      let convertedWbExternalLongitudinalLimitPoints;
      let convertedWbExternalLateralLimitPoints;

      if (wbExternalLongitudinalLimit && wbExternalLateralLimit) {
        const {
          wbLimitPoints: wbExternalLongitudinalLimitPoints = [],
          arm_unit: wbExternalLongitudinalLimitArmUnit,
          weight_unit: wbExternalLongitudinalLimitWeightUnit,
        } = wbExternalLongitudinalLimit;

        convertedWbExternalLongitudinalLimitPoints = clone(
          wbExternalLongitudinalLimitPoints
        )
          .sort((a, b) => a.position - b.position)
          .map((point) => {
            const {
              wb_limit_arm: wbLimitArm,
              wb_limit_weight: wbLimitWeight,
              position,
            } = point;
            return {
              converted_wb_limit_arm: convertFromTo(
                parseFloat(wbLimitArm),
                wbExternalLongitudinalLimitArmUnit,
                bookingArmUnit
              ),
              converted_wb_limit_weight: convertFromTo(
                parseFloat(wbLimitWeight),
                wbExternalLongitudinalLimitWeightUnit,
                bookingWeightUnit
              ),
              position,
            };
          });

        // const externalLongitudinalPolygon =
        //   convertedWbExternalLongitudinalLimitPoints.map((p) => [
        //     p.converted_wb_limit_arm,
        //     p.converted_wb_limit_weight,
        //   ]);

        const {
          wbLimitPoints: wbExternalLateralLimitPoints = [],
          arm_unit: wbExternalLateralLimitArmUnit,
          weight_unit: wbExternalLateralLimitWeightUnit,
        } = wbExternalLateralLimit;

        convertedWbExternalLateralLimitPoints = clone(wbExternalLateralLimitPoints)
          .sort((a, b) => a.position - b.position)
          .map((point) => {
            const {
              wb_limit_arm: wbLimitArm,
              wb_limit_weight: wbLimitWeight,
              position,
            } = point;
            return {
              converted_wb_limit_arm: convertFromTo(
                parseFloat(wbLimitArm),
                wbExternalLateralLimitArmUnit,
                bookingArmUnit
              ),
              converted_wb_limit_weight: convertFromTo(
                parseFloat(wbLimitWeight),
                wbExternalLateralLimitWeightUnit,
                bookingWeightUnit
              ),
              position,
            };
          });

        // const externalLateralPolygon = convertedWbExternalLateralLimitPoints.map((p) => [
        //   p.converted_wb_limit_arm,
        //   p.converted_wb_limit_weight,
        // ]);
      }

      const {
        index: flightSegmentIndex,
        seat_assignments_attributes: seatAssignmentsAttributes,
        hold_assignments_attributes: holdAssignmentsAttributes,
        tank_assignments_attributes: tankAssignmentsAttributes,
        asset_assignments_attributes: assetAssignmentsAttributes,
        external_load_assignments_attributes: externalLoadAssignmentsAttributes,
      } = flightSegment;

      const convertedAircraftTypeMaximumAllUpWeight = convertFromTo(
        aircraftTypeMaximumAllUpWeight,
        aircraftTypeWeightUnit,
        bookingWeightUnit
      );

      const convertedAircraftTypeMaximumAllUpWeightWithExternalLoad = convertFromTo(
        aircraftTypeMaximumAllUpWeightWithExternalLoad,
        aircraftTypeWeightUnit,
        bookingWeightUnit
      );

      let convertedEmptyAircraft = defaultConvertedWbResult('Basic Empty Weight');
      if (
        bookingWeightUnit &&
        bookingArmUnit &&
        aircraftTypeWeightUnit &&
        aircraftTypeArmUnit &&
        // using loose != null checks to pick up zeros
        bookingAircraftWeight != null &&
        bookingAircraftLongitudinalArm != null &&
        bookingAircraftLateralArm != null
      ) {
        const newWeight = convertFromTo(
          bookingAircraftWeight,
          aircraftTypeWeightUnit,
          bookingWeightUnit
        );
        const newLongitudinalArm = convertFromTo(
          bookingAircraftLongitudinalArm,
          aircraftTypeArmUnit,
          bookingArmUnit
        );
        const newLateralArm = convertFromTo(
          bookingAircraftLateralArm,
          aircraftTypeArmUnit,
          bookingArmUnit
        );
        convertedEmptyAircraft = {
          ...convertedEmptyAircraft,
          weight: newWeight,
          longitudinalArm: newLongitudinalArm,
          longitudinalMoment: newWeight * newLongitudinalArm,
          lateralArm: newLateralArm,
          lateralMoment: newWeight * newLateralArm,
        };
      }

      let convertedSeatAssignments = [];
      if (seatAssignmentsAttributes) {
        convertedSeatAssignments = seatAssignmentsAttributes
          .filter((sa) => !sa._destroy)
          .map((seatAssignment) => {
            let results = [];
            const {
              id,
              name,
              pax_name: paxName,
              position,
              seat_assignment_installed: seatAssignmentInstalled,
              seat_assignment_seat_type: seatAssignmentSeatType,
              seat_assignment_arm_unit: seatAssignmentArmUnit,
              seat_assignment_lateral_arm: seatAssignmentLateralArm,
              seat_assignment_longitudinal_arm: seatAssignmentLongitudinalArm,
              seat_assignment_weight_unit: seatAssignmentWeightUnit,
              seat_assignment_weight: seatAssignmentWeight,
              seat_assignment_installed_weight_increase:
                seatAssignmentInstalledWeightIncrease,
              seat_assignment_uninstalled_weight_reduction:
                seatAssignmentUninstalledWeightReduction,
            } = seatAssignment;

            const newLongitudinalArm = convertFromTo(
              seatAssignmentLongitudinalArm,
              seatAssignmentArmUnit,
              bookingArmUnit
            );
            const newLateralArm = convertFromTo(
              seatAssignmentLateralArm,
              seatAssignmentArmUnit,
              bookingArmUnit
            );
            const newWeight = convertFromTo(
              seatAssignmentWeight,
              seatAssignmentWeightUnit,
              bookingWeightUnit
            );

            if (seatAssignmentInstalled) {
              results = [
                ...results,
                {
                  id: `sa-${id}-${uuidv4()}`,
                  position,
                  name: `${name} - ${paxName || 'Vacant'}`,
                  weight: newWeight,
                  longitudinalArm: newLongitudinalArm,
                  longitudinalMoment: newWeight * newLongitudinalArm,
                  lateralArm: newLateralArm,
                  lateralMoment: newWeight * newLateralArm,
                },
              ];

              if (
                bookingCaaWeightLoadingIncluded &&
                bookingCaaWeightLoading > 0 &&
                newWeight > 0 &&
                (seatAssignmentSeatType === 'pax_only' ||
                  (seatAssignmentSeatType === 'copilot_or_pax' && !copilotId))
              ) {
                // the caa weight is already in booking weight unit
                const caaWeight = convertFromTo(
                  bookingCaaWeightLoading,
                  bookingWeightUnit,
                  bookingWeightUnit
                );
                results = [
                  ...results,
                  {
                    id: `sa-${id}-${uuidv4()}-caa`,
                    position,
                    name: `${name} - CAA Weight Loading`,
                    weight: caaWeight,
                    longitudinalArm: newLongitudinalArm,
                    longitudinalMoment: caaWeight * newLongitudinalArm,
                    lateralArm: newLateralArm,
                    lateralMoment: caaWeight * newLateralArm,
                  },
                ];
              }
              if (seatAssignmentInstalledWeightIncrease) {
                const installedWeight = convertFromTo(
                  seatAssignmentInstalledWeightIncrease,
                  seatAssignmentWeightUnit,
                  bookingWeightUnit
                );
                results = [
                  ...results,
                  {
                    id: `sa-${id}-${uuidv4()}-i`,
                    position,
                    name: `${name} - Installed Weight Increase`,
                    weight: installedWeight,
                    longitudinalArm: newLongitudinalArm,
                    longitudinalMoment: installedWeight * newLongitudinalArm,
                    lateralArm: newLateralArm,
                    lateralMoment: installedWeight * newLateralArm,
                  },
                ];
              }
            } else if (seatAssignmentUninstalledWeightReduction) {
              const uninstalledWeight = convertFromTo(
                seatAssignmentUninstalledWeightReduction,
                seatAssignmentWeightUnit,
                bookingWeightUnit
              );
              results = [
                ...results,
                {
                  id: `sa-${id}-${uuidv4()}-r`,
                  position,
                  name: `${name} - Uninstalled Weight Reduction`,
                  weight: uninstalledWeight,
                  longitudinalArm: newLongitudinalArm,
                  longitudinalMoment: uninstalledWeight * newLongitudinalArm,
                  lateralArm: newLateralArm,
                  lateralMoment: uninstalledWeight * newLateralArm,
                },
              ];
            }
            return results;
          });
        convertedSeatAssignments = flatten(convertedSeatAssignments).sort(
          (a, b) => a.position - b.position
        );
      }

      const convertedSeatAssignmentsWeight = convertedSeatAssignments.reduce(
        (accum, csa) => accum + csa.weight,
        0
      );

      let convertedHoldAssignments = [];
      if (holdAssignmentsAttributes) {
        convertedHoldAssignments = holdAssignmentsAttributes
          .filter((ta) => !ta._destroy)
          .map((holdAssignment) => {
            let results = [];
            const {
              id,
              name,
              position,
              hold_assignment_installed: holdAssignmentInstalled,
              hold_assignment_arm_unit: holdAssignmentArmUnit,
              hold_assignment_lateral_arm: holdAssignmentLateralArm,
              hold_assignment_longitudinal_arm: holdAssignmentLongitudinalArm,
              hold_assignment_weight_unit: holdAssignmentWeightUnit,
              hold_assignment_weight: holdAssignmentWeight,
              hold_assignment_maximum_weight: holdAssignmentMaximumWeight,
              hold_assignment_installed_weight_increase:
                holdAssignmentInstalledWeightIncrease,
              hold_assignment_uninstalled_weight_reduction:
                holdAssignmentUninstalledWeightReduction,
            } = holdAssignment;
            const newLongitudinalArm = convertFromTo(
              holdAssignmentLongitudinalArm,
              holdAssignmentArmUnit,
              bookingArmUnit
            );
            const newLateralArm = convertFromTo(
              holdAssignmentLateralArm,
              holdAssignmentArmUnit,
              bookingArmUnit
            );
            const newWeight = convertFromTo(
              holdAssignmentWeight,
              holdAssignmentWeightUnit,
              bookingWeightUnit
            );
            const newMaximumWeight = convertFromTo(
              holdAssignmentMaximumWeight,
              holdAssignmentWeightUnit,
              bookingWeightUnit
            );
            if (holdAssignmentInstalled) {
              results = [
                ...results,
                {
                  id: `ha-${id}-${uuidv4()}`,
                  position,
                  name,
                  weight: newWeight,
                  longitudinalArm: newLongitudinalArm,
                  longitudinalMoment: newWeight * newLongitudinalArm,
                  lateralArm: newLateralArm,
                  lateralMoment: newWeight * newLateralArm,
                  maximumWeight: newMaximumWeight,
                },
              ];
              if (holdAssignmentInstalledWeightIncrease) {
                const installedWeight = convertFromTo(
                  holdAssignmentInstalledWeightIncrease,
                  holdAssignmentWeightUnit,
                  bookingWeightUnit
                );
                results = [
                  ...results,
                  {
                    id: `ha-${id}-${uuidv4()}-i`,
                    position,
                    name: `${name} - Installed Weight Increase`,
                    weight: installedWeight,
                    longitudinalArm: newLongitudinalArm,
                    longitudinalMoment: installedWeight * newLongitudinalArm,
                    lateralArm: newLateralArm,
                    lateralMoment: installedWeight * newLateralArm,
                  },
                ];
              }
            } else if (holdAssignmentUninstalledWeightReduction) {
              const uninstalledWeight = convertFromTo(
                holdAssignmentUninstalledWeightReduction,
                holdAssignmentWeightUnit,
                bookingWeightUnit
              );
              results = [
                ...results,
                {
                  id: `ha-${id}-${uuidv4()}-r`,
                  position,
                  name: `${name} - Uninstalled Weight Reduction`,
                  weight: uninstalledWeight,
                  longitudinalArm: newLongitudinalArm,
                  longitudinalMoment: uninstalledWeight * newLongitudinalArm,
                  lateralArm: newLateralArm,
                  lateralMoment: uninstalledWeight * newLateralArm,
                },
              ];
            }
            return results;
          });
      }
      convertedHoldAssignments = flatten(convertedHoldAssignments).sort(
        (a, b) => a.position - b.position
      );

      const convertedHoldAssignmentsWeight = convertedHoldAssignments.reduce(
        (accum, cha) => accum + cha.weight,
        0
      );

      const convertedHoldAssignmentsMaximumWeight = convertedHoldAssignments.reduce(
        (accum, cha) => accum + cha.maximumWeight,
        0
      );

      let convertedAssetAssignments = [];
      if (assetAssignmentsAttributes) {
        convertedAssetAssignments = assetAssignmentsAttributes
          .filter((aa) => !aa._destroy)
          .map((assetAssignment, idx) => {
            let results = [];
            const {
              id,
              name,
              asset_assignment_installed: assetAssignmentInstalled,
              asset_assignment_arm_unit: assetAssignmentArmUnit,
              asset_assignment_lateral_arm: assetAssignmentLateralArm,
              asset_assignment_longitudinal_arm: assetAssignmentLongitudinalArm,
              asset_assignment_weight_unit: assetAssignmentWeightUnit,
              asset_assignment_installed_weight_increase:
                assetAssignmentInstalledWeightIncrease,
              asset_assignment_uninstalled_weight_reduction:
                assetAssignmentUninstalledWeightReduction,
            } = assetAssignment;
            const newLongitudinalArm = convertFromTo(
              assetAssignmentLongitudinalArm,
              assetAssignmentArmUnit,
              bookingArmUnit
            );
            const newLateralArm = convertFromTo(
              assetAssignmentLateralArm,
              assetAssignmentArmUnit,
              bookingArmUnit
            );
            const assetAssignmentWeight = assetAssignmentInstalled
              ? assetAssignmentInstalledWeightIncrease
              : assetAssignmentUninstalledWeightReduction;
            const newWeight = convertFromTo(
              assetAssignmentWeight,
              assetAssignmentWeightUnit,
              bookingWeightUnit
            );
            results = [
              ...results,
              {
                id: `aa-${id}-${uuidv4()}`,
                position: idx,
                name: `${name} - (${
                  assetAssignmentInstalled ? 'installed' : 'not installed'
                })`,
                weight: newWeight,
                longitudinalArm: newLongitudinalArm,
                longitudinalMoment: newWeight * newLongitudinalArm,
                lateralArm: newLateralArm,
                lateralMoment: newWeight * newLateralArm,
              },
            ];
            return results;
          });
      }
      convertedAssetAssignments = flatten(convertedAssetAssignments).sort(
        (a, b) => a.position - b.position
      );

      const convertedAssetAssignmentsWeight = convertedAssetAssignments.reduce(
        (accum, caa) => accum + caa.weight,
        0
      );

      let convertedExternalLoadAssignments = [];
      if (externalLoadAssignmentsAttributes) {
        convertedExternalLoadAssignments = externalLoadAssignmentsAttributes
          .filter((ela) => !ela._destroy)
          .map((externalLoadAssignment) => {
            let results = [];
            const {
              id,
              name,
              external_load_assignment_arm_unit: externalLoadAssignmentArmUnit,
              external_load_assignment_lateral_arm: externalLoadAssignmentLateralArm,
              external_load_assignment_longitudinal_arm:
                externalLoadAssignmentLongitudinalArm,
              external_load_assignment_weight_unit: externalLoadAssignmentWeightUnit,
              external_load_assignment_weight: externalLoadAssignmentWeight,
            } = externalLoadAssignment;
            const newLongitudinalArm = convertFromTo(
              externalLoadAssignmentLongitudinalArm,
              externalLoadAssignmentArmUnit,
              bookingArmUnit
            );
            const newLateralArm = convertFromTo(
              externalLoadAssignmentLateralArm,
              externalLoadAssignmentArmUnit,
              bookingArmUnit
            );
            const newWeight = convertFromTo(
              externalLoadAssignmentWeight,
              externalLoadAssignmentWeightUnit,
              bookingWeightUnit
            );
            results = [
              ...results,
              {
                id: `ela-${id}-${uuidv4()}`,
                name,
                weight: newWeight,
                longitudinalArm: newLongitudinalArm,
                longitudinalMoment: newWeight * newLongitudinalArm,
                lateralArm: newLateralArm,
                lateralMoment: newWeight * newLateralArm,
              },
            ];
            return results;
          });
      }
      convertedExternalLoadAssignments = flatten(convertedExternalLoadAssignments).sort(
        (a, b) => a.name - b.name
      );
      const convertedExternalLoadAssignmentsWeight = round10(
        convertedExternalLoadAssignments.reduce((accum, cela) => accum + cela.weight, 0)
      );

      let convertedZeroFuelAircraft = defaultConvertedWbResult('Zero Fuel Weight');
      let convertedZeroFuelAircraftWithExternalLoad = defaultConvertedWbResult(
        'Zero Fuel Weight with External Load'
      );
      if (
        convertedEmptyAircraft.weight != null &&
        convertedSeatAssignments &&
        convertedHoldAssignments &&
        convertedAssetAssignments &&
        convertedExternalLoadAssignments
      ) {
        const seatAssignmentsLongitudinalMoment = convertedSeatAssignments.reduce(
          (accum, csa) => accum + csa.longitudinalMoment,
          0
        );
        const seatAssignmentsLateralMoment = convertedSeatAssignments.reduce(
          (accum, csa) => accum + csa.lateralMoment,
          0
        );
        const holdAssignmentsLongitudinalMoment = convertedHoldAssignments.reduce(
          (accum, cha) => accum + cha.longitudinalMoment,
          0
        );
        const holdAssignmentsLateralMoment = convertedHoldAssignments.reduce(
          (accum, cha) => accum + cha.lateralMoment,
          0
        );
        const assetAssignmentsLongitudinalMoment = convertedAssetAssignments.reduce(
          (accum, caa) => accum + caa.longitudinalMoment,
          0
        );
        const assetAssignmentsLateralMoment = convertedAssetAssignments.reduce(
          (accum, caa) => accum + caa.lateralMoment,
          0
        );
        const externalLoadAssignmentsLongitudinalMoment =
          convertedExternalLoadAssignments.reduce(
            (accum, cela) => accum + cela.longitudinalMoment,
            0
          );
        const externalLoadAssignmentsLateralMoment =
          convertedExternalLoadAssignments.reduce(
            (accum, cela) => accum + cela.lateralMoment,
            0
          );
        const newWeight =
          convertedEmptyAircraft.weight +
          convertedSeatAssignmentsWeight +
          convertedHoldAssignmentsWeight +
          convertedAssetAssignmentsWeight;
        const newLongitudinalMoment =
          convertedEmptyAircraft.longitudinalMoment +
          seatAssignmentsLongitudinalMoment +
          holdAssignmentsLongitudinalMoment +
          assetAssignmentsLongitudinalMoment;
        const newLateralMoment =
          convertedEmptyAircraft.lateralMoment +
          seatAssignmentsLateralMoment +
          holdAssignmentsLateralMoment +
          assetAssignmentsLateralMoment;
        convertedZeroFuelAircraft = {
          ...convertedZeroFuelAircraft,
          weight: newWeight,
          longitudinalArm: newLongitudinalMoment / newWeight,
          longitudinalMoment: newLongitudinalMoment,
          lateralArm: newLateralMoment / newWeight,
          lateralMoment: newLateralMoment,
        };
        const newWeightWithExternalLoad =
          newWeight + convertedExternalLoadAssignmentsWeight;
        const newLateralMomentWithExternalLoad =
          newLateralMoment + externalLoadAssignmentsLateralMoment;
        const newLongitudinalMomentWithExternalLoad =
          newLongitudinalMoment + externalLoadAssignmentsLongitudinalMoment;
        convertedZeroFuelAircraftWithExternalLoad = {
          ...convertedZeroFuelAircraftWithExternalLoad,
          weight: newWeightWithExternalLoad,
          longitudinalArm:
            newLongitudinalMomentWithExternalLoad / newWeightWithExternalLoad,
          longitudinalMoment: newLongitudinalMomentWithExternalLoad,
          lateralArm: newLateralMomentWithExternalLoad / newWeightWithExternalLoad,
          lateralMoment: newLateralMomentWithExternalLoad,
        };
      }

      let convertedTankAssignments = [];
      if (tankAssignmentsAttributes) {
        convertedTankAssignments = tankAssignmentsAttributes
          .filter((ta) => !ta._destroy)
          .map((tankAssignment) => {
            let results = [];
            const {
              id,
              name,
              position,
              tank_assignment_arm_unit: tankAssignmentArmUnit,
              tank_assignment_lateral_arm: tankAssignmentLateralArm,
              tank_assignment_longitudinal_arm: tankAssignmentLongitudinalArm,
              tank_assignment_fuel_unit: tankAssignmentFuelUnit,
              tank_assignment_fuel: tankAssignmentFuel,
              tank_assignment_maximum_fuel: tankAssignmentMaximumFuel,
            } = tankAssignment;
            const newLongitudinalArm = convertFromTo(
              tankAssignmentLongitudinalArm,
              tankAssignmentArmUnit,
              bookingArmUnit
            );
            const newLateralArm = convertFromTo(
              tankAssignmentLateralArm,
              tankAssignmentArmUnit,
              bookingArmUnit
            );
            const newWeight = convertFuelToMass({
              fuelQuantity: tankAssignmentFuel,
              fuelUnit: tankAssignmentFuelUnit,
              massUnit: bookingWeightUnit,
              specificGravity: aircraftTypeFuelTypeSpecificGravity,
            });
            const newMaximumWeight = convertFuelToMass({
              fuelQuantity: tankAssignmentMaximumFuel,
              fuelUnit: tankAssignmentFuelUnit,
              massUnit: bookingWeightUnit,
              specificGravity: aircraftTypeFuelTypeSpecificGravity,
            });
            results = [
              ...results,
              {
                id: `ta-${id}-${uuidv4()}`,
                position,
                name,
                weight: newWeight,
                longitudinalArm: newLongitudinalArm,
                longitudinalMoment: newWeight * newLongitudinalArm,
                lateralArm: newLateralArm,
                lateralMoment: newWeight * newLateralArm,
                maximumWeight: newMaximumWeight,
              },
            ];
            return results;
          });
      }
      convertedTankAssignments = flatten(convertedTankAssignments).sort(
        (a, b) => a.position - b.position
      );
      const convertedTankAssignmentsWeight = round10(
        convertedTankAssignments.reduce((accum, cta) => accum + cta.weight, 0)
      );
      const convertedTankAssignmentsMaximumWeight = round10(
        convertedTankAssignments.reduce((accum, cta) => accum + cta.maximumWeight, 0)
      );
      const tankAssignmentsMaximumFuel = convertMassToFuel({
        massQuantity: convertedTankAssignmentsMaximumWeight,
        massUnit: bookingWeightUnit,
        fuelUnit: bookingFuelUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      let convertedAllUpAircraft = defaultConvertedWbResult('All Up Weight');
      let convertedAllUpAircraftWithExternalLoad = defaultConvertedWbResult(
        'All Up Weight With External Load'
      );
      if (convertedZeroFuelAircraft.weight != null && convertedTankAssignments) {
        const tankAssignmentsLongitudinalMoment = convertedTankAssignments.reduce(
          (accum, cta) => accum + cta.longitudinalMoment,
          0
        );
        const tankAssignmentsLateralMoment = convertedTankAssignments.reduce(
          (accum, cta) => accum + cta.lateralMoment,
          0
        );
        const externalLoadAssignmentsLongitudinalMoment =
          convertedExternalLoadAssignments.reduce(
            (accum, cela) => accum + cela.longitudinalMoment,
            0
          );
        const externalLoadAssignmentsLateralMoment =
          convertedExternalLoadAssignments.reduce(
            (accum, cela) => accum + cela.lateralMoment,
            0
          );
        const newWeight = round10(
          convertedZeroFuelAircraft.weight + convertedTankAssignmentsWeight
        );
        const newLongitudinalMoment = round10(
          convertedZeroFuelAircraft.longitudinalMoment + tankAssignmentsLongitudinalMoment
        );
        const newLateralMoment = round10(
          convertedZeroFuelAircraft.lateralMoment + tankAssignmentsLateralMoment
        );
        convertedAllUpAircraft = {
          ...convertedAllUpAircraft,
          weight: newWeight,
          longitudinalArm: newLongitudinalMoment / newWeight,
          longitudinalMoment: newLongitudinalMoment,
          lateralArm: newLateralMoment / newWeight,
          lateralMoment: newLateralMoment,
        };
        const newWeightWithExternalLoad =
          newWeight + convertedExternalLoadAssignmentsWeight;
        const newLateralMomentWithExternalLoad =
          newLateralMoment + externalLoadAssignmentsLateralMoment;
        const newLongitudinalMomentWithExternalLoad =
          newLongitudinalMoment + externalLoadAssignmentsLongitudinalMoment;
        convertedAllUpAircraftWithExternalLoad = {
          ...convertedAllUpAircraftWithExternalLoad,
          weight: newWeightWithExternalLoad,
          longitudinalArm:
            newLongitudinalMomentWithExternalLoad / newWeightWithExternalLoad,
          longitudinalMoment: newLongitudinalMomentWithExternalLoad,
          lateralArm: newLateralMomentWithExternalLoad / newWeightWithExternalLoad,
          lateralMoment: newLateralMomentWithExternalLoad,
        };
      }
      // balances
      // -1 if point is contained inside loop
      // 0 if point is on the boundary of loop
      // 1 if point is outside loop
      const {
        weight: convertedZeroFuelAircraftWeight,
        longitudinalArm: convertedZeroFuelAircraftLongitudinalArm,
        lateralArm: convertedZeroFuelAircraftLLateralArm,
      } = convertedZeroFuelAircraft;
      const flightSegmentZeroFuelLongitudinalBalanced =
        classifyPoint(longitudinalPolygon, [
          convertedZeroFuelAircraftLongitudinalArm,
          convertedZeroFuelAircraftWeight,
        ]) < 1;
      const flightSegmentZeroFuelLateralBalanced =
        classifyPoint(lateralPolygon, [
          convertedZeroFuelAircraftLLateralArm,
          convertedZeroFuelAircraftWeight,
        ]) < 1;

      const {
        weight: convertedAllUpAircraftWeight,
        longitudinalArm: convertedAllUpAircraftLongitudinalArm,
        lateralArm: convertedAllUpAircraftLateralArm,
      } = convertedAllUpAircraft;
      const flightSegmentAllUpLongitudinalBalanced =
        classifyPoint(longitudinalPolygon, [
          convertedAllUpAircraftLongitudinalArm,
          convertedAllUpAircraftWeight,
        ]) < 1;
      const flightSegmentAllUpLateralBalanced =
        classifyPoint(lateralPolygon, [
          convertedAllUpAircraftLateralArm,
          convertedAllUpAircraftWeight,
        ]) < 1;

      const flightSegmentOutOfBalance = !(
        flightSegmentZeroFuelLongitudinalBalanced &&
        flightSegmentZeroFuelLateralBalanced &&
        flightSegmentAllUpLongitudinalBalanced &&
        flightSegmentAllUpLateralBalanced
      );

      // weights
      const flightSegmentRemainingWeight = round10(
        convertedAircraftTypeMaximumAllUpWeight - convertedAllUpAircraft.weight
      );
      const flightSegmentAllUpWeightOverMaximumWeight = flightSegmentRemainingWeight < 0;

      // distances
      const startLocationId = get(flightSegment, 'start_location_id');
      const endLocationId = get(flightSegment, 'end_location_id');
      const locationIds = [startLocationId, endLocationId].sort();
      const locationDistanceMeter = LocationsDistanceMemo({
        locationIdA: first(locationIds),
        locationIdB: last(locationIds),
        locations,
      });
      const convertedLocationDistance = convertFromTo(
        locationDistanceMeter,
        meterUnit,
        bookingDistanceUnit
      );

      const flightSegmentDistanceAdj = get(flightSegment, 'distance_adj', 0);
      const flightSegmentDistance = convertedLocationDistance + flightSegmentDistanceAdj;

      const flightSegmentDistanceAdjMeter = convertFromTo(
        flightSegmentDistanceAdj,
        bookingDistanceUnit,
        meterUnit
      );
      const flightSegmentDistanceMeter =
        locationDistanceMeter + flightSegmentDistanceAdjMeter;

      // airspeed
      const flightSegmentAirspeedAdj = get(flightSegment, 'airspeed_adj', 0);
      const flightSegmentAirspeed = bookingCruiseAirspeedSl + flightSegmentAirspeedAdj;

      const flightSegmentAirspeedAdjKmph = convertFromTo(
        flightSegmentAirspeedAdj,
        bookingAirspeedUnit,
        kmphUnit
      );
      const flightSegmentAirspeedKmph =
        bookingCruiseAirspeedSlKmph + flightSegmentAirspeedAdjKmph;

      // flighttime
      const flightSegmentAdjustedFlighttime = round10(
        (flightSegmentDistanceMeter / 1000 / flightSegmentAirspeedKmph) * 60
      );
      const flightSegmentLoadedFlighttime =
        flightSegmentAdjustedFlighttime + aircraftTypeDepartureAndArrivalLoading;

      let flightSegmentLoadedFlighttimeFormatted;
      if (flightSegmentLoadedFlighttime > 0) {
        const loadedFlighttimeDuration = moment.duration(
          flightSegmentLoadedFlighttime,
          'minutes'
        );
        const hours = `${loadedFlighttimeDuration.hours()}`;
        const mins = `${loadedFlighttimeDuration.minutes()}`;
        flightSegmentLoadedFlighttimeFormatted = `${
          '00'.substring(0, 2 - hours.length) + hours
        }:${'00'.substring(0, 2 - mins.length) + mins}`;
      }

      // fueldata
      const locationFuelData = LocationFuelDataMemo({
        locationId: startLocationId,
        fuelBowsers,
        fuelTankers,
      });
      const flightSegmentFuelBowsers = get(
        locationFuelData,
        'locationBowsers',
        []
      ).filter((fb) => fb.fuel_type_id === aircraftTypeFuelTypeId);
      const flightSegmentFuelTankers = get(
        locationFuelData,
        'locationTankers',
        []
      ).filter((ft) => ft.fuel_type_id === aircraftTypeFuelTypeId);

      // time on ground
      let timeOnGroundFormatted;
      if (activeFlightSegmentIndex > 0) {
        const prevFlightSegment = flightSegments.at(activeFlightSegmentIndex - 1);
        const prevEndAt = get(prevFlightSegment, 'end_at');
        const startAt = get(flightSegment, 'start_at');
        if (prevEndAt && startAt) {
          const timeOnGroundDiff = moment(startAt).diff(moment(prevEndAt));
          if (timeOnGroundDiff > 0) {
            const hours = `${moment.duration(timeOnGroundDiff).hours()}`;
            const mins = `${moment.duration(timeOnGroundDiff).minutes()}`;
            timeOnGroundFormatted = `${'00'.substring(0, 2 - hours.length) + hours}:${
              '00'.substring(0, 2 - mins.length) + mins
            }`;
          }
        }
      }

      // fuel consumption
      const convertedBookingReserveConsumptionSl = convertFuelToMass({
        fuelQuantity: bookingReserveConsumptionSl,
        fuelUnit: bookingFuelUnit,
        massUnit: bookingWeightUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      const flightSegmentPilotTakeOffFuelAdjusted = get(
        flightSegment,
        'pilot_take_off_fuel_adjusted'
      );

      const flightSegmentTakeOffFuel = get(flightSegment, 'take_off_fuel');
      const convertedFlightSegmentTakeOffFuel = convertFuelToMass({
        fuelQuantity: flightSegmentTakeOffFuel,
        fuelUnit: bookingFuelUnit,
        massUnit: bookingWeightUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      const flightSegmentPilotTakeOffFuel = get(flightSegment, 'pilot_take_off_fuel');
      const convertedFlightSegmentPilotTakeOffFuel = convertFuelToMass({
        fuelQuantity: flightSegmentPilotTakeOffFuel,
        fuelUnit: bookingFuelUnit,
        massUnit: bookingWeightUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      const flightSegmentFuelBurn = round10(
        (flightSegmentLoadedFlighttime / 60) * bookingCruiseFuelConsumptionSl
      );

      const convertedFlightSegmentFuelBurn = convertFuelToMass({
        fuelQuantity: flightSegmentFuelBurn,
        fuelUnit: bookingFuelUnit,
        massUnit: bookingWeightUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      // const convertedFlightSegmentLandingFuel = round10(
      //   convertedFlightSegmentTakeOffFuel - convertedFlightSegmentFuelBurn
      // );
      // const flightSegmentLandingFuel = convertMassToFuel({
      //   massQuantity: convertedFlightSegmentLandingFuel,
      //   massUnit: bookingWeightUnit,
      //   fuelUnit: bookingFuelUnit,
      //   specificGravity: aircraftTypeFuelTypeSpecificGravity,
      // });

      // const convertedFlightSegmentPilotLandingFuel = round10(
      //   // this is the one that might be underweight, so base on whats actualli in tanks? Or not
      //   // convertedTankAssignmentsWeight - convertedFlightSegmentFuelBurn
      //   convertedFlightSegmentTakeOffFuel - convertedFlightSegmentFuelBurn
      // );
      // const flightSegmentPilotLandingFuel = convertMassToFuel({
      //   massQuantity: convertedFlightSegmentPilotLandingFuel,
      //   massUnit: bookingWeightUnit,
      //   fuelUnit: bookingFuelUnit,
      //   specificGravity: aircraftTypeFuelTypeSpecificGravity,
      // });

      const flightSegmentMinDispatchFuel = round10(
        flightSegmentFuelBurn + bookingReserveConsumptionSl
      );
      const convertedFlightSegmentMinDispatchFuel = convertFuelToMass({
        fuelQuantity: flightSegmentMinDispatchFuel,
        fuelUnit: bookingFuelUnit,
        massUnit: bookingWeightUnit,
        specificGravity: aircraftTypeFuelTypeSpecificGravity,
      });

      // luggage and hold
      const flightSegmentLuggageWeight = get(flightSegment, 'luggage_weight', 0);
      let flightSegmentLuggageWeightOverMaximumHoldCapacity = false;
      if (convertedHoldAssignmentsMaximumWeight > 0) {
        // no need to convert luggage it is already in bookingWeightUnit
        flightSegmentLuggageWeightOverMaximumHoldCapacity =
          flightSegmentLuggageWeight > convertedHoldAssignmentsMaximumWeight;
      }
      const flightSegmentCabinWeight =
        convertedSeatAssignmentsWeight + convertedHoldAssignmentsWeight;

      const calculatedData = {
        flightSegmentIndex,
        activeFlightSegmentIndex,
        ...bookingCalculatedData,
        //
        convertedEmptyAircraft,
        convertedSeatAssignments,
        convertedHoldAssignments,
        convertedAssetAssignments,
        convertedExternalLoadAssignments,
        convertedZeroFuelAircraft,
        convertedZeroFuelAircraftWithExternalLoad,
        convertedTankAssignments,
        convertedAllUpAircraft,
        convertedAllUpAircraftWithExternalLoad,
        //
        convertedAircraftTypeMaximumAllUpWeight,
        convertedAircraftTypeMaximumAllUpWeightWithExternalLoad,
        flightSegmentRemainingWeight,
        flightSegmentAllUpWeightOverMaximumWeight,
        convertedTankAssignmentsWeight,
        convertedTankAssignmentsMaximumWeight,
        tankAssignmentsMaximumFuel,
        // envelopes
        convertedWbLongitudinalLimitPoints,
        convertedWbLateralLimitPoints,
        convertedWbExternalLongitudinalLimitPoints,
        convertedWbExternalLateralLimitPoints,
        // balance
        flightSegmentOutOfBalance,
        // luggage and hold
        flightSegmentLuggageWeight,
        flightSegmentLuggageWeightOverMaximumHoldCapacity,
        flightSegmentCabinWeight,
        // distance
        convertedLocationDistance,
        flightSegmentDistanceAdj,
        flightSegmentDistance,
        // airspeed
        flightSegmentAirspeedAdj,
        flightSegmentAirspeed,
        // flighttime
        flightSegmentLoadedFlighttime,
        flightSegmentLoadedFlighttimeFormatted,
        // fuel options
        flightSegmentFuelBowsers,
        flightSegmentFuelTankers,
        timeOnGroundFormatted,
        // locations
        startLocationId,
        endLocationId,
        // fuel calc
        convertedBookingReserveConsumptionSl,
        flightSegmentPilotTakeOffFuelAdjusted,
        flightSegmentFuelBurn,
        convertedFlightSegmentFuelBurn,
        flightSegmentMinDispatchFuel,
        convertedFlightSegmentMinDispatchFuel,
        flightSegmentTakeOffFuel,
        convertedFlightSegmentTakeOffFuel,
        flightSegmentPilotTakeOffFuel,
        convertedFlightSegmentPilotTakeOffFuel,
      };
      return calculatedData;
    }
    return {};
  },
  ({
    aircraftId,
    aircraftConfigurationId,
    activeFlightSegmentIndex,
    bookingModifiersObjectHash,
    flightSegmentObjectHash,
  }) => {
    if (aircraftId && aircraftConfigurationId) {
      return [
        aircraftId,
        aircraftConfigurationId,
        activeFlightSegmentIndex,
        bookingModifiersObjectHash,
        flightSegmentObjectHash,
      ]
        .map((i) => String(i))
        .join('.');
    }
    return 'unknown';
  }
);
