import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash.get';

import InputField from '../form/input_field';
import InvalidBlock from '../form/invalid_block';

class BookingEngineEventFields extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.bookingAircraftId !== nextProps.bookingAircraftId) {
      const input = get(nextProps, `${nextProps.parentField}aircraft_id.input`);
      if (nextProps.bookingAircraftId) {
        input.onChange(nextProps.bookingAircraftId);
      } else {
        input.onChange('');
      }
    }
  }

  handleDeleteButtonClick() {
    const id = get(this.props, 'formValues.id', '');
    if (id) {
      const input = get(this.props, `${this.props.parentField}._destroy.input`);
      input.onChange(true);
    } else if (this.props.handleDeleteButtonClick) {
      this.props.handleDeleteButtonClick(this.props.index);
    }
  }

  render() {
    const {
      event_value: { input: eventValueInput, meta: eventValueMeta },
      event_type: { input: eventTypeInput, meta: eventTypeMeta },
    } = get(this.props, this.props.parentField);

    return (
      <InputField
        size="sm"
        labelWidth={2}
        inputWidth={10}
        input={{}}
        innerContent={
          <Row>
            <Col sm={1}>
              <InputField
                controlOnly
                blurOnly
                type="text"
                input={eventValueInput}
                meta={eventValueMeta}
                size="sm"
              />
            </Col>
            <Col sm={2}>
              <InputField
                controlOnly
                type="text"
                size="sm"
                asElement="select"
                selectOptions={this.props.currentSettingsEngineEventTypes.map((type) => ({
                  id: type,
                  name: type,
                }))}
                input={eventTypeInput}
                meta={eventTypeMeta}
                // readOnly
              />
            </Col>
            <Col sm={9}>
              <Button
                type="button"
                variant="link"
                size="sm"
                onClick={this.handleDeleteButtonClick}
                tabIndex={-1}
              >
                del...
              </Button>
            </Col>
            <Col sm={12}>
              <InvalidBlock meta={eventValueMeta} force />
              <InvalidBlock meta={eventTypeMeta} force />
            </Col>
          </Row>
        }
      >
        {`Event ${this.props.index + 1}`}
      </InputField>
    );
  }
}

export default BookingEngineEventFields;
