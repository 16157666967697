import { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import EnvelopeChart from '../wb_limit_form/envelope_chart';

const WeightBalanceLatCg = (props) => {
  const { flightSegmentCalculatedData } = props;
  const {
    bookingArmUnit,
    bookingWeightUnit,
    convertedZeroFuelAircraft,
    convertedZeroFuelAircraftWithExternalLoad,
    convertedAllUpAircraft,
    convertedAllUpAircraftWithExternalLoad,
    convertedWbLateralLimitPoints,
    convertedWbExternalLateralLimitPoints,
  } = flightSegmentCalculatedData;
  const [limitsCollection, setLimitsCollection] = useState([]);
  const [externalLimitsCollection, setExternalLimitsCollection] = useState([]);
  const [fuelBurnCollection, setFuelBurnCollection] = useState([]);
  const [fuelBurnWithExternalLoadCollection, setFuelBurnWithExternalLoadCollection] =
    useState([]);

  useEffect(() => {
    let newLimitsCollection = [];
    if (convertedWbLateralLimitPoints) {
      newLimitsCollection = convertedWbLateralLimitPoints.map((point) => {
        const {
          converted_wb_limit_arm: x,
          converted_wb_limit_weight: y,
          position,
        } = point;
        return { name: 'Limit', x, y, position };
      });
    }
    setLimitsCollection(newLimitsCollection);
  }, [convertedWbLateralLimitPoints]);

  useEffect(() => {
    let newExternalLimitsCollection = [];
    if (convertedWbExternalLateralLimitPoints) {
      newExternalLimitsCollection = convertedWbExternalLateralLimitPoints.map((point) => {
        const {
          converted_wb_limit_arm: x,
          converted_wb_limit_weight: y,
          position,
        } = point;
        return { name: 'Limit', x, y, position };
      });
    }
    setExternalLimitsCollection(newExternalLimitsCollection);
  }, [convertedWbExternalLateralLimitPoints]);

  useEffect(() => {
    let newFuelBurnCollection = [];
    if (convertedZeroFuelAircraft.id && convertedAllUpAircraft.id) {
      newFuelBurnCollection = [
        {
          name: 'All Up Weight',
          x: convertedAllUpAircraft.lateralArm,
          y: convertedAllUpAircraft.weight,
        },
        {
          name: 'Zero Fuel Weight',
          x: convertedZeroFuelAircraft.lateralArm,
          y: convertedZeroFuelAircraft.weight,
        },
      ];
    }
    setFuelBurnCollection(newFuelBurnCollection);
  }, [convertedZeroFuelAircraft, convertedAllUpAircraft]);

  useEffect(() => {
    let newFuelBurnWithExternalLoadCollection = [];
    if (
      convertedZeroFuelAircraftWithExternalLoad.id &&
      convertedAllUpAircraftWithExternalLoad.id &&
      convertedAllUpAircraft.weight !== convertedAllUpAircraftWithExternalLoad.weight
    ) {
      newFuelBurnWithExternalLoadCollection = [
        {
          name: 'All Up Weight With External Load',
          x: convertedAllUpAircraftWithExternalLoad.lateralArm,
          y: convertedAllUpAircraftWithExternalLoad.weight,
        },
        {
          name: 'Zero Fuel Weight With External Load',
          x: convertedZeroFuelAircraftWithExternalLoad.lateralArm,
          y: convertedZeroFuelAircraftWithExternalLoad.weight,
        },
      ];
    }
    setFuelBurnWithExternalLoadCollection(newFuelBurnWithExternalLoadCollection);
  }, [
    convertedAllUpAircraft,
    convertedZeroFuelAircraftWithExternalLoad,
    convertedAllUpAircraftWithExternalLoad,
  ]);

  if (!convertedWbLateralLimitPoints) {
    return (
      <Row className="mt-3">
        <Col sm={12}>
          <p>No lateral limits</p>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mt-3">
      <Col xs={12}>
        <Card>
          <Card.Body>
            <EnvelopeChart
              limitsCollection={limitsCollection}
              externalLimitsCollection={externalLimitsCollection}
              armUnit={bookingArmUnit}
              weightUnit={bookingWeightUnit}
              fuelBurnCollection={fuelBurnCollection}
              fuelBurnWithExternalLoadCollection={fuelBurnWithExternalLoadCollection}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default WeightBalanceLatCg;
