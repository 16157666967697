import { useState, useEffect } from 'react';

import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';

const WeightBalanceHoldAssignmentFields = (props) => {
  const { formValues, parentField, isDeveloper } = props;

  const {
    name,
    hold_assignment_installed: holdAssignmentInstalled,
    hold_assignment_weight_unit: holdAssignmentWeightUnit,
    hold_assignment_weight: holdAssignmentWeight,
    hold_assignment_maximum_weight: holdAssigmentMaximumWeight,
    hold_assignment_installed_weight_increase: holdAssignmentInstalledWeightIncrease,
    hold_assignment_uninstalled_weight_reduction:
      holdAssignmentUninstalledWeightReduction,
  } = formValues;

  const {
    hold_assignment_installed: { input: holdAssignmentInstalledInput },
    hold_assignment_weight: { input: holdAssignmentWeightInput },
  } = get(props, parentField);

  const [weight, setWeight] = useState('');
  const [installed, setInstalled] = useState(false);

  useEffect(() => {
    setWeight(holdAssignmentWeight || 0);
  }, [holdAssignmentWeight]);

  useEffect(() => {
    setInstalled(holdAssignmentInstalled || false);
  }, [holdAssignmentInstalled]);

  const handleWeightChange = (e) => {
    const newWeight = defaultTo(parseInt(e.target.value, 10), '');
    setWeight(newWeight);
  };

  const handleWeightBlur = (e) => {
    const newWeight = defaultTo(parseInt(e.target.value, 10), 0);
    holdAssignmentWeightInput.onChange(newWeight);
  };

  const handleInstalledChange = (e) => {
    setInstalled(e.target.checked);
    holdAssignmentInstalledInput.onChange(e.target.checked);
    if (holdAssignmentWeight) {
      holdAssignmentWeightInput.onChange(0);
    }
  };

  const renderWeight = () => {
    if (
      holdAssignmentUninstalledWeightReduction ||
      holdAssignmentInstalledWeightIncrease
    ) {
      return `${
        holdAssignmentUninstalledWeightReduction || holdAssignmentInstalledWeightIncrease
      }${holdAssignmentWeightUnit}`;
    }
    return 0;
  };

  const renderMaximumWeight = () => {
    if (holdAssigmentMaximumWeight) {
      return `${holdAssigmentMaximumWeight}${holdAssignmentWeightUnit}`;
    }
    return 0;
  };

  return (
    <tr>
      <td>{name}</td>
      {isDeveloper && <td>{holdAssignmentWeightUnit}</td>}
      <td>{renderWeight()}</td>
      <td>{renderMaximumWeight()}</td>
      <td>
        <input
          type="text"
          style={{ width: '88px' }}
          value={weight}
          onChange={handleWeightChange}
          onBlur={handleWeightBlur}
        />
      </td>
      <td>
        <input type="checkbox" checked={installed} onChange={handleInstalledChange} />
      </td>
    </tr>
  );
};

export default WeightBalanceHoldAssignmentFields;
