import React, { useCallback, useState, useEffect } from 'react';
import { ReactTags } from 'react-tag-autocomplete';

import snakeCase from 'lodash.snakecase';

function TagList(props) {
  const {
    tagCollectionInput = {},
    suggestions: filteredSuggestions,
    onTagCreate,
    placeholderText,
    suggestionsOnly: allowNew = true,
  } = props;

  const { onChange: tagCollectionInputOnChange, value: tagCollectionInputValue = [] } =
    tagCollectionInput;
  const [suggestions, setSuggestions] = useState([]);
  const [selected, setSelected] = useState([]);

  const onAdd = useCallback(
    (newTag) => {
      const { label: text } = newTag;
      const newSelected = [
        ...selected.map(({ value, label }) => ({ id: value, text: label })),
        {
          id: snakeCase(text),
          text,
        },
      ];
      if (onTagCreate) {
        onTagCreate(text);
      }
      if (tagCollectionInputOnChange) {
        tagCollectionInputOnChange(newSelected);
      }
    },
    [selected, onTagCreate, tagCollectionInputOnChange]
  );

  const onDelete = useCallback(
    (tagIndex) => {
      const newSelected = selected
        .filter((_, i) => i !== tagIndex)
        .map(({ value, label }) => ({ id: value, text: label }));

      if (tagCollectionInputOnChange) {
        tagCollectionInputOnChange(newSelected);
      }
    },
    [selected, tagCollectionInputOnChange]
  );

  useEffect(() => {
    let newSuggestions = [];
    if (filteredSuggestions.length > 0) {
      newSuggestions = filteredSuggestions.map(({ value, label }) => ({
        value: snakeCase(value),
        label,
      }));
    }
    setSuggestions(newSuggestions);
  }, [filteredSuggestions]);

  useEffect(() => {
    let newSelected = [];
    if (tagCollectionInputValue.length > 0) {
      newSelected = tagCollectionInputValue.map(({ id, text }) => ({
        value: id,
        label: text,
      }));
    }
    setSelected(newSelected);
  }, [tagCollectionInputValue]);

  return (
    <ReactTags
      selected={selected}
      suggestions={suggestions}
      onAdd={onAdd}
      onDelete={onDelete}
      placeholderText={placeholderText || 'Add new tag'}
      noOptionsText="No matching tags"
      allowBackspace={false}
      allowNew={allowNew}
    />
  );
}

export default TagList;
