import { Row, Col, Form, Card } from 'react-bootstrap';
import { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, FieldArray, Field, getFormValues } from 'redux-form';

import clone from 'lodash.clone';
import cloneDeep from 'lodash.clonedeep';
import isNil from 'lodash.isnil';
import get from 'lodash.get';
import omit from 'lodash.omit';
import omitBy from 'lodash.omitby';
import pick from 'lodash.pick';

import InputField from '../components/form/input_field';
import EnvelopeChart from '../components/wb_limit_form/envelope_chart';
import WbLimitFormWbLimitPointFieldArray from '../components/wb_limit_form/wb_limit_point_field_array';
import Loader from '../components/loader';
import Title from '../components/title';
import FormButtons from '../components/form/form_buttons';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import wbLimitQuery from '../queries/wb_limit_query';
import wbLimitUpdateMutation from '../mutations/wb_limit_update_mutation';
import wbLimitCreateMutation from '../mutations/wb_limit_create_mutation';

import { queriesReady, queryReady, coerceInput } from '../lib/utils';

let isInitialisedWbLimitForm = false;

class WbLimitForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.renderWbLimitPointFieldArray = this.renderWbLimitPointFieldArray.bind(this);
  }

  componentWillUnmount() {
    isInitialisedWbLimitForm = false;
  }

  onSubmit(data) {
    this.props.mutationSet(true);
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: coerceInput(submitData) },
    };
    if (this.state.updating) {
      mutation = this.props.wbLimitUpdateMutation;
      mutationMessage = 'WB Limit update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.wbLimitCreateMutation;
      mutationMessage = 'WB Limit create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/wb_limits');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.aircraftTypeListQuery, [testProps.wbLimitQuery, true]);
  }

  getLimitsCollection() {
    return get(this.props, 'formValues.wbLimitPoints', [])
      .sort((a, b) => a.position - b.position)
      .map((point) => {
        const { wb_limit_arm: x, wb_limit_weight: y, position } = point;
        return { x: parseFloat(x), y: parseFloat(y), position };
      });
  }

  renderWbLimitPointFieldArray(wbLimitPoints) {
    const formKeys = ['id', 'wbLimitPoints'];
    return (
      <WbLimitFormWbLimitPointFieldArray
        wbLimitPoints={wbLimitPoints}
        formValues={pick(this.props.formValues, formKeys)}
      />
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedWbLimitForm &&
      this.isLoaded() &&
      get(this.props, 'formValues') &&
      (!this.state.updating || get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting } = this.props;
      return (
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Title form updating={this.state.updating}>
            WB Limit
          </Title>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">WB Limit Details</legend>
            <Field
              type="text"
              name="name"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
            >
              Name
            </Field>
            <Field
              type="text"
              name="description"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="textarea"
              rows={3}
            >
              Description
            </Field>
            <Field
              type="text"
              name="aircraft_type_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.props.aircraftTypeListQuery.data.map((model) => ({
                id: model.id,
                name: model.name,
              }))}
            >
              Aircraft Type
            </Field>
            <Field
              type="text"
              name="limit_type"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              defaultSelectOption={false}
              selectOptions={this.props.currentSettingsWbLimitLimitTypes.map((unit) => ({
                id: unit,
                name: unit,
              }))}
            >
              Limit Type
            </Field>
            <Field
              type="text"
              name="arm_unit"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              defaultSelectOption={false}
              selectOptions={this.props.currentSettingsAircraftTypeArmUnits.map(
                (unit) => ({
                  id: unit,
                  name: unit,
                })
              )}
            >
              Arm Unit
            </Field>
            <Field
              type="text"
              name="weight_unit"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              defaultSelectOption={false}
              selectOptions={this.props.currentSettingsAircraftTypeWeightUnits.map(
                (unit) => ({
                  id: unit,
                  name: unit,
                })
              )}
            >
              Weight Unit
            </Field>
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">WB Limit Points</legend>
            <FieldArray
              name="wbLimitPoints"
              component={this.renderWbLimitPointFieldArray}
            />
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Envelope</legend>
            <Col sm={12}>
              <Card>
                <Card.Body>
                  <EnvelopeChart
                    limitsCollection={this.getLimitsCollection()}
                    externalLimitsCollection={[]}
                    armUnit={this.props.formValues.arm_unit}
                    weightUnit={this.props.formValues.weight_unit}
                  />
                </Card.Body>
              </Card>
            </Col>
          </fieldset>
          <Row>
            <FormButtons
              submitting={submitting}
              pristine={pristine}
              updating={this.state.updating}
              cancelLink="/wb_limits"
            />
          </Row>
        </Form>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

// TODO this is saved in express app so no current rails validations
// needs ajv client and express?
function validate() {
  return {};
}

const attributeBlackList = ['__typename', 'wbLimitPoints', 'aircraftType'];

function pickInitialValues(wbLimitQueryInitial, updating) {
  if (!isInitialisedWbLimitForm) {
    if (!updating) {
      isInitialisedWbLimitForm = true;
      return {
        wbLimitPoints: [],
        arm_unit: 'millimeters',
        weight_unit: 'kg',
        limit_type: 'longitudinal',
      };
    }
    if (queryReady(wbLimitQueryInitial)) {
      /* eslint-disable camelcase */
      isInitialisedWbLimitForm = true;
      const editData = omit(
        omitBy(cloneDeep(wbLimitQueryInitial.data), isNil),
        attributeBlackList
      );
      editData.wbLimitPoints = clone(get(wbLimitQueryInitial, 'data.wbLimitPoints', []))
        .sort((a, b) => a.position - b.position)
        .map(({ id, position, wb_limit_arm, wb_limit_weight }) => ({
          id,
          wb_limit_arm,
          wb_limit_weight,
          position,
        }));
      return editData;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.wbLimitQuery, !!props.params.id);
  return {
    initialValues,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsWbLimitLimitTypes: state.currentSettings.wb_limit_limit_types,
    currentSettingsAircraftTypeArmUnits: state.currentSettings.aircraft_type_arm_units,
    currentSettingsAircraftTypeWeightUnits:
      state.currentSettings.aircraft_type_weight_units,
    formValues: getFormValues('WbLimitForm')(state),
  };
}

export default compose(
  graphql(wbLimitCreateMutation, {
    name: 'wbLimitCreateMutation',
  }),
  graphql(wbLimitUpdateMutation, {
    name: 'wbLimitUpdateMutation',
  }),
  graphql(aircraftTypeListQuery, {
    name: 'aircraftTypeListQuery',
  }),
  graphql(wbLimitQuery, {
    name: 'wbLimitQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
    // options: (props) => ({ variables: { id: props.params.id } }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'WbLimitForm',
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    validate,
  })
)(WbLimitForm);
