import { Row, Col, Button } from 'react-bootstrap';
import { useCallback } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash.get';
import pick from 'lodash.pick';

import WbLimitFormWbLimitPointFields from './wb_limit_point_fields';
import Glyphicon from '../glyphicon';

const WbLimitFormWbLimitPointFieldArray = (props) => {
  const { formValues, wbLimitPoints } = props;

  const handleAddClick = useCallback(() => {
    wbLimitPoints.fields.push({
      position: 99,
    });
  }, [wbLimitPoints.fields]);

  const handleDeleteClick = useCallback(
    (index) => {
      wbLimitPoints.fields.remove(index);
    },
    [wbLimitPoints.fields]
  );

  const renderList = () => {
    const formKeys = ['id', 'position'];
    return (
      <Col sm={{ span: 9, offset: 3 }}>
        {wbLimitPoints.fields.map((field, index) => {
          const values = get(formValues, field, {});
          const fields = [
            `${field}.position`,
            `${field}.wb_limit_arm`,
            `${field}.wb_limit_weight`,
          ];
          return (
            <Fields
              key={`wb-${field}`}
              index={index}
              parentField={field}
              names={fields}
              component={WbLimitFormWbLimitPointFields}
              formValues={pick(values, formKeys)}
              handleDeleteClick={handleDeleteClick}
            />
          );
        })}
      </Col>
    );
  };

  return (
    <Row>
      {renderList()}
      <Col sm={{ span: 9, offset: 3 }}>
        <Button type="button" variant="default" onClick={handleAddClick}>
          <Glyphicon glyph="plus" />
          &nbsp; Add Point
        </Button>
      </Col>
    </Row>
  );
};

export default WbLimitFormWbLimitPointFieldArray;
