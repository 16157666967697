import { useMemo } from 'react';
import union from 'lodash.union';

export function StringFilter(props) {
  const {
    column: { getFilterValue, setFilterValue },
  } = props;

  const columnFilterValue = getFilterValue();

  return (
    <input
      type="text"
      value={columnFilterValue ?? ''}
      onChange={(e) => setFilterValue(e.target.value || undefined)}
      placeholder="Filter..."
      className="w-100 border rounded"
    />
  );
}

export function DropdownFilter(props) {
  const {
    column: { id: columnId, getFacetedUniqueValues, getFilterValue, setFilterValue },
    table,
  } = props;
  // eslint-disable-next-line prefer-destructuring
  const flatRows = table.getPreFilteredRowModel().flatRows;
  const firstValue = flatRows[0]?.getValue(columnId);
  const columnFilterValue = getFilterValue();

  let columnFacetedUniqueValues;
  if (Array.isArray(firstValue)) {
    columnFacetedUniqueValues = union(...flatRows.map((r) => r.getValue(columnId)));
  } else {
    columnFacetedUniqueValues = Array.from(getFacetedUniqueValues().keys());
  }
  const sortedUniqueValues = useMemo(
    () => columnFacetedUniqueValues.sort(),
    [columnFacetedUniqueValues]
  );

  return (
    <select
      value={columnFilterValue}
      onChange={(e) => {
        setFilterValue(e.target.value || undefined);
      }}
      className="w-100 border rounded"
      style={{ padding: '1px 2px' }}
    >
      <option value="">all</option>
      {sortedUniqueValues.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
