import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import accounting from 'accounting';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady, getExport } from '../lib/utils';

import pilotFlightLogMonthlyByPilotQuery from '../queries/pilot_flight_log_monthly_by_pilot_query';
import contactListQuery from '../queries/contact_list_query';

class ReportPilotFlightLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPilotId: this.props.currentSettingsReportPilotId,
      filterPilotIds: [],
    };
    this._handleDisplayPilotIdChange = this._handleDisplayPilotIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.pilotId) {
      this._handleDisplayPilotIdChange({
        target: { value: parseInt(this.props.params.pilotId) },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.pilotFlightLogMonthlyByPilotQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterPilotIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterPilotIds = nextProps.pilotFlightLogMonthlyByPilotQuery.data.map(
        (data) => data.pilot_id
      );
    }
    this.setState({
      filterPilotIds,
    });
    let filterPilotId = nextProps.currentSettingsReportPilotId;
    if (filterPilotId && this.isLoaded(nextProps)) {
      if (filterPilotIds.length > 0 && filterPilotIds.indexOf(filterPilotId) === -1) {
        filterPilotId = '';
      }
    }
    if (filterPilotId !== this.props.currentSettingsReportPilotId) {
      this._handleDisplayPilotIdChange({ target: { value: filterPilotId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.pilotFlightLogMonthlyByPilotQuery, props.pilotListQuery);
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      pilotId: this.props.currentSettingsReportPilotId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  _handleDisplayPilotIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterPilotId: value,
    });
    this.props.currentSettingsSet({
      reportPilotId: value,
    });
  }

  _renderHeaderRowA() {
    return (
      <thead>
        <tr>
          <th
            colSpan={6}
            className="text-center border-top-0"
            style={{ borderBottom: 'none' }}
          />
          <th colSpan={8} className="text-center border-top-0">
            Single-engine Aircraft
          </th>
          <th colSpan={8} className="text-center border-top-0">
            Multi-engine Aircraft
          </th>
          <th className="text-center border-top-0" style={{ borderBottom: 'none' }} />
        </tr>
      </thead>
    );
  }

  _renderHeaderRowB() {
    return (
      <thead>
        <tr>
          <th style={{ borderTop: 'none', borderBottom: 'none' }} />
          <th colSpan={2} className="text-center" style={{ borderTop: 'none' }}>
            Aircraft
          </th>
          <th
            colSpan={3}
            className="text-center"
            style={{ borderTop: 'none', borderBottom: 'none' }}
          />
          <th
            colSpan={4}
            className="text-center"
            style={{ borderLeft: '1px solid #dddddd', borderTop: 'none' }}
          >
            Day
          </th>
          <th
            colSpan={4}
            className="text-center"
            style={{ borderLeft: '1px solid #dddddd', borderTop: 'none' }}
          >
            Night
          </th>
          <th
            colSpan={4}
            className="text-center"
            style={{ borderLeft: '1px solid #dddddd', borderTop: 'none' }}
          >
            Day
          </th>
          <th
            colSpan={4}
            className="text-center"
            style={{
              borderLeft: '1px solid #dddddd',
              borderRight: '1px solid #dddddd',
              borderTop: 'none',
            }}
          >
            Night
          </th>
          <th style={{ borderTop: 'none', borderBottom: 'none' }} />
        </tr>
      </thead>
    );
  }

  _renderHeaderRowC() {
    return (
      <thead>
        <tr>
          <th style={{ borderTop: 'none' }}>Date</th>
          <th style={{ borderTop: 'none' }}>Type</th>
          <th style={{ borderTop: 'none' }}>Regn</th>
          <th style={{ borderTop: 'none' }}>Pilot</th>
          <th style={{ borderTop: 'none' }}>Copilot</th>
          <th style={{ borderTop: 'none' }}>Route</th>
          <th
            className="text-center"
            style={{ borderLeft: '1px solid #dddddd', borderTop: 'none', width: '4%' }}
          >
            Dual
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            PIC
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            Co-pilot
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            Comm'd Practice
          </th>
          <th
            className="text-center"
            style={{ borderLeft: '1px solid #dddddd', borderTop: 'none', width: '4%' }}
          >
            Dual
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            PIC
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            Co-pilot
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            Comm'd Practice
          </th>
          <th
            className="text-center"
            style={{ borderLeft: '1px solid #dddddd', borderTop: 'none', width: '4%' }}
          >
            Dual
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            PIC
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            Co-pilot
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            Comm'd Practice
          </th>
          <th
            className="text-center"
            style={{ borderLeft: '1px solid #dddddd', borderTop: 'none', width: '4%' }}
          >
            Dual
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            PIC
          </th>
          <th className="text-center" style={{ borderTop: 'none', width: '4%' }}>
            Co-pilot
          </th>
          <th
            className="text-center"
            style={{ borderRight: '1px solid #dddddd', borderTop: 'none', width: '4%' }}
          >
            Comm'd Practice
          </th>
          <th style={{ borderTop: 'none' }}>Other</th>
        </tr>
      </thead>
    );
  }

  _renderSingleEngineRow(pilotFlightLog) {
    const {
      id,
      booking_start_at_s,
      booking_summary,
      aircraft_type_name,
      aircraft_registration_abbreviated,
      pilot_display_name,
      copilot_display_name,
      pilot_in_charge_day,
      pilot_in_charge_night,
      copilot_day,
      copilot_night,
      dual_day,
      dual_night,
      command_practice_day,
      command_practice_night,
      flight_types,
    } = pilotFlightLog;

    const flight_types_o = JSON.parse(flight_types);

    return (
      <tr key={id}>
        <td>{booking_start_at_s}</td>
        <td>{aircraft_type_name}</td>
        <td>{aircraft_registration_abbreviated}</td>
        <td>{pilot_display_name}</td>
        <td>{copilot_display_name || ''}</td>
        <td>{booking_summary}</td>
        <td className="text-center" style={{ borderLeft: '1px solid #dddddd' }}>
          {dual_day ? accounting.toFixed(dual_day, 2) : ''}
        </td>
        <td className="text-center">
          {pilot_in_charge_day ? accounting.toFixed(pilot_in_charge_day, 2) : ''}
        </td>
        <td className="text-center">
          {copilot_day ? accounting.toFixed(copilot_day, 2) : ''}
        </td>
        <td className="text-center">
          {command_practice_day ? accounting.toFixed(command_practice_day, 2) : ''}
        </td>
        <td className="text-center" style={{ borderLeft: '1px solid #dddddd' }}>
          {dual_night ? accounting.toFixed(dual_night, 2) : ''}
        </td>
        <td className="text-center">
          {pilot_in_charge_night ? accounting.toFixed(pilot_in_charge_night, 2) : ''}
        </td>
        <td className="text-center">
          {copilot_night ? accounting.toFixed(copilot_night, 2) : ''}
        </td>
        <td className="text-center">
          {command_practice_night ? accounting.toFixed(command_practice_night, 2) : ''}
        </td>
        <td style={{ borderLeft: '1px solid #dddddd' }} />
        <td />
        <td />
        <td />
        <td style={{ borderLeft: '1px solid #dddddd' }} />
        <td />
        <td />
        <td style={{ borderRight: '1px solid #dddddd' }} />
        <td>
          {Object.keys(flight_types_o).length > 0 &&
            Object.keys(flight_types_o).map((flightType) => (
              <span key={flightType}>
                {`${flightType}:`}
                <br />
                {accounting.toFixed(flight_types_o[flightType], 2)}
                <br />
              </span>
            ))}
        </td>
      </tr>
    );
  }

  _renderMultiEngineRow(pilotFlightLog) {
    const {
      id,
      booking_start_at_s,
      booking_summary,
      aircraft_type_name,
      aircraft_registration_abbreviated,
      pilot_display_name,
      copilot_display_name,
      pilot_in_charge_day,
      pilot_in_charge_night,
      copilot_day,
      copilot_night,
      dual_day,
      dual_night,
      command_practice_day,
      command_practice_night,
      flight_types,
    } = pilotFlightLog;

    const flight_types_o = JSON.parse(flight_types);

    return (
      <tr key={id}>
        <td>{booking_start_at_s}</td>
        <td>{aircraft_type_name}</td>
        <td>{aircraft_registration_abbreviated}</td>
        <td>{pilot_display_name}</td>
        <td>{copilot_display_name || ''}</td>
        <td>{booking_summary}</td>
        <td style={{ borderLeft: '1px solid #dddddd' }} />
        <td />
        <td />
        <td />
        <td style={{ borderLeft: '1px solid #dddddd' }} />
        <td />
        <td />
        <td />
        <td className="text-center" style={{ borderLeft: '1px solid #dddddd' }}>
          {dual_day ? accounting.toFixed(dual_day, 2) : ''}
        </td>
        <td className="text-center">
          {pilot_in_charge_day ? accounting.toFixed(pilot_in_charge_day, 2) : ''}
        </td>
        <td className="text-center">
          {copilot_day ? accounting.toFixed(copilot_day, 2) : ''}
        </td>
        <td className="text-center">
          {command_practice_day ? accounting.toFixed(command_practice_day, 2) : ''}
        </td>
        <td className="text-center" style={{ borderLeft: '1px solid #dddddd' }}>
          {dual_night ? accounting.toFixed(dual_night, 2) : ''}
        </td>
        <td className="text-center">
          {pilot_in_charge_night ? accounting.toFixed(pilot_in_charge_night, 2) : ''}
        </td>
        <td className="text-center">
          {copilot_night ? accounting.toFixed(copilot_night, 2) : ''}
        </td>
        <td className="text-center" style={{ borderRight: '1px solid #dddddd' }}>
          {command_practice_night ? accounting.toFixed(command_practice_night, 2) : ''}
        </td>
        <td>
          {Object.keys(flight_types_o).length > 0 &&
            Object.keys(flight_types_o).map((flightType) => (
              <span key={flightType}>
                {`${flightType}:`}
                <br />
                {accounting.toFixed(flight_types_o[flightType], 2)}
                <br />
              </span>
            ))}
        </td>
      </tr>
    );
  }

  _renderPilotFlightLogs(pilotFlightLogs) {
    return (
      <tbody>
        {[...pilotFlightLogs]
          .sort((a, b) => a.booking_start_at.localeCompare(b.booking_start_at))
          .map((pilotFlightLog) =>
            pilotFlightLog.multiengine
              ? this._renderMultiEngineRow(pilotFlightLog)
              : this._renderSingleEngineRow(pilotFlightLog)
          )}
      </tbody>
    );
  }

  _calculateTotalCommandTimes(pilotFlightLogs, multiengine, commandTime) {
    const totalResult = pilotFlightLogs
      .filter(
        (pilotFlightLog) =>
          pilotFlightLog.multiengine === multiengine && pilotFlightLog[commandTime]
      )
      .reduce((sum, pilotFlightLog) => sum + pilotFlightLog[commandTime], 0);
    return totalResult ? accounting.toFixed(totalResult, 2) : '';
  }

  _renderFooterRow(pilotFlightLogs, pilotFullName) {
    return (
      <tfoot>
        <tr>
          <th colSpan={6} className="text-end">{`Totals for ${pilotFullName}`}</th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(pilotFlightLogs, false, 'dual_day')}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(
              pilotFlightLogs,
              false,
              'pilot_in_charge_day'
            )}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(pilotFlightLogs, false, 'copilot_day')}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(
              pilotFlightLogs,
              false,
              'command_practice_day'
            )}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(pilotFlightLogs, false, 'dual_night')}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(
              pilotFlightLogs,
              false,
              'pilot_in_charge_night'
            )}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(pilotFlightLogs, false, 'copilot_night')}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(
              pilotFlightLogs,
              false,
              'command_practice_night'
            )}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(pilotFlightLogs, true, 'dual_day')}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(
              pilotFlightLogs,
              true,
              'pilot_in_charge_day'
            )}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(pilotFlightLogs, true, 'copilot_day')}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(
              pilotFlightLogs,
              true,
              'command_practice_day'
            )}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(pilotFlightLogs, true, 'dual_night')}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(
              pilotFlightLogs,
              true,
              'pilot_in_charge_night'
            )}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(pilotFlightLogs, true, 'copilot_night')}
          </th>
          <th className="text-center">
            {this._calculateTotalCommandTimes(
              pilotFlightLogs,
              true,
              'command_practice_night'
            )}
          </th>
          <th />
        </tr>
      </tfoot>
    );
  }

  _renderPilots() {
    return this.props.pilotFlightLogMonthlyByPilotQuery.data
      .filter((data) => {
        if (this.state.filterPilotId && data.pilot_id !== this.state.filterPilotId) {
          return false;
        }
        return true;
      })
      .map((data, index) => {
        const pilot = this.props.pilotListQuery.data.find(
          (model) => model.id === data.pilot_id
        );
        if (pilot) {
          const pilotFullName = pilot.fullName;
          return (
            <Row key={data.pilot_id} className="mb-4" xs={1}>
              <Col>
                <h4>{pilotFullName}</h4>
              </Col>
              <Col>
                <Table striped size="sm">
                  {this._renderHeaderRowA()}
                  {this._renderHeaderRowB()}
                  {this._renderHeaderRowC()}
                  {this._renderPilotFlightLogs(data.pilot_flight_logs)}
                  {this._renderFooterRow(data.pilot_flight_logs, pilotFullName)}
                </Table>
              </Col>
            </Row>
          );
        }
      });
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <>
        <Row className="my-3">
          <Col className="d-flex justify-content-between align-items-start">
            <ReportHeader
              title="Flight Log Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
            <Button
              variant="primary"
              data-report-name="pilot_flight_log_monthly_by_pilot"
              onClick={this.getExport}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
          />
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'filterPilotId',
              value: this.state.filterPilotId,
              onChange: this._handleDisplayPilotIdChange,
            }}
            asElement="select"
            selectOptions={this.props.pilotListQuery.data.filter(
              (model) => this.state.filterPilotIds.indexOf(model.id) > -1
            )}
            optionKey="fullName"
            defaultSelectOptionName="All"
          />
        </Row>
        {this._renderPilots()}
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportPilotId: state.currentSettings.reportPilotId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, { currentSettingsSet, mutationFailure, mutationSet }),
  graphql(contactListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(pilotFlightLogMonthlyByPilotQuery, {
    name: 'pilotFlightLogMonthlyByPilotQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportPilotFlightLog);
