/* eslint-disable react/prefer-stateless-function, react/jsx-props-no-spreading */
import { Component } from 'react';

import get from 'lodash.get';

import InputField from '../form/input_field';
import CheckboxInputField from '../form/checkbox_input_field';

class SeatConfigurationFormAircraftSeatSeatConfigurationFields extends Component {
  render() {
    const { aircraftTypeId, aircraftTypes, parentField } = this.props;

    const {
      seat_installed: { input: seatInstalledInput, meta: seatInstalledMeta },
      aircraft_seat_id: { input: aircraftSeatIdInput },
      position: { input: positionInput, meta: positionMeta },
    } = get(this.props, parentField);

    const aircraftType = aircraftTypes.find(
      (at) => at.id === parseInt(aircraftTypeId, 10)
    );

    const { aircraftSeats } = aircraftType;
    const { name, seat_type: seatType } = aircraftSeats.find(
      (as) => as.id === parseInt(aircraftSeatIdInput.value, 10)
    );
    const fieldsetLegend = seatType
      ? `${name} - (${seatType})`
      : 'New aircraft seat configuration';
    return (
      <fieldset className="border rounded-3 p-3">
        <legend className="float-none w-auto px-3 fs-6">{fieldsetLegend}</legend>
        <CheckboxInputField
          size={this.props.size}
          labelWidth={this.props.labelWidth}
          inputWidth={this.props.inputWidth}
          input={seatInstalledInput}
          meta={seatInstalledMeta}
        >
          Seat Installed
        </CheckboxInputField>
        <InputField
          input={positionInput}
          meta={positionMeta}
          type="text"
          size={this.props.size}
          labelWidth={this.props.labelWidth}
          inputWidth={this.props.inputWidth}
        >
          Position
        </InputField>
      </fieldset>
    );
  }
}

SeatConfigurationFormAircraftSeatSeatConfigurationFields.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 3,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  noTab: true,
};

export default SeatConfigurationFormAircraftSeatSeatConfigurationFields;
