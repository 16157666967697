import { Component } from 'react';

import defaultTo from 'lodash.defaultto';
import find from 'lodash.find';
import get from 'lodash.get';
import includes from 'lodash.includes';
import map from 'lodash.map';

import InputField from '../form/input_field';
import { getSelectable } from '../../lib/utils';

class PilotInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.aircraft_id && this.props.flight_type_id) {
      this.setState({
        selectOptions: this.getSelectOptions(),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.copilot_id !== nextProps.copilot_id ||
      this.props.aircraft_id !== nextProps.aircraft_id ||
      this.props.flight_type_id !== nextProps.flight_type_id
    ) {
      const pilotId = defaultTo(parseInt(nextProps.pilot_id, 10), '');
      const aircraftId = defaultTo(parseInt(nextProps.aircraft_id, 10), '');
      const flightTypeId = defaultTo(parseInt(nextProps.flight_type_id, 10), '');
      let newPilotId = '';
      let selectOptions = [];
      if (aircraftId && flightTypeId) {
        selectOptions = this.getSelectOptions(nextProps);
        const pilotIds = selectOptions.map((so) => so.id);
        if (pilotId && includes(pilotIds, pilotId)) {
          newPilotId = pilotId;
        } else {
          const aircraft = this.props.aircrafts.find((c) => c.id === aircraftId);
          const aircraftDefaultPilotId = get(aircraft, 'default_pilot_id', '');
          if (aircraftDefaultPilotId && includes(pilotIds, aircraftDefaultPilotId)) {
            newPilotId = aircraftDefaultPilotId;
          } else {
            newPilotId = '';
          }
        }
      }
      this.setState({
        selectOptions,
      });
      if (newPilotId !== pilotId) {
        this.props.input.onChange(newPilotId);
      }
    }
  }

  getSelectablePilots(props = this.props) {
    return getSelectable(
      get(props, 'pilots', []),
      get(props, 'pilot_id'),
      'fullName',
      'rolePilot'
    );
  }

  getSelectOptions(props = this.props) {
    const copilotId = defaultTo(parseInt(props.copilot_id, 10), '');
    const aircraftId = defaultTo(parseInt(props.aircraft_id, 10), '');
    const flightTypeId = defaultTo(parseInt(props.flight_type_id, 10), '');
    const pilotIds = map(
      get(
        find(
          get(
            find(props.aircraftFlightTypePilotMap, ['aircraft_id', aircraftId]),
            'flight_types',
            {}
          ),
          ['flight_type_id', flightTypeId]
        ),
        'pilots',
        {}
      ),
      'pilot_id'
    );
    return this.getSelectablePilots(props)
      .filter((p) => includes(pilotIds, p.id))
      .filter((p) => p.id !== copilotId);
  }

  render() {
    const { input, meta } = this.props;

    return (
      <InputField
        type="text"
        size="sm"
        labelWidth={4}
        inputWidth={8}
        asElement="select"
        selectOptions={this.state.selectOptions}
        input={input}
        meta={meta}
      >
        {this.props.children}
      </InputField>
    );
  }
}

export default PilotInputField;
