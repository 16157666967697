const defaultProps = {
  size: 'sm',
  nameWidth: 5,
  descriptionWidth: 7,
};

const DlHorizontal = (props) => {
  const { dt, dd, children, size, nameWidth, descriptionWidth } = {
    ...defaultProps,
    ...props,
  };

  return (
    <dl className="row mb-2">
      <dt className={`col-${size}-${nameWidth} text-end`}>{dt}</dt>
      <dd className={`ps-0 mb-0 col-${size}-${descriptionWidth}`}>{dd || children}</dd>
    </dl>
  );
};

export default DlHorizontal;
