import includes from 'lodash.includes';
import memoize from 'lodash.memoize';

export const LocationFuelDataMemo = memoize(
  ({ locationId, fuelBowsers, fuelTankers }) => {
    if (locationId) {
      const locationBowsers = fuelBowsers.filter(
        (fb) => fb.location_id === parseInt(locationId, 10) && fb.active
      );
      const locationTankers = fuelTankers.filter((ft) =>
        includes(ft.service_location_ids, parseInt(locationId, 10))
      );
      return {
        locationBowsers,
        locationTankers,
      };
    }
    return {};
  },
  ({ locationId }) => {
    if (locationId) {
      return [locationId].map((i) => String(i)).join('.');
    }
    return 'unknown';
  }
);
