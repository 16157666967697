import { Col, Badge } from 'react-bootstrap';

const FilterBadge = ({ filtered, children }) => (
  <Col sm="auto" className="me-2 align-self-center">
    <p className="m-0 p-0">
      <Badge
        pill
        bg={filtered ? 'warning' : 'info'}
        style={filtered ? {} : { opacity: '0.5' }}
      >
        {children}
      </Badge>
    </p>
  </Col>
);

export default FilterBadge;
