import { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import BookingManagerListItem from './booking_manager_list_item';

class BookingManagerListItems extends Component {
  renderItems() {
    if (this.props.listBookings.length === 0) {
      return (
        <Card>
          <Card.Body>
            <p className="text-center">No Records</p>
          </Card.Body>
        </Card>
      );
    }
    return this.props.listBookings.map((model, index) => (
      <BookingManagerListItem
        key={index}
        model={model}
        currentContact={this.props.currentContact}
        onListItemCalendarClicked={this.props.onListItemCalendarClicked}
        onFlightCompleteClicked={this.props.onFlightCompleteClicked}
        onDeleteClicked={this.props.onDeleteClicked}
        onCloneClicked={this.props.onCloneClicked}
        currentSettingsBookingCalendarTypes={
          this.props.currentSettingsBookingCalendarTypes
        }
        currentSettingsBookingStatuses={this.props.currentSettingsBookingStatuses}
      />
    ));
  }

  render() {
    return this.renderItems();
  }
}

BookingManagerListItems.propTypes = {
  currentContact: PropTypes.object,
  onListItemCalendarClicked: PropTypes.func,
  onFlightCompleteClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  onCloneClicked: PropTypes.func,
};

export default BookingManagerListItems;
