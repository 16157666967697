import get from 'lodash.get';
import memoize from 'lodash.memoize';

export const AircraftTypeFuelDataMemo = memoize(
  ({ aircraftTypeId, aircraftTypes, fuelTypes }) => {
    if (aircraftTypeId) {
      const aircraftType = aircraftTypes.find(
        (at) => at.id === parseInt(aircraftTypeId, 10)
      );
      const fuelType = fuelTypes.find(
        (ft) => ft.id === get(aircraftType, 'fuel_type_id')
      );
      return {
        aircraftTypeFuelTypeSpecificGravity: get(fuelType, 'specific_gravity'),
        aircraftTypeFuelTypeId: get(aircraftType, 'fuel_type_id'),
        aircraftTypeCompanyPlannedReserve: get(aircraftType, 'company_planned_reserve'),
        aircraftTypeCruiseFuelConsumptionSl: get(
          aircraftType,
          'cruise_fuel_consumption_sl'
        ),
        aircraftTypeFuelUnit: get(aircraftType, 'fuel_gauge_unit'),
      };
    }
    return {};
  },
  ({ aircraftTypeId }) => {
    if (aircraftTypeId) {
      return [aircraftTypeId].map((i) => String(i)).join('.');
    }
    return 'unknown';
  }
);
