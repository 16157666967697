import makeValidator from './make_validator';
import { documentNestedFormSchema } from './document_validator';

const checkFormSchema = {
  type: 'object',
  required: [
    'check_type_id',
    'check_category_id',
    'checkable_type',
    'checkable_id',
    'manager_id',
    'check_end_on',
  ],
  properties: {
    documents: {
      type: 'array',
      items: documentNestedFormSchema,
    },
  },
};

export const checkFormValidator = makeValidator(checkFormSchema);
