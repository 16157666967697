import { Row } from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';
import compact from 'lodash.compact';

import InputField from '../form/input_field';
import ReactDateTimeField from '../form/react_date_time_field';

moment.updateLocale('en-nz');

class EndDateAndTimeInputFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      time: '',
      dateError: {},
      timeError: {},
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.end_at) {
      this.receiveEndAt();
      if (this.props.start_at || this.props.lastFlightSegmentEndAt) {
        this.normalizeEndAt();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.end_at !== nextProps.end_at) {
      this.receiveEndAt(nextProps);
    }
    if (nextProps.end_at && (nextProps.start_at || nextProps.lastFlightSegmentEndAt)) {
      this.normalizeEndAt(nextProps, this.props);
    }
  }

  handleDateChange(newDate) {
    const date = moment(newDate);
    if (date.isValid()) {
      const formattedDate = date.format('DD/MM/YYYY');
      this.setState({
        date: formattedDate,
        dateError: {},
      });
      const { time, timeError } = this.state;
      if (!timeError.invalid) {
        this.props.input.onChange(
          moment(`${formattedDate} ${time}`, 'DD/MM/YYYY HHmm').format()
        );
      }
    } else {
      this.setState({
        date,
        dateError: { touched: true, invalid: true, error: 'DD/MM/YYYY' },
      });
    }
  }

  handleTimeChange(e) {
    let time = e.target.value;
    time = time === '2400' ? '2359' : time;
    if (time.match(/^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/)) {
      this.setState({
        time,
        timeError: {},
      });
      const { date, dateError } = this.state;
      if (!dateError.invalid) {
        this.props.input.onChange(moment(`${date} ${time}`, 'DD/MM/YYYY HHmm').format());
      }
    } else {
      this.setState({
        time,
        timeError: { touched: true, invalid: true, error: 'HHMM' },
      });
    }
  }

  normalizeEndAt(props = this.props, prevProps = {}) {
    const mmLatestDateTime = moment.max(
      compact([props.start_at, props.lastFlightSegmentEndAt]).map((datetime) =>
        moment(datetime)
      )
    );
    const mmEndAt = moment(props.end_at);

    if (mmLatestDateTime.isBefore(mmEndAt)) {
      if (prevProps.end_at && (prevProps.start_at || prevProps.lastFlightSegmentEndAt)) {
        if (prevProps.end_at === props.end_at) {
          const mmPrevLatestDateTime = moment.max(
            compact([prevProps.start_at, prevProps.lastFlightSegmentEndAt]).map(
              (datetime) => moment(datetime)
            )
          );
          const mmPrevEndAt = moment(prevProps.end_at);
          const prevDayDiff = mmPrevEndAt.diff(mmPrevLatestDateTime, 'days');
          const currentDayDiff = mmEndAt.diff(mmLatestDateTime, 'days');
          if (currentDayDiff > prevDayDiff) {
            mmEndAt.subtract(currentDayDiff - prevDayDiff, 'days');
            props.input.onChange(mmEndAt.format());
          }
        }
      }
    } else if (mmLatestDateTime.isAfter(mmEndAt)) {
      if (mmLatestDateTime.isAfter(mmEndAt, 'day')) {
        mmEndAt.set({
          year: mmLatestDateTime.year(),
          month: mmLatestDateTime.month(),
          date: mmLatestDateTime.date(),
        });
      }
      if (mmLatestDateTime.isAfter(mmEndAt, 'second')) {
        mmEndAt.set({
          hour: mmLatestDateTime.hour(),
          minute: mmLatestDateTime.minute(),
          second: mmLatestDateTime.second(),
        });
      }
      props.input.onChange(mmEndAt.format());
    }
  }

  receiveEndAt(props = this.props) {
    this.setState({
      date: moment(props.end_at).format('DD/MM/YYYY'),
      time: moment(props.end_at).format('HHmm'),
    });
  }

  render() {
    const { input, size } = this.props;

    return (
      <Row>
        <ReactDateTimeField
          input={{
            ...this.props.input,
            onChange: this.handleDateChange,
          }}
          labelWidth={0}
          inputWidth={6}
          dateFormat="DD/MM/YYYY"
          timeFormat={false}
          closeOnSelect
        >
          {this.props.children}
        </ReactDateTimeField>
        {this.props.withoutTime || (
          <InputField
            size={size}
            labelWidth={0}
            inputWidth={6}
            input={{
              name: `${input.name}-time`,
              value: this.state.time,
              onChange: this.handleTimeChange,
            }}
            meta={{ ...this.state.timeError }}
          >
            Time
          </InputField>
        )}
      </Row>
    );
  }
}

EndDateAndTimeInputFields.defaultProps = {
  size: 'sm',
};

export default EndDateAndTimeInputFields;
