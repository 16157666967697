import { useState, useEffect } from 'react';

import range from 'lodash.range';
import forEach from 'lodash.foreach';

const WeightBalanceAssetAssignmentFields = (props) => {
  const {
    index,
    change,
    flightSegmentIndex,
    flightSegmentsLength,
    formValues,
    isDeveloper,
  } = props;

  const {
    name,
    asset_assignment_installed_weight_increase: assetAssignmentInstalledWeightIncrease,
    asset_assignment_uninstalled_weight_reduction:
      assetAssignmentUninstalledWeightReduction,
    asset_assignment_weight_unit: assetAssignmentWeightUnit,
    asset_assignment_installed: assetAssignmentInstalled,
  } = formValues;

  const [installed, setInstalled] = useState(false);

  useEffect(() => {
    setInstalled(assetAssignmentInstalled || false);
  }, [assetAssignmentInstalled]);

  const handleInstalledChange = (e) => {
    setInstalled(e.target.checked);
    // Asset changes are propagated forward
    const flightSegmentIndexes = range(flightSegmentIndex, flightSegmentsLength);
    forEach(flightSegmentIndexes, (i) => {
      change(
        `flight_segments_attributes[${i}].asset_assignments_attributes[${index}].asset_assignment_installed`,
        e.target.checked
      );
    });
  };

  const renderWeight = () => {
    if (
      assetAssignmentUninstalledWeightReduction ||
      assetAssignmentInstalledWeightIncrease
    ) {
      return `${
        assetAssignmentUninstalledWeightReduction ||
        assetAssignmentInstalledWeightIncrease
      }${assetAssignmentWeightUnit}`;
    }
    return 0;
  };

  return (
    <tr>
      <td>{name}</td>
      {isDeveloper && <td>{assetAssignmentWeightUnit}</td>}
      <td>{renderWeight()}</td>
      <td>
        <input type="checkbox" checked={installed} onChange={handleInstalledChange} />
      </td>
    </tr>
  );
};

export default WeightBalanceAssetAssignmentFields;
