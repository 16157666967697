import get from 'lodash.get';
import memoize from 'lodash.memoize';

import { AircraftTypeAirspeedDataMemo } from './aircraft_type_airspeed_data_memo';

export const AircraftAirspeedDataMemo = memoize(
  ({ aircraftId, aircrafts, aircraftTypes }) => {
    if (aircraftId) {
      const aircraft = aircrafts.find((a) => a.id === parseInt(aircraftId, 10));
      const aircraftType = aircraftTypes.find(
        (at) => at.id === get(aircraft, 'aircraft_type_id')
      );
      const aircraftTypeId = get(aircraftType, 'id', '');
      return AircraftTypeAirspeedDataMemo({
        aircraftTypeId,
        aircraftTypes,
      });
    }
    return {};
  },
  ({ aircraftId }) => {
    if (aircraftId) {
      return [aircraftId].map((i) => String(i)).join('.');
    }
    return 'unknown';
  }
);
