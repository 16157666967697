import moment from 'moment';

import get from 'lodash.get';

const BookingCreators = (props) => {
  const {
    contactsDataSelector,
    created_by_admin_id: createdByAdminId,
    created_at: createdAt,
    updated_by_admin_id: updatedByAdminId,
    updated_at: updatedAt,
  } = props;

  function renderCreator(date, id, type) {
    if (date) {
      const adminDisplayName = get(contactsDataSelector, [id, 'fullName'], 'unknown');
      return (
        <span>
          {type}
          {' by '}
          {adminDisplayName}
          {' on '}
          {moment(date).format('llll')}
        </span>
      );
    }
    return <p>no date</p>;
  }

  return (
    <div>
      <div>{renderCreator(updatedAt, updatedByAdminId, 'Last Updated')}</div>
      <div>{renderCreator(createdAt, createdByAdminId, 'Created')}</div>
    </div>
  );
};

export default BookingCreators;
