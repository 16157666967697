import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash.get';
import pick from 'lodash.pick';

import ContactFormPhoneNumberFields from './contact_form_phone_number_fields';
import Glyphicon from '../glyphicon';

class ContactFormPhoneNumberFieldArray extends Component {
  constructor(props) {
    super(props);
    this._handleDefaultClick = this._handleDefaultClick.bind(this);
    this._handleAddButtonClick = this._handleAddButtonClick.bind(this);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  _handleAddButtonClick() {
    this.props.phoneNumbers.fields.push({
      phone_number_type: 'home',
    });
  }

  _handleDeleteClick(index) {
    const phoneNumberValues = get(this.props, [
      'formValues',
      this.props.phoneNumbers.fields.name,
      index,
    ]);
    if (get(phoneNumberValues, 'id')) {
      this.props.change(
        [this.props.phoneNumbers.fields.name, index, '_destroy'].join('.'),
        true
      );
    } else {
      this.props.phoneNumbers.fields.remove(index);
    }
  }

  _handleDefaultClick(index) {
    this.props.phoneNumbers.fields.forEach((field) => {
      this.props.change(`${field}.default_phone_number`, false);
    });
    if (index || index === 0) {
      this.props.change(
        `${this.props.phoneNumbers.fields.name}[${index}].default_phone_number`,
        true
      );
    }
  }

  _renderPhoneNumbersList() {
    const formKeys = ['id'];
    return (
      <Col sm={{ span: 9, offset: 3 }}>
        {this.props.phoneNumbers.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}._destroy`,
              `${field}.phone_number_type`,
              `${field}.description`,
              `${field}.country_code`,
              `${field}.area_code`,
              `${field}.phone_number`,
              `${field}.extension`,
              `${field}.default_phone_number`,
            ];
            return (
              <Fields
                key={`ph-${index}`}
                index={index}
                parentField={field}
                names={fields}
                component={ContactFormPhoneNumberFields}
                formValues={pick(values, formKeys)}
                handleDefaultClick={this._handleDefaultClick}
                handleDeleteClick={this._handleDeleteClick}
                phoneNumberTypes={this.props.phoneNumberTypes}
              />
            );
          }
        })}
      </Col>
    );
  }

  render() {
    return (
      <Row>
        {this._renderPhoneNumbersList()}
        <Col sm={{ span: 9, offset: 3 }}>
          <Button type="button" variant="dark" onClick={this._handleAddButtonClick}>
            <Glyphicon glyph="plus" />
            Add Phone Number
          </Button>
        </Col>
      </Row>
    );
  }
}

export default ContactFormPhoneNumberFieldArray;
