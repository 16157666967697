const Title = ({ children, form, updating, list, show, fullName, id, noNew }) => (
  <>
    {form && updating && <h3>Edit {children}</h3>}
    {form && !updating && !noNew && <h3>New {children}</h3>}
    {form && !updating && noNew && <h3>{children}</h3>}

    {list && <h3>{children}</h3>}
    {show && fullName && (
      <h3>
        {children} Details for {fullName}
      </h3>
    )}
    {show && id && (
      <h3>
        {children} Details for #{id}
      </h3>
    )}
  </>
);

export default Title;
