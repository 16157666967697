import { useEffect, useRef, useCallback, useMemo } from 'react';
import moment from 'moment';

import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';

import renderEventContent from './booking_manager_calendar_render';
import renderEventPopover from './booking_manager_calendar_popover';
import getContactResources from './booking_manager_calendar_contact_resources';
import getAircraftResources from './booking_manager_calendar_aircraft_resources';
import getContactEvents from './booking_manager_calendar_contact_events';
import getAircraftEvents from './booking_manager_calendar_aircraft_events';
import getSunEvents from './booking_manager_calendar_sun_events';

moment.updateLocale('en-nz');

const BookingManagerCalendar = ({
  isDeveloper,
  BOOKING_VIEW_CALENDAR_HTML_ID,
  BOOKING_VIEW_POPOVERS_HTML_ID,
  bookingView,
  defaultBookingViewBookingView,
  currentSettingsBookingCollectionView,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingCollectionEndDate,
  currentSettingsBookingCalendarTypes,
  contactBookings,
  aircraftBookings,
  dutyEvents,
  allViewPilotIds,
  onEditClicked,
  onDisplayDateChanged,
  resourceTypeContact,
  resourceGroupContact,
}) => {
  const calendarRef = useRef();

  const sunEvents = useMemo(
    () =>
      getSunEvents(
        currentSettingsBookingCollectionStartDate,
        currentSettingsBookingCollectionEndDate,
        currentSettingsBookingCollectionView
      ),
    [
      currentSettingsBookingCollectionStartDate,
      currentSettingsBookingCollectionEndDate,
      currentSettingsBookingCollectionView,
    ]
  );

  const contactResources = useMemo(() => {
    if (contactBookings.length > 0 || dutyEvents.length > 0) {
      const newContactResources = getContactResources({
        bookings: contactBookings,
        dutyEvents,
        bookingView,
        allViewPilotIds,
        resourceGroupContact,
      });
      return newContactResources;
    }
    return [];
  }, [contactBookings, dutyEvents, bookingView, allViewPilotIds, resourceGroupContact]);

  const contactEvents = useMemo(() => {
    if (contactBookings.length > 0 || dutyEvents.length > 0) {
      const newContactEvents = getContactEvents({
        bookings: contactBookings,
        dutyEvents,
        bookingView,
        defaultBookingViewBookingView,
        currentSettingsBookingCollectionView,
        currentSettingsBookingCollectionStartDate,
        currentSettingsBookingCollectionEndDate,
        currentSettingsBookingCalendarTypes,
        isDeveloper,
      });
      return [...newContactEvents, ...sunEvents];
    }
    return [];
  }, [
    contactBookings,
    dutyEvents,
    bookingView,
    defaultBookingViewBookingView,
    currentSettingsBookingCollectionView,
    currentSettingsBookingCollectionStartDate,
    currentSettingsBookingCollectionEndDate,
    currentSettingsBookingCalendarTypes,
    isDeveloper,
    sunEvents,
  ]);

  const aircraftResources = useMemo(() => {
    if (aircraftBookings.length > 0) {
      const newAircraftResources = getAircraftResources({
        bookings: aircraftBookings,
        bookingView,
      });
      return newAircraftResources;
    }
    return [];
  }, [aircraftBookings, bookingView]);

  const aircraftEvents = useMemo(() => {
    if (aircraftBookings.length > 0) {
      const newAircraftEvents = getAircraftEvents({
        bookings: aircraftBookings,
        currentSettingsBookingCollectionView,
        currentSettingsBookingCalendarTypes,
      });
      return [...newAircraftEvents, ...sunEvents];
    }
    return [];
  }, [
    aircraftBookings,
    currentSettingsBookingCollectionView,
    currentSettingsBookingCalendarTypes,
    sunEvents,
  ]);

  const handleDateClicked = useCallback(
    (info) => {
      if (onDisplayDateChanged) {
        const {
          dateStr,
          view: { type: viewType },
        } = info;
        if (viewType !== 'resourceTimelineDay') {
          const startCalendar = moment(dateStr).startOf('day').format();
          const endCalendar = moment(dateStr).endOf('day').format();
          onDisplayDateChanged(startCalendar, endCalendar, 'resourceTimelineDay');
        }
      }
    },
    [onDisplayDateChanged]
  );

  const handleEventClicked = useCallback(
    (info) => {
      if (onEditClicked) {
        const {
          event: {
            extendedProps: { bookingId, adminComplete, isFlight },
          },
        } = info;
        if (bookingId && !adminComplete) {
          onEditClicked(bookingId, isFlight);
        }
      }
    },
    [onEditClicked]
  );

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.batchRendering(() => {
        calendarApi.changeView(
          currentSettingsBookingCollectionView,
          currentSettingsBookingCollectionStartDate
        );
      });
    }
  }, [currentSettingsBookingCollectionStartDate, currentSettingsBookingCollectionView]);

  return (
    <div id={BOOKING_VIEW_CALENDAR_HTML_ID}>
      <FullCalendar
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        ref={calendarRef}
        timeZone="local"
        locale="en-nz"
        firstDay={1}
        height="auto"
        plugins={[
          interactionPlugin,
          momentPlugin,
          dayGridPlugin,
          timeGridPlugin,
          resourceTimelinePlugin,
        ]}
        initialView={currentSettingsBookingCollectionView}
        initialDate={currentSettingsBookingCollectionStartDate}
        headerToolbar={false}
        views={{
          resourceTimelineDay: {
            eventMinWidth: 15,
          },
          timeGridWeek: {
            allDaySlot: true,
            scrollTime: '06:00:00',
          },
        }}
        events={resourceTypeContact ? contactEvents : aircraftEvents}
        resources={resourceTypeContact ? contactResources : aircraftResources}
        resourceGroupField={resourceGroupContact ? '' : 'groupId'}
        scrollTime="06:00:00"
        resourceOrder="extendedProps.position"
        resourceAreaWidth="200px"
        resourceAreaHeaderContent=""
        eventContent={(info) => renderEventContent(info)}
        eventDidMount={(info) => renderEventPopover(info, BOOKING_VIEW_POPOVERS_HTML_ID)}
        eventClick={(info) => handleEventClicked(info)}
        dateClick={(info) => handleDateClicked(info)}
      />
    </div>
  );
};

export default BookingManagerCalendar;
