import { Component } from 'react';

import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';

import InputField from '../form/input_field';

class AircraftConfigurationInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
  }

  UNSAFE_componentWillMount() {
    const aircraftId = defaultTo(
      parseInt(get(this.props, 'formValues.aircraft_id'), 10),
      ''
    );
    if (aircraftId) {
      this.setState({
        selectOptions: this.getSelectOptions(),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.aircraft_id') !==
      get(nextProps, 'formValues.aircraft_id')
    ) {
      const aircraftConfigurationId = defaultTo(
        parseInt(get(nextProps, 'formValues.aircraft_configuration_id'), 10),
        ''
      );
      const aircraftId = defaultTo(
        parseInt(get(nextProps, 'formValues.aircraft_id'), 10),
        ''
      );
      let newAircraftConfigurationId;
      let selectOptions;
      if (aircraftId) {
        selectOptions = this.getSelectOptions(nextProps);
        const aircraft = this.props.aircrafts.find((c) => c.id === aircraftId);
        newAircraftConfigurationId = get(
          aircraft,
          'default_aircraft_configuration_id',
          ''
        );
        const aircraftType = this.props.aircraftTypes.find(
          (at) => at.id === get(aircraft, 'aircraft_type_id')
        );
        nextProps.change(
          'booking_cruise_airspeed_sl',
          get(aircraftType, 'cruise_airspeed_sl', '')
        );
        nextProps.change(
          'booking_cruise_fuel_consumption_sl',
          get(aircraftType, 'cruise_fuel_consumption_sl', '')
        );
        nextProps.change(
          'booking_planned_reserve',
          get(aircraftType, 'company_planned_reserve', '')
        );
        nextProps.change('booking_arm_unit', get(aircraftType, 'arm_unit', ''));
        nextProps.change('booking_weight_unit', get(aircraftType, 'weight_unit', ''));
        nextProps.change('booking_fuel_unit', get(aircraftType, 'fuel_gauge_unit', ''));
        nextProps.change('booking_airspeed_unit', get(aircraftType, 'airspeed_unit', ''));
        nextProps.change('booking_aircraft_weight', get(aircraft, 'aircraft_weight', ''));
        nextProps.change(
          'booking_aircraft_weight_defined',
          get(aircraft, 'aircraft_weight_defined', '')
        );
        nextProps.change(
          'booking_aircraft_lateral_arm',
          get(aircraft, 'aircraft_lateral_arm', '')
        );
        nextProps.change(
          'booking_aircraft_longitudinal_arm',
          get(aircraft, 'aircraft_longitudinal_arm', '')
        );
      } else {
        selectOptions = [];
        newAircraftConfigurationId = '';
        nextProps.change('booking_cruise_airspeed_sl', '');
        nextProps.change('booking_cruise_fuel_consumption_sl', '');
        nextProps.change('booking_planned_reserve', '');
        nextProps.change('booking_arm_unit', '');
        nextProps.change('booking_weight_unit', '');
        nextProps.change('booking_fuel_unit', '');
        nextProps.change('booking_airspeed_unit', '');
        nextProps.change('booking_aircraft_weight', '');
        nextProps.change('booking_aircraft_weight_defined', '');
        nextProps.change('booking_aircraft_lateral_arm', '');
        nextProps.change('booking_aircraft_longitudinal_arm', '');
      }
      this.setState({
        selectOptions,
      });
      if (newAircraftConfigurationId !== aircraftConfigurationId) {
        this.props.input.onChange(newAircraftConfigurationId);
      }
    }
  }

  getSelectOptions(props = this.props) {
    const {
      formValues: { aircraft_id: aircraftId },
      aircrafts,
      aircraftConfigurations,
    } = props;
    const aircraft = aircrafts.find((c) => c.id === parseInt(aircraftId, 10));
    const { aircraft_type_id: aircraftTypeId } = aircraft;
    return aircraftConfigurations.filter(
      (ac) => ac.aircraft_type_id === defaultTo(parseInt(aircraftTypeId, 10), '')
    );
  }

  render() {
    const { input, meta } = this.props;
    return (
      <InputField
        input={input}
        meta={meta}
        type="text"
        asElement="select"
        size={this.props.size}
        labelWidth={this.props.labelWidth}
        inputWidth={this.props.inputWidth}
        noTab={this.props.noTab}
        selectOptions={this.state.selectOptions}
        defaultSelectOption={this.props.defaultSelectOption}
      >
        Configuration
      </InputField>
    );
  }
}

AircraftConfigurationInputField.defaultProps = {
  size: 'sm',
  labelWidth: 4,
  inputWidth: 8,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  noTab: true,
};

export default AircraftConfigurationInputField;
