import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import flatten from 'lodash.flatten';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';
import uniqBy from 'lodash.uniqby';

import FuelTankerFillFields from './fuel_tanker_fill_fields';

class FuelTankerFillFieldArray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getSelectOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentStartLocationIds = get(
      this.props,
      'flightSegmentsCalculatedData',
      []
    ).map((fd) => fd.startLocationId);
    const nextStartLocationIds = get(nextProps, 'flightSegmentsCalculatedData', []).map(
      (fd) => fd.startLocationId
    );
    if (!isEqual(nextStartLocationIds, currentStartLocationIds)) {
      this.getSelectOptions(nextProps);
    }
  }

  getSelectOptions(props = this.props) {
    const fuelTankers = uniqBy(
      flatten(
        get(props, 'flightSegmentsCalculatedData', [])
          .filter((fd) => get(fd, 'flightSegmentFuelTankers', []).length > 0)
          .map((fd) => fd.flightSegmentFuelTankers)
      ),
      'id'
    );
    this.setState({
      selectOptions: fuelTankers,
    });
  }

  handleAddButtonClick() {
    this.props.fuelTankerFills.fields.push({
      tanker_fillable_id: this.props.formValues.aircraft_id,
      tanker_fillable_type: 'Aircraft',
      quantity_value: 0,
      filled_on: this.props.formValues.start_at,
    });
  }

  handleDeleteButtonClick(index) {
    this.props.fuelTankerFills.fields.remove(index);
  }

  renderFuelTankerFillsList() {
    return (
      <>
        {this.props.fuelTankerFills.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}._destroy`,
              `${field}.fuel_tanker_id`,
              `${field}.quantity_value`,
            ];
            return (
              <Fields
                key={`booking-${field}`}
                index={index}
                parentField={field}
                names={fields}
                component={FuelTankerFillFields}
                formValues={pick(values, ['id', 'quantity_value', 'fuel_tanker_id'])}
                handleDeleteButtonClick={this.handleDeleteButtonClick}
                selectOptions={this.state.selectOptions}
                currentSettingsFuelBowserFillDefaultQuantityUnit={
                  this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
                }
              />
            );
          }
          return undefined;
        })}
      </>
    );
  }

  render() {
    const fillsLength = get(
      this.props,
      'formValues.fuel_tanker_fills_attributes',
      []
    ).filter((ff) => !ff._destroy).length;
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Tanker Refuelling</strong>
          </p>
        </Col>
        <Col sm={10}>
          {fillsLength > 0 && this.renderFuelTankerFillsList()}
          <Row>
            <Col sm={2} className="text-end">
              <Button
                type="button"
                variant="link"
                size="sm"
                onClick={this.handleAddButtonClick}
                tabIndex={-1}
                disabled={!this.props.formValues.aircraft_id}
              >
                add...
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default FuelTankerFillFieldArray;
