import moment from 'moment';

import upperFirst from 'lodash.upperfirst';

moment.updateLocale('en-nz');

const BookingTitle = (props) => {
  function _humanize(string) {
    const newString = string || 'Booking';
    return upperFirst(newString.split('_').join(' '));
  }

  if (props.updating) {
    return (
      <h2>
        Edit &nbsp;
        {_humanize(props.calendarType)}
        &nbsp; #{props.reference}
        &nbsp;
        {moment(props.startAt).format('ddd, DD MMM YYYY')}
      </h2>
    );
  } else {
    return (
      <h2>
        New &nbsp;
        {_humanize(props.calendarType)}
      </h2>
    );
  }
};

export default BookingTitle;
