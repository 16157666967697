import { Row, Col, Table } from 'react-bootstrap';
import { FieldArray } from 'redux-form';

import pick from 'lodash.pick';

import WeightBalanceTankAssignmentFieldArray from './weight_balance_tank_assignment_field_array';

const WeightBalanceTankAssignments = (props) => {
  const { field, formValues } = props;

  const renderTankAssignmentsFieldArray = (tankAssignments) => {
    const formKeys = ['tank_assignments_attributes'];

    return (
      <WeightBalanceTankAssignmentFieldArray
        tankAssignments={tankAssignments}
        formValues={pick(formValues, formKeys)}
      />
    );
  };

  return (
    <Row className="mt-3">
      <Col xs={8}>
        <Table striped size="sm">
          <thead>
            <tr>
              <th>Tank Name</th>
              <th>Tank Fuel Unit</th>
              <th>Tank Maximum Capacity</th>
              <th>Tank Take Off Fuel</th>
            </tr>
          </thead>
          <FieldArray
            name={`${field}.tank_assignments_attributes`}
            component={renderTankAssignmentsFieldArray}
          />
        </Table>
      </Col>
    </Row>
  );
};

export default WeightBalanceTankAssignments;
