import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component, Fragment } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { connect } from 'react-redux';

import debounce from 'lodash.debounce';
import uniq from 'lodash.uniq';
import first from 'lodash.first';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady, getExport } from '../lib/utils';

import bookingSwapsForMonthByProviderQuery from '../queries/booking_swaps_for_month_by_provider_query';
import contactListQuery from '../queries/contact_list_query';

moment.updateLocale('en-nz');

class ReportProviderSwapDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterProviderId: this.props.currentSettingsReportProviderId,
      filterProviderIds: [],
    };
    this._handleDisplayProviderIdChange = this._handleDisplayProviderIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
    }
    if (this.props.params.provider_id) {
      this._handleDisplayProviderIdChange({
        target: { value: this.props.params.provider_id },
      });
    }
    if (
      !moment(this.props.currentSettingsReportStart).isSame(
        moment(this.props.currentSettingsReportStart).startOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportStart: moment(this.props.currentSettingsReportStart)
          .startOf('month')
          .toISOString(),
      });
    }
    if (
      !moment(this.props.currentSettingsReportEnd).isSame(
        moment(this.props.currentSettingsReportStart).endOf('month')
      )
    ) {
      this.props.currentSettingsSet({
        reportEnd: moment(this.props.currentSettingsReportStart)
          .endOf('month')
          .toISOString(),
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.bookingSwapsForMonthByProviderQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterProviderIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterProviderIds = nextProps.bookingSwapsForMonthByProviderQuery.data
        .filter((data) => data.bookings_length > 0)
        .map((data) => data.provider_id);
    }
    this.setState({
      filterProviderIds,
    });
    let filterProviderId = nextProps.currentSettingsReportProviderId;
    if (filterProviderId && this.isLoaded(nextProps)) {
      if (
        filterProviderIds.length > 0 &&
        filterProviderIds.indexOf(filterProviderId) === -1
      ) {
        filterProviderId = '';
      }
    }
    if (filterProviderId !== this.props.currentSettingsReportProviderId) {
      this._handleDisplayProviderIdChange({ target: { value: filterProviderId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.bookingSwapsForMonthByProviderQuery,
      props.providerListQuery
    );
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      providerId: this.props.currentSettingsReportProviderId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  _handleDisplayProviderIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterProviderId: value,
    });
    this.props.currentSettingsSet({
      reportProviderId: value,
    });
  }

  _renderFixed(value, symbol = '') {
    return value ? symbol + value.toFixed(2) : '-';
  }

  _renderRow(booking) {
    const {
      id,
      booking_id,
      aircraft_registration_abbreviated,
      reference,
      customer_reference,
      start_at_s,
      pilot_display_name,
      copilot_display_name,
      booking_summary,
      hobb_record_flight_time_credit,
      hobb_record_flight_time_debit,
      swap_type,
      counterparty_full_name,
    } = booking;

    return (
      <tr key={id}>
        <td>
          <LinkContainer to={`/flights/${booking_id}/edit`} className="ps-0">
            <Button variant="link">{`#${reference}`}</Button>
          </LinkContainer>
        </td>
        <td>{start_at_s}</td>
        <td>{swap_type}</td>
        <td>{counterparty_full_name}</td>
        <td>{aircraft_registration_abbreviated}</td>
        <td>
          {[pilot_display_name, copilot_display_name].filter((name) => name).join(', ')}
        </td>
        <td>{booking_summary}</td>
        <td>{customer_reference}</td>
        <td className="text-end">{this._renderFixed(hobb_record_flight_time_credit)}</td>
        <td className="text-end">{this._renderFixed(hobb_record_flight_time_debit)}</td>
      </tr>
    );
  }

  _renderRows(bookings) {
    return <tbody>{bookings.map((booking) => this._renderRow(booking))}</tbody>;
  }

  _renderOpeningBalanceRow(balance) {
    balance = parseFloat(balance);
    const type = balance === 0 ? '' : balance > 0 ? 'Credit ' : 'Debit ';
    return (
      <tr>
        <th colSpan={10} className="text-end border-top-0">
          {`${type}Balance Brought Forward ${balance}`}
        </th>
      </tr>
    );
  }

  _renderHeaderRow() {
    return (
      <tr>
        <th>Ref#</th>
        <th>Date</th>
        <th>Type</th>
        <th>Counterparty</th>
        <th>Aircraft</th>
        <th>Pilot</th>
        <th>Route Details</th>
        <th>Job Ref</th>
        <th style={{ width: '12%' }} className="text-end">
          Credit Time
        </th>
        <th style={{ width: '12%' }} className="text-end">
          Debit Time
        </th>
      </tr>
    );
  }

  _renderFooterRow(bookings, adjustment) {
    const credit_total = bookings.reduce(
      (sum, booking) => sum + (booking.hobb_record_flight_time_credit || 0),
      0
    );
    const debit_total = bookings.reduce(
      (sum, booking) => sum + (booking.hobb_record_flight_time_debit || 0),
      0
    );

    return (
      <tr>
        {adjustment ? (
          <th colSpan={8} className="text-end">{`Total Adjustments: ${adjustment}`}</th>
        ) : (
          <th colSpan={8} />
        )}
        <th className="text-end">{`Total Credit: ${credit_total}`}</th>
        <th className="text-end">{`Total Debit: ${debit_total}`}</th>
      </tr>
    );
  }

  _renderClosingBalanceRow(balance) {
    balance = parseFloat(balance);
    const type = balance === 0 ? '' : balance > 0 ? 'Credit ' : 'Debit ';
    return (
      <tr>
        <th className="text-end" colSpan={10}>{`Closing ${type}Balance ${balance}`}</th>
      </tr>
    );
  }

  _renderProviders(bookings) {
    return bookings
      .filter((data) => {
        if (
          this.state.filterProviderId &&
          data.provider_id !== this.state.filterProviderId
        ) {
          return false;
        }
        return true;
      })
      .map((data, index) => {
        const { id, provider_full_name } = data;
        return (
          <Row key={id} xs={1} className="mb-4">
            <Col>
              <h4 className="ms-2">{provider_full_name}</h4>
            </Col>
            <Col>
              <Table striped size="sm">
                <thead>
                  {this._renderOpeningBalanceRow(data.start_balance)}
                  {this._renderHeaderRow()}
                </thead>
                {this._renderRows(data.bookings)}
                <tfoot>
                  {this._renderFooterRow(data.bookings, data.adjustment)}
                  {this._renderClosingBalanceRow(data.end_balance)}
                </tfoot>
              </Table>
            </Col>
          </Row>
        );
      });
  }

  _renderSwapGroups() {
    const swap_group_ids = uniq(
      this.props.bookingSwapsForMonthByProviderQuery.data
        .filter((data) => data.bookings_length > 0 || data.adjustment)
        .map((b) => b.swap_group_id)
    );
    return swap_group_ids.map((swap_group_id) => {
      const bookings = this.props.bookingSwapsForMonthByProviderQuery.data
        .filter((data) => data.bookings_length > 0 || data.adjustment)
        .filter((b) => b.swap_group_id === swap_group_id);
      const { swap_group_name } = first(bookings);
      return (
        <Fragment key={swap_group_id}>
          <Row>
            <Col>
              <h4>{`Swap Group - ${swap_group_name}`}</h4>
              <hr className="mt-0" />
            </Col>
          </Row>
          {this._renderProviders(bookings)}
        </Fragment>
      );
    });
  }

  _renderProviderFilter() {
    const dataProviders = this.props.providerListQuery.data.filter(
      (model) => this.state.filterProviderIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterProviderId',
          value: this.state.filterProviderId,
          onChange: this._handleDisplayProviderIdChange,
        }}
        asElement="select"
        selectOptions={dataProviders}
        optionKey="fullName"
        defaultSelectOptionName="All"
      />
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <>
        <Row className="my-3">
          <Col className="d-flex justify-content-between align-items-start">
            <ReportHeader
              title="Provider Swap Detail Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
            <Button
              variant="primary"
              data-report-name="booking_swaps_for_month_by_provider"
              onClick={this.getExport}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
            locked
          />
          {this._renderProviderFilter()}
        </Row>
        {this._renderSwapGroups()}
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportProviderId: state.currentSettings.reportProviderId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationFailure,
    mutationSet,
  }),
  graphql(contactListQuery, {
    name: 'providerListQuery',
    options: { variables: { role: 'provider' } },
  }),
  graphql(bookingSwapsForMonthByProviderQuery, {
    name: 'bookingSwapsForMonthByProviderQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportProviderSwapDetail);
