import { Row, Col, Table } from 'react-bootstrap';
import { FieldArray } from 'redux-form';

import pick from 'lodash.pick';

import WeightBalanceHoldAssignmentFieldArray from './weight_balance_hold_assignment_field_array';

const WeightBalanceHoldAssignments = (props) => {
  const {
    field,
    formValues,
    flightSegmentCalculatedData: { bookingWeightUnit },
    isDeveloper,
  } = props;

  const {
    luggage_weight: luggageWeight,
    hold_assignments_attributes: holdAssignmentsAttributes,
  } = formValues;

  const renderHoldAssignmentsFieldArray = (holdAssignments, optional) => {
    const formKeys = ['hold_assignments_attributes'];
    return (
      <WeightBalanceHoldAssignmentFieldArray
        holdAssignments={holdAssignments}
        formValues={pick(formValues, formKeys)}
        isDeveloper={isDeveloper}
        optional={optional}
      />
    );
  };

  const renderOptionalHoldAssignmentsFieldArray = (holdAssignments) =>
    renderHoldAssignmentsFieldArray(holdAssignments, true);

  const renderIncludedHoldAssignmentsFieldArray = (holdAssignments) =>
    renderHoldAssignmentsFieldArray(holdAssignments, false);

  const hasOptional = () =>
    holdAssignmentsAttributes
      .filter((ha) => !ha._destroy)
      .some((ha) => ha.hold_assignment_installed_weight_increase > 0);

  const hasIncluded = () =>
    holdAssignmentsAttributes
      .filter((ha) => !ha._destroy)
      .some((ha) => ha.hold_assignment_installed_weight_increase === 0);

  return (
    <Row className="mt-3">
      <Col xs={10}>
        <Row>
          <Col xs={4}>
            <p>
              <strong>{`Admin Entered Luggage Weight (${bookingWeightUnit})`}</strong>
            </p>
          </Col>
          <Col xs={2}>{luggageWeight}</Col>
        </Row>
        {hasIncluded() && (
          <>
            <h4>Included</h4>

            <Table striped size="sm">
              <thead>
                <tr>
                  <th style={{ width: '200px' }}>Name</th>
                  {isDeveloper && <th>Weight Unit</th>}
                  <th>Weight</th>
                  <th>Maximum Weight</th>
                  <th>Luggage Weight</th>
                  <th>Hold Installed</th>
                </tr>
              </thead>
              <FieldArray
                name={`${field}.hold_assignments_attributes`}
                component={renderIncludedHoldAssignmentsFieldArray}
              />
            </Table>
          </>
        )}
        {hasOptional() && (
          <>
            <h4>Optional</h4>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th style={{ width: '200px' }}>Name</th>
                  {isDeveloper && <th>Weight Unit</th>}
                  <th>Weight</th>
                  <th>Maximum Weight</th>
                  <th>Luggage Weight</th>
                  <th>Hold Installed</th>
                </tr>
              </thead>
              <FieldArray
                name={`${field}.hold_assignments_attributes`}
                component={renderOptionalHoldAssignmentsFieldArray}
              />
            </Table>
          </>
        )}
      </Col>
    </Row>
  );
};

export default WeightBalanceHoldAssignments;
