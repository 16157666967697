import { useCallback, useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import get from 'lodash.get';

import { currentSettingsSet } from '../actions/current_setting_actions';
import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import ReactTable from '../components/react_table';
import { toastSuccess, toastError } from '../lib/action_helpers';
import { getCheckStatus, getCheckStatusClassName } from '../lib/utils';

import checkDeleteMutation from '../mutations/check_delete_mutation';
import pageCheckListQuery from '../queries/page_check_list_query';

const CheckList = () => {
  const dispatch = useDispatch();
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const [checks, setChecks] = useState([]);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const currentContact = useSelector((state) => state.currentContact);
  const [checkDelete] = useMutation(checkDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageCheckListQuery, { notifyOnNetworkStatusChange: true });

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
      enableColumnFilter: false,
    },
    {
      header: 'Category',
      id: 'checkCategory',
      accessorFn: (row) => get(row, 'checkCategory.name', 'Unset Check Category'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Type',
      id: 'checkType',
      accessorFn: (row) => get(row, 'checkType.name', 'Unset Check Type'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Checkable',
      id: 'checkable',
      accessorFn: (row) => get(row, 'checkable.fullName', 'Unset Checkable'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Responsible',
      id: 'manager',
      accessorFn: (row) => get(row, 'manager.fullName', 'Unset Manager'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Crit',
      id: 'critical',
      accessorFn: (row) => (get(row, 'checkType.critical', false) ? 'Yes' : 'No'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Start On',
      id: 'check_start_on',
      accessorFn: (row) =>
        row.check_start_on ? moment(row.check_start_on).format('DD/MM/YYYY') : '-',
      enableColumnFilter: false,
    },
    {
      header: 'End On',
      id: 'check_end_on',
      accessorFn: (row) =>
        row.check_end_on ? moment(row.check_end_on).format('DD/MM/YYYY') : '-',
      enableColumnFilter: false,
    },
    {
      header: 'Status',
      id: 'checkStatus',
      accessorFn: (row) => getCheckStatus(row),
      filterFn: 'equalsString',
      filterType: 'dropdown',
      getCellClassName: getCheckStatusClassName,
    },
  ];

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  useEffect(() => {
    setChecks(
      get(pageData, 'checkList', []).filter((check) => {
        const {
          checkable_type: checkableType,
          checkable_id: checkableId,
          checkable,
        } = check;
        const { activeActivation } = checkable;
        if (checkableType === 'Contact' && !activeActivation) {
          return false;
        }
        if (!currentContact['manager?'] && currentContact.id !== checkableId) {
          return false;
        }
        return true;
      })
    );
  }, [pageData, currentContact]);

  const handleRefetch = () => pageRefetch();

  const handleDelete = useCallback(
    async (e) => {
      const checkId = e.currentTarget.getAttribute('data-id');
      const mutationData = {
        variables: { id: checkId },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await checkDelete(mutationData);
        toastSuccess('Check delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Check delete failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [checkDelete, dispatch]
  );

  const getTableTools = () => {
    if (currentContact['manager?']) {
      return {
        handleDelete,
      };
    }
    return { doEdit: false };
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title list>Checks</Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                size="sm"
                variant="primary"
                onClick={handleRefetch}
                disabled={pageLoading}
              >
                Refresh
              </Button>
            </Col>
            <Col sm="auto">
              <LinkContainer to="/checks/new">
                <Button size="sm" variant="primary">
                  Add a Check
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      {checks.length ? (
        <ReactTable
          rootName="check"
          parentColumns={parentColumns}
          data={checks}
          {...getTableTools()}
        />
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>No checks</Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default CheckList;
