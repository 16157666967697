// this should match or be a subset of the input typing for the model
export const aircraftConfigurationWhiteList = [
  'id',
  'name',
  'description',
  'aircraft_type_id',
  'wb_lateral_limit_id',
  'wb_longitudinal_limit_id',
  'wb_external_lateral_limit_id',
  'wb_external_longitudinal_limit_id',
  'seat_configuration_id',
  'hold_configuration_id',
  'tank_configuration_id',
  'external_load_point_id',
];
