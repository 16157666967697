const FlightPlanTableHeader = (props) => {
  const { bookingCalculatedData, isDeveloper } = props;
  const { bookingAirspeedUnit, bookingFuelUnit, bookingWeightUnit } =
    bookingCalculatedData;

  return (
    <thead>
      <tr>
        <th className="text-start">Leg</th>
        <th>
          S.L. Dist
          <br />
          (nm)
        </th>
        <th style={{ width: '88px' }}>
          Cust Dist
          <br />
          (nm)
        </th>
        <th style={{ width: '88px' }}>
          HWC
          <br />
          {`(+/- ${bookingAirspeedUnit})`}
        </th>
        <th>
          Leg
          <br />
          ETE/Fuel
          <br />
          {`(min/${bookingFuelUnit})`}
        </th>
        {/* {isDeveloper && (
          <th>
            *dev*
            <br />
            Min Dispatch
            <br />
            Fuel
            <br />
            {`(${bookingFuelUnit})`}
          </th>
        )} */}
        {isDeveloper && (
          <th>
            *dev*
            <br />
            Calc Remaining
            <br />
            Required Fuel
            <br />
            {`(${bookingFuelUnit})`}
          </th>
        )}
        <th style={{ width: '88px' }}>
          Calc
          <br />
          Toff Fuel
          <br />
          {`(${bookingFuelUnit})`}
        </th>
        <th style={{ width: '88px' }}>
          Calc
          <br />
          Ldg Fuel
          <br />
          {`(${bookingFuelUnit})`}
        </th>
        {/* {isDeveloper && (
          <th>
            *dev*
            <br />
            Pilot Fuel
            <br />
            Weight
            <br />
            {`(${bookingWeightUnit})`}
          </th>
        )} */}
        {isDeveloper ? (
          <th>
            *dev*
            <br />
            Refuel
          </th>
        ) : (
          <th>Refuel</th>
        )}
        <th style={{ width: '88px' }}>
          Dispatch
          <br />
          Toff Fuel
          <br />
          {`(${bookingFuelUnit})`}
        </th>
        <th>
          Dispatch
          <br />
          Ldg Fuel
          <br />
          {`(${bookingFuelUnit})`}
        </th>
        {/* {isDeveloper && (
          <th>
            *dev*
            <br />
            Pilot Fuel
            <br />
            Weight
            <br />
            {`(${bookingWeightUnit})`}
          </th>
        )} */}
        {/* <th>
          Cabin
          <br />
          Weight
          <br />
          {`(${bookingWeightUnit})`}
        </th> */}
        <th>
          R-Payload
          <br />
          {`(${bookingWeightUnit})`}
        </th>
        {isDeveloper && (
          <th>
            *dev*
            <br />
            Man
            <br />
            Adj
          </th>
        )}
        <th>Bal</th>
      </tr>
    </thead>
  );
};

export default FlightPlanTableHeader;
