import { useState, useEffect } from 'react';

import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';

const WeightBalanceExternalLoadAssignmentFields = (props) => {
  const { formValues, parentField, isDeveloper } = props;

  const {
    name,
    external_load_assignment_weight_unit: externalLoadAssignmentWeightUnit,
    external_load_assignment_weight: externalLoadAssignmentWeight,
  } = formValues;

  const {
    external_load_assignment_weight: { input: externalLoadAssignmentWeightInput },
  } = get(props, parentField);

  const [weight, setWeight] = useState('');

  useEffect(() => {
    setWeight(externalLoadAssignmentWeight || 0);
  }, [externalLoadAssignmentWeight]);

  const handleWeightChange = (e) => {
    const newWeight = defaultTo(parseInt(e.target.value, 10), '');
    setWeight(newWeight);
  };

  const handleWeightBlur = (e) => {
    const newWeight = defaultTo(parseInt(e.target.value, 10), 0);
    externalLoadAssignmentWeightInput.onChange(newWeight);
  };

  return (
    <tr>
      <td>{name}</td>
      {isDeveloper && <td>{externalLoadAssignmentWeightUnit}</td>}
      <td>
        <input
          type="text"
          style={{ width: '88px' }}
          value={weight}
          onChange={handleWeightChange}
          onBlur={handleWeightBlur}
        />
      </td>
    </tr>
  );
};

export default WeightBalanceExternalLoadAssignmentFields;
