import moment from 'moment';
import mirrorCreator from 'mirror-creator';
import actionTypes from '../constants';

import { reset, loadSuccess, loadFailure, rehydrate } from '../lib/reducer_helpers';

const dataKey = 'currentSettings';

moment.updateLocale('en-nz');

const unhydratedInitialState = {
  authenticated: false,
  authenticating: false,
  versionCurrent: true,
  mutating: false,
  enteredBookingViewIndexes: [0, 1],
  weightBalancesTabKey: 0,
  weightBalanceTabKey: 0,
  trackedMutationCount: 0,
  online: false,
  deviceOnline: false,
  serverOnline: false,
  tenant: '',
  tenantLongName: '',
  tenantShortName: '',
};

export const initialState = {
  ...unhydratedInitialState,
  //
  bootswatchStyle: 'simplex',
  // versionCurrent: true,
  bookingRequestedCalendarType: 'flight',
  bookingCollectionView: 'resourceTimelineDay',
  bookingCollectionStartDate: moment().startOf('day').format(),
  bookingCollectionEndDate: moment().endOf('day').format(),
  // mutating: false,
  lastMutation: { type: '', id: '', timestamp: '' },
  lastMutationProcessed: moment().toISOString(),
  cacheUpdatedAt: '',
  statutory_roles: [],
  booking_statuses: [], // array from data controller not used
  bookingStatuses: mirrorCreator([
    // align with rails
    'tentative',
    'to_be_confirmed',
    'confirmed',
    'cancelled',
  ]),
  address_types: [],
  pilot_flight_log_flight_types: [],
  phone_number_types: [],
  location_display_formats: [],
  location_default_display_format_id: 1,
  engine_event_types: [],
  returnRoute: '/checks',
  salutations: [],
  check_category_checkable_groups: {},
  booking_calendar_types: [], // array from rails data_controller not used
  // CALENDAR_TYPES = ["flight", "meeting", "maintenance", "leave", "rostered", "banner"]
  bookingCalendarTypes: mirrorCreator([
    // align with rails
    'banner',
    'flight',
    'meeting',
    'maintenance',
    'leave',
    'rostered',
  ]),
  booking_calendar_type_banner: 'banner', // from rails data_controller not used
  booking_calendar_type_flight: 'flight', // from rails data_controller not used
  // booking_calendar_type_meeting: 'meeting',
  // booking_calendar_type_maintenance: 'maintenance',
  pilot_flight_expense_overnight_text: '',
  pilot_flight_expense_overnight_rate: '',
  fuel_bowser_fill_default_quantity_unit: '',
  wb_limit_limit_types: [],
  wb_limit_limit_type_lateral: '',
  wb_limit_limit_type_longitudinal: '',
  aircraft_seat_seat_types: [],
  aircraft_type_arm_units: [],
  aircraft_type_weight_units: [],
  aircraft_type_fuel_units: [],
  aircraft_type_airspeed_units: [],
  aircraft_type_height_units: [],
  reportStartDate: moment().startOf('month').toISOString(),
  reportAircraftId: '',
  reportFuelTankerId: '',
  reportLocationId: '',
  reportPilotId: '',
  reportChargeableId: '',
  reportProviderId: '',
  reportStart: moment().startOf('month').toISOString(),
  reportEnd: moment().endOf('month').toISOString(),
  containerWidth: 0,
  containerHeight: 0,
  // weightBalancesTabKey: 0,
  // weightBalanceTabKey: 0,
  listColumnFilters: {
    aircraft: [{ id: 'activeActivationState', value: 'active' }],
    aircraftConfiguration: [],
    check: [],
    contact: [],
    document: [],
    fuelTanker: [],
    holdConfiguration: [],
    location: [],
    seatConfiguration: [],
    tankConfiguration: [],
    wbLimit: [],
  },
  listPagination: {
    aircraft: { pageIndex: 0, pageSize: 50 },
    aircraftConfiguration: { pageIndex: 0, pageSize: 25 },
    check: { pageIndex: 0, pageSize: 25 },
    contact: { pageIndex: 0, pageSize: 25 },
    document: { pageIndex: 0, pageSize: 25 },
    fuelTanker: { pageIndex: 0, pageSize: 25 },
    holdConfiguration: { pageIndex: 0, pageSize: 25 },
    location: { pageIndex: 0, pageSize: 25 },
    seatConfiguration: { pageIndex: 0, pageSize: 25 },
    tankConfiguration: { pageIndex: 0, pageSize: 25 },
    wbLimit: { pageIndex: 0, pageSize: 25 },
    pilotDutyRecord: { pageIndex: 0, pageSize: 50 },
  },
  dutyEventCollectionStartDate: moment().startOf('month').subtract(1, 'month').format(),
  dutyEventCollectionEndDate: moment().startOf('month').add(2, 'month').format(),
};

export default function currentSettingsReducer(state = initialState, action) {
  const { type, payload, error } = action;
  if (error) {
    return state;
  }
  switch (type) {
    case 'persist/REHYDRATE':
      return rehydrate(
        initialState,
        (payload || {})[dataKey] || {},
        unhydratedInitialState
      );
    case actionTypes.DATA_RESET:
      return reset({
        ...initialState,
        ...{
          containerWidth: state.containerWidth,
          containerHeight: state.containerHeight,
          online: state.online,
          deviceOnline: state.deviceOnline,
          serverOnline: state.serverOnline,
          tenant: state.tenant,
          tenantLongName: state.tenantLongName,
          tenantShortName: state.tenantShortName,
        },
      });
    case actionTypes.DATA_SET:
      return loadSuccess(
        {
          ...initialState,
          ...{
            containerWidth: state.containerWidth,
            containerHeight: state.containerHeight,
            online: state.online,
            deviceOnline: state.deviceOnline,
            serverOnline: state.serverOnline,
            tenant: state.tenant,
            tenantLongName: state.tenantLongName,
            tenantShortName: state.tenantShortName,
          },
        },
        payload[dataKey],
        false
      );
    case actionTypes.CURRENT_SETTINGS_SET:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
