import { Fields } from 'redux-form';

import get from 'lodash.get';
import last from 'lodash.last';

import WeightBalanceTankAssignmentFields from './weight_balance_tank_assignment_fields';

const WeightBalanceTankAssignmentFieldArray = (props) => {
  const { formValues, tankAssignments } = props;
  return (
    <tbody>
      {tankAssignments.fields.map((field) => {
        const tankAssignmentField = last(field.split('.'));
        const values = get(formValues, tankAssignmentField, {});
        if (!values._destroy) {
          const fields = [`${field}.tank_assignment_fuel`];
          return (
            <Fields
              key={field}
              parentField={field}
              names={fields}
              component={WeightBalanceTankAssignmentFields}
              formValues={values}
            />
          );
        }
        return undefined;
      })}
    </tbody>
  );
};

export default WeightBalanceTankAssignmentFieldArray;
