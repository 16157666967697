import { Component } from 'react';

import first from 'lodash.first';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';

import InputField from '../form/input_field';
import { getSelectable } from '../../lib/utils';

class ProviderInputField extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentBookingChargeables = get(
      this.props,
      'formValues.booking_chargeables_attributes'
    );
    const nextBookingChargeables = get(
      nextProps,
      'formValues.booking_chargeables_attributes'
    );
    if (!isEqual(currentBookingChargeables, nextBookingChargeables)) {
      let firstCurrentChargeableId = '';
      if (currentBookingChargeables && currentBookingChargeables.length > 0) {
        firstCurrentChargeableId = get(
          first(currentBookingChargeables.filter((bca) => !bca._destroy)),
          'chargeable_id',
          ''
        );
      }
      const firstNextChargeableId = get(
        first(nextBookingChargeables.filter((bca) => !bca._destroy)),
        'chargeable_id',
        ''
      );
      if (firstCurrentChargeableId !== firstNextChargeableId) {
        if (firstNextChargeableId) {
          const chargeable = this.props.chargeables.find(
            (c) => c.id === firstNextChargeableId
          );
          const defaultProviderId = get(chargeable, 'default_provider_id', '');
          this.props.input.onChange(defaultProviderId);
        } else {
          this.props.input.onChange('');
        }
      }
    }
  }

  getSelectableProviders() {
    return getSelectable(
      get(this.props, 'providers', []),
      get(this.props, 'formValues.provider_id'),
      'fullName',
      'roleProvider'
    );
  }

  render() {
    const { input, meta } = this.props;
    return (
      <InputField
        type="text"
        size="sm"
        labelWidth={4}
        inputWidth={8}
        asElement="select"
        selectOptions={this.getSelectableProviders()}
        input={input}
        meta={meta}
      >
        {this.props.children}
      </InputField>
    );
  }
}

export default ProviderInputField;
