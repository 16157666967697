import { Row } from 'react-bootstrap';
import LabelBlock from '../form/label_block';
import InputField from '../form/input_field';
import HelpBlock from '../form/help_block';

export default function SeatAssignmentPilot({
  pilot,
  copilot,
  vacant,
  paxName,
  seatName,
}) {
  return (
    <InputField
      size="sm"
      labelWidth={0}
      inputWidth={12}
      input={{
        name: seatName,
      }}
      groupClassName="p-0"
      innerContent={
        <Row>
          <LabelBlock size="sm">{seatName}</LabelBlock>
          {pilot && <HelpBlock helpText={vacant ? 'Select Pilot' : paxName} />}
          {copilot && <HelpBlock helpText={vacant ? 'Select Copilot' : paxName} />}
        </Row>
      }
    />
  );
}
