import { useState, useCallback, useMemo, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { Table, Col, Row, Button } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import groupBy from 'lodash.groupby';
import uniq from 'lodash.uniq';

import { currentSettingsSet } from '../actions/current_setting_actions';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import InputField from '../components/form/input_field';

import { renderOverlay, renderError } from '../components/render_helpers';

import pageReportDutyEventSummaryQuery from '../queries/page_report_duty_event_summary_query';

const ReportDutyEventSummary = () => {
  const dispatch = useDispatch();
  const printRef = useRef();
  const [filterContactId, setFilterContactId] = useState('');
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const currentSettingsReportStart = useSelector(
    ({ currentSettings }) => currentSettings.reportStart
  );
  const currentSettingsReportEnd = useSelector(
    ({ currentSettings }) => currentSettings.reportEnd
  );
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageReportDutyEventSummaryQuery, {
    variables: {
      startAt: currentSettingsReportStart,
      endAt: currentSettingsReportEnd,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () =>
      !pageLoading ||
      (pageLoading &&
        [NetworkStatus.refetch, NetworkStatus.setVariables].includes(pageNetworkStatus)),
    [pageLoading, pageNetworkStatus]
  );

  const dutyEvents = useMemo(() => {
    if (pageData?.dutyEventList) {
      return pageData.dutyEventList.filter((de) => de.published);
    }
    return [];
  }, [pageData]);

  const contacts = useMemo(() => {
    if (pageData?.contactList && dutyEvents.length > 0) {
      const contactIds = uniq(dutyEvents.map((p) => p.contact_id));
      return contactIds.map((contactId) => {
        const contact = pageData.contactList.find((c) => c.id === contactId);
        return { id: contact.id, fullName: contact.fullName };
      });
    }
    return [];
  }, [pageData, dutyEvents]);

  const publicHolidays = useMemo(() => {
    if (pageData?.publicHolidayList) {
      const filteredPublicHolidays = pageData.publicHolidayList.filter((ph) => {
        const { date } = ph;
        return moment(date).isBetween(
          currentSettingsReportStart,
          currentSettingsReportEnd,
          'day',
          '[]'
        );
      });
      return filteredPublicHolidays;
    }
    return [];
  }, [pageData, currentSettingsReportStart, currentSettingsReportEnd]);

  const dispatchReportVars = useCallback(
    ({
      reportStart = currentSettingsReportStart,
      reportEnd = currentSettingsReportEnd,
    }) => {
      dispatch(
        currentSettingsSet({
          reportStart,
          reportEnd,
        })
      );
    },
    [currentSettingsReportStart, currentSettingsReportEnd, dispatch]
  );

  const handleFilterContactIdChange = useCallback((e) => {
    const contactId = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    setFilterContactId(contactId);
  }, []);

  const renderDutyEvents = (contactDutyEvents) => {
    const contactFullName = contactDutyEvents[0].contact.fullName;
    const logContact = false;
    // const logContact = 'Tom Aitken';
    if (logContact && contactFullName === logContact) {
      console.log('-------------------');
      console.log(`Logging ${logContact} duty events`);
      console.log('-------------------');
    }
    const groupedDutyEvents = groupBy(contactDutyEvents, (de) => de.name);
    const momentCurrentSettingsReportStart = moment(currentSettingsReportStart).utc();
    // report end is end of day, events are start of following day.  Normalise
    const momentCurrentSettingsReportEnd = moment(currentSettingsReportEnd)
      .add(1, 'second')
      .utc();
    let sum = 0;
    return (
      <Table striped>
        <thead>
          <tr>
            <th className="border-top-0">Duty Event Name</th>
            <th style={{ width: '50px' }} className="text-end border-top-0">
              Count
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedDutyEvents).map((dutyEventName) => {
            if (logContact && contactFullName === logContact) {
              console.log(dutyEventName);
            }
            const count = groupedDutyEvents[dutyEventName].reduce((accum, de) => {
              const { start_at: startAt, end_at: endAt } = de;
              //
              // TODO hack see ticket https://trello.com/c/f0NTrfJ9
              // many dates are stored without proper timezones
              //
              const newStartAt = moment(startAt).startOf('day').utc();
              const newEndAt = moment(endAt).startOf('day').utc();
              if (logContact && contactFullName === logContact) {
                console.log('-------');
                console.log({
                  momentCurrentSettingsReportStart:
                    momentCurrentSettingsReportStart.format(),
                });
                console.log({
                  momentCurrentSettingsReportEnd: momentCurrentSettingsReportEnd.format(),
                });
                console.log({ startAt });
                console.log({
                  newStartAt: newStartAt.format(),
                });
                console.log({ endAt });
                console.log({
                  newEndAt: newEndAt.format(),
                });
              }
              const maxStartAt = moment.max(newStartAt, momentCurrentSettingsReportStart);
              const minEndAt = moment.min(newEndAt, momentCurrentSettingsReportEnd);
              const duration = minEndAt.diff(maxStartAt, 'days', true);
              if (logContact && contactFullName === logContact) {
                console.log({ maxStartAt: maxStartAt.format() });
                console.log({ minEndAt: minEndAt.format() });
                console.log({ duration });
                console.log({ durationRounded: Math.round(duration) });
                console.log('--');
              }
              // round the diff to handle small DST problems
              return accum + Math.round(duration);
            }, 0);
            sum += count;
            return (
              <tr key={dutyEventName}>
                <td>{dutyEventName}</td>
                <td className="text-end">{count}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th className="text-end">Total</th>
            <th className="text-end">{sum}</th>
          </tr>
        </tfoot>
      </Table>
    );
  };

  const renderManagerNotes = (contactDutyEvents) => {
    const sortedDutyEvents = contactDutyEvents
      .filter((de) => de.manager_notes)
      .sort((a, b) => a.start_at.localeCompare(b.start_at));
    if (sortedDutyEvents.length > 0) {
      return (
        <Table striped>
          <thead>
            <tr>
              <th style={{ width: '100px' }} className="border-top-0">
                Date
              </th>
              <th className="border-top-0">Manager Note</th>
            </tr>
          </thead>
          <tbody>
            {sortedDutyEvents.map((dutyEvent) => {
              const { id, start_at: startAt, manager_notes: managerNotes } = dutyEvent;
              if (managerNotes) {
                return (
                  <tr key={id}>
                    <td>{moment(startAt).format('ll')}</td>
                    <td>{managerNotes}</td>
                  </tr>
                );
              }
              return undefined;
            })}
          </tbody>
        </Table>
      );
    }
    return undefined;
  };

  const renderPublicHolidays = (contactDutyEvents) => {
    const contactFullName = contactDutyEvents[0].contact.fullName;
    const logContact = false;
    // const logContact = 'Brooke Crampton';
    if (logContact && contactFullName === logContact) {
      console.log('-------------------');
      console.log(`Logging ${logContact} public holidays`);
      console.log('-------------------');
    }
    // if (logContact && contactFullName === logContact) {
    //   console.log(contactDutyEvents);
    // }
    if (publicHolidays.length > 0) {
      return (
        <Table striped>
          <thead>
            <tr>
              <th className="border-top-0">Public Holiday</th>
              <th className="border-top-0">Rostered Duty</th>
            </tr>
          </thead>
          <tbody>
            {publicHolidays.map((publicHoliday) => {
              const { id, date, name } = publicHoliday;
              const holidayDutyEvent = contactDutyEvents.find((de) => {
                const { start_at: startAt, end_at: endAt } = de;
                return moment(date).isBetween(
                  moment(startAt).startOf('day').utc(),
                  moment(endAt).startOf('day').subtract(1, 'second').utc(),
                  'day',
                  '[]'
                );
              });
              if (holidayDutyEvent && logContact && contactFullName === logContact) {
                console.log(holidayDutyEvent.start_at);
                console.log(holidayDutyEvent.end_at);
                // console.log(moment(holidayDutyEvent.start_at).startOf('day').format());
                // console.log(moment(holidayDutyEvent.end_at).startOf('day').format());
                // console.log(holidayDutyEvent);
              }
              return (
                <tr key={id}>
                  <td>{`${date} - ${name}`}</td>
                  <td>{holidayDutyEvent ? holidayDutyEvent.name : '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    }
    return undefined;
  };

  const renderContacts = () =>
    contacts
      .filter((contact) => {
        if (filterContactId && contact.id !== filterContactId) {
          return false;
        }
        return true;
      })
      .map(({ id: contactId, fullName }) => {
        const contactDutyEvents = dutyEvents.filter((de) => de.contact_id === contactId);
        return (
          <Row key={contactId} xs={1}>
            <Col>
              <h4>{fullName}</h4>
            </Col>
            <Row>
              <Col md={6}>{renderDutyEvents(contactDutyEvents)}</Col>
              <Col md={6}>
                {renderManagerNotes(contactDutyEvents)}
                {renderPublicHolidays(contactDutyEvents)}
              </Col>
            </Row>
          </Row>
        );
      });

  const renderContent = () => (
    <>
      <Row className="my-3">
        <Col>
          <ReportHeader
            title="Duty Event Summary Report"
            start={currentSettingsReportStart}
            end={currentSettingsReportEnd}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="auto" className="px-0">
          <Button variant="link" onClick={() => pageRefetch()} className="p-0">
            <Glyphicon glyph="repeat" />
          </Button>
          <Button variant="link" onClick={handlePrint} className="p-0">
            <Glyphicon glyph="print" />
          </Button>
        </Col>
        <ReactDateTimeFilter
          size="sm"
          labelWidth={0}
          inputWidth={0}
          currentSettingsReportStart={currentSettingsReportStart}
          currentSettingsReportEnd={currentSettingsReportEnd}
          onChange={dispatchReportVars}
          closeOnSelect
        />
        <InputField
          size="sm"
          labelWidth={0}
          inputWidth={0}
          input={{
            name: 'filterContactId',
            value: filterContactId,
            onChange: handleFilterContactIdChange,
          }}
          asElement="select"
          selectOptions={contacts}
          optionKey="fullName"
          defaultSelectOptionName="All"
        />
      </Row>
      {renderContacts()}
    </>
  );

  return (
    <div ref={printRef}>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ReportDutyEventSummary;
