import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import DateTime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';

import get from 'lodash.get';

import { isInvalid } from '../form/helpers';
import InputField from '../form/input_field';
import LabelBlock from '../form/label_block';
import InvalidBlock from '../form/invalid_block';

moment.updateLocale('en-nz');

class BookingPilotDutyRecordFields extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleDutyStartAtTimeChange = this.handleDutyStartAtTimeChange.bind(this);
    this.handleDutyEndAtTimeChange = this.handleDutyEndAtTimeChange.bind(this);
    this.handleRestStartAtTimeChange = this.handleRestStartAtTimeChange.bind(this);
    this.handleRestEndAtTimeChange = this.handleRestEndAtTimeChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setTimestamps();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setTimestamps(nextProps);
  }

  handleDutyStartAtTimeChange(date) {
    if (moment.isMoment(date)) {
      const newDate = moment(
        get(this.props, `formValues.${this.props.parentField}.duty_start_at`)
      )
        .hours(date.format('HH'))
        .minutes(date.format('mm'));
      const input = get(this.props, `${this.props.parentField}.duty_start_at.input`);
      input.onChange(newDate.format());
    } else {
      this.setState({
        dutyStartAtTime: date,
        dutyStartAtTimeError: { touched: true, invalid: true, error: 'HHmm' },
      });
    }
  }

  handleDutyEndAtTimeChange(date) {
    if (moment.isMoment(date)) {
      const newDate = moment(
        get(this.props, `formValues.${this.props.parentField}.duty_end_at`)
      )
        .hours(date.format('HH'))
        .minutes(date.format('mm'));
      const input = get(this.props, `${this.props.parentField}.duty_end_at.input`);
      input.onChange(newDate.format());
    } else {
      this.setState({
        dutyEndAtTime: date,
        dutyEndAtTimeError: { touched: true, invalid: true, error: 'HHmm' },
      });
    }
  }

  handleRestStartAtTimeChange(date) {
    if (moment.isMoment(date)) {
      let newDate;
      if (get(this.props, `formValues.${this.props.parentField}.rest_start_at`)) {
        newDate = moment(
          get(this.props, `formValues.${this.props.parentField}.rest_start_at`)
        )
          .hours(date.format('HH'))
          .minutes(date.format('mm'));
      } else {
        newDate = moment(
          get(this.props, `formValues.${this.props.parentField}.duty_start_at`)
        )
          .hours(date.format('HH'))
          .minutes(date.format('mm'));
      }
      const input = get(this.props, `${this.props.parentField}.rest_start_at.input`);
      input.onChange(newDate.format());
    } else {
      this.setState({
        restStartAtTime: date,
        restStartAtTimeError: { touched: true, invalid: true, error: 'HHmm' },
      });
    }
  }

  handleRestEndAtTimeChange(date) {
    if (moment.isMoment(date)) {
      let newDate;
      if (get(this.props, `formValues.${this.props.parentField}.rest_end_at`)) {
        newDate = moment(
          get(this.props, `formValues.${this.props.parentField}.rest_end_at`)
        )
          .hours(date.format('HH'))
          .minutes(date.format('mm'));
      } else {
        newDate = moment(
          get(this.props, `formValues.${this.props.parentField}.duty_end_at`)
        )
          .hours(date.format('HH'))
          .minutes(date.format('mm'));
      }
      const input = get(this.props, `${this.props.parentField}.rest_end_at.input`);
      input.onChange(newDate.format());
    } else {
      this.setState({
        restEndAtTime: date,
        restEndAtTimeError: { touched: true, invalid: true, error: 'HHmm' },
      });
    }
  }

  setTimestamps(props = this.props) {
    const timestamps = {
      dutyStartAtTime: '',
      dutyEndAtTime: '',
      restStartAtTime: '',
      restEndAtTime: '',
      dutyStartAtTimeError: {},
      dutyEndAtTimeError: {},
      restStartAtTimeError: {},
      restEndAtTimeError: {},
      total: '0',
    };
    const dutyStartAt = get(
      props,
      `formValues.${this.props.parentField}.duty_start_at`,
      ''
    );
    const dutyEndAt = get(props, `formValues.${this.props.parentField}.duty_end_at`, '');
    const restStartAt = get(
      props,
      `formValues.${this.props.parentField}.rest_start_at`,
      ''
    );
    const restEndAt = get(props, `formValues.${this.props.parentField}.rest_end_at`, '');
    if (dutyStartAt) {
      timestamps.dutyStartAtTime = moment(dutyStartAt).format('HHmm');
    }
    if (dutyEndAt) {
      timestamps.dutyEndAtTime = moment(dutyEndAt).format('HHmm');
    }
    if (restStartAt) {
      timestamps.restStartAtTime = moment(restStartAt).format('HHmm');
    }
    if (restEndAt) {
      timestamps.restEndAtTime = moment(restEndAt).format('HHmm');
    }
    timestamps.total = this.calcTotal(
      moment(dutyStartAt),
      moment(dutyEndAt),
      moment(restStartAt),
      moment(restEndAt)
    );
    this.setState(timestamps);
  }

  calcTotal = (dutyStartAt, dutyEndAt, restStartAt, restEndAt) => {
    let tot = '0';
    if (dutyStartAt.isValid() && dutyEndAt.isValid()) {
      let totD = dutyEndAt.diff(dutyStartAt);
      if (totD > 0) {
        if (restStartAt.isValid() && restEndAt.isValid()) {
          const totR = restEndAt.diff(restStartAt);
          if (totR > 0) {
            totD -= totR;
          }
        }
        const hours = `${moment.duration(totD).hours()}`;
        const mins = `${moment.duration(totD).minutes()}`;
        tot = `${'00'.substring(0, 2 - hours.length) + hours}:${
          '00'.substring(0, 2 - mins.length) + mins
        }`;
      }
    }
    return tot;
  };

  getPilotName() {
    return get(
      this.props.contactsDataSelector,
      [get(this.props, `formValues.${this.props.parentField}.pilot_id`), 'fullName'],
      'Unknown'
    );
  }

  renderAttributes() {
    const {
      duty_start_at: { meta: dutyStartAtMeta },
      duty_end_at: { meta: dutyEndAtMeta },
      rest_start_at: { meta: restStartAtMeta },
      rest_end_at: { meta: restEndAtMeta },
    } = get(this.props, this.props.parentField);

    const dutyStartAtMetas = {
      ...dutyStartAtMeta,
      ...this.state.dutyStartAtTimeError,
    };
    const dutyStartAtInvalid = isInvalid(dutyStartAtMetas);
    const dutyEndAtMetas = {
      ...dutyEndAtMeta,
      ...this.state.dutyEndAtTimeError,
    };
    const dutyEndAtInvalid = isInvalid(dutyEndAtMetas);

    const restStartAtMetas = {
      ...restStartAtMeta,
      ...this.state.restStartAtTimeError,
    };
    const restStartAtInvalid = isInvalid(restStartAtMetas);
    const restEndAtMetas = {
      ...restEndAtMeta,
      ...this.state.restEndAtTimeError,
    };
    const restEndAtInvalid = isInvalid(restEndAtMetas);

    return (
      <InputField
        size="sm"
        labelWidth={2}
        inputWidth={10}
        input={{}}
        innerContent={
          <Row>
            <Col sm={2}>
              <DateTime
                value={this.state.dutyStartAtTime}
                dateFormat={false}
                timeFormat="HHmm"
                tabIndex={-1}
                onChange={this.handleDutyStartAtTimeChange}
                inputProps={{
                  className: classNames('form-control', 'form-control-sm', {
                    'is-invalid': dutyStartAtInvalid,
                  }),
                }}
              />
              <InvalidBlock meta={dutyStartAtMetas} force />
            </Col>
            <Col sm={2}>
              <DateTime
                value={this.state.dutyEndAtTime}
                dateFormat={false}
                timeFormat="HHmm"
                tabIndex={-1}
                onChange={this.handleDutyEndAtTimeChange}
                inputProps={{
                  className: classNames('form-control', 'form-control-sm', {
                    'is-invalid': dutyEndAtInvalid,
                  }),
                }}
              />
              <InvalidBlock meta={dutyEndAtMetas} force />
            </Col>
            <Col sm={2}>
              <DateTime
                value={this.state.restStartAtTime}
                dateFormat={false}
                timeFormat="HHmm"
                tabIndex={-1}
                onChange={this.handleRestStartAtTimeChange}
                inputProps={{
                  className: classNames('form-control', 'form-control-sm', {
                    'is-invalid': restStartAtInvalid,
                  }),
                }}
              />
              <InvalidBlock meta={restStartAtMetas} force />
            </Col>
            <Col sm={2}>
              <DateTime
                value={this.state.restEndAtTime}
                dateFormat={false}
                timeFormat="HHmm"
                tabIndex={-1}
                onChange={this.handleRestEndAtTimeChange}
                inputProps={{
                  className: classNames('form-control', 'form-control-sm', {
                    'is-invalid': restEndAtInvalid,
                  }),
                }}
              />
              <InvalidBlock meta={restEndAtMetas} force />
            </Col>
            <Col sm={2}>
              <InputField
                controlOnly
                type="text"
                input={{
                  value: this.state.total,
                  name: 'booking_pilot_duty_ecord',
                }}
                size="sm"
                readOnly
                plaintext
              />
            </Col>
          </Row>
        }
      >
        {this.getPilotName()}
      </InputField>
    );
  }

  renderHeader = () => (
    <Row>
      <Col sm={2} className="text-end">
        &nbsp;
      </Col>
      <Col sm={10}>
        <Row>
          <Col sm={2}>
            <LabelBlock>Duty Start</LabelBlock>
          </Col>
          <Col sm={2}>
            <LabelBlock>Duty End</LabelBlock>
          </Col>
          <Col sm={2}>
            <LabelBlock>Rest Start</LabelBlock>
          </Col>
          <Col sm={2}>
            <LabelBlock>Rest End</LabelBlock>
          </Col>
          <Col sm={2}>
            <LabelBlock>Total</LabelBlock>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  renderDutyRecords() {
    return (
      <>
        {this.renderHeader()}
        {this.renderAttributes()}
      </>
    );
  }

  render() {
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>{this.props.title}</strong>
          </p>
        </Col>
        <Col sm={10}>{this.renderDutyRecords()}</Col>
      </Row>
    );
  }
}

export default BookingPilotDutyRecordFields;
