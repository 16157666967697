import { Row } from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';

import InputField from '../form/input_field';

import ReactDateTimeField from '../form/react_date_time_field';

moment.updateLocale('en-nz');

class StartDateAndTimeInputFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      time: '',
      dateError: {},
      timeError: {},
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.start_at) {
      this.receiveStartAt();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.start_at !== nextProps.start_at) {
      this.receiveStartAt(nextProps);
    }
  }

  handleDateChange(newDate) {
    const date = moment(newDate);
    if (date.isValid()) {
      const formattedDate = date.format('DD/MM/YYYY');
      this.setState({
        date: formattedDate,
        dateError: {},
      });
      const { time, timeError } = this.state;
      if (!timeError.invalid) {
        this.props.input.onChange(
          moment(`${formattedDate} ${time}`, 'DD/MM/YYYY HHmm').format()
        );
      }
    } else {
      this.setState({
        date,
        dateError: { touched: true, invalid: true, error: 'DD/MM/YYYY' },
      });
    }
  }

  handleTimeChange(e) {
    let time = e.target.value;
    time = time === '2400' ? '2359' : time;
    if (time.match(/^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/)) {
      this.setState({
        time,
        timeError: {},
      });
      const { date, dateError } = this.state;
      if (!dateError.invalid) {
        this.props.input.onChange(moment(`${date} ${time}`, 'DD/MM/YYYY HHmm').format());
      }
    } else {
      this.setState({
        time,
        timeError: { touched: true, invalid: true, error: 'HHMM' },
      });
    }
  }

  receiveStartAt(props = this.props) {
    this.setState({
      date: moment(props.start_at).format('DD/MM/YYYY'),
      time: moment(props.start_at).format('HHmm'),
    });
  }

  render() {
    const { input, size } = this.props;

    return (
      <Row>
        <ReactDateTimeField
          size={size}
          input={{
            ...this.props.input,
            onChange: this.handleDateChange,
          }}
          labelWidth={0}
          inputWidth={6}
          dateFormat="DD/MM/YYYY"
          timeFormat={false}
          closeOnSelect
        >
          {this.props.children}
        </ReactDateTimeField>
        {this.props.withoutTime || (
          <InputField
            size={size}
            labelWidth={0}
            inputWidth={6}
            input={{
              name: `${input.name}-time`,
              value: this.state.time,
              onChange: this.handleTimeChange,
            }}
            meta={{ ...this.state.timeError }}
          >
            Time
          </InputField>
        )}
      </Row>
    );
  }
}

StartDateAndTimeInputFields.defaultProps = {
  size: 'sm',
};

export default StartDateAndTimeInputFields;
