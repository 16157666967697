import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash.get';
import has from 'lodash.has';
import pick from 'lodash.pick';

import BookingEngineEventFields from './booking_engine_event_fields';

class EngineEvents extends Component {
  constructor(props) {
    super(props);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (
      has(this.props, 'formValues.engine_events_attributes') &&
      get(this.props, 'formValues.engine_events_attributes', []).length === 0
    ) {
      const engineEvents = [];
      const defaultEngineEvents = ['start', 'landing'];
      defaultEngineEvents.forEach((event) => {
        engineEvents.push({
          aircraft_id: this.props.formValues.aircraft_id,
          event_type: event,
          event_value: 0,
        });
      });
      this.props.change('engine_events_attributes', engineEvents);
    }
  }

  handleAddButtonClick() {
    this.props.engineEvents.fields.push({
      aircraft_id: this.props.formValues.aircraft_id,
      event_value: 0,
    });
  }

  handleDeleteButtonClick(index) {
    this.props.engineEvents.fields.remove(index);
  }

  renderEngineEventsList() {
    return (
      <>
        {this.props.engineEvents.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}._destroy`,
              `${field}.aircraft_id`,
              `${field}.event_value`,
              `${field}.event_type`,
            ];
            return (
              <Fields
                key={`booking-${field}`}
                index={index}
                parentField={field}
                names={fields}
                component={BookingEngineEventFields}
                formValues={pick(values, [
                  'id',
                  'event_value',
                  'event_type',
                  'aircraft_id',
                ])}
                handleDeleteButtonClick={this.handleDeleteButtonClick}
                bookingAircraftId={get(this.props, 'formValues.aircraft_id', '')}
                currentSettingsEngineEventTypes={
                  this.props.currentSettingsEngineEventTypes
                }
              />
            );
          }
          return undefined;
        })}
      </>
    );
  }

  render() {
    const eventsLength = get(
      this.props,
      'formValues.engine_events_attributes',
      []
    ).filter((ff) => !ff._destroy).length;
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Engine Events</strong>
          </p>
        </Col>
        <Col sm={10}>
          {eventsLength > 0 && this.renderEngineEventsList()}
          <Row>
            <Col sm={2} className="text-end">
              <Button
                type="button"
                variant="link"
                size="sm"
                onClick={this.handleAddButtonClick}
                tabIndex={-1}
                disabled={!this.props.formValues.aircraft_id}
              >
                add...
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default EngineEvents;
