import moment from 'moment';
import SunCalc from 'suncalc';

import compact from 'lodash.compact';
import flatten from 'lodash.flatten';

const getSunEvents = (startStr, endStr) => {
  const sunEvents = [];
  const start = moment(startStr);
  const end = moment(endStr);
  const startOfDay = start.clone();
  while (startOfDay.isSameOrBefore(end)) {
    const times = SunCalc.getTimes(
      startOfDay.clone().add(12, 'hours').toDate(),
      -36.848461,
      174.763336
    );
    sunEvents.push({
      color: 'black',
      start: moment(times.dawn).toISOString(),
      end: moment(times.dawn).add(2, 'm').toISOString(),
      display: 'background',
      extendedProps: {
        eventType: 'Solar',
      },
    });
    sunEvents.push({
      color: 'black',
      start: moment(times.dusk).subtract(2, 'm').toISOString(),
      end: moment(times.dusk).toISOString(),
      display: 'background',
      extendedProps: {
        eventType: 'Solar',
      },
    });
    startOfDay.add(1, 'day');
  }
  return sunEvents;
};

const getEvents = (
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingCollectionEndDate,
  currentSettingsBookingCollectionView
) => {
  let sunEvents = [];
  if (currentSettingsBookingCollectionView !== 'dayGridMonth') {
    sunEvents = compact(
      flatten(
        getSunEvents(
          currentSettingsBookingCollectionStartDate,
          currentSettingsBookingCollectionEndDate
        )
      )
    );
  }
  return sunEvents;
};

export default getEvents;
