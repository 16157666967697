import { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';

import get from 'lodash.get';

import Field from '../react_final_form_field';
import InputField from '../input_field';

const DocumentField = (props) => {
  const { values, labelWidth, inputWidth, size } = props;

  const handleFileChange = useCallback((name, onChange, e) => {
    const file = get(e, 'target.files.0');
    if (file) {
      onChange(file);
    }
  }, []);

  return (
    <Col className="mb-2" sm={{ span: inputWidth, offset: labelWidth }}>
      <fieldset className="border rounded-3 p-3">
        <legend className="float-none w-auto px-3 fs-6">{`${values.id ? 'Update' : 'Select'} document`}</legend>
        <Row>
          <Col>
            <Field
              type="file"
              name="document"
              labelWidth={0}
              inputWidth={12}
              size={size}
              component={InputField}
              customOnChange={handleFileChange}
            />
          </Col>
        </Row>
      </fieldset>
    </Col>
  );
};

DocumentField.defaultProps = {
  labelWidth: 3,
  inputWidth: 9,
  helpText: '',
  size: 'sm',
};

export default DocumentField;
