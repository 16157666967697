import { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import DateTime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';

import InputField from './input_field';
import InvalidBlock from './invalid_block';

import { isInvalid } from './helpers';

const ReactDateTimeFilter = (props) => {
  const {
    size,
    labelWidth,
    inputWidth,
    closeOnSelect,
    locked,
    currentSettingsReportStart,
    currentSettingsReportEnd,
    onChange,
  } = props;

  const [fieldFormat, setFieldFormat] = useState('');
  const [fieldView, setFieldView] = useState('');
  const [fieldPeriod, setFieldPeriod] = useState('');

  const [startField, setStartField] = useState('');
  const [startFieldMeta, setStartFieldMeta] = useState({});

  const [endField, setEndField] = useState('');
  const [endFieldMeta, setEndFieldMeta] = useState({});

  useEffect(() => {
    if (locked) {
      setFieldFormat('MM/YYYY');
      setFieldView('months');
      setFieldPeriod('month');
    } else {
      setFieldFormat('DD/MM/YYYY');
      setFieldView('days');
      setFieldPeriod('day');
    }
  }, [locked]);

  useEffect(() => {
    if (currentSettingsReportStart && fieldFormat) {
      setStartField(moment(currentSettingsReportStart).format(fieldFormat));
    } else {
      setStartField('');
    }
    setStartFieldMeta({});
  }, [currentSettingsReportStart, fieldFormat]);

  useEffect(() => {
    if (currentSettingsReportEnd && fieldFormat) {
      setEndField(moment(currentSettingsReportEnd).format(fieldFormat));
    } else {
      setEndField('');
    }
    setEndFieldMeta({});
  }, [currentSettingsReportEnd, fieldFormat]);

  const handleStartChange = (date) => {
    if (moment.isMoment(date)) {
      setStartField(date.format(fieldFormat));
      setStartFieldMeta({});

      setEndField(date.clone().endOf('month').format(fieldFormat));
      setEndFieldMeta({});

      onChange({
        reportStart: date.clone().startOf(fieldPeriod).toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    } else {
      setStartFieldMeta({
        touched: true,
        invalid: true,
        error: fieldFormat,
      });
      setStartField(date);
    }
  };

  const handleEndChange = (date) => {
    if (moment.isMoment(date)) {
      setEndField(date.format(fieldFormat));
      setEndFieldMeta({});
      onChange({
        reportEnd: date.clone().endOf(fieldPeriod).toISOString(),
      });
    } else {
      setEndFieldMeta({ touched: true, invalid: true, error: fieldFormat });
      setEndField(date);
    }
  };

  const startInvalid = isInvalid(startFieldMeta);
  const endInvalid = isInvalid(endFieldMeta);

  return (
    <>
      <InputField
        size={size}
        labelWidth={labelWidth}
        inputWidth={inputWidth}
        input={{
          name: 'react-date-time-filter-start',
        }}
        innerContent={
          startField && fieldFormat && fieldView ? (
            <>
              <DateTime
                value={startField}
                dateFormat={fieldFormat}
                timeFormat={false}
                onChange={handleStartChange}
                closeOnSelect={closeOnSelect}
                initialViewMode={fieldView}
                inputProps={{
                  className: classNames('form-control', `form-control-${size}`, {
                    'is-invalid': startInvalid,
                  }),
                }}
              />
              <InvalidBlock meta={startFieldMeta} helpText={fieldFormat} force />
            </>
          ) : (
            <span />
          )
        }
      />
      {!locked && (
        <Col sm="auto" className="px-0 pt-1">
          <span className="small text-muted">to...</span>
        </Col>
      )}
      {!locked && (
        <InputField
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={{
            name: 'react-date-time-filter-end',
          }}
          innerContent={
            endField && fieldFormat && fieldView ? (
              <>
                <DateTime
                  value={endField}
                  dateFormat={fieldFormat}
                  timeFormat={false}
                  onChange={handleEndChange}
                  closeOnSelect={closeOnSelect}
                  initialViewMode={fieldView}
                  inputProps={{
                    className: classNames('form-control', `form-control-${size}`, {
                      'is-invalid': endInvalid,
                    }),
                  }}
                />
                <InvalidBlock meta={endFieldMeta} helpText={fieldFormat} force />
              </>
            ) : (
              <span />
            )
          }
        />
      )}
    </>
  );
};

export default ReactDateTimeFilter;
