import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import SunCalc from 'suncalc';
import moment from 'moment';
import objectHash from 'object-hash';

import isEqual from 'lodash.isequal';
import get from 'lodash.get';

import InputField from '../form/input_field';
import LabelBlock from '../form/label_block';

moment.updateLocale('en-nz');

class PilotFlightLogSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasNight: false,
    };
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getState();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.start_at', '') !==
        get(nextProps, 'formValues.start_at', '') ||
      get(this.props, 'formValues.end_at', '') !==
        get(nextProps, 'formValues.end_at', '') ||
      get(this.props, 'formValues.end_location_id', '') !==
        get(nextProps, 'formValues.end_location_id', '') ||
      !isEqual(
        get(this.props, 'formValues.pilot_flight_logs_attributes', []),
        get(nextProps, 'formValues.pilot_flight_logs_attributes', [])
      )
    ) {
      this.getState(nextProps);
    }
  }

  getState(props = this.props) {
    let hasNight = false;
    const bookingStartAt = moment(get(props, 'formValues.start_at', ''));
    const bookingEndAt = moment(get(props, 'formValues.end_at', ''));
    if (bookingStartAt.isValid() && bookingEndAt.isValid()) {
      const { latitude: locationLatitude, longitude: locationLongitude } = get(
        this.props.locationsDataSelector,
        get(props, 'formValues.end_location_id'),
        {}
      );
      if (locationLatitude && locationLongitude) {
        const times = SunCalc.getTimes(
          bookingStartAt.toDate(),
          locationLatitude,
          locationLongitude
        );
        const dawn = moment(times.dawn);
        const dusk = moment(times.dusk);
        hasNight = bookingStartAt.isBefore(dawn) || bookingEndAt.isAfter(dusk);
      }
    }
    this.setState({
      hasNight,
    });
  }

  handleEditClick() {
    if (this.props.handleTabClick) {
      this.props.handleTabClick(5);
    }
  }

  getPilotName(pilotId) {
    return get(this.props.contactsDataSelector, [pilotId, 'fullName'], 'Unknown');
  }

  getCommandValue({
    pilot_in_charge_day,
    pilot_in_charge_night,
    copilot_day,
    copilot_night,
  }) {
    if (pilot_in_charge_day || pilot_in_charge_night) {
      return (
        parseFloat(pilot_in_charge_day || 0) + parseFloat(pilot_in_charge_night || 0)
      ).toFixed(2);
    } else if (copilot_day || copilot_night) {
      return (parseFloat(copilot_day || 0) + parseFloat(copilot_night || 0)).toFixed(2);
    } else {
      return 'Check tab...';
    }
  }

  getCommandType({
    pilot_in_charge_day,
    pilot_in_charge_night,
    copilot_day,
    copilot_night,
  }) {
    if (pilot_in_charge_day || pilot_in_charge_night) {
      return 'PIC';
    } else if (copilot_day || copilot_night) {
      return 'Copilot';
    } else {
      return 'Check tab...';
    }
  }

  renderPilotFlightLogsList() {
    return (
      <>
        {get(this.props, 'formValues.pilot_flight_logs_attributes', []).map(
          (values, index) => {
            if (!values._destroy) {
              const key = objectHash({ id: values.id, index });
              return (
                <InputField
                  key={key}
                  size="sm"
                  labelWidth={2}
                  inputWidth={10}
                  input={{}}
                  innerContent={
                    <Row>
                      <Col sm={2}>
                        <InputField
                          controlOnly
                          type="text"
                          input={{
                            value: this.getPilotName(values.pilot_id),
                            name: `pilot-${key}`,
                          }}
                          size="sm"
                          readOnly
                          plaintext
                        />
                      </Col>
                      <Col sm={2}>
                        <InputField
                          controlOnly
                          type="text"
                          input={{
                            value: this.getCommandType(values),
                            name: `commandtype-${key}`,
                          }}
                          size="sm"
                          readOnly
                          plaintext
                        />
                      </Col>
                      <Col sm={2}>
                        <InputField
                          controlOnly
                          type="text"
                          input={{
                            value: this.getCommandValue(values),
                            name: `commandValue-${key}`,
                          }}
                          size="sm"
                          readOnly
                          plaintext
                        />
                      </Col>
                      <Col sm={6}>
                        <Button
                          type="button"
                          variant="link"
                          size="sm"
                          onClick={this.handleEditClick}
                          tabIndex={-1}
                        >
                          {this.state.hasNight ? 'edit for night flying...' : 'edit...'}
                        </Button>
                      </Col>
                    </Row>
                  }
                >{`Log ${index + 1}`}</InputField>
              );
            }
            return undefined;
          }
        )}
      </>
    );
  }

  renderHeader = () => (
    <Row>
      <Col sm={2} className="text-end">
        &nbsp;
      </Col>
      <Col sm={10}>
        <Row>
          <Col sm={2}>
            <LabelBlock>Pilot</LabelBlock>
          </Col>
          <Col sm={2}>
            <LabelBlock>Command Type</LabelBlock>
          </Col>
          <Col sm={2}>
            <LabelBlock>Command Value</LabelBlock>
          </Col>
          <Col sm={6} />
        </Row>
      </Col>
    </Row>
  );

  renderPilotFlightLogs() {
    return (
      <>
        {this.renderHeader()}
        {this.renderPilotFlightLogsList()}
      </>
    );
  }

  render() {
    const logsLength = get(
      this.props,
      'formValues.pilot_flight_logs_attributes',
      []
    ).filter((pfl) => !pfl._destroy).length;
    const flightTime = get(this.props, 'formValues.hobb_record_attributes.flight_time');
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Pilot Flight Logs</strong>
          </p>
        </Col>
        {flightTime && (
          <Col sm={10}>{logsLength > 0 && this.renderPilotFlightLogs()}</Col>
        )}
        {!flightTime && (
          <Col sm={10}>
            <p className="small">Update hobb record</p>
          </Col>
        )}
      </Row>
    );
  }
}

export default PilotFlightLogSummary;
