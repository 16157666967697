import { Component } from 'react';
import moment from 'moment';

import first from 'lodash.first';
import get from 'lodash.get';

import DlHorizontal from '../dl_horizontal';

class FlightSignoffInfo extends Component {
  renderFixed(value) {
    return value ? value.toFixed(1) : '-';
  }

  renderFlightSegmentSummary(flightSegmentSummary) {
    const {
      start_at: startAt,
      start_location: startLocation,
      end_location: endLocation,
      pax,
    } = flightSegmentSummary;
    const summary = `${startAt} ${startLocation} - ${endLocation}`;
    return (
      <span>
        {summary} {pax && ` | ${pax}`}
      </span>
    );
  }

  renderFuelBowserFill(fuelBowserFill) {
    const fuelBowserName = get(this.props.fuelBowsersDataSelector, [
      get(fuelBowserFill, 'fuel_bowser_id'),
      'name',
    ]);
    const value = get(fuelBowserFill, 'quantity_value');
    const quantityUnit = this.props.currentSettingsFuelBowserFillDefaultQuantityUnit;
    const names = fuelBowserName.split('/');
    const locationName = first(names);
    const type = names[1];
    return (
      <span>
        <strong>{value.toFixed(2)}</strong> {quantityUnit}
        <strong> {locationName}</strong> - {type}
      </span>
    );
  }

  renderFuelTankerFill(fuelTankerFill) {
    const fuelTankerName = get(this.props.fuelTankersDataSelector, [
      get(fuelTankerFill, 'fuel_tanker_id'),
      'name',
    ]);
    const value = get(fuelTankerFill, 'quantity_value');
    const quantityUnit = this.props.currentSettingsFuelBowserFillDefaultQuantityUnit;
    return (
      <span>
        <strong>{value.toFixed(2)}</strong> {quantityUnit}
        <strong> {fuelTankerName}</strong>
        {' - tanker'}
      </span>
    );
  }

  renderEngineEvent(engineEvent) {
    const { event_value: eventValue, event_type: eventType } = engineEvent;
    const summary = `${eventType} ${eventValue}`;
    return <span>{summary}</span>;
  }

  render() {
    const {
      booking: {
        start_at: startAt,
        provider_id: providerId,
        flight_type_id: flightTypeId,
        pilot_id: pilotId,
        bookingChargeables,
        hobbRecord: { start_hobb: startHobb, end_hobb: endHobb, flight_time: flightTime },
        flightSegmentSummaries,
        fuelBowserFills,
        fuelTankerFills,
        engineEvents,
      },
    } = this.props;

    const chargeableFullNames = bookingChargeables
      .map((bca) => get(this.props.contactsDataSelector, [bca.chargeable_id, 'fullName']))
      .join(', ');

    const flightTypeName = get(this.props.flightTypesDataSelector, [
      flightTypeId,
      'name',
    ]);
    const pilotFullName = get(this.props.contactsDataSelector, [pilotId, 'fullName']);
    const providerFullName = get(this.props.contactsDataSelector, [
      providerId,
      'fullName',
    ]);

    return (
      <>
        <DlHorizontal dt="Date">{moment(startAt).format('llll')}</DlHorizontal>
        <DlHorizontal dt="Charge to">{chargeableFullNames}</DlHorizontal>
        <DlHorizontal dt="Allocate to">{providerFullName}</DlHorizontal>
        <DlHorizontal dt="Flight type">{flightTypeName}</DlHorizontal>
        <DlHorizontal dt="Pilot">{pilotFullName}</DlHorizontal>
        <DlHorizontal dt="Summary" className="mb-2">
          {flightSegmentSummaries.map((flightSegmentSummary) => (
            <span key={flightSegmentSummary.id} style={{ display: 'block' }}>
              {this.renderFlightSegmentSummary(flightSegmentSummary)}
            </span>
          ))}
        </DlHorizontal>
        <DlHorizontal dt="Hobb Start/End">
          {`${this.renderFixed(startHobb)} / ${this.renderFixed(endHobb)}`}
        </DlHorizontal>
        <DlHorizontal dt="Flight time">{this.renderFixed(flightTime)}</DlHorizontal>
        <DlHorizontal dt="Fuel Bowser Fills">
          {fuelBowserFills && fuelBowserFills.length > 0 ? (
            fuelBowserFills.map((fuelBowserFill) => (
              <span key={fuelBowserFill.id} style={{ display: 'block' }}>
                {this.renderFuelBowserFill(fuelBowserFill)}
              </span>
            ))
          ) : (
            <span>-</span>
          )}
        </DlHorizontal>
        <DlHorizontal dt="Fuel Tanker Fills">
          {fuelTankerFills && fuelTankerFills.length > 0 ? (
            fuelTankerFills.map((fuelTankerFill) => (
              <span key={fuelTankerFill.id} style={{ display: 'block' }}>
                {this.renderFuelTankerFill(fuelTankerFill)}
              </span>
            ))
          ) : (
            <span>-</span>
          )}
        </DlHorizontal>
        <DlHorizontal dt="Engine Events">
          {engineEvents && engineEvents.length > 0 ? (
            engineEvents.map((engineEvent) => (
              <span key={engineEvent.id} style={{ display: 'block' }}>
                {this.renderEngineEvent(engineEvent)}
              </span>
            ))
          ) : (
            <span>-</span>
          )}
        </DlHorizontal>
      </>
    );
  }
}

export default FlightSignoffInfo;
