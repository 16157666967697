import { Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import defaultTo from 'lodash.defaultto';
import compact from 'lodash.compact';
import get from 'lodash.get';

import LabelBlock from '../form/label_block';

import BookingPilotFlightExpenseFields from './booking_pilot_flight_expense_fields';

class PilotFlightExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleAddOvernightButtonClick = this.handleAddOvernightButtonClick.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getSelectOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.pilot_id', '') !==
        get(nextProps, 'formValues.pilot_id', '') ||
      get(this.props, 'formValues.copilot_id', '') !==
        get(nextProps, 'formValues.copilot_id', '')
    ) {
      this.getSelectOptions(nextProps);
    }
  }

  getSelectOptions(props = this.props) {
    const pilotId = get(props, 'formValues.pilot_id', '');
    const copilotId = get(props, 'formValues.copilot_id', '');
    const selectOptions = [];
    if (pilotId) {
      selectOptions.push({
        id: pilotId,
        name: get(this.props.contactsDataSelector, [pilotId, 'fullName']),
      });
    }
    if (copilotId) {
      selectOptions.push({
        id: copilotId,
        name: get(this.props.contactsDataSelector, [copilotId, 'fullName']),
      });
    }
    this.setState({
      selectOptions,
    });
  }

  handleAddButtonClick() {
    this.props.pilotFlightExpenses.fields.push({
      onchargeable: false,
      reimbursable: false,
      includes_gst: true,
      total: '',
    });
  }

  handleAddOvernightButtonClick() {
    this.props.pilotFlightExpenses.fields.push({
      onchargeable: true,
      reimbursable: true,
      includes_gst: true,
      notes: this.props.currentSettingsPilotFlightExpenseOvernightText,
      total: this.props.currentSettingsPilotFlightExpenseOvernightRate,
    });
  }

  handleDeleteButtonClick(index) {
    this.props.pilotFlightExpenses.fields.remove(index);
  }

  renderPilotFlightExpensesList() {
    const pilotIds = compact([
      defaultTo(parseInt(get(this.props, 'formValues.pilot_id'), 10), ''),
      defaultTo(parseInt(get(this.props, 'formValues.copilot_id'), 10), ''),
    ]);
    return (
      <>
        {this.props.pilotFlightExpenses.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}._destroy`,
              `${field}.pilot_id`,
              `${field}.notes`,
              `${field}.total`,
              `${field}.total_excl_gst`,
              `${field}.includes_gst`,
              `${field}.onchargeable`,
              `${field}.reimbursable`,
            ];
            return (
              <Fields
                key={`booking-${field}`}
                index={index}
                parentField={field}
                names={fields}
                component={BookingPilotFlightExpenseFields}
                formValues={values}
                pilotIds={pilotIds}
                overNight={
                  this.props.currentSettingsPilotFlightExpenseOvernightText ===
                  values.notes
                }
                handleDeleteButtonClick={this.handleDeleteButtonClick}
                selectOptions={this.state.selectOptions}
              />
            );
          }
          return undefined;
        })}
      </>
    );
  }

  renderHeader = () => (
    <Row>
      <Col sm={2} className="text-end">
        &nbsp;
      </Col>
      <Col sm={10}>
        <Row>
          <Col sm={2}>
            <LabelBlock>Pilot</LabelBlock>
          </Col>
          <Col sm={3}>
            <LabelBlock>Notes</LabelBlock>
          </Col>
          <Col sm={1}>
            <LabelBlock>Total</LabelBlock>
          </Col>
          <Col sm={1}>
            <LabelBlock>Incl. Gst</LabelBlock>
          </Col>
          <Col sm={1}>
            <LabelBlock>Oncharge</LabelBlock>
          </Col>
          <Col sm={1}>
            <LabelBlock>Reimburse</LabelBlock>
          </Col>
          <Col sm={1} />
        </Row>
      </Col>
    </Row>
  );

  renderPilotFlightExpenses() {
    return (
      <>
        {this.renderHeader()}
        {this.renderPilotFlightExpensesList()}
      </>
    );
  }

  render() {
    const expensesLength = get(
      this.props,
      'formValues.pilot_flight_expenses_attributes',
      []
    ).filter((pfe) => !pfe._destroy).length;
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Pilot Flight Expenses</strong>
          </p>
        </Col>
        <Col sm={10}>
          {expensesLength > 0 && this.renderPilotFlightExpenses()}
          <Row>
            <Col sm={2} className="text-end">
              <ButtonGroup>
                <Button
                  type="button"
                  variant="link"
                  size="sm"
                  onClick={this.handleAddButtonClick}
                  tabIndex={-1}
                  disabled={!this.props.formValues.pilot_id}
                >
                  add...
                </Button>
                <Button
                  type="button"
                  variant="link"
                  size="sm"
                  onClick={this.handleAddOvernightButtonClick}
                  tabIndex={-1}
                  disabled={!this.props.formValues.pilot_id}
                >
                  add overnight...
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default PilotFlightExpenses;
