import { Row, Col } from 'react-bootstrap';

import get from 'lodash.get';

import CheckboxInputField from '../form/checkbox_input_field';
import InputField from '../form/input_field';
import InvalidBlock from '../form/invalid_block';

const FlightSignoffFormLandingChargeFields = (props) => {
  const { locationsDataSelector, formValues, parentField } = props;

  const { end_location_id: endLocationId } = formValues;

  const {
    end_location_landing_fee: {
      input: endLocationLandingFeeInput,
      meta: endLocationLandingFeeMeta,
    },
    oncharge_end_location_landing_fee: {
      input: onchargeEndLocationLandingFeeInput,
      meta: onchargeEndLocationLandingFeeMeta,
    },
  } = get(props, parentField);

  const renderControlLabel = () =>
    get(locationsDataSelector, [endLocationId, 'longName'], 'Select an end location');

  return (
    <InputField
      size="sm"
      labelWidth={3}
      inputWidth={9}
      input={{}}
      innerContent={
        <Row>
          <Col sm={3}>
            <InputField
              controlOnly
              blurOnly
              type="text"
              input={endLocationLandingFeeInput}
              meta={endLocationLandingFeeMeta}
              size="sm"
            />
          </Col>
          <Col sm={3}>
            <CheckboxInputField
              innerContentOnly
              size="sm"
              input={onchargeEndLocationLandingFeeInput}
              meta={onchargeEndLocationLandingFeeMeta}
            >
              Oncharge
            </CheckboxInputField>
          </Col>
          <Col sm={12}>
            <InvalidBlock meta={endLocationLandingFeeMeta} force />
            <InvalidBlock meta={onchargeEndLocationLandingFeeMeta} force />
          </Col>
        </Row>
      }
    >
      {renderControlLabel()}
    </InputField>
  );
};

export default FlightSignoffFormLandingChargeFields;
