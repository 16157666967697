import moment from 'moment';
import objectHash from 'object-hash';

import compact from 'lodash.compact';
import clone from 'lodash.clone';
import flatten from 'lodash.flatten';

import { kebabCase, sentenceCase } from 'change-case';
import { isCalendarType, isAdminComplete, isPilotComplete } from '../lib/bookingHelpers';
import { getDateRanges } from '../../lib/utils';

const getBookingEventClasses = (booking, isFlight, isViewAircraft) => {
  const {
    calendar_type: calendarType,
    status,
    end_at: endAt,
    tia_tog_profile: tiaTogProfile,
  } = booking;
  const classNames = [calendarType];
  if (tiaTogProfile && tiaTogProfile.length > 0) {
    classNames.push('profiled');
  }
  const adminComplete = isAdminComplete(booking);
  const pilotComplete = isPilotComplete(booking);
  if (!isViewAircraft) {
    classNames.push('blocked-out');
  } else if (adminComplete) {
    classNames.push('admin_complete');
  } else if (pilotComplete) {
    classNames.push('pilot_complete');
  } else if (!isFlight && moment(endAt).isBefore(moment())) {
    classNames.push('time_complete');
  } else {
    classNames.push(status);
  }
  return classNames;
};

const getUnavailableDutyEventCalendarEvent = (
  dutyEvent,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingCollectionEndDate
) => {
  const {
    id,
    contact,
    start_at: startAtStr,
    end_at: endAtStr,
    name,
    duty_notes: dutyNotes,
  } = dutyEvent;
  const maxStartAt = moment.max(
    moment(startAtStr),
    moment(currentSettingsBookingCollectionStartDate)
  );
  const minEndAt = moment.min(
    moment(endAtStr),
    moment(currentSettingsBookingCollectionEndDate)
  );
  const { id: employeeId, fullName, employee_color: employeeColor } = contact;
  return {
    id: `${id}-unavailable-dutyemployee-${employeeId}`,
    title: sentenceCase(name),
    resourceId: `${kebabCase(fullName)}-test`,
    // start: startAt,
    // end: endAt,
    // use these dates otherwise the hashing doesn't change and the event doesn't show
    start: maxStartAt.format(),
    end: minEndAt.format(),
    allDay: true,
    display: 'background',
    borderColor: '#A9A9A9',
    backgroundColor: '#A9A9A9',
    textColor: '#000000',
    extendedProps: {
      eventType: 'DutyEvent',
      dutyEventId: id,
      jobNotes: dutyNotes,
      contactFullName: fullName,
      contactColor: employeeColor || '#3a87ad',
    },
  };
};

const getAvailableDutyEventCalendarEvent = (
  dutyEvent,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingCollectionEndDate
) => {
  const {
    id,
    contact,
    start_at: startAtStr,
    end_at: endAtStr,
    start_time: startTimeStr,
    end_time: endTimeStr,
    name,
    duty_notes: dutyNotes,
  } = dutyEvent;
  const { id: employeeId, fullName, employee_color: employeeColor } = contact;
  const startTime = moment(startTimeStr, 'HH:mm:ss');
  const endTime = moment(endTimeStr, 'HH:mm:ss');
  const endTimeBeforeStartTime = endTime.isBefore(startTime, 'second');
  const hasStartBefore = moment(startAtStr).isBefore(
    moment(currentSettingsBookingCollectionStartDate),
    'second'
  );
  // const hasEndAfter = moment(currentSettingsBookingCollectionEndDate).isBefore(
  //   moment(endAtStr).subtract(1, 'day').endOf('day'),
  //   'second'
  // );
  const maxStartAt = moment.max(
    moment(startAtStr),
    moment(currentSettingsBookingCollectionStartDate)
  );
  const minEndAt = moment.min(
    moment(endAtStr).subtract(1, 'day').endOf('day'),
    moment(currentSettingsBookingCollectionEndDate)
  );
  const dayRanges = getDateRanges(
    maxStartAt.format(),
    minEndAt.format(),
    true,
    false,
    false
  );
  const flightEvents = dayRanges.map((dayRange) => {
    // available events wrap the available event with unavailable events
    const { startAt: rangeStartAt, endAt: rangeEndAt } = dayRange;
    if (!endTimeBeforeStartTime) {
      const dutyStartAt = moment(rangeStartAt)
        .hour(startTime.hour())
        .minute(startTime.minute())
        .format();
      const dutyEndAt = moment(rangeEndAt)
        .hour(endTime.hour())
        .minute(endTime.minute())
        .second(0)
        .format();
      return [
        {
          id: `${id}-available-dutyemployee-${employeeId}`,
          title: sentenceCase(name),
          resourceId: `${kebabCase(fullName)}-test`,
          start: dutyStartAt,
          end: dutyEndAt,
          borderColor: '#FFFFFF',
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          display: 'background',
          extendedProps: {
            eventType: 'DutyEvent',
            dutyEventId: id,
            jobNotes: dutyNotes,
            contactFullName: fullName,
            contactColor: employeeColor || '#3a87ad',
          },
        },
        {
          id: `${id}-pre-available-dutyemployee-${employeeId}`,
          title: sentenceCase(name),
          resourceId: `${kebabCase(fullName)}-test`,
          start: rangeStartAt,
          end: dutyStartAt,
          borderColor: '#A9A9A9',
          backgroundColor: '#A9A9A9',
          textColor: '#000000',
          display: 'background',
          extendedProps: {
            eventType: 'DutyEvent',
            // skipPopover: true,
            dutyEventId: id,
            jobNotes: dutyNotes,
            contactFullName: fullName,
            contactColor: employeeColor || '#3a87ad',
          },
        },
        {
          id: `${id}-post-available-dutyemployee-${employeeId}`,
          title: sentenceCase(name),
          resourceId: `${kebabCase(fullName)}-test`,
          start: dutyEndAt,
          end: rangeEndAt,
          borderColor: '#A9A9A9',
          backgroundColor: '#A9A9A9',
          textColor: '#000000',
          display: 'background',
          extendedProps: {
            eventType: 'DutyEvent',
            // skipPopover: true,
            dutyEventId: id,
            jobNotes: dutyNotes,
            contactFullName: fullName,
            contactColor: employeeColor || '#3a87ad',
          },
        },
      ];
    }
    const spanFlightEvents = [];
    const eveningDutyStartAt = moment(rangeStartAt)
      .hour(startTime.hour())
      .minute(startTime.minute())
      .format();
    const eveningDutyEndAt = moment(rangeEndAt).format();

    const morningDutyStartAt = moment(rangeStartAt).format();
    const morningDutyEndAt = moment(rangeStartAt)
      .hour(endTime.hour())
      .minute(endTime.minute())
      .second(0)
      .format();
    if (hasStartBefore) {
      // this is the day after a shift spanning both days
      spanFlightEvents.push({
        id: `${id}-morning-available-dutyemployee-${employeeId}`,
        title: sentenceCase(name),
        resourceId: `${kebabCase(fullName)}-test`,
        start: morningDutyStartAt,
        end: morningDutyEndAt,
        display: 'background',
        borderColor: '#FFFFFF',
        backgroundColor: '#FFFFFF',
        textColor: '#000000',
        extendedProps: {
          eventType: 'DutyEvent',
          dutyEventId: id,
          jobNotes: dutyNotes,
          contactFullName: fullName,
          contactColor: employeeColor || '#3a87ad',
        },
      });
      spanFlightEvents.push({
        id: `${id}-pre-evening-available-dutyemployee-${employeeId}`,
        title: sentenceCase(name),
        resourceId: `${kebabCase(fullName)}-test`,
        start: morningDutyEndAt,
        end: eveningDutyStartAt,
        display: 'background',
        borderColor: '#A9A9A9',
        backgroundColor: '#A9A9A9',
        textColor: '#000000',
        extendedProps: {
          // skipPopover: true,
          eventType: 'DutyEvent',
          dutyEventId: id,
          jobNotes: dutyNotes,
          contactFullName: fullName,
          contactColor: employeeColor || '#3a87ad',
        },
      });
    } else {
      // first day of a night shift. Block out the preceding hours
      spanFlightEvents.push({
        id: `${id}-pre-first-evening-available-dutyemployee-${employeeId}`,
        title: sentenceCase(name),
        resourceId: `${kebabCase(fullName)}-test`,
        start: rangeStartAt,
        end: eveningDutyStartAt,
        display: 'background',
        borderColor: '#A9A9A9',
        backgroundColor: '#A9A9A9',
        textColor: '#000000',
        extendedProps: {
          // skipPopover: true,
          eventType: 'DutyEvent',
          dutyEventId: id,
          jobNotes: dutyNotes,
          contactFullName: fullName,
          contactColor: employeeColor || '#3a87ad',
        },
      });
    }
    spanFlightEvents.push({
      id: `${id}-evening-available-dutyemployee-${employeeId}`,
      title: sentenceCase(name),
      resourceId: `${kebabCase(fullName)}-test`,
      start: eveningDutyStartAt,
      end: eveningDutyEndAt,
      display: 'background',
      borderColor: '#FFFFFF',
      backgroundColor: '#FFFFFF',
      textColor: '#000000',
      extendedProps: {
        eventType: 'DutyEvent',
        dutyEventId: id,
        jobNotes: dutyNotes,
        contactFullName: fullName,
        contactColor: employeeColor || '#3a87ad',
      },
    });
    return spanFlightEvents;
  });
  return compact(flatten(flightEvents));
};

const getBookingEmployeeEvent = (booking, currentSettingsBookingCollectionView) => {
  const isFlight = false;
  const isViewAircraft = false;
  const {
    id,
    start_at: startAt,
    end_at: endAt,
    calendar_type: calendarType,
    job_notes: jobNotes,
    employees,
  } = booking;

  const employeeFullNames = employees.map((e) => e.fullName).join(', ');
  const employeeEvents = employees.map((employee) => {
    const { id: employeeId, fullName, employee_color: employeeColor } = employee;
    const thisEmployeeEvents = [];
    if (fullName) {
      // if (currentSettingsBookingCollectionView === 'resourceTimelineDay') {
      //   thisEmployeeEvents.push({
      //     id: `${id}-employee-${employeeId}-orig`,
      //     title: sentenceCase(calendarType),
      //     resourceId: `${kebabCase(fullName)}-orig`,
      //     start: startAt,
      //     end: endAt,
      //     classNames: getBookingEventClasses(booking, isFlight, isViewAircraft),
      //     textColor: 'inherit',
      //     extendedProps: {
      //       eventType: 'Booking',
      //       bookingId: id,
      //       jobNotes,
      //       employeeFullNames,
      //       contactFullName: fullName,
      //       contactColor: employeeColor || '#3a87ad',
      //       isFlight,
      //       isViewAircraft,
      //     },
      //   });
      // }
      thisEmployeeEvents.push({
        id: `${id}-employee-${employeeId}-test`,
        title: sentenceCase(calendarType),
        resourceId: `${kebabCase(fullName)}-test`,
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, isFlight, isViewAircraft),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          jobNotes,
          employeeFullNames,
          contactFullName: fullName,
          contactColor: employeeColor || '#3a87ad',
          isFlight,
          isViewAircraft,
        },
      });
    }
    return thisEmployeeEvents;
  });
  if (currentSettingsBookingCollectionView !== 'resourceTimelineDay') {
    return flatten(compact(employeeEvents)).map((event) => {
      if (moment(event.end).dayOfYear() !== moment(event.start).dayOfYear()) {
        const allDayEvent = clone(event);
        allDayEvent.allDay = true;
        allDayEvent.extendedProps.start = moment(event.start).format();
        allDayEvent.extendedProps.end = moment(event.end).format();
        allDayEvent.start = moment(event.start).startOf('day').format();
        allDayEvent.end = moment(event.end).add(1, 'days').startOf('day').format();
        return allDayEvent;
      }
      return event;
    });
  }
  return flatten(compact(employeeEvents));
};

const getBookingFlightEvent = (
  booking,
  bookingView,
  defaultBookingViewBookingView,
  currentSettingsBookingCollectionView,
  isDeveloper = false
) => {
  const isFlight = true;
  const {
    id,
    aircraft,
    start_at: startAt,
    end_at: endAt,
    pilot,
    copilot,
    flightSegmentSummaries,
    chargeables,
    tia_tog_profile: tiaTogProfile,
  } = booking;

  const { id: bookingViewId, default_booking_view: defaultBookingView } = bookingView;

  const { name: defaultBookingViewBookingViewName } = defaultBookingViewBookingView || {};

  const flightEvents = [];

  const {
    registration_abbreviated: registrationAbbreviated,
    bookingView: aircraftBookingView,
  } = aircraft;

  const { id: aircraftBookingViewId, name: aircraftBookingViewName } =
    aircraftBookingView || {};

  const chargeableFullNames = chargeables.map((c) => c.fullName).join(', ');

  const isViewAircraft =
    bookingViewId === aircraftBookingViewId ||
    (!aircraftBookingViewId && defaultBookingView);

  const adminComplete = isAdminComplete(booking);

  if (pilot) {
    const {
      id: pilotId,
      fullName: pilotFullName,
      employee_color: pilotEmployeeColor,
    } = pilot;
    if (currentSettingsBookingCollectionView === 'resourceTimelineDay') {
      flightEvents.push({
        id: `${id}-pilot-${pilotId}-orig`,
        title: isViewAircraft
          ? `${registrationAbbreviated}${isDeveloper ? '-p' : ''}`
          : aircraftBookingViewName || defaultBookingViewBookingViewName,
        resourceId: `${kebabCase(pilotFullName)}-orig`,
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, isFlight, isViewAircraft),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          adminComplete,
          flightSegmentSummaries,
          tiaTogProfile,
          chargeableFullNames,
          contactFullName: pilotFullName,
          contactColor: pilotEmployeeColor || '#3a87ad',
          isFlight,
          isViewAircraft,
        },
      });
    }
    flightEvents.push({
      id: `${id}-pilot-${pilotId}-test`,
      title: isViewAircraft
        ? `${registrationAbbreviated}${isDeveloper ? '-p' : ''}`
        : aircraftBookingViewName || defaultBookingViewBookingViewName,
      resourceId: `${kebabCase(pilotFullName)}-test`,
      start: startAt,
      end: endAt,
      classNames: getBookingEventClasses(booking, isFlight, isViewAircraft),
      textColor: 'inherit',
      extendedProps: {
        eventType: 'Booking',
        bookingId: id,
        adminComplete,
        flightSegmentSummaries,
        tiaTogProfile,
        chargeableFullNames,
        contactFullName: pilotFullName,
        contactColor: pilotEmployeeColor || '#3a87ad',
        isFlight,
        isViewAircraft,
      },
    });
    if (copilot) {
      const {
        id: copilotId,
        fullName: copilotFullName,
        employee_color: copilotEmployeeColor,
      } = copilot;
      if (currentSettingsBookingCollectionView === 'resourceTimelineDay') {
        flightEvents.push({
          id: `${id}-copilot-${copilotId}-orig`,
          title: isViewAircraft
            ? `${registrationAbbreviated}${isDeveloper ? '-co' : ''}`
            : aircraftBookingViewName || defaultBookingViewBookingViewName,
          resourceId: `${kebabCase(copilotFullName)}-orig`,
          start: startAt,
          end: endAt,
          classNames: getBookingEventClasses(booking, isFlight, isViewAircraft),
          textColor: 'inherit',
          extendedProps: {
            eventType: 'Booking',
            bookingId: id,
            adminComplete,
            flightSegmentSummaries,
            tiaTogProfile,
            chargeableFullNames,
            contactFullName: copilotFullName,
            contactColor: copilotEmployeeColor || '#3a87ad',
            isFlight,
            isViewAircraft,
          },
        });
      }
      flightEvents.push({
        id: `${id}-copilot-${copilotId}-test`,
        title: isViewAircraft
          ? `${registrationAbbreviated}${isDeveloper ? '-co' : ''}`
          : aircraftBookingViewName || defaultBookingViewBookingViewName,
        resourceId: `${kebabCase(copilotFullName)}-test`,
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, isFlight, isViewAircraft),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          adminComplete,
          flightSegmentSummaries,
          tiaTogProfile,
          chargeableFullNames,
          contactFullName: copilotFullName,
          contactColor: copilotEmployeeColor || '#3a87ad',
          isFlight,
          isViewAircraft,
        },
      });
    }
  }
  return compact(flightEvents);
};

const getBookingEvent = ({
  booking,
  bookingView,
  defaultBookingViewBookingView,
  currentSettingsBookingCalendarTypes,
  currentSettingsBookingCollectionView,
  isDeveloper,
}) => {
  const { employees } = booking;
  let events = [];
  if (isCalendarType(currentSettingsBookingCalendarTypes.flight, booking)) {
    events = getBookingFlightEvent(
      booking,
      bookingView,
      defaultBookingViewBookingView,
      currentSettingsBookingCollectionView,
      isDeveloper
    );
  }
  if (employees.length > 0) {
    events = getBookingEmployeeEvent(booking, currentSettingsBookingCollectionView);
  }
  return compact(flatten(events));
};

const getEvents = ({
  bookings,
  dutyEvents,
  bookingView,
  defaultBookingViewBookingView,
  currentSettingsBookingCollectionView,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingCollectionEndDate,
  currentSettingsBookingCalendarTypes,
  isDeveloper,
}) => {
  const bookingCalendarEvents = compact(
    flatten(
      bookings.map((booking) =>
        getBookingEvent({
          booking,
          bookingView,
          defaultBookingViewBookingView,
          currentSettingsBookingCalendarTypes,
          currentSettingsBookingCollectionView,
          isDeveloper,
        })
      )
    )
  );
  let unavailableDutyEventCalendarEvents = [];
  let availableDutyEventCalendarEvents = [];
  if (currentSettingsBookingCollectionView === 'resourceTimelineDay') {
    const unavailableDutyEvents = dutyEvents.filter((de) => !de.contact_available);
    unavailableDutyEventCalendarEvents = compact(
      flatten(
        unavailableDutyEvents.map((dutyEvent) =>
          getUnavailableDutyEventCalendarEvent(
            dutyEvent,
            currentSettingsBookingCollectionStartDate,
            currentSettingsBookingCollectionEndDate
          )
        )
      )
    );
    const availableDutyEvents = dutyEvents.filter((de) => de.contact_available);
    availableDutyEventCalendarEvents = compact(
      flatten(
        availableDutyEvents.map((dutyEvent) =>
          getAvailableDutyEventCalendarEvent(
            dutyEvent,
            currentSettingsBookingCollectionStartDate,
            currentSettingsBookingCollectionEndDate
          )
        )
      )
    );
  }
  const flightEvents = [
    ...bookingCalendarEvents,
    ...unavailableDutyEventCalendarEvents,
    ...availableDutyEventCalendarEvents,
  ].map((flightEvent) => {
    const hash = objectHash(flightEvent);
    return {
      ...flightEvent,
      id: `${flightEvent.id}-${hash}`,
    };
  });
  return flightEvents;
};

export default getEvents;
