import get from 'lodash.get';

export function isStatus(type, booking) {
  return get(booking, 'status') === type;
}

export function isCalendarType(type, booking) {
  return get(booking, 'calendar_type') === type || get(booking, 'calendarType') === type;
}

export function isAdminComplete(booking) {
  return !!(get(booking, 'admin_completed_at') || get(booking, 'adminCompletedAt'));
}

export function isAuditComplete(booking) {
  return !!(get(booking, 'audit_created_at') || get(booking, 'auditCreatedAt'));
}

export function isPilotComplete(booking) {
  return !!get(booking, 'hobbRecord.id');
}
