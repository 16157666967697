import { Row, Col, Table, Button } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';

import uniq from 'lodash.uniq';
import debounce from 'lodash.debounce';
import set from 'lodash.set';
import get from 'lodash.get';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady, getExport } from '../lib/utils';

import bookingFlightTimeForMonthByProviderQuery from '../queries/booking_flight_time_for_month_by_provider_query';
import contactListQuery from '../queries/contact_list_query';
import aircraftListQuery from '../queries/aircraft_list_query';

moment.updateLocale('en-nz');

class ReportProviderFlightHour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterProviderId: this.props.currentSettingsReportProviderId,
      filterProviderIds: [],
    };
    this.handleDisplayProviderIdChange = this.handleDisplayProviderIdChange.bind(this);
    this.handleNavAircraftFlightRecord = this.handleNavAircraftFlightRecord.bind(this);
    this.handleNavChargeableFlightRecord =
      this.handleNavChargeableFlightRecord.bind(this);
    this.handleNavChargeableInvoice = this.handleNavChargeableInvoice.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.providerId) {
      this.handleDisplayProviderIdChange({
        target: { value: this.props.params.providerId },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce(() => {
      this.props.bookingFlightTimeForMonthByProviderQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterProviderIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterProviderIds = nextProps.bookingFlightTimeForMonthByProviderQuery.data.map(
        (data) => data.provider_id
      );
    }
    this.setState({
      filterProviderIds,
    });
    let filterProviderId = nextProps.currentSettingsReportProviderId;
    if (filterProviderId && this.isLoaded(nextProps)) {
      if (
        filterProviderIds.length > 0 &&
        filterProviderIds.indexOf(filterProviderId) === -1
      ) {
        filterProviderId = '';
      }
    }
    if (filterProviderId !== this.props.currentSettingsReportProviderId) {
      this.handleDisplayProviderIdChange({ target: { value: filterProviderId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      providerId: this.props.currentSettingsReportProviderId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  getSelectableAircrafts(aircraftFilterIds) {
    return get(this.props, 'aircraftListQuery.data', []).filter((a) =>
      aircraftFilterIds.includes(a.id)
    );
  }

  renderFixed = (value) => (value ? value.toFixed(2) : '-');

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const newProps = props || this.props;
    return !queriesReady(
      newProps.bookingFlightTimeForMonthByProviderQuery,
      newProps.providerListQuery,
      newProps.aircraftListQuery
    );
  }

  handleDisplayProviderIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterProviderId: value,
    });
    this.props.currentSettingsSet({
      reportProviderId: value,
    });
  }

  handleNavAircraftFlightRecord(e) {
    const aircraftId = parseInt(e.target.getAttribute('data-id'), 10);
    this.props.currentSettingsSet({
      reportAircraftId: aircraftId,
    });
    this.props.navigate('/reports/aircraft_flight_record');
  }

  handleNavChargeableFlightRecord(e) {
    const chargeableId = parseInt(e.target.getAttribute('data-id'), 10);
    this.props.currentSettingsSet({
      reportChargeableId: chargeableId,
    });
    this.props.navigate('/reports/chargeable_flight_record');
  }

  handleNavChargeableInvoice(e) {
    const chargeableId = parseInt(e.target.getAttribute('data-id'), 10);
    this.props.currentSettingsSet({
      reportChargeableId: chargeableId,
    });
    this.props.navigate('/reports/chargeable_invoice');
  }

  renderRow(chargeableId, chargeableFullName, totals, filterAircraftIds) {
    const complete = totals.completed.every((b) => b);
    const invoiced = totals.invoiced.every((b) => b);
    return (
      <tr key={chargeableId}>
        <td>{chargeableFullName}</td>
        <td className="text-end">{this.renderFixed(totals.total)}</td>
        {this.getSelectableAircrafts(filterAircraftIds).map((model) => {
          const aircraftId = model.id;
          return (
            <td key={chargeableId + aircraftId} className="text-end">
              {this.renderFixed(get(totals, [aircraftId]))}
            </td>
          );
        })}
        <td className="text-end">
          <Glyphicon
            style={{ color: complete ? 'green' : 'red' }}
            glyph={complete ? 'ok' : 'exclamation-sign'}
          />
        </td>
        <td className="text-end">
          <Glyphicon
            style={{ color: invoiced ? 'green' : 'red' }}
            glyph={invoiced ? 'ok' : 'exclamation-sign'}
          />
        </td>
        <td className="text-end">
          <Button
            data-id={chargeableId}
            variant="link"
            onClick={this.handleNavChargeableFlightRecord}
          >
            flt
          </Button>
          <Button
            data-id={chargeableId}
            variant="link"
            onClick={this.handleNavChargeableInvoice}
          >
            inv
          </Button>
        </td>
      </tr>
    );
  }

  renderChargeables(bookings, totals, filterAircraftIds) {
    const chargeableIds = [...new Set(bookings.map((booking) => booking.chargeable_id))];
    return (
      <tbody>
        {chargeableIds.map((chargeableId) => {
          const chargeableBookings = [
            ...new Set(
              bookings.filter((booking) => booking.chargeable_id === chargeableId)
            ),
          ];
          const { chargeable_full_name: chargeableFullName } = chargeableBookings[0];
          return this.renderRow(
            chargeableId,
            chargeableFullName,
            totals.chargeables[chargeableId],
            filterAircraftIds
          );
        })}
      </tbody>
    );
  }

  renderHeaderRow(providerId, filterAircraftIds) {
    return (
      <thead>
        <tr>
          <th width="20%" className="border-top-0">
            Chargeable
          </th>
          <th width="10%" className="text-end border-top-0">
            Total
          </th>
          {this.getSelectableAircrafts(filterAircraftIds).map((model) => {
            const { id, registration_abbreviated: registrationAbbreviated } = model;
            return (
              <th key={providerId + id} className="text-end border-top-0">
                <Button
                  style={{ paddingRight: 0 }}
                  data-id={id}
                  variant="link"
                  size="sm"
                  onClick={this.handleNavAircraftFlightRecord}
                >
                  {registrationAbbreviated}
                </Button>
              </th>
            );
          })}
          <th className="text-end border-top-0">Complete</th>
          <th className="text-end border-top-0">Invoiced</th>
          <th className="text-end border-top-0">Reports</th>
        </tr>
      </thead>
    );
  }

  renderFooterRow(providerFullName, totals, filterAircraftIds) {
    return (
      <tfoot>
        <tr>
          <th>{`Totals for ${providerFullName}`}</th>
          <th className="text-end">{this.renderFixed(totals.total)}</th>
          {this.getSelectableAircrafts(filterAircraftIds).map((model) => {
            const aircraftId = model.id;
            return (
              <td key={providerFullName + aircraftId} className="text-end">
                {this.renderFixed(get(totals, ['aircrafts', aircraftId]))}
              </td>
            );
          })}
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </tfoot>
    );
  }

  renderGrandTotalFooterRow(totals) {
    return (
      <tfoot>
        <tr>
          <th>&nbsp;</th>
          <th className="text-end">Total</th>
          {this.props.aircraftListQuery.data.map((model) => {
            const aircraftregistrationAbbreviated = model.registration_abbreviated;
            return (
              <th key={model.id} className="text-end">
                {aircraftregistrationAbbreviated}
              </th>
            );
          })}
        </tr>
        <tr>
          <th>Totals</th>
          <td className="text-end">{this.renderFixed(totals.total)}</td>
          {this.props.aircraftListQuery.data.map((model) => {
            const aircraftId = model.id;
            return (
              <td key={aircraftId} className="text-end">
                {this.renderFixed(get(totals, ['aircrafts', aircraftId]))}
              </td>
            );
          })}
        </tr>
      </tfoot>
    );
  }

  renderProviders() {
    const grandTotals = { total: 0 };
    return (
      <>
        {this.props.bookingFlightTimeForMonthByProviderQuery.data
          .filter((data) => {
            if (
              this.state.filterProviderId &&
              data.provider_id !== this.state.filterProviderId
            ) {
              return false;
            }
            return true;
          })
          .sort((a, b) =>
            b.provider_id === this.props.currentTenantId
              ? 1
              : a.provider_full_name.localeCompare(b.provider_full_name)
          )
          .map((data) => {
            const { id, bookings, provider_full_name: providerFullName } = data;
            // const totals = {
            //   total: 0,
            // };
            const filterAircraftIds = uniq(bookings.map((b) => b.aircraft_id));
            const totals = bookings.reduce(
              (accum, booking) => {
                const {
                  aircraft_id: aircraftId,
                  chargeable_id: chargeableId,
                  chargeable_flight_time: chargeableFlightTime,
                  has_invoice_reference: hasInvoiceReference,
                  is_complete: isComplete,
                } = booking;
                set(accum, ['total'], get(accum, ['total'], 0) + chargeableFlightTime);
                set(
                  accum,
                  ['aircrafts', aircraftId],
                  get(accum, ['aircrafts', aircraftId], 0) + chargeableFlightTime
                );
                set(
                  accum,
                  ['chargeables', chargeableId, 'total'],
                  get(accum, ['chargeables', chargeableId, 'total'], 0) +
                    chargeableFlightTime
                );
                set(
                  accum,
                  ['chargeables', chargeableId, aircraftId],
                  get(accum, ['chargeables', chargeableId, aircraftId], 0) +
                    chargeableFlightTime
                );
                set(
                  accum,
                  ['chargeables', chargeableId, 'invoiced'],
                  [
                    ...get(accum, ['chargeables', chargeableId, 'invoiced'], []),
                    hasInvoiceReference,
                  ]
                );
                set(
                  accum,
                  ['chargeables', chargeableId, 'completed'],
                  [
                    ...get(accum, ['chargeables', chargeableId, 'completed'], []),
                    isComplete,
                  ]
                );
                grandTotals.total += chargeableFlightTime;
                set(
                  grandTotals,
                  ['aircrafts', aircraftId],
                  get(grandTotals, ['aircrafts', aircraftId], 0) + chargeableFlightTime
                );
                return accum;
              },
              {
                total: 0,
              }
            );
            return (
              <Row key={id} className="mb-4" xs={1}>
                <Col>
                  <h4>{providerFullName}</h4>
                </Col>
                <Col className="mb-4">
                  <Table striped size="sm">
                    {this.renderHeaderRow(id, filterAircraftIds)}
                    {this.renderChargeables(bookings, totals, filterAircraftIds)}
                    {this.renderFooterRow(providerFullName, totals, filterAircraftIds)}
                  </Table>
                </Col>
              </Row>
            );
          })}
        <Row>
          <Col>
            <Table striped size="sm">
              {this.renderGrandTotalFooterRow(grandTotals)}
            </Table>
          </Col>
        </Row>
      </>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <>
        <Row className="my-3">
          <Col className="d-flex justify-content-between align-items-start">
            <ReportHeader
              title="Flight Hour Matrix"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
            <Button
              variant="primary"
              data-report-name="booking_flight_time_for_month_by_provider"
              onClick={this.getExport}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
          />
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'filterProviderId',
              value: this.state.filterProviderId,
              onChange: this.handleDisplayProviderIdChange,
            }}
            asElement="select"
            selectOptions={this.props.providerListQuery.data.filter(
              (model) => this.state.filterProviderIds.indexOf(model.id) > -1
            )}
            optionKey="fullName"
            defaultSelectOptionName="All"
          />
        </Row>
        {this.renderProviders()}
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentTenantId: state.currentTenant.id,
    currentSettingsReportProviderId: state.currentSettings.reportProviderId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationFailure,
    mutationSet,
  }),
  graphql(contactListQuery, {
    name: 'providerListQuery',
    options: { variables: { role: 'provider' } },
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(bookingFlightTimeForMonthByProviderQuery, {
    name: 'bookingFlightTimeForMonthByProviderQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportProviderFlightHour);
