import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';

import compact from 'lodash.compact';
import debounce from 'lodash.debounce';
import groupBy from 'lodash.groupby';

import { currentSettingsSet } from '../actions/current_setting_actions';

import InputField from '../components/form/input_field';
import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import Glyphicon from '../components/glyphicon';
import ReportHeader from '../components/report_header';

import { queriesReady } from '../lib/utils';

import contactListQuery from '../queries/contact_list_query';
import pilotDutyRecordListQuery from '../queries/pilot_duty_record_list_query';

class ReportPilotDutyRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPilotId: this.props.currentSettingsReportPilotId,
      filterPilotIds: [],
    };
    this.handleDisplayPilotIdChange = this.handleDisplayPilotIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.pilotId) {
      this.handleDisplayPilotIdChange({
        target: {
          value: parseInt(this.props.params.pilotId, 10),
        },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce(() => {
      this.props.pilotDutyRecordListQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterPilotIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterPilotIds = nextProps.pilotDutyRecordListQuery.data.map(
        (data) => data.pilot_id
      );
    }
    this.setState({
      filterPilotIds,
    });
    let filterPilotId = nextProps.currentSettingsReportPilotId;
    if (filterPilotId && this.isLoaded(nextProps)) {
      if (filterPilotIds.length > 0 && filterPilotIds.indexOf(filterPilotId) === -1) {
        filterPilotId = '';
      }
    }
    if (filterPilotId !== this.props.currentSettingsReportPilotId) {
      this.handleDisplayPilotIdChange({ target: { value: filterPilotId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.pilotDutyRecordListQuery, testProps.pilotListQuery);
  }

  handleDisplayPilotIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterPilotId: value,
    });
    this.props.currentSettingsSet({
      reportPilotId: value,
    });
  }

  renderBookingCell = (bookings) => (
    <span style={{ display: 'block' }}>
      {bookings.map((booking) => {
        const { flightSegmentSummaries } = booking;
        return flightSegmentSummaries.map((flightSegmentSummary) => {
          const { id: fsId, start_at: startAt } = flightSegmentSummary;
          return <span key={fsId}>{startAt}&nbsp;|&nbsp;</span>;
        });
      })}
    </span>
  );

  renderRow = (dutyRecord) => {
    const {
      id,
      duty_start_at: dutyStartAt,
      duty_end_at: dutyEndAt,
      rest_start_at: restStartAt,
      rest_end_at: restEndAt,
      pilotBookings,
      copilotBookings,
    } = dutyRecord;

    return (
      <tr key={id}>
        <td>
          <LinkContainer to={`/flights/${id}/edit`}>
            <Button variant="link" size="sm" className="p-0 text-start">
              {`#${id}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{moment(dutyStartAt).format('DD/MM/YY')}</td>
        <td>{moment(dutyStartAt).format('HH:mm')}</td>
        <td>{restStartAt ? moment(restStartAt).format('HH:mm') : '-'}</td>
        <td>{restEndAt ? moment(restEndAt).format('HH:mm') : '-'}</td>
        <td>{moment(dutyEndAt).format('HH:mm')}</td>
        <td>{this.renderBookingCell(compact([...pilotBookings, ...copilotBookings]))}</td>
      </tr>
    );
  };

  renderRows(dutyRecords) {
    return <tbody>{dutyRecords.map((dutyRecord) => this.renderRow(dutyRecord))}</tbody>;
  }

  renderHeaderRow = () => (
    <thead>
      <tr>
        <th>Id</th>
        <th>Duty Date</th>
        <th>Duty Start</th>
        <th>Rest Start</th>
        <th>Rest End</th>
        <th>Duty End</th>
        <th width="50%">Bookings</th>
      </tr>
    </thead>
  );

  renderPilots() {
    const pilotDutyRecords = groupBy(
      this.props.pilotDutyRecordListQuery.data.filter((data) => {
        if (this.state.filterPilotId && data.pilot_id !== this.state.filterPilotId) {
          return false;
        }
        return true;
      }),
      'pilot_id'
    );
    return (
      <Row>
        <Col xs={12}>
          {Object.keys(pilotDutyRecords).map((pilotId) => {
            const pilot = this.props.pilotListQuery.data.find(
              (model) => model.id === parseInt(pilotId, 10)
            );
            if (pilot) {
              const pilotFullName = pilot.fullName;
              const records = pilotDutyRecords[pilotId];
              return (
                <Row key={pilotId} className="mb-2">
                  <Col xs={12}>
                    <h4>{pilotFullName}</h4>
                  </Col>
                  <Col xs={12}>
                    <Table striped>
                      {this.renderHeaderRow()}
                      {this.renderRows(records)}
                    </Table>
                  </Col>
                </Row>
              );
            }
            return undefined;
          })}
        </Col>
      </Row>
    );
  }

  renderPilotFilter() {
    const dataPilots = this.props.pilotListQuery.data.filter(
      (model) => this.state.filterPilotIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterPilotId',
          value: this.state.filterPilotId,
          onChange: this.handleDisplayPilotIdChange,
        }}
        asElement="select"
        selectOptions={dataPilots}
        optionKey="fullName"
        defaultSelectOptionName="All"
      />
    );
  }

  render() {
    if (this.isLoading()) {
      return <Loader />;
    }
    return (
      <>
        <Row className="my-3">
          <Col>
            <ReportHeader
              title="Pilot Duty Records Audit"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
          />
          {this.renderPilotFilter()}
        </Row>
        {this.renderPilots()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportPilotId: state.currentSettings.reportPilotId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
  };
}

export default compose(
  connect(mapStateToProps, { currentSettingsSet }),
  graphql(contactListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(pilotDutyRecordListQuery, {
    name: 'pilotDutyRecordListQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportPilotDutyRecord);
