import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash.get';
import pick from 'lodash.pick';

import FlightSignoffFormLandingChargeFields from './flight_signoff_form_landing_charge_fields';

class FlightSignoffFormLandingCharges extends Component {
  render() {
    return this.props.flightSegments.fields.map((field) => {
      const values = get(this.props, `formValues.${field}`, {});
      if (!values._destroy) {
        if (values.end_location_landing_fee) {
          const fields = [
            `${field}.end_location_landing_fee`,
            `${field}.oncharge_end_location_landing_fee`,
          ];
          return (
            <Fields
              key={values.id}
              parentField={field}
              names={fields}
              component={FlightSignoffFormLandingChargeFields}
              locationsDataSelector={this.props.locationsDataSelector}
              formValues={pick(values, [
                'end_location_id',
                'end_location_landing_fee',
                'oncharge_end_location_landing_fee',
              ])}
            />
          );
        }
      }
      return undefined;
    });
  }
}

export default FlightSignoffFormLandingCharges;
