import { Col, Row, Modal, Button, Form } from 'react-bootstrap';
import { Component } from 'react';

import get from 'lodash.get';

import { getSelectable } from '../lib/utils';

class AircraftFlightTypePilot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pilotIds: [],
    };
    this.handleEditCancel = this.handleEditCancel.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.handleMultipleCheckboxChange = this.handleMultipleCheckboxChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ pilotIds: nextProps.pilotIds });
  }

  handleEditSubmit() {
    if (this.props.handleEditSubmit) {
      this.props.handleEditSubmit(this.state.pilotIds);
    }
  }

  handleEditCancel() {
    if (this.props.handleEditCancel) {
      this.props.handleEditCancel();
    }
  }

  handleMultipleCheckboxChange(e) {
    const ids = [...this.state.pilotIds];
    const id = parseInt(e.target.value, 10);
    if (id && ids) {
      const index = ids.indexOf(id);
      if (e.target.checked) {
        if (index === -1) {
          this.setState({
            pilotIds: [id, ...ids],
          });
        }
      } else if (index > -1) {
        ids.splice(index, 1);
        this.setState({
          pilotIds: ids,
        });
      }
    }
  }

  getSelectablePilots() {
    const namedPilotsOnly = get(this.props, 'aircraft.named_pilots_only');
    const namedPilotIds = get(this.props, 'aircraft.namedPilots', []).map((np) => np.id);
    const selectablePilots = getSelectable(
      get(this.props, 'pilots', []),
      get(this.props, 'pilotIds'),
      'fullName',
      'rolePilot'
    );
    if (namedPilotsOnly) {
      return selectablePilots.filter((sp) => namedPilotIds.includes(sp.id));
    }
    return selectablePilots;
  }

  renderForm() {
    const namedPilotsOnly = get(this.props, 'aircraft.named_pilots_only');
    const selectablePilots = this.getSelectablePilots();
    return (
      <Form onSubmit={this.handleEditSubmit}>
        <Form.Group controlId="pilot_ids">
          <Form.Label>
            {namedPilotsOnly
              ? 'Authorized Pilots (select from aircraft named list only)'
              : 'Authorized Pilots (select from all pilots)'}
          </Form.Label>
          <Row>
            {selectablePilots.map((model) => {
              const { id, name } = model;
              return (
                <Col sm={4} key={id}>
                  <Form.Check
                    id={id}
                    type="checkbox"
                    value={id}
                    checked={this.state.pilotIds.indexOf(id) > -1}
                    onChange={this.handleMultipleCheckboxChange}
                    label={name}
                  />
                </Col>
              );
            })}
          </Row>
        </Form.Group>
      </Form>
    );
  }

  render() {
    const { isEditing, editTitle } = this.props;
    return (
      <Modal size="lg" show={isEditing} onHide={this.handleEditCancel} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{editTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderForm()}</Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="danger" onClick={this.handleEditCancel}>
            Cancel
          </Button>
          <Button type="button" variant="primary" onClick={this.handleEditSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AircraftFlightTypePilot;
