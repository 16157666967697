import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash.get';
import pick from 'lodash.pick';

import FlightSegmentLandingChargeFields from './flight_segment_landing_charge_fields';

class LandingCharges extends Component {
  renderLandingChargesList() {
    return (
      <>
        {this.props.flightSegments.fields.map((field) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const listLandingFee = get(this.props.locationsDataSelector, [
              values.end_location_id,
              'landingFee',
            ]);
            if (
              values.end_location_landing_fee ||
              values.end_location_landing_fee_original ||
              listLandingFee
            ) {
              const fields = [
                `${field}.end_location_landing_fee`,
                `${field}.oncharge_end_location_landing_fee`,
              ];
              return (
                <Fields
                  key={`booking-fs-lc-${field}`}
                  parentField={field}
                  names={fields}
                  component={FlightSegmentLandingChargeFields}
                  locationsDataSelector={this.props.locationsDataSelector}
                  formValues={pick(values, [
                    'end_location_id',
                    'end_location_landing_fee',
                    'oncharge_end_location_landing_fee',
                  ])}
                />
              );
            }
          }
          return undefined;
        })}
      </>
    );
  }

  render() {
    const chargesLength = get(
      this.props,
      'formValues.flight_segments_attributes',
      []
    ).filter((ff) => !ff._destroy).length;
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Landing Fees</strong>
          </p>
        </Col>
        {chargesLength > 0 && <Col sm={10}>{this.renderLandingChargesList()}</Col>}
        {chargesLength === 0 && (
          <Col sm={10}>
            <p className="small">No landing fees</p>
          </Col>
        )}
      </Row>
    );
  }
}

export default LandingCharges;
