import moment from 'moment';

import compact from 'lodash.compact';
import clone from 'lodash.clone';
import flatten from 'lodash.flatten';

import { kebabCase, sentenceCase } from 'change-case';
import { isCalendarType, isAdminComplete, isPilotComplete } from '../lib/bookingHelpers';

const getBookingEventClasses = (booking, isFlight) => {
  const {
    calendar_type: calendarType,
    status,
    end_at: endAt,
    tia_tog_profile: tiaTogProfile,
  } = booking;
  const classNames = [calendarType];
  if (tiaTogProfile && tiaTogProfile.length > 0) {
    classNames.push('profiled');
  }
  const adminComplete = isAdminComplete(booking);
  const pilotComplete = isPilotComplete(booking);
  if (adminComplete) {
    classNames.push('admin_complete');
  } else if (pilotComplete) {
    classNames.push('pilot_complete');
  } else if (!isFlight && moment(endAt).isBefore(moment())) {
    classNames.push('time_complete');
  } else {
    classNames.push(status);
  }
  return classNames;
};

const getBookingNonFlightEvent = (booking, calendarView) => {
  const {
    id,
    aircraft,
    start_at: startAt,
    end_at: endAt,
    calendar_type: calendarType,
    job_notes: jobNotes,
  } = booking;
  const { registration_abbreviated: registrationAbbreviated } = aircraft;
  const aircraftEvents = [
    {
      id: `${id}_aircraft_${registrationAbbreviated}`,
      title: `${registrationAbbreviated} ${sentenceCase(calendarType)}`,
      resourceId: kebabCase(registrationAbbreviated),
      start: startAt,
      end: endAt,
      classNames: getBookingEventClasses(booking, false),
      textColor: 'inherit',
      extendedProps: {
        eventType: 'Booking',
        bookingId: id,
        jobNotes,
        isFlight: false,
      },
    },
  ];
  if (calendarView !== 'resourceTimelineDay') {
    return compact(aircraftEvents).map((event) => {
      if (moment(event.end).dayOfYear() !== moment(event.start).dayOfYear()) {
        const allDayEvent = clone(event);
        allDayEvent.allDay = true;
        allDayEvent.extendedProps.start = moment(event.start).format();
        allDayEvent.extendedProps.end = moment(event.end).format();
        allDayEvent.start = moment(event.start).startOf('day').format();
        allDayEvent.end = moment(event.end).add(1, 'days').startOf('day').format();
        return allDayEvent;
      }
      return event;
    });
  }
  return compact(aircraftEvents);
};

const getBookingFlightEvent = (booking) => {
  const {
    id,
    aircraft,
    pilot,
    start_at: startAt,
    end_at: endAt,
    flightSegmentSummaries,
    chargeables,
    tia_tog_profile: tiaTogProfile,
  } = booking;

  const { registration_abbreviated: registrationAbbreviated } = aircraft;
  const chargeableFullNames = chargeables.map((c) => c.fullName).join(', ');
  const adminComplete = isAdminComplete(booking);

  if (pilot) {
    const { fullName: pilotFullName, employee_color: pilotEmployeeColor } = pilot;

    return [
      {
        id: `${id}_aircraft_${registrationAbbreviated}`,
        title: registrationAbbreviated,
        resourceId: kebabCase(registrationAbbreviated),
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, true),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          adminComplete,
          flightSegmentSummaries,
          tiaTogProfile,
          chargeableFullNames,
          contactFullName: pilotFullName,
          contactColor: pilotEmployeeColor || '#3a87ad',
          isFlight: true,
        },
      },
    ];
  }
  return [];
};

const getBookingEvent = (booking, currentSettingsBookingCalendarTypes, calendarView) => {
  let events = [];
  if (isCalendarType(currentSettingsBookingCalendarTypes.flight, booking)) {
    events = getBookingFlightEvent(booking);
  } else {
    // maintenance
    events = getBookingNonFlightEvent(booking, calendarView);
  }
  return compact(flatten(events));
};

const getEvents = ({
  bookings,
  currentSettingsBookingCollectionView,
  currentSettingsBookingCalendarTypes,
}) => {
  const bookingEvents = compact(
    flatten(
      bookings.map((booking) =>
        getBookingEvent(
          booking,
          currentSettingsBookingCalendarTypes,
          currentSettingsBookingCollectionView
        )
      )
    )
  );
  return bookingEvents;
};

export default getEvents;
