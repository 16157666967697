/* eslint-disable react/jsx-props-no-spreading */
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Component } from 'react';
import classNames from 'classnames';

import BlurOnlyFormControl from './blur_only_form_control';
import HelpBlock from './help_block';
import LabelBlock from './label_block';
import InvalidBlock from './invalid_block';
import { isInvalid } from './helpers';

class InputField extends Component {
  renderFormControl(invalid) {
    const {
      type: rootType,
      controlOnly,
      innerContentOnly,
      blurOnly,
      noTab,
      placeholder,
      input,
      input: { type: inputType },
      inputStyle,
      inputProps,
      asElement,
      defaultSelectOption,
      defaultSelectOptionName,
      selectOptions,
      optionKey,
      rows,
      plainText,
      readOnly,
      disabled,
      customOnFocus,
      customOnBlur,
      customOnChange,
      showValid,
      showInvalid,
      size,
    } = this.props;

    const type = rootType || inputType; // redux form || final form

    const onFocus = customOnFocus
      ? (e) => customOnFocus(input.name, input.onFocus, e)
      : input.onFocus;
    const onBlur = customOnBlur
      ? (e) => customOnBlur(input.name, input.onBlur, e)
      : input.onBlur;
    const onChange = customOnChange
      ? (e) => customOnChange(input.name, input.onChange, e)
      : input.onChange;

    const commonProps = {
      ...(type && { type }),
      ...(type !== 'file' && { value: input.value }),
      size,
      onFocus,
      ...((controlOnly || innerContentOnly) && { id: input.name }),
      ...(placeholder && { placeholder }),
      ...(asElement && { as: asElement }),
      ...(rows && { rows }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
      ...(inputProps && { ...inputProps }),
      ...(showInvalid && { isInvalid: invalid }),
      ...(showValid && { isValid: !invalid }),
      ...(readOnly && { readOnly }),
      ...(disabled && { disabled }),
    };

    if (plainText) {
      return (
        <Form.Control
          plaintext
          readOnly
          value={input.value}
          className={`form-control-${size}`}
          {...(inputStyle && { style: inputStyle })}
        />
      );
    }
    if (selectOptions) {
      return (
        <Form.Control {...commonProps} onChange={onChange} onBlur={onBlur}>
          {defaultSelectOption ? (
            <option key={0} value="">
              {defaultSelectOptionName}
            </option>
          ) : (
            ''
          )}
          {selectOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option[optionKey]}
            </option>
          ))}
        </Form.Control>
      );
    }
    if (blurOnly) {
      return <BlurOnlyFormControl {...commonProps} onBlur={onBlur} />;
    }

    return <Form.Control {...commonProps} onBlur={onBlur} onChange={onChange} />;
  }

  renderInnerContent() {
    const {
      meta,
      size,
      labelWidth,
      helpText,
      helpBlock,
      invalidBlock,
      preAddon: prepend,
      postAddon: append,
      children,
    } = this.props;

    const invalid = isInvalid(meta);

    return (
      <>
        {labelWidth === 0 && children && <LabelBlock size={size}>{children}</LabelBlock>}
        {prepend || append ? (
          <InputGroup size={size}>
            {prepend && <InputGroup.Text>{prepend}</InputGroup.Text>}
            {this.renderFormControl(invalid)}
            {append && <InputGroup.Text>{append}</InputGroup.Text>}
            {invalidBlock && <InvalidBlock meta={meta} helpText={helpText} />}
          </InputGroup>
        ) : (
          <>
            {this.renderFormControl(invalid)}
            {invalidBlock && <InvalidBlock meta={meta} helpText={helpText} />}
          </>
        )}
        {helpBlock && <HelpBlock meta={meta} helpText={helpText} />}
      </>
    );
  }

  render() {
    const {
      controlOnly,
      innerContentOnly,
      input,
      meta,
      groupStyle,
      groupClassName,
      labelWidth,
      inputWidth,
      size,
      children,
      innerContent,
      labelAlign,
      labelColClassName,
      inputColClassName,
    } = this.props;

    if (controlOnly) {
      const invalid = isInvalid(meta);
      return this.renderFormControl(invalid);
    }

    if (innerContentOnly) {
      return innerContent || this.renderInnerContent();
    }

    return (
      <Form.Group
        {...{ className: groupClassName ? classNames('mb-3', groupClassName) : 'mb-3' }}
        {...(input && input.name && { controlId: input.name })}
        {...(groupStyle && { style: groupStyle })}
        {...(labelWidth > 0 && { as: Row })}
        {...(labelWidth === 0 && inputWidth > 0 && { as: Col, sm: inputWidth })}
        {...(labelWidth === 0 && inputWidth === 0 && { as: Col, sm: 'auto' })}
      >
        {labelWidth > 0 ? (
          <>
            <Form.Label
              column={size || true}
              sm={labelWidth}
              style={{ textAlign: labelAlign }}
              {...(labelColClassName && { className: labelColClassName })}
            >
              {children}
            </Form.Label>
            <Col
              sm={inputWidth}
              {...(inputColClassName && { className: inputColClassName })}
            >
              {innerContent || this.renderInnerContent()}
            </Col>
          </>
        ) : (
          innerContent || this.renderInnerContent()
        )}
      </Form.Group>
    );
  }
}

InputField.defaultProps = {
  controlOnly: false,
  innerContentOnly: false,
  size: 'sm',
  labelWidth: 3,
  inputWidth: 9,
  defaultSelectOption: true,
  selectOptions: false,
  helpText: '',
  helpBlock: true,
  invalidBlock: true,
  plainText: false,
  showValid: false,
  showInvalid: true,
  optionKey: 'name',
  defaultSelectOptionName: '',
  labelAlign: 'right',
};

export default InputField;
