import { useState } from 'react';

import ReactDateTimeField from './form/react_date_time_field';

const EditableDateCell = (props) => {
  const { cell, handleBlur, handleChange, isDisabled, timeConstraints } = props;
  const {
    getValue,
    row: {
      index: rowIndex,
      original: { id: originalId },
    },
    column: {
      id: columnId,
      columnDef: { meta: columnMeta = {} },
    },
  } = cell;

  const { dateFormat, timeFormat } = columnMeta;
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  const onChange = (newValue) => {
    if (handleChange && timeConstraints) {
      setValue(handleChange(value, newValue));
    } else {
      setValue(newValue);
    }
  };

  const onClose = () => {
    if (value !== initialValue) {
      const payload = { id: originalId, [columnId]: value };
      handleBlur(payload);
    }
  };

  return (
    <ReactDateTimeField
      size="sm"
      input={{
        name: `${columnId}-${rowIndex}`,
        value,
        onChange,
        onClose,
      }}
      labelWidth={0}
      inputWidth={6}
      dateFormat={dateFormat || 'YYYY-MM-DD'}
      timeFormat={timeFormat || 'HH:mm:ss'}
      closeOnSelect
      initialViewMode="time"
      {...(timeConstraints && {
        timeConstraints,
      })}
      disabled={isDisabled}
    />
  );
};

export default EditableDateCell;
