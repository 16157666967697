import { useEffect, useMemo } from 'react';

import defaultTo from 'lodash.defaultto';

import CheckboxInputField from '../form/checkbox_input_field';
import { roundUp, convertFromTo } from '../../lib/convert_units';

const BookingCaaWeightLoadingIncludedInputField = (props) => {
  const {
    flightTypes,
    formValues: {
      flight_type_id: origFlightTypeId,
      booking_weight_unit: bookingWeightUnit,
    },
    booking_caa_weight_loading: { input: bookingCaaWeightLoadingInput },
    booking_caa_weight_loading_included: {
      input: bookingCaaWeightLoadingIncludedInput,
      meta: bookingCaaWeightLoadingIncludedMeta,
    },
    booking_caa_weight_loading_included_adjusted: {
      input: bookingCaaWeightLoadingIncludedAdjustedInput,
    },
  } = props;

  const flightType = useMemo(() => {
    let newFlightType;
    if (origFlightTypeId && flightTypes.length > 0) {
      const flightTypeId = defaultTo(parseInt(origFlightTypeId, 10), '');
      newFlightType = flightTypes.find((ft) => ft.id === flightTypeId);
    }
    return newFlightType;
  }, [origFlightTypeId, flightTypes]);

  useEffect(() => {
    // console.log({
    //   loading: bookingCaaWeightLoadingInput.value,
    //   included: bookingCaaWeightLoadingIncludedInput.value,
    //   adjusted: bookingCaaWeightLoadingIncludedAdjustedInput.value,
    // });
    if (!origFlightTypeId) {
      // clear if no flight_type
      bookingCaaWeightLoadingInput.onChange(0);
      bookingCaaWeightLoadingIncludedInput.onChange(false);
      bookingCaaWeightLoadingIncludedAdjustedInput.onChange(false);
    } else if (flightType && bookingWeightUnit) {
      const {
        caa_weight_loading: flightTypeCaaWeightLoading,
        flight_type_weight_unit: flightTypeWeightUnit,
      } = flightType;
      if (flightTypeCaaWeightLoading === 0) {
        // clear if loading is 0 for flight type
        bookingCaaWeightLoadingInput.onChange(0);
        bookingCaaWeightLoadingIncludedInput.onChange(false);
        bookingCaaWeightLoadingIncludedAdjustedInput.onChange(false);
      } else if (!bookingCaaWeightLoadingInput.value) {
        // set if loading is > 0 for flight type
        const caaWeightLoading = convertFromTo(
          flightTypeCaaWeightLoading,
          flightTypeWeightUnit,
          bookingWeightUnit
        );
        bookingCaaWeightLoadingInput.onChange(roundUp(caaWeightLoading));
      } else if (
        bookingCaaWeightLoadingInput.value &&
        !bookingCaaWeightLoadingIncludedInput.value &&
        !bookingCaaWeightLoadingIncludedAdjustedInput.value
      ) {
        // set included boolean if loading is > 0 for booking
        // but only if it hasn't been adjusted already
        bookingCaaWeightLoadingIncludedInput.onChange(true);
        bookingCaaWeightLoadingIncludedAdjustedInput.onChange(true);
      }
    }
  }, [
    origFlightTypeId,
    flightType,
    bookingWeightUnit,
    bookingCaaWeightLoadingInput,
    bookingCaaWeightLoadingIncludedInput,
    bookingCaaWeightLoadingIncludedAdjustedInput,
  ]);

  const getLabel = () => {
    const { value } = bookingCaaWeightLoadingInput;
    return `Include CAA weight loading of ${value}${bookingWeightUnit || ''}`;
  };

  return (
    <CheckboxInputField
      input={bookingCaaWeightLoadingIncludedInput}
      meta={bookingCaaWeightLoadingIncludedMeta}
      labelWidth={4}
      inputWidth={8}
      inlineLabel
      disabled={bookingCaaWeightLoadingInput.value === 0}
    >
      {getLabel()}
    </CheckboxInputField>
  );
};

export default BookingCaaWeightLoadingIncludedInputField;
