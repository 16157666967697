/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { capitalCase, snakeCase, noCase, kebabCase } from 'change-case';
import pluralize from 'pluralize';
import { Col, Row, Button, ButtonGroup, Table, Pagination, Form } from 'react-bootstrap';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  flexRender,
  getSortedRowModel,
  createColumnHelper,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
} from '@tanstack/react-table';

import get from 'lodash.get';

import { StringFilter, DropdownFilter } from './react_table_filters';
import Confirm from './confirm';
import { currentSettingsSet } from '../actions/current_setting_actions';

const ReactTable = (props) => {
  const {
    parentColumns,
    data,
    rootName,
    initialStateSorting,
    // initialStateColumnFilters,
    doShow = true,
    doEdit = true,
    doEditNew = false,
    handleDelete = false,
    handleActivate = false,
    handleDeactivate = false,
    hideActions = false,
    hiddenColumns,
  } = props;

  const [columnVisibility, setColumnVisibility] = useState(
    hiddenColumns && hiddenColumns.reduce((cols, col) => ({ ...cols, [col]: false }), {})
  );
  const [gotoPage, setGotoPage] = useState(1);
  const dispatch = useDispatch();
  const listColumnFilters = useSelector(
    (state) => state.currentSettings.listColumnFilters
  );
  const listPagination = useSelector((state) => state.currentSettings.listPagination);
  const navigate = useNavigate();

  const setListColumnFilters = useCallback(
    (columnFiltersUpdateFn) => {
      const newColumnFilters = columnFiltersUpdateFn(listColumnFilters[rootName]);
      dispatch(
        currentSettingsSet({
          listColumnFilters: {
            ...listColumnFilters,
            [rootName]: newColumnFilters,
          },
        })
      );
    },
    [rootName, listColumnFilters, dispatch]
  );

  const setListPagination = useCallback(
    (paginationUpdateFn) => {
      const newPagination = paginationUpdateFn(listPagination[rootName]);
      setGotoPage(newPagination.pageIndex + 1);
      dispatch(
        currentSettingsSet({
          listPagination: {
            ...listPagination,
            [rootName]: newPagination,
          },
        })
      );
    },
    [rootName, listPagination, dispatch]
  );

  const handleShow = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-id');
      pluralize.addIrregularRule('aircraft', 'aircrafts');
      navigate(`/${snakeCase(pluralize.plural(rootName))}/${id}`);
    },
    [navigate, rootName]
  );

  const handleEdit = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-id');
      pluralize.addIrregularRule('aircraft', 'aircrafts');
      navigate(`/${snakeCase(pluralize.plural(rootName))}/${id}/edit`);
    },
    [navigate, rootName]
  );

  // help with form conversions to rff
  const handleEditNew = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-id');
      // pluralize.addIrregularRule('aircraft', 'aircrafts');
      navigate(`/${snakeCase(pluralize.plural(rootName))}_new/${id}/edit`);
    },
    [navigate, rootName]
  );

  const renderActionCell = useCallback(
    (cell) => {
      const id = cell.row.getValue('id');
      return (
        <ButtonGroup key={id}>
          {doShow && (
            <Button variant="link" size="sm" data-id={id} onClick={handleShow}>
              show
            </Button>
          )}
          {doEdit && (
            <Button variant="link" size="sm" data-id={id} onClick={handleEdit}>
              edit
            </Button>
          )}
          {doEditNew && (
            <Button variant="link" size="sm" data-id={id} onClick={handleEditNew}>
              editnew
            </Button>
          )}
          {handleDelete && (
            <Confirm
              dataId={id}
              onConfirm={handleDelete}
              title={`Delete ${capitalCase(rootName)}`}
              body={`Are you sure you want to delete this ${noCase(rootName)}`}
              confirmText="Confirm"
            >
              <Button variant="link" size="sm">
                delete
              </Button>
            </Confirm>
          )}
          {handleActivate &&
            cell.row.getValue('activeActivationState') === 'inactive' && (
              <Confirm
                dataId={id}
                onConfirm={handleActivate}
                title={`Activate ${capitalCase(rootName)}`}
                body={`Are you sure you want to activate this ${noCase(rootName)}`}
                confirmText="Confirm"
              >
                <Button variant="link" size="sm">
                  activate
                </Button>
              </Confirm>
            )}
          {handleDeactivate &&
            cell.row.getValue('activeActivationState') === 'active' && (
              <Confirm
                dataId={id}
                onConfirm={handleDeactivate}
                title={`Deactivate ${capitalCase(rootName)}`}
                body={`Are you sure you want to deactivate this ${noCase(rootName)}`}
                confirmText="Confirm"
              >
                <Button variant="link" size="sm">
                  deactivate
                </Button>
              </Confirm>
            )}
        </ButtonGroup>
      );
    },
    [
      rootName,
      doEdit,
      doEditNew,
      doShow,
      handleDelete,
      handleActivate,
      handleDeactivate,
      handleShow,
      handleEdit,
      handleEditNew,
    ]
  );

  const columns = useMemo(
    () => [
      ...parentColumns,
      ...(hideActions
        ? []
        : [
            createColumnHelper().display({
              id: 'actions',
              header: 'Actions',
              cell: renderActionCell,
            }),
          ]),
    ],
    [hideActions, renderActionCell, parentColumns]
  );

  const reactTable = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    initialState: {
      ...(initialStateSorting && { sorting: initialStateSorting }),
    },
    state: {
      pagination: listPagination[rootName],
      columnFilters: listColumnFilters[rootName],
      columnVisibility,
    },
    onColumnFiltersChange: setListColumnFilters,
    onPaginationChange: setListPagination,
    onColumnVisibilityChange: setColumnVisibility,
  });

  return (
    <Row>
      <Col>
        <Table striped bordered>
          <thead>
            {reactTable.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const headerClassName = get(
                    header,
                    'column.columnDef.headerClassName',
                    'text-nowrap'
                  );
                  const filterType = get(header, 'column.columnDef.filterType');
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={headerClassName}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                          {header.column.getCanFilter() && filterType === 'dropdown' && (
                            <div>
                              <DropdownFilter column={header.column} table={reactTable} />
                            </div>
                          )}
                          {header.column.getCanFilter() && !filterType && (
                            <div>
                              <StringFilter column={header.column} />
                            </div>
                          )}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {reactTable.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  let cellClassName = get(cell, 'column.columnDef.cellClassName');
                  const getCellClassName = get(cell, 'column.columnDef.getCellClassName');
                  if (getCellClassName) {
                    cellClassName = getCellClassName(cell.getValue());
                  }
                  return (
                    <td
                      key={cell.id}
                      {...(cellClassName && { className: cellClassName })}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col md="6">
            <span className="mx-2">
              Page{' '}
              <strong>
                {reactTable.getState().pagination.pageIndex + 1} of{' '}
                {reactTable.getPageCount()}
              </strong>
            </span>
            <span className="ms-3 me-2">Show:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={reactTable.getState().pagination.pageSize}
              onChange={(e) => {
                reactTable.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Form.Select>

            <span className="ms-3 me-2">Go to page:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              min={1}
              max={reactTable.getPageCount()}
              value={gotoPage}
              onChange={(e) => {
                let pageNumber = e.target.value ? Number(e.target.value) : '';
                if (Number.isInteger(pageNumber)) {
                  pageNumber = Math.min(reactTable.getPageCount(), pageNumber);
                  pageNumber = Math.max(1, pageNumber);
                }
                setGotoPage(pageNumber);
              }}
              onBlur={(e) => {
                let pageNumber = e.target.value ? Number(e.target.value) : 1;
                pageNumber = Math.min(reactTable.getPageCount(), pageNumber);
                pageNumber = Math.max(1, pageNumber);
                reactTable.setPageIndex(pageNumber - 1);
              }}
              style={{ width: '75px' }}
            />
          </Col>
          <Col md="6">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => reactTable.setPageIndex(0)}
                disabled={!reactTable.getCanPreviousPage()}
              />
              <Pagination.Prev
                onClick={() => reactTable.previousPage()}
                disabled={!reactTable.getCanPreviousPage()}
              />
              <Pagination.Next
                onClick={() => reactTable.nextPage()}
                disabled={!reactTable.getCanNextPage()}
              />
              <Pagination.Last
                onClick={() => reactTable.setPageIndex(reactTable.getPageCount() - 1)}
                disabled={!reactTable.getCanNextPage()}
              />
            </Pagination>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ReactTable;
