/* eslint-disable react/prefer-stateless-function, react/jsx-props-no-spreading */
import { Component } from 'react';

import get from 'lodash.get';

import InputField from '../form/input_field';

class TankConfigurationFormAircraftTankTankConfigurationFields extends Component {
  render() {
    const { aircraftTypeId, aircraftTypes, parentField } = this.props;

    const {
      aircraft_tank_id: { input: aircraftTankIdInput },
      position: { input: positionInput, meta: positionMeta },
    } = get(this.props, parentField);

    const aircraftType = aircraftTypes.find(
      (at) => at.id === parseInt(aircraftTypeId, 10)
    );

    const { aircraftTanks } = aircraftType;
    const { name } = aircraftTanks.find(
      (as) => as.id === parseInt(aircraftTankIdInput.value, 10)
    );
    const fieldsetLegend = name || 'New aircraft tank configuration';
    return (
      <fieldset className="border rounded-3 p-3">
        <legend className="float-none w-auto px-3 fs-6">{fieldsetLegend}</legend>
        <InputField
          input={positionInput}
          meta={positionMeta}
          type="text"
          size={this.props.size}
          labelWidth={this.props.labelWidth}
          inputWidth={this.props.inputWidth}
        >
          Position
        </InputField>
      </fieldset>
    );
  }
}

TankConfigurationFormAircraftTankTankConfigurationFields.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 3,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  noTab: true,
};

export default TankConfigurationFormAircraftTankTankConfigurationFields;
