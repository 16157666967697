import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSet, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import bookingDetailForMonthByChargeableQuery from '../queries/booking_detail_for_month_by_chargeable_query';
import contactListQuery from '../queries/contact_list_query';

import { queriesReady, getExport } from '../lib/utils';

moment.updateLocale('en-nz');

class ReportBookingChargeable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterChargeableId: this.props.currentSettingsReportChargeableId,
      filterChargeableIds: [],
    };
    this._handleDisplayChargeableIdChange =
      this._handleDisplayChargeableIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.chargeableId) {
      this._handleDisplayChargeableIdChange({
        target: { value: this.props.params.chargeableId },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.bookingDetailForMonthByChargeableQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterChargeableIds } = this.state;
    if (this.isLoaded(nextProps)) {
      filterChargeableIds = nextProps.bookingDetailForMonthByChargeableQuery.data.map(
        (data) => data.chargeable_id
      );
    }
    this.setState({
      filterChargeableIds,
    });
    let filterChargeableId = nextProps.currentSettingsReportChargeableId;
    if (filterChargeableId && this.isLoaded(nextProps)) {
      if (
        filterChargeableIds.length > 0 &&
        filterChargeableIds.indexOf(filterChargeableId) === -1
      ) {
        filterChargeableId = '';
      }
    }
    if (filterChargeableId !== this.props.currentSettingsReportChargeableId) {
      this._handleDisplayChargeableIdChange({ target: { value: filterChargeableId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.bookingDetailForMonthByChargeableQuery,
      props.chargeableListQuery
    );
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      chargeableId: this.props.currentSettingsReportChargeableId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  _handleDisplayChargeableIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterChargeableId: value,
    });
    this.props.currentSettingsSet({
      reportChargeableId: value,
    });
  }

  _renderFixed(value, symbol = '', precision = 2) {
    return value ? symbol + value.toFixed(precision) : '-';
  }

  _renderLandingFees(landingFees) {
    if (landingFees) {
      return (
        <span>
          {landingFees
            .filter((lf) => lf.oncharge)
            .map((lf) => {
              const fee = `$${lf.fee}`;
              return (
                <span
                  key={lf.id}
                  style={{ display: 'block' }}
                >{`${lf.name} Landing Fee - ${fee}`}</span>
              );
            })}
        </span>
      );
    }
  }

  _renderPilotFlightExpenses(pilotExpenses) {
    if (pilotExpenses) {
      return (
        <span>
          {pilotExpenses
            .filter((pe) => pe.oncharge)
            .map((pe) => {
              const fee = `$${pe.fee}`;
              return (
                <span
                  key={pe.id}
                  style={{ display: 'block' }}
                >{`Pilot Expense - ${pe.note} - ${fee}`}</span>
              );
            })}
        </span>
      );
    }
  }

  _renderInvoiceRow(invoiceReferenceString, chargeableFullName) {
    return (
      <tr>
        <th
          colSpan={8}
          className="border-top-0"
        >{`${chargeableFullName} bookings and expenses on Invoice #${invoiceReferenceString}`}</th>
      </tr>
    );
  }

  _renderHeaderRow() {
    return (
      <tr>
        <th className="border-top-0">Ref#</th>
        <th className="border-top-0">Date</th>
        <th className="border-top-0">Pilot</th>
        <th className="border-top-0">Registration</th>
        <th className="border-top-0" width="30%">
          Route Details
        </th>
        <th className="border-top-0">Exp%</th>
        <th className="border-top-0">Time</th>
        <th className="border-top-0" width="30%">
          Chargeable Expenses
        </th>
        <th className="border-top-0">Total</th>
      </tr>
    );
  }

  _renderFlightSegmentSummary(flightSegmentSummary) {
    const {
      start_at: startAt,
      start_location: startLocation,
      end_location: endLocation,
      pax,
    } = flightSegmentSummary;
    const summary = `${startAt} ${startLocation} - ${endLocation}`;
    return (
      <span>
        {summary} {pax ? ' | ' : ''} {pax}
      </span>
    );
  }

  _renderRow(booking) {
    const {
      id,
      booking_id: bookingId,
      aircraft_registration_abbreviated: aircraftRegistrationAbbreviated,
      reference,
      start_at_s: startAtString,
      pilot_display_name: pilotDisplayName,
      copilot_display_name: copilotDisplayName,
      flight_segment_summaries: flightSegmentSummaries,
      chargeable_percentage: chargeablePercentage,
      chargeable_flight_time: chargeableFlightTime,
      pilot_expenses: pilotExpenses,
      landing_fees: landingFees,
      expense_total: expenseTotal,
    } = booking;

    return (
      <tr key={id}>
        <td>
          <LinkContainer to={`/flights/${bookingId}/edit`} className="ps-0">
            <Button variant="link" size="sm" className="text-start p-0 m-0">
              {`#${reference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{startAtString}</td>
        <td>
          {[pilotDisplayName, copilotDisplayName].filter((name) => name).join(', ')}
        </td>
        <td>{aircraftRegistrationAbbreviated}</td>
        <td>
          {flightSegmentSummaries.map((flightSegmentSummary, index) => (
            <span key={index} style={{ display: 'block' }}>
              {this._renderFlightSegmentSummary(flightSegmentSummary)}
            </span>
          ))}
        </td>
        <td className="text-end">
          {chargeablePercentage === 1
            ? ''
            : this._renderFixed(chargeablePercentage * 100, '', 0)}
        </td>
        <td className="text-end">{this._renderFixed(chargeableFlightTime)}</td>
        <td>
          <span>
            {this._renderLandingFees(landingFees)}
            {this._renderPilotFlightExpenses(pilotExpenses)}
          </span>
        </td>
        <td className="text-end">{this._renderFixed(expenseTotal, '$')}</td>
      </tr>
    );
  }

  _renderRows(bookings) {
    return <tbody>{bookings.map((booking) => this._renderRow(booking))}</tbody>;
  }

  _renderFooterRow(
    invoiceReferenceString,
    chargeableFullName,
    chargeableFlightTime,
    expenseTotal
  ) {
    return (
      <tr>
        <th
          colSpan={6}
          style={{ width: '50%', textAlign: 'right' }}
        >{`Total ${chargeableFullName} Flight Time for Invoice #${invoiceReferenceString}:`}</th>
        <th colSpan={1} style={{ textAlign: 'left' }}>
          {this._renderFixed(chargeableFlightTime)}
        </th>
        <th
          colSpan={1}
          style={{ width: '50%', textAlign: 'right' }}
        >{`Total ${chargeableFullName} Expenses for Invoice #${invoiceReferenceString}:`}</th>
        <th colSpan={1} style={{ textAlign: 'right' }}>
          {this._renderFixed(expenseTotal, '$')}
        </th>
      </tr>
    );
  }

  _renderInvoices(data, chargeableFullName) {
    const groupedBookings = data.bookings.reduce((bookings, booking) => {
      bookings[booking.invoice_reference_s]
        ? (bookings[booking.invoice_reference_s] = [
            ...bookings[booking.invoice_reference_s],
            booking,
          ])
        : (bookings[booking.invoice_reference_s] = [booking]);
      return bookings;
    }, {});

    return Object.keys(groupedBookings).map((invoiceReferenceString) => {
      const invoiceBookings = groupedBookings[invoiceReferenceString];
      const chargeableFlightTime = invoiceBookings.reduce(
        (sum, booking) => sum + (booking.chargeable_flight_time || 0),
        0
      );
      const expenseTotal = invoiceBookings.reduce(
        (sum, booking) => sum + (booking.expense_total || 0),
        0
      );
      return (
        <Table key={data.id + invoiceReferenceString} striped className="mb-4" size="sm">
          <thead>
            {this._renderInvoiceRow(invoiceReferenceString, chargeableFullName)}
            {this._renderHeaderRow()}
          </thead>
          {this._renderRows(invoiceBookings)}
          <tfoot>
            {this._renderFooterRow(
              invoiceReferenceString,
              chargeableFullName,
              chargeableFlightTime,
              expenseTotal
            )}
          </tfoot>
        </Table>
      );
    });
  }

  _renderChargeables() {
    return this.props.bookingDetailForMonthByChargeableQuery.data
      .filter((data) => {
        if (
          this.state.filterChargeableId &&
          data.chargeable_id !== this.state.filterChargeableId
        ) {
          return false;
        }
        return true;
      })
      .map((data) => (
        <Row key={data.chargeable_id} className="mb-4" xs={1}>
          <Col>
            <h4>{data.chargeable_full_name}</h4>
          </Col>
          <Col>{this._renderInvoices(data, data.chargeable_full_name)}</Col>
        </Row>
      ));
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <>
        <Row className="my-3">
          <Col className="d-flex justify-content-between align-items-start">
            <ReportHeader
              title="Customer Invoice Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
            <Button
              variant="primary"
              data-report-name="booking_detail_for_month_by_chargeable_invoice"
              onClick={this.getExport}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
          />
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'filterChargeableId',
              value: this.state.filterChargeableId,
              onChange: this._handleDisplayChargeableIdChange,
            }}
            asElement="select"
            selectOptions={this.props.chargeableListQuery.data.filter(
              (model) => this.state.filterChargeableIds.indexOf(model.id) > -1
            )}
            optionKey="fullName"
            defaultSelectOptionName="All"
          />
        </Row>
        {this._renderChargeables()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsReportChargeableId: state.currentSettings.reportChargeableId,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationFailure,
    mutationSet,
  }),
  graphql(contactListQuery, {
    name: 'chargeableListQuery',
    options: { variables: { role: 'chargeable' } },
  }),
  graphql(bookingDetailForMonthByChargeableQuery, {
    name: 'bookingDetailForMonthByChargeableQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportBookingChargeable);
