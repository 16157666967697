import { Row, Col, Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { Component } from 'react';
import moment from 'moment';

import get from 'lodash.get';

import Confirm from '../confirm';

import Glyphicon from '../glyphicon';

moment.updateLocale('en-nz');

class FlightSegmentCardHeader extends Component {
  constructor(props) {
    super(props);
    this.handleCriticalClick = this.handleCriticalClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  handleCriticalClick(e) {
    e.stopPropagation();
    if (this.props.handleCriticalLocationModalClicked) {
      this.props.handleCriticalLocationModalClicked(this.props.index);
    }
  }

  handleDeleteClick(e) {
    e.stopPropagation();
    if (this.props.handleDeleteClicked) {
      this.props.handleDeleteClicked(this.props.index);
    }
  }

  handleAddClick(e) {
    e.stopPropagation();
    if (this.props.handleAddFlightSegmentModalClicked) {
      this.props.handleAddFlightSegmentModalClicked(this.props.index);
    }
  }

  styleAlert(alert) {
    const style = { color: '#777777' };
    if (alert) {
      style.color = 'red';
    }
    return style;
  }

  renderPanelAlerts() {
    const {
      index,
      flightSegmentCalculatedData: {
        flightSegmentPilotTakeOffFuelWeightUnderMinDispatchFuelWeight: underFuel,
        flightSegmentPilotTakeOffFuelOverMaximumTankCapacity: overFuel,
        flightSegmentLuggageWeightOverMaximumHoldCapacity: overHold,
        flightSegmentAllUpWeightOverMaximumWeight: overAircraft,
        flightSegmentOutOfBalance: outOfBalance,
      },
    } = this.props;
    const fuelTip = (
      <Tooltip id={`tooltip-fuel-${index}`}>
        {
          // eslint-disable-next-line no-nested-ternary
          underFuel
            ? 'Fuel alert - insufficient loaded fuel for this leg'
            : overFuel
              ? 'Fuel alert - insufficient capacity for entered take off fuel'
              : 'Fuel for leg'
        }
      </Tooltip>
    );
    const holdOverTip = (
      <Tooltip id={`tooltip-holdover-${index}`}>
        {overHold
          ? 'Hold alert - insufficient capacity for entered luggage weight'
          : 'Hold capacity for leg'}
      </Tooltip>
    );
    const aircraftOverTip = (
      <Tooltip id={`tooltip-holdover-${index}`}>
        {overAircraft
          ? 'Aircraft Weight alert - the aircraft is over its maximum all up weight'
          : 'Aircraft Weight for leg'}
      </Tooltip>
    );
    const aircraftBalanceTip = (
      <Tooltip id={`tooltip-outofbalance-${index}`}>
        {outOfBalance
          ? 'Aircraft Balance alert - the aircraft CoG is outside its allowed envelope'
          : 'Aircraft Balance for leg'}
      </Tooltip>
    );
    return (
      <Col xs="auto" style={{ padding: 0 }}>
        <OverlayTrigger key="0-under" placement="top" overlay={fuelTip}>
          <span>
            <Glyphicon style={this.styleAlert(underFuel || overFuel)} glyph="tint" />
          </span>
        </OverlayTrigger>
        <OverlayTrigger key="0-holdover" placement="top" overlay={holdOverTip}>
          <span>
            <Glyphicon style={this.styleAlert(overHold)} glyph="briefcase" />
          </span>
        </OverlayTrigger>
        <OverlayTrigger key="0-aircraftover" placement="top" overlay={aircraftOverTip}>
          <span>
            <Glyphicon style={this.styleAlert(overAircraft)} glyph="scale" />
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          key="0-aircraftbalance"
          placement="top"
          overlay={aircraftBalanceTip}
        >
          <span>
            <Glyphicon style={this.styleAlert(outOfBalance)} glyph="erase" />
          </span>
        </OverlayTrigger>
        &nbsp; | &nbsp;
      </Col>
    );
  }

  renderPanelTools() {
    const { index } = this.props;

    const criticalTip = (
      <Tooltip id={`tooltip-critical-${index}`}>Set critical start or end</Tooltip>
    );
    const deleteTip = <Tooltip id={`tooltip-delete-${index}`}>Delete leg</Tooltip>;
    const addTip = <Tooltip id={`tooltip-add-${index}`}>Add leg</Tooltip>;
    return (
      <Col xs="auto" style={{ padding: 0 }}>
        <OverlayTrigger key="1" placement="top" overlay={criticalTip}>
          <Button
            variant="link"
            style={{ color: '#777777', padding: '0' }}
            onClick={this.handleCriticalClick}
            tabIndex={-1}
          >
            <Glyphicon glyph="exclamation-sign" />
          </Button>
        </OverlayTrigger>
        <Confirm
          title="Delete Flight Segment"
          onConfirm={this.handleDeleteClick}
          body="Delete this flight segment?"
          confirmText="Confirm"
          style={{ color: '#777777', padding: '0' }}
          tip={deleteTip}
          stopPropagation
        >
          <Button variant="link" tabIndex={-1}>
            <Glyphicon glyph="bin" />
          </Button>
        </Confirm>
        <OverlayTrigger key="3" placement="top" overlay={addTip}>
          <Button
            variant="link"
            style={{ color: '#777777', padding: '0' }}
            onClick={this.handleAddClick}
            tabIndex={-1}
          >
            <Glyphicon glyph="plus" />
          </Button>
        </OverlayTrigger>
      </Col>
    );
  }

  renderPanelSeats() {
    const {
      bookingCopilotId,
      seat_assignments_attributes: seatAssignmentsAttributes = [],
    } = this.props;
    const passengerNames = seatAssignmentsAttributes
      .filter((sa) => {
        if (sa._destroy || !sa.seat_assignment_installed) {
          return false;
        }
        if (sa.seat_assignment_seat_type === 'pilot_only') {
          return false;
        }
        if (sa.seat_assignment_seat_type === 'copilot_only') {
          return false;
        }
        if (sa.seat_assignment_seat_type === 'copilot_or_pax' && bookingCopilotId) {
          return false;
        }
        return true;
      })
      .map((sa) => sa.pax_name)
      .filter((name) => !!name)
      .join(', ');
    if (passengerNames) {
      return (
        <Col
          xs="3"
          style={{
            padding: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {passengerNames}
        </Col>
      );
    }
    return undefined;
  }

  renderPanelPilot() {
    const { bookingPilotId, bookingAircraftId } = this.props;

    let bookingPilotDisplayName = '';
    let bookingAircraftRegistrationAbbreviated = '';

    if (bookingPilotId) {
      bookingPilotDisplayName = get(this.props.contactsDataSelector, [
        bookingPilotId,
        'fullName',
      ]);
    }
    if (bookingAircraftId) {
      bookingAircraftRegistrationAbbreviated = get(this.props.aircraftsDataSelector, [
        bookingAircraftId,
        'registrationAbbreviated',
      ]);
    }
    if (bookingPilotDisplayName && bookingAircraftRegistrationAbbreviated) {
      return (
        <Col xs="auto" style={{ marginLeft: 'auto', padding: 0 }}>
          {`${bookingAircraftRegistrationAbbreviated} - ${bookingPilotDisplayName}`}
          &nbsp; | &nbsp;
        </Col>
      );
    }
    if (bookingPilotDisplayName || bookingAircraftRegistrationAbbreviated) {
      return (
        <Col xs="auto" style={{ marginLeft: 'auto', padding: 0 }}>
          {bookingPilotDisplayName || bookingAircraftRegistrationAbbreviated} &nbsp; |
          &nbsp;
        </Col>
      );
    }
    return undefined;
  }

  renderPanelLocations() {
    const { start_location_id: startLocationId, end_location_id: endLocationId } =
      this.props;

    let startLocationLongName = '';
    if (startLocationId) {
      startLocationLongName = get(this.props.locationsDataSelector, [
        startLocationId,
        'longName',
      ]);
    }
    let endLocationLongName = '';
    if (endLocationId) {
      endLocationLongName = get(this.props.locationsDataSelector, [
        endLocationId,
        'longName',
      ]);
    }
    if (startLocationLongName || endLocationLongName) {
      return (
        <Col xs="auto" style={{ padding: 0 }}>
          {startLocationLongName || '?'}
          {' - '}
          {endLocationLongName || '?'}
          &nbsp; | &nbsp;
        </Col>
      );
    }
    return undefined;
  }

  render() {
    const {
      activeIndex,
      isXs,
      isMd,
      start_at: startAt,
      start_critical: startCritical,
    } = this.props;

    return (
      <Row style={{ flexWrap: 'nowrap' }}>
        {isXs && (
          <Col xs="auto" style={{ padding: 0 }}>{`Leg${parseInt(
            activeIndex + 1,
            10
          )}: `}</Col>
        )}
        {startAt && (
          <Col xs="auto" className="ps-1 pe-0">
            <span {...(startCritical && { style: { backgroundColor: 'yellow' } })}>
              {moment(startAt).format('HHmm')}
            </span>
            &nbsp; | &nbsp;
          </Col>
        )}
        {this.renderPanelLocations()}
        {this.renderPanelSeats()}
        {this.renderPanelPilot()}
        {this.renderPanelAlerts()}
        {this.renderPanelTools()}
      </Row>
    );
  }
}

export default FlightSegmentCardHeader;
