import { Row, Col, Button } from 'react-bootstrap';
import { FieldArray } from 'react-final-form-arrays';

import get from 'lodash.get';

import DocumentFields from './document_fields';
import Glyphicon from '../../../glyphicon';
import { documentDefaultValues } from '../../../../defaults';

const DocumentFieldArray = (props) => {
  const { check, labelWidth, inputWidth, size } = props;
  return (
    <FieldArray name="documents">
      {({ fields: arrayFields }) => {
        const { push, remove } = arrayFields;
        return (
          <Row>
            {arrayFields.map((arrayName, arrayIndex) => (
              <DocumentFields
                key={arrayName}
                arrayName={arrayName}
                arrayIndex={arrayIndex}
                remove={remove}
                document={get(check, arrayName, {})}
                labelWidth={labelWidth}
                inputWidth={inputWidth}
                size={size}
              />
            ))}
            <Col sm={{ span: inputWidth, offset: labelWidth }} className="mt-2">
              <Button
                size={size}
                type="button"
                variant="dark"
                onClick={() => push(documentDefaultValues)}
                className="ps-0"
              >
                <Glyphicon glyph="plus" />
                <span className="align-middle">Add Document</span>
              </Button>
            </Col>
          </Row>
        );
      }}
    </FieldArray>
  );
};

DocumentFieldArray.defaultProps = {
  labelWidth: 3,
  inputWidth: 9,
  size: 'sm',
};

export default DocumentFieldArray;
