import { useState, useMemo, useCallback } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Card, Col, Row, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import pick from 'lodash.pick';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import ReactTable from '../components/react_table';
import EditableDateCell from '../components/react_table_editable_date_cell';
import Glyphicon from '../components/glyphicon';

import { toastSuccess, toastError } from '../lib/action_helpers';
import { coerceInput, handleSubmitError } from '../lib/utils';

import pagePilotDutyRecordListQuery from '../queries/page_pilot_duty_record_list_query';
import pilotDutyRecordUpdateMutation from '../mutations/pilot_duty_record_update_mutation';

const PilotDutyRecordList = () => {
  const timeConstraintUnit = 'minutes';
  const timeConstraintQuantity = 5;
  const timeConstraintZeroingMin = 5;
  const timeConstraintZeroingMax = 55;

  const dispatch = useDispatch();
  const currentContact = useSelector((state) => state.currentContact);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const [startAt, setStartAt] = useState(moment().startOf('month').format());
  const [endAt, setEndAt] = useState(moment().endOf('month').format());
  const [pilotDutyRecordUpdate] = useMutation(pilotDutyRecordUpdateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pagePilotDutyRecordListQuery, {
    variables: {
      startAt,
      endAt,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () =>
      !pageLoading ||
      (pageLoading &&
        [NetworkStatus.refetch, NetworkStatus.setVariables].includes(pageNetworkStatus)),
    [pageLoading, pageNetworkStatus]
  );

  const pilotDutyRecords = useMemo(() => {
    if (currentContact?.id && pageData?.pilotDutyRecordList) {
      const {
        id: contactId,
        'manager?': manager,
        'office_admin?': officeAdmin,
        'pilot?': pilot,
      } = currentContact;
      return get(pageData, 'pilotDutyRecordList', []).filter((pdr) => {
        if (manager || officeAdmin) {
          return true;
        }
        if (pilot) {
          return pdr.pilot_id === contactId;
        }
        return false;
      });
    }
    return [];
  }, [pageData, currentContact]);

  const isManagerOrOfficeAdmin = useMemo(() => {
    if (currentContact?.id) {
      const { 'manager?': manager, 'office_admin?': officeAdmin } = currentContact;
      return manager || officeAdmin;
    }
    return false;
  }, [currentContact]);

  const onPrevClicked = useCallback(() => {
    setStartAt(moment(startAt).subtract(1, 'month').startOf('month').format());
    setEndAt(moment(endAt).subtract(1, 'month').endOf('month').format());
  }, [startAt, endAt]);

  const onNextClicked = useCallback(() => {
    setStartAt(moment(startAt).add(1, 'month').startOf('month').format());
    setEndAt(moment(endAt).add(1, 'month').endOf('month').format());
  }, [startAt, endAt]);

  const handlePilotDutyRecordChange = useCallback((currentValue, newValue) => {
    const unitQuantity = moment(newValue).get(timeConstraintUnit);
    const currentUnitQuantity = moment(currentValue).get(timeConstraintUnit);
    if (
      (currentUnitQuantity < timeConstraintZeroingMin &&
        unitQuantity > timeConstraintZeroingMax) ||
      (unitQuantity < timeConstraintZeroingMin &&
        currentUnitQuantity > timeConstraintZeroingMax)
    ) {
      return moment(newValue).set(timeConstraintUnit, 0).toISOString();
    }
    const remainder = unitQuantity % timeConstraintQuantity;
    if (remainder !== 0) {
      const timeForward = moment(currentValue).isBefore(newValue);
      if (timeForward) {
        return moment(newValue).subtract(remainder, timeConstraintUnit).toISOString();
      }
      return moment(newValue)
        .add(timeConstraintQuantity - remainder, timeConstraintUnit)
        .toISOString();
    }
    return newValue;
  }, []);

  const handlePilotDutyRecordUpdate = useCallback(
    async (data) => {
      const mutation = pilotDutyRecordUpdate;
      const mutationMessageAction = 'update';
      const submitData = coerceInput(cloneDeep(data));
      const { id } = submitData;
      const currentPilotDutyRecord = pilotDutyRecords.find((pdr) => pdr.id === id);
      const optimisticAttributes = pick(currentPilotDutyRecord, [
        'duty_start_at',
        'duty_end_at',
      ]);
      const mutationData = {
        variables: { id, input: submitData },
        optimisticResponse: {
          __typename: 'Mutation',
          pilotDutyRecordUpdate: {
            __typename: 'PilotDutyRecordType',
            ...optimisticAttributes,
            ...submitData,
          },
        },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await mutation(mutationData);
        toastSuccess(`Pilot Duty record ${mutationMessageAction} succeeded`);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
      return undefined;
    },
    [pilotDutyRecordUpdate, dispatch, pilotDutyRecords]
  );

  const renderEditableDateCell = (cell) => {
    let isDisabled = false;
    if (!isManagerOrOfficeAdmin) {
      const column = get(cell, ['column', 'id']);
      if (column === 'duty_start_at' || column === 'duty_end_at') {
        const currentDutyStartAt = get(cell, ['row', 'original', 'duty_start_at']);
        isDisabled = moment().isAfter(moment(currentDutyStartAt).add(48, 'hours'));
      }
    }
    return (
      <EditableDateCell
        cell={cell}
        handleBlur={handlePilotDutyRecordUpdate}
        handleChange={handlePilotDutyRecordChange}
        isDisabled={isDisabled}
        timeConstraints={{ [timeConstraintUnit]: { step: timeConstraintQuantity } }}
      />
    );
  };

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
      enableColumnFilter: false,
    },
    {
      header: 'Pilot',
      id: 'pilot_full_name',
      accessorFn: (row) => get(row, 'pilot.fullName', 'Unset pilot'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Duty Start At',
      accessorKey: 'duty_start_at',
      enableColumnFilter: false,
      cell: renderEditableDateCell,
      meta: {
        dateFormat: 'ddd Do MMMM YYYY',
        timeFormat: 'HH:mm',
      },
    },
    {
      header: 'Duty End At',
      accessorKey: 'duty_end_at',
      enableColumnFilter: false,
      cell: renderEditableDateCell,
      meta: {
        dateFormat: 'ddd Do MMMM YYYY',
        timeFormat: 'HH:mm',
      },
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title
            list
          >{`Pilot Duty Records - ${moment(startAt).format('MMMM YYYY')}`}</Title>
        </Col>
        <Col className="flex-grow-1">
          <ButtonToolbar className="d-flex justify-content-end">
            <ButtonGroup className="me-2">
              <Button type="info" size="sm" variant="secondary" onClick={onPrevClicked}>
                <Glyphicon glyph="chevron-left" />
              </Button>
              <Button type="button" size="sm" variant="secondary" onClick={onNextClicked}>
                <Glyphicon glyph="chevron-right" />
              </Button>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <Button
                size="sm"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={pageLoading}
              >
                Refresh
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button size="sm" variant="primary" className="me-2">
                <LinkContainer to="/pilot_duty_records/new" className="pt-2">
                  <span>Add a Pilot Duty Record</span>
                </LinkContainer>
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      {pilotDutyRecords.length ? (
        <ReactTable
          rootName="pilotDutyRecord"
          parentColumns={parentColumns}
          data={pilotDutyRecords}
          initialStateSorting={[
            { id: 'duty_start_at', desc: false },
            { id: 'pilot_full_name', desc: false },
          ]}
          hideActions
          hiddenColumns={['id']}
        />
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>No pilot duty records</Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PilotDutyRecordList;
