import { useState, useEffect } from 'react';
import {
  Label,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from 'recharts';

import CustomTooltip from './custom_tooltip';

export default function EnvelopeChart(props) {
  const {
    limitsCollection,
    externalLimitsCollection,
    armUnit,
    weightUnit,
    fuelBurnCollection,
    fuelBurnWithExternalLoadCollection,
  } = props;
  const [limits, setLimits] = useState([]);
  const [externalLimits, setExternalLimits] = useState([]);

  useEffect(() => {
    let newLimits = limitsCollection;
    if (limitsCollection.length > 0) {
      // this is a hack to prevent duplicate key on closing point as as they are formed from x/y
      const lastLimit = {
        name: newLimits[0].name,
        x: newLimits[0].x + 0.001,
        y: newLimits[0].y + 0.001,
      };
      newLimits = [...newLimits, lastLimit];
    }
    setLimits(newLimits);
  }, [limitsCollection]);

  useEffect(() => {
    let newExternalLimits = externalLimitsCollection;
    if (externalLimitsCollection.length > 0) {
      // this is a hack to prevent duplicate key on closing point as as they are formed from x/y
      const lastExternalLimit = {
        name: newExternalLimits[0].name,
        x: newExternalLimits[0].x + 0.001,
        y: newExternalLimits[0].y + 0.001,
      };
      newExternalLimits = [...newExternalLimits, lastExternalLimit];
    }
    setExternalLimits(newExternalLimits);
  }, [externalLimitsCollection]);

  if (limits.length === 0) return <p>No envelope data available</p>;
  if (limits.length < 4)
    return (
      <p>
        Insufficient envelope data available. Enter at least three xy pairs to create a
        polygon
      </p>
    );

  return (
    <ScatterChart
      margin={{ top: 0, right: 0, bottom: 25, left: 25 }}
      width={800}
      height={500}
    >
      <CartesianGrid strokeDasharray="3" />
      <XAxis
        type="number"
        dataKey="x"
        domain={([dataMin, dataMax]) => {
          const diff = dataMax - dataMin;
          return [dataMin - diff * 0.2, dataMax + diff * 0.2];
        }}
        name="arm"
        tickFormatter={(tick) => tick.toLocaleString()}
      >
        <Label value={`Arm (${armUnit})`} offset={0} position="bottom" />
      </XAxis>
      <YAxis
        type="number"
        dataKey="y"
        domain={([dataMin, dataMax]) => {
          const diff = dataMax - dataMin;
          return [dataMin - diff * 0.2, dataMax + diff * 0.2];
        }}
        name="weight"
        tickFormatter={(tick) => tick.toLocaleString()}
      >
        <Label value={`Weight (${weightUnit})`} offset={10} position="left" angle="-90" />
      </YAxis>
      <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />

      {externalLimits.length > 0 && (
        <Scatter
          name="External Limits"
          data={externalLimits}
          fill="blue"
          line={{ stroke: 'blue', strokeWidth: 1 }}
        />
      )}
      <Scatter
        name="Limits"
        data={limits}
        fill="black"
        line={{ stroke: 'black', strokeWidth: 1 }}
      >
        <LabelList dataKey="position" position="left" />
      </Scatter>
      {fuelBurnCollection && (
        <Scatter
          name="FuelBurn"
          data={fuelBurnCollection}
          fill="red"
          line={{ stroke: 'red', strokeWidth: 2 }}
          shape="square"
        >
          <LabelList dataKey="name" position="right" />
        </Scatter>
      )}
      {fuelBurnWithExternalLoadCollection && (
        <Scatter
          name="FuelBurnWithExternalLoad"
          data={fuelBurnWithExternalLoadCollection}
          fill="red"
          line={{ stroke: 'red', strokeWidth: 2 }}
          shape="square"
        >
          <LabelList dataKey="name" position="left" />
        </Scatter>
      )}
    </ScatterChart>
  );
}
