import { useEffect, useState } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';

import get from 'lodash.get';

import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import ReactTable from '../components/react_table';

import pageAircraftListQuery from '../queries/page_aircraft_list_query';

const AircraftList = () => {
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageAircraftListQuery, { notifyOnNetworkStatusChange: true });

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
      enableColumnFilter: false,
    },
    {
      header: 'State',
      id: 'activeActivationState',
      accessorFn: (row) => (row.activeActivation ? 'active' : 'inactive'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Registration',
      accessorKey: 'registration_abbreviated',
      filterFn: 'includesString',
      headerClassName: 'w-25',
    },
    {
      header: 'Type',
      id: 'aircraftType',
      accessorFn: (row) => get(row, 'aircraftType.name', 'Unset aircraft type'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
  ];

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleRefetch = () => pageRefetch();

  const getTableTools = () => {};

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title list>Aircraft</Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                size="sm"
                variant="primary"
                onClick={handleRefetch}
                disabled={pageLoading}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      {pageData?.aircraftList.length ? (
        <ReactTable
          rootName="aircraft"
          parentColumns={parentColumns}
          data={pageData.aircraftList}
          initialStateSorting={[
            { id: 'aircraftType', desc: false },
            { id: 'registration_abbreviated', desc: false },
          ]}
          {...getTableTools()}
          doShow={false}
        />
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>No aircraft</Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default AircraftList;
