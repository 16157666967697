/* eslint-disable react/jsx-props-no-spreading, react/no-children-prop */
import { Form } from 'react-bootstrap';

import InputField from '../form/input_field';
import HelpBlock from '../form/help_block';
import InvalidBlock from '../form/invalid_block';

// todo this can probably be a shared compoent, or a small update to input_field.js
const ContactFormPhotoField = (props) => {
  const { input, meta, children, size, labelWidth, inputWidth, helpText } = props;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    input.onChange(file);
  };

  return (
    <InputField
      size={size}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      input={{
        name: input.name,
      }}
      innerContent={
        <>
          <Form.Control type="file" value={undefined} onChange={handleFileChange} />
          <InvalidBlock meta={meta} helpText={helpText} force />
          <HelpBlock meta={meta} helpText={helpText} />
        </>
      }
    >
      {children}
    </InputField>
  );
};

ContactFormPhotoField.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 3,
};

export default ContactFormPhotoField;
