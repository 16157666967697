export function validationState(field) {
  return field.touched && field.invalid ? 'error' : null;
}

export function validationStates(fields) {
  return fields.map((field) => validationState(field)).filter((field) => field).length > 0
    ? 'error'
    : null;
}

export function validationText(meta, helpText = '') {
  if (!!meta && meta.touched && (meta.error || meta.submitError)) {
    if (Array.isArray(meta.error)) {
      return meta.error.join(', ');
    }
    if (Array.isArray(meta.submitError)) {
      return meta.submitError.join(', ');
    }
    return meta.error || meta.submitError;
  }
  return helpText;
}

export function isInvalid(meta) {
  return !!meta && meta.touched && meta.invalid;
}

export function anyInvalid(metas) {
  return metas.some((meta) => isInvalid(meta));
}
