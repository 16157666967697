import makeClassNames from 'classnames';

const Glyphicon = ({ glyph, className, style, spin = false }) => {
  const newClassNames = makeClassNames(
    'align-middle',
    'glyphicons',
    `glyphicons-${glyph}`,
    { 'glyphicons-spin': spin },
    className
  );
  return <span className={newClassNames} {...(style && { style })} />;
};

export default Glyphicon;
