/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { FieldArray, Fields } from 'redux-form';
import { useSelector } from 'react-redux';

import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';

import FlightPlans from './flight_plans';
import FlightPlanTableHeader from './flight_plan_table_header';
import FlightPlanTableFooter from './flight_plan_table_footer';
import BookingFlightPlanFields from './booking_flight_plan_fields';
import { convertFromTo, convertFuelToFuel, round2 } from '../../lib/convert_units';

const BookingFlightPlan = (props) => {
  const {
    change,
    formValues,
    aircrafts,
    aircraftConfigurations,
    aircraftTypes,
    bookingCalculatedData,
    flightSegmentsCalculatedData,
    locationsDataSelector,
  } = props;

  const { aircraftTypeFuelTypeSpecificGravity } = bookingCalculatedData;

  const {
    aircraft_id: aircraftId,
    aircraft_configuration_id: aircraftConfigurationId,
    flight_segments_attributes: flightSegmentsAttributes = [],
  } = formValues;
  const [isDeveloper, setIsDeveloper] = useState(false);
  const [anyPilotTakeOffFuelAdjusted, setAnyPilotTakeOffFuelAdjusted] = useState(false);
  const [aircraft, setAircraft] = useState({});
  const [aircraftType, setAircraftType] = useState({});
  const [aircraftConfiguration, setAircraftConfiguration] = useState({});
  const currentContactEmail = useSelector((state) => state.currentContact.email);

  useEffect(() => {
    let newAircraft = {};
    let newAircraftType = {};
    if (aircraftId) {
      newAircraft = aircrafts.find((a) => a.id === parseInt(aircraftId, 10));
      newAircraftType = aircraftTypes.find(
        (at) => at.id === newAircraft.aircraft_type_id
      );
    }
    setAircraft(newAircraft);
    setAircraftType(newAircraftType);
  }, [aircraftId]);

  useEffect(() => {
    setIsDeveloper(currentContactEmail === 'gordon.king02@gmail.com');
  }, [currentContactEmail]);

  useEffect(() => {
    let newAircraftConfiguration = {};
    if (aircraftConfigurationId) {
      newAircraftConfiguration = aircraftConfigurations.find(
        (a) => a.id === parseInt(aircraftConfigurationId, 10)
      );
    }
    setAircraftConfiguration(newAircraftConfiguration);
  }, [aircraftConfigurationId]);

  useEffect(() => {
    let newAnyPilotTakeOffFuelAdjusted = {};
    if (flightSegmentsAttributes.length > 0) {
      newAnyPilotTakeOffFuelAdjusted = flightSegmentsAttributes.some(
        (fs) => fs.pilot_take_off_fuel_adjusted
      );
    }
    setAnyPilotTakeOffFuelAdjusted(newAnyPilotTakeOffFuelAdjusted);
  }, [flightSegmentsAttributes]);

  const handleResetCalculation = useCallback(() => {
    const newFlightSegments = flightSegmentsAttributes.map((fs) => ({
      ...fs,
      take_off_fuel: 0,
      pilot_take_off_fuel: 0,
      pilot_take_off_fuel_adjusted: false,
    }));
    change('flight_segments_attributes', newFlightSegments);
  }, [flightSegmentsAttributes]);

  const convertFlightSegmentWeight = useCallback(
    (prevUnit, nextUnit) => {
      const newFlightSegments = flightSegmentsAttributes.map((fs) => ({
        ...fs,
        luggage_weight: Math.round(
          convertFromTo(defaultTo(parseInt(fs.luggage_weight, 10), 0), prevUnit, nextUnit)
        ),
      }));
      change('flight_segments_attributes', newFlightSegments);
    },
    [flightSegmentsAttributes]
  );

  const convertFlightSegmentAirspeed = useCallback(
    (prevUnit, nextUnit) => {
      const newFlightSegments = flightSegmentsAttributes.map((fs) => ({
        ...fs,
        airspeed_adj: round2(
          convertFromTo(defaultTo(parseFloat(fs.airspeed_adj), 0), prevUnit, nextUnit),
          10
        ),
      }));
      change('flight_segments_attributes', newFlightSegments);
    },
    [flightSegmentsAttributes]
  );

  const convertFlightSegmentFuel = useCallback(
    (prevUnit, nextUnit) => {
      const newFlightSegments = flightSegmentsAttributes.map((fs) => ({
        ...fs,
        take_off_fuel: convertFuelToFuel({
          fuelQuantity: defaultTo(parseFloat(fs.take_off_fuel), 0),
          fromFuelUnit: prevUnit,
          toFuelUnit: nextUnit,
          specificGravity: aircraftTypeFuelTypeSpecificGravity,
        }),
        pilot_take_off_fuel: convertFuelToFuel({
          fuelQuantity: defaultTo(parseFloat(fs.pilot_take_off_fuel), 0),
          fromFuelUnit: prevUnit,
          toFuelUnit: nextUnit,
          specificGravity: aircraftTypeFuelTypeSpecificGravity,
        }),
      }));
      change('flight_segments_attributes', newFlightSegments);
    },
    [flightSegmentsAttributes]
  );

  const renderFlightPlansFieldArray = (flightSegments) => (
    <FlightPlans
      change={change}
      formValues={formValues}
      flightSegments={flightSegments}
      flightSegmentsCalculatedData={flightSegmentsCalculatedData}
      locationsDataSelector={locationsDataSelector}
      isDeveloper={isDeveloper}
      anyPilotTakeOffFuelAdjusted={anyPilotTakeOffFuelAdjusted}
    />
  );

  if (!aircraftConfigurationId) {
    return (
      <Row className="mt-3">
        <Col sm={12}>
          <p>Select aircraft and aircraft configuration</p>
        </Col>
      </Row>
    );
  }

  if (aircraft.id && aircraftType.id && aircraftConfiguration.id) {
    return (
      <Row className="mt-3">
        <Col sm={12}>
          <Fields
            names={[
              'booking_cruise_airspeed_sl',
              'booking_cruise_fuel_consumption_sl',
              'booking_planned_reserve',
              'booking_weight_unit',
              'booking_arm_unit',
              'booking_fuel_unit',
              'booking_airspeed_unit',
            ]}
            component={BookingFlightPlanFields}
            bookingCalculatedData={bookingCalculatedData}
            firstFlightSegmentsCalculatedData={get(flightSegmentsCalculatedData, [0])}
            handleResetCalculation={handleResetCalculation}
            convertFlightSegmentWeight={convertFlightSegmentWeight}
            convertFlightSegmentFuel={convertFlightSegmentFuel}
            convertFlightSegmentAirspeed={convertFlightSegmentAirspeed}
            isDeveloper={isDeveloper}
          />
          <Row>
            <Col sm={12}>
              <Table striped size="sm" className="text-center">
                <FlightPlanTableHeader
                  bookingCalculatedData={bookingCalculatedData}
                  isDeveloper={isDeveloper}
                />
                <FieldArray
                  name="flight_segments_attributes"
                  component={renderFlightPlansFieldArray}
                />
                <FlightPlanTableFooter
                  flightSegmentsCalculatedData={flightSegmentsCalculatedData}
                  isDeveloper={isDeveloper}
                />
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  return null;
};

export default BookingFlightPlan;
