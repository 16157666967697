import { useState, useMemo, useCallback } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Table,
} from 'react-bootstrap';
import moment from 'moment';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import get from 'lodash.get';
import sortBy from 'lodash.sortby';

import ReactDateTimeField from '../form/react_date_time_field';
import Field from '../form/react_final_form_field';
import OnChangeField from '../form/rff_on_change_field';
import CheckboxInputField from '../form/checkbox_input_field';
import InputField from '../form/input_field';

function TemplateDutyEventsModal(props) {
  const {
    show,
    rosterId,
    rosterName,
    calendarResources,
    rosterTemplates,
    handleTemplateDutyEventsModalCancel,
    handleTemplateDutyEventsModalSubmit,
  } = props;

  const [selectedRosterTemplate, setSelectedRosterTemplate] = useState(null);

  const initialValues = useMemo(() => {
    let templateDefaultValues = { contactIds: [] };
    if (rosterId && calendarResources?.length > 0) {
      templateDefaultValues = {
        rosterId,
        templateFrom: moment().add(1, 'month').startOf('month').format(),
        templateThru: moment().add(1, 'month').endOf('month').format(),
        contacts: sortBy(calendarResources, ['id']).map(({ extendedProps, title }) => ({
          id: get(extendedProps, 'contactId'),
          title,
          template: false,
        })),
      };
    }
    return templateDefaultValues;
  }, [rosterId, calendarResources]);

  const activeSortedDutyTemplates = useMemo(() => {
    if (selectedRosterTemplate) {
      return selectedRosterTemplate.rosterTemplateDutyTemplates
        .filter((rtdt) => rtdt.active)
        .sort((a, b) => a.position - b.position)
        .map((rtdt) => ({
          id: rtdt.id,
          length: rtdt.length,
          dutyTemplateName: rtdt.dutyTemplate.name,
        }));
    }
    return [];
  }, [selectedRosterTemplate]);

  const onRosterTemplateIdChange = useCallback(
    (form, values) => {
      let newSelectedRosterTemplate;
      if (rosterTemplates) {
        const { rosterTemplateId } = values;
        if (rosterTemplateId) {
          newSelectedRosterTemplate = rosterTemplates.find(
            (rt) => rt.id === parseInt(rosterTemplateId, 10)
          );
        }
      }
      setSelectedRosterTemplate(newSelectedRosterTemplate);
    },
    [rosterTemplates]
  );

  const onTemplateFromChange = useCallback(
    (form, values) => {
      if (rosterId) {
        const { templateFrom, templateThru } = values;
        if (moment(templateFrom).isAfter(templateThru)) {
          form.batch(() => {
            form.change('templateThru', templateFrom);
          });
        }
      }
    },
    [rosterId]
  );

  const onTemplateThruChange = useCallback(
    (form, values) => {
      if (rosterId) {
        const { templateFrom, templateThru } = values;
        if (moment(templateThru).isBefore(templateFrom)) {
          form.batch(() => {
            form.change('templateFrom', templateThru);
          });
        }
      }
    },
    [rosterId]
  );

  return (
    <Modal
      id={`roster-${rosterId}-duty-event-modal`}
      show={show}
      onHide={() => {
        setSelectedRosterTemplate(null);
        handleTemplateDutyEventsModalCancel();
      }}
      centered
      size="xl"
      animation={false}
    >
      <FinalForm
        initialValues={initialValues}
        onSubmit={(data) => {
          setSelectedRosterTemplate(null);
          handleTemplateDutyEventsModalSubmit(data);
        }}
        mutators={{ setFieldTouched, ...arrayMutators }}
      >
        {({ handleSubmit, submitting, form, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{`Template Duty Events for ${rosterName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th>Duty Templates</th>
                        <th className="text-end">Length in Days</th>
                      </tr>
                    </thead>
                    {selectedRosterTemplate ? (
                      <tbody>
                        {activeSortedDutyTemplates.map((dt) => (
                          <tr key={dt.id}>
                            <td>{dt.dutyTemplateName}</td>
                            <td className="text-end">{dt.length}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={2}>select a template...</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Col>
                <Col />
              </Row>
              <OnChangeField name="rosterTemplateId">
                {() => onRosterTemplateIdChange(form, values)}
              </OnChangeField>
              <OnChangeField name="templateFrom">
                {() => onTemplateFromChange(form, values)}
              </OnChangeField>
              <OnChangeField name="templateThru">
                {() => onTemplateThruChange(form, values)}
              </OnChangeField>
              <fieldset className="border rounded-3 p-3">
                <Row>
                  <Col>
                    <Field
                      asElement="select"
                      name="rosterTemplateId"
                      labelWidth={4}
                      inputWidth={8}
                      component={InputField}
                      selectOptions={rosterTemplates}
                    >
                      Template
                    </Field>
                  </Col>
                  <Col>
                    <Field
                      name="templateFrom"
                      labelWidth={4}
                      inputWidth={8}
                      component={ReactDateTimeField}
                      helpText="DD/MM/YYYY"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      closeOnSelect
                    >
                      Template From
                    </Field>
                  </Col>
                  <Col>
                    <Field
                      name="templateThru"
                      labelWidth={4}
                      inputWidth={8}
                      component={ReactDateTimeField}
                      helpText="DD/MM/YYYY"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      closeOnSelect
                    >
                      Template Thru
                    </Field>
                  </Col>
                </Row>
                <fieldset className="border rounded-3 p-3">
                  <legend className="float-none w-auto px-3 fs-6">
                    Resources to Template
                  </legend>
                  <FieldArray name="contacts">
                    {({ fields: arrayFields }) => (
                      <Row>
                        {arrayFields.map((arrayName) => (
                          <Col sm={4} key={arrayName}>
                            <Field
                              type="checkbox"
                              name={`${arrayName}.template`}
                              labelWidth={1}
                              inputWidth={11}
                              component={CheckboxInputField}
                            >
                              {get(values, `${arrayName}.title`)}
                            </Field>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </FieldArray>
                </fieldset>
              </fieldset>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-end w-100">
                <ButtonGroup>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      setSelectedRosterTemplate(null);
                      handleTemplateDutyEventsModalCancel();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary" disabled={submitting}>
                    Template
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
}

export default TemplateDutyEventsModal;
