import Moment from 'moment';
import { extendMoment } from 'moment-range';
import mime from 'mime-types';
import { snakeCase } from 'change-case';
import path from 'path';

import cloneDeepWith from 'lodash.clonedeepwith';
import compact from 'lodash.compact';
import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';
import includes from 'lodash.includes';
import isNil from 'lodash.isnil';
import mapKeys from 'lodash.mapkeys';
import omit from 'lodash.omit';
import omitBy from 'lodash.omitby';
import pick from 'lodash.pick';
import transform from 'lodash.transform';

import Api from './api';

const moment = extendMoment(Moment);
moment.updateLocale('en-nz');

function omitByNil(data) {
  return omitBy(data, isNil);
}

export function omitValues(data, blacklist = ['__typename']) {
  return omit(omitByNil(data), blacklist);
}

export function mapOmitValues(data, blacklist = ['__typename']) {
  return data.map((datum) => omitValues(datum, blacklist));
}

export function pickValues(data, whitelist = ['id']) {
  return pick(omitByNil(data), whitelist);
}

export function mapPickValues(data, whitelist = ['id']) {
  return data.map((datum) => pickValues(datum, whitelist));
}

export function queryReady(query, allowUndefinedQuery = false) {
  return !!((query && !query.loading && query.data) || (!query && allowUndefinedQuery));
}

export function queryJustReady(prevQuery, query) {
  return prevQuery.loading && queryReady(query);
}

export function queriesReady(...queries) {
  return queries.every((query) => queryReady(...[].concat(query)));
}

const integers = [
  // flight_segment
  'luggage_weight',
  // booking
  'booking_planned_reserve',
  'booking_caa_weight_loading',
  // contact
  'weight',
  // seat_assignment
  'seat_assignment_weight',
  // fuel_tanker
  // 'capacity_unit',
  'capacity_value',
  'capacity_start',
  'service_radius',
  // location
  'display_format',
  // location_image, flight_segment, seat_assignment, aircraft_seat_seat_configuration
  'position',
  // phone_number
  // 'phone_number_type'
  // booking chargeables
  'pax',
];

const floats = [
  // aircraft
  'aircraft_weight',
  'aircraft_lateral_arm',
  'aircraft_longitudinal_arm',
  // booking
  'booking_cruise_airspeed_sl',
  'booking_cruise_fuel_consumption_sl',
  'booking_aircraft_weight',
  'booking_aircraft_lateral_arm',
  'booking_aircraft_longitudinal_arm',
  // admin_flight_record, hobb_record
  'flight_time',
  // engine_event
  'event_value',
  // flight_segment
  'airspeed_adj',
  'distance_adj',
  'end_location_landing_fee',
  'end_location_landing_fee_original',
  'take_off_fuel',
  'pilot_take_off_fuel',
  // fuel_bowser_fill, fuel_tanker_fill, oil_fill
  'quantity_value',
  // fuel_bowser_fill, fuel_tanker_fill, fuel_bowser
  'price',
  // hobb_record
  'start_hobb',
  'end_hobb',
  // location
  'airways_fee',
  'elevation',
  'landing_fee',
  'pal_frequency',
  // location, fuel_tanker
  'latitude',
  'longitude',
  // pilot_duty_record
  'duty_total',
  // pilot_flight_expense
  'total',
  'total_excl_gst',
  // pilot_flight_log_type
  'instrument_actual',
  'instrument_simulated',
  'instrument_ground',
  'sling',
  'longline',
  'fire',
  'survey',
  'nvg',
  'nvg_operations',
  // pilot_flight_log
  'command_practice_day',
  'command_practice_night',
  'copilot_day',
  'copilot_night',
  'dual_day',
  'dual_night',
  'log_total',
  'pilot_in_charge_day',
  'pilot_in_charge_night',
  // aircraft_asset
  'asset_longitudinal_arm',
  'asset_lateral_arm',
  'asset_uninstalled_weight_reduction',
  'asset_installed_weight_increase',
  // asset_assignment
  'asset_assignment_longitudinal_arm',
  'asset_assignment_lateral_arm',
  'asset_assignment_uninstalled_weight_reduction',
  'asset_assignment_installed_weight_increase',
  // aircraft_seat
  'seat_longitudinal_arm',
  'seat_lateral_arm',
  'seat_uninstalled_weight_reduction',
  'seat_installed_weight_increase',
  // aircraft_hold
  'hold_longitudinal_arm',
  'hold_lateral_arm',
  'hold_maximum_weight',
  'hold_uninstalled_weight_reduction',
  'hold_installed_weight_increase',
  // aircraft_tank
  'tank_maximum_fuel',
  // aircraft_tank_point
  'tank_point_longitudinal_arm',
  'tank_point_lateral_arm',
  'tank_point_fuel',
  // wb_limit_point
  'wb_limit_arm',
  'wb_limit_weight',
  // hold_assignment
  'hold_assignment_weight',
  'hold_assignment_maximum_weight',
  'hold_assignment_uninstalled_weight_reduction',
  'hold_assignment_installed_weight_increase',
  'hold_assignment_longitudinal_arm',
  'hold_assignment_lateral_arm',
  // tank_assignment
  'tank_assignment_fuel',
  'tank_assignment_maximum_fuel',
  'tank_assignment_longitudinal_arm',
  'tank_assignment_lateral_arm',
  // seat assignment
  'seat_assignment_installed_weight_increase',
  'seat_assignment_uninstalled_weight_reduction',
  'seat_assignment_longitudinal_arm',
  'seat_assignment_lateral_arm',
];

function inputTyper(value, key) {
  if (key && !(typeof value === 'object')) {
    if (includes(floats, key)) {
      return defaultTo(parseFloat(value), null);
    }
    if (includes(integers, key)) {
      return defaultTo(parseInt(value, 10), null);
    }
    if (value === '') {
      return null;
    }
  }
  return undefined;
}

export function coerceInput(data) {
  const typedData = cloneDeepWith(data, inputTyper);
  return typedData;
}

export function humanReportDate(start, end) {
  if (start && end) {
    if (moment(start).isAfter(end)) {
      return `${moment(start).format('MMMM, YYYY')} END DATE ERROR`;
    }
    if (moment(start).isSame(end, 'month')) {
      if (moment(start).isSame(end, 'day')) {
        return moment(start).format('Do MMMM, YYYY');
      }
      return `${moment(start).format('Do')} - ${moment(end).format('Do MMMM, YYYY')}`;
    }
    return `${moment(start).format('Do MMMM, YYYY')}  - ${moment(end).format(
      'Do MMMM, YYYY'
    )}`;
  }
  return 'eva..';
}

export function getExport(
  reportName,
  args = {},
  reportType = 'reports',
  exportType = 'pdf',
  displayName
) {
  const contentType = mime.lookup(exportType);
  return Api.get(
    `/export/${compact([reportType, reportName]).join('/')}.${exportType}`,
    mapKeys(args, (v, k) => snakeCase(k)),
    { responseType: 'arraybuffer' }
  ).then((resp) => {
    const blob = new Blob([resp.data], { type: contentType });
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(blob);
    if (exportType === 'pdf') {
      link.target = '_blank';
    } else {
      link.download = displayName || reportName;
    }
    link.click();
  });
}

export function getTaggingsNameArray(taggings) {
  if (taggings && taggings.length > 0) {
    return taggings.map((tagging) => tagging.tag.name);
  }
  return [];
}

export function getTaggingsNameString(taggings) {
  if (taggings && taggings.length > 0) {
    return getTaggingsNameArray(taggings).join(', ');
  }
  return '';
}

function getFullPhoneNumber(phoneNumber) {
  const a = phoneNumber.country_code
    ? `+${phoneNumber.country_code.replace(/^\+/, '')}`
    : null;
  const b = phoneNumber.area_code
    ? `(0)${phoneNumber.area_code.replace(/^0/, '')}`
    : null;
  const y = phoneNumber.extension ? `x${phoneNumber.extension.replace(/^x/, '')}` : null;
  return [a, b, phoneNumber.phone_number, y].filter((part) => part).join(' ');
}

export function getPhoneNumberToString(phoneNumber) {
  if (phoneNumber && phoneNumber.id) {
    const z = phoneNumber.description ? `- ${phoneNumber.description}` : null;
    const q = phoneNumber.phone_number_type || 'No type';
    return [`${q}:`, getFullPhoneNumber(phoneNumber), z].filter((part) => part).join(' ');
  }
  return '';
}

function getFullAddress(address) {
  const streetNumber = [address.prefix, address.street_number, address.suffix]
    .filter((s) => s)
    .join('');
  const streetLine = [streetNumber, address.street].filter((s) => s).join(' ');
  const cityLine = [address.city, address.postcode].filter((s) => s).join(' ');
  return [streetLine, address.post_office_box, address.suburb, cityLine, address.region]
    .filter((s) => s)
    .join(', ');
}

export function getAddressToString(address) {
  if (address && address.id) {
    const z = address.description ? `- ${address.description}` : '';
    const q = address.address_type || 'No type';
    return [`${q}:`, getFullAddress(address), z].filter((part) => part).join(' ');
  }
  return '';
}

export function getCheckStatus(check) {
  const {
    check_start_on: checkStartOn,
    check_end_on: checkEndOn,
    check_complete_on: checkCompleteOn,
  } = check;
  if (checkCompleteOn) {
    return 'completed';
  }
  const now = moment();
  if (moment(checkEndOn).isBefore(now)) {
    return 'overdue';
  }
  if (moment(checkStartOn).isBefore(now)) {
    return 'due_now';
  }
  return 'not_due';
}

export function getCheckStatusClassName(checkStatus) {
  switch (checkStatus) {
    case 'completed':
      return 'table-success';
    case 'overdue':
      return 'table-danger';
    case 'due_now':
      return 'table-warning';
    default:
      return '';
  }
}

export function getRolesToString(roles = [], statutoryRoles = []) {
  return [...roles, ...statutoryRoles].map((r) => r.name).join(', ');
}

export function getSelectable(data, selected, nameKey, roleKey = 'id') {
  let hasSelectedId = false;
  let selectedId;
  let hasSelectedIds = false;
  const selectedIds = []
    .concat(selected)
    .filter((id) => id)
    .map((id) => parseInt(id, 10));
  if (selectedIds.length === 1) {
    hasSelectedId = true;
    [selectedId] = selectedIds;
  } else if (selectedIds.length > 1) {
    hasSelectedIds = true;
  }
  const selectOptions = data.filter(
    (o) =>
      o[roleKey] &&
      (o.activeActivation ||
        (hasSelectedId && selectedId === o.id) ||
        (hasSelectedIds && selectedIds.includes(o.id)))
  );
  if (nameKey) {
    return selectOptions.map((o) => ({
      id: o.id,
      name:
        !o.activeActivation &&
        ((hasSelectedId && selectedId === o.id) ||
          (hasSelectedIds && selectedIds.includes(o.id)))
          ? `${o[nameKey]} (inactive)`
          : o[nameKey],
    }));
  }
  return selectOptions;
}

export function getContrastYIQ(color) {
  const hexcolor = color.substr(1, 6);
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#282828' : '#F8F8F8';
}

export function renderMultlilineText(text) {
  if (text) {
    return text.split('\n').map((line, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={`notes-${index}`}>
        {line}
        <br />
      </span>
    ));
  }
  return undefined;
}

export function getDateRanges(startAt, endAt, days = true, weeks = true, months = true) {
  /* eslint-disable no-restricted-syntax, camelcase */
  const result = [];
  if (days) {
    const dayRange = moment.range(
      moment(startAt).startOf('day'),
      moment(endAt).startOf('day')
    );
    for (const day of dayRange.by('day')) {
      result.push({ startAt: day.format(), endAt: day.endOf('day').format() });
    }
  }
  if (weeks) {
    const weekRange = moment.range(
      moment(startAt).startOf('isoWeek'),
      moment(endAt).startOf('isoWeek')
    );
    for (const week of weekRange.by('week')) {
      result.push({ startAt: week.format(), endAt: week.endOf('isoWeek').format() });
    }
  }
  if (months) {
    const monthRange = moment.range(
      moment(startAt).startOf('month'),
      moment(endAt).startOf('month')
    );
    for (const month of monthRange.by('month')) {
      result.push({ startAt: month.format(), endAt: month.endOf('month').format() });
    }
  }
  return result;
}

const transformSubmitErrors = (errors) =>
  transform(
    errors,
    (result, values, key) => {
      /* eslint-disable no-param-reassign */
      if (Array.isArray(values) && values.length > 0) {
        if (typeof get(values, [0, 'message']) === 'string') {
          result[key] = compact(values.map((value) => value.message));
        } else {
          result[key] = values.map((value) => transformSubmitErrors(value));
        }
      }
    },
    {}
  );

export function handleSubmitError(err) {
  const errorMessage = get(err, ['graphQLErrors', '0', 'message']);
  let submitErrors = {};
  const graphqlErrors = get(
    err,
    ['graphQLErrors', '0', 'extensions', 'data', 'errors'],
    {}
  );
  if (Object.keys(graphqlErrors).length > 0) {
    submitErrors = transformSubmitErrors(graphqlErrors);
  }
  return {
    errorMessage: errorMessage || 'Something went wrong',
    submitErrors: Object.keys(submitErrors).length > 0 ? submitErrors : undefined,
  };
}

export function getFileExtension(name = '') {
  return path.extname(name).slice(1).toLowerCase().replace('jpeg', 'jpg') || 'unknown';
}
