import get from 'lodash.get';
import memoize from 'lodash.memoize';

export const LocationCoordinatesMemo = memoize(
  (locationId, locations) => {
    if (locationId) {
      const location = locations.find((c) => c.id === parseInt(locationId, 10));
      return {
        locationLatitude: get(location, 'latitude', ''),
        locationLongitude: get(location, 'longitude', ''),
      };
    }
    return {};
  },
  (locationId) => {
    if (locationId) {
      return [locationId].map((i) => String(i)).join('.');
    }
    return 'unknown';
  }
);
