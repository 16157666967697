import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash.get';

import FlightSignoffFormPilotFlightExpenseFields from './flight_signoff_form_pilot_flight_expense_fields';

class FlightSignoffFormPilotFlightExpenses extends Component {
  render() {
    return this.props.pilotFlightExpenses.fields.map((field) => {
      const values = get(this.props, `formValues.${field}`, {});
      if (!values._destroy) {
        const fields = [
          `${field}.notes`,
          `${field}.total`,
          `${field}.includes_gst`,
          `${field}.onchargeable`,
          `${field}.reimbursable`,
        ];

        return (
          <Fields
            key={values.id}
            parentField={field}
            names={fields}
            component={FlightSignoffFormPilotFlightExpenseFields}
            formValues={values}
            overNight={
              this.props.currentSettingsPilotFlightExpenseOvernightText === values.notes
            }
            contactsDataSelector={this.props.contactsDataSelector}
          />
        );
      }
      return undefined;
    });
  }
}

export default FlightSignoffFormPilotFlightExpenses;
