import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'DATA_SET',
  'DATA_RESET',
  'AUTH_SET',
  'AUTH_RESET',
  'CURRENT_SETTINGS_SET',
  'TAG_CREATE',
]);

export default actionTypes;
