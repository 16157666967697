import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import flatten from 'lodash.flatten';
import get from 'lodash.get';
import has from 'lodash.has';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';
import uniqBy from 'lodash.uniqby';

import FuelBowserFillFields from './fuel_bowser_fill_fields';

class FuelBowserFillFieldArray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (
      has(this.props, 'formValues.fuel_bowser_fills_attributes') &&
      get(this.props, 'formValues.fuel_bowser_fills_attributes', []).length === 0
    ) {
      const fuelBowsers = flatten(
        get(this.props, 'flightSegmentsCalculatedData', [])
          .filter((fd) => get(fd, 'flightSegmentFuelBowsers', []).length > 0)
          .map((fd) => fd.flightSegmentFuelBowsers)
      );
      const locationIds = [];
      const fuelBowserFills = [];
      fuelBowsers.forEach((fuelBowser) => {
        if (locationIds.indexOf(fuelBowser.location_id) === -1) {
          fuelBowserFills.push({
            bowser_fillable_id: this.props.formValues.aircraft_id,
            bowser_fillable_type: 'Aircraft',
            fuel_bowser_id: fuelBowser.id,
            quantity_value: 0,
            price: fuelBowser.price,
            filled_on: this.props.formValues.start_at,
          });
          locationIds.push(fuelBowser.location_id);
        }
      });
      this.props.change('fuel_bowser_fills_attributes', fuelBowserFills);
    }
    this.getSelectOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentStartLocationIds = get(
      this.props,
      'flightSegmentsCalculatedData',
      []
    ).map((fd) => fd.startLocationId);
    const nextStartLocationIds = get(nextProps, 'flightSegmentsCalculatedData', []).map(
      (fd) => fd.startLocationId
    );
    if (!isEqual(currentStartLocationIds, nextStartLocationIds)) {
      this.getSelectOptions(nextProps);
    }
  }

  getSelectOptions(props = this.props) {
    const fuelBowsers = uniqBy(
      flatten(
        get(props, 'flightSegmentsCalculatedData', [])
          .filter((fd) => get(fd, 'flightSegmentFuelBowsers', []).length > 0)
          .map((fd) => fd.flightSegmentFuelBowsers)
      ),
      'id'
    );
    this.setState({
      selectOptions: fuelBowsers,
    });
  }

  handleAddButtonClick() {
    this.props.fuelBowserFills.fields.push({
      bowser_fillable_id: this.props.formValues.aircraft_id,
      bowser_fillable_type: 'Aircraft',
      quantity_value: 0,
      filled_on: this.props.formValues.start_at,
    });
  }

  handleDeleteButtonClick(index) {
    this.props.fuelBowserFills.fields.remove(index);
  }

  renderFuelBowserFillsList() {
    return (
      <>
        {this.props.fuelBowserFills.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}._destroy`,
              `${field}.bowser_fillable_id`,
              `${field}.fuel_bowser_id`,
              `${field}.quantity_value`,
            ];
            return (
              <Fields
                key={`booking-${field}`}
                index={index}
                parentField={field}
                names={fields}
                component={FuelBowserFillFields}
                formValues={pick(values, ['id', 'quantity_value', 'fuel_bowser_id'])}
                handleDeleteButtonClick={this.handleDeleteButtonClick}
                selectOptions={this.state.selectOptions}
                currentSettingsFuelBowserFillDefaultQuantityUnit={
                  this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
                }
              />
            );
          }
          return undefined;
        })}
      </>
    );
  }

  render() {
    const fillsLength = get(
      this.props,
      'formValues.fuel_bowser_fills_attributes',
      []
    ).filter((ff) => !ff._destroy).length;
    return (
      <Row>
        <Col sm={2}>
          <p>
            <strong>Bowser Refuelling</strong>
          </p>
        </Col>
        <Col sm={10}>
          {fillsLength > 0 && this.renderFuelBowserFillsList()}
          <Row>
            <Col sm={2} className="text-end">
              <Button
                type="button"
                variant="link"
                size="sm"
                onClick={this.handleAddButtonClick}
                tabIndex={-1}
                disabled={!this.props.formValues.aircraft_id}
              >
                add...
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default FuelBowserFillFieldArray;
