import get from 'lodash.get';
import actionTypes from '../constants';
import { toastSuccess, toastError, manageFormErrors } from '../lib/action_helpers';

export function mutationSet(set) {
  return {
    type: actionTypes.CURRENT_SETTINGS_SET,
    payload: {
      mutating: set,
    },
  };
}

export function mutationSuccess(message) {
  return function mutationSuccessDispatch(dispatch) {
    dispatch(mutationSet(false));
    if (message) {
      toastSuccess(`${message} ok`);
    }
  };
}

export function mutationFailure(
  err,
  reduxForm = false,
  isFormV6 = true,
  compactNestedObjectErrorArrays = false
) {
  return function mutationFailureDispatch(dispatch) {
    dispatch(mutationSet(false));
    const apiMessage = get(err, ['graphQLErrors', '0', 'data', 'apiMessage']); // this will be redundant
    const message = get(err, ['graphQLErrors', '0', 'message']);
    if (window.$NODE_ENV === 'development') {
      if (!(message || apiMessage)) {
        console.log('[MutationFailure error]', JSON.stringify(err, undefined, 2));
      }
    }
    toastError(apiMessage || message || 'Something went wrong');
    if (reduxForm) {
      let apiErrors = {};
      if (get(err, ['graphQLErrors', '0', 'extensions', 'data', 'errors'])) {
        // new schema error formatting
        apiErrors = get(err, ['graphQLErrors', '0', 'extensions', 'data', 'errors'], {});
      } else {
        // old schema error formatting
        apiErrors = get(err, ['graphQLErrors', '0', 'data', 'apiErrors'], {});
      }
      if (Object.keys(apiErrors).length > 0) {
        return manageFormErrors(apiErrors, isFormV6, compactNestedObjectErrorArrays);
      }
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1);
      });
    }
    return undefined;
  };
}
