/* eslint-disable react/no-array-index-key */
const TableHeader = ({ headers }) => (
  <tr>
    {headers.map((header, index) => (
      <th key={index} style={header.style} className={header.className}>
        {header.title}
      </th>
    ))}
  </tr>
);

export default TableHeader;
