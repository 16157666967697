import { Col } from 'react-bootstrap';
import { Fields } from 'redux-form';

import SeatAssignmentPaxFields from './seat_assignment_pax_fields';
import SeatAssignmentPilot from './seat_assignment_pilot';

export default function SeatAssignment({
  seatAssignmentIndex,
  flightSegmentIndex,
  width,
  field,
  selectablePassengers,
  addingPassenger,
  handleAddPassengerModalClicked,
  handlePassengerChanged,
  bookingPilotId,
  bookingCopilotId,
  formValues,
}) {
  const {
    name: seatName,
    seat_assignment_seat_type: seatAssignmentSeatType,
    seat_assignment_weight: seatAssignmentWeight,
    pax_name: paxName,
    passenger_id: passengerId,
  } = formValues;

  if (seatAssignmentSeatType === 'pilot_only') {
    return (
      <Col className="px-1" xs={6} {...(width && { style: { flexBasis: width } })}>
        <SeatAssignmentPilot
          pilot
          seatName={seatName}
          vacant={!bookingPilotId}
          paxName={paxName}
        />
      </Col>
    );
  }
  if (
    seatAssignmentSeatType === 'copilot_only' ||
    (seatAssignmentSeatType === 'copilot_or_pax' && bookingCopilotId)
  ) {
    return (
      <Col className="px-1" xs={6} {...(width && { style: { flexBasis: width } })}>
        <SeatAssignmentPilot
          copilot
          seatName={seatName}
          vacant={!bookingPilotId}
          paxName={paxName}
        />
      </Col>
    );
  }
  return (
    <Col className="px-1" xs={6} {...(width && { style: { flexBasis: width } })}>
      <Fields
        seatAssignmentIndex={seatAssignmentIndex}
        flightSegmentIndex={flightSegmentIndex}
        seatName={seatName}
        field={field}
        names={[`${field}.pax_name`, `${field}.seat_assignment_weight`]}
        component={SeatAssignmentPaxFields}
        selectablePassengers={selectablePassengers}
        addingPassenger={addingPassenger}
        handleAddPassengerModalClicked={handleAddPassengerModalClicked}
        handlePassengerChanged={handlePassengerChanged}
        seatAssignmentWeight={seatAssignmentWeight}
        paxName={paxName}
        passengerId={passengerId}
      />
    </Col>
  );
}
