import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady, coerceInput } from '../lib/utils';

import bookingUpdateMutation from '../mutations/booking_update_mutation';

import fuelTankerFillDetailForMonthForAircraftQuery from '../queries/fuel_tanker_fill_detail_for_month_for_aircraft_query';
import fuelTankerListQuery from '../queries/fuel_tanker_list_query';
import aircraftListQuery from '../queries/aircraft_list_query';

moment.updateLocale('en-nz');

class ReportFuelTankerFill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterFuelTankerId: this.props.currentSettingsReportFuelTankerId,
      filterFuelTankerIds: [],
      filterAircraftId: this.props.currentSettingsReportAircraftId,
      filterAircraftIds: [],
      fuel_tanker_fill_ids: {},
    };
    this._handleDisplayFuelTankerIdChange =
      this._handleDisplayFuelTankerIdChange.bind(this);
    this._handleDisplayAircraftIdChange = this._handleDisplayAircraftIdChange.bind(this);
    this._handleFuelTankerFillInvoicedChange =
      this._handleFuelTankerFillInvoicedChange.bind(this);
    this._handleSaveFuelTankerFillInvoicedClick =
      this._handleSaveFuelTankerFillInvoicedClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.fuelTankerId) {
      this._handleDisplayFuelTankerIdChange({
        target: { value: this.props.params.fuelTankerId },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      this.props.fuelTankerFillDetailForMonthForAircraftQuery.refetch();
      this.setState({
        fuel_tanker_fill_ids: {},
      });
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterFuelTankerIds } = this.state;
    let { filterAircraftIds } = this.state;
    if (!this.isLoaded(this.props) && this.isLoaded(nextProps)) {
      filterFuelTankerIds =
        nextProps.fuelTankerFillDetailForMonthForAircraftQuery.data.map(
          (data) => data.fuel_tanker_id
        );
      filterAircraftIds = nextProps.fuelTankerFillDetailForMonthForAircraftQuery.data.map(
        (data) => data.aircraft_id
      );
    }
    filterFuelTankerIds = [...new Set(filterFuelTankerIds)];
    filterAircraftIds = [...new Set(filterAircraftIds)];
    this.setState({
      filterFuelTankerIds,
      filterAircraftIds,
    });
    let filterFuelTankerId = nextProps.currentSettingsReportFuelTankerId;
    if (filterFuelTankerId && this.isLoaded(nextProps)) {
      if (
        filterFuelTankerIds.length > 0 &&
        filterFuelTankerIds.indexOf(filterFuelTankerId) === -1
      ) {
        filterFuelTankerId = '';
      }
    }
    if (filterFuelTankerId !== this.props.currentSettingsReportFuelTankerId) {
      this._handleDisplayFuelTankerIdChange({ target: { value: filterFuelTankerId } });
    }

    let filterAircraftId = nextProps.currentSettingsReportAircraftId;
    if (filterAircraftId && this.isLoaded(nextProps)) {
      if (
        filterAircraftIds.length > 0 &&
        filterAircraftIds.indexOf(filterAircraftId) === -1
      ) {
        filterAircraftId = '';
      }
    }
    if (filterAircraftId !== this.props.currentSettingsReportAircraftId) {
      this._handleDisplayAircraftIdChange({ target: { value: filterAircraftId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.fuelTankerFillDetailForMonthForAircraftQuery,
      props.aircraftListQuery,
      props.fuelTankerListQuery
    );
  }

  _handleDisplayFuelTankerIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterFuelTankerId: value,
    });
    this.props.currentSettingsSet({
      reportFuelTankerId: value,
    });
  }

  _handleDisplayAircraftIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterAircraftId: value,
    });
    this.props.currentSettingsSet({
      reportAircraftId: value,
    });
  }

  _handleSaveFuelTankerFillInvoicedClick() {
    let mutated = false;
    const { fuel_tanker_fill_ids } = this.state;
    Object.keys(fuel_tanker_fill_ids).forEach((fuel_tanker_fill_id) => {
      mutated = true;
      this.props.mutationSet(true);
      const { booking_id, quantity_value, invoiced } =
        fuel_tanker_fill_ids[fuel_tanker_fill_id];
      this.props
        .bookingUpdateMutation({
          variables: {
            id: booking_id,
            input: coerceInput({
              id: booking_id,
              fuel_tanker_fills_attributes: {
                id: fuel_tanker_fill_id,
                quantity_value,
                invoiced,
              },
            }),
          },
        })
        .then((res) => {
          this.props.mutationSuccess('Booking fuel invoice update');
        })
        .catch((err) => {
          this.props.mutationFailure(err);
        });
    });
    if (mutated) {
      this.delayedHandleRefetch();
    }
  }

  _handleFuelTankerFillInvoicedChange(e) {
    const { fuel_tanker_fill_ids } = this.state;
    const [, booking_id, fuel_tanker_fill_id, quantity_value] = e.target.id.split('-');
    fuel_tanker_fill_ids[fuel_tanker_fill_id] = {
      booking_id,
      quantity_value: parseFloat(quantity_value),
      invoiced: e.target.checked,
    };
    this.setState({
      fuel_tanker_fill_ids,
    });
  }

  _renderFixed(value) {
    return value ? value.toFixed(2) : '-';
  }

  _renderRow(fuel_tanker_fill) {
    const {
      id,
      fuel_tanker_fill_id,
      booking_id,
      booking_reference,
      booking_start_at_s,
      aircraft_registration_abbreviated,
      fuel_tanker_name,
      fuel_tanker_current_location,
      price,
      quantity_value,
      quantity_s,
      invoiced,
    } = fuel_tanker_fill;

    return (
      <tr key={id}>
        <td>
          <LinkContainer to={`/flights/${booking_id}/edit`} className="ps-0">
            <Button variant="link" size="sm" className="text-start p-0 m-0">
              {`#${booking_reference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{booking_start_at_s}</td>
        <td>{aircraft_registration_abbreviated}</td>
        <td>{fuel_tanker_name}</td>
        <td>{fuel_tanker_current_location}</td>
        <td className="text-end">{quantity_s}</td>
        <td className="text-end">
          <input
            id={`toggleinvoiced-${booking_id}-${fuel_tanker_fill_id}-${quantity_value}`}
            type="checkbox"
            checked={
              this.state.fuel_tanker_fill_ids[fuel_tanker_fill_id] !== undefined
                ? this.state.fuel_tanker_fill_ids[fuel_tanker_fill_id].invoiced
                : invoiced
            }
            onChange={this._handleFuelTankerFillInvoicedChange}
          />
        </td>
      </tr>
    );
  }

  _renderHeaderRow() {
    return (
      <thead>
        <tr>
          <th className="border-top-0">Ref#</th>
          <th className="border-top-0">Date</th>
          <th className="border-top-0">Registration</th>
          <th className="border-top-0">Fuel Tanker</th>
          <th className="border-top-0">Current Location</th>
          <th className="text-end border-top-0">Qty</th>
          <th className="text-end border-top-0">Received</th>
        </tr>
      </thead>
    );
  }

  _renderFuelTankerFills() {
    const fuel_tanker_fills =
      this.props.fuelTankerFillDetailForMonthForAircraftQuery.data.filter(
        (fuel_tanker_fill) => {
          if (
            this.state.filterAircraftId &&
            fuel_tanker_fill.aircraft_id !== this.state.filterAircraftId
          ) {
            return false;
          }
          if (
            this.state.filterFuelTankerId &&
            fuel_tanker_fill.fuel_tanker_id !== this.state.filterFuelTankerId
          ) {
            return false;
          }
          return true;
        }
      );
    return (
      <Row xs={1}>
        <Col className="d-flex justify-content-end align-items-start">
          <Button
            variant="primary"
            onClick={this._handleSaveFuelTankerFillInvoicedClick}
            className="py-0"
          >
            Save Received
          </Button>
        </Col>
        <Col>
          <Table striped size="sm">
            {this._renderHeaderRow()}
            <tbody>
              {fuel_tanker_fills.map((fuel_tanker_fill) =>
                this._renderRow(fuel_tanker_fill)
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }

  _renderFuelTankerFilter() {
    const dataFuelTankers = this.props.fuelTankerListQuery.data.filter(
      (model) => this.state.filterFuelTankerIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterFuelTankerId',
          value: this.state.filterFuelTankerId,
          onChange: this._handleDisplayFuelTankerIdChange,
        }}
        asElement="select"
        selectOptions={dataFuelTankers}
        optionKey="name"
        defaultSelectOptionName="All"
      />
    );
  }

  _renderAircraftFilter() {
    const dataAircrafts = this.props.aircraftListQuery.data.filter(
      (model) => this.state.filterAircraftIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterAircraftId',
          value: this.state.filterAircraftId,
          onChange: this._handleDisplayAircraftIdChange,
        }}
        asElement="select"
        selectOptions={dataAircrafts}
        optionKey="registration_abbreviated"
        defaultSelectOptionName="All"
      />
    );
  }

  _renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      return (
        <>
          <Row className="my-3">
            <Col>
              <ReportHeader
                title="Aircraft Refuellings by Tanker Report"
                start={this.props.currentSettingsReportStart}
                end={this.props.currentSettingsReportEnd}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="auto" className="px-0">
              <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
                <Glyphicon glyph="repeat" />
              </Button>
            </Col>
            <ReactDateTimeFilter
              size="sm"
              labelWidth={0}
              inputWidth={0}
              currentSettingsReportStart={this.props.currentSettingsReportStart}
              currentSettingsReportEnd={this.props.currentSettingsReportEnd}
              onChange={this.props.currentSettingsSet}
              closeOnSelect
            />
            {this._renderFuelTankerFilter()}
            {this._renderAircraftFilter()}
          </Row>
          {this._renderFuelTankerFills()}
        </>
      );
    }
  }

  render() {
    return (
      <>
        {this._renderOverlay()}
        {this._renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportFuelTankerId: state.currentSettings.reportFuelTankerId,
    currentSettingsReportAircraftId: state.currentSettings.reportAircraftId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(fuelTankerListQuery, {
    name: 'fuelTankerListQuery',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(fuelTankerFillDetailForMonthForAircraftQuery, {
    name: 'fuelTankerFillDetailForMonthForAircraftQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportFuelTankerFill);
