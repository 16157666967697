import LoaderComponent from './loader_component';
import ErrorComponent from './error_component';

export const renderOverlay = (loading = false, currentSettingsMutating = false) => {
  if (loading || currentSettingsMutating) {
    return <LoaderComponent />;
  }
  return undefined;
};

export const renderUnauthenticated = (authenticated = false) => {
  if (!authenticated) {
    return <p>logging out...</p>;
  }
  return undefined;
};

export const renderError = (error) => {
  if (error) {
    return <ErrorComponent error={error} />;
  }
  return undefined;
};
