import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import { Row, Col, Card, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

import Confirm from '../components/confirm';
import Loader from '../components/loader';
import DlHorizontal from '../components/dl_horizontal';
import Title from '../components/title';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import wbLimitQuery from '../queries/wb_limit_query';
import wbLimitDeleteMutation from '../mutations/wb_limit_delete_mutation';

import { queriesReady } from '../lib/utils';

class WbLimitShow extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const wbLimitId = e.currentTarget.getAttribute('data-id');
    this.props
      .wbLimitDeleteMutation({
        variables: {
          id: wbLimitId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('WB Limit delete');
        this.props.navigate('/wb_limits');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.aircraftTypeListQuery, testProps.wbLimitQuery);
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoaded()) {
      const {
        id,
        name,
        aircraft_type_id: aircraftTypeId,
        description,
        wbLimitPoints,
        limit_type: limitType,
        arm_unit: armUnit,
        weight_unit: weightUnit,
      } = this.props.wbLimitQuery.data;
      const aircraftType = this.props.aircraftTypeListQuery.data.find(
        (ft) => ft.id === aircraftTypeId
      );
      return (
        <>
          <Row className="mt-4 mb-3">
            <Col sm="auto">
              <Title show id={id}>
                WB Limits
              </Title>
            </Col>
            <Col>
              <Row className="justify-content-end g-0">
                <Col sm="auto">
                  <ButtonToolbar>
                    <ButtonGroup size="sm" className="mx-1">
                      <Confirm
                        dataId={id}
                        onConfirm={this.handleDeleteClick}
                        title="Delete WB Limit"
                        body="Are you sure you want to delete this WB Limit?"
                        confirmText="Confirm"
                      >
                        <Button variant="danger">Delete</Button>
                      </Confirm>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="mx-1">
                      <LinkContainer to={`/wb_limits/${id}/edit`}>
                        <Button variant="primary">Edit</Button>
                      </LinkContainer>
                      <LinkContainer to="/wb_limits">
                        <Button variant="primary">All WB Limits</Button>
                      </LinkContainer>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row>
            <Col sm={6}>
              <Card>
                <Card.Header>WB Limit Details</Card.Header>
                <Card.Body>
                  <DlHorizontal dt="Name" dd={name} />
                  <DlHorizontal dt="Description" dd={description} />
                  <DlHorizontal dt="Limit Type" dd={limitType} />
                  <DlHorizontal dt="Arm Unit" dd={armUnit} />
                  <DlHorizontal dt="Weight Unit" dd={weightUnit} />
                  <DlHorizontal dt="Aircraft Type" dd={aircraftType.name} />
                  <DlHorizontal
                    dt="Envelope Coordinates"
                    dd={
                      wbLimitPoints &&
                      [...wbLimitPoints]
                        .sort((a, b) => a.position - b.position)
                        .map((point) => {
                          const pointName = `${point.position} - ${point.wb_limit_arm}:${point.wb_limit_weight}`;
                          return (
                            <span key={pointName}>
                              {pointName}
                              <br />
                            </span>
                          );
                        })
                    }
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(wbLimitDeleteMutation, {
    name: 'wbLimitDeleteMutation',
  }),
  graphql(aircraftTypeListQuery, {
    name: 'aircraftTypeListQuery',
  }),
  graphql(wbLimitQuery, {
    name: 'wbLimitQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  })
)(WbLimitShow);

// export default connect(mapStateToProps, {
//   mutationSuccess,
//   mutationFailure,
//   mutationSet,
// })(WbLimitShow);
