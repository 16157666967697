import { Component } from 'react';

import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';
import includes from 'lodash.includes';

import InputField from '../form/input_field';
import { getSelectable } from '../../lib/utils';

class CopilotInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.aircraft_id && this.props.flight_type_id) {
      this.setState({
        selectOptions: this.getSelectOptions(),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.pilot_id !== nextProps.pilot_id ||
      this.props.aircraft_id !== nextProps.aircraft_id ||
      this.props.flight_type_id !== nextProps.flight_type_id
    ) {
      const copilotId = defaultTo(parseInt(nextProps.copilot_id, 10), '');
      const aircraftId = defaultTo(parseInt(nextProps.aircraft_id, 10), '');
      const flightTypeId = defaultTo(parseInt(nextProps.flight_type_id, 10), '');
      let newCopilotId = '';
      let selectOptions = [];
      if (aircraftId && flightTypeId) {
        selectOptions = this.getSelectOptions(nextProps);
        const pilotIds = selectOptions.map((so) => so.id);
        if (copilotId && includes(pilotIds, copilotId)) {
          newCopilotId = copilotId;
        } else {
          newCopilotId = '';
        }
      }
      this.setState({
        selectOptions,
      });
      if (newCopilotId !== copilotId) {
        this.props.input.onChange(newCopilotId);
      }
    }
  }

  getSelectableCopilots(props = this.props) {
    return getSelectable(
      get(props, 'pilots', []),
      get(props, 'copilot_id'),
      'fullName',
      'rolePilot'
    );
  }

  getSelectOptions(props = this.props) {
    // // All flights can choose any copilot
    const pilotId = defaultTo(parseInt(props.pilot_id, 10), '');
    // const aircraftId = defaultTo(parseInt(props.aircraft_id, 10), '');
    // const flightTypeId = defaultTo(parseInt(props.flight_type_id, 10), '');
    // const pilotIds = map(
    //   get(
    //     find(
    //       get(
    //         find(props.aircraftFlightTypePilotMap, ['aircraft_id', aircraftId]),
    //         'flight_types',
    //         {}
    //       ),
    //       ['flight_type_id', flightTypeId]
    //     ),
    //     'pilots',
    //     {}
    //   ),
    //   'pilot_id'
    // );
    // return (
    //   props.pilots
    //     // .filter((p) => includes(pilotIds, p.id))
    //     .map((p) => ({ id: p.id, name: p.fullName }))
    // );

    return this.getSelectableCopilots(props).filter((p) => p.id !== pilotId);
  }

  render() {
    const { input, meta } = this.props;

    return (
      <InputField
        type="text"
        size="sm"
        labelWidth={4}
        inputWidth={8}
        asElement="select"
        selectOptions={this.state.selectOptions}
        input={input}
        meta={meta}
      >
        {this.props.children}
      </InputField>
    );
  }
}

export default CopilotInputField;
