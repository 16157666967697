import { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import get from 'lodash.get';

import InputField from '../form/input_field';
import CheckboxInputField from '../form/checkbox_input_field';

class FlightSegmentLandingChargeFields extends Component {
  getControlLabel() {
    return get(
      this.props.locationsDataSelector,
      [this.props.formValues.end_location_id, 'longName'],
      'Select an end location'
    );
  }

  render() {
    const {
      end_location_landing_fee: {
        input: endLocationLandingFeeInput,
        meta: endLocationLandingFeeMeta,
      },
      oncharge_end_location_landing_fee: {
        input: onchargeEndLocationLandingFeeInput,
        meta: onchargeEndLocationLandingFeeMeta,
      },
    } = get(this.props, this.props.parentField);

    return (
      <InputField
        size="sm"
        labelWidth={2}
        inputWidth={10}
        input={{}}
        innerContent={
          <Row>
            <Col sm={2}>
              <InputField
                controlOnly
                blurOnly
                type="text"
                input={endLocationLandingFeeInput}
                meta={endLocationLandingFeeMeta}
                size="sm"
              />
            </Col>
            <Col sm={4}>
              <CheckboxInputField
                innerContentOnly
                size="sm"
                input={onchargeEndLocationLandingFeeInput}
                meta={onchargeEndLocationLandingFeeMeta}
              >
                Oncharge
              </CheckboxInputField>
            </Col>
          </Row>
        }
      >
        {this.getControlLabel()}
      </InputField>
    );
  }
}

export default FlightSegmentLandingChargeFields;
