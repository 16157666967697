import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';

import { kebabCase } from 'change-case';

// const getBookingAircraft = (bookings) => {
//   const aircraft = bookings
//     .filter((a) => a.aircraft_id)
//     .map((booking) => {
//       const {
//         aircraft: { id, registration_abbreviated: registrationAbbreviated },
//       } = booking;
//       return {
//         id,
//         registrationAbbreviated,
//       };
//     });
//   return uniqBy(aircraft, 'id');
// };

const getBookingViewAircraft = (bookingView, bookings) => {
  const { default_booking_view: defaultBookingView, aircrafts: viewAircrafts } =
    bookingView;
  let bookingAircrafts = [];
  if (defaultBookingView) {
    bookingAircrafts = bookings.map((b) => b.aircraft);
  }
  const activeViewAircraft = viewAircrafts.filter(
    (aircraft) => aircraft.activeActivation
  );
  const uniqAircraft = uniqBy([...activeViewAircraft, ...bookingAircrafts], 'id');
  const aircrafts = uniqAircraft.map((aircraft) => {
    const {
      id,
      registration_abbreviated: registrationAbbreviated,
      aircraft_type_id: aircraftTypeId,
    } = aircraft;
    return {
      id,
      registrationAbbreviated,
      aircraftTypeId,
    };
  });
  return sortBy(aircrafts, ['aircraftTypeId', 'registrationAbbreviated']);
};

const getAircraftResource = ({ registrationAbbreviated }, index) => ({
  id: kebabCase(registrationAbbreviated),
  title: registrationAbbreviated,
  groupId: 'Aircraft',
  extendedProps: {
    position: index,
  },
});

const getAircraftResources = ({ bookings, bookingView }) => {
  // const aircraftResources = getBookingAircraft(bookings);
  const aircraftResources = getBookingViewAircraft(bookingView, bookings);
  return aircraftResources.map((aircraft, index) => getAircraftResource(aircraft, index));
};

export default getAircraftResources;
