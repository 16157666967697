import { useState, useEffect } from 'react';

import get from 'lodash.get';

const WeightBalanceSeatAssignmentFields = (props) => {
  const { formValues, parentField, isDeveloper } = props;

  const {
    name,
    pax_name: paxName,
    passenger_id: passengerId,
    seat_assignment_seat_type: seatAssignmentSeatType,
    seat_assignment_installed_weight_increase: seatAssignmentInstalledWeightIncrease,
    seat_assignment_uninstalled_weight_reduction:
      seatAssignmentUninstalledWeightReduction,
    seat_assignment_weight: seatAssignmentWeight,
    seat_assignment_weight_unit: seatAssignmentWeightUnit,
    seat_assignment_installed: seatAssignmentInstalled,
  } = formValues;

  const {
    seat_assignment_installed: { input: seatAssignmentInstalledInput },
    seat_assignment_weight: { input: seatAssignmentWeightInput },
    passenger_id: { input: passengerIdInput },
    pax_name: { input: paxNameInput },
  } = get(props, parentField);

  const [installed, setInstalled] = useState(false);

  useEffect(() => {
    setInstalled(seatAssignmentInstalled || false);
  }, [seatAssignmentInstalled]);

  const handleInstalledChange = (e) => {
    setInstalled(e.target.checked);
    seatAssignmentInstalledInput.onChange(e.target.checked);
    if (seatAssignmentWeight) {
      seatAssignmentWeightInput.onChange(0);
    }
    if (paxName) {
      paxNameInput.onChange('');
    }
    if (passengerId) {
      passengerIdInput.onChange('');
    }
  };

  const renderWeight = () => {
    if (
      seatAssignmentUninstalledWeightReduction ||
      seatAssignmentInstalledWeightIncrease
    ) {
      return `${
        seatAssignmentUninstalledWeightReduction || seatAssignmentInstalledWeightIncrease
      }${seatAssignmentWeightUnit}`;
    }
    return 0;
  };

  return (
    <tr>
      <td>{name}</td>
      {isDeveloper && <td>{seatAssignmentWeightUnit}</td>}
      <td>{renderWeight()}</td>
      <td>{seatAssignmentSeatType}</td>
      <td>{paxName || 'vacant'}</td>
      <td>{seatAssignmentWeight || 0}</td>
      <td>
        <input type="checkbox" checked={installed} onChange={handleInstalledChange} />
      </td>
    </tr>
  );
};

export default WeightBalanceSeatAssignmentFields;
