import { Row, Col, Table, Button, Alert } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';
import { Fields } from 'redux-form';

import first from 'lodash.first';
import get from 'lodash.get';
import compact from 'lodash.compact';
import defaultTo from 'lodash.defaultto';
import difference from 'lodash.difference';

import PilotFlightLog from './pilot_flight_log';
import PilotFlightLogTableHeader from './pilot_flight_log_table_header';

const PilotFlightLogs = (props) => {
  const {
    change,
    pilotFlightLogs,
    formValues,
    currentSettingsPilotFlightLogFlightTypes,
    contactsDataSelector,
    aircraftsDataSelector,
    aircraftId,
    flightTime,
    prevFlightTime,
    flightPilotId,
    flightCopilotId,
    prevFlightPilotId,
    prevFlightCopilotId,
  } = props;

  const { pilot_flight_logs_attributes: pilotFlightLogsAttributes = [] } = formValues;

  const [multiengine, setMultiengine] = useState(false);
  const [multiengineSet, setMultiengineSet] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [rejectOptions, setRejectOptions] = useState([]);

  useEffect(() => {
    const flightPilotIds = compact(
      [flightPilotId, flightCopilotId].map((id) => defaultTo(parseInt(id, 10), ''))
    );
    const logPilotIds = compact(
      pilotFlightLogsAttributes
        .filter((pfl) => !pfl._destroy)
        .map((pfl) => defaultTo(parseInt(pfl.pilot_id, 10), ''))
    );
    const newSelectOptions = difference(flightPilotIds, logPilotIds);
    const newRejectOptions = difference(logPilotIds, flightPilotIds);
    setSelectOptions(newSelectOptions);
    setRejectOptions(newRejectOptions);
  }, [flightPilotId, flightCopilotId, pilotFlightLogsAttributes]);

  useEffect(() => {
    const newMultiengine = get(
      aircraftsDataSelector,
      [aircraftId, 'aircraftTypeMultiengine'],
      false
    );
    setMultiengine(newMultiengine);
    setMultiengineSet(true);
  }, [aircraftId, aircraftsDataSelector]);

  useEffect(() => {
    const makePilotFLightLogs = () => {
      const newPilotFlightLogs = [];
      if (flightPilotId) {
        newPilotFlightLogs.push({
          pilot_id: flightPilotId,
          pilot_in_charge_day: flightTime,
          multiengine,
          flight_types: {},
        });
      }
      if (flightCopilotId) {
        newPilotFlightLogs.push({
          pilot_id: flightCopilotId,
          copilot_day: flightTime,
          multiengine,
          flight_types: {},
        });
      }
      change('pilot_flight_logs_attributes', newPilotFlightLogs);
    };
    if (multiengineSet && pilotFlightLogsAttributes.length === 0) {
      makePilotFLightLogs();
    }
  }, [
    change,
    flightTime,
    flightPilotId,
    flightCopilotId,
    multiengine,
    multiengineSet,
    pilotFlightLogs,
    pilotFlightLogsAttributes,
  ]);

  const handleDelete = useCallback(
    (index) => {
      pilotFlightLogs.fields.remove(index);
    },
    [pilotFlightLogs]
  );

  const handleAddButtonClick = () => {
    const newPilotId = first(selectOptions);
    if (newPilotId) {
      const fields = {
        multiengine,
        pilot_id: newPilotId,
        flight_types: {},
      };
      if (newPilotId === parseInt(flightPilotId, 10)) {
        fields.pilot_in_charge_day = flightTime;
      } else if (newPilotId === parseInt(flightCopilotId, 10)) {
        fields.copilot_day = flightTime;
      }
      pilotFlightLogs.fields.push(fields);
    }
  };

  const renderEngineWarning = () => {
    const currentMultis = pilotFlightLogsAttributes
      .filter((pfl) => !pfl._destroy)
      .map((pfl) => pfl.multiengine);
    if (currentMultis.some((multi) => multi !== multiengine)) {
      const bookingType = multiengine ? 'multi-engine' : 'single-engine';
      const logType = multiengine ? 'single-engine' : 'multi-engine';
      return (
        <Col sm={12} className="mt-3">
          <Alert variant="warning">
            <strong>Caution</strong>
            &nbsp;
            {`The booking aircraft is a ${bookingType} type but one or more log entries are for ${logType} types`}
          </Alert>
        </Col>
      );
    }
    return null;
  };

  const renderPilotWarning = () => {
    // can't see how this will ever be true
    if (rejectOptions.length > 0) {
      return (
        <Col sm={12} className="mt-3">
          <Alert variant="warning">
            <strong>Caution</strong>
            &nbsp;One or more log entries are for non flight pilots
          </Alert>
        </Col>
      );
    }
    return null;
  };

  const renderPilotFlightLogsList = () => (
    <tbody>
      {pilotFlightLogs.fields.map((field, index) => {
        const values = get(formValues, field, {});
        if (!values._destroy) {
          const fields = [
            `${field}._destroy`,
            `${field}.pilot_id`,
            `${field}.multiengine`,
            `${field}.command_practice_day`,
            `${field}.command_practice_night`,
            `${field}.dual_day`,
            `${field}.dual_night`,
            `${field}.copilot_day`,
            `${field}.copilot_night`,
            `${field}.pilot_in_charge_day`,
            `${field}.pilot_in_charge_night`,
            `${field}.flight_types`,
          ];
          return (
            <Fields
              key={`pfl-${field}`}
              index={index}
              parentField={field}
              names={fields}
              component={PilotFlightLog}
              formValues={values}
              handleDelete={handleDelete}
              contactsDataSelector={contactsDataSelector}
              currentSettingsPilotFlightLogFlightTypes={
                currentSettingsPilotFlightLogFlightTypes
              }
              flightTime={flightTime}
              prevFlightTime={prevFlightTime}
              flightPilotId={flightPilotId}
              flightCopilotId={flightCopilotId}
              prevFlightPilotId={prevFlightPilotId}
              prevFlightCopilotId={prevFlightCopilotId}
            />
          );
        }
        return undefined;
      })}
    </tbody>
  );

  return (
    <Col sm={12}>
      <Row>
        {renderEngineWarning()}
        {renderPilotWarning()}
      </Row>
      <Table size="sm" striped>
        <PilotFlightLogTableHeader />
        {renderPilotFlightLogsList()}
      </Table>
      <Row>
        <Col sm={12}>
          <Button
            type="button"
            variant="link"
            size="sm"
            onClick={handleAddButtonClick}
            tabIndex={-1}
            disabled={selectOptions.length === 0}
          >
            add...
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default PilotFlightLogs;
