import { Row, Col, Button } from 'react-bootstrap';

import get from 'lodash.get';

import Confirm from '../confirm';
import InputField from '../form/input_field';
import Glyphicon from '../glyphicon';

const ContactFormAddressFields = (props) => {
  const {
    size,
    labelWidth,
    inputWidth,
    handleDeleteClick: parentHandleDeleteClick,
    index,
    parentField,
    addressTypes,
  } = props;

  const {
    address_type: { input: addressTypeInput, meta: addressTypeMeta },
    description: { input: descriptionInput, meta: descriptionMeta },
    prefix: { input: prefixInput, meta: prefixMeta },
    street_number: { input: streetNumberInput, meta: streetNumberMeta },
    suffix: { input: suffixInput, meta: suffixMeta },
    street: { input: streetInput, meta: streetMeta },
    suburb: { input: suburbInput, meta: suburbMeta },
    city: { input: cityInput, meta: cityMeta },
    region: { input: regionInput, meta: regionMeta },
    postcode: { input: postcodeInput, meta: postcodeMeta },
    post_office_box: { input: postOfficeBoxInput, meta: postOfficeBoxMeta },
  } = get(props, parentField);

  const handleDeleteClick = () => {
    if (parentHandleDeleteClick) {
      parentHandleDeleteClick(index);
    }
  };

  const renderDeleteMessage = () => {
    let msg = 'Are you sure you want to delete this address information?';
    if (get(props, 'formValues.id')) {
      msg +=
        ' Note that while the information will be removed from the list, it will not be permanently deleted until you save this Contact.';
    }
    return msg;
  };

  return (
    <fieldset className="border rounded-3 p-3">
      <Row>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          asElement="select"
          selectOptions={addressTypes}
          input={addressTypeInput}
          meta={addressTypeMeta}
        >
          Type
        </InputField>
        <InputField
          type="text"
          size={size}
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={descriptionInput}
          meta={descriptionMeta}
        >
          Description
        </InputField>
      </Row>
      <Row>
        <InputField
          type="text"
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={postOfficeBoxInput}
          meta={postOfficeBoxMeta}
        >
          PO Box
        </InputField>
        <InputField
          type="text"
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={prefixInput}
          meta={prefixMeta}
        >
          Prefix
        </InputField>
        <InputField
          type="text"
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={streetNumberInput}
          meta={streetNumberMeta}
        >
          Number
        </InputField>

        <InputField
          type="text"
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={suffixInput}
          meta={suffixMeta}
        >
          Suffix
        </InputField>

        <InputField
          type="text"
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={streetInput}
          meta={streetMeta}
        >
          Street
        </InputField>
      </Row>
      <Row>
        <InputField
          type="text"
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={suburbInput}
          meta={suburbMeta}
        >
          Suburb
        </InputField>

        <InputField
          type="text"
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={cityInput}
          meta={cityMeta}
        >
          City
        </InputField>

        <InputField
          type="text"
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={regionInput}
          meta={regionMeta}
        >
          Region
        </InputField>

        <InputField
          type="text"
          labelWidth={labelWidth}
          inputWidth={inputWidth}
          input={postcodeInput}
          meta={postcodeMeta}
        >
          Postcode
        </InputField>
        <Col sm={{ span: 1, offset: 4 }}>
          <Confirm
            onConfirm={handleDeleteClick}
            title="Delete Address"
            body={renderDeleteMessage()}
            confirmText="Confirm"
          >
            <Button type="button" variant="danger" size={size} className="mt-4">
              <Glyphicon glyph="bin" />
            </Button>
          </Confirm>
        </Col>
      </Row>
    </fieldset>
  );
};

ContactFormAddressFields.defaultProps = {
  size: 'sm',
  labelWidth: 0,
  inputWidth: 4,
};

export default ContactFormAddressFields;
