const getTenantConfig = (tenant) => {
  const tenantConfig = {
    flight: {
      tenantLongName: 'Advanced Flight Limited',
      tenantShortName: 'Flight',
    },
  }[tenant];
  return (
    { tenant, ...tenantConfig } || {
      tenant: '',
      tenantLongName: '',
      tenantShortName: '',
    }
  );
};

export default getTenantConfig;
